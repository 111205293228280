import axios from "axios";
import { createAsyncThunk,createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
    IDLE : 'idle',
    ERROR:'error',
    LOADING: 'loading',
});

const getVerifyAndDeleteGroundTypeSlice = createSlice({
    name: 'VerifyAndDeleteGroundType',
    initialState: {
        data:[],
        status : STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
        .addCase(fetchVerifyAndDeleteGroundType.pending, (state, action) => {
            state.status = STATUSES.LOADING;
        })
        .addCase(fetchVerifyAndDeleteGroundType.fulfilled, (state, action) => {
            state.data = action.payload;
            state.status = STATUSES.IDLE
        }) 
        .addCase(fetchVerifyAndDeleteGroundType.rejected , (state,action) => {
            state.status = STATUSES.ERROR;
        })
    }
});

export const {setVerifyAndDeleteGroundType , setStatus} = getVerifyAndDeleteGroundTypeSlice.actions;
export default getVerifyAndDeleteGroundTypeSlice.reducer;

export const fetchVerifyAndDeleteGroundType = createAsyncThunk('/getVerifyAndDeleteGroundType/fetch', async({id}) => {
    const res = await axios.delete(`${window.env.API_URL}/VerifyAndDeleteGroundType/${id}`);
    const VerifyAndDeleteGroundTypeData = res.data;
    return VerifyAndDeleteGroundTypeData.data;
})