import React, { useEffect, Fragment, useState } from "react";
import { fetchbreeder, STATUSES } from "../../redux/getReducer/getBreeder";
import { useDispatch, useSelector } from "react-redux";
import { MdDelete } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { BiEdit } from "react-icons/bi";
import swal from "sweetalert";
import ScrollContainer from "react-indiana-drag-scroll";
import Lottie from "lottie-react";
import HorseAnimation from "../../assets/horselottie.json";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { BsEyeFill } from "react-icons/bs";
import BreederPopup from "../../Components/Popup/BreederPopup";
import Pagination from "./Pagination";
import { BiFilter } from "react-icons/bi";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import CSVBreeder from "../../Components/CSVUploadPopup/BreederPopup";
import Defaultimg from "../../assets/default.jpg";
import Notfound from "../../Notfound";
import { MdAutoDelete } from 'react-icons/md';
import { fetchVerifyAndDeleteBreeder } from '../../redux/VerifyDeleteReducer/getbreederverifydelete';


const BreederTable = () => {
  const [ShowCalender, setShowCalender] = useState(false);
  const [SearchCode, setSearchCode] = useState("");
  const [SearchTitle, setSearchTitle] = useState("");
  const [TotalCount, setTotalCount] = useState("");
  const [TotalPages, setTotalPages] = useState("");
  const [show, setShow] = useState(false);
  const [modaldata, setmodaldata] = useState();
  const [loading, setloading] = useState(false);
  const { userInfo } = useSelector((state) => state.user);


  //--------------------------show popup----------------------//
  const handleClose = () => setShow(false);
  const handleShow = async (data) => {
    setmodaldata(data);
    await setShow(true);
  };

  const [showCSV, setShowCSV] = useState(false);
  const [modaldataCSV, setmodaldataCSV] = useState();
  const handleCloseCSV = () => setShowCSV(false);
  const handleShowCSV = async (data) => {
    setmodaldataCSV(data);
    await setShowCSV(true);
  };

  const dispatch = useDispatch();
  const history = useNavigate();

  const { data: breeder, status } = useSelector((state) => state.breeder);
  const { data: VerifyAndDeleteBreeder } = useSelector((state) => state.VerifyAndDeleteBreeder);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(8);


  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const GetSearch = async () => {
    dispatch(
      fetchbreeder({
        SearchTitle,
        SearchCode,
        currentPage,
      })
    );
  setCurrentPage(1)
  };

  useEffect(() => {
    dispatch(
      fetchbreeder({
        SearchTitle,
        SearchCode,
        currentPage,
      })
    );
  }, [dispatch, currentPage]);

  useEffect(() => {
    setTotalCount(breeder.totalcount)
    setTotalPages(breeder.totalPages)
  },[breeder])
  //--------------------------delete----------------------//
  const handleRemove1 = async (Id) => {
    try {
      swal({
        title: "Are you sure?",
        text: "do you want to delete this data ?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          await axios.delete(`${window.env.API_URL}/softdeleteBreeder/${Id}`);
          swal("Your data has been deleted Successfully!", {
            icon: "success",
          });
          dispatch(
            fetchbreeder({
              SearchTitle,
              SearchCode,

              currentPage,
            })
          );
        } else {
          swal("Your data is safe!");
        }
      });
    } catch (error) {
      const err = error.response.data.message;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
    }
  };

  const handleRemove = async (id) => {
    try {
      swal({
        title: "Are you sure?",
        text: "do you want to delete this data ?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          setloading(true)
          const res = await axios.delete(`${window.env.API_URL}/DeleteBreederAlltoAll/${id}`);
          const msg = res.data.message ?  res.data.message : "deleted";
          swal(msg, {
            icon: "",
          });
      let data1 =  JSON.stringify(res.data.data)
      const blob = new Blob([data1]);
      if(res?.data?.flag){
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.download = "report1.json";
        link.href = url;
        link.click();
        setloading(false)
            GetSearch({ currentPage });

      }     
      setloading(false)
          GetSearch({ currentPage });

        } else {
          swal("Your data is safe!");
        }
      });
    } catch (error) {
      const err = error.success;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
      setloading(false)

    }
  }

   

  const handleDeleteVerify = async (id) => {

    try {
      swal({
        title: "Are you sure?",
        text: "do you want to delete this data ?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          setloading(true)
          const res = await axios.delete(`${window.env.API_URL}/VerifyAndDeleteBreeder/${id}`);
          const msg = res.data.message ?  res.data.message : "deleted";
          swal(msg, {
            icon: "",
          });
          let data1 =  JSON.stringify(res.data.data)
      const blob = new Blob([data1]);
      const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
      link.download = "report1.json";
      link.href = url;
      link.click();
      setloading(false)
      dispatch(fetchbreeder({ SearchTitle, SearchCode }));
        } else {
          swal("Your data is safe!");
        }
      });
    } catch (error) {
      const err = error.success;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
    }

  }
 
  if (status === STATUSES.ERROR) {
    return (
      <h2
        style={{
          margin: "100px",
        }}
      >
        Something went wrong!
      </h2>
    );
  }

  return (
    <Fragment>
      <div className="page">
        <div className="rightsidedata">
          <div
            style={{
              marginTop: "30px",
            }}
          >
            <div className="Header ">
              <h4>Breeder Listings</h4>

              <div>
                <Link to="/breeder">
                  <button>Add Breeder</button>
                </Link>
                <OverlayTrigger
                  overlay={<Tooltip id={`tooltip-top`}>Filter</Tooltip>}
                >
                  <span className="addmore">
                    <BiFilter
                      className="calendericon"
                      onClick={() => setShowCalender(!ShowCalender)}
                    />
                  </span>
                </OverlayTrigger>{" "}
                <p onClick={() => handleShowCSV()} className="importcsv">
                  Import JSON
                </p>

              </div>
            </div>
            <div>
              {ShowCalender ? (
                <>
                  <div className="userfilter">

                    <div className="filtertextform d-flex">
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setSearchTitle(e.target.value)}
                        placeholder="Enter Name"
                      />
                      <input
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        className="form-control"
                        onChange={(e) => setSearchCode(e.target.value)}
                        placeholder="Enter Short Code"
                      />
                    </div>
                  </div>
                  <button className="filterbtn" onClick={GetSearch}>
                    Apply Filter
                  </button>
                </>
              ) : (
                <></>
              )}
            </div>
            <>
              <div className="div_maintb">
                <ScrollContainer>
                  <table>
                    <thead>
                      <tr>
                        <th>Action</th>
                        <th>Name</th>
                        <th>Name Arabic </th>
                        <th>Short Code</th>
                        <th>Description</th>
                        <th>Description Arabic</th>
                        <th>Image</th>
                      </tr>
                    </thead>
                    {status === 'loading' ?  <Lottie
                            animationData={HorseAnimation}
                            loop={true}
                            className="TableLottie"
                          /> : breeder.data === [] ? (
                      <Notfound />
                    ) : (
                      <tbody>
                        {status === "loading" ? (
                          <Lottie
                            animationData={HorseAnimation}
                            loop={true}
                            className="TableLottie"
                          />
                        ) : (
                          breeder.data && breeder.data.length ? breeder.data.map((item, index) => {
                            return (
                              <>
                                <tr className="tr_table_class">
                                  <td className="table_delete_btn1">
                                    <BiEdit
                                      onClick={() =>
                                        history("/editbreeder", {
                                          state: {
                                            breederid: item,
                                          },
                                        })
                                      }
                                    />
                                   <MdAutoDelete
                                    style={{
                                      fontSize: "22px",
                                    }}
                                    onClick={() => handleRemove(item._id)}
                                  />

                                {/* {userInfo && userInfo.data.role === "admin" ? (
                                  <MdAutoDelete
                                    onClick={() => handleDeleteVerify(item._id)}
                                  />
                                ) : (
                                  <></>
                                )} */}
                                    <BsEyeFill
                                      onClick={() => handleShow(item)}
                                    />
                                  </td>
                                  <td>{item.NameEn}</td>
                                  <td>{item.NameAr}</td>

                                  <td>{item.shortCode} </td>
                                  <td>{item.DescriptionEn} </td>
                                  <td>{item.DescriptionAr} </td>
                                  <td>
                                    <img
                                      src={item.image ? item.image : Defaultimg}
                                      alt=""
                                    />
                                  </td>

                                </tr>
                              </>
                            );
                          }) : <Notfound />
                        )}
                      </tbody>
                    )}
                  </table>
                </ScrollContainer>
              </div>
            </>
          </div>
          <span className="plusIconStyle"></span>
          <Pagination
            postsPerPage={postsPerPage}
            totalPosts={TotalCount}
            currentPage={currentPage}
            paginate={paginate}
            TotalPages={TotalPages}
          />
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2 style={{ fontFamily: "inter" }}>Breeder</h2>
        </Modal.Header>
        <Modal.Body>
          <BreederPopup data={modaldata} />
        </Modal.Body>
        <Modal.Footer>
          <button onClick={handleClose} className="modalClosebtn">
            Close
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showCSV}
        onHide={handleCloseCSV}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2 style={{ fontFamily: "inter" }}>Breeder JSON</h2>
        </Modal.Header>
        <Modal.Body>
          <CSVBreeder data={modaldataCSV} />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default BreederTable;
