import React, { useState, useEffect } from 'react'
import swal from 'sweetalert';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { fetchracetypeshortcode } from "../../redux/getShortCode/getracetypeshortcode";
import { useSelector, useDispatch } from "react-redux";
import Loader from '../../Components/Common/Loader';
import { Form, Input } from 'antd';

const Racetypeform = ({ setShowRaceType }) => {
  //for error

  const { data: racetypeshortcode } = useSelector((state) => state.racetypeshortcode)
  const dispatch = useDispatch();



  const [NameEn, setNameEn] = useState("");
  const [NameAr, setNameAr] = useState("");

  const [isLoading, setisLoading] = useState(false);

  const history = useNavigate()
  const { pathname } = useLocation();


  const [state1, setState] = useState({
    shortCode: '',
  });
  //----------------------------shortcode -------------------------//
  useEffect(() => {
    if (racetypeshortcode) {
      setState({
        shortCode: racetypeshortcode.length === 0 ? 10 : racetypeshortcode[0].maxshortCode + 1,
      });
    } else {
      setState.shortCode('9')
    }
  }, [racetypeshortcode]);


  useEffect(() => {
    dispatch(fetchracetypeshortcode());
  }, [dispatch])
  //----------------------------submit -------------------------//
  const submit = async () => {

    setisLoading(true);
    try {
      const formData = new FormData();
      formData.append("NameEn", NameEn);
      formData.append("NameAr", NameAr)
      formData.append("shortCode", state1.shortCode);

      await axios.post(`${window.env.API_URL}/uploadRaceType`, formData)
      swal({
        title: "Success!",
        text: "Data has been added successfully",
        icon: "success",
        button: "OK",
      });
      if (pathname === '/racetypeform') {
        history('/racetype')
      } setShowRaceType(false)
      setisLoading(false)
    } catch (error) {
      const err = error.response.data.message[0];
      const err1 = error.response.data.message[1];
      const err2 = error.response.data.message[2];


      swal({
        title: "Error!",
        text: err, err1, err2,
        icon: "error",
        button: "OK",
      });
      setisLoading(false)
    }
  };
  return (
    <div className="page">

      <div className="rightsidedata">
        <div
          style={{
            marginTop: "30px",
          }}
        >
          <div className="Headers">Create Race type</div>
          {isLoading ? <Loader /> : <div className="form">

            <Form onFinish={submit} fields={[{

              name: "shortCode",
              value: state1.shortCode

            }]}>
              <div className="row mainrow">
                <div className="col-sm">










                  <Form.Item
                    name="NameEn"
                    label="Name"
                    rules={[
                      {
                        required: true,
                        message: 'Name (English) is required',
                      },
                    ]}
                  >
                    <Input placeholder="Name" onChange={(e) => setNameEn(e.target.value)}
                      value={NameEn} name="NameEn" />
                  </Form.Item>






                  <span className="spanForm"> |</span>
                  <span className={Error.status ? "success" : "error"}>
                    {Error.message}
                  </span>
                </div>

                <div className="col-sm">
                  <Form.Item
                    name="NameAr"
                    label="اسم"
                    rules={[
                      {
                        required: true,
                        message: 'Name (Arabic) is required',
                      },
                    ]}
                    style={{ direction: 'rtl' }}
                  >
                    <Input placeholder="اسم" onChange={(e) => setNameAr(e.target.value)}
                      name="Name"
                      value={NameAr} />
                  </Form.Item>

                </div>
              </div>
              <div className="row mainrow">
                <div className="col-sm">
                  <Form.Item
                    name="shortCode"
                    label="Short Code"
                    rules={[
                      {
                        required: true,
                        message: 'Short Code  is required',
                      },
                    ]}
                  >
                    <Input
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onPaste={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(e) =>
                        setState({ ...state1, shortCode: e.target.value })
                      }
                      value={state1.shortCode}
                      placeholder="Short Code"
                      maxLength="5"
                    />
                  </Form.Item>
                </div>
              </div>

              <div className="ButtonSection " style={{ justifyContent: "end" }}>
                <button
                  Name="submit"
                  className="SubmitButton"
                  disabled={isLoading}
                >
                                       Save

                </button>
              </div>
            </Form>
          </div>}
        </div>
      </div>
    </div>
  )
}

export default Racetypeform