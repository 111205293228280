import React, { useEffect, Fragment, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import ScrollContainer from "react-indiana-drag-scroll";
import Lottie from "lottie-react";
import HorseAnimation from "../../assets/horselottie.json";
import axios from "axios";
import { BiEdit } from "react-icons/bi";
import { Modal } from "react-bootstrap";

import Notfound from "../../Notfound";
import { fetchdeletedpoint, STATUSES } from "../../redux/getDeletedreducer/DeletedPoints";
import { FaTrashRestoreAlt } from "react-icons/fa";




const BonusPoint = (data) => {
    return (
        <>
            <table className="Prizeclass">
                <thead className="Prizeclassthead">
                    <tr>
                        <th>Type </th>
                        <th>Rank </th>
                        <th>Point </th>
                        <th>Bonus Point</th>




                    </tr>
                </thead>
                <tbody className="Prizeclasstbody">
                    {
                        data.data.PointSystemidDataOfCompetition ? data.data.PointSystemidDataOfCompetition.map((item) => {
                            return (
                                <tr>
                                    <td>{item.Type}</td>
                                    <td>{item.Rank}</td>

                                    <td>{item.Point}</td>
                                    <td>{item.BonusPoint}</td>

                                </tr>
                            )
                        }) : <></>
                    }
                    <tr>
                        {/* <td>{data.data.FirstPrice}</td>
            <td>{data.data.SecondPrice}</td>
            <td>{data.data.ThirdPrice}</td>
            <td>{data.data.FourthPrice}</td>
            <td>{data.data.FifthPrice}</td>
            <td>{data.data.SixthPrice}</td> */}
                    </tr>
                </tbody>
            </table>
        </>
    );
};

const Pointstable = () => {
    //---------------------------state-------------------------//



    //---------------------------modal-------------------------//

    const [modaldata, setmodaldata] = useState();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = async (data) => {
        setmodaldata(data);
        await setShow(true);
    };






    const dispatch = useDispatch();
    const history = useNavigate();
    const { data: deletedpoints, status } = useSelector((state) => state.deletedpoints);

    const [currentPage, setCurrentPage] = useState(1);
    const [Disable, setDisable] = useState(true);
    //---------------------------api-------------------------//


    //---------------------------search-------------------------//


    useEffect(() => {
        dispatch(fetchdeletedpoint());
        // Data1()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, currentPage]);
    //---------------------------delete-------------------------//

    const Restorefunction = async (id) => {
        try {

            //buttons disable
            setDisable(false)

            await axios.post(`${window.env.API_URL}/restoresoftdeleteAds/${id}`,);
            // api 
            // button enable
            dispatch(fetchdeletedpoint());
            setDisable(true)
            swal({
                title: "Success!",
                text: "Data has been added successfully ",
                icon: "success",
                button: "OK",
            });
        } catch (error) {
            const err = error.response.data.message;
            swal({
                title: "Error!",
                text: err,
                icon: "error",
                button: "OK",
            });
        }



    }



    if (status === STATUSES.ERROR) {
        return (
            <h2
                style={{
                    margin: "100px",
                }}
            >
                Something went wrong!
            </h2>
        );
    }

    return (
        <Fragment>
            <div className="page">
                <div className="rightsidedata">
                    <div
                        style={{
                            marginTop: "30px",
                        }}
                    >
                        <div className="Header ">
                            <h4>Point Table Listings</h4>

                            <div>
                                <h6
                                    style={{
                                        marginRight: "100px",
                                        alignItems: "center",
                                        color: "rgba(0, 0, 0, 0.6)",
                                    }}
                                >

                                </h6>

                                {/* <Link to="/addcompetitionPoint">
                                    <button>Add Point Table</button>
                                </Link> */}

                            </div>
                        </div>
                        <>
                            <div className="div_maintb">
                                <ScrollContainer>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Action</th>

                                                <th>Point Group Name</th>

                                                <th>Length</th>
                                                <th>Point System </th>

                                                <th>Short Code</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                !deletedpoints.length ? <Notfound /> :
                                                    status === STATUSES.LOADING ?

                                                        <Lottie animationData={HorseAnimation} loop={true} className="TableLottie" />

                                                        :
                                                        deletedpoints.map((item, index) => {
                                                            return (
                                                                <>
                                                                    <tr className="tr_table_class">
                                                                        <td className="table_delete_btn1">
                                                                            <BiEdit

                                                                                onClick={() =>
                                                                                    history("/editpoint", {
                                                                                        state: {
                                                                                            pointid: item,
                                                                                        },
                                                                                    })
                                                                                }
                                                                            />
                                                                            <FaTrashRestoreAlt onClick={() => Restorefunction(item._id)} disabled={!Disable} />

                                                                            {/* <BsEyeFill
                                                                                onClick={() => handleShow(item)}
                                                                            /> */}
                                                                        </td>
                                                                        <td>{item.Group_NameDataOfCompetition ? item.Group_NameDataOfCompetition.NameEn : <></>}</td>

                                                                        <td>{item.Length}</td>

                                                                        <td>
                                                                            <button
                                                                                className="Approvedbtn resultbtn"
                                                                                onClick={() => handleShow(item)}
                                                                            >
                                                                                Click
                                                                            </button>
                                                                        </td>

                                                                        <td>{item.shortCode} </td>


                                                                    </tr>
                                                                </>
                                                            );
                                                        })}
                                        </tbody>
                                    </table>
                                </ScrollContainer>
                            </div>
                        </>
                    </div>
                    <span className="plusIconStyle"></span>
                    {/* <Pagination
                        postsPerPage={postsPerPage}
                        totalPosts={TotalCount}
                        paginate={paginate}
                        currentPage={currentPage}
                        TotalPages={TotalPages}

                    /> */}
                </div>
            </div>
            <Modal
                show={show}
                onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <h2>Point Table  </h2>
                </Modal.Header>
                <Modal.Body>
                    <BonusPoint data={modaldata} />
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={handleClose} className="modalClosebtn">
                        Close
                    </button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default Pointstable;
