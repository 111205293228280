import React, { useState, useEffect } from "react";
import "../../Components/CSS/forms.css";
import { useNavigate, useLocation } from "react-router-dom";
import swal from "sweetalert";
import axios from "axios";
import Select from "react-select";
import { ImCross } from 'react-icons/im';

import { Form, Input } from 'antd';
import Loader from "../../Components/Common/Loader";

//---------------------------options for dropdowns---------------------------//

const Hemisphere = [
  {
    id: "1", value: "Southern Hemisphere", label: (
      <div style={{
        display: 'flex',
        justifyContent: 'space-between'
      }}>
        <p>Southern Hemisphere</p>
        <p>نصف الكرة الجنوبي</p>

      </div>
    ),
  },
  {
    id: "2", value: "Northern Hemisphere", label: (
      <div style={{
        display: 'flex',
        justifyContent: 'space-between'
      }}>
        <p>Northern Hemisphere</p>
        <p>نصف الكرة الشمالي</p>

      </div>
    ),
  },
];
const EditNationality = () => {
  const [form] = Form.useForm();
  const history = useNavigate();
  const { state } = useLocation();
  const [HemisphereEn, setHemisphereEn] = useState("");
  const [isLoading, setisLoading] = useState(false)
  const [isLoading1, setisLoading1] = useState(false);

  const { nationalityid } = state;

  const [image, setImage] = useState();
  const [preview, setPreview] = useState();

  const [state1, setState] = useState({
    NameEn: '',
    NameAr: '',
    shortCode: '',
    AltNameEn: '',
    AltNameAr: '',
    AbbrevAr: '',
    AbbrevEn: '',
    HemisphereEn: "",
    Offset: '',
    ValueEn: '',
    ValueAr: '',
    image: image

  });

  const fileSelected = (event) => {
    const image = event.target.files[0];
    setImage(image, image);
  };

  //---------------------------get Data from previous state---------------------------//

  useEffect(() => {
    if (nationalityid) {
      setState({
        NameEn: nationalityid.NameEn,
        NameAr: nationalityid.NameAr,
        shortCode: nationalityid.shortCode,
        AltNameEn: nationalityid.AltNameEn,
        AltNameAr: nationalityid.AltNameAr,
        AbbrevAr: nationalityid.AbbrevAr,
        AbbrevEn: nationalityid.AbbrevEn,
        Offset: nationalityid.Offset,
        ValueEn: nationalityid.ValueEn,
        ValueAr: nationalityid.ValueAr,
        HemisphereEn: nationalityid.HemisphereEn,
        image: nationalityid.image
      });
    } else {
    }
  }, [nationalityid]);
  //---------------------------Image Preview---------------------------//

  useEffect(() => {
    if (image === undefined) {

      setPreview(nationalityid.image)
      return
    }


    const objectUrl = URL.createObjectURL(image)
    setPreview(objectUrl)
    return () => URL.revokeObjectURL(objectUrl)

  }, [image, nationalityid.image])
  const handlePreview = () => {
    setImage()
    setPreview()
    setisLoading1(true)
    document.getElementById("file").value = ""
  };

  //---------------------------submit---------------------------//


  const submit = async () => {



    try {
      setisLoading(true)
      const formData = new FormData();
      formData.append("image", image);
      formData.append("NameEn", state1.NameEn);
      formData.append("NameAr", state1.NameAr);
      formData.append("shortCode", state1.shortCode);
      formData.append("AltNameEn", state1.AltNameEn);
      formData.append("AltNameAr", state1.AltNameAr);
      formData.append("HemisphereEn", (HemisphereEn.value === undefined ? state1.HemisphereEn : HemisphereEn.value));
      formData.append("AbbrevAr", state1.AbbrevAr);
      formData.append("AbbrevEn", state1.AbbrevEn);

      formData.append("Offset", state1.Offset);
      if (isLoading1 === true && image === undefined) {
        formData.append("deleteexisitingimage", isLoading1)
      }

      await axios.put(`${window.env.API_URL}/updateNationality/${nationalityid._id}`, formData);
      history("/nationalitylist");
      swal({
        title: "Success!",
        text: "Data has been Updated Successfully ",
        icon: "success",
        button: "OK",
      });
    } catch (error) {
      const err = error.response.data.message;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
    }
    setisLoading(false)
  };
  form.setFieldsValue({
    NameEn: state1.NameEn,
    NameAr: state1.NameAr,
    Abbreviation: state1.AbbrevEn,
    AbbreviationAr: state1.AbbrevAr,
    AltNameEn: state1.AltNameEn,
    AltNameAr: state1.AltNameAr,
    shortCode: state1.shortCode

  });

  return (
    <>
      <div className="page">
        <div className="rightsidedata">
          <div
            style={{
              marginTop: "30px",
            }}
          >
            <div className="Headers">Edit Nationality</div>
            {isLoading ? <Loader /> : <div className="form">
              <Form onFinish={submit}
                form={form}


              >
                <div className="row mainrow">
                  <div className="col-sm">




                    <Form.Item
                      name="NameEn"
                      label="Name"
                      rules={[
                        {
                          required: true,
                          message: 'Name (English) is required',
                        },
                      ]}
                    >
                      <Input placeholder="Name" onChange={(e) =>
                        setState({ ...state1, NameEn: e.target.value })
                      }
                        value={state1.NameEn} name="NameEn" />
                    </Form.Item>










                    <span className="spanForm spacer"> |</span>
                  </div>

                  <div className="col-sm">





                    <Form.Item
                      name="NameAr"
                      label="اسم"
                      rules={[
                        {
                          required: true,
                          message: 'Name (Arabic) is required',
                        },
                      ]}
                      style={{ direction: 'rtl' }}
                    >
                      <Input placeholder="اسم" onChange={(e) =>
                        setState({ ...state1, NameAr: e.target.value })
                      }
                        name="Name"
                        value={state1.NameAr} />
                    </Form.Item>
















                  </div>
                </div>

                <div className="row mainrow">
                  <div className="col-sm">


                    <Form.Item
                      name="Abbreviation"
                      label="Abbreviation"
                      rules={[
                        {
                          required: true,
                          message: 'Abbreviation is required',
                        },
                      ]}
                    >
                      <Input placeholder="Abbreviation"

                        onChange={(e) =>
                          setState({ ...state1, AbbrevEn: e.target.value })
                        }

                        value={state1.AbbrevEn} />
                    </Form.Item>








                    <span className="spanForm spacer"> |</span>
                  </div>

                  <div className="col-sm">




                    <Form.Item
                      name="AbbreviationAr"
                      label="اكتب الاختصار"
                      rules={[
                        {
                          required: true,
                          message: 'Abbreviation (Arabic) is required',
                        },
                      ]}
                      style={{ direction: 'rtl' }}
                    >
                      <Input placeholder="اكتب الاختصار" onChange={(e) =>
                        setState({ ...state1, AbbrevAr: e.target.value })
                      }
                        value={state1.AbbrevAr}




                      />
                    </Form.Item>













                  </div>

                </div>

                <div className="row mainrow">
                  <div className="col-sm">



                    <Form.Item
                      name="AltNameEn"
                      label="AltNameEn"
                      rules={[
                        {
                          required: true,
                          message: 'AltNameEn (English) is required',
                        },
                      ]}
                    >
                      <Input placeholder="Alternative Name" label=" Alternative Name"

                        onChange={(e) =>
                          setState({ ...state1, AltNameEn: e.target.value })
                        }
                        value={state1.AltNameEn} name="AltNameEn" />
                    </Form.Item>









                    <span className="spanForm spacer"> |</span>
                  </div>

                  <div className="col-sm">





                    <Form.Item
                      name="AltNameAr"
                      label="متبادل"
                      rules={[
                        {
                          required: true,
                          message: 'Alternative Name (Arabic) is required',
                        },
                      ]}
                      style={{ direction: 'rtl' }}
                    >
                      <Input placeholder="اكتب الاسم البديل" onChange={(e) =>
                        setState({ ...state1, AltNameAr: e.target.value })
                      }
                        value={state1.AltNameAr}
                        style={{ direction: "rtl", left: "initial", right: 0 }}


                      />
                    </Form.Item>








                  </div>

                </div>


                <div className="row mainrow">
                  <div className="col-sm">
                    <Select
                      placeholder={<div>{state1.HemisphereEn}</div>}
                      defaultValue={HemisphereEn}
                      onChange={setHemisphereEn}
                      options={Hemisphere}
                      isClearable={true}
                      isSearchable={true}


                    />



                  </div>



                </div>
                <div className="row mainrow">
                  <div className="col-sm">
                    <Form.Item
                      name="shortCode"
                      label="Short Code"
                      rules={[
                        {
                          required: true,
                          message: "short Code is required"
                        },
                      ]}
                    >
                      <Input
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        onChange={(e) =>
                          setState({ ...state1, shortCode: e.target.value })
                        }
                        value={state1.shortCode}
                        onPaste={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        placeholder="Short Code"
                        maxLength="5"
                      />
                    </Form.Item>

                  </div>

                </div>


                <div className="ButtonSection ">
                  <div className={image === undefined ? "inputFile" : ""}>
                    <label className="Multipleownerlabel">
                      Select Nationality Flag image
                    </label>
                    <input
                      type="file"
                      onChange={fileSelected}
                      className="formInput fileinputdata "

                      id="file"
                    />
                    {preview && (
                      <>
                        <ImCross onClick={handlePreview} className="crossIcon" />
                        <img src={preview} className="PreviewImage" alt="" />
                      </>
                    )}
                  </div>
                  <div>
                    <button type="submit" className="SubmitButton" disabled={isLoading}>
                      Update
                    </button>
                  </div>
                </div>
              </Form>
            </div>}
          </div>
        </div>
      </div>
    </>
  );
};

export default EditNationality;
