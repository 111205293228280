import React, { Fragment, useState, useEffect } from "react";
import swal from "sweetalert";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { Form, Input } from 'antd';
import { fetchcolorshortcode } from "../../redux/getShortCode/getcolorshortcode";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../Components/Common/Loader";

const Color = ({ setShowColor }) => {
  //for errors

  const [colorForRaceCourse, setColorForRaceCourse] = useState("");

  const dispatch = useDispatch();

  const { data: colorshortcode } = useSelector((state) => state.colorshortcode);
  const [registeration, setregisteration] = useState({
    NameEn: "",
    NameAr: "",
    shortCode: "",
    AbbrevEn: "",
    AbbrevAr: "",
  });

  const [isLoading, setisLoading] = useState(false);
  const [state1, setState] = useState({
    shortCode: "",
  });
  //----------------------------shortcode ---------------------//

  useEffect(() => {
    if (colorshortcode) {
      setState({
        shortCode:
          colorshortcode.length === 0 ? 10 : colorshortcode[0].maxshortCode + 1,
      });
    } else {
      setState.shortCode("9");
    }
  }, [colorshortcode]);

  useEffect(() => {
    dispatch(fetchcolorshortcode());
  }, [dispatch]);
  //----------------------------hnadlechange ---------------------//
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setregisteration({ ...registeration, [name]: value });

  };









  //----------------------------submit ---------------------//
  const submit = async (event) => {

    setisLoading(true);
    try {
      const formData = new FormData();
      formData.append("NameEn", registeration.NameEn);
      formData.append("NameAr", registeration.NameAr);
      formData.append("AbbrevEn", registeration.AbbrevEn);
      formData.append("AbbrevAr", registeration.AbbrevAr);
      formData.append("shortCode", state1.shortCode);
      formData.append("Color", colorForRaceCourse);
      console.log(formData, 'data')
      await axios.post(`${window.env.API_URL}/uploadColor`, formData);
      swal({
        title: "Success!",
        text: "Data has been added Successfully ",
        icon: "success",
        button: "OK",
      });
      if (pathname === "/color") {
        history("/colorlist");
      } setShowColor(false)

    } catch (error) {
      const err = error.response.data.message[0];
      const err1 = error.response.data.message[1];
      const err2 = error.response.data.message[2];


      swal({
        title: "Error!",
        text: err,
        err1,
        err2,
        icon: "error",
        button: "OK",
      });
      setisLoading(false);
    }
  };
  //conditional styling for errors

  const history = useNavigate();
  const { pathname } = useLocation();

  return (
    <Fragment>
      <div className="page">
        <div className="rightsidedata">
          <div
            style={{
              marginTop: "30px",
            }}
          >
            <div className="Headers">Create Color</div>
            {!isLoading ? <div className="form">
              <Form onFinish={submit}

                fields={[{

                  name: "shortCode",
                  value: state1.shortCode

                }]}
              >
                <div className="row mainrow">
                  <div className="col-sm">
                    <Form.Item
                      name="NameEn"
                      label="Name"
                      rules={[
                        {
                          required: true,
                          message: 'Name (English) is required',
                        },
                      ]}
                    >
                      <Input placeholder="Name" onChange={handleChange}
                        value={registeration.NameEn} name="NameEn" />
                    </Form.Item>


                    <span className="spanForm spacer"> |</span>


                  </div>

                  <div className="col-sm">
                    <Form.Item
                      name="NameAr"
                      label="اسم"
                      rules={[
                        {
                          required: true,
                          message: 'Name (Arabic) is required',
                        },
                      ]}
                      style={{ direction: 'rtl' }}
                    >
                      <Input placeholder="اسم" name="NameAr"
                        onChange={handleChange}
                        value={registeration.NameAr}
                        type="text"
                      />
                    </Form.Item>




                  </div>
                </div>

                <div className="row mainrow">
                  <div className="col-sm">
                    <Form.Item
                      name="AbbrevEn"
                      label="Abbreviation"
                      rules={[
                        {
                          required: true,
                          message: 'Abbreviation (English) is required',
                        },
                      ]}
                    >
                      <Input placeholder="Abbrevation" onChange={handleChange}
                        value={registeration.AbbrevEn}
                        name="AbbrevEn"
                        type="text"
                      />
                    </Form.Item>


                    <span className="spanForm spacer"> |</span>

                  </div>

                  <div className="col-sm">
                    <Form.Item
                      name="AbbrevAr"
                      label="اختصار"
                      rules={[
                        {
                          required: true,
                          message: 'Abbreviation (Arabic) is required',
                        },
                      ]}
                      style={{ direction: 'rtl' }}
                    >
                      <Input placeholder="اختصار" name="AbbrevAr"
                        onChange={handleChange}
                        value={registeration.AbbrevAr}
                        type="text"

                      />
                    </Form.Item>

                  </div>
                </div>
                <div className="row mainrow">
                  <div className="col-sm">
                    <Form.Item
                      name="shortCode"
                      label="Short Code"
                      rules={[
                        {
                          required: true,
                          message: 'Short Code  is required',
                        },
                      ]}
                    >
                      <Input
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        onPaste={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        onChange={(e) =>
                          setState({ ...state1, shortCode: e.target.value })
                        }
                        value={state1.shortCode}
                        placeholder="Short Code"
                        maxLength="5"
                      />
                    </Form.Item>
                  </div>
                </div>


                <div className="row mainrow">
                  <div className="col-sm">
                    <Form.Item

                      label="Color"

                      value={colorForRaceCourse}
                    >
                      <Input
                        value={colorForRaceCourse}

                        placeholder="Color"

                      />
                    </Form.Item>
                  </div>
                </div>


                <div className="swatch">
                  <input type="color" id="color" name="color" onChange={(e) => setColorForRaceCourse(e.target.value)} value={colorForRaceCourse} />
                  {
                    colorForRaceCourse ? <h5 className="text-center p-1">
                      {colorForRaceCourse}
                    </h5> : <label className="Multipleownerlabel">
                      Select Race Course Color
                    </label>
                  }
                </div>
                <div
                  className="ButtonSection btnsectionbtn"
                  style={{ justifyContent: "end" }}
                >
                  <button
                    Name="submit"
                    className="SubmitButton"
                    disabled={isLoading}

                  >
                    Save
                  </button>
                </div>
              </Form>
            </div> : <Loader />}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Color;
