import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { fetchtrackconditionshortcode } from "../../redux/getShortCode/gettrackconditionshortcode";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Components/Common/Loader";
import { Form, Input } from 'antd';

const TrackCondition = ({ setshowCondition }) => {
    //FOR ERRORS


    const [NameEn, setNameEn] = useState("");
    const [NameAr, setNameAr] = useState("");
    const [shortName, setshortName] = useState("");
    const [shortNameAr, setshortNameAr] = useState("");
    const [isLoading, setisLoading] = useState(false);
    const dispatch = useDispatch();
    const { data: trackconditionshortcode } = useSelector(
        (state) => state.trackconditionshortcode
    );
    const history = useNavigate();
    const { pathname } = useLocation();

    const [state1, setState] = useState({
        shortCode: "",
    });
    //----------------------------shortcode -------------------------//

    console.log(trackconditionshortcode, "trackconditionshortcode");
    useEffect(() => {
        if (trackconditionshortcode) {
            setState({
                shortCode:
                    trackconditionshortcode.length === 0
                        ? 10
                        : trackconditionshortcode[0].maxshortCode + 1,
            });
        } else {
        }
    }, [trackconditionshortcode]);

    useEffect(() => {
        dispatch(fetchtrackconditionshortcode());
    }, [dispatch]);
    //----------------------------submit-------------------------//

    const submit = async (event) => {
        // event.preventDefault();
        setisLoading(true);
        try {
            const formData = new FormData();
            formData.append("NameEn", NameEn);
            formData.append("NameAr", NameAr);
            formData.append("shortCode", state1.shortCode);
            formData.append("AbbrevEn", shortName);
            formData.append("AbbrevAr", shortNameAr);
            await axios.post(`${window.env.API_URL}/uploadTrackCondition`, formData);
            swal({
                title: "Success!",
                text: "Data has been added successfully ",
                icon: "success",
                button: "OK",
            });
            if (pathname === "/trackcondition") {
                history("/trackconditionlist");
            }
            setshowCondition(false);
            setisLoading(false);
        } catch (error) {
            const err = error.response.data.message[0];
            const err1 = error.response.data.message[1];
            const err2 = error.response.data.message[2];

            swal({
                title: "Error!",
                text: err,
                err1,
                err2,
                icon: "error",
                button: "OK",
            });
            setisLoading(false);
        }
    };




    return (
        <div className="page">
            <div className="rightsidedata">
                <div
                    style={{
                        marginTop: "30px",
                    }}
                >
                    <div className="Headers">Create Track Condition</div>
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <div className="form">
                            <Form onFinish={submit}

                                fields={[{

                                    name: "shortCode",
                                    value: state1.shortCode

                                }]}
                            >
                                <div className="row mainrow">
                                    <div className="col-sm">
                                        <Form.Item
                                            name="NameEn"
                                            label="Name"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Name (English) is required',
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Name" onChange={(e) => setNameEn(e.target.value)}
                                                value={NameEn} name="NameEn" />
                                        </Form.Item>


                                        <span className="spanForm"> |</span>

                                    </div>

                                    <div className="col-sm">
                                        <Form.Item
                                            name="NameAr"
                                            label="اسم"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Name (Arabic) is required',
                                                },
                                            ]}
                                            style={{ direction: 'rtl' }}
                                        >
                                            <Input placeholder="اسم" onChange={(e) => setNameAr(e.target.value)}
                                                name="Name"
                                                value={NameAr} />
                                        </Form.Item>

                                    </div>
                                </div>
                                <div className="row mainrow">
                                    <div className="col-sm">
                                        <Form.Item
                                            name="AbbrevEn"
                                            label="Abbreviation"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Abbreviation (English) is required',
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Abbrevation" onChange={(e) => setshortName(e.target.value)}
                                                value={shortName}
                                                name="AbbrevEn"
                                                type="text"
                                            />
                                        </Form.Item>


                                        <span className="spanForm"> |</span>

                                    </div>

                                    <div className="col-sm">


                                        <Form.Item
                                            name="AbbrevAr"
                                            label="اختصار"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Abbreviation (Arabic) is required',
                                                },
                                            ]}
                                            style={{ direction: 'rtl' }}
                                        >
                                            <Input placeholder="اختصار" name="AbbrevAr"
                                                onChange={(e) => setshortNameAr(e.target.value)}
                                                value={shortNameAr}
                                                type="text"

                                            />
                                        </Form.Item>


                                    </div>
                                </div>
                                <div className="row mainrow">
                                    <div className="col-sm">


                                        <Form.Item
                                            name="shortCode"
                                            label="Short Code"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "short Code is required"
                                                },
                                            ]}
                                        >
                                            <Input
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                onChange={(e) =>
                                                    setState({ ...state1, shortCode: e.target.value })
                                                }
                                                value={state1.shortCode}
                                                onPaste={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                placeholder="Short Code"
                                                maxLength="5"
                                            />
                                        </Form.Item>

                                    </div>
                                </div>

                                <div className="ButtonSection" style={{ justifyContent: "end" }}>


                                    <button
                                        type="submit"
                                        className="SubmitButton"
                                        disabled={isLoading}
                                    >
                                                            Save

                                    </button>
                                </div>

                            </Form>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TrackCondition;
