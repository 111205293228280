import React, { Fragment, useState, useEffect } from "react";
import swal from "sweetalert";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { Form, Input } from 'antd';
import { fetchfinalpositionshortcode } from "../../redux/getShortCode/getfinalpositionshortcode";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../Components/Common/Loader";

const FinalPosiiton = () => {

  const dispatch = useDispatch();

  const { data: finalpositionshortcode } = useSelector(
    (state) => state.finalpositionshortcode
  );
  const [registeration, setregisteration] = useState({
    NameEn: "",
    NameAr: "",
    Rank: "",
    shortCode: "",
  });

  const [isLoading, setisLoading] = useState(false);
  const [state1, setState] = useState({
    shortCode: "",
  });
  //----------------------------shortcode -------------------------//
  useEffect(() => {
    if (finalpositionshortcode) {
      setState({
        shortCode:
          finalpositionshortcode.length === 0
            ? 10
            : finalpositionshortcode[0].maxshortCode + 1,
      });
    } else {
      setState.shortCode("9");
    }
  }, [finalpositionshortcode]);

  useEffect(() => {
    dispatch(fetchfinalpositionshortcode());
  }, [dispatch]);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setregisteration({ ...registeration, [name]: value });
  };



  //----------------------------submit -------------------------//
  const submit = async () => {

    setisLoading(true);
    try {
      const formData = new FormData();
      formData.append("NameEn", registeration.NameEn);
      formData.append("NameAr", registeration.NameAr);
      formData.append("Rank", registeration.Rank);
      formData.append("shortCode", state1.shortCode);

      await axios.post(`${window.env.API_URL}/uploadFinalPosition`, formData);
      swal({
        title: "Success!",
        text: "Data has been added Successfully ",
        icon: "success",
        button: "OK",
      });
      if (pathname === "/finalposition") {
        history("/finalpositionlist");
      }
      setisLoading(false);
    } catch (error) {
      const err = error.response.data.message[0];
      const err1 = error.response.data.message[1];
      const err2 = error.response.data.message[2];


      swal({
        title: "Error!",
        text: err,
        err1,
        err2,
        icon: "error",
        button: "OK",
      });
      setisLoading(false);
    }
  };

  const history = useNavigate();
  const { pathname } = useLocation();

  return (
    <Fragment>
      <div className="page">
        <div className="rightsidedata">
          <div
            style={{
              marginTop: "30px",
            }}
          >
            <div className="Headers">Final Position</div>
            {isLoading ? <Loader /> : <div className="form">
              <Form onFinish={submit} fields={[{

                name: "shortCode",
                value: state1.shortCode

              }]}>
                <div className="row mainrow">
                  <div className="col-sm">
                    <Form.Item
                      name="NameEn"
                      label="Name"
                      rules={[
                        {
                          required: true,
                          message: 'Name (English) is required',
                        },
                      ]}
                    >
                      <Input placeholder="Name" onChange={handleChange}
                        value={registeration.NameEn} name="NameEn" />
                    </Form.Item>

                    <span className="spanForm"> |</span>

                  </div>

                  <div className="col-sm">
                    <Form.Item
                      name="NameAr"
                      label="اسم"
                      rules={[
                        {
                          required: true,
                          message: 'اسم (Arabic) is required',
                        },
                      ]}
                      style={{ direction: 'rtl' }}
                    >
                      <Input placeholder="اسم" name="NameAr"
                        onChange={handleChange}
                        value={registeration.NameAr}
                        type="text"
                      />
                    </Form.Item>

                  </div>
                </div>

                <div className="row mainrow">
                  <div className="col-sm">


                    <Form.Item
                      label="Rank"
                      name="Rank"
                      rules={[
                        {
                          required: true,
                          message: 'Rank is required',
                        },
                      ]}
                    >
                      <Input
                        name="Rank"
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        onChange={handleChange}
                        value={registeration.Rank}
                        onPaste={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        placeholder="Rank"
                        maxLength="5"
                      />
                    </Form.Item>


                  </div>
                </div>
                <div className="row mainrow">
                  <div className="col-sm">
                    <Form.Item
                      name="shortCode"
                      label="Short Code"
                      rules={[
                        {
                          required: true,
                          message: 'Short Code  is required',
                        },
                      ]}
                    >
                      <Input
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        onPaste={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        placeholder="Short Code"
                        maxLength="5"
                      />
                    </Form.Item>
                  </div>
                </div>
                <div
                  className="ButtonSection "
                  style={{ justifyContent: "end" }}
                >
                  <button
                    Name="submit"
                    className="SubmitButton"
                    disabled={isLoading}
                  >
                   Save
                  </button>
                </div>
              </Form>
            </div>}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default FinalPosiiton;
