import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import { fetchNationalityList } from "../../../redux/getDropDownReducer/getNationalityList";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import { Form, Input, DatePicker, Select } from 'antd';
import { AiOutlineReload } from "react-icons/ai";
import { Modal } from "react-bootstrap";

import { ImCross } from "react-icons/im";

import NationalityPopup from "../Nationality";
import Loader from "../../../Components/Common/Loader";
import { fetchGetOwnerMaxShortCode } from "../../../redux/getShortCode/getownershortcode";

const OwnerForm = ({ setShowActiveOwner }) => {
  //for error

  const { Option } = Select;
  const [isLoading, setisLoading] = useState(false);

  const [Value, setValue] = useState()


  const dispatch = useDispatch();
  const history = useNavigate();
  const { pathname } = useLocation();

  const { data: NationalityList } = useSelector(
    (state) => state.NationalityList
  );

  //----------------------------dropdow options -------------------------//


  const [NameEn, setNameEn] = useState("");
  const [NameAr, setNameAr] = useState();
  const [TitleEn, setTitleEn] = useState("");
  const [TitleAr, setTitleAr] = useState("");
  const [ShortEn, setShortEn] = useState("");

  const [ShortAr, setShortAr] = useState("");
  const [NationalityID, setNationalityID] = useState("");
  const [RegistrationDate, setRegistrationDate] = useState("");
  const [Ownerimage, setOwnerimage] = useState();

  const [preview, setPreview] = useState();
  const [showActivenationality, setShowNationality] = useState(false);

  const handleCloseActivenationality = () => setShowNationality(false);
  const { data: GetOwnerMaxShortCode } = useSelector((state) => state.GetOwnerMaxShortCode)


  const handleShowActivenationality = async () => {
    await setShowNationality(true);
  };

  const [state1, setState] = useState({
    shortCode: '',
  });
  //----------------------------shortcode -------------------------//
  useEffect(() => {
    if (GetOwnerMaxShortCode) {
      setState({
        shortCode: GetOwnerMaxShortCode.length === 0 ? 10 : GetOwnerMaxShortCode[0].maxshortCode + 1,
      });
    } else {
    }
  }, [GetOwnerMaxShortCode]);


  // useEffect(() => {
  //   dispatch(fetchGetOwnerMaxShortCode())
  // },[dispatch])


  const handleChange = async (value) => {
    await setNationalityID(value)
    setValue(value)
  };

  useEffect(() => {

    dispatch(fetchNationalityList({ Value }));

  }, [dispatch]);
  //----------------------------submit-------------------------//
  const submit = async (fieldsValue) => {






    setisLoading(true);
    try {

      const formData = new FormData();
      formData.append("Ownerimage", Ownerimage);
      formData.append("NameEn", NameEn);
      formData.append("NameAr", NameAr);
      formData.append("TitleEn", TitleEn);
      formData.append("TitleAr", TitleAr);
      formData.append("ShortEn", ShortEn);
      formData.append("ShortAr", ShortAr);
      formData.append("NationalityID", NationalityID);
      formData.append("RegistrationDate", RegistrationDate);
      formData.append("shortCode", state1?.shortCode);

      const response = await axios.post(
        `${window.env.API_URL}/createowner`,
        formData
      );
      swal({
        title: "Success!",
        text: "Data has been added successfully",
        icon: "success",
        button: "OK",
      });
      const OwnerId = response.data.data._id;

      if (pathname === "/ownerform") {
        history("/ownerSilkColor", {
          state: {
            OwnerId: OwnerId,
          },
        });
      }

      setShowActiveOwner(false)
      setisLoading(false);

    } catch (error) {
      const err = error.response.data.message[0];
      const err1 = error.response.data.message[1];
      const err2 = error.response.data.message[2];


      swal({
        title: "Error!",
        text: err,
        err1,
        err2,
        icon: "error",
        button: "OK",
      });
      setisLoading(false);
    }







  };

  const handleSearch = async (value) => {
    try {
      dispatch(fetchNationalityList({ value }))

    } catch (error) {

    }
  };


  const handleDateChange = (date, dateString) => {
    setRegistrationDate(dateString);
  };
  //----------------------------Image Preview -------------------------//
  useEffect(() => {
    if (!Ownerimage) {
      setPreview(undefined);
      return;
    }
    if (Ownerimage.name.match(/\.(gif)$/)) {
      setOwnerimage("");
      setPreview(undefined);
      document.getElementById("file").value = "";
      swal({
        title: "Error!",
        text: "Invalid Format ",
        icon: "error",
        button: "OK",
      });
    }

    const objectUrl = URL.createObjectURL(Ownerimage);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [Ownerimage]);

  const FetchNew = () => {
    dispatch(fetchNationalityList());
  };
  const onSelectFile = (e) => {
    setOwnerimage(e.target.files[0]);
  };

  const handlePreview = () => {
    setOwnerimage();
    document.getElementById("file").value = "";
  };
  //----------------------------input Validation -------------------------//

  return (
    <>
      <div className="page">
        <div className="rightsidedata">
          <div
            style={{
              marginTop: "30px",
            }}
          >
            <div className="Headers">Create Owner</div>
            {isLoading ? <Loader /> : <div className="form">
              <Form onFinish={submit} name="time_related_controls">
                <div className="row mainrow">
                  <div className="col-sm">




                    <Form.Item
                      name="NameEn"
                      label="Name"
                      rules={[
                        {
                          required: true,
                          message: 'Name (English) is required',
                        },
                      ]}
                    >
                      <Input placeholder="Name" onChange={(e) => setNameEn(e.target.value)}
                        value={NameEn} name="NameEn" />
                    </Form.Item>








                    <span className="spanForm spacer"> |</span>

                  </div>

                  <div className="col-sm">
                    <Form.Item
                      name="NameAr"
                      label="اسم"
                      rules={[
                        {
                          required: true,
                          message: 'Name (Arabic) is required',
                        },
                      ]}
                      style={{ direction: 'rtl' }}
                    >
                      <Input placeholder="اسم" onChange={(e) => setNameAr(e.target.value)}
                        name="NameAr"
                        value={NameAr} />
                    </Form.Item>

                  </div>
                </div>
                <div className="row mainrow">
                  <div className="col-sm">





                    <Form.Item
                      name="Title"
                      label="Title"
                      rules={[
                        {
                          required: true,
                          message: 'Title (English) is required',
                        },
                      ]}
                    >
                      <Input placeholder="Title" onChange={(e) => setTitleEn(e.target.value)}
                        value={TitleEn} name="Title" />
                    </Form.Item>








                    <span className="spanForm spacer" > |</span>
                    <span className={Error.status ? "success" : "error"}>
                      {Error.message}
                    </span>
                  </div>

                  <div className="col-sm">



                    <Form.Item
                      name="TitleAr"
                      label="عنوان"
                      rules={[
                        {
                          required: true,
                          message: 'Title (Arabic) is required',
                        },
                      ]}
                      style={{ direction: 'rtl' }}
                    >
                      <Input placeholder="عنوان" onChange={(e) => setTitleAr(e.target.value)}
                        name="TitleAr"
                        value={TitleAr} />
                    </Form.Item>









                  </div>
                </div>

                <div className="row mainrow">
                  <div className="col-sm">






                    <Form.Item
                      name="ShortName"
                      label="Short Name"
                      rules={[
                        {
                          required: true,
                          message: 'Short Name (English) is required',
                        },
                      ]}
                    >
                      <Input placeholder="Short Name" onChange={(e) => setShortEn(e.target.value)}
                        value={ShortEn} name="ShortName" />
                    </Form.Item>







                    <span className="spanForm spacer"> |</span>

                  </div>




                  <div className="col-sm">


                    <Form.Item
                      name="ShortNameAr"
                      label="اسم قصير"
                      rules={[
                        {
                          required: true,
                          message: 'Short Name (Arabic) is required',
                        },
                      ]}
                      style={{ direction: 'rtl' }}
                    >
                      <Input placeholder="اسم قصير" onChange={(e) => setShortAr(e.target.value)}
                        name="ShortNameAr"
                        value={ShortAr} />
                    </Form.Item>














                  </div>
                </div>
                <div className="row mainrow">
                  <div className="col-sm">
                    <Form.Item name="date-picker" label="Registeration Date" rules={[
                      {
                        required: true,
                        message: 'Registeration Date is required',
                      },
                    ]}>


                      <DatePicker value={RegistrationDate} onChange={handleDateChange} format="DD-MM-YYYY" />

                    </Form.Item>



                  </div>


                </div>
                <div className="row mainrow">
                  <div className="col-sm">


                    <Form.Item

                      name="select"
                      label="Nationality"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: 'Please select your Nationality!',
                        },
                      ]}
                    >
                      <Select
                        value={NationalityID}
                        filterOption={false}
                        onChange={handleChange}
                        onSearch={handleSearch}
                        showSearch placeholder="Please select a Nationality" >

                        {
                          NationalityList?.map((item) => (


                            <Option value={item._id}>{item.NameEn} ({item.NameAr})</Option>

                          ))
                        }
                      </Select>
                    </Form.Item>
                 
                    <span className="spanForm selectSpacer">
                      <OverlayTrigger
                        overlay={<Tooltip id={`tooltip-top`}>Add more</Tooltip>}
                      >
                        <span
                          className="addmore"
                          onClick={handleShowActivenationality}
                        >
                          +
                        </span>
                      </OverlayTrigger>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id={`tooltip-top`}>Fetch New</Tooltip>
                        }
                      >
                        <span className="addmore" onClick={FetchNew}>
                          <AiOutlineReload />
                        </span>
                      </OverlayTrigger>{" "}
                    </span>

                  </div>
                </div>


                <div className="row mainrow">
                <div className="col-sm">
                  <Form.Item
                    name="shortCode"
                    label="Short Code"
                    rules={[
                      {
                        required: true,
                        message: "short Code is required"
                      },
                    ]}
                  >
                    <Input
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(e) =>
                        setState({ ...state1, shortCode: e.target.value })
                      }
                      value={state1.shortCode}
                      onPaste={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      placeholder="Short Code"
                      maxLength="7"
                    />
                  </Form.Item>


                </div>
              </div>

                <div className="SelectOwnerimage">
                  <div className="ButtonSection">
                    <div>
                      <label className="Multipleownerlabel">
                        Select Owner image
                      </label>

                      <input
                        type="file"
                        onChange={onSelectFile}
                        className="formInput"
                        id="file"
                      />
                      {Ownerimage && (
                        <>
                          <ImCross
                            onClick={handlePreview}
                            className="crossIcon"
                          />
                          <img src={preview} className="PreviewImage" alt="" />
                        </>
                      )}
                    </div>
                    <div>
                      <button
                        type="submit"
                        className="SubmitButton"
                        disabled={isLoading}
                      >
                        Next
                      </button></div>
                  </div>
                </div>
              </Form>
            </div>}
          </div>
          <div></div>
        </div>
      </div>
      <Modal
        show={showActivenationality}
        onHide={handleCloseActivenationality}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2>Nationality</h2>
        </Modal.Header>
        <Modal.Body>
          <NationalityPopup setShowNationality={setShowNationality} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default OwnerForm;
