import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});

const getTrainerListSlice = createSlice({
    name: 'TrainerList',
    initialState: {
        data: [],
        status: STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchTrainerList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(fetchTrainerList.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.IDLE
            })
            .addCase(fetchTrainerList.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
    }
});

export const { setHorseList, setStatus } = getTrainerListSlice.actions;
export default getTrainerListSlice.reducer;

export const fetchTrainerList = createAsyncThunk('/TrainerListget/fetch', async ({ Value4 }) => {
    const res = await axios.get(`${window.env.API_URL}/TrainerDropDown?NameEn=${Value4 === undefined ? '' : Value4}&limit=50`);
    const TrainerListData = res.data;
    return TrainerListData.data;
})
