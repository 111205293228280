import axios from "axios";
import { createAsyncThunk,createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
    IDLE : 'idle',
    ERROR:'error',
    LOADING: 'loading',
});

const getPointTableSystemDropDownSlice = createSlice({
    name: 'PointTableSystemDropDown',
    initialState: {
        data:[],
        status : STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
        .addCase(fetchPointTableSystemDropDown.pending, (state, action) => {
            state.status = STATUSES.LOADING;
        })
        .addCase(fetchPointTableSystemDropDown.fulfilled, (state, action) => {
            state.data = action.payload;
            state.status = STATUSES.IDLE
        }) 
        .addCase(fetchPointTableSystemDropDown.rejected , (state,action) => {
            state.status = STATUSES.ERROR;
        })
    }
});

export const {setPointTableSystemDropDown , setStatus} = getPointTableSystemDropDownSlice.actions;
export default getPointTableSystemDropDownSlice.reducer;

export const fetchPointTableSystemDropDown = createAsyncThunk('/PointTableSystemDropDownget/fetch', async({id}) => {
    const res = await axios.get(`${window.env.API_URL}/PointTableSystemDropDown/${id}`);
    const PointTableSystemDropDownData = res.data;
    return PointTableSystemDropDownData.data;
})