import React, { useState, Fragment, useEffect } from "react";
import "../../Components/CSS/forms.css";
import axios from "axios";

import { useNavigate } from "react-router-dom";

import swal from "sweetalert";

import { ImCross } from 'react-icons/im';
import { Form, Input } from 'antd';

const EmailImages = () => {

  const history = useNavigate();

  const [NameEn, setNameEn] = useState("");
  const [isLoading, setisLoading] = useState(false);


  const [preview, setPreview] = useState();
  const [image, setImage] = useState();




  const submit = async (event) => {
    setisLoading(true)
    try {
      const formData = new FormData();
      formData.append("image", image);
      formData.append("Title", NameEn);

      await axios.post(
        `${window.env.API_URL}/uploadImagesStorage`,
        formData
      );

      swal({
        title: "Success!",
        text: "Data has been added successfully",
        icon: "success",
        button: "OK",
      });
      history("/email");
      setisLoading(false)
    } catch (error) {
      const err = error.response.data.message[0];


      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
      setisLoading(false)
    }
  };



  const onSelectFile = (e) => {
    setImage(e.target.files[0]);
  };
  useEffect(() => {
    if (!image) {
      setPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(image);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [image]);


  const handlePreview = () => {
    setImage()
    document.getElementById("file").value = ""
  };


  return (
    <Fragment>
      <div className="page">
        <div className="rightsidedata">
          <div
            style={{
              marginTop: "30px",
            }}
          >
            <div className="Headers">Add Email Images</div>
            <div className="form">
              <Form onFinish={submit}>
                <div className="row mainrow">
                  <div className="col-sm">


                    <Form.Item
                      name="NameEn"
                      label="Name"
                      rules={[
                        {
                          required: true,
                          message: 'Name  is required',
                        },
                      ]}
                    >
                      <Input placeholder="Name" onChange={(e) => setNameEn(e.target.value)}
                        value={NameEn} name="NameEn" />
                    </Form.Item>









                  </div>


                </div>
                <div className="ButtonSection">
                  <div>
                    <label className="Multipleownerlabel">
                      Select Email image
                    </label>
                    <input
                      type="file"
                      onChange={onSelectFile}
                      className="formInput"
                      id="file"
                    />
                    {image && (
                      <>
                        <ImCross onClick={handlePreview} className="crossIcon" />
                        <img src={preview} className="PreviewImage" alt="" />
                      </>
                    )}
                  </div>
                  <div>

                    <button type="submit" className="SubmitButton" disabled={isLoading}>
                      Add Images
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>

    </Fragment>
  );
};

export default EmailImages;
