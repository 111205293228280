import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import TextInputValidation from "../../utils/TextInputValidation";
import { ImCross } from 'react-icons/im';
import Loader from "../../Components/Common/Loader";

const NotificationForm = () => {
  //for error
  const [Error, setError] = useState("");
  const [ErrorAr, setErrorAr] = useState("");
  const [ErrorURL, setErrorURL] = useState("");
  const [isLoading, setisLoading] = useState(false);

  const history = useNavigate();
  const [TitleEn, setTitleEn] = useState("");
  const [TitleAr, setTitleAr] = useState("");
  const [TextAr, setTextAr] = useState("");
  const [TextEn, setTextEn] = useState("");
  const [Url, setUrl] = useState();
  const [image, setImage] = useState();
  const [preview, setPreview] = useState();


  //error Function
  const data1 = (JSON.stringify(
    TextInputValidation(
      "en",
      TitleEn,
      "Notification Title"
    )
  ));

  const obj = JSON.parse(data1);
  const data2 = (JSON.stringify(
    TextInputValidation(
      "ar",
      TitleAr,
      "Notification Title Arabic"
    )
  ));
  const objAr = JSON.parse(data2);
  //----------------------------submit -------------------------//
  const submit = async (event) => {
    event.preventDefault();
    setisLoading(true)
    try {
      const formData = new FormData();
      formData.append("image", image);
      formData.append("NotificationTemplateTextEn", Url);
      formData.append("NotificationTemplateTitleEn", TitleAr);
      formData.append("NotificationTemplateTitleAr", TitleEn);
      formData.append("NotificationTemplateTextAr", TitleEn);
      formData.append("NotificationTemplateName", TitleEn);
      await axios.post(
        `${window.env.API_URL}/uploadPushNotificationTemplate`,
        formData
      );
      history("/notification");
      swal({
        title: "Success!",
        text: "Data has been added successfully ",
        icon: "success",
        button: "OK",
      });
      setisLoading(false)
    } catch (error) {
      const err = error.response.data.message;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
      setisLoading(false)
    }
  };
  //----------------------------image preview -------------------------//
  useEffect(() => {
    if (!image) {
      setPreview(undefined);
      return;
    }
    if (image.name.match(/\.(gif)$/)) {
      setImage("");
      setPreview(undefined);
      document.getElementById("file").value = "";
      swal({
        title: "Error!",
        text: "Invalid Format ",
        icon: "error",
        button: "OK",
      });
    }
    const objectUrl = URL.createObjectURL(image);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [image]);

  const onSelectFile = (e) => {
    setImage(e.target.files[0]);
  };
  //----------------------------remove preview -------------------------//
  const handlePreview = () => {
    setImage()
    document.getElementById("file").value = ""
  };

  return (
    <>
      <div className="page">
        <div className="rightsidedata">
          <div
            style={{
              marginTop: "30px",
            }}
          >
            <div className="Headers">Add Notification</div>
            {isLoading ? <Loader /> : <div className="form">
              <form onSubmit={submit}>
              <div className="row mainrow">
              <div className="col-sm">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Notification Name"
                      className="mb-3"
                      onChange={(e) => setUrl(e.target.value)}
                      name="Name"
                      value={Url}
                      onBlur={() =>
                        setError(obj)

                      }
                    >
                      <Form.Control type="text" placeholder="Notification Text" required />
                    </FloatingLabel>

                    {/* <span className="spanForm"> |</span> */}
                    {/* <span className={Error.status ? 'success' : 'error'}
                    >{Error.message}</span> */}
                  </div>


                </div>
                <div className="row mainrow">
                  <div className="col-sm">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Notification Title"
                      className="mb-3"
                      onChange={(e) => setTitleEn(e.target.value)}
                      name="Name"
                      value={TitleEn}
                      onBlur={() =>
                        setError(obj)

                      }
                    >
                      <Form.Control type="text" placeholder="Notification Text" required />
                    </FloatingLabel>

                    <span className="spanForm"> |</span>
                    {/* <span className={Error.status ? 'success' : 'error'}
                    >{Error.message}</span> */}
                  </div>

                  <div className="col-sm">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="عنوان الإخطار                      "
                      className="mb-3 floatingInputAr"
                      onChange={(e) => setTitleAr(e.target.value)}
                      name="Name"
                      value={TitleAr}
                      onBlur={() =>
                        setErrorAr(objAr)

                      }
                      style={{ direction: "rtl" }}
                    >
                      <Form.Control type="text" placeholder="Notification Template Text" required />
                    </FloatingLabel>
                    {/* <span className={ErrorAr.status ? 'successAr' : 'errorAr'} >{ErrorAr.message}</span> */}
                  </div>
                </div>
                <div className="row mainrow">
                  <div className="col-sm">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Notification Text"
                      className="mb-3"
                      onChange={(e) => setTextEn(e.target.value)}
                      name="Name"
                      value={TextEn}
                      onBlur={() =>
                        setError(obj)

                      }
                    >
                      <Form.Control type="text" placeholder="Notification Text" required />
                    </FloatingLabel>

                    <span className="spanForm"> |</span>
                    {/* <span className={Error.status ? 'success' : 'error'}
                    >{Error.message}</span> */}
                  </div>

                  <div className="col-sm">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="نص الإخطار                      "
                      className="mb-3 floatingInputAr"
                      onChange={(e) => setTextAr(e.target.value)}
                      name="Name"
                      value={TextAr}
                      onBlur={() =>
                        setErrorAr(objAr)

                      }
                      style={{ direction: "rtl" }}
                    >
                      <Form.Control type="text" placeholder="Notification Template Text" required />
                    </FloatingLabel>
                    {/* <span className={ErrorAr.status ? 'successAr' : 'errorAr'} >{ErrorAr.message}</span> */}
                  </div>
                </div>

                

                <div className="ButtonSection">
                  <div>
                    <label className="Multipleownerlabel">
                      Select Notification image
                    </label>
                    <input
                      type="file"
                      onChange={onSelectFile}
                      className="formInput"
                      id="file"
                    />
                    {image && (
                      <>
                        <ImCross onClick={handlePreview} className="crossIcon" />
                        <img src={preview} className="PreviewImage" alt="" />
                      </>
                    )}
                  </div>
                  <div>
                    <button
                      type="submit"
                      onClick={submit}
                      className="SubmitButton"
                      disabled={isLoading}
                    >
                      Add Notification
                    </button>
                  </div>
                </div>

              </form>
            </div>}
          </div>
        </div>
      </div>
    </>
  );
};

export default NotificationForm;
