import React, { useState, useEffect } from "react";
import "../../Components/CSS/forms.css";
import { useNavigate, useLocation } from "react-router-dom";
import swal from "sweetalert";
import axios from "axios";
import { ImCross } from "react-icons/im";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
const EditAds = () => {
  const history = useNavigate();
  const { state } = useLocation();
  const [isLoading, setisLoading] = useState(false);
  const { notoficationid } = state;

  console.log(notoficationid,'123')

  const [image, setImage] = useState();
  const [preview, setPreview] = useState();
  //----------------------------get data -------------------------//
  const [state1, setState] = useState({
    NotificationTemplateName: "",
    NotificationTemplateTitleEn: "",
    NotificationTemplateTitleAr: "",
    NotificationTemplateTextEn: "",
    NotificationTemplateTextAr: "",
    NotificationImage: image,
  });

  useEffect(() => {
    if (notoficationid) {
      setState({
        NotificationTemplateName: notoficationid.NotificationTemplateName,
        NotificationTemplateTitleEn: notoficationid.NotificationTemplateTitleEn,
        NotificationTemplateTitleAr: notoficationid.NotificationTemplateTitleAr,
        NotificationTemplateTextEn: notoficationid.NotificationTemplateTextEn,
        NotificationTemplateTextAr: notoficationid.NotificationTemplateTextAr,
      });
    } else {
      alert("No Data");
    }
  }, [notoficationid]);
  const [isLoading1, setisLoading1] = useState(false);

  useEffect(() => {
    if (image === undefined) {
      setPreview(notoficationid.NotificationImage);
      return;
    }
    const objectUrl = URL.createObjectURL(image);
    setPreview(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [notoficationid.NotificationImage, image]);

  const fileSelected = (event) => {
    const image = event.target.files[0];
    setImage(image);
  };
  //----------------------------remove preview -------------------------//
  const handlePreview = () => {
    setImage();
    setPreview();
    setisLoading1(true)
    document.getElementById("file").value = "";
  };
  //----------------------------submit -------------------------//s

  const submit = async (event) => {
    event.preventDefault();
    setisLoading(true)
    try {
      const formData = new FormData();
      formData.append("image", image === undefined ? notoficationid.NotificationImage : image);

      formData.append("NotificationTemplateTextEn",  state1.NotificationTemplateTextEn);
      formData.append("NotificationTemplateTitleEn",  state1.NotificationTemplateTitleEn);
      formData.append("NotificationTemplateTitleAr",  state1.NotificationTemplateTitleAr);
      formData.append("NotificationTemplateTextAr",  state1.NotificationTemplateTextAr);
      formData.append("NotificationTemplateName",  state1.NotificationTemplateName);
      if(isLoading1 === true && image === undefined){
        formData.append("deleteexisitingimage",isLoading1)
      }

      await axios.put(
        `${window.env.API_URL}/updatePushNotificationTemplate/${notoficationid._id}`,
        formData
      );
      history("/notification");
      swal({
        title: "Success!",
        text: "Data has been added successfully ",
        icon: "success",
        button: "OK",
      });
      setisLoading(false)
    } catch (error) {
      const err = error.response.data.message;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
      setisLoading(false)
    }
  };
  return (
    <>
      <div className="page">
        <div className="rightsidedata">
          <div
            style={{
              marginTop: "30px",
            }}
          >
            <div className="Headers">Edit Notification</div>
            <div className="form">
              <form onSubmit={submit}>

              <div className="row mainrow">
                  <div className="col-sm">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Notification Name"
                      className="mb-3"
                      onChange={(e) =>
                        setState({ ...state1, NotificationTemplateName: e.target.value })
                      }
                      readOnly
                    >
                      <Form.Control
                        type="text"
                        placeholder="Description"
                        value={state1.NotificationTemplateName}
                        readOnly
                      />
                    </FloatingLabel>
                  </div>
                </div>


                <div className="row mainrow">
                  <div className="col-sm">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Notification Title"
                      className="mb-3"
                      onChange={(e) =>
                        setState({ ...state1, NotificationTemplateTitleEn: e.target.value })
                      }
                      
                    >
                      <Form.Control
                        type="text"
                        placeholder="Details"
                        
                        value={state1.NotificationTemplateTitleEn}
                      />
                    </FloatingLabel>

                    <span className="spanForm"> |</span>
                  </div>

                  <div className="col-sm">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="عنوان الإخطار                      "
                      className="mb-3 floatingInputAr"
                      onChange={(e) =>
                        setState({ ...state1, NotificationTemplateTitleAr: e.target.value })
                      }
                      style={{ direction: "rtl" }}
                    >
                      <Form.Control
                        type="text"
                        placeholder="Details"
                        value={state1.NotificationTemplateTitleAr}
                      />
                    </FloatingLabel>
                  </div>
                </div>

                <div className="row mainrow">
                  <div className="col-sm">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Notification Text"
                      className="mb-3"
                      onChange={(e) =>
                        setState({ ...state1, NotificationTemplateTextEn: e.target.value })
                      }
                      
                    >
                      <Form.Control
                        type="text"
                        placeholder="Details"
                        
                        value={state1.NotificationTemplateTextEn}
                      />
                    </FloatingLabel>

                    <span className="spanForm"> |</span>
                  </div>

                  <div className="col-sm">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="نص الإخطار                      "
                      className="mb-3 floatingInputAr"
                      onChange={(e) =>
                        setState({ ...state1, NotificationTemplateTextAr: e.target.value })
                      }
                      style={{ direction: "rtl" }}
                    >
                      <Form.Control
                        type="text"
                        placeholder="Details"
                        value={state1.NotificationTemplateTextAr}
                      />
                    </FloatingLabel>
                  </div>
                </div>

               

                <div className="ButtonSection">
                  <div>
                    <label className="Multipleownerlabel">
                      Select Notification image
                    </label><br/>
                    <input
                      type="file"
                      onChange={fileSelected}
                      className="formInput"
                      id="file"
                    />
                    {preview && (
                      <div>
                        <ImCross
                          onClick={handlePreview}
                          className="crossIcon"
                        />
                        <img src={preview} className="PreviewImage" alt="" />
                      </div>
                    )}
                  </div>
                      <div>
                  <button
                    type="submit"
                    className="SubmitButton"
                    disabled={isLoading}
                  >
                    Update
                  </button></div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditAds;
