import React, { useState, useEffect } from "react";
import "../../../Components/CSS/forms.css";
import { useNavigate, useLocation } from "react-router-dom";
import swal from "sweetalert";
import axios from "axios";

import { fetchracecourse } from "../../../redux/getReducer/getRaceCourseSlice";
import { useSelector, useDispatch } from "react-redux";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { AiOutlineReload } from "react-icons/ai";
import { Modal } from "react-bootstrap";
import TrackConditionPopup from "../../PostTable/TrackCondition";
import Racename from "../../PostTable/Racenameform";
import MeetingTypePopUp from "../../PostTable/MeetingType";
import RaceTypePopup from "../../PostTable/Racetypeform";
import TrackLengthPopup from "../../PostTable/Tracklengthform";
import GroundTypePopup from "../../PostTable/GroundType";
import RaceKindPopup from "../../PostTable/RaceKind";
import RaceCoursePopup from "../../PostTable/RaceCourseForm";
import JockeyPopup from "../../PostTable/JockeyForm";
import SponsorPopup from "../../PostTable/SponsorForm";
import HorseKindPopup from "../../PostTable/Horsekindform";
import { fetchSponsorDropdown } from "../../../redux/getDropDownReducer/getSponsor";
import { fetchMeeting } from "../../../redux/getReducer/getMeeting";
import { fetchRaceTypeDropdown } from "../../../redux/getDropDownReducer/getRaceType";
import { fetchTrackConditionDropdown } from "../../../redux/getDropDownReducer/getTrackConditionDropDown";
import { fetchRaceName } from "../../../redux/getReducer/getRaceName";
import { fetchTrackLength } from "../../../redux/getReducer/getTracklength";
import { fetchRaceKind } from "../../../redux/getReducer/getRaceKind";
import { fetchgroundtype } from "../../../redux/getReducer/getGroundType";
import { fetchSearchRaceName } from "../../../redux/getReducer/getSearchRaceName";
import { fetchcurrency } from "../../../redux/getReducer/getCurrency";
import { fetchHorseKind } from "../../../redux/getReducer/getHorseKind";
import Loader from "../../../Components/Common/Loader";
import { Input, Form, Select } from "antd";
import { fetchRaceType } from "../../../redux/getReducer/getRacetype";
import { fetchTrackLengthwithRaceCourse } from "../../../redux/getDropDownReducer/getTracklengthwithRacecourse";
import { fetchRaceKindDropdown } from "../../../redux/getDropDownReducer/getRaceKind";
import { fetchEditSingleRace } from "../../../redux/getReducer/getSingleRace";

const RaceStatuss = [
  { id: "1", value: "Cancelled", label: "Cancel" },
  { id: "2", value: "Due", label: "Due" },
  { id: "2", value: "Live", label: "Live" },
  { id: "2", value: "Completed", label: "End" },
];
const raceStatus = [
  {
    id: "1",
    value: "cancelled",
    label: "Cancel",
  },
  {
    id: "2",
    value: "Due",
    label: "Due",
  },
  {
    id: "3",
    value: "Live",
    label: "Live",
  },
  {
    id: "3",
    value: "Completed",
    label: "End",
  },
];

const WeatherTypes = [
  {
    id: "1",
    value: "Sunny",
    label: (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <p>Sunny</p>
        <p>مشمس</p>
      </div>
    ),
  },
  {
    id: "2",
    value: "Cloudy",
    label: (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <p>Cloudy</p>
        <p>غائم</p>
      </div>
    ),
  },
];

const NewsForm = () => {
  const history = useNavigate();
  const [form] = Form.useForm();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const { data: racecourse } = useSelector((state) => state.racecourse);
  const { data: sponsordropdown } = useSelector(
    (state) => state.sponsordropdown
  );
  const { data: meeting } = useSelector((state) => state.meeting);
  const { data: racetypedropdown } = useSelector(
    (state) => state.racetypedropdown
  );
  const { data: RaceName } = useSelector((state) => state.RaceName);
  const { data: trackLength } = useSelector((state) => state.trackLength);
  const { data: raceKinds } = useSelector((state) => state.raceKinds);
  const { data: racekinddropdown } = useSelector(
    (state) => state.racekinddropdown
  );
  const [isLoading, setisLoading] = useState(false);
  const { data: TrackConditiondropdown } = useSelector(
    (state) => state.TrackConditiondropdown
  );
  const { data: currency } = useSelector((state) => state.currency);
  const { data: EditSingleRace } = useSelector((state) => state.EditSingleRace);
  const { data: HorseKind } = useSelector((state) => state.HorseKind);
  const [isSubscribed, setIsSubscribed] = useState(true);

  const [FirstPrice, setFirstPrice] = useState("");
  const [SecondPrice, setSecondPrice] = useState("");
  const [ThirdPrice, setThirdPrice] = useState("");
  const [FourthPrice, setFourthPrice] = useState("");
  const [FifthPrice, setFifthPrice] = useState("");
  const [SixthPrice, setSixthPrice] = useState("");
  const [showCalculated, setshowCalculated] = useState(false);
  const { fullraceid } = state;
  const [Day, setDay] = useState("");
  const [WeatherType, setWeatherType] = useState("");
  const [Currency, setCurrency] = useState("");
  const [RaceStatus, setRaceStatus] = useState("");
  const [RaceCourse, setRaceCourse] = useState("");
  const [Sponsor, setSponsor] = useState("");
  const [MeetingType, setMeetingType] = useState("");
  const [RaceNameEn, setRaceNameEn] = useState("");
  const [TrackCondition, setTrackCondition] = useState("");
  const [HorsesKind, sethorsesKind] = useState("");
  const [RaceKind, setRaceKind] = useState("");
  const [RaceTyp, setRaceType] = useState("");

  const id1 = fullraceid?._id;

  const [showhorseKind, setshowhorseKind] = useState(false);

  const [TrackLength, setTrackLength] = useState("");
  const { Option } = Select;

  useEffect(() => {
    dispatch(fetchEditSingleRace({ id1 }));
  }, [id1]);

  const weatherOptions = [
    { id: 1, value: "Sunny", label: "Sunny" },
    { id: 2, value: "Cloudy", label: "Cloudy" },
  ];
  let racecourses =
    racecourse === undefined ? (
      <></>
    ) : (
      racecourse.map(function (item) {
        return {
          id: item._id,
          value: item.TrackNameEn,
          label: (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <p>{item.TrackNameEn}</p>
              <p>{item.TrackNameAr}</p>
            </div>
          ),
        };
      })
    );

  let trackconditionTable =
    TrackConditiondropdown === undefined ? (
      <></>
    ) : (
      TrackConditiondropdown.map(function (item) {
        return {
          id: item._id,
          value: item.NameEn,
          label: (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <p>{item.NameEn}</p>
              <p>{item.NameAr}</p>
            </div>
          ),
        };
      })
    );

  let currencyoption =
    currency === undefined ? (
      <></>
    ) : (
      currency.map(function (item) {
        return {
          id: item._id,
          value: item.NameEn,
          label: (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <p>{item.NameEn}</p>
              <p>{item.NameAr}</p>
            </div>
          ),
        };
      })
    );
  let Racenameoptions =
    RaceName === undefined ? (
      <></>
    ) : (
      RaceName.map(function (item) {
        return {
          id: item._id,
          value: item.NameEn,
          label: (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <p>{item.NameEn}</p>
              <p>{item.NameAr}</p>
            </div>
          ),
        };
      })
    );

  let SponsorForTheRace =
    sponsordropdown === undefined ? (
      <></>
    ) : (
      sponsordropdown.map(function (item) {
        return {
          id: item._id,
          value: item.TitleEn,
          label: (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span
                style={{
                  display: "flex",
                }}
              >
                <img src={item.image} height="30px" width="30px" alt="" />
                <p>{item.TitleEn}</p>
              </span>
              <span
                style={{
                  display: "flex",
                }}
              >
                <img src={item.image} height="30px" width="30px" alt="" />
                <p>{item.TitleAr}</p>
              </span>
            </div>
          ),
        };
      })
    );

  let MeetingTypes =
    meeting === undefined ? (
      <></>
    ) : (
      meeting.map(function (item) {
        return {
          id: item._id,
          value: item.NameEn,
          label: (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <p>{item.NameEn}</p>
              <p>{item.NameAr}</p>
            </div>
          ),
        };
      })
    );
  let horsekindoptions =
    HorseKind === undefined ? (
      <></>
    ) : (
      HorseKind.map(function (item) {
        return {
          id: item._id,
          value: item.NameEn,
          label: (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <p>{item.NameEn}</p>
              <p>{item.NameAr}</p>
            </div>
          ),
        };
      })
    );

  let RaceTypes =
    racetypedropdown === undefined ? (
      <></>
    ) : (
      racetypedropdown.map(function (item) {
        return {
          id: item._id,
          value: item.NameEn,
          label: (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <p>{item.NameEn}</p>
              <p>{item.NameAr}</p>
            </div>
          ),
        };
      })
    );

  let TrackLenght =
    trackLength === undefined ? (
      <></>
    ) : (
      trackLength.map(function (item) {
        return {
          id: item._id,
          value: item.TrackLength,
          label: item.TrackLength,
        };
      })
    );

  let OprtionRaceKind =
    raceKinds === undefined ? (
      <></>
    ) : (
      raceKinds.map(function (item) {
        return {
          id: item._id,
          value: item.NameEn,
          label: (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <p>{item.NameEn}</p>
              <p>{item.NameAr}</p>
            </div>
          ),
        };
      })
    );
  const { data: searchRaceName } = useSelector((state) => state.searchRaceName);
  const { data: RaceType } = useSelector((state) => state.RaceType);
  const { data: tracklengthwithracecourse } = useSelector(
    (state) => state.tracklengthwithracecourse
  );
  const [showName, setShowName] = useState(false);
  const [showType, setShowType] = useState(false);
  const [showRaceType, setShowRaceType] = useState(false);
  const [showTrackLength, setShowTrackLength] = useState(false);
  const [showGroundType, setShowGroundType] = useState(false);
  const [showRaceKind, setShowRaceKind] = useState(false);
  const [showRaceCourse, setShowRaceCourse] = useState(false);
  const [showJockey, setShowJockey] = useState(false);
  const [showSponsor, setShowSponsor] = useState(false);
  const [showCondition, setshowCondition] = useState(false);

  const [Value1, setValue1] = useState("");
  const [Value2, setValue2] = useState("");
  const [Value3, setValue3] = useState("");
  const [Value4, setValue4] = useState("");
  const [Value5, setValue5] = useState("");
  const [Value6, setValue6] = useState("");
  const [Value7, setValue7] = useState("");

  const handleCloseName = () => setShowName(false);
  const handleCloseType = () => setShowType(false);
  const handleCloseRaceType = () => setShowRaceType(false);
  const handleCloseTrackLength = () => setShowTrackLength(false);
  const handleCloseGroundType = () => setShowGroundType(false);
  const handleCloseRaceKind = () => setShowRaceKind(false);
  const handleCloseRaceCourse = () => setShowRaceCourse(false);
  const handleCloseJockey = () => setShowJockey(false);
  const handleCloseSponsor = () => setShowSponsor(false);
  const handleCloseTrackCondition = () => setshowCondition(false);
  const handleCloseHorseKind = () => setshowhorseKind(false);

  const handleShowTrackCondition = async () => {
    await setshowCondition(true);
  };

  const handleShowName = async () => {
    await setShowName(true);
  };
  const handleShowHorseKind = async () => {
    await setshowhorseKind(true);
  };
  const handleShowType = async () => {
    await setShowType(true);
  };

  const handleShowRaceType = async () => {
    await setShowRaceType(true);
  };
  const handleShowTrackLength = async () => {
    await setShowTrackLength(true);
  };

  const handleShowRaceKind = async () => {
    await setShowRaceKind(true);
  };

  const handleShowRaceCourse = async () => {
    await setShowRaceCourse(true);
  };

  const [StartTime, setStartTime] = useState("");

  const handleShowSponsor = async () => {
    await setShowSponsor(true);
  };
  const SearchCode = "";
  const SearchTitle = "";
  const SearchAge = "";
  const FetchNew = () => {
    dispatch(fetchracecourse({ SearchCode, SearchTitle, SearchAge }));
    dispatch(fetchSponsorDropdown());
    dispatch(fetchMeeting({ SearchCode, SearchTitle, SearchAge }));
    dispatch(fetchRaceTypeDropdown());
    dispatch(fetchRaceName({ SearchCode, SearchTitle, SearchAge }));
    dispatch(fetchTrackLength({ SearchCode, SearchTitle, SearchAge }));
    dispatch(fetchRaceKind({ SearchCode, SearchTitle, SearchAge }));
    dispatch(fetchgroundtype({ SearchCode, SearchTitle, SearchAge }));
    dispatch(fetchTrackConditionDropdown());
    dispatch(fetchHorseKind({ SearchCode, SearchTitle, SearchAge }));
    dispatch(fetchcurrency({ SearchCode, SearchTitle, SearchAge }));
  };

  const [state1, setState] = useState({
    Day: "",
    MeetingCode: "",
    DescriptionEn: "",
    DescriptionAr: "",
    WeatherDegree: "",
    RaceStatus: "",
    RaceNumber: "",
    RaceCourse: "",
    HorseKindinRace: "",
    Sponsor: "",
    MeetingType: "",
    RaceName: "",
    RaceTyp: "",
    TrackLength: "",
    RaceKind: "",
    StartTime: "18:59",
    TotalPrize: "",
    WeatherType: "",
    TrackCondition: "",
    Prize: "",
    sixPrize: "",
  });
  const racecourseid = RaceCourse === null ? "" : RaceCourse;
  const fetchTrackLengthAcctoRaceCourse = async () => {
    dispatch(fetchTrackLengthwithRaceCourse({ racecourseid }));
  };
  useEffect(() => {
    dispatch(fetchTrackLengthwithRaceCourse({ racecourseid }));
  }, [dispatch, racecourseid]);

  const [image, setImage] = useState();
  const [preview, setPreview] = useState();
  const totalprize =
    EditSingleRace.FirstPrice +
      EditSingleRace.SecondPrice +
      EditSingleRace.ThirdPrice +
      EditSingleRace.FourthPrice +
      EditSingleRace.FifthPrice +
      EditSingleRace.SixthPrice || 0;

  useEffect(() => {
    if (EditSingleRace) {
      setState({
        MeetingCode: EditSingleRace.MeetingCode,
        DescriptionEn: EditSingleRace.DescriptionEn,
        DescriptionAr: EditSingleRace.DescriptionAr,
        WeatherDegree: EditSingleRace.WeatherDegree,
        RaceStatus: EditSingleRace.RaceStatus,
        WeatherType: EditSingleRace.WeatherType,
        TrackCondition: EditSingleRace.TrackConditionData?._id,
        Currency: EditSingleRace.CurrencyData?._id,
        RaceCourse: EditSingleRace.RaceCourseData?._id,
        Sponsor: EditSingleRace.SponsorData?._id,
        MeetingType: EditSingleRace.MeetingTypeData?._id,
        RaceName: EditSingleRace.RaceNameModelData?._id,
        RaceKind: EditSingleRace.RaceKindData?._id,
        Day: EditSingleRace?.Day,
        StartTime: EditSingleRace?.StartTime,
        RaceNumber: EditSingleRace?.RaceNumber,
        Prize: totalprize,

        TrackLength: EditSingleRace.TrackLengthData?._id,
        RaceTyp: EditSingleRace.RaceTypeModelData?._id,
        meetingtype: EditSingleRace.MeetingTypeData?.NameEn,
        HorseKindinRace: EditSingleRace.HorseKindinRaceData?._id,
        racename: EditSingleRace.RaceNameModelData?.NameEn,
        racekind: EditSingleRace.RaceKindData?.NameEn,
        RaceTyp: EditSingleRace.RaceTypeModelData?.NameEn,
        RaceStatuss: EditSingleRace?.RaceStatus,
        Racecourses: EditSingleRace.RaceCourseData?.TrackNameEn,
        TracklengthName: EditSingleRace.TrackLengthData?.TrackLength,
        Horseskinds: EditSingleRace.HorseKindinRaceData?.NameEn,
        trackcondition: EditSingleRace.TrackConditionData?.NameEn,
        currency: EditSingleRace.CurrencyData?.NameEn,
        sponsor: EditSingleRace.SponsorData?.TitleEn,
        totalprize: totalprize,
        sixPrize: EditSingleRace.SixthPrice,
      });
      let date123 = new Date(EditSingleRace.StartTime);

      let dateformat = `${date123.getHours()}:${date123.getMinutes()}`;
      if (date123.getMinutes() < 10) {
        dateformat = `${date123.getHours()}:${date123.getMinutes()}0`;
      }

      setStartTime(dateformat);
      let day123 = new Date(EditSingleRace.Day);
      let daydate = day123.getDate();
      if (daydate < 10) {
        daydate = `0${day123.getDate()}`;
      }
      let daymonth = day123.getMonth() + 1;
      if (daymonth < 10) {
        daymonth = `0${day123.getMonth() + 1}`;
      }
      let dayformat = `${day123.getFullYear()}-${daymonth}-${daydate}`;

      setDay(dayformat);
    } else {
    }
  }, [EditSingleRace, totalprize]);
  const fileSelected = (event) => {
    const image = event.target.files[0];
    setImage(image, image);
  };
  useEffect(() => {
    dispatch(fetchracecourse());
    FetchNew();
    if (image === undefined) {
      setPreview(EditSingleRace.image);
      return;
    }
    const objectUrl = URL.createObjectURL(image);
    setPreview(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image]);

  const GoToEditRace = async () => {
    let Timing = new Date(`${Day} ${StartTime}`);
    let Endtiming = new Date(Timing.getTime() + 1000);
    let UTCTime = Timing.toUTCString();
    let UTCDate = Endtiming.toUTCString();
    try {
      const formData = new FormData();
      formData.append("MeetingCode", state1.MeetingCode);
      formData.append("DescriptionEn", state1.DescriptionEn);
      formData.append("DescriptionAr", state1.DescriptionAr);
      formData.append("WeatherDegree", state1.WeatherDegree);
      formData.append("RaceNumber", state1.RaceNumber);
      formData.append("StartTime", StartTime);
      formData.append("Day", Day === "" ? Day : Day);
      formData.append("Timing", UTCTime);
      formData.append("Endtiming", UTCDate);
      formData.append(
        "RaceType",
        RaceTyp === undefined ? state1.RaceTyp : RaceTyp
      );
      formData.append(
        "RaceKind",
        RaceKind === undefined ? state1.RaceKind : RaceKind
      );

      formData.append(
        "WeatherType",
        WeatherType === undefined ? state1.WeatherType : WeatherType
      );

      formData.append(
        "MeetingType",
        MeetingType === undefined ? state1.MeetingType : MeetingType
      );
      formData.append(
        "HorseKindinRace",
        HorsesKind === undefined ? state1.HorseKindinRace : HorsesKind
      );
      formData.append("totalPrize", state1.Prize);
      formData.append(
        "TrackLength",
        TrackLength === undefined ? state1.TrackLength : TrackLength
      );
      formData.append("TrackCondition", state1.TrackCondition);

      formData.append(
        "RaceStatus",
        RaceStatus === undefined ? state1.RaceStatus : RaceStatus
      );
      formData.append(
        "RaceCourse",
        RaceCourse === undefined ? state1.RaceCourse : RaceCourse.id
      );
      formData.append(
        "Sponsor",
        Sponsor === undefined ? state1.Sponsor : Sponsor
      );
      // formData.append(
      //   "TrackLength",
      //   TrackLength.id === undefined ? state1.TrackLength : TrackLength.id
      // );

      formData.append(
        "RaceName",
        RaceNameEn === undefined ? state1.RaceName : RaceNameEn
      );

      await axios.put(
        `${window.env.API_URL}/updaterace/${EditSingleRace._id}`,
        formData
      );
      history("/editracehorse", {
        state: {
          RaceId: EditSingleRace._id,
        },
      });
      swal({
        title: "Success!",
        text: "Data has been Updated successfully ",
        icon: "success",
        button: "OK",
      });
    } catch (error) {
      const err = error.response.data.message;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
    }
  };
  const handleChange = (event) => {
    if (event.target.checked) {
      console.log("✅ Checkbox is checked", isSubscribed);
    } else {
      console.log("⛔️ Checkbox is NOT checked", isSubscribed);
    }
    setIsSubscribed((current) => !current);
  };
  const handleChange6 = (value) => {
    setTrackCondition(value);
    setState({ ...state1, trackcondition: value });
  };

  const calculatePrize = () => {
    setshowCalculated(true);

    if (!isSubscribed) {
      let data1 = 60 / 100;
      let data2 = 20 / 100;
      let data3 = 11 / 100;
      let data4 = 6 / 100;
      let data5 = 3 / 100;

      setFirstPrice(data1 * state1.Prize);
      setSecondPrice(data2 * state1.Prize);
      setThirdPrice(data3 * state1.Prize);
      setFourthPrice(data4 * state1.Prize);
      setSixthPrice("0");
      setFifthPrice(data5 * state1.Prize);
    } else {
      let data1 = 60 / 100;
      let data2 = 20 / 100;
      let data3 = 10 / 100;
      let data4 = 5 / 100;
      let data5 = 3 / 100;
      let data6 = 2 / 100;
      setFirstPrice(data1 * state1.Prize);
      setSecondPrice(data2 * state1.Prize);
      setThirdPrice(data3 * state1.Prize);
      setFourthPrice(data4 * state1.Prize);
      setSixthPrice(data6 * state1.Prize);
      setFifthPrice(data5 * state1.Prize);
    }
  };

  const handleSearch = (value) => {
    setValue2(value);

    setState({ ...state1, raceName: value });
  };
  useEffect(() => {
    dispatch(fetchSearchRaceName({ Value2 }));
  }, [Value2, dispatch]);

  const handleChange8 = (value) => {
    setRaceKind(value);
    setState({ ...state1, racekind: value });
  };

  const handleChange12 = (value) => {
    setWeatherType(value);
    setState({ ...state1, WeatherType: value });
  };

  const handlechangetrack = (value) => {
    setTrackLength(value);
    setState({ ...state1, TracklengthName: value });
  };

  const handleSearch2 = (value) => {
    setValue4(value);
  };

  const handleChange9 = (value) => {
    setRaceStatus(value);
    setState({ ...state1, RaceStatus: value });
  };
  useEffect(() => {
    const value = Value4;
    dispatch(fetchRaceKindDropdown({ Value4 }));
  }, [Value4]);

  const handleChange5 = (value) => {
    sethorsesKind(value);
    setState({ ...state1, Horseskinds: value });
  };
  const handleChange10 = (value) => {
    setRaceCourse(value);
    setState({ ...state1, Racecourses: value });
  };
  const handleChange1 = (value) => {
    setMeetingType(value);
    setState({ ...state1, MeetingType: value });
  };
  const handleChange2 = (value) => {
    setRaceNameEn(value);
    setState({ ...state1, racename: value });
  };
  const handleSearch3 = (value) => {
    setValue5(value);
  };
  const handleChange3 = (value) => {
    setRaceType(value);
    setState({ ...state1, RaceTyp: value });
  };
  useEffect(() => {
    const SearchTitle = Value5;
    dispatch(fetchRaceType({ SearchTitle }));
  }, [Value5, dispatch]);

  const handleSearch4 = (value) => {
    setValue6(value);
  };

  useEffect(() => {
    const Value1 = Value6;
    dispatch(fetchracecourse({ Value1 }));
  }, [Value6, dispatch]);
  useEffect(() => {
    const racecourseid = EditSingleRace.RaceCourseData?._id;
    dispatch(fetchTrackLengthwithRaceCourse({ racecourseid }));
  }, []);

  const handleSearch5 = (value) => {
    setValue7(value);
  };

  useEffect(() => {
    const value = Value7;
    dispatch(fetchTrackConditionDropdown({ value }));
  }, [Value7, dispatch]);
  const handleSearch1 = (value) => {
    setValue3(value);
    setState({ ...state1, sponsor: value });
  };

  useEffect(() => {
    if (state1.sixPrize > 0 && isSubscribed === false) {
      const checkbox = document.getElementById("vehicle1");
      setIsSubscribed(true);
      checkbox.checked = true;
    } else if (isSubscribed === true) {
      const checkbox = document.getElementById("vehicle1");
      setIsSubscribed(false);
      checkbox.checked = false;
    }
  }, [state1]);

  useEffect(() => {
    const value = Value3;
    dispatch(fetchSponsorDropdown({ value }));
  }, [Value3, dispatch]);

  const handleChange7 = (value) => {
    setSponsor(value);

    setState({ ...state1, sponsor: value });
  };

  const handleChange4 = (value) => {
    setCurrency(value);
    setState({ ...state1, currency: value });
  };

  let MyPrize = state1.Prize;
  form.setFieldsValue({
    RaceNumber1: state1?.RaceNumber,
    Prize: state1?.Prize,
    MeetingType: state1?.MeetingType,
    NameAr: state1?.NameAr,
    horseKindName: state1?.horseKindName,
    ShortEn: state1?.ShortNameEn,
    ShortAr: state1?.ShortNameAr,
    Description: state1?.DescriptionEn,
    DescriptionAr: state1?.DescriptionAr,
    RemarksEn: state1?.RemarksEn,
    RemarksAr: state1?.RemarksAr,
    nationalityName: state1?.nationalityName,
    Rating: state1?.Rating,
    colorName: state1?.colorName,
    foal: state1?.Foal,
    horseCode: state1?.horseCode,
    genderName: state1?.genderName,
    damName: state1?.damName,
    sireName: state1?.sireName,
    GSireName: state1?.GSireName,
    ActiveOwners: state1?.ActiveOwners,
    breederName: state1?.breederName,
    trainerName: state1?.trainerName,
    PurchasePrice: state1?.PurchasePrice,
    // MeetingType: state1.meetingtype,
    racename: state1.racename,
    MeetingCode: state1.MeetingCode,
    WeatherDegree: state1.WeatherDegree,
    WeatherType: state1.WeatherType,
    racekind: state1.racekind,
    RaceTyp: state1.RaceTyp,
    RaceStatus: state1.RaceStatus,
    Racecourse: state1.Racecourses,
    Tracklength: state1.Tracklength,
    TracklengthName: state1.TracklengthName,
    Horseskinds: state1.Horseskinds,
    trackcondition: state1.trackcondition,
    currency: state1.currency,
    sponsor: state1.sponsor,
    totalprize: state1.Prize,
  });

  const submit = async () => {
    let Timing = new Date(`${Day} ${StartTime}`);
    let Endtiming = new Date(Timing.getTime() + 1000);
    let UTCTime = Timing.toUTCString();
    let UTCDate = Endtiming.toUTCString();

    try {
      setisLoading(true);
      const formData = new FormData();

      formData.append("MeetingCode", state1.MeetingCode);
      formData.append("DescriptionEn", state1.DescriptionEn);
      formData.append("DescriptionAr", state1.DescriptionAr);
      formData.append("WeatherDegree", state1.WeatherDegree);
      formData.append("RaceNumber", state1.RaceNumber);
      formData.append(
        "StartTime",
        StartTime === "" ? state1.StartTime : StartTime
      );
      formData.append("Day", Day === "" ? state1.Day : Day);
      formData.append(
        "RaceType",
        RaceTyp === undefined ? state1.RaceTyp : RaceTyp
      );
      formData.append("RaceKind", RaceKind === "" ? state1.RaceKind : RaceKind);
      formData.append(
        "MeetingType",
        MeetingType === "" ? state1.MeetingType : MeetingType
      );
      formData.append("Timing", UTCTime);
      formData.append("Endtiming", UTCDate);
      formData.append("PrizeNumber", isSubscribed === false ? 5 : 6);

      formData.append(
        "WeatherType",
        WeatherType === undefined ? state1.WeatherType : WeatherType
      );

      formData.append(
        "Currency",
        Currency === undefined ? state1.Currency : Currency
      );
      formData.append(
        "HorseKindinRace",
        HorsesKind === undefined ? state1.HorseKindinRace : HorsesKind
      );
      formData.append("totalPrize", MyPrize);
      formData.append(
        "TrackLength",
        TrackLength === undefined ? state1.TrackLength : TrackLength
      );
      formData.append(
        "TrackCondition",
        TrackCondition === undefined ? state1.TrackCondition : TrackCondition
      );

      formData.append(
        "RaceStatus",
        RaceStatus === undefined ? state1.RaceStatus : RaceStatus
      );
      formData.append(
        "RaceCourse",
        RaceCourse === undefined ? state1.RaceCourse : RaceCourse
      );
      formData.append(
        "Sponsor",
        Sponsor === undefined ? state1.Sponsor : Sponsor
      );
      // formData.append(
      //   "TrackLength",
      //   TrackLength.id === undefined ? state1.TrackLength : TrackLength.id
      // );

      formData.append(
        "RaceName",
        RaceNameEn === undefined ? state1.RaceName : RaceNameEn
      );
      // formData.append("RaceType", RaceTyp.id === undefined ? state1.RaceTyp : RaceTyp.id);

      await axios.put(
        `${window.env.API_URL}/updaterace/${EditSingleRace?._id}`,
        formData
      );
      history("/editracehorse", {
        state: {
          RaceId: EditSingleRace?._id,
        },
      });
      swal({
        title: "Success!",
        text: "Data has been Updated successfully ",
        icon: "success",
        button: "OK",
      });
    } catch (error) {
      const err = error.response.data.message;
      async function deleteRaceHorse() {
        const confirmation = await swal({
          title: "Are you sure?",
          text: err,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        });

        if (confirmation) {
          try {
            const res = await axios.delete(
              `${window.env.API_URL}/DeleteAllRaceHorse/${EditSingleRace._id}`
            );
            swal("Done! Horses Has Been Delete Successfully!", {
              icon: "success",
            });

            history("/publishrace", {
              state: {
                RaceId: fullraceid._id,
              },
            });
          } catch (error) {
            swal("Oops! Something went wrong while deleting the file.", {
              icon: "error",
            });
          }
        } else {
          swal("Your Data is safe!");
        }
      }
      deleteRaceHorse();
    }
    setisLoading(false);
  };

  return (
    <>
      <div className="page edit">
        <div className="rightsidedata">
          <div
            style={{
              marginTop: "30px",
            }}
          >
            <div className="Headers">Edit Race</div>
            {isLoading ? (
              <Loader />
            ) : (
              <div className="form">
                <Form onFinish={submit} form={form}>
                  <div className="row mainrow">
                    <input
                      type="date"
                      data-placeholder="Race Date"
                      onChange={(e) => setDay(e.target.value)}
                      value={Day}
                      className="dateforraces"
                      required
                    />
                  </div>

                  <div className="row mainrow">
                    <div className="col-sm">
                      <input
                        type="time"
                        onChange={(e) => setStartTime(e.target.value)}
                        value={StartTime}
                        // required
                        // onChange={(e) =>
                        //   setState({ ...state1, StartTime: e.target.value })
                        // }
                        // value={state1.StartTime}
                      />

                      <span className="spanForm spacer1">|</span>
                    </div>

                    <div className="col-sm">
                      <Form.Item
                        name="RaceNumber1"
                        label="Race Number"
                        rules={[
                          {
                            required: true,
                            message: " Race Number  is required",
                          },
                        ]}
                      >
                        <Input
                          name="RaceNumber1"
                          placeholder="Race Number"
                          onChange={(e) =>
                            setState({ ...state1, RaceNumber: e.target.value })
                          }
                          value={state1.RaceNumber}
                          type="number"
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="row mainrow">
                    <div className="col-sm">
                      <Form.Item
                        defaultValue={MeetingType}
                        name="MeetingType"
                        label="Meeting Type"
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: "Please select your Meeting Type ",
                          },
                        ]}
                      >
                        <Select
                          filterOption={false}
                          onChange={handleChange1}
                          name="meetingtype"
                          placeholder="Meeting Type"
                        >
                          {meeting?.map((item) => (
                            <Option value={item._id}>
                              {item.NameEn} {"(" + item.NameAr + ")"}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>

                      <span className="spanForm ">
                        <OverlayTrigger
                          overlay={
                            <Tooltip id={`tooltip-top`}>Add more</Tooltip>
                          }
                        >
                          <span className="addmore" onClick={handleShowType}>
                            +
                          </span>
                        </OverlayTrigger>
                        <OverlayTrigger
                          overlay={
                            <Tooltip id={`tooltip-top`}>Fetch New</Tooltip>
                          }
                        >
                          <span className="addmore" onClick={FetchNew}>
                            <AiOutlineReload />
                          </span>
                        </OverlayTrigger>
                      </span>
                    </div>
                  </div>
                  <div className="row mainrow">
                    <div className="col-sm">
                      <Form.Item
                        defaultValue={RaceNameEn}
                        name="racename"
                        label="Race Name"
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: "Please select your Race Name",
                          },
                        ]}
                      >
                        <Select
                          name="racename"
                          filterOption={false}
                          onChange={handleChange2}
                          onSearch={handleSearch}
                          showSearch
                          placeholder="Race Name"
                        >
                          {searchRaceName?.map((item) => (
                            <Option value={item._id}>
                              {item.NameEn} {"(" + item.NameAr + ")"}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                      {/* <Select
                        placeholder={
                          <div>
                            {fullraceid.RaceNameModelData &&
                              fullraceid.RaceNameModelData.NameEn}
                          </div>
                        }
                        defaultValue={RaceNameEn}
                        onChange={setRaceNameEn}
                        options={Racenameoptions}
                        isClearable={true}
                        isSearchable={true}
                      /> */}
                      <span className="spanForm">
                        <OverlayTrigger
                          overlay={
                            <Tooltip id={`tooltip-top`}>Add more</Tooltip>
                          }
                        >
                          <span className="addmore" onClick={handleShowName}>
                            +
                          </span>
                        </OverlayTrigger>
                        <OverlayTrigger
                          overlay={
                            <Tooltip id={`tooltip-top`}>Fetch New</Tooltip>
                          }
                        >
                          <span className="addmore" onClick={FetchNew}>
                            <AiOutlineReload />
                          </span>
                        </OverlayTrigger>
                      </span>
                    </div>
                  </div>

                  <div className="row mainrow">
                    <div className="col-sm">
                      <Form.Item
                        name="MeetingCode"
                        label="Meeting Code"
                        rules={[
                          {
                            required: true,
                            message: "Meeting Code is required",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Meeting Code"
                          onChange={(e) =>
                            setState({ ...state1, MeetingCode: e.target.value })
                          }
                          value={state1.MeetingCode}
                          name="MeetingCode"
                          type="text"
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="row mainrow">
                    <div className="col-sm">
                      <Form.Item
                        name="Description"
                        label="Description"
                        rules={[
                          {
                            required: true,
                            message: "Description (English) is required",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Description"
                          onChange={(e) =>
                            setState({
                              ...state1,
                              DescriptionEn: e.target.value,
                            })
                          }
                          value={state1.DescriptionEn}
                          name="Description"
                        />
                      </Form.Item>

                      <span className="spanForm spacer"> |</span>
                    </div>

                    <div className="col-sm">
                      <Form.Item
                        name="DescriptionAr"
                        label="وصف"
                        rules={[
                          {
                            required: true,
                            message: "Description (Arabic) is required",
                          },
                        ]}
                        style={{ direction: "rtl" }}
                      >
                        <Input
                          placeholder="وصف"
                          onChange={(e) =>
                            setState({
                              ...state1,
                              DescriptionAr: e.target.value,
                            })
                          }
                          name="DescriptionAr"
                          value={state1.DescriptionAr}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="row mainrow">
                    <div className="col-sm">
                      <Form.Item
                        name="WeatherDegree"
                        label="Weather Degree"
                        rules={[
                          {
                            required: true,
                            message: "Weather Degree is required",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Weather Degree"
                          onChange={(e) =>
                            setState({
                              ...state1,
                              WeatherDegree: e.target.value,
                            })
                          }
                          value={state1.WeatherDegree}
                          name="WeatherDegree"
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="row mainrow">
                    <div className="col-sm">
                      <Form.Item
                        defaultValue={WeatherType}
                        name="WeatherType"
                        label="Weather Type"
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: "Please select your weather Type ",
                          },
                        ]}
                      >
                        <Select
                          filterOption={false}
                          onChange={handleChange12}
                          name=""
                          placeholder="Weather Type"
                        >
                          {weatherOptions?.map((item) => (
                            <Option value={item.value}>{item.label} </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="row mainrow">
                    <div className="col-sm">
                      <Form.Item
                        defaultValue={RaceKind}
                        name="racekind"
                        label="Race Kind"
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: "Please select your Race Kind ",
                          },
                        ]}
                      >
                        <Select
                          name="racekind"
                          filterOption={false}
                          onChange={handleChange8}
                          onSearch={handleSearch2}
                          showSearch
                          placeholder="Race Kind"
                        >
                          {racekinddropdown?.map((item) => (
                            <Option value={item._id}>
                              {item.NameEn} {"(" + item.NameAr + ")"}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="row mainrow">
                    <div className="col-sm">
                      <Form.Item
                        defaultValue={RaceTyp}
                        name="RaceTyp"
                        label="Race Type"
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: "Please select your Race Type ",
                          },
                        ]}
                      >
                        <Select
                          name="RaceTyp"
                          filterOption={false}
                          onChange={handleChange3}
                          onSearch={handleSearch3}
                          showSearch
                          placeholder="Race Type"
                        >
                          {racetypedropdown?.map((item) => (
                            <Option value={item._id}>
                              {item.NameEn} {"(" + item.NameAr + ")"}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>

                      <span className="spanForm">
                        <OverlayTrigger
                          overlay={
                            <Tooltip id={`tooltip-top`}>Add more</Tooltip>
                          }
                        >
                          <span
                            className="addmore"
                            onClick={handleShowRaceType}
                          >
                            +
                          </span>
                        </OverlayTrigger>
                        <OverlayTrigger
                          overlay={
                            <Tooltip id={`tooltip-top`}>Fetch New</Tooltip>
                          }
                        >
                          <span className="addmore" onClick={FetchNew}>
                            <AiOutlineReload />
                          </span>
                        </OverlayTrigger>
                      </span>
                    </div>
                  </div>
                  {/* <div className="row mainrow">


                    <div className="col-sm">

                      <Form.Item
                        defaultValue={RaceStatus}
                        name="RaceStatus"
                        label="Race Status"
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: 'Please select your Race Status ',
                          },
                        ]}
                      >
                        <Select

                          filterOption={false}
                          onChange={handleChange9}
                          name=""


                          placeholder="Race Status" >

                          {
                            raceStatus?.map((item) => (


                              <Option value={item.value}>{item.label} </Option>

                            ))
                          }
                        </Select>
                      </Form.Item>
                     
                    </div>
                  </div> */}
                  <div className="row mainrow">
                    <div className="col-sm">
                      <Form.Item
                        name="Racecourse"
                        label="Race Course"
                        defaultValue={RaceCourse}
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: "Please select your Race Course ",
                          },
                        ]}
                      >
                        <Select
                          filterOption={false}
                          onChange={handleChange10}
                          name="Racecourse"
                          onSearch={handleSearch4}
                          showSearch
                          placeholder="Race Course"
                        >
                          {racecourse?.map((item) => (
                            <Option value={item._id}>
                              {item.TrackNameEn} {"(" + item.TrackNameAr + ")"}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>

                      <span className="spanForm">
                        <OverlayTrigger
                          overlay={
                            <Tooltip id={`tooltip-top`}>Add more</Tooltip>
                          }
                        >
                          <span
                            className="addmore"
                            onClick={handleShowRaceCourse}
                          >
                            +
                          </span>
                        </OverlayTrigger>
                        <OverlayTrigger
                          overlay={
                            <Tooltip id={`tooltip-top`}>Fetch New</Tooltip>
                          }
                        >
                          <span className="addmore" onClick={FetchNew}>
                            <AiOutlineReload />
                          </span>
                        </OverlayTrigger>{" "}
                      </span>

                      <div className="col-sm fetchTrack">
                        <p
                          onClick={fetchTrackLengthAcctoRaceCourse}
                          className="verifybtn"
                        >
                          Fetch TrackLength
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="row mainrow">
                    <div className="col-sm">
                      <Form.Item
                        defaultValue={TrackLength}
                        name="TracklengthName"
                        label="Track Length"
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: "Please select your Track Length ",
                          },
                        ]}
                      >
                        <Select
                          name="TracklengthName"
                          filterOption={false}
                          onChange={handlechangetrack}
                          showSearch
                          placeholder=" Track Length"
                        >
                          {tracklengthwithracecourse?.map((item) => (
                            <Option value={item._id}>
                              {" "}
                              {item.TrackLength}(
                              {item?.GroundTypeModelData?.NameEn})
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>

                      <span className="spanForm">
                        <OverlayTrigger
                          overlay={
                            <Tooltip id={`tooltip-top`}>Add more</Tooltip>
                          }
                        >
                          <span
                            className="addmore"
                            onClick={handleShowTrackLength}
                          >
                            +
                          </span>
                        </OverlayTrigger>
                        <OverlayTrigger
                          overlay={
                            <Tooltip id={`tooltip-top`}>Fetch New</Tooltip>
                          }
                        >
                          <span className="addmore" onClick={FetchNew}>
                            <AiOutlineReload />
                          </span>
                        </OverlayTrigger>{" "}
                      </span>
                    </div>
                  </div>
                  <div className="row mainrow">
                    <div className="col-sm">
                      <Form.Item
                        value={HorsesKind}
                        name="Horseskinds"
                        label="HorseKind"
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: "Please select your Horse Kind!",
                          },
                        ]}
                      >
                        <Select
                          name="Horseskinds"
                          filterOption={false}
                          onChange={handleChange5}
                          showSearch
                          placeholder="Horse Kind"
                        >
                          {HorseKind?.map((item) => (
                            <Option value={item._id}>
                              {item.NameEn} {"(" + item.NameAr + ")"}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>

                      {/* <Select
                   placeholder={
                     <div>
                       {fullraceid.HorseKindinRaceData &&
                         fullraceid.HorseKindinRaceData.NameEn}
                     </div>
                   }
                   defaultValue={HorsesKind}
                   onChange={sethorsesKind}
                   options={horsekindoptions}
                   isClearable={true}
                   isSearchable={true}
                 /> */}
                      <span className="spanForm ">
                        <OverlayTrigger
                          overlay={
                            <Tooltip id={`tooltip-top`}>Add more</Tooltip>
                          }
                        >
                          <span
                            className="addmore"
                            onClick={handleShowHorseKind}
                          >
                            +
                          </span>
                        </OverlayTrigger>
                        <OverlayTrigger
                          overlay={
                            <Tooltip id={`tooltip-top`}>Fetch New</Tooltip>
                          }
                        >
                          <span className="addmore" onClick={FetchNew}>
                            <AiOutlineReload />
                          </span>
                        </OverlayTrigger>{" "}
                      </span>
                    </div>
                  </div>
                  <div className="row mainrow">
                    <div className="col-sm">
                      <Form.Item
                        defaultValue={TrackCondition}
                        name="trackcondition"
                        label="Track Condition"
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: "Please select your Track Condition ",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          filterOption={false}
                          onChange={handleChange6}
                          name="trackcondition"
                          onSearch={handleSearch5}
                          placeholder="Track Condition"
                        >
                          {TrackConditiondropdown?.map((item) => (
                            <Option value={item._id}>
                              {item.NameEn} {"(" + item.NameAr + ")"}{" "}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <span className="spanForm">
                        <OverlayTrigger
                          overlay={
                            <Tooltip id={`tooltip-top`}>Add more</Tooltip>
                          }
                        >
                          <span
                            className="addmore"
                            onClick={handleShowTrackCondition}
                          >
                            +
                          </span>
                        </OverlayTrigger>
                        <OverlayTrigger
                          overlay={
                            <Tooltip id={`tooltip-top`}>Fetch New</Tooltip>
                          }
                        >
                          <span className="addmore" onClick={FetchNew}>
                            <AiOutlineReload />
                          </span>
                        </OverlayTrigger>{" "}
                      </span>
                    </div>
                  </div>
                  <div className="row mainrow">
                    <div className="col-sm">
                      <Form.Item
                        defaultValue={currency}
                        name="currency"
                        label="Currency"
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: "Please select your Currency ",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          filterOption={false}
                          // onChange={setCurrencyData}
                          name="currency"
                          onChange={handleChange4}
                          placeholder="Currency"
                        >
                          {currency?.map((item) => (
                            <Option value={item._id}>
                              {item.NameEn} {"(" + item.NameAr + ")"}{" "}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                      {/* <Select
                        placeholder={
                          <div>
                            {fullraceid.CurrencyData &&
                              fullraceid.CurrencyData.NameEn}
                          </div>
                        }
                        defaultValue={Currency}
                        onChange={setCurrency}
                        options={currencyoption}
                        isClearable={true}
                        isSearchable={true}
                      /> */}
                      <span className="spanForm">
                        <OverlayTrigger
                          overlay={
                            <Tooltip id={`tooltip-top`}>Add more</Tooltip>
                          }
                        >
                          <span className="addmore" onClick={handleShowSponsor}>
                            +
                          </span>
                        </OverlayTrigger>
                        <OverlayTrigger
                          overlay={
                            <Tooltip id={`tooltip-top`}>Fetch New</Tooltip>
                          }
                        >
                          <span className="addmore" onClick={FetchNew}>
                            <AiOutlineReload />
                          </span>
                        </OverlayTrigger>
                      </span>
                    </div>
                  </div>
                  <div className="row mainrow">
                    <div className="col-sm">
                      <Form.Item
                        defaultValue={Sponsor}
                        name="sponsor"
                        label="Sponsor"
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: "Please select your Sponsor ",
                          },
                        ]}
                      >
                        <Select
                          name="sponsor"
                          filterOption={false}
                          onChange={handleChange7}
                          onSearch={handleSearch1}
                          showSearch
                          placeholder="Sponsor"
                        >
                          {sponsordropdown.map((item) => (
                            <Option key={item._id} value={item._id}>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={item.image}
                                  alt={item.image}
                                  style={{
                                    width: "24px",
                                    height: "24px",
                                    marginRight: "8px",
                                  }}
                                />
                                {item.TitleEn} ({item.TitleAr})
                              </div>
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>

                      <span className="spanForm">
                        <OverlayTrigger
                          overlay={
                            <Tooltip id={`tooltip-top`}>Add more</Tooltip>
                          }
                        >
                          <span className="addmore" onClick={handleShowSponsor}>
                            +
                          </span>
                        </OverlayTrigger>
                        <OverlayTrigger
                          overlay={
                            <Tooltip id={`tooltip-top`}>Fetch New</Tooltip>
                          }
                        >
                          <span className="addmore" onClick={FetchNew}>
                            <AiOutlineReload />
                          </span>
                        </OverlayTrigger>
                      </span>
                    </div>
                  </div>
                  <div className="row mainrow">
                    <div className="col-sm">
                      <Form.Item
                        name="Prize"
                        label="Race Prize"
                        rules={[
                          {
                            required: true,
                            message: " Race Prize  is required",
                          },
                        ]}
                      >
                        <Input
                          name="Prize"
                          placeholder="Race Prize"
                          onChange={(e) =>
                            setState({ ...state1, Prize: e.target.value })
                          }
                          value={state1.Prize}
                          type="number"
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="ButtonSection ">
                    <div>
                      <div className="ViewCalulatedPrize">
                        <div className="ViewCalulatedPrize1122">
                          <span className="ViewCalulatedPrize111">
                            <input
                              type="checkbox"
                              id="vehicle1"
                              name="checked"
                              value={isSubscribed}
                              onChange={handleChange}
                            />
                            <label for="vehicle1">Six Position</label>
                            <br />
                          </span>
                          <span
                            onClick={calculatePrize}
                            className="ViewCalulatedPrize1"
                          >
                            Calculate Prize
                          </span>
                        </div>

                        {showCalculated ? (
                          <table className="calcualtedPrizeTable">
                            <tr>
                              <th>First Prize</th>
                              <th>Second Prize</th>
                              <th>Third Prize</th>
                              <th>Fourth Prize</th>
                              <th>Fifth Prize</th>
                              <th>Six Prize</th>
                            </tr>
                            <tr>
                              <td>{FirstPrice}</td>
                              <td>{SecondPrice}</td>
                              <td>{ThirdPrice}</td>
                              <td>{FourthPrice}</td>
                              <td>{FifthPrice}</td>
                              <td>{SixthPrice}</td>
                            </tr>
                          </table>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <div>
                      <button type="submit" className="SubmitButton">
                        Update & Next
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal
        show={showName}
        onHide={handleCloseName}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2>Race Name</h2>
        </Modal.Header>
        <Modal.Body>
          <Racename setShowName={setShowName} />
        </Modal.Body>
      </Modal>
      <Modal
        show={showType}
        onHide={handleCloseType}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2>Meeting Type</h2>
        </Modal.Header>
        <Modal.Body>
          <MeetingTypePopUp setShowType={setShowType} />
        </Modal.Body>
      </Modal>
      <Modal
        show={showRaceType}
        onHide={handleCloseRaceType}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2>Race Type</h2>
        </Modal.Header>
        <Modal.Body>
          <RaceTypePopup setShowRaceType={setShowRaceType} />
        </Modal.Body>
      </Modal>
      <Modal
        show={showTrackLength}
        onHide={handleCloseTrackLength}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2>Track Length</h2>
        </Modal.Header>
        <Modal.Body>
          <TrackLengthPopup setShowTrackLength={setShowTrackLength} />
        </Modal.Body>
      </Modal>
      <Modal
        show={showRaceKind}
        onHide={handleCloseRaceKind}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2>Race Kind</h2>
        </Modal.Header>
        <Modal.Body>
          <RaceKindPopup setShowRaceKind={setShowRaceKind} />
        </Modal.Body>
      </Modal>
      <Modal
        show={showRaceCourse}
        onHide={handleCloseRaceCourse}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2>Create Race Course</h2>
        </Modal.Header>
        <Modal.Body>
          <RaceCoursePopup setShowRaceCourse={setShowRaceCourse} />
        </Modal.Body>
      </Modal>

      <Modal
        show={showJockey}
        onHide={handleCloseJockey}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2>Create Jockey</h2>
        </Modal.Header>
        <Modal.Body>
          <JockeyPopup />
        </Modal.Body>
      </Modal>
      <Modal
        show={showSponsor}
        onHide={handleCloseSponsor}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2>Sponsor</h2>
        </Modal.Header>
        <Modal.Body>
          <SponsorPopup setShowSponsor={setShowSponsor} />
        </Modal.Body>
      </Modal>

      <Modal
        show={showGroundType}
        onHide={handleCloseGroundType}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2>Ground Type</h2>
        </Modal.Header>
        <Modal.Body>
          <GroundTypePopup setShowGroundType={setShowGroundType} />
        </Modal.Body>
      </Modal>
      <Modal
        show={showCondition}
        onHide={handleCloseTrackCondition}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2>Track Condition</h2>
        </Modal.Header>
        <Modal.Body>
          <TrackConditionPopup setshowCondition={setshowCondition} />
        </Modal.Body>
      </Modal>
      <Modal
        show={showhorseKind}
        onHide={handleCloseHorseKind}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2>Horse Kind</h2>
        </Modal.Header>
        <Modal.Body>
          <HorseKindPopup setshowhorseKind={setshowhorseKind} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NewsForm;
