import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import { fetchEditResultImage } from "../../redux/getDropDownReducer/getEditResultIamges";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../Components/Common/Loader";
import { Upload, Button, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";

const EditImages = ({data1}) => {
  const { state } = useLocation();
  const { RaceId } = state;
  const [OwnerSlik, setOwnerSlik] = useState([]);
  const { data: EditResultImage } = useSelector((state) => state.EditResultImage);
  const dispatch = useDispatch();


  const history = useNavigate();

  let RaceIdid = RaceId;
  useEffect(() => {
    dispatch(fetchEditResultImage({ RaceIdid }));
  }, [dispatch, RaceIdid]);

  const [selectedImages, setSelectedImages] = useState([]);
  const [isLoading, setloading] = useState(false);

  const onSelectFile = (fileList) => {
    setSelectedImages(fileList);
  };

  const UploadSilkColor = async () => {
    
    try {
      setloading(true);
      const formData = new FormData();
      selectedImages.forEach((image) => {
        formData.append("image", image.originFileObj);
      });

       const res =  await axios.post(`${window.env.API_URL}/AddRaceImage/${RaceId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      const success = res.statusText;
      console.log(res,"http://localhost:3001/editresult")
      setloading(false);
      swal({
        title: "Success!",
        text:success,
        icon: "success",
        button: "OK",
      });
      data1.handleCloseResultimage();

    } catch (error) {
      setloading(false);
      const err = "File size too large";
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
    }
  };

  const handleRemove = (file) => {
    const updatedList = selectedImages.filter((image) => image.uid !== file.uid);
    setSelectedImages(updatedList);
  };

  const beforeUpload = (file) => {
    // Add any custom validation here (e.g., file size, file type)
    return true;
  };

  const uploadProps = {
    multiple: true,
    fileList: selectedImages,
    onChange: (info) => {
      onSelectFile(info.fileList);
    },
    onRemove: handleRemove,
    beforeUpload: beforeUpload,
  };


  const handleRemove1 = async (obj) => {
    setloading(true)
    try {
      const response = await axios.delete(`${window.env.API_URL}/DeleteResultImage/${obj.RaceId}/${obj._id}`);
      console.log(response);
      swal({
        title: "Success!",
        text: `${response.data.message}`,
        icon: "success",
        button: "OK",
      });
      dispatch(fetchEditResultImage({ RaceIdid }));
    }
    catch (err) {
      swal({
        title: "Error!",
        text: `${err}`,
        icon: "error",
        button: "OK",
      });
    }
    setloading(false)
  };

  return (
    <>
      <div className="page">
        <div className="rightsidedata">
          <div
            style={{
              marginTop: "30px",
            }}
          >
            <div className="Headers">Edit Result Images</div>
            {isLoading ? <Loader /> : 
               <section className="addsectionimage">
               <Upload {...uploadProps}>
                   <Button icon={<UploadOutlined />}>+ Add Images</Button>
                 </Upload>
                 <br />
   
                 {selectedImages.length > 0 && (
                   <>
                     {selectedImages.length > 10 ? (
                       <p className="error11">
                         You can't upload more than 10 images! <br />
                         <span>
                           please delete <b>{selectedImages.length - 10}</b> of them{" "}
                         </span>
                       </p>
                     ) : (
                       <Button
                         type="primary"
                         className="upload-btn111"
                         onClick={UploadSilkColor}
                         loading={isLoading}
                       >
                         UPLOAD {selectedImages.length} IMAGE
                         {selectedImages.length === 1 ? "" : "S"}
                       </Button>
                     )}
                   </>
                 )}
   
                 {/* <div className="images111">
                   {selectedImages.map((image, index) => (
                     <div key={index} className="image111">
                       <img src={image.thumbUrl} height="200" width="200" alt="upload" />
                       <Button onClick={() => handleRemove(image)}>delete image</Button>
                       <p>{index + 1}</p>
                     </div>
                   ))}
                 </div> */}
               </section>
            }
          </div>
          <div className="editsilkcolorapi">
            {
              EditResultImage.map((item) => {
                return (
                  <div className="editsilkcolorimg">
                    <img src={item.image} alt="" />
                    <button
                      onClick={() => handleRemove1(item)}
                    >Delete</button>
                  </div>
                );
              })
            }
          </div>
         
        </div>
      </div>
    </>
  );
};

export default EditImages;
