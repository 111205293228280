import React, { Fragment, useState } from "react";
import swal from "sweetalert";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import TextInputValidation from "../../utils/TextInputValidation";
import Loader from "../../Components/Common/Loader";

const Color = () => {
  //for errors
  const [Error, setError] = useState("");
  const [colorForPrimary, setColorForPrimary] = useState("");
  const [colorForSecondary, setColorForSecondary] = useState("");
  const [FontForSecondary, setFontForSecondary] = useState("");
  const [FontForPrimary, setFontForPrimary] = useState("");


  const [registeration, setregisteration] = useState({
    ThemeName: "",
    PrimaryColor: "",
    SecondaryColor: "",
    PrimaryColorFont:"",
    SecondaryColorFont:""
  });

  const history = useNavigate();


  const [isLoading, setisLoading] = useState(false);

  //----------------------------shortcode ---------------------//

  //----------------------------hnadlechange ---------------------//
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setregisteration({ ...registeration, [name]: value });
  };
  //----------------------------input validation ---------------------//
  const data1 = JSON.stringify(
    TextInputValidation("en", registeration.ThemeName, "Color Name")
  );

  const obj = JSON.parse(data1);

  //----------------------------submit ---------------------//
  const submit = async (event) => {
    event.preventDefault();
    setisLoading(true);
    try {
      const formData = new FormData();
      formData.append("ThemeName", registeration.ThemeName);
      formData.append("PrimaryColor", colorForPrimary);
      formData.append("SecondaryColor", colorForSecondary);
      formData.append("PrimaryColorFont", FontForPrimary);
      formData.append("SecondaryColorFont", FontForSecondary);

      await axios.post(`${window.env.API_URL}/uploadTheme`, formData);
      swal({
        title: "Success!",
        text: "Data has been added Successfully ",
        icon: "success",
        button: "OK",
      });
      history("/ThemeListing");

    } catch (error) {
      const err = error.response.data.message[0];
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
      setisLoading(false);
    }
  };
  //conditional styling for errors


  return (
    <Fragment>
      <div className="page">
        <div className="rightsidedata">
          <div
            style={{
              marginTop: "30px",
            }}
          >
            <div className="Headers">Theme Setting</div>
            {!isLoading ? <div className="form">
              <form onSubmit={submit}>
                <div className="row mainrow">
                  <div className="col-sm">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Theme Name"
                      className="mb-3"
                      name="Name"
                    >
                      <Form.Control
                        required
                        onChange={handleChange}
                        value={registeration.ThemeName}
                        name="ThemeName"
                        type="text"
                        placeholder="Theme Name"
                        onBlur={() => setError(obj)}
                      />
                    </FloatingLabel>
                    <span className={Error.status ? "success" : "error"}>
                      {Error.message}
                    </span>
                  </div>
                </div>

                <div className="row mainrow">
                <div className="col-sm">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Primary Theme Color "
                      className="mb-3"


                    >
                      <Form.Control
                        required
                        name="PrimaryColor"
                        type="text"
                        value={colorForPrimary}
                        placeholder="Primary Color"
                        readOnly

                      />
                    </FloatingLabel>
                    <span className="spanForm"> |</span>

                  </div>

                  <div className="col-sm">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Secondary Theme Color "
                      className="mb-3"


                    >
                      <Form.Control
                        required
                        name="SecondaryColor"
                        type="text"
                        value={colorForSecondary}
                        placeholder="Secondary Color"
                        readOnly

                      />
                    </FloatingLabel>

                  </div>
                </div>
            
                <div className="row mainrow1">
                <div className="col-sm">
                <div className="swatch">
                  <input type="color" id="color" name="color" onChange={(e) => setColorForPrimary(e.target.value)} value={colorForPrimary} />
                  {
                    colorForPrimary ? <h5 className="text-center p-1">
                      {colorForPrimary}
                    </h5> : <label className="Multipleownerlabel">
                      Select Primary Color
                    </label>
                  }

                
                </div>
                </div>
                <div className="col-sm">
                <div className="swatch">
                  <input type="color" id="color" name="color" onChange={(e) => setColorForSecondary(e.target.value)} value={colorForSecondary} />
                  {
                    colorForSecondary ? <h5 className="text-center p-1">
                      {colorForSecondary}
                    </h5> : <label className="Multipleownerlabel">
                      Select Secondary Color
                    </label>
                  }

                
                </div>
                </div>
                </div>
                


                {/* <div className="row mainrow">
                <div className="col-sm">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Primary Font Color "
                      className="mb-3"


                    >
                      <Form.Control
                        required
                        name="PrimaryColor"
                        type="text"
                        value={FontForPrimary}
                        placeholder="Primary Color"
                        readOnly

                      />
                    </FloatingLabel>
                    <span className="spanForm"> |</span>

                  </div>

                  <div className="col-sm">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Secondary Font Color "
                      className="mb-3"


                    >
                      <Form.Control
                        required
                        name="SecondaryColor"
                        type="text"
                        value={FontForSecondary}
                        placeholder="Secondary Color"
                        readOnly

                      />
                    </FloatingLabel>

                  </div>
                </div>
            
                <div className="row mainrow1">
                <div className="col-sm">
                <div className="swatch">
                  <input type="color" id="color" name="color" onChange={(e) => setFontForPrimary(e.target.value)} value={FontForPrimary} />
                  {
                    FontForPrimary ? <h5 className="text-center p-1">
                      {FontForPrimary}
                    </h5> : <label className="Multipleownerlabel">
                      Select Primary Font
                    </label>
                  }

                
                </div>
                </div>
                <div className="col-sm">
                <div className="swatch">
                  <input type="color" id="color" name="color" onChange={(e) => setFontForSecondary(e.target.value)} value={colorForSecondary} />
                  {
                    FontForSecondary ? <h5 className="text-center p-1">
                      {FontForSecondary}
                    </h5> : <label className="Multipleownerlabel">
                      Select Secondary Font
                    </label>
                  }

                
                </div>
                </div>
                </div> */}

                
                
                <div
                  className="ButtonSection "
                  style={{ justifyContent: "end" }}
                >
                  <button
                    Name="submit"
                    className="SubmitButton"
                    disabled={isLoading}
                  >
                                         Save

                  </button>
                </div>
              </form>
            </div> : <Loader />}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Color;
