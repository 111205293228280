import React, { useState } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import axios from "axios";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";

const CompetitionPopup = (data) => {
  const history = useNavigate();
  const [Disable, setDisable] = useState(true);
  const [Prizes, SetPrize] = useState();
  const CloseCompetition = async () => {

    // console.log(data.data._id);
    // console.log(data._id);
    // alert(data._id);
    const formData = new FormData();
    formData.append("TotalPrizeDistiributed", Prizes);
    try {
      setDisable(false);
      await axios.post(
        `${window.env.API_URL}/CloseCompetition/${data.data._id}`,
        formData
      );


      history(0);
      setDisable(true);
      swal({
        title: "Success!",
        text: "Data has been added successfully ",
        icon: "success",
        button: "OK",
      });
    } catch (error) {
      const err = error.response.data.message;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
    }
  };
  return (
    <div className="form competitionPopup">
      <div className="row mainrow">
        <div className="col-sm">



          <FloatingLabel
            controlId="floatingInput"
            label="Prize To Be Distributed"
            onChange={(e) => SetPrize(e.target.value)}
          >
            <Form.Control

              placeholder="Prize To Be Distributed"
            />
          </FloatingLabel>

        </div>
      </div>
      <div className="compSubmit">
        <button onClick={CloseCompetition} className="RaceButtonDiv">Distribute</button>

      </div>


    </div>
  );
};

export default CompetitionPopup;
