import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});

const getHorseforRaceSlice = createSlice({
    name: 'HorseforRace',
    initialState: {
        data: [],
        status: STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchHorseforRace.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(fetchHorseforRace.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.IDLE
            })
            .addCase(fetchHorseforRace.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
    }
});

export const { setHorseofRace, setStatus } = getHorseforRaceSlice.actions;
export default getHorseforRaceSlice.reducer;

export const fetchHorseforRace = createAsyncThunk('/getHorsesInRace/fetch', async ({ RaceId }) => {
    const res = await axios.get(`${window.env.API_URL}/HorsesInRace/${RaceId}?size=50`);
    const HorseForRaceData = res.data;
    return HorseForRaceData.data;
})