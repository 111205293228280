import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchsubscriber,
  STATUSES,
} from "../../redux/getReducer/getSubscriber";
import { useNavigate } from "react-router-dom";
import ScrollContainer from "react-indiana-drag-scroll";
import swal from "sweetalert";
import axios from "axios";
import Lottie from "lottie-react";
import HorseAnimation from "../../assets/horselottie.json";
import Pagination from "../GetTable/Pagination";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import { BiFilter } from "react-icons/bi";
import { CSVLink } from "react-csv";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Moment from "react-moment";
import Notfound from "../../Notfound";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { BsEye } from 'react-icons/bs';

const statusData = [
  {
    id: 1,
    data: "true",
    value: "Approved",
  },
  {
    id: 0,
    data: "false",
    value: "Not Approved",
  },
];

const SubscriberList = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const { data: subscriber, status } = useSelector((state) => state.subscriber);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(6);

  const [imageData, setimageData] = useState("");
  const [SearchData, setSearchData] = useState([]);
  const [PassportNo, setPassportNo] = useState("");
  const [Address, setAddress] = useState("");
  const [Email, setEmail] = useState("");
  const [FirstName, setFirstName] = useState("")
  const [loading, setLoading] = useState(false);


  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (data) => {
    setimageData(data)
    setShow(true)
  };

  const [Approved, setApproved] = useState("");
  const [SearchStartdate1, setStartdate1] = useState('');
  const [SearchEndDate1, setEndDate1] = useState('');
  const [TotalCount, setTotalCount] = useState();
  const [TotalPages, setTotalPages] = useState("");
  const [state, setState] = useState([
    {
      startDate: new Date('2000-01-01'),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  const startDate = (convert(state[0].startDate))
  const endsDate = (convert(state[0].endDate))


  const GetSearch = async () => {
    setStartdate1(startDate);
    setEndDate1(endsDate)
    setLoading(true)
    const response = await axios.get(
      `${window.env.API_URL}/SearchUser?PassportNo=${PassportNo}&Address=${Address}&ApprovedStatus=${Approved}&Email=${Email}&FirstName=${FirstName}&page=${currentPage}`
    );
    setTotalCount(response.data.totalcount)
    setSearchData(response.data.data);
    setTotalPages(response.data.totalPages)
    setLoading(false)
    // state.startDate('')
    // state.endDate('')
  };
  useEffect(() => {

    GetSearch({ currentPage });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, currentPage]);


  const paginate = (pageNumber) => setCurrentPage(pageNumber);






  const [ShowCalender, setShowCalender] = useState(false);

  const handleRole = async (Id) => {
    try {
      await axios.put(`${window.env.API_URL}/ChangeStatus/${Id}`);
      swal({
        title: "Success!",
        text: "Data has been Updated successfully ",
        icon: "success",
        button: "OK",
      });
      GetSearch({ currentPage });
    } catch (error) {
      const err = error.response.data.message;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
    }
    history("/userlist");
  };


  if (status === STATUSES.ERROR) {
    return (
      <h2
        style={{
          margin: "100px",
        }}
      >
        Something went wrong!
      </h2>
    );
  }


  return (
    <>
      <div className="page">
        <div className="rightsidedata">
          <div
            style={{
              marginTop: "30px",
            }}
          >
            <div className="Header ">
              <h4>User Management</h4>
              <div>
                <h6
                  style={{
                    marginRight: "100px",
                    alignItems: "center",
                    color: "rgba(0, 0, 0, 0.6)",
                  }}
                >
                  <OverlayTrigger
                    overlay={<Tooltip id={`tooltip-top`}>Filter</Tooltip>}
                  >
                    <span
                      className="addmore"
                    >
                      <BiFilter
                        className="calendericon"
                        onClick={() => setShowCalender(!ShowCalender)}
                      />
                    </span>
                  </OverlayTrigger>

                  <CSVLink
                    data={SearchData}
                    separator={";"}
                    filename={"MKS User.csv"}
                    className="csvclass"
                  >
                    Export CSV
                  </CSVLink>
                </h6>
              </div>
            </div>

            <div>
              {ShowCalender ? (
                <>
                  <div className="userfilter userFilter">
                    <div className="calenderuser">
                      <DateRangePicker
                        onChange={(item) => setState([item.selection])}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={2}
                        ranges={state}
                        direction="horizontal"

                      />
                    </div>
                    <div className="myuser filtertextform filterTextForm">
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setFirstName(e.target.value)}
                        placeholder="First Name"
                        value={FirstName}
                      />
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setPassportNo(e.target.value)}
                        placeholder="Enter Passport Number"
                        value={PassportNo}
                      />
                      <input
                        type="text"
                        className="form-control"

                        placeholder="Enter Phone Number"
                      />
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter Address"
                        value={Email}
                      />
                      <select
                        className="form-control"
                        id="exampleFormControlSelect1"
                        name="country"
                        onChange={(e) => setApproved(e.target.value)}
                        required
                      >
                        {statusData.map((item) => {
                          return (
                            <option
                              key={item.id}
                              name="country"
                              value={item.id}
                            >
                              {item.value}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <button className="filterbtn" onClick={GetSearch}>
                    Apply Filter
                  </button>
                </>
              ) : (
                <></>
              )}
            </div>

            <>
              <div className="div_maintb">
                <ScrollContainer className="scroll-container">
                  <table className="userlists">
                    <thead>
                      <tr>
                        <th style={{ textAlign: "center" }}>Status</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        {/* <th>Passport No</th> */}
                        <th>Email</th>
                        <th>Phone Number</th>
                        <th>Country</th>
                        <th>Date of Birth</th>
                        <th>Passport Picture</th>
                        <th>Created At</th>
                       
                      </tr>
                    </thead>
                    {loading ? <Lottie animationData={HorseAnimation} loop={true} className="TableLottie" /> :
                      SearchData.length === 0 ? <Notfound /> :

                        <tbody>
                          {
                            status === STATUSES.LOADING ?

                              <Lottie animationData={HorseAnimation} loop={true} className="TableLottie" />

                              :
                              SearchData.map((item, index) => {
                                return (
                                  <tr className="tr_table_class">
                                    <td style={{ textAlign: "center" }}>
                                      <button
                                        className="Approvedbtn"
                                        style={{
                                          backgroundColor: `${item.ApprovedStatus === true
                                            ? "#4547EE"
                                            : "#DE3E28"
                                            }`,
                                        }}
                                        onClick={() => handleRole(item._id)}
                                      >
                                        {item.ApprovedStatus === true ? (
                                          <>Approved</>
                                        ) : (
                                          <>Not Approved</>
                                        )}
                                      </button>
                                    </td>
                                    <td>{item.FirstName}</td>
                                    <td>{item.LastName}</td>
                                    {/* <td>{item.PassportNo}</td> */}
                                    <td>{item.Email}</td>
                                    <td>{item.PhoneNumber}</td>
                                    <td>{item.Nationality}</td>
                                    <td><Moment format="YYYY/MM/DD">
                                      {item.DOB}
                                    </Moment></td>
                                    <td  onClick={() => handleShow(item.PassportPicture)}>
                                      <span className="eyeiconimg">
                                      <img src={item.PassportPicture} alt="" />
                                      <BsEye style={{ cursor: 'pointer' }} />
                                      </span>
                                    </td>
                                    <td>
                                      {" "}
                                      <Moment format="YYYY/MM/DD">
                                        {item.createdAt}
                                      </Moment>
                                    </td>
                                    

                                  </tr>
                                );
                              })}
                        </tbody>
                    }
                  </table>
                </ScrollContainer>
              </div>
              {/* <div className="ButtonSection">
                   
                  <button type="submit" className="SubmitButton" onClick={() => history(-1)}>
                    Back
                  </button>
              </div> */}
            </>
          </div>
          <Pagination
            postsPerPage={11}
            totalPosts={TotalCount}
            paginate={paginate}
            currentPage={currentPage}
            TotalPages={TotalPages}

          />
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <img src={imageData} alt="" className="modalimg"/>
        </Modal.Body>
        <Modal.Footer>
  
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SubscriberList;
