import axios from "axios";

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading'
})

const getResultSlice = createSlice({
    name: 'Result',
    initialState: {
        data: [],
        status: STATUSES.IDLE
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchResult.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(fetchResult.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.IDLE;
            })
            .addCase(fetchResult.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
    },
});

export const { setResult, setStatus } = getResultSlice.actions;
export default getResultSlice.reducer;
export const fetchResult = createAsyncThunk('getResult/fetch', async ({currentPage,racenameid,startdate1,enddate1}) => {
    const res = await axios.get(`${window.env.API_URL}/GetRaceResultToBeAnnounced?page=${currentPage}&RaceName=${racenameid === undefined ? '' : racenameid}&racestartday=${startdate1 == undefined ? '' : startdate1} &raceendday=${enddate1 == undefined ? '' : enddate1}`);
    const data = res.data;
    return data;
})