import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { fetchbreedershortcode } from "../../redux/getShortCode/getbreedershortcode";
import { useSelector, useDispatch } from "react-redux";
import { ImCross } from 'react-icons/im';
import Loader from "../../Components/Common/Loader";
import { Form, Input } from 'antd';

const Breeder = ({ setShowBreeder }) => {
  //for error
  const { data: breedershortcode } = useSelector((state) => state.breedershortcode)


  const dispatch = useDispatch();
  //----------------------------state for errors-------------------------//

  const [isLoading, setisLoading] = useState(false);

  const [NameEn, setNameEn] = useState("");
  const [NameAr, setNameAr] = useState("");
  const [DescriptionAr, setDescriptionAr] = useState("");
  const [DescriptionEn, setDescriptionEn] = useState("");
  const [image, setImage] = useState();

  const [state1, setState] = useState({
    shortCode: '',
  });
  //----------------------------shortcode -------------------------//
  useEffect(() => {
    if (breedershortcode) {
      setState({
        shortCode: breedershortcode.length === 0 ? 10 : breedershortcode[0].maxshortCode + 1,
      });
    } else {
    }
  }, [breedershortcode]);
  const [preview, setPreview] = useState();
  const history = useNavigate();
  const { pathname } = useLocation();
  //----------------------------submit -------------------------//
  const submit = async () => {

    setisLoading(true)

    try {
      const formData = new FormData();

      formData.append("NameAr", NameAr);
      formData.append("NameEn", NameEn);
      formData.append("shortCode", state1.shortCode);
      formData.append("DescriptionAr", DescriptionAr);
      formData.append("DescriptionEn", DescriptionEn);
      formData.append("image", image);

      await axios.post(`${window.env.API_URL}/uploadBreeder`, formData);

      if (pathname === "/breeder") {
        history("/breederlist");
      }

      swal({
        title: "Success!",
        text: "Data has been added successfully ",
        icon: "success",
        button: "OK",
      });

      setisLoading(false)
    } catch (error) {

      const err = error.response.data.message[0];
      const err1 = error.response.data.message[1];
      const err2 = error.response.data.message[2];


      swal({
        title: "Error!",
        text: err,
        err1,
        err2,
        icon: "error",
        button: "OK",
      });
      setisLoading(false)
    } setShowBreeder(false)
  };
  const onSelectFile = (e) => {
    setImage(e.target.files[0]);
  };

  useEffect(() => {
    dispatch(fetchbreedershortcode());

  }, [dispatch])
  //----------------------------image Preview -------------------------//
  useEffect(() => {
    if (!image) {
      setPreview(undefined);
      return;
    }
    if (image.name.match(/\.(gif)$/)) {
      setImage("");
      setPreview(undefined);
      document.getElementById("file").value = "";
      swal({
        title: "Error!",
        text: "Invalid Format ",
        icon: "error",
        button: "OK",
      });
    }
    const objectUrl = URL.createObjectURL(image);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [image, dispatch]);

  //----------------------------clear preview -------------------------//
  const handlePreview = () => {
    setImage()
    document.getElementById("file").value = " "
  };







  return (
    <div className="page">
      <div className="rightsidedata">
        <div
          style={{
            marginTop: "30px",
          }}
        >
          <div className="Headers">Create Breeder</div>

          {isLoading ? <   Loader /> : <div className="form">
            <Form onFinish={submit}

              fields={[{

                name: "shortCode",
                value: state1.shortCode

              }]}
            >
              <div className="row mainrow">
                <div className="col-sm">


                  <Form.Item
                    name="NameEn"
                    label="Name"
                    rules={[
                      {
                        required: true,
                        message: 'Name (English) is required',
                      },
                    ]}
                  >
                    <Input placeholder="Name" onChange={(e) => setNameEn(e.target.value)}
                      value={NameEn} name="NameEn" />
                  </Form.Item>


                  <span className="spanForm spacer"> |</span>

                </div>

                <div className="col-sm">
                  <Form.Item
                    name="NameAr"
                    label="اسم"
                    rules={[
                      {
                        required: true,
                        message: 'Name (Arabic) is required',
                      },
                    ]}
                    style={{ direction: 'rtl' }}
                  >
                    <Input placeholder="اسم" onChange={(e) => setNameAr(e.target.value)}
                      name="Name"
                      value={NameAr} />
                  </Form.Item>

                </div>
              </div>
              <div className="row mainrow">
                <div className="col-sm">


                  <Form.Item
                    name="DescriptionEn"
                    label="Description"
                    rules={[
                      {
                        required: true,
                        message: 'Description (English) is required',
                      },
                    ]}
                  >
                    <Input placeholder="Description" onChange={(e) => setDescriptionEn(e.target.value)}
                      value={DescriptionEn} name="DescriptionEn" />
                  </Form.Item>












                  <span className="spanForm spacer"> |</span>

                </div>

                <div className="col-sm">






                  <Form.Item
                    name="DescriptionAr"
                    label="وصف"
                    rules={[
                      {
                        required: true,
                        message: 'Description (Arabic) is required',
                      },
                    ]}
                    style={{ direction: 'rtl' }}
                  >
                    <Input placeholder="وصف" onChange={(e) => setDescriptionAr(e.target.value)}
                      value={DescriptionAr} />
                  </Form.Item>

                </div>
              </div>
              <div className="row mainrow">
                <div className="col-sm">
                  <Form.Item
                    name="shortCode"
                    label="Short Code"
                    rules={[
                      {
                        required: true,
                        message: "short Code is required"
                      },
                    ]}
                  >
                    <Input
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(e) =>
                        setState({ ...state1, shortCode: e.target.value })
                      }
                      value={state1.shortCode}
                      onPaste={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      placeholder="Short Code"
                      maxLength="5"
                    />
                  </Form.Item>


                </div>
              </div>



              <div className="ButtonSection">
                <div>
                  <label className="Multipleownerlabel">
                    Select Breeder image
                  </label>
                  <input
                    type="file"
                    onChange={onSelectFile}
                    className="formInput"
                    id="file"
                  />
                  {image && (
                    <>
                      <ImCross onClick={handlePreview} className="crossIcon" />
                      <img src={preview} className="PreviewImage" alt="" />
                    </>
                  )}
                </div>

                <div><button type="submit" className="SubmitButton" disabled={isLoading}>
                Save

                </button></div>
              </div>
            </Form>
          </div>}
        </div>
      </div>
    </div>
  );
};

export default Breeder;
