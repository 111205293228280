import React, { Fragment, useState, useEffect } from "react";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { fetchOwnerList } from "../../redux/getDropDownReducer/getOwnerList";
import { fetchHorseList } from "../../redux/getDropDownReducer/getHorseList";
import { fetchColorDropdown } from "../../redux/getDropDownReducer/getColor";
import { fetchBreederList } from "../../redux/getDropDownReducer/getBreederList";
import { fetchNationalityList } from "../../redux/getDropDownReducer/getNationalityList";
import { fetchgender } from "../../redux/getReducer/getGenderSlice";
import { fetchHorseKindData } from "../../redux/getDropDownReducer/getHorseKindData";
import { fetchTrainerList } from "../../redux/getDropDownReducer/getTrainerList";
import { fetchHorseKind } from "../../redux/getReducer/getHorseKind";

import swal from "sweetalert";
import axios from "axios";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Modal } from "react-bootstrap";
import { AiOutlineReload } from "react-icons/ai";

import BreederPopup from './Breeder';
import ColorPopup from './Color';
import GenderPopup from './Gender';
import OwnerPopup from "./Owner/OwnerForm";
import TrainerPopup from "./TrainerPopupForm";
import NationalityPopup from "./Nationality";
import HorseKindPopup from "./Horsekindform";
import { ImCross } from 'react-icons/im';
import Moment from "react-moment";
import { useLocation } from "react-router-dom";
import Loader from "../../Components/Common/Loader";
import axiosInstance from '../../Components/Common/axiosInstance';
import { Input, Form, Select, DatePicker } from "antd";


//------------------------options for dropdown-----------------------//
const Gelteds = [
  {
    id: 0,
    label: "false"

  },
  {
    id: 1,
    label: "true"

  }
];

const Foals = [
  { id: "0", value: "1", label: "1" },
  { id: "1", value: "2", label: "2" },
  { id: "2", value: "3", label: "3" },
  { id: "3", value: "4", label: "4" },
  { id: "4", value: "5", label: "5" },
  { id: "5", value: "6", label: "6" },
  { id: "6", value: "7", label: "7" },
  { id: "7", value: "8", label: "8" },
  { id: "8", value: "9", label: "9" },
  { id: "9", value: "10", label: "10" },
];

const HorseForm = () => {
  const { pathname } = useLocation();
  const { Option } = Select;
  //for error



  const [value, setValue1] = useState('');
  const [Value2, setValue2] = useState('');
  const [Value3, setValue3] = useState('');
  const [Value4, setValue4] = useState('');
  const [Value5, setValue5] = useState('')

  const [Value6, setValue6] = useState('')
  const [Value7, setValue7] = useState('')
  const [KindHorse, setKindHorse] = useState("");


  const dispatch = useDispatch();
  const history = useNavigate();
  //------------------------data from redux-----------------------//

  const { data: TrainerList } = useSelector((state) => state.TrainerList);
  const { data: OwnerList } = useSelector((state) => state.OwnerList);
  const { data: colordropdown } = useSelector((state) => state.colordropdown);
  const { data: BreederList } = useSelector((state) => state.BreederList);
  const { data: NationalityList } = useSelector((state) => state.NationalityList);
  const { data: gender } = useSelector((state) => state.gender);
  const { data: HorseKind } = useSelector((state) => state.HorseKind);
  const { data: HorseKindData } = useSelector((state) => state.HorseKindData);



  const KindHorseid = KindHorse === '' ? "" : KindHorse;


  useEffect(() => {

    const Value1 = Value4
    dispatch(fetchHorseKindData({ Value1, KindHorseid }));
  }, [dispatch, KindHorse, KindHorseid, Value4])
  useEffect(() => {
    const Value2 = Value5
    dispatch(fetchOwnerList({ Value2 }));
  }, [dispatch, Value5]);
  useEffect(() => {
    const Value3 = Value7
    dispatch(fetchBreederList({ Value3 }));
  }, [dispatch, Value7]);
  useEffect(() => {

    const Value4 = Value6
    dispatch(fetchTrainerList({ Value4 }));
  }, [dispatch, Value6]);
  useEffect(() => {

    // dispatch(fetchBreederList());
    dispatch(fetchgender({ SearchTitle, SearchCode, SearchAge }));
    dispatch(fetchHorseKind({ SearchTitle, SearchCode, SearchAge }));
  }, [dispatch]);

  useEffect(() => {

    dispatch(fetchNationalityList({ value }));
  }, [dispatch, value])

  useEffect(() => {
    const SearchTitle = Value2
    dispatch(fetchgender({ SearchTitle }));

  }, [Value2, dispatch])




  const handleSearch5 = (value) => {
    setValue6(value)
  }



  //------------------------options for dropdown-----------------------//




  //------------------------Modal-----------------------//
  const [showBreeder, setShowBreeder] = useState(false);
  const [showColor, setShowColor] = useState(false);
  const [showGender, setShowGender] = useState(false);
  const [showActiveOwner, setShowActiveOwner] = useState(false);
  const [showActiveTrainer, setShowActiveTrainer] = useState(false);
  const [showActivenationality, setShowNationality] = useState(false);
  const [showHorseKind, setShowHorseKind] = useState(false);
  const [isLoading, setisLoading] = useState(false);





  const handleCloseBreeder = () => setShowBreeder(false);
  const handleCloseColor = () => setShowColor(false);
  const handleCloseGender = () => setShowGender(false);
  const handleCloseActiveOwner = () => setShowActiveOwner(false);
  const handleCloseActiveTrainer = () => setShowActiveTrainer(false);
  const handleCloseActivenationality = () => setShowNationality(false);
  const handleCloseHorseKind = () => setShowHorseKind(false);

  const handleShowBreeder = async () => {
    await setShowBreeder(true);
  };

  const handleShowColor = async () => {
    await setShowColor(true);
  };

  const handleShowGender = async () => {
    await setShowGender(true);
  };

  const handleShowActiveOwner = async () => {
    await setShowActiveOwner(true);
  };

  const handleShowActiveTrainer = async () => {
    await setShowActiveTrainer(true);
  };

  const handleShowActivenationality = async () => {
    await setShowNationality(true);
  };

  const handleShowHorseKind = async () => {
    await setShowHorseKind(true);
  };


  const handleDateChange = (date, dateString) => {
    setDOB(dateString);
  };
  const SearchTitle = ""
  const SearchCode = ""
  const SearchAge = ""


  const FetchNew = () => {
    dispatch(fetchOwnerList({ SearchTitle, SearchCode, SearchAge }));
    dispatch(fetchHorseList());
    dispatch(fetchColorDropdown());
    dispatch(fetchBreederList({ Value3 }));
    dispatch(fetchNationalityList());
    dispatch(fetchTrainerList({ Value4 }));
    dispatch(fetchgender({ SearchTitle, SearchCode, SearchAge }));
    dispatch(fetchHorseKind({ SearchTitle, SearchCode, SearchAge }));

  };
  // Modal functionalities End Here

  const [ActiveOwner, setActiveOwner] = useState("");
  const [NameEn, setNameEn] = useState("");
  const [NameAr, setNameAr] = useState("");
  const [ActiveTrainer, setActiveTrainer] = useState("");
  const [Breeder, setBreeder] = useState("");
  const [RemarksEn, setRemarksEn] = useState("");
  const [RemarksAr, setRemarksAr] = useState("");
  const [HorseStatus, setHorseStatus] = useState("");
  const [Gender, setGender] = useState("");
  const [ColorID, setColor] = useState("");


  const [Dam, setDam] = useState("");
  const [Sire, setSire] = useState("");
  const [DOB, setDOB] = useState("");

  const [GSire, setGSire] = useState("");
  const [HorseCode, setHorseCode] = useState("");
  const [image, setimage] = useState();
  const [Foal, setFoal] = useState("");

  const [CodeMsg, setCodeMsg] = useState('');
  const [isGelted, setisGelted] = useState(false);
  const [NationalityId, setNationalityId] = useState("");

  const [Rds, setRds] = useState("");

  const [preview, setPreview] = useState();
  const [Dstate, setDstate] = useState({
    PurchasePrice: 0,
  });
  var today = new Date();



  const errorMessages = {
    KindHorse: "",
    NameEn: "",
    NameAr: "",
    HorseCode: "",
    ActiveTrainer: '',
    ActiveOwner: '',
    Breeder: '',
    // RemarksEn: '',
    // RemarksAr: '',
    Gender: '',
    ColorID: '',
    DOB: '',
    NationalityId: '',
    Dam: '',
    Sire: '',
    GSire: ''
  };
  const [errors, setErrors] = useState(errorMessages);

  const handleSearch4 = (value) => {
    setValue5(value)


  }
  const handleSearch6 = (value) => {

    setValue7(value)

  }

  //------------------------Submit-----------------------//

  const submit = async () => {


    setisLoading(true)
    try {
      const formData = new FormData();
      formData.append("image", image);
      formData.append("NameEn", NameEn);
      formData.append("DOB", DOB === '' ? today : DOB);
      formData.append("Height", 0)
      formData.append("NameAr", NameAr);
      formData.append("RemarksEn", RemarksEn);
      formData.append("RemarksAr", RemarksAr);
      formData.append("ActiveOwner", ActiveOwner);

      formData.append("HorseStatus", HorseStatus.value === undefined ? 1 : HorseStatus.value);
      formData.append("ActiveTrainer", ActiveTrainer);
      formData.append("Sex", Gender);
      formData.append("Breeder", Breeder);
      formData.append("ColorID", ColorID);
      formData.append("KindHorse", KindHorse);

      formData.append("Dam", Dam === '' ? '' : Dam);
      formData.append("Sire", Sire === '' ? '' : Sire);
      formData.append("GSire", GSire === '' ? '' : GSire);

      formData.append("Foal", Foal.value === undefined ? 1 : Foal);
      formData.append("shortCode", HorseCode);

      formData.append("Rds", Rds === undefined ? 0 : Rds);
      formData.append("STARS", 0);
      formData.append("isGelded", isGelted.value === undefined ? 0 : isGelted);
      formData.append("NationalityID", NationalityId);
      formData.append("CreationId", NationalityId);
      formData.append("PurchasePrice", Dstate.PurchasePrice);
      await axiosInstance.post(
        `${window.env.API_URL}createhorse?keyword=&page=`,
        formData
      );
      setisLoading(false)

      swal({
        title: "Success!",
        text: "Data has been added Successfully",
        icon: "success",
        button: "OK",
      });
      if (pathname === "/horseform") {
        history("/horse");
      }
      setisLoading(false)

    } catch (error) {

      if (error.code === 'ECONNABORTED') {
        // Timeout error occurred
        swal({
          title: "Error!",
          text: "The request timed out. Please try again later.",
          icon: "error",
          button: "OK",
        });
        setisLoading(false)
      } else {
        const err = error.response.data.message;
        swal({
          title: "Error!",
          text: err,
          icon: "error",
          button: "OK",
        });
        setisLoading(false)
      }
    }


  };


  const handleSearch1 = (value) => {
    setValue2(value)

  }
  const handleSearch2 = (value) => {

    setValue3(value)

  }







  useEffect(() => {

    const Value2 = Value3
    dispatch(fetchColorDropdown({ Value2 }))

  }, [Value3, dispatch])






  //------------------------Code Verification-----------------------//
  const VerifyCode = async (event) => {
    event.preventDefault();

    try {
      const formData = new FormData();
      formData.append("shortCode", HorseCode);
      const response = await axios.post(
        `${window.env.API_URL}VerifyShortCode`,
        formData
      );

      const resdata = response.data.data === null ? "Yes You can use it" : `Already exist in ${response.data.data.NameEn}`
      setCodeMsg(resdata);

    } catch (error) {


    }
  };

  //------------------------Image Preview-----------------------//
  useEffect(() => {
    if (!image) {
      setPreview(undefined);
      return;
    }
    if (image.name.match(/\.(gif)$/)) {
      setimage("");
      setPreview(undefined);
      document.getElementById("file").value = "";
      swal({
        title: "Error!",
        text: "Invalid Format ",
        icon: "error",
        button: "OK",
      });
    }

    const objectUrl = URL.createObjectURL(image);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [image]);

  const onSelectFile = (e) => {
    setimage(e.target.files[0]);

  };
  //------------------------remove preview-----------------------//
  const handlePreview = () => {
    setimage()
    document.getElementById("file").value = ""
  };
  //------------------------input validation -----------------------//




  const handleSearch = async (value) => {
    setValue1(value)
  };

  const handleSearch3 = (value) => {

    setValue4(value)

  }
  // ... (rest 


  const handleChange = async (value) => {
    await setKindHorse(value)

  };


  const initialValues = {
    PurchasePrice: 0,
    foal: 1,

  };

  const [form] = Form.useForm();



  form.setFieldsValue({
    gelded: "false",
    Rds: "false",
    HorseStatus: "true"
  })


  return (
    <Fragment>
      <div className="page">
        <div className="rightsidedata">
          <div
            style={{
              marginTop: "30px",
            }}
          >
            <div className="Headers">Add Horse</div>
            {isLoading ? <Loader /> : <div className="form">
              <Form onFinish={submit} initialValues={initialValues} form={form}>

                <div className="row mainrow">
                  <div className="col-sm" style={{
                    marginTop: '10px'
                  }}>


                    <Form.Item
                      defaultValue={KindHorse}
                      name="select1"
                      label="Horse Kind"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: 'Please select your Horse Kind!',
                        },
                      ]}
                    >
                      <Select

                        filterOption={false}
                        onChange={handleChange}


                        showSearch placeholder="Horse Kind" >

                        {
                          HorseKind?.map((item) => (


                            <Option value={item._id}>{item.NameEn} {"(" + item.NameAr + ")"}</Option>

                          ))
                        }
                      </Select>
                    </Form.Item>


                    <span className="spanForm selectSpacer">
                      <OverlayTrigger
                        overlay={<Tooltip id={`tooltip-top`}>Add more</Tooltip>}
                      >
                        <span className="addmore" onClick={handleShowHorseKind}>
                          +
                        </span>
                      </OverlayTrigger>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id={`tooltip-top`}>Fetch New</Tooltip>
                        }
                      >
                        <span className="addmore" onClick={FetchNew}>
                          <AiOutlineReload />
                        </span>
                      </OverlayTrigger>

                    </span>

                  </div>

                </div>
                <div className="row mainrow">
                  <div className="col-sm">



                    <Form.Item
                      name="HorseCode "
                      label="Horse Code"
                      rules={[
                        {
                          required: true,
                          message: 'Horse Code is required',
                        },
                      ]}
                    >
                      <Input placeholder="Horse Code" onChange={(e) => setHorseCode(e.target.value)}
                        value={HorseCode} name="HorseCode" type="number" onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }} />
                    </Form.Item>








                    <span className="spanForm spacer"> |</span>
                    <span className="CodeMsg">{CodeMsg}</span>

                  </div>
                  <div className="col-sm">
                    <p onClick={VerifyCode} className="verifybtn">Verify Code</p>

                  </div>
                  <span >{errors.HorseCode}</span>

                </div>
                <div className="row mainrow">
                  <div className="col-sm">





                    <Form.Item
                      name="Horse Name "
                      label="Horse Name"
                      rules={[
                        {
                          required: true,
                          message: 'Horse Name  is required',
                        },
                      ]}
                    >
                      <Input placeholder="Horse Name" onChange={(e) => setNameEn(e.target.value)}
                        value={NameEn} name="Horse Name" type="text" />
                    </Form.Item>


                    <span className="spanForm spacer"> |</span>

                  </div>

                  <div className="col-sm">
                    <Form.Item
                      name="NameAr"
                      label="اسم"
                      rules={[
                        {
                          required: true,
                          message: 'Name (Arabic) is required',
                        },
                      ]}
                      style={{ direction: 'rtl' }}
                    >
                      <Input placeholder="اسم" onChange={(e) => setNameAr(e.target.value)}
                        name="Name"
                        value={NameAr} />
                    </Form.Item>

                  </div>
                </div>
                <div className="row mainrow">
                  <div className="col-sm">


                    <Form.Item name="date-picker" label="Date of Birth" rules={[
                      {
                        required: true,
                        message: 'Date of Birth is required',
                      },
                    ]}>


                      <DatePicker value={DOB} onChange={handleDateChange} format="MM-DD-YYYY" />

                    </Form.Item>

                    <span className="spanForm spacer"> |</span>


                  </div>
                  <div className="col-sm ages" style={{
                    display: 'flex',
                    marginTop: '20px'
                  }}>
                    <p className="formodal">Horse Age :</p>
                    {
                      DOB === "" ? <></> : <Moment durationFromNow>
                        {DOB}
                      </Moment>
                    }

                  </div>

                </div>
                <div className="row mainrow">
                  <div className="col-sm">


                    <Form.Item

                      name="FOAL"
                      label="Foal"
                      hasFeedback

                    >
                      <Select
                        name="foal"
                        defaultValue={1}
                        value={Foals}
                        filterOption={false}
                        onChange={setFoal}

                        placeholder="Foal" >

                        {
                          Foals?.map((item) => (


                            <Option value={item.value}>{item.label} </Option>

                          ))
                        }
                      </Select>
                    </Form.Item>

                  </div>


                </div>
                <div className="row mainrow">
                  <div className="col-sm">



                    <Form.Item

                      name="select"
                      label="Nationality"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: 'Please select your Nationality!',
                        },
                      ]}
                    >
                      <Select
                        value={NationalityId}
                        filterOption={false}
                        onChange={setNationalityId}
                        onSearch={handleSearch}
                        showSearch placeholder="Please select a Nationality" >

                        {
                          NationalityList?.map((item) => (


                            <Option value={item._id}>{item.NameEn} ({item.NameAr})</Option>

                          ))
                        }
                      </Select>
                    </Form.Item>

                    <span className="spanForm selectSpacer">
                      <OverlayTrigger
                        overlay={<Tooltip id={`tooltip-top`}>Add more</Tooltip>}
                      >
                        <span className="addmore" onClick={handleShowActivenationality}>
                          +
                        </span>
                      </OverlayTrigger>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id={`tooltip-top`}>Fetch New</Tooltip>
                        }
                      >
                        <span className="addmore" onClick={FetchNew}>
                          <AiOutlineReload />
                        </span>
                      </OverlayTrigger>

                    </span>


                  </div>

                </div>
                <div className="row mainrow">
                  <div className="col-sm">



                    <Form.Item

                      name="color"
                      label="Color"

                      rules={[
                        {
                          required: true,
                          message: 'Please select your Color!',
                        },
                      ]}
                    >
                      <Select
                        name="Color"
                        value={ColorID}
                        filterOption={false}
                        onChange={setColor}
                        onSearch={handleSearch2}
                        showSearch placeholder="Please select a Color" >

                        {
                          colordropdown?.map((item) => (


                            <Option value={item._id}>{item.NameEn} {"(" + item.NameAr + ")"}</Option>

                          ))
                        }
                      </Select>

                    </Form.Item>

                    <span className="spanForm selectSpacer">
                      <OverlayTrigger
                        overlay={<Tooltip id={`tooltip-top`}>Add more</Tooltip>}
                      >
                        <span className="addmore" onClick={handleShowColor}>
                          +
                        </span>
                      </OverlayTrigger>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id={`tooltip-top`}>Fetch New</Tooltip>
                        }
                      >
                        <span className="addmore" onClick={FetchNew}>
                          <AiOutlineReload />
                        </span>
                      </OverlayTrigger>{" "}

                    </span>

                  </div>

                </div>
                <div className="row mainrow">
                  <div className="col-sm">




                    <Form.Item

                      name="gender"
                      label="Gender"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: 'Please select your Gender!',
                        },
                      ]}
                    >
                      <Select
                        name="gender"
                        value={Gender}
                        filterOption={false}
                        onChange={setGender}
                        onSearch={handleSearch1}
                        showSearch placeholder="Please select a Gender" >

                        {
                          gender?.map((item) => (


                            <Option value={item._id}>{item.NameEn} ({item.NameAr})</Option>

                          ))
                        }
                      </Select>

                    </Form.Item>


                    <span className="spanForm selectSpacer">
                      <OverlayTrigger
                        overlay={<Tooltip id={`tooltip-top`}>Add more</Tooltip>}
                      >
                        <span className="addmore" onClick={handleShowGender}>
                          +
                        </span>
                      </OverlayTrigger>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id={`tooltip-top`}>Fetch New</Tooltip>
                        }
                      >
                        <span className="addmore" onClick={FetchNew}>
                          <AiOutlineReload />
                        </span>
                      </OverlayTrigger>{" "}

                    </span>


                  </div>

                </div>

                <div className="row mainrow">
                  <div className="col-sm">










                    <Form.Item

                      name="dam"
                      label="Dam"
                      hasFeedback

                    >
                      <Select
                        name="dam"
                        value={Dam}
                        filterOption={false}
                        onChange={setDam}
                        onSearch={handleSearch3}
                        showSearch placeholder="Please select a dam" >

                        {
                          HorseKindData?.map((item) => (


                            <Option value={item._id}>{item.NameEn} ({item.NameAr}) {item.NationalityData ? item.NationalityData.NameEn : <></>}</Option>

                          ))
                        }
                      </Select>

                    </Form.Item>





                  </div>

                </div>
                <div className="row mainrow">
                  <div className="col-sm">







                    <Form.Item

                      name="Sire"
                      label="Sire"
                      hasFeedback

                    >
                      <Select
                        name="dam"
                        value={Sire}
                        filterOption={false}
                        onChange={setSire}
                        onSearch={handleSearch3}
                        showSearch placeholder="Please select a Sire" >

                        {
                          HorseKindData?.map((item) => (


                            <Option value={item._id}>{item.NameEn} ({item.NameAr}) {item.NationalityData ? item.NationalityData.NameEn : <></>}</Option>

                          ))
                        }
                      </Select>

                    </Form.Item>


                  </div>


                </div>
                <div className="row mainrow">
                  <div className="col-sm">


                    <Form.Item

                      name="GSire"
                      label="GSire"
                      hasFeedback

                    >

                      <Select
                        name="GSire"
                        value={GSire}
                        filterOption={false}
                        onChange={setGSire}
                        onSearch={handleSearch3}
                        showSearch placeholder="Please select a GSire" >

                        {
                          HorseKindData?.map((item) => (


                            <Option value={item._id}>{item.NameEn} ({item.NameAr}) {item.NationalityData ? item.NationalityData.NameEn : <></>}</Option>

                          ))
                        }
                      </Select>
                    </Form.Item>

                  </div>

                </div>
                <div className="row mainrow">
                  <div className="col-sm">



                    <Form.Item

                      name="owner"
                      label="Active Owner"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: 'Please select your Active Owner!',
                        },
                      ]}
                    >

                      <Select
                        name="Active Owner"
                        value={ActiveOwner}
                        filterOption={false}
                        onChange={setActiveOwner}
                        onSearch={handleSearch4}
                        showSearch placeholder="Please select a Active Owner" >

                        {
                          OwnerList?.map((item) => (


                            <Option value={item._id}>{item.NameEn} ({item.NameAr}) </Option>

                          ))
                        }
                      </Select>
                    </Form.Item>





                    <span className="spanForm  selectSpacer">
                      <OverlayTrigger
                        overlay={<Tooltip id={`tooltip-top`}>Add more</Tooltip>}
                      >
                        <span className="addmore" onClick={handleShowActiveOwner}>
                          +
                        </span>
                      </OverlayTrigger>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id={`tooltip-top`}>Fetch New</Tooltip>
                        }
                      >
                        <span className="addmore" onClick={FetchNew}>
                          <AiOutlineReload />
                        </span>
                      </OverlayTrigger>{" "}

                    </span>
                    <span className="error">{errors.ActiveOwner}</span>
                  </div>


                </div>
                <div className="row mainrow">
                  <div className="col-sm">








                    <Form.Item

                      name="trainer"
                      label="Active trainer"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: 'Please select your Active trainer!',
                        },
                      ]}
                    >

                      <Select
                        name="Active trainer"
                        value={ActiveTrainer}
                        filterOption={false}
                        onChange={setActiveTrainer}
                        onSearch={handleSearch5}
                        showSearch placeholder="Please select a Active trainer" >

                        {
                          TrainerList?.map((item) => (


                            <Option value={item._id}>{item.NameEn} ({item.NameAr}) </Option>

                          ))
                        }
                      </Select>
                    </Form.Item>



                    {/* <Select
                      placeholder={<div>Type to search Active trainer</div>}
                      defaultValue={ActiveTrainer}
                      onChange={setActiveTrainer}
                      options={traineroption}
                      onInputChange={handleChangeInput3}
                      isClearable={true}
                      isSearchable={true}

                      onBlur={() => ActiveTrainer === "" ? setErrorTrainer("Horse Trainer is required ") : setErrorTrainer("Horse Trainer is Validated ")}
                    /> */}
                    <span className="spanForm selectSpacer">
                      <OverlayTrigger
                        overlay={<Tooltip id={`tooltip-top`}>Add more</Tooltip>}
                      >
                        <span className="addmore" onClick={handleShowActiveTrainer}>
                          +
                        </span>
                      </OverlayTrigger>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id={`tooltip-top`}>Fetch New</Tooltip>
                        }
                      >
                        <span className="addmore" onClick={FetchNew}>
                          <AiOutlineReload />
                        </span>
                      </OverlayTrigger>{" "}

                    </span>

                  </div>

                </div>
                <div className="row mainrow">
                  <div className="col-sm">

                    <Form.Item

                      name="Breeder"
                      label=" Breeder"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: 'Please select your  Breeder!',
                        },
                      ]}
                    >

                      <Select
                        name="Breeder"
                        value={Breeder}
                        filterOption={false}
                        onChange={setBreeder}
                        onSearch={handleSearch6}
                        showSearch placeholder="Please select a Breeder" >

                        {
                          BreederList?.map((item) => (


                            <Option value={item._id}>{item.NameEn} ({item.NameAr}) </Option>

                          ))
                        }
                      </Select>
                    </Form.Item>





                    <span className="spanForm selectSpacer">
                      <OverlayTrigger
                        overlay={<Tooltip id={`tooltip-top`}>Add more</Tooltip>}
                      >
                        <span className="addmore" onClick={handleShowBreeder}>
                          +
                        </span>
                      </OverlayTrigger>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id={`tooltip-top`}>Fetch New</Tooltip>
                        }
                      >
                        <span className="addmore" onClick={FetchNew}>
                          <AiOutlineReload />
                        </span>
                      </OverlayTrigger>{" "}

                    </span>
                    <span className="error">{errors.Breeder}</span>
                  </div>

                </div>




                <div className="row mainrow">
                  <div className="col-sm">


                    <Form.Item

                      name="gelded"
                      label=" Gelded"

                    // rules={[
                    //   {
                    //     required: true,
                    //     message: 'Please select your Gelded!',
                    //   },
                    // ]}
                    >

                      <Select
                        name="gelded"
                        value={isGelted}
                        // defaultValue={isGelted}
                        filterOption={false}
                        onChange={setisGelted}
                        showSearch placeholder="Please select a Gelded" >

                        {
                          Gelteds?.map((item) => (


                            <Option value={item.id}>{item.label}  </Option>

                          ))
                        }
                      </Select>
                    </Form.Item>



                  </div>

                </div>
                <div className="row mainrow">
                  <div className="col-sm">


                    <Form.Item

                      name="Rds"
                      label=" Rds"

                    // rules={[
                    //   {
                    //     required: true,
                    //     message: 'Please select your Rds!',
                    //   },
                    // ]}
                    >

                      <Select
                        name="Rds"
                        // defaultValue={Rds}
                        value={Rds}
                        filterOption={false}
                        onChange={setRds}
                        showSearch placeholder="Please select a Rds" >

                        {
                          Gelteds?.map((item) => (


                            <Option value={item.id}>{item.label}  </Option>

                          ))
                        }
                      </Select>
                    </Form.Item>


                  </div>

                </div>
                <div className="row mainrow">
                  <div className="col-sm">


                    <Form.Item

                      name="HorseStatus"
                      label="Horse Status"
                    >

                      <Select
                        name="HorseStatus"
                        value={HorseStatus}
                        filterOption={false}
                        onChange={setHorseStatus}
                        showSearch placeholder="Please select a Horse Status" >

                        {
                          Gelteds?.map((item) => (


                            <Option value={item.value}>{item.label}  </Option>

                          ))
                        }
                      </Select>
                    </Form.Item>


                  </div>

                </div>

                <div className="row mainrow">
                  <div className="col-sm">


                    <Form.Item
                      name="PurchasePrice"
                      label="Purchase Price"
                      rules={[
                        {
                          required: true,
                          message: 'Purchase Price is required',
                        },
                      ]}
                    >
                      <Input placeholder="Purchase Price" onChange={(e) =>
                        setDstate({ ...Dstate, PurchasePrice: e.target.value })
                      }

                        value={Dstate.PurchasePrice} name="PurchasePrice" type="number" onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}

                        maxLength='7'

                        min='0'
                      />
                    </Form.Item>







                    {/* 
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Purchase Price"
                      className="mb-3"
                      onChange={(e) =>
                        setDstate({ ...Dstate, PurchasePrice: e.target.value })
                      }

                    >
                      <Form.Control
                        maxLength='7'
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        min='0'
                        value={Dstate.PurchasePrice}

                        placeholder="Purchase Price"
                      />
                    </FloatingLabel> */}

                  </div>

                </div>

                <div className="row mainrow">
                  <div className="col-sm">






                    <Form.Item
                      name="Remarks"
                      label="Remarks"

                    >
                      <Input placeholder="Remarks" onChange={(e) => setRemarksEn(e.target.value)}
                        value={RemarksEn} name="Remarks" />
                    </Form.Item>










                    <span className="spanForm spacer"> |</span>

                  </div>

                  <div className="col-sm">












                    <Form.Item
                      name="RemarksAr"
                      label="ملاحظات"

                      style={{ direction: 'rtl' }}
                    >
                      <Input placeholder="ملاحظات" onChange={(e) => setRemarksAr(e.target.value)}
                        name="RemarksAr"
                        value={RemarksAr} />
                    </Form.Item>



                  </div>
                </div>
                <div className="ButtonSection">
                  <div>
                    <label className="Multipleownerlabel">
                      Select Horse image
                    </label>
                    <input
                      type="file"
                      onChange={onSelectFile}
                      className="formInput"
                      id="file"
                    />
                    {image && (
                      <>
                        <ImCross onClick={handlePreview} className="crossIcon" />
                        <img src={preview} className="PreviewImage" alt="" />
                      </>
                    )}
                  </div><div>
                    <button
                      type="submit"
                      disabled={isLoading}
                      className="SubmitButton"
                    >
                                           Save

                    </button></div>
                </div>
              </Form>
            </div>}
          </div>
        </div>
      </div>

      <Modal
        show={showBreeder}
        onHide={handleCloseBreeder}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2> Breeder</h2>
        </Modal.Header>
        <Modal.Body>
          <BreederPopup setShowBreeder={setShowBreeder} />
        </Modal.Body>
      </Modal>
      <Modal
        show={showColor}
        onHide={handleCloseColor}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2>Color</h2>
        </Modal.Header>
        <Modal.Body>
          <ColorPopup setShowColor={setShowColor} />
        </Modal.Body>
      </Modal>
      <Modal
        show={showActiveOwner}
        onHide={handleCloseActiveOwner}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2>Owner</h2>
        </Modal.Header>
        <Modal.Body>
          <OwnerPopup setShowActiveOwner={setShowActiveOwner} />
        </Modal.Body>
      </Modal>
      <Modal
        show={showActiveTrainer}
        onHide={handleCloseActiveTrainer}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2>Trainer</h2>
        </Modal.Header>
        <Modal.Body>
          <TrainerPopup setShowActiveTrainer={setShowActiveTrainer} />
        </Modal.Body>
      </Modal>
      <Modal
        show={showGender}
        onHide={handleCloseGender}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2>Gender</h2>
        </Modal.Header>
        <Modal.Body>
          <GenderPopup setShowGender={setShowGender} />
        </Modal.Body>
      </Modal>
      <Modal
        show={showActivenationality}
        onHide={handleCloseActivenationality}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2>Nationality</h2>
        </Modal.Header>
        <Modal.Body>
          <NationalityPopup setShowNationality={setShowNationality} />
        </Modal.Body>
      </Modal>
      <Modal
        show={showHorseKind}
        onHide={handleCloseHorseKind}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2>Horse Kind</h2>
        </Modal.Header>
        <Modal.Body>
          <HorseKindPopup setshowhorseKind={setShowHorseKind} />
        </Modal.Body>
      </Modal>

    </Fragment>
  );
};

export default HorseForm;