import React, { useEffect, useState } from "react";
import { fetchRaceName } from "../../redux/getReducer/getRaceName";
import { fetchMeeting } from "../../redux/getReducer/getMeeting";
import { fetchracecourse } from "../../redux/getReducer/getRaceCourseSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import "../../Components/CSS/Table.css";
import ScrollContainer from "react-indiana-drag-scroll";
import Select from "react-select";
import "../../Components/CSS/race.css";
import { Modal } from "react-bootstrap";
import { MdAutoDelete, MdDelete } from "react-icons/md";
import swal from "sweetalert";
import Moment from "react-moment";
import Lottie from "lottie-react";
import HorseAnimation from "../../assets/horselottie.json";
import axios from "axios";
import { BiEdit } from "react-icons/bi";
import RaceDetailPopup from "../../Components/Popup/RaceDetailPopup";
import { BsEyeFill } from "react-icons/bs";
import Pagination from "./Pagination";
import { BiFilter } from "react-icons/bi";
import { CSVLink } from "react-csv";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { DateRangePicker } from "react-date-range";
import Defaultimg from "../../assets/default.jpg";
import { fetchRaceTypeDropdown } from "../../redux/getDropDownReducer/getRaceType";
import Notfound from "../../Notfound";
import Form from "react-bootstrap/Form";

const Prize = (data) => {
  return (
    <>
      <table className="Prizeclass">
        <thead className="Prizeclassthead">
          <tr>
            <th>1st</th>
            <th>2nd </th>
            <th>3rd </th>
            <th>4th </th>
            <th>5th </th>
            <th>6th </th>
          </tr>
        </thead>
        <tbody className="Prizeclasstbody">
          <tr>
            <td>{data.data.FirstPrice}</td>
            <td>{data.data.SecondPrice}</td>
            <td>{data.data.ThirdPrice}</td>
            <td>{data.data.FourthPrice}</td>
            <td>{data.data.FifthPrice}</td>
            <td>{data.data.SixthPrice}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

const Races = () => {
  const [ShowCalender, setShowCalender] = useState(false);
  const [MeetingType, setMeetingType] = useState("");
  const [loading, setLoading] = useState(false);
  const [SearchRaceName, setRaceName] = useState("");
  const [TotalCount, setTotalCount] = useState();
  const [SearchRaceTyp, setRaceType] = useState("");
  const [RaceCourse, setRaceCourse] = useState("");
  const [SearchData, setSearchData] = useState([]);
  const [TotalPages, setTotalPages] = useState("");
  const [showStatus, setShowStatus] = useState(null);
  const [postsPerPage] = useState(5);
  const { userInfo } = useSelector((state) => state.user);
  const [StatusData, setStatusData] = useState(false);
  const [TotalPageData, setTotalPageData] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const [state, setState] = useState([
    {
      startDate: new Date("2023-01-01"),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const [SearchStartdate1, setStartdate1] = useState("");
  const [SearchEndDate1, setEndDate1] = useState("");
  const [Value1, setValue1] = useState('');

  const { data: RaceName } = useSelector((state) => state.RaceName);
  const { data: meeting } = useSelector((state) => state.meeting);

  const { data: racecourse } = useSelector((state) => state.racecourse);

  let MeetingTypes =
    meeting === undefined ? (
      <></>
    ) : (
      meeting.map(function (item) {
        return {
          id: item._id,
          value: item.NameEn,
          label: (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <p>{item.NameEn}</p>
            </div>
          ),
        };
      })
    );
  let racecourses =
    racecourse === undefined ? (
      <></>
    ) : (
      racecourse.map(function (item) {
        return {
          id: item._id,
          value: item.TrackNameEn,
          label: (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <p>{item.TrackNameEn}</p>
            </div>
          ),
        };
      })
    );
  let Racenameoptions =
    RaceName === undefined ? (
      <></>
    ) : (
      RaceName.map(function (item) {
        return {
          id: item._id,
          value: item.NameEn,
          label: (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <p>{item.NameEn}</p>
            </div>
          ),
        };
      })
    );

  const typ = SearchRaceTyp ? SearchRaceTyp.id : "";
  const name = SearchRaceName ? SearchRaceName.id : "";
  const meet = MeetingType ? MeetingType.id : "";
  const course = RaceCourse ? RaceCourse.id : "";

  function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  function convertDateFormat(dateString) {
    // Create a new Date object from the given date string
    var date = new Date(dateString);

    // Get the individual components of the date
    var year = date.getFullYear();
    var month = ("0" + (date.getMonth() + 1)).slice(-2);
    var day = ("0" + date.getDate()).slice(-2);
    var hours = ("0" + date.getHours()).slice(-2);
    var minutes = ("0" + date.getMinutes()).slice(-2);
    var seconds = ("0" + date.getSeconds()).slice(-2);

    // Assemble the converted date format
    var convertedDate = year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;

    // Return the converted date format
    return convertedDate;
  }
  let startdate1 = !StatusData ? '' : convertDateFormat(state[0].startDate);
  let enddate1 = !StatusData ? '' : convertDateFormat(state[0].endDate);



  const GetSearch = async () => {
    setLoading(true);
    const response = await axios.get(
      `${window.env.API_URL}/SearchRace?MeetingType=${meet}&RaceName=${name}&RaceCourse=${course}&racestartday=${startdate1 == undefined ? '' : startdate1} &raceendday=${enddate1 == undefined ? '' : enddate1}&page=${currentPage}`
    );

    setSearchData(response.data.data);
    setLoading(false);
    const totalcount = response.data.totalcount;
    const totalpagecount = response.data.totalPages;

    setTotalCount(totalcount);
    const TotalPagesData = totalcount / 8;
    setTotalPages(totalpagecount);
    setTotalPageData(response.data.totalcount);

  };


  const history = useNavigate();

  const [show, setShow] = useState(false);
  const [modaldata, setmodaldata] = useState();
  const handleClose = () => setShow(false);
  const handleShow = async (data) => {
    setmodaldata(data);
    await setShow(true);
  };

  const [showRacePopup, setShowRacePopup] = useState(false);
  const [modaldataPopup, setmodaldataPopup] = useState();
  const handleCloseRacePopup = () => setShowRacePopup(false);

  const handleShowRacePopup = async (data) => {
    setmodaldataPopup(data);
    await setShowRacePopup(true);
  };

  const dispatch = useDispatch();
  const { data: race } = useSelector((state) => state.race);


  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const SearchCode = "";
  const SearchTitle = "";
  const SearchAge = "";

  useEffect(() => {
    if (TotalPageData < 12) {
      setCurrentPage(1);
    }
  }, [TotalPageData]);

  useEffect(() => {

    GetSearch({ currentPage });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, currentPage]);

  const handleOpen = () => {
    setShowCalender(!ShowCalender);
  };

  const shouldRunEffect = ShowCalender;

  useEffect(() => {
    if (shouldRunEffect) {
      dispatch(fetchMeeting({ SearchTitle, SearchCode, currentPage }));
      dispatch(fetchRaceName());
    }
  }, [shouldRunEffect, dispatch, SearchTitle, SearchCode, currentPage]);

  useEffect(() => {
    dispatch(fetchracecourse({ Value1 }));

  }, [Value1, dispatch]);


  const handleRemove1 = async (Id) => {
    try {
      swal({
        title: "Are you sure?",
        text: "do you want to delete this data ?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        await axios.delete(`${window.env.API_URL}/DeleteRaceAlltoAll/${Id}`);

        if (willDelete) {
          swal("Your data has been deleted Successfully!", {
            icon: "success",
          });
          GetSearch();
        } else {
          swal("Your data is safe!");
        }
      });
    } catch (error) {
      const err = error.response.data.message;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
    }
  };

  const handleRemove = async (id) => {
    try {
      swal({
        title: "Are you sure?",
        text: "do you want to delete this data ?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          setLoading(true)
          const res = await axios.delete(`${window.env.API_URL}/DeleteRaceAlltoAll/${id}`);
          const msg = res.data.message ?  res.data.message : "deleted";
          swal(msg, {
            icon: "",
          });
      let data1 =  JSON.stringify(res.data.data)
      const blob = new Blob([data1]);
      if(res?.data?.flag){
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.download = "report1.json";
        link.href = url;
        link.click();
        setLoading(false)
            GetSearch({ currentPage });

      }     
      setLoading(false)
          GetSearch({ currentPage });

        } else {
          swal("Your data is safe!");
        }
      });
    } catch (error) {
      const err = error.success;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
      setLoading(false)

    }
  }

  const handleSearch6 = (value) => {

    setValue1(value);

  };

  const showstatusHandler = async (i) => {


    await setShowStatus(i);


  };
  const changeStatusHandler = async (id, Value, Status) => {



    if (Value === Status) {
      return (
        swal({
          title: "Error!",
          text: `Race Status is Already ${Status} `,
          icon: "error",
          button: "OK",
        })
      );
    }
    try {
      swal({
        title: "Are you sure?",
        text: `do you want to Change this Race Status ${Status} to ${Value} ?`,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {

        if (Value === Status) {

        }
        const formData = new FormData();
        formData.append("RaceStatus", Value);

        await axios.put(
          `${window.env.API_URL}ChangeRaceStatus/${id}`, formData);
        if (willDelete) {
          swal("Race Status is Updated Successfully!", {
            icon: "success",
          });
          GetSearch();
        } else {
          swal("Race Status Not Changed!");
        }
      });
    } catch (error) {
      swal({
        title: "Error!",
        text: error.message,
        icon: "error",
        button: "OK",
      });
    }
  };
  let racetime = SearchData ? SearchData.map((item) => {
    return (item.StartTime);
  }) : <></>;

  const handleStatus = async () => {
    setStatusData(!StatusData);

  };
  return (
    <>
      <div className="page">
        <div className="rightsidedata">
          <div
            style={{
              marginTop: "30px",
            }}
          >
            <div className="Header ">
              <h4> Race Listings</h4>
              <div>
                <Link to="/raceform">
                  <button>Add Race</button>
                </Link>
                <OverlayTrigger
                  overlay={<Tooltip id={`tooltip-top`}>Filter</Tooltip>}
                >
                  <span className="addmore">
                    <BiFilter
                      className="calendericon"
                      onClick={() => setShowCalender(!ShowCalender)}
                    />
                  </span>
                </OverlayTrigger>{" "}
                <CSVLink
                  data={race}
                  separator={";"}
                  filename={"MKS Race.csv"}
                  className="csvclass"
                >
                  Export CSV
                </CSVLink>
              </div>
            </div>
            <div>
              {ShowCalender ? (
                <>
                  <div className="userfilter userFilter">
                    <div className="calenderuser">
                      <DateRangePicker
                        onChange={(item) => setState([item.selection])}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={2}
                        ranges={state}
                        direction="horizontal"
                      />
                    </div>

                    <div className="filtertextform filterTextForm">
                      <div className="">
                        <p>Active Calender</p>
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          onChange={() => handleStatus()}
                          defaultChecked={StatusData}
                        />
                      </div>
                      <div className="searchDropdown">
                        <Select
                          placeholder={<div>Race Name</div>}
                          defaultValue={SearchRaceName}
                          onChange={setRaceName}
                          options={Racenameoptions}
                          isClearable={true}
                          isSearchable={true}
                        />
                      </div>
                      <div className="searchDropdown">
                        <Select
                          placeholder={<div>Race Course</div>}
                          defaultValue={RaceCourse}
                          onChange={setRaceCourse}
                          onInputChange={handleSearch6}
                          options={racecourses}
                          isClearable={true}
                          isSearchable={true}
                        />
                      </div>
                      <div className="searchDropdown">
                        <Select
                          placeholder={<div>Meeting Type</div>}
                          defaultValue={MeetingType}
                          onChange={setMeetingType}
                          options={MeetingTypes}
                          isClearable={true}
                          isSearchable={true}
                        />
                      </div>
                      {/* <div className="searchDropdown">
                        <Select
                          placeholder={<div>Select Race Type</div>}
                          defaultValue={SearchRaceTyp}
                          onChange={setRaceType}
                          options={RaceTypes}
                          isClearable={true}
                          isSearchable={true}
                        />
                      </div> */}
                    </div>

                    <div className="filtertextform  "></div>
                  </div>
                  <button className="filterbtn filterBtn" onClick={GetSearch}>
                    Apply Filter
                  </button>
                </>
              ) : (
                <></>
              )}
            </div>
            <div className="div_maintb">
              <ScrollContainer className="scroll-container">
                <table className="Sc">
                  <thead
                    style={{
                      marginTop: "30px",
                    }}
                  >
                    <tr className="trtabletd">
                      <th>Action</th>
                      <th>Race Name</th>
                      <th>Date</th>
                      <th>Race Number</th>
                      <th>Race Course</th>
                      <th>Time</th>
                      <th>Ground</th>
                      <th>Cancel race</th>

                      {/* <th>Action</th>
                      <th>Race Name</th>
                      <th>Race Name Arabic </th>

                      <th>Day</th>
                      <th>Race Course</th>

                     
                      
                      <th>Prize Money</th>
                      <th>Race Number</th>
                      <th>Race Kind </th>
                     
                      <th>Time</th>
                      <th>Meeting Code</th>
                      <th>Meeting Type </th>
                      <th>Race Type</th>
                     
                      <th>Description</th>
                      <th>Description Arabic</th>
                      <th>Number of Horses</th>
                      <th>Weather in Degree</th>
                      <th>Weather Type</th>

                     
                      // <th>Result Status</th>
                      <th>Horse Kind</th>
                      <th>Change Race Status</th>
                      <th>Sponsor</th>
                      <th>Sponsor Image</th> */}
                    </tr>
                  </thead>
                  {loading ? (
                    <Lottie
                      animationData={HorseAnimation}
                      loop={true}
                      className="TableLottie"
                    />
                  ) : (
                    <>
                      {SearchData.length <= 0 ? (
                        <Notfound />
                      ) : (
                        SearchData.map((item, i) => {
                          const { RaceStatus } = item;


                          return (
                            <tbody
                              key={item._id}
                              style={{
                                marginTop: "20px",
                              }}
                            >
                              <tr>
                                <td
                                  className="table_delete_btn1"
                                  style={{
                                    background: `${RaceStatus === "Cancelled"
                                      ? "#000000"
                                      : RaceStatus === "Completed"
                                        ? "#FF0000"
                                        : RaceStatus === "Live"
                                          ? "#5EC30F"
                                          : "#FF9900"
                                      }`,
                                    color: `${RaceStatus === "Cancelled"
                                      ? "#ffff"
                                      : RaceStatus === "Completed"
                                        ? "#00000"
                                        : RaceStatus === "Live"
                                          ? "#00000"
                                          : "#000000"
                                      }`,
                                  }}
                                >
                                  <BiEdit
                                    onClick={() =>
                                      history("/editrace", {
                                        state: {
                                          fullraceid: item,
                                        },
                                      })
                                    }
                                  />
                                  <MdAutoDelete
                                    style={{
                                      fontSize: "22px",
                                    }}
                                    onClick={() => handleRemove(item._id)}
                                  />
                                  <BsEyeFill
                                    onClick={() => handleShowRacePopup(item)}
                                  />
                                </td>
                                <td>
                                  {!item.RaceNameModelData ? (
                                    <></>
                                  ) : (
                                    item.RaceNameModelData.NameEn
                                  )}
                                </td>


                                <td>
                                  <Moment format="YYYY/MM/DD">
                                    {item.Day}
                                  </Moment>
                                </td>
                                <td>{item.RaceNumber}</td>
                                <td>
                                  {item.RaceCourseData === null ? (
                                    <>-</>
                                  ) : (
                                    item.RaceCourseData.TrackNameEn
                                  )}
                                </td>

                                {/* <td>
                                  <button
                                    className="Approvedbtn resultbtn"
                                    onClick={() => handleShow(item)}
                                  >
                                    Click
                                  </button>
                                </td> */}



                                {/* <td>
                                  {item.RaceKindData
                                    ? item.RaceKindData.NameEn
                                    : "-"}
                                </td> */}



                                <td>
                                  {new Date(racetime[i]).toLocaleTimeString()}
                                </td>
                                <td>{item?.TrackLengthData?.GroundTypeModelData?.NameEn}</td>
                                {/* <td>
                                  {item.MeetingTypeData
                                    ? item.MeetingTypeData.NameEn
                                    : "-"}
                                </td>
                                <td>
                                  {item.RaceTypeModelData === null ? (
                                    <>-</>
                                  ) : (
                                    item.RaceTypeModelData.NameEn
                                  )}
                                </td> */}

                                {/* <td
                                  style={{
                                    maxHeight: "400px",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {item.DescriptionEn}
                                </td> */}
                                {/* <td
                                  style={{
                                    maxHeight: "400px",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {item.DescriptionAr}
                                </td>

                                <td>{item.RacehorsesData.length}</td>
                                <td>{item.WeatherDegree}</td>
                                <td>{item.WeatherType}</td> */}


                                {/* <td>{item.RaceStatus}</td>
                                <td>{item.ResultStatus}</td>
                                <td>
                                  {item.HorseKindinRaceData ? (
                                    item.HorseKindinRaceData.NameEn
                                  ) : (
                                    <></>
                                  )}
                                </td> */}

                                <td>
                                  <button
                                    className="changeStatusbtn compBtn" onClick={() => changeStatusHandler(item._id, "Cancelled", item.RaceStatus)} >

                                    Click
                                  </button>

                                  {/* {showStatus === i && (
  <>
    <button
      className="changeStatusbtn liveBtn" onClick={() => changeStatusHandler(item._id, "Live", item.RaceStatus)}  >

      Live
    </button>

    <button
      className="changeStatusbtn dueBtn" onClick={() => changeStatusHandler(item._id, "Due", item.RaceStatus)} >

      Due
    </button>

    <button
      className="changeStatusbtn compBtn" onClick={() => changeStatusHandler(item._id, "Cancelled", item.RaceStatus)} >

      Cancelled
    </button>


  </>


)}

<button
  className="statusBtn" onClick={() => showstatusHandler(i)} >

  Change Status

</button> */}

                                  <>
                                  </>






                                </td>

                                {/* <td>
                                  {item.SponsorData
                                    ? item.SponsorData.TitleEn
                                    : "-"}{" "}
                                </td>
                                
                                <td>
                                  <img
                                    src={
                                      item.SponsorData.image
                                        ? item.SponsorData.image
                                        : Defaultimg
                                    }
                                    alt=""
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                    }}
                                  />{" "}
                                </td> */}
                              </tr>
                            </tbody>
                          );
                        })
                      )}
                    </>
                  )}
                </table>
              </ScrollContainer>
            </div>
          </div>
          <Pagination
            postsPerPage={postsPerPage}
            totalPosts={TotalCount}
            paginate={paginate}
            currentPage={currentPage}
            TotalPages={TotalPages}
          />
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2>Race Prize </h2>
        </Modal.Header>
        <Modal.Body>
          <Prize data={modaldata} />
        </Modal.Body>

      </Modal>

      <Modal
        show={showRacePopup}
        onHide={handleCloseRacePopup}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2 style={{ fontFamily: "inter" }}>Race</h2>
        </Modal.Header>
        <Modal.Body>
          <RaceDetailPopup data={modaldataPopup} />
        </Modal.Body>
        <Modal.Footer>
          <button onClick={handleCloseRacePopup} className="modalClosebtn">
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default Races;
