import React, { useEffect, Fragment, useState } from "react";
import {
  fetchTrackLength,
  STATUSES,
} from "../../redux/getReducer/getTracklength";
import { fetchracecourse } from "../../redux/getReducer/getRaceCourseSlice";
import { useDispatch, useSelector } from "react-redux";
import { MdAutoDelete, MdDelete } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import ScrollContainer from "react-indiana-drag-scroll";
import Select from "react-select";
import Lottie from "lottie-react";
import HorseAnimation from "../../assets/horselottie.json";
import axios from "axios";
import { BiEdit } from "react-icons/bi";
import { BsEyeFill } from "react-icons/bs";
import TrackLengthPopup from "../../Components/Popup/TrackLengthPopup";
import { Modal } from "react-bootstrap";
import Pagination from "./Pagination";
import { BiFilter } from "react-icons/bi";
import { CSVLink } from "react-csv";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { fetchgroundtypelist } from "../../redux/getDropDownReducer/getGroundList";
import Notfound from "../../Notfound";
import Defaultimg from "../../assets/Placeholder.jpg"

const Tracklength = () => {
  //------------------------state-----------------------//
  const [ShowCalender, setShowCalender] = useState(false);
  const [Loading, setLoading] = useState(false);

  const [SearchTitle, setSearchTitle] = useState("");

  const [SearchGroundType, setGroundType] = useState();
  const [TotalCount, setTotalCount] = useState();
  const [TotalPages, setTotalPages] = useState("");
  const { userInfo } = useSelector((state) => state.user);

  const [show, setShow] = useState(false);
  const [modaldata, setmodaldata] = useState();
  //------------------------modal-----------------------//
  const handleClose = () => setShow(false);
  const handleShow = async (data) => {
    setmodaldata(data);
    await setShow(true);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: trackLength, status } = useSelector(
    (state) => state.trackLength
  );

  const { data: GroundList } = useSelector((state) => state.GroundList);

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(8);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  //------------------------Api-----------------------//
  const Data1 = async () => {
    const res = await axios.get(
      `${window.env.API_URL}TrackLengthget?TrackLength=${SearchTitle}&page=${currentPage}`
    );
    const totalcount = res.data.totalcount;
    setTotalCount(totalcount);
    const TotalPagesData = res.data.totalPages;
    setTotalPages(TotalPagesData);
  };
  //------------------------options for dropdown-----------------------//

  let groundtypeopt =
    GroundList === undefined ? (
      <></>
    ) : (
      GroundList.map(function (item) {
        return {
          id: item._id,
          value: item.NameEn,
          label: (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <p>{item.NameEn}</p>
            </div>
          ),
        };
      })
    );

  const Grounds = SearchGroundType ? SearchGroundType.id : "";

  const GetSearch = async () => {
    dispatch(fetchTrackLength({ SearchTitle, Grounds ,currentPage}));
    setCurrentPage(1)
  };

  const SearchAge = "";
  useEffect(() => {
    dispatch(fetchTrackLength({ SearchTitle, Grounds ,currentPage}));
    dispatch(fetchgroundtypelist());
    dispatch(fetchracecourse({ SearchTitle, SearchAge }));
    Data1();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, currentPage]);
  //------------------------delete-----------------------//
  const handleRemove1 = async (Id) => {
    try {
      swal({
        title: "Are you sure?",
        text: "do you want to delete this data ?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          await axios.delete(
            `${window.env.API_URL}/softdeleteTrackLength/${Id}`
          );
          swal("Your data has been deleted Successfully!", {
            icon: "success",
          });
          dispatch(fetchTrackLength({ SearchTitle }));
        } else {
          swal("Your data is safe!");
        }
      });
    } catch (error) {
      const err = error.response.data.message;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
    }
  };

  const handleRemove = async (id) => {
    try {
      swal({
        title: "Are you sure?",
        text: "do you want to delete this data ?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          setLoading(true)
          const res = await axios.delete(`${window.env.API_URL}/DeleteTrackLengthAlltoAll/${id}`);
          const msg = res.data.message ?  res.data.message : "deleted";
          swal(msg, {
            icon: "",
          });
      let data1 =  JSON.stringify(res.data.data)
      const blob = new Blob([data1]);
      if(res?.data?.flag){
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.download = "report1.json";
        link.href = url;
        link.click();
        setLoading(false)
            GetSearch({ currentPage });

      }     
      setLoading(false)
          GetSearch({ currentPage });

        } else {
          swal("Your data is safe!");
        }
      });
    } catch (error) {
      const err = error.success;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
      setLoading(false)

    }
  }

  if (status === STATUSES.ERROR) {
    return (
      <h2
        style={{
          margin: "100px",
        }}
      >
        Something went wrong!
      </h2>
    );
  }

  return (
    <Fragment>
      <div className="page">
        <div className="rightsidedata">
          <div
            style={{
              marginTop: "30px",
            }}
          >
            <div className="Header ">
              <h4>Track Length Listings</h4>

              <div>
                <Link to="/tracklengthform">
                  <button>Add Track Length</button>
                </Link>
                <OverlayTrigger
                  overlay={<Tooltip id={`tooltip-top`}>Filter</Tooltip>}
                >
                  <span className="addmore">
                    <BiFilter
                      className="calendericon"
                      onClick={() => setShowCalender(!ShowCalender)}
                    />
                  </span>
                </OverlayTrigger>
                <CSVLink
                  data={trackLength}
                  separator={";"}
                  filename={"MKS Track Length.csv"}
                  className="csvclass"
                >
                  Export CSV
                </CSVLink>
              </div>
            </div>
            <div>
              {ShowCalender ? (
                <span className="transitionclass">
                  <div className="userfilter">
                    <div className="filtertextform forflex">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Track Length"
                        onChange={(e) => setSearchTitle(e.target.value)}
                      />

                      <div className="searchDropdown searchDropdown1">
                        <Select
                          placeholder={<div>Select Ground Type</div>}
                          defaultValue={SearchGroundType}
                          onChange={setGroundType}
                          options={groundtypeopt}
                          isClearable={true}
                          isSearchable={true}
                        />
                      </div>
                    </div>
                  </div>
                  <button className="filterbtn" onClick={GetSearch}>
                    Apply Filter
                  </button>
                </span>
              ) : (
                <></>
              )}
            </div>
            <>
              <div className="div_maintb">
                <ScrollContainer>
                  <table>
                    <thead>
                      <tr>
                        <th>Action</th>

                        <th>Track Length</th>

                        <th>Rail Position</th>
                        <th>Ground Type</th>
                        <th>Race Course</th>
                        <th>Race Course Arabic</th>
                        <th>Image</th>

                      </tr>
                    </thead>
                    {trackLength === 0 ? (
                      <Notfound />
                    ) : (
                      <tbody>
                        {status === STATUSES.LOADING ? (
                          <Lottie
                            animationData={HorseAnimation}
                            loop={true}
                            className="TableLottie"
                          />
                        ) : (
                          trackLength.map((item, index) => {
                            return (
                              <>
                                <tr className="tr_table_class">
                                  <td className="table_delete_btn1">
                                    <BiEdit
                                      onClick={() =>
                                        navigate("/edittrack", {
                                          state: {
                                            trackid: item,
                                          },
                                        })
                                      }
                                    />
                                    <MdAutoDelete
                                    style={{
                                      fontSize: "22px",
                                    }}
                                    onClick={() => handleRemove(item._id)}
                                  />
                                    <BsEyeFill
                                      onClick={() => handleShow(item)}
                                    />
                                  </td>
                                  <td>{item.TrackLength}</td>
                                  {/* <td>
                                    {item.TrackLengthRaceCourseData === null ||
                                      undefined ? (
                                      <>N/A</>
                                    ) : (
                                      <>
                                        {item.TrackLengthRaceCourseData.TrackNameEn}
                                      </>
                                    )}
                                  </td> */}
                                  <td>{item.RailPosition}</td>
                                  <td>
                                    {item.GroundTypeModelData === null ||
                                      undefined ? (
                                      <>N/A</>
                                    ) : (
                                      <>{item.GroundTypeModelData.NameEn}</>
                                    )}
                                  </td>
                                  <td>{item.TrackLengthRaceCourseData ? item.TrackLengthRaceCourseData.TrackNameEn : <></>}</td>
                                  <td>{item.TrackLengthRaceCourseData ? item.TrackLengthRaceCourseData.TrackNameAr : <></>}</td>
                                  <td>

                                    <img src={item.RaceCourseImage ? item.RaceCourseImage : Defaultimg} alt="" />
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        )}
                      </tbody>
                    )}
                  </table>
                </ScrollContainer>
              </div>
            </>
          </div>
          <span className="plusIconStyle"></span>
          <Pagination
            postsPerPage={postsPerPage}
            totalPosts={TotalCount}
            paginate={paginate}
            currentPage={currentPage}
            TotalPages={TotalPages}
          />
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2 style={{ fontFamily: "inter" }}>TrackLength </h2>
        </Modal.Header>
        <Modal.Body>
          <TrackLengthPopup data={modaldata} />
        </Modal.Body>
        <Modal.Footer>
          <button onClick={handleClose} className="modalClosebtn">
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default Tracklength;
