import React from 'react'
import { useEffect } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { AiOutlineReload } from 'react-icons/ai';
import { TiTick } from 'react-icons/ti';
import { useLocation ,useNavigate } from 'react-router-dom';
import Select from "react-select";
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { fetchGroupName } from '../../redux/getReducer/getGroupName';
import { fetchEditPointTableSystemV2 } from '../../redux/getReducer/getEditPointTableV2';
import swal from 'sweetalert';
import axios from 'axios';
import { ImCross } from 'react-icons/im';

const EditPoints = () => {

  const dispatch = useDispatch()
  const { state } = useLocation();


  const { pointid } = state;
  const { data: EditPointTableSystemV2 } = useSelector((state) => state.EditPointTableSystemV2);
  const [data1, setData] = useState(EditPointTableSystemV2);
  const [isLoading, setisLoading] = useState(false);
  const [Rank, setRank] = useState('');
  const [Bonus, setBonus] = useState('');
  const [Point, setPoint] = useState('');
  const history = useNavigate();

  let id = pointid._id
  
  
  
  let MapGate = [];
  for (var i = 0; i < EditPointTableSystemV2.length; i++) {
    MapGate.push({
      Rank: EditPointTableSystemV2[i].Rank,
      Point: EditPointTableSystemV2[i].Point,
      BonusPoint: EditPointTableSystemV2[i].BonusPoint,
      id: EditPointTableSystemV2[i]._id,
    });
  }
  
  useEffect(() => {
    setData(EditPointTableSystemV2)
  },[])
  useEffect(() => {
    dispatch(fetchEditPointTableSystemV2({id}))
  },[id])

  const saveEditItem = async (event, data) => {
    event.preventDefault();
    try {
      const formData = new FormData();

      formData.append("Rank", Rank == '' ? data.Rank : Rank);
      formData.append("Point", Point == '' ? data.Point : Point);
      formData.append("BonusPoint", Bonus == '' ? data.BonusPoint : Bonus);
      await axios.put(`${window.env.API_URL}/EditPointTableSystemV2/${data._id}`, formData);
      
      swal({
        title: "Success!",
        text: "Data has been added successfully ",
        icon: "success",
        button: "OK",
      });
      setisLoading(false);
    } catch (error) {
      const err = error.response.data.message;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
      setisLoading(false);
    }
  };

 const GoBack = () => {
  history('/viewcompetitionPoint')
 }


  return (
    <div className="page">
      <div className="rightsidedata">
        <div
          style={{
            marginTop: "30px",
          }}
        >
          <div className="Header ">
            <h4>Edit Points</h4>

          </div>
        </div>
        <div className="casttable">
        <table className="editformtable">
              <thead>
                <tr>
                  <th>Action</th>
                <th>Rank</th>
              <th>Points</th>
              <th>Bonus Point</th>

                </tr>
              </thead>
            {
             
                EditPointTableSystemV2 && EditPointTableSystemV2.map((obj, index) => (
                    <tbody
                      className="myselectdata resultsdata resultsdata12 "
                      key={obj._id}
                    >
                      <tr>
                      <td className="myedit">
                              <button
                                className="savebtnhorse"
                                onClick={(e) => saveEditItem(e, obj)}
                              >
                                <TiTick />
                              </button>

                              
                            </td>
                        <td>
                          <input
                            type="number"
                            onChange={(e) => setRank(e.target.value)}
                            placeholder={obj.Rank}
/>
                        </td>
                        
                       


                       

                        <td>
                          <input
                            type="number"
                            onChange={(e) => setPoint(e.target.value)}
                            placeholder={obj.Point}
                            
                          />
                        </td>

                        <td >
                          <input
                            type="number"
                            onChange={(e) => setBonus(e.target.value)}
                            placeholder={obj.BonusPoint}
                           
                          />
                        </td>
                      
                      </tr>
                    </tbody>
                  ))
              }
           </table>
          </div>
          <div
            className="ButtonSection "
            style={{ justifyContent: "end" }}
          >
            <button
              Name="submit"
              className="SubmitButton"
              onClick={GoBack}
            >
             Go Back
            </button>
          </div>

      </div>
    </div>
  )


}

export default EditPoints