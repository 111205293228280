import React, { useEffect, useState } from "react";
import { fetchThemeSetting, STATUSES } from "../../redux/getReducer/getThemeSetting";
import { fetchActiveTheme } from "../../redux/getReducer/getThemeActive";
import { useDispatch, useSelector } from "react-redux";
import { MdDelete } from "react-icons/md";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ScrollContainer from "react-indiana-drag-scroll";
import Lottie from "lottie-react";
import HorseAnimation from "../../assets/horselottie.json";
import { BiEdit } from "react-icons/bi";
import { BsEyeFill } from "react-icons/bs";
import Pagination from "./Pagination";
import Form from "react-bootstrap/Form";
import Notfound from "../../Notfound";
import ThemePopup from '../../Components/Popup/ThemePopup';
import { Modal } from "react-bootstrap";
import axios from "axios";
import swal from "sweetalert";


const Ads = () => {
  const [Value, setValue] = useState(false);


  const { data: ThemeSetting, status } = useSelector((state) => state.ThemeSetting);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [postsPerPage] = useState(8);

  // const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const history = useNavigate();
  const dispatch = useDispatch();
  //--------------------------api----------------------//

  useEffect(() => {
    dispatch(fetchThemeSetting());
  }, [dispatch]);

  const [show, setShow] = useState(false);
  const [modaldata, setmodaldata] = useState();
  const handleClose = () => setShow(false);
  const handleShow = async (data) => {
    setmodaldata(data);
    await setShow(true);
  };

  //--------------------------delete----------------------//
  const handleRemove = async (Id) => {
    try {
      swal({
        title: "Are you sure?",
        text: "do you want to delete this data ?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          await axios.delete(`${window.env.API_URL}/deleteTheme/${Id}`);
          swal("Your data has been deleted Successfully!", {
            icon: "success",
          });
          dispatch(fetchThemeSetting());
        } else {
          swal("Your data is safe!");
        }
      });
    } catch (error) {
      const err = error.response.data.message;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
    }
  };

  if (status === STATUSES.ERROR) {
    return (
      <h2
        style={{
          margin: "100px",
        }}
      >
        Something went wrong!
      </h2>
    );
  }

  const handleStatus = async (id) => {
    await dispatch(fetchActiveTheme({id}))
    dispatch(fetchThemeSetting());

  }
  return (
    <>
      <div className="page">
        <div className="rightsidedata">
          <div
            style={{
              marginTop: "30px",
            }}
          >
            <>
              <div className="Header ">
                <h4>Theme Listings</h4>

                <div>
                  <Link to="/ThemeSetting">
                    <button>Create Theme</button>
                  </Link>
                 
                </div>
              </div>

              <div className="div_maintb">
                <ScrollContainer className="scroll-container">
                  <table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Action</th>
                        <th>Name </th>
                        <th>Primary Color</th>
                        <th>Secondary Color </th>
                        {/* <th>Primary Font </th>
                        <th>Secondary Font </th> */}
                        <th>Active</th>
                      </tr>
                    </thead>
                    {
                      <tbody>
                        {status === STATUSES.LOADING ? (
                          <Lottie
                            animationData={HorseAnimation}
                            loop={true}
                            className="TableLottie"
                          />
                        ) : (
                          ThemeSetting.length ? ThemeSetting.map((item, index) => {
                            return (
                              <>
                                <tr className="tr_table_class">
                                  <td className="table_delete_btn1">
                                    <BiEdit
                                      onClick={() =>
                                        history("/edittheme", {
                                          state: {
                                            themeid: item,
                                          },
                                        })
                                      }
                                    />

                                    <MdDelete
                                      style={{
                                        fontSize: "22px",
                                      }}
                                      onClick={() => handleRemove(item._id)}
                                    />
                                   <BsEyeFill
                                      onClick={() => handleShow(item)}
                                    />
                                  </td>
                                  <td>{item.ThemeName}</td>
                                  <td>{item.PrimaryColor}</td>
                                  <td>{item.PrimaryColorFont}</td>
                                  {/* <td>{item.SecondaryColor}</td>
                                  <td>{item.SecondaryColorFont}</td> */}
                                
                                  <td>
                                    <Form.Check
                                      type="switch"
                                      id="custom-switch"
                                      onChange={()=> handleStatus(item._id)}
                                      defaultChecked={item.Active}
                                      />
                                  </td>
                                </tr>
                              </>
                            );
                          }) : <Notfound />


                        )}
                      </tbody>
                    }
                  </table>
                </ScrollContainer>
              </div>
            </>
          </div>
          {/* <Pagination
            postsPerPage={postsPerPage}
            totalPosts={TotalCount}
            paginate={paginate}
            currentPage={currentPage}
            TotalPages={TotalPages}
          /> */}
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2 style={{ fontFamily: "inter" }}>Theme </h2>
        </Modal.Header>
        <Modal.Body>
          <ThemePopup data={modaldata} />
        </Modal.Body>
        <Modal.Footer>
          <button onClick={handleClose} className="modalClosebtn">
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default Ads;
