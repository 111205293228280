import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import "../../../Components/CSS/Table.css";
import ScrollContainer from "react-indiana-drag-scroll";
import "../../../Components/CSS/race.css";
import { Modal } from "react-bootstrap";
import RacePopup from "../../../Components/Popup/RacePopup";
import swal from "sweetalert";
import Moment from "react-moment";
import {
  fetchResult,
  STATUSES,
} from "../../../redux/getReducer/getResultSlice";
import Lottie from "lottie-react";
import HorseAnimation from "../../../assets/horselottie.json";
import NotFound from "../../../Notfound";
import Pagination from "../../GetTable/Pagination";
import { DateRangePicker } from "react-date-range";
import Select from "react-select";
import { fetchRaceName } from "../../../redux/getReducer/getRaceName";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { BiFilter } from "react-icons/bi";
import Form from "react-bootstrap/Form";

const Races = () => {
  const [show, setShow] = useState(false);
  const [modaldata, setmodaldata] = useState();
  const [ShowCalender, setShowCalender] = useState(false);

  const handleClose = () => setShow(false);

  const dispatch = useDispatch();
  const history = useNavigate();
  const { data: Result, status } = useSelector((state) => state.Result);

  const [currentPage, setCurrentPage] = useState(1);
  const [StatusData, setStatusData] = useState(false);
  const [postsPerPage] = useState(5);
  const [SearchRaceName, setRaceName] = useState('');
  const SearchCode = "";
  const SearchTitle = "";
  const SearchAge = "";
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const TotalPages = Result.totalPages;
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const { data: RaceName } = useSelector((state) => state.RaceName);

  const totalPage = Math.round(TotalPages);
  const racenameid = SearchRaceName === null ? "" : SearchRaceName.id

  console.log(state,'state')
  useEffect(() => {
    dispatch(fetchResult({ racenameid,currentPage }));
  }, [dispatch, currentPage]);

  useEffect(() => {
    dispatch(fetchRaceName({ SearchCode, SearchTitle, SearchAge }));
  }, [SearchAge, SearchCode, SearchTitle, dispatch]);

  let Racenameoptions =
    RaceName === undefined ? (
      <></>
    ) : (
      RaceName.map(function (item) {
        return {
          id: item._id,
          value: item.NameEn,
          label: (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <p>{item.NameEn}</p>
            </div>
          ),
        };
      })
    );  

    function convertDateFormat(dateString) {
      // Create a new Date object from the given date string
      var date = new Date(dateString);
    
      // Get the individual components of the date
      var year = date.getFullYear();
      var month = ("0" + (date.getMonth() + 1)).slice(-2);
      var day = ("0" + date.getDate()).slice(-2);
      var hours = ("0" + date.getHours()).slice(-2);
      var minutes = ("0" + date.getMinutes()).slice(-2);
      var seconds = ("0" + date.getSeconds()).slice(-2);
    
      // Assemble the converted date format
      var convertedDate = year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
    
      // Return the converted date format
      return convertedDate;
    }
    
    
    let startdate1 = !StatusData ? '' : convertDateFormat(state[0].startDate);
    let enddate1 =  !StatusData ? '' :  convertDateFormat(state[0].endDate);

    console.log(startdate1)

  const GetSearch = () => {
    dispatch(fetchResult({ racenameid,currentPage ,startdate1, enddate1}));
    setCurrentPage(1)

  };

  if (status === STATUSES.ERROR) {
    return (
      <h2
        style={{
          margin: "100px",
        }}
      >
        Something went wrong!
      </h2>
    );
  }

  const handleStatus = async () => {
    setStatusData(!StatusData)

  }

  return (
    <>
      <div className="page">
        <div className="rightsidedata">
          <div
            style={{
              marginTop: "30px",
            }}
          >
            <div className="Header ">
              <h4>Result Awaited</h4>
              <div>
                <div>
                  <OverlayTrigger
                    overlay={<Tooltip id={`tooltip-top`}>Filter</Tooltip>}
                  >
                    <span className="addmore">
                      <BiFilter
                        className="calendericon"
                        onClick={() => setShowCalender(!ShowCalender)}
                      />
                    </span>
                  </OverlayTrigger>{" "}
                </div>
              </div>
            </div>
            <div>
              {ShowCalender ? (
                <>
                  <div className="userfilter userFilter">
                    <div className="calenderuser">
                      <DateRangePicker
                        onChange={(item) => setState([item.selection])}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={2}
                        ranges={state}
                        direction="horizontal"
                      />
                    </div>

                    <div className="filtertextform filterTextForm">

                    <div className="">
                      <p>Active Calender</p>
                    <Form.Check
                                      type="switch"
                                      id="custom-switch"
                                      onChange={()=> handleStatus()}
                                      defaultChecked={StatusData}
                                      />
                      </div>
                      <div className="searchDropdown">
                        <Select
                          placeholder={<div>Race Name</div>}
                          defaultValue={SearchRaceName}
                          onChange={setRaceName}
                          options={Racenameoptions}
                          isClearable={true}
                          isSearchable={true}
                        />
                      </div>
                    </div>

                    <div className="filtertextform  "></div>
                  </div>
                  <button className="filterbtn filterBtn" onClick={GetSearch}>
                    Apply Filter
                  </button>
                </>
              ) : (
                <></>
              )}
            </div>
            <div className="div_maintb">
              <ScrollContainer className="scroll-container">
                <table className="Sc">
                  <thead
                    style={{
                      marginTop: "30px",
                    }}
                  >
                    <tr className="trtabletd">
                      <th>Race Name</th>
                      <th>Race Name Arabic </th>
                      <th>Add Result</th>
                      <th>Race Type</th>
                      <th>Race Course</th>
                      <th>Date</th>
                      <th>Race Status</th>
                    </tr>
                  </thead>
                  {
                    <>
                      {status === STATUSES.LOADING ? (
                        <Lottie
                          animationData={HorseAnimation}
                          loop={true}
                          className="TableLottie"
                        />
                      ) : Result.data ? (
                        Result.data &&
                        Result.data.map((item) => {
                          const { RaceStatus } = item;
                          return (
                            <tbody
                              key={item._id}
                              style={{
                                marginTop: "20px",
                              }}
                            >
                              <tr>
                                <td className="truncate-text"
                                  style={{
                                    backgroundColor: `${
                                      RaceStatus === "Cancelled"
                                        ? "#000000"
                                        : RaceStatus === "Completed"
                                        ? "#FF0000"
                                        : RaceStatus === "Live"
                                        ? "#5EC30F"
                                        : "#FF9900"
                                    }`,
                                    color: `${
                                      RaceStatus === "Cancelled"
                                        ? "#ffff"
                                        : RaceStatus === "Completed"
                                        ? "#00000"
                                        : RaceStatus === "Live"
                                        ? "#00000"
                                        : "#000000"
                                    }`,
                                  }}
                                >
                                  {item.RaceNameModelData === null ? (
                                    <>N/A</>
                                  ) : (
                                    item.RaceNameModelData.NameEn
                                  )}
                                </td>
                                <td>
                                  {item.RaceNameModelData === null ? (
                                    <>N/A</>
                                  ) : (
                                    item.RaceNameModelData.NameAr
                                  )}{" "}
                                </td>
                                <td>
                                  <button
                                    className="Approvedbtn resultbtn"
                                    onClick={() =>
                                      history("/resultform", {
                                        state: {
                                          RaceId: item._id,
                                        },
                                      })
                                    }
                                  >
                                    {" "}
                                    Add Result
                                  </button>
                                </td>
                                <td>
                                  {item.RaceTypeModelData === null ? (
                                    <>N/A</>
                                  ) : (
                                    item.RaceTypeModelData.NameEn
                                  )}{" "}
                                </td>
                                <td>
                                  {item.RaceCourseData === null ? (
                                    <>N/A</>
                                  ) : (
                                    item.RaceCourseData.TrackNameEn
                                  )}
                                </td>
                             
                                <td>
                                  {" "}
                                  <Moment parse="YYYY-MM-DD HH:mm">
                                    {item.Day}
                                  </Moment>
                                </td>

                                <td>{item.RaceStatus}</td>
                              </tr>
                            </tbody>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </>
                  }
                </table>
              </ScrollContainer>
            </div>
          </div>
          <Pagination
            postsPerPage={postsPerPage}
            totalPosts={Result.length}
            TotalPages={totalPage}
            paginate={paginate}
            currentPage={currentPage}
          />
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2>Race Course </h2>
        </Modal.Header>
        <Modal.Body>
          <RacePopup data={modaldata} />
        </Modal.Body>
      </Modal>
    </>
  );
};
export default Races;
