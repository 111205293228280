import React, { useEffect, useRef } from "react";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { fetchHorseForRace } from "../../../redux/getDropDownReducer/getHorseRace";
import { toast } from "react-toastify";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import swal from "sweetalert";
import axios from "axios";
import { fetchColorDropdown } from "../../../redux/getDropDownReducer/getColor";
import { ImCross } from "react-icons/im";
import { TiTick } from "react-icons/ti";
import { fetchEditRaceHorse } from "../../../redux/getReducer/getEditHorseRaceSlice";
import { fetchequipmentdropdown } from "../../../redux/getDropDownReducer/getEquipment";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { AiOutlineReload } from "react-icons/ai";
import HorsePopup from "../../PostTable/HorsePopupForm"
import JockeyPopup from "../../PostTable/JockeyPopupForm";
import ColorPopup from "../../PostTable/Color";
import EquipmentPopup from "../../PostTable/Equipment";
import { fetchsilkcolordropdown } from "../../../redux/getDropDownReducer/getOwnerSilkColor";
import Loader from "../../../Components/Common/Loader";
import { fetchJockeyForRace } from "../../../redux/getDropDownReducer/getJockeyForRace";

const AddMoreHorse = () => {
  const { data: JockeyForRace } = useSelector((state) => state.JockeyForRace);
  const { data: HorseForRace } = useSelector((state) => state.HorseForRace);
  const { data: equipmentdropdown } = useSelector(
    (state) => state.equipmentdropdown
  );

  const { data: EditRaceHorse } = useSelector((state) => state.EditRaceHorse);
  const { data: silkcolordropdown } = useSelector((state) => state.silkcolordropdown);

  const [HorseData, SetHorseData] = useState("");

  const [Gate, setGate] = useState("");
  const [EquipmentData, SetEquipmentData] = useState("");
  const [JockeyData, SetJockeyData] = useState("");
  const [CapColor, SetCapColor] = useState("");
  const [HorseStatus, SetHorseStatus] = useState(true);
  const [items, setitems] = useState("");
  const [isLoading, setisLoading] = useState(false)
  const [Rating, setRating] = useState(0);


  const [showHorse, setShowHorse] = useState(false)
  const [showJockey, setshowJockey] = useState(false)
  const [showColor, setshowColor] = useState(false)
  const [showEquipment, setshowEquipment] = useState(false)
  const [Value, setValue] = useState();
  const [Value1, SetValue1] = useState();
  const [IncreasePage, setIncreasePage] = useState(24);
  const [IncreasePage1, setIncreasePage1] = useState(24);
  const H1 = EditRaceHorse.map((item) => item.HorseNo);
  const HighNum = H1?.length == 0 ? 0 : Math.max(...H1);

  let IncreseData = HighNum + 1;
  const [HorseNo, setHorseNo] = useState(IncreseData);

  const [RaceWeight, setRaceWeight] = useState('');

  // const [HorseNo, setHorseNo] = useState(1);

  let formRef = useRef();
  const [StoreData, setStoreData] = useState([]);

  const history = useNavigate();
  const { state } = useLocation();
  const { RaceId } = state;

  let MapGate = [];
  for (var i = 1; i < 100; i++) {
    MapGate.push({ id: i, value: i, label: i });
  }

  const G1 = StoreData.map((item) => item.GateNo);
  const G2 = EditRaceHorse.map((item) => item.GateNo);

  let Gateoptions = MapGate.map(function (item) {
    return {
      id: item._id,
      value: item.value,
      label: item.value,
      isdisabled:
        G1[0] !== item.value &&
          G1[1] !== item.value &&
          G1[2] !== item.value &&
          G1[3] !== item.value &&
          G1[4] !== item.value &&
          G1[5] !== item.value &&
          G1[6] !== item.value &&
          G1[7] !== item.value &&
          G1[8] !== item.value &&
          G1[9] !== item.value &&
          G1[10] !== item.value &&
          G1[11] !== item.value &&
          G1[12] !== item.value &&
          G1[13] !== item.value &&
          G1[14] !== item.value &&
          G1[15] !== item.value &&
          G1[16] !== item.value &&
          G1[17] !== item.value &&
          G1[18] !== item.value &&
          G1[19] !== item.value &&
          G1[20] !== item.value &&
          G2[0] !== item.value &&
          G2[1] !== item.value &&
          G2[2] !== item.value &&
          G2[3] !== item.value &&
          G2[4] !== item.value &&
          G2[5] !== item.value &&
          G2[6] !== item.value &&
          G2[7] !== item.value &&
          G2[8] !== item.value &&
          G2[9] !== item.value &&
          G2[10] !== item.value &&
          G2[11] !== item.value &&
          G2[12] !== item.value &&
          G2[13] !== item.value &&
          G2[14] !== item.value &&
          G2[15] !== item.value &&
          G2[16] !== item.value &&
          G2[17] !== item.value &&
          G2[18] !== item.value &&
          G2[19] !== item.value &&
          G2[20] !== item.value
          ? false
          : true,
    };
  });

  const A1 = StoreData.map((item) => item.HorseId);
  const A2 = EditRaceHorse.map((item) => item.HorseModelId);

  let horseoptions = HorseForRace.map(function (item) {
    return {
      id: item._id,
      value: item.NameEn,
      label: item.NameEn,
      ActiveOwnerid: item.ActiveOwner === null ? <></> : item.ActiveOwner,
      Ownername:
        item.ActiveOwnerData === null ? <>NAN</> : item.ActiveOwnerData.NameEn,
      rating: item.STARS,
      isdisabled:
        A1[0] !== item._id &&
          A1[1] !== item._id &&
          A1[2] !== item._id &&
          A1[3] !== item._id &&
          A1[4] !== item._id &&
          A1[5] !== item._id &&
          A1[6] !== item._id &&
          A1[7] !== item._id &&
          A1[8] !== item._id &&
          A1[9] !== item._id &&
          A1[10] !== item._id &&
          A1[11] !== item._id &&
          A1[12] !== item._id &&
          A1[13] !== item._id &&
          A1[14] !== item._id &&
          A1[15] !== item._id &&
          A1[16] !== item._id &&
          A1[17] !== item._id &&
          A1[18] !== item._id &&
          A1[19] !== item._id &&
          A1[20] !== item._id &&
          A1[21] !== item._id &&
          A2[0] !== item._id &&
          A2[1] !== item._id &&
          A2[2] !== item._id &&
          A2[3] !== item._id &&
          A2[4] !== item._id &&
          A2[5] !== item._id &&
          A2[6] !== item._id &&
          A2[7] !== item._id &&
          A2[8] !== item._id &&
          A2[9] !== item._id &&
          A2[10] !== item._id &&
          A2[11] !== item._id &&
          A2[12] !== item._id &&
          A2[13] !== item._id &&
          A2[14] !== item._id &&
          A2[15] !== item._id &&
          A2[16] !== item._id &&
          A2[17] !== item._id &&
          A2[18] !== item._id &&
          A2[19] !== item._id &&
          A2[20] !== item._id &&
          A2[21] !== item._id
          ? false
          : true,
    };
  });



  const J1 = StoreData.map((item) => item.JockeyDataId);
  const J2 = EditRaceHorse.map((item) => item.JockeyOnRace);

  let AllJockey = JockeyForRace.map(function (item) {
    return {
      id: item._id,
      value: item._id,
      label: item.NameEn,
      weight: item.JockeyAllowance,
      minweight: item.MiniumumJockeyWeight,
      isdisabled:
        J1[0] !== item._id &&
          J1[1] !== item._id &&
          J1[2] !== item._id &&
          J1[3] !== item._id &&
          J1[4] !== item._id &&
          J1[5] !== item._id &&
          J1[6] !== item._id &&
          J1[7] !== item._id &&
          J1[8] !== item._id &&
          J1[9] !== item._id &&
          J1[10] !== item._id &&
          J1[11] !== item._id &&
          J1[12] !== item._id &&
          J1[13] !== item._id &&
          J1[14] !== item._id &&
          J1[15] !== item._id &&
          J1[16] !== item._id &&
          J1[17] !== item._id &&
          J1[18] !== item._id &&
          J1[19] !== item._id &&
          J1[20] !== item._id &&
          J2[0] !== item._id &&
          J2[1] !== item._id &&
          J2[2] !== item._id &&
          J2[3] !== item._id &&
          J2[4] !== item._id &&
          J2[5] !== item._id &&
          J2[6] !== item._id &&
          J2[7] !== item._id &&
          J2[8] !== item._id &&
          J2[9] !== item._id &&
          J2[10] !== item._id &&
          J2[11] !== item._id &&
          J2[12] !== item._id &&
          J2[13] !== item._id &&
          J2[14] !== item._id &&
          J2[15] !== item._id &&
          J2[16] !== item._id &&
          J2[17] !== item._id &&
          J2[18] !== item._id &&
          J2[19] !== item._id &&
          J2[20] !== item._id
          ? false
          : true,
    };
  });

  let AllEquipment = equipmentdropdown.map(function (item) {
    return {
      id: item._id,
      value: item.NameEn,
      label: item.NameEn,
    };
  });

  const dispatch = useDispatch();



  const handleChangeInput = (value) => {
    setValue(value);
  };
  const handleChangeInput1 = (value) => {
    SetValue1(value);
  };
  const slider = document.querySelector(".horizontal-scroll-wrapper");
  let isDown = false;
  let startX;
  let scrollLeft;
  if (slider) {
    slider.addEventListener("mousedown", (e) => {
      isDown = true;
      slider.classList.add("active");
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    });

    slider.addEventListener("mouseleave", () => {
      isDown = false;
      slider.classList.remove("active");
    });
    slider.addEventListener("mouseup", () => {
      isDown = false;
      slider.classList.remove("active");
    });
    slider.addEventListener("mousemove", (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 3;
      slider.scrollLeft = scrollLeft - walk;

    });
  }

  const HorseLength = HorseForRace.length;
  const ItemLength = items.length;
  const DataABC = JSON.parse(localStorage.getItem("mapping"));
  const AOwnerid = HorseData.ActiveOwnerid === '' ? <></> : HorseData.ActiveOwnerid

  useEffect(() => {
    dispatch(fetchHorseForRace({ RaceId, Value, IncreasePage }));
  }, [IncreasePage, RaceId, Value, dispatch])
  useEffect(() => {
    dispatch(fetchJockeyForRace({ Value1, IncreasePage1 }));

  }, [IncreasePage1, Value1, dispatch])
  useEffect(() => {
    formRef.current?.reset();



    dispatch(fetchequipmentdropdown());
    dispatch(fetchsilkcolordropdown({ AOwnerid }));
    localStorage.setItem("lists", JSON.stringify(items));
    localStorage.setItem("mapping", JSON.stringify(StoreData));
  }, [dispatch, items, StoreData, , AOwnerid]);

  const removesingleItem = (id) => {
    const updateItems = StoreData.filter((elem, ind) => {
      return ind !== id;
    });
    const updateItems1 = items.filter((elem, ind) => {
      return ind !== id;
    });
    setStoreData(updateItems);
    setitems(updateItems1)

  };

  let AllsilkColor = silkcolordropdown.map(function (item) {
    return {
      id: item._id,
      value: item.NameEn,
      urlimg: item.OwnerSilkColor,
      label: (
        <img src={item.OwnerSilkColor} height="30px" width="30px" alt="" />

      ),
    };
  });
  const saveItem = (e) => {
    e.preventDefault();

    let HorseEntry = {
      GateNo: Gate === '' ? 100 : Gate.value,
      HorseNo: HorseNo,
      HorseModelId: HorseData.id,
      Equipment: EquipmentData.id,
      JockeyOnRace: JockeyData.id,
      JockeyWeight: JockeyData.weight,
      Rating: Rating === 0 ? HorseData.rating : Rating,
      HorseRunningStatus: HorseStatus,
      CapColor: CapColor.id === '' ? null : CapColor.id,
      JockeyRaceWeight: RaceWeight === "" ? undefined : RaceWeight,
    };
    let MappingData = {
      GateNo: Gate.value,
      HorseNo: HorseNo,
      HorseName: HorseData.label,
      EquipmentName: EquipmentData.label,
      OwnerOnRace: HorseData.Ownername,
      JockeyOnRaceName: JockeyData.label,
      JockeyWeight: JockeyData.weight,
      MiniumumJockeyWeight: JockeyData.minweight,
      RaceWeight: RaceWeight,
      Rating: Rating === 0 ? HorseData.rating : Rating,
      HorseRunningStatus: HorseStatus,
      CapColor: CapColor.urlimg,
      HorseId: HorseData.id,
      JockeyDataId: JockeyData.id,
    };

    if (HorseLength === ItemLength) {
      toast("No Horse ");
    }
    else if (JockeyData === '' && HorseStatus === true) {
      toast("Select Jockey Value ");
    }
    else if (
      HorseData === "" ||
      RaceWeight === ""
    ) {
     return toast("Select RaceWeight Values ");
    } else {
      setitems([...items, HorseEntry]);
      setStoreData([...StoreData, MappingData]);
      setHorseNo(HorseNo + 1);
      formRef.current?.reset();
    }
    SetHorseStatus(true)
    setGate("");
    SetHorseData(" ");
    SetCapColor("");
    SetJockeyData("");
    SetEquipmentData("");
    setRaceWeight("");
    setRating(0)

  };
  const Remove = () => {
    setitems([]);
    setHorseNo(1);
    setStoreData([]);
  };


  function findMissingHorseNos(array) {
    const sortedArray = array.slice().sort((a, b) => a.HorseNo - b.HorseNo);
  
    const missingHorseNos = [];
    for (let i = 0; i < sortedArray.length - 1; i++) {
      const diff = sortedArray[i + 1].HorseNo - sortedArray[i].HorseNo;
      if (diff > 1) {
        for (let j = 1; j < diff; j++) {
          missingHorseNos.push(sortedArray[i].HorseNo + j);
        }
      }
    }
  
    return missingHorseNos;
  }

  

  const submit = async (event) => {
    event.preventDefault();
    const missingHorseNos = findMissingHorseNos( items);
    // if (missingHorseNos.length > 0) {
    //   const missinghorse =  missingHorseNos.join(', ');
    //   return toast(`Missing HorseNo.${missinghorse} You should enter:`);
    // } 
       
      if (ItemLength === 0) {
       return toast("Please Add and Save Horse ");
      }
   else {
      try {
        setisLoading(true)

        await axios.post(`${window.env.API_URL}addracehorses/${RaceId}`, {
          HorseEntry: items,
        });
        localStorage.removeItem("lists");
        history("/editracehorse", {
          state: {
            RaceId: RaceId,
          },
        });

        swal({
          title: "Success",
          text: "Data has been added successfully ",
          icon: "success",
          button: "OK",
        });
      } catch (error) {
        const err = error.response.data.message;
        swal({
          title: "Error!",
          text: err,
          icon: "error",
          button: "OK",
        });
      }
    } setisLoading(false)

  };
  const fetchall = () => {
    dispatch(fetchHorseForRace({ RaceId, Value1 }));
    dispatch(fetchequipmentdropdown());
    dispatch(fetchsilkcolordropdown({ AOwnerid }));
  }
  const handleshowHorse = async () => {
    await setShowHorse(true)
  }
  const handleshowJockey = async () => {
    await setshowJockey(true)
  }
  const handleshowColor = async () => {
    await setshowColor(true)
  }
  const handleshowEquipment = async () => {
    await setshowEquipment(true)
  }

  const handleCloseHorse = () => setShowHorse(false)

  const handleCloseColor = () => setshowColor(false)

  const handleCloseJockey = () => setshowJockey(false)

  const handleCloseEquipment = () => setshowEquipment(false)
  const handleOnScrollBottom = () => {
    setIncreasePage(IncreasePage + 12)
    dispatch(fetchHorseForRace({ RaceId, IncreasePage }))
  };

  const handleOnScrollBottom1 = () => {
    setIncreasePage1(IncreasePage1 + 12)
    dispatch(fetchJockeyForRace({ Value1, IncreasePage1 }));
  };
  return (
    <>
      <div className="page">
        <div className="rightsidedata">
          {isLoading ? <Loader /> : <div
            style={{
              marginTop: "30px",
            }}
          >
            <div className="Header ">
              <h4>Add More Horse</h4>
            </div>
            <div className="horizontal-scroll-wrapper squares">

              <div className="myselectdata">
                <form ref={formRef} onSubmit={saveItem} className="myselectiondataaddHorse  myselectiondataaddHorse1 mynew" >
                  <table className="editformtable">

                    <thead>
                      <tr>
                        <th>Horse #</th>
                        <th>Gate #</th>
                        <th>Horse</th>
                        <th>Owner Name</th>
                        <th>Jockey</th>
                        <th>Weight</th>
                        <th>Min Weight</th>
                        <th>Race Weigh</th>
                        <th>Rating</th>
                        <th>Cap Color</th>
                        <th>Equipment</th>
                        <th>Horse Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>

                      <tr>

                        <td className="myedit">
                          <span className="inputAddhorse3">
                            <input onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                              onChange={(event) => { const value = Number(event.target.value); setHorseNo(value); }} value={HorseNo} min="1" name="horseNo" />
                          </span>
                        </td>


                        <td className="myedit">
                          <span className="inputAddhorse4">
                            <Select placeholder="Gate #" className="dropdown multidropdown w-5" onChange={setGate} options={Gateoptions} isSearchable={true} isOptionDisabled={(option) => option.isdisabled} hideSelectedOptions={true} value={Gate} />
                          </span>
                        </td>


                        <td className="myedit">
                          <span className="InputAddhorse5">

                            <Select id="selectNow" placeholder="Horse" className="dropdown multidropdown" onChange={SetHorseData} options={horseoptions} isOptionDisabled={(option) => option.isdisabled} isSearchable={true}
                              hideSelectedOptions={true}
                              onInputChange={handleChangeInput}
                              value={HorseData}
                              name="HorseValue"
                              onMenuScrollToBottom={(e) => handleOnScrollBottom(e)}

                            />
                            <span className="spanForm spanForm1 aaaa1">
                              <OverlayTrigger
                                overlay={<Tooltip id={`tooltip-top`}>Add more</Tooltip>}
                              >
                                <span className="addmore addmore1" onClick={handleshowHorse}>
                                  +
                                </span>
                              </OverlayTrigger>
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id={`tooltip-top`}>Fetch New</Tooltip>
                                }
                              >
                                <span className="addmore addmore1" onClick={fetchall}>
                                  <AiOutlineReload />
                                </span>
                              </OverlayTrigger>{" "}
                            </span>
                          </span>
                        </td>


                        <td className="myedit">
                          <span className="InputAddhorse5">
                            <input
                              placeholder="Owner"
                              value={HorseData.Ownername}
                              className="ownerinput"
                            />
                          </span>
                        </td>


                        <td className="myedit">
                          <span className="InputAddhorse5">
                            <Select
                              placeholder="Jockey"
                              className="dropdown multidropdown"
                              onChange={SetJockeyData}
                              options={AllJockey}
                              isOptionDisabled={(option) => option.isdisabled}
                              onInputChange={handleChangeInput1}
                              isSearchable={true}
                              hideSelectedOptions={true}
                              value={JockeyData}
                              onMenuScrollToBottom={(e) => handleOnScrollBottom1(e)}
                            />
                            <span className="spanForm spanForm1 aaaa1">
                              <OverlayTrigger
                                overlay={<Tooltip id={`tooltip-top`}>Add more</Tooltip>}
                              >
                                <span className="addmore addmore1" onClick={handleshowJockey}>
                                  +
                                </span>
                              </OverlayTrigger>
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id={`tooltip-top`}>Fetch New</Tooltip>
                                }
                              >
                                <span className="addmore addmore1" onClick={fetchall}>
                                  <AiOutlineReload />
                                </span>
                              </OverlayTrigger>{" "}
                            </span>
                          </span>
                        </td>


                        <td className="myedit">
                          <span className="inputAddhorse3">
                            <input
                              placeholder="Jockey Weight"
                              value={JockeyData.weight}
                              readOnly
                            />
                          </span>
                        </td>


                        <td className="myedit">
                          <span className="inputAddhorse3">
                            <input
                              placeholder="Min Weight"
                              value={JockeyData.minweight}
                              readOnly
                            />
                          </span>
                        </td>


                        <td className="myedit">
                          <span className="inputAddhorse3">
                            <input
                              placeholder="Race Weight"
                              value={RaceWeight}
                              onChange={(e) => setRaceWeight(e.target.value)}
                              onKeyPress={(event) => {
                                if (!/^\d*\.?\d*$/
                                  .test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                          </span>
                        </td>


                        <td className="myedit"><span className="inputAddhorse3">
                        <input
                            type="text"
                            onKeyPress={(event) => {
                              if (!/^\d*\.?\d*$/
                                .test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            onChange={(e) => setRating(e.target.value)}
                            // readOnly
                            placeholder={HorseData.rating ? HorseData.rating  : 0}
                          />
                        </span></td>

                        <td className="myedit"><span className="inputAddhorse4">
                          <Select
                            placeholder="Color"
                            className="dropdown multidropdown"
                            onChange={SetCapColor}
                            options={AllsilkColor}
                            isSearchable={true}
                            hideSelectedOptions={true}
                            value={CapColor}
                          />
                        </span></td>


                        <td className="myedit"><span className="inputAddhorse4">
                          <Select
                            placeholder="Equipment"
                            className="dropdown multidropdown"
                            onChange={SetEquipmentData}
                            options={AllEquipment}
                            isSearchable={true}
                            hideSelectedOptions={true}
                            value={EquipmentData}
                          />
                          <span className="spanForm spanForm1 aaaa1">
                            <OverlayTrigger
                              overlay={<Tooltip id={`tooltip-top`}>Add more</Tooltip>}
                            >
                              <span className="addmore addmore1" onClick={handleshowEquipment}>
                                +
                              </span>
                            </OverlayTrigger>
                            <OverlayTrigger
                              overlay={
                                <Tooltip id={`tooltip-top`}>Fetch New</Tooltip>
                              }
                            >
                              <span className="addmore addmore1" onClick={fetchall}>
                                <AiOutlineReload />
                              </span>
                            </OverlayTrigger>{" "}
                          </span>
                        </span></td>


                        <td className="myedit"><Form.Check
                          type="switch"
                          id="custom-switch"
                          onChange={() => SetHorseStatus(!HorseStatus)}
                          value={HorseStatus}
                          checked={HorseStatus}
                        /></td>
                        <td className="myedit"><button className="savebtnhorse" onClick={saveItem}>
                          <TiTick />
                        </button></td>
                      </tr>

                      {/* print row */}
                      {!DataABC ? (
                        <></>
                      ) : (
                        DataABC.map((item, i) => {
                          return (
                            <tr key={i} className="bgtable">
                              <td className="myedit"><span className="inputAddhorse3">
                                <input onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }} value={item.HorseNo} min="1" />
                              </span></td>
                              <td className="myedit"><span className="inputAddhorse4">
                                <span>
                                  <input onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }} value={item.GateNo} />
                                </span>
                              </span></td>
                              <td className="myedit"><span className="InputAddhorse5">
                                <input
                                  placeholder="Horse Name"
                                  value={item.HorseName}
                                  readOnly
                                />
                              </span></td>
                              <td className="myedit"><span className="InputAddhorse5">
                                <input
                                  placeholder="Owner"
                                  value={item.OwnerOnRace}
                                  readOnly
                                  className="ownerinput"
                                />
                              </span></td>
                              <td className="myedit"><span className="InputAddhorse5">
                                <input
                                  placeholder="Jockey Name"
                                  value={item.JockeyOnRaceName}
                                  readOnly
                                  className="inputAddhorse"
                                />
                              </span></td>
                              <td className="myedit"><span className="inputAddhorse3">
                                <input
                                  placeholder="Weight"
                                  value={item.JockeyWeight}
                                  readOnly
                                />
                              </span></td>
                              <td className="myedit"><span className="inputAddhorse3">
                                <input
                                  placeholder="Min inputAddhorse3"
                                  value={item.MiniumumJockeyWeight}
                                  readOnly
                                />
                              </span></td>
                              <td className="myedit"><span className="inputAddhorse3">
                                <input
                                  placeholder="Race Weight"
                                  value={item.RaceWeight}
                                  readOnly
                                />
                              </span></td>
                              <td className="myedit"><span className="inputAddhorse3">
                                <input
                                  type="text"
                                  value={item.Rating}
                                  // readOnly
                                  placeholder="Rating"
                                />
                              </span></td>
                              <td className="myedit"><span className="inputAddhorse4">
                                <img src={item.CapColor} height="30px" width="30px" alt="" />
                              </span></td>
                              <td className="myedit"><span className="inputAddhorse4">
                                <input
                                  type="text"
                                  value={item.EquipmentName}
                                  readOnly
                                  placeholder="Equipment"
                                />
                              </span></td>
                              <td className="myedit"></td>
                              <td className="myedit"><span
                                className="removebtnhorse"
                                onClick={() => removesingleItem(i)}
                              >
                                <ImCross />
                              </span></td>

                            </tr>
                          );
                        })
                      )}

                    </tbody>
                  </table>
                </form>
              </div>


            </div>
            <div className="sbmtbtndiv">
              <div className="RaceButtonDiv">
                <button className="updateButton" onClick={Remove}>
                  Remove
                </button>

                <button className="SubmitButton" type="submit" onClick={submit} disabled={isLoading}>
                  Add Horses
                </button>
              </div>
            </div>
          </div>}
        </div>
      </div>
      <Modal show={showHorse} onHide={handleCloseHorse}

        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Add New Horse</Modal.Title>
        </Modal.Header>
        <Modal.Body><HorsePopup /> </Modal.Body>

      </Modal>


      <Modal show={showJockey} onHide={handleCloseJockey}

        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Add New Jockey</Modal.Title>
        </Modal.Header>
        <Modal.Body><JockeyPopup /> </Modal.Body>

      </Modal>
      <Modal show={showColor} onHide={handleCloseColor}

        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Add New Color</Modal.Title>
        </Modal.Header>
        <Modal.Body><ColorPopup /> </Modal.Body>

      </Modal>
      <Modal show={showEquipment} onHide={handleCloseEquipment}

        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Add New Equipment</Modal.Title>
        </Modal.Header>
        <Modal.Body><EquipmentPopup /> </Modal.Body>

      </Modal>
    </>
  );
};

export default AddMoreHorse;
