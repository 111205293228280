import React, { useState, useEffect, useRef } from "react";
import swal from "sweetalert";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Select from "react-select";
import { TiTick } from "react-icons/ti";
import { ImCross } from "react-icons/im";
import { fetchpointTableList } from "../../../redux/getDropDownReducer/getPointTableList";
import { fetchLiveRaceOnly } from "../../../redux/getDropDownReducer/LiveRaceOnly";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { AiOutlineReload } from "react-icons/ai";
import { fetchcompetitionrace } from "../../../redux/getReducer/getCompetitionRace";
import { fetchPointTableSystemDropDown } from "../../../redux/getReducer/getPointByCategory";
import Moment from "react-moment";


const EditRaceforCompetetion = () => {
  const LocalItem = () => {
    const list = localStorage.getItem("compete");
    if (list) {
      return JSON.parse(localStorage.getItem("compete"));
    } else {
      return [];
    }
  };

  const { data: LiveRaceOnly } = useSelector(
    (state) => state.LiveRaceOnly
  );
  const { data: pointTableList } = useSelector((state) => state.pointTableList);
  const { data: CompetitionRaces } = useSelector((state) => state.CompetitionRaces);

  const { state } = useLocation();
  const { compid } = state;

  const [items, setitems] = useState();
  const [checked, setChecked] = useState([]);
  const [StoreDataRace, setStoreDataRace] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [RaceName, setRaceName] = useState();
  const [Points, setPoints] = useState();
  const [itemsComp, setitemsComp] = useState(LocalItem());

  const dispatch = useDispatch();
  const history = useNavigate();
  const G1 = itemsComp.map((item) => item.RaceId)


  const [showHorse, setShowHorse] = useState(false)
  const handleCloseHorse = () => setShowHorse(false)
  const handleshowHorse = async () => {
    await setShowHorse(true)
  }



  

  
  let raceoptions =
    LiveRaceOnly === undefined ? (
      <></>
    ) : (
      LiveRaceOnly.map(function (item) {
        return {
          id: item._id,
          value: item.RaceNameModelData.NameEn,
          isdisabled: G1[0] !== item._id && G1[1] !== item._id && G1[2] !== item._id && G1[3] !== item._id && G1[4] !== item._id && G1[5] !== item._id && G1[6] !== item._id && G1[7] !== item._id && G1[8] !== item._id && G1[9] !== item._id && G1[10] !== item._id ? false : true,

          label: (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >  <Moment format="DD/MM/YYYY">
            {item ===
            null ? (
              <></>
            ) : (
              item
                .Day
            )}
          </Moment>{item.RaceNameModelData.NameEn}( {item.RaceNumber}  )</div>
          ),
        };
      })
    );
  let formRef = useRef();

  const addItem = (e) => {
    e.preventDefault();
    let CompetitionRaces = {
      RaceId: RaceName.id,
      PointTableOfRace: Points.id,
    };
    let RaceMappingData = {
      RaceName: RaceName.value,
      Points: Points.value,
    };

    if (items === 0) {
      return toast("No Data ");
    }
    if (RaceName === '' || Points === '') {
      return toast("Enter Values ");
    }
    else {
      setitemsComp([...itemsComp, CompetitionRaces]);
      setStoreDataRace([...StoreDataRace, RaceMappingData]);
      formRef.current?.reset();
      // setNewVerdictData(RaceName.id);
    }
    setRaceName("");
    setPoints("");
  };
  const addMoreRace = (e) => {
    history("/editmoreracecompetition", {
      state: {
        compid,
      },
    });
  };

  let AllGroup =
    pointTableList === undefined ? (
      <></>
    ) : (
      pointTableList.map(function (item) {
        return {
          id: item._id,
          value: item.Group_NameDataOfCompetition.NameEn,
          label: item.Group_NameDataOfCompetition.NameEn,
        };
      })
    );

    let id = compid._id

    const fetchall = () => {
      dispatch(fetchPointTableSystemDropDown({id}));
    };

  useEffect(() => {
    dispatch(fetchLiveRaceOnly());
    dispatch(fetchpointTableList());
    dispatch(fetchcompetitionrace(compid._id))
  }, [compid._id, dispatch]);


  const fetch = () => {
    dispatch(fetchLiveRaceOnly());
    dispatch(fetchpointTableList());
  };
  const handleRemove = async (Id) => {
    try {
      swal({
        title: "Are you sure?",
        text: "do you want to delete this data ?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          await axios.delete(`${window.env.API_URL}/DeleteCompetitionRace/${Id}`);
          swal("Your data has been deleted Successfully!", {
            icon: "success",
          });

        } else {
          swal("Your data is safe!");
        }
      });
    } catch (error) {
      const err = error.response.data.message;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
    }
  };


  const saveEditItem = async (e, data) => {
    e.preventDefault();
    const formData = new FormData();
    let a = {
      Points: undefined,
      RaceName: undefined,

    };
    // eslint-disable-next-line array-callback-return
    CompetitionRaces.map((singleentry) => {
      if (singleentry._id === data) {
        a.Points = singleentry.PointTableOfRaceofRace._id;
        a.RaceName = singleentry.Race;


      }
    });

    if (RaceName === undefined) {

      formData.append(
        "Race",
        a.RaceName
      );
    } else {

      formData.append(
        "Race",
        RaceName.id
      );
    }


    if (Points === undefined) {

      formData.append(
        "Point",
        a.Points
      );

    } else {
      formData.append(
        "Point",
        Points.id
      );


    }




    try {


      await axios.put(
        `${window.env.API_URL}EditCompetitionRaces/${data}`,
        formData
      );


      swal({
        title: "Success",
        text: "Data has been added successfully",
        icon: "success",
        button: "OK",
      });


    } catch (error) {
      const err = error.response.data.message;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
    }
  };

  const saveItem = (e) => {

    if (items.length) {
      setitems([...items]);
    } else {
      swal({
        title: "Limit!",
        text: "Limit Exceeded ",
        icon: "error",
        button: "OK",
      });
    }
  };
  const removesingleItem = (id) => {
    const updateItems = StoreDataRace.filter((elem, ind) => {
      return ind !== id;
    });
    const updateItems1 = itemsComp.filter((elem, ind) => {
      return ind !== id;
    });
    setStoreDataRace(updateItems);
    setitemsComp(updateItems1);

  };
  const handleCheck = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      if (checked.length === compid.CategoryCount) {
        return toast("limit exceed");
      }
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
  };



  return (
    <div className="page">
      <div className="rightsidedata">
        <div
          style={{
            marginTop: "30px",
          }}
        >
          <div className="Header ">
            <div>
              <h4>Edit Races of Competition</h4>
            </div>
            <div>
              <OverlayTrigger
                overlay={<Tooltip id={`tooltip-top`}>Fetch New</Tooltip>}
              >
                <span className="fetchNewVerdict" onClick={fetch}>
                  <AiOutlineReload />
                </span>
              </OverlayTrigger>{" "}
              {compid.CategoryCount > compid.CompetitionDataOfRace.length ? <button onClick={addMoreRace}>

                Add More Races
              </button> : <></>}
            </div>
          </div>

          <div className="myselecthorse">
            <div className="myselectioncompetition">
              <span>Race</span>
              <span>Points</span>
              <span>Action</span>
            </div>
          </div>

          {!CompetitionRaces ? (
            <></>
          ) : (
            CompetitionRaces.map((item, i) => {
              return (
                <div className="racesinCompetition">

                  <div className="inputAddraces">
                    <span>
                      <Select
                        id="selectNow"
                        placeholder={item.RaceDataOfCompetition.RaceNameModelData && item.RaceDataOfCompetition.RaceNameModelData.NameEn}
                        className="dropdown multidropdown"
                        options={raceoptions}
                        isSearchable={true}
                        hideSelectedOptions={true}
                        name="HorseValue"
                        onChange={setRaceName}

                        isOptionDisabled={(option) => option.isdisabled}
                      />
                    </span>
                  </div>
                  <div className="inputAddraces">
                    <span>
                      <Select
                        id="selectNow"
                        placeholder={item.PointTableOfRaceofRace.Group_NameDataOfCompetition && item.PointTableOfRaceofRace.Group_NameDataOfCompetition.NameEn}
                        className="dropdown multidropdown"
                        options={AllGroup}
                        isSearchable={true}
                        hideSelectedOptions={true}
                        name="HorseValue"
                        onChange={setPoints}

                        
                        
                      />
                            <span className="spanForm spanForm1 aaaa1">
                    <OverlayTrigger
                      overlay={<Tooltip id={`tooltip-top`}>Add more</Tooltip>}
                    >
                      <span className="addmore addmore1" onClick={handleshowHorse}>
                        +
                      </span>
                    </OverlayTrigger>
                    <OverlayTrigger
                      overlay={
                        <Tooltip id={`tooltip-top`}>Fetch New</Tooltip>
                      }
                    >
                      <span className="addmore addmore1" onClick={fetchall}>
                        <AiOutlineReload />
                      </span>
                    </OverlayTrigger>{" "}
                  </span>
                    </span>
                  </div>

                  <span>

                    <button
                      className="removebtnhorse"
                      onClick={() => handleRemove(item._id)}
                    >
                      <ImCross />
                    </button>
                    <button className="savebtnhorse" onClick={(e) => saveEditItem(e, item._id)} type="submit">
                      <TiTick />
                    </button>
                  </span>

                </div>
              );
            })
          )}
          {/* <form ref={formRef} onSubmit={addItem}>
            <div className="racesinCompetition">
              <div className="inputAddraces">
                <Select
                  id="selectNow"
                  placeholder="Race "
                  className="dropdown multidropdown"
                  options={raceoptions}
                  isSearchable={true}
                  hideSelectedOptions={true}
                  name="HorseValue"
                  onChange={setRaceName}
                  value={RaceName}
                  isOptionDisabled={(option) => option.isdisabled}

                />
              </div>
              <div className="inputAddraces">
                <Select
                  id="selectNow"
                  placeholder="Points"
                  className="dropdown multidropdown"
                  options={AllGroup}
                  isSearchable={true}
                  hideSelectedOptions={true}
                  name="HorseValue"
                  onChange={setPoints}
                  value={Points}
                />
              </div>

              <button className="savebtnhorse" onClick={addItem} type="submit">
                <TiTick />
              </button>
            </div>
       
          </form> */}
        </div>
        <div className="ButtonSection " style={{ justifyContent: "end" }}>
          <button
            Name="submit"
            className="SubmitButton"
            onClick={() => history("/competitionlisting")}
            disabled={isLoading}
          >
            Publish
          </button>
        </div>
      </div>
    </div >
  );
};

export default EditRaceforCompetetion;
