import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});

const getSponsorDropdownSlice = createSlice({
    name: 'sponsordropdown',
    initialState: {
        data: [],
        status: STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchSponsorDropdown.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(fetchSponsorDropdown.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.IDLE
            })
            .addCase(fetchSponsorDropdown.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
    }
});

export const { setColor, setStatus } = getSponsorDropdownSlice.actions;
export default getSponsorDropdownSlice.reducer;

export const fetchSponsorDropdown = createAsyncThunk('/Sponsor/fetch', async ({ value }) => {

    const res = await axios.get(`${window.env.API_URL}/Sponsorget?TitleEn=${value === undefined ? "" : value}&size=15`);
    const data = res.data;
    return data.data;
})