import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import axios from "axios";
import { ImCross } from "react-icons/im";
import { useNavigate, useLocation } from "react-router-dom";


import { fetchgroundshortcode } from "../../redux/getShortCode/getgroundtypeshortcode";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../Components/Common/Loader";
import { Form, Input } from 'antd';
const GroundType = ({ setShowGroundType }) => {
  const { data: groundshortcode } = useSelector(
    (state) => state.groundshortcode
  );

  const [isLoading, setisLoading] = useState(false);

  const [NameEn, setNameEn] = useState("");
  const [NameAr, setNameAr] = useState("");

  const [AbbrevEn, setAbbrevEn] = useState("");
  const [AbbrevAr, setAbbrevAr] = useState("");
  const [image, setImage] = useState();
  const [preview, setPreview] = useState();

  const dispatch = useDispatch();

  const history = useNavigate();
  const { pathname } = useLocation();

  const [state1, setState] = useState({
    shortCode: "",
  });
  //----------------------------shortcode ---------------------//
  useEffect(() => {
    if (groundshortcode) {
      setState({
        shortCode:
          groundshortcode.length === 0
            ? 10
            : groundshortcode[0].maxshortCode + 1,
      });
    } else {
      setState.shortCode("9");
    }
  }, [groundshortcode]);

  const onSelectFile = (e) => {
    setImage(e.target.files[0]);
  };
  //----------------------------Image Preview---------------------//



  useEffect(() => {
    dispatch(fetchgroundshortcode());


    if (!image) {
      setPreview(undefined);
      return;
    }

    if (!image.name.match(/\.(gif)$/)) {
      setImage("");
      setPreview(undefined);
      document.getElementById("file").value = "";
      swal({
        title: "Error!",
        text: "Enter Gif Image",
        icon: "error",
        button: "OK",
      });
    }

    const objectUrl = URL.createObjectURL(image);
    setPreview(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [image, dispatch]);

  const handlePreview = () => {
    setImage();
    document.getElementById("file").value = "";
  };
  //----------------------------submit----------------------------//
  const submit = async (event) => {

    setisLoading(true);
    try {
      const formData = new FormData();
      formData.append("NameEn", NameEn);
      formData.append("NameAr", NameAr);
      formData.append("shortCode", state1.shortCode);
      formData.append("AbbrevEn", AbbrevEn);
      formData.append("AbbrevAr", AbbrevAr);
      formData.append("image", image);

      await axios.post(`${window.env.API_URL}/uploadGroundType`, formData);
      if (pathname === "/ground") {
        history("/groundlist");
      }
      swal({
        title: "Success!",
        text: "Data has been added Successfully ",
        icon: "success",
        button: "OK",
      }); setisLoading(false);
    } catch (error) {
      const err = error.response.data.message[0];
      const err1 = error.response.data.message[1];
      const err2 = error.response.data.message[2];
      swal({
        title: "Error!",
        text: err,
        err1,
        err2,
        icon: "error",
        button: "OK",
      });

    }
    setisLoading(false);
    setShowGroundType(false)
  };



  return (
    <div className="page">
      <div className="rightsidedata">
        <div
          style={{
            marginTop: "30px",
          }}
        >
          <div className="Headers">Create Ground</div>
          {isLoading ? <Loader /> : <div className="form">
            <Form onFinish={submit}

              fields={[{

                name: "shortCode",
                value: state1.shortCode

              }]}
            >
              <div className="row mainrow">
                <div className="col-sm">
                  <Form.Item
                    name="NameEn"
                    label="Name"
                    rules={[
                      {
                        required: true,
                        message: 'Name (English) is required',
                      },
                    ]}
                  >
                    <Input placeholder="Name" onChange={(e) => setNameEn(e.target.value)}
                      value={NameEn} name="NameEn" />
                  </Form.Item>


                  <span className="spanForm"> |</span>

                </div>

                <div className="col-sm">
                  <Form.Item
                    name="NameAr"
                    label="اسم"
                    rules={[
                      {
                        required: true,
                        message: 'Name (Arabic) is required',
                      },
                    ]}
                    style={{ direction: 'rtl' }}
                  >
                    <Input placeholder="اسم" onChange={(e) => setNameAr(e.target.value)}
                      name="Name"
                      value={NameAr} />
                  </Form.Item>

                </div>
              </div>
              <div className="row mainrow">
                <div className="col-sm">
                  <Form.Item
                    name="AbbrevEn"
                    label="Abbreviation"
                    rules={[
                      {
                        required: true,
                        message: 'Abbreviation (English) is required',
                      },
                    ]}
                  >
                    <Input placeholder="Abbrevation" onChange={(e) => setAbbrevEn(e.target.value)}
                      value={AbbrevEn}
                      name="AbbrevEn"
                      type="text"
                    />
                  </Form.Item>


                  <span className="spanForm"> |</span>

                </div>

                <div className="col-sm">


                  <Form.Item
                    name="AbbrevAr"
                    label="اختصار"
                    rules={[
                      {
                        required: true,
                        message: 'Abbreviation (Arabic) is required',
                      },
                    ]}
                    style={{ direction: 'rtl' }}
                  >
                    <Input placeholder="اختصار" name="AbbrevAr"
                      onChange={(e) => setAbbrevAr(e.target.value)}
                      value={AbbrevAr}
                      type="text"

                    />
                  </Form.Item>


                </div>
              </div>
              <div className="row mainrow">
                <div className="col-sm">


                  <Form.Item
                    name="shortCode"
                    label="Short Code"
                    rules={[
                      {
                        required: true,
                        message: "short Code is required"
                      },
                    ]}
                  >
                    <Input
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(e) =>
                        setState({ ...state1, shortCode: e.target.value })
                      }
                      value={state1.shortCode}
                      onPaste={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      placeholder="Short Code"
                      maxLength="5"
                    />
                  </Form.Item>

                </div>
              </div>

              <div className="ButtonSection">
                <div >
                  <label className="Multipleownerlabel">Select GIF Image</label>
                  <input
                    type="file"
                    onChange={onSelectFile}
                    className="formInput"
                    id="file"
                  />
                  {image && (
                    <>
                      <ImCross onClick={handlePreview} className="crossIcon" />
                      <img src={preview} className="PreviewImage" alt="" />
                    </>
                  )}
                </div>

                <button
                  type="submit"
                  className="SubmitButton"
                  disabled={isLoading}
                >
                  Save
                </button>
              </div>

            </Form>
          </div>}
        </div>
      </div>
    </div>
  );
};

export default GroundType;
