import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});

const getPushNotificationTemplategetSlice = createSlice({
    name: 'PushNotificationTemplateget',
    initialState: {
        data: [],
        status: STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchPushNotificationTemplateget.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(fetchPushNotificationTemplateget.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.IDLE
            })
            .addCase(fetchPushNotificationTemplateget.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
    }
});

export const { setPushNotificationTemplateget, setStatus } = getPushNotificationTemplategetSlice.actions;
export default getPushNotificationTemplategetSlice.reducer;

export const fetchPushNotificationTemplateget = createAsyncThunk('/PushNotificationTemplategetget/fetch', async () => {
    const res = await axios.get(`${window.env.API_URL}/PushNotificationTemplateget`);
    const PushNotificationTemplategetData = res.data;
    return PushNotificationTemplategetData.data;
})