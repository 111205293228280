import React from 'react'
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Defaultimg from "../../assets/default.jpg"

const TrackLengthPopup = (data) => {

  console.log(data, "aayass")

  const ground = data.data.GroundTypeModelData ? data.data.GroundTypeModelData : "N/A"

  const length = data.data.TrackLengthRaceCourseData ? data.data.TrackLengthRaceCourseData : "N/A"

  return (
    <div className="form">

      <div className='modalPreview'>


        <img src={data.data.RaceCourseImage ? data.data.RaceCourseImage : Defaultimg} className="PreviewImage" alt="" />




      </div>

      <div className="row mainrow">
        <div className="col-sm">
          <FloatingLabel
            controlId="floatingInput"
            label="Track Length"
            className="mb-3"
            name="TrackNameEn"

          >
            <Form.Control
              type="text"


              placeholder="Track Name"
              value={data.data.TrackLength} readOnly
            />
          </FloatingLabel>
        </div>
      </div>
      <div className="row mainrow">
        <div className="col-sm">
          <FloatingLabel
            controlId="floatingInput"
            label="Track Name"
            className="mb-3"
            name="TrackNameEn"

          >
            <Form.Control
              type="text"


              placeholder="Track Name"
              value={length.TrackNameEn} readOnly
            />
          </FloatingLabel>
          <span className="spanForm"> |</span>
        </div>

        <div className="col-sm">
          <FloatingLabel
            controlId="floatingInput"
            label="اسم المسار"
            className="mb-3 floatingInputAr"
            style={{ direction: "rtl", left: "initial", right: 0 }}
          >
            <Form.Control
              type="text"
              placeholder="اسم"
              readOnly
              value={length.TrackNameAr}

              style={{ direction: "rtl", left: "%" }}
            />
          </FloatingLabel>
        </div>
      </div>





      <div className="row mainrow">
        <div className="col-sm">
          <FloatingLabel
            controlId="floatingInput"
            label="Ground Type"
            className="mb-3"
            name="TrackNameEn"

          >
            <Form.Control
              type="text"


              placeholder="Track Name"
              value={ground.NameEn} readOnly
            />
          </FloatingLabel>
          <span className="spanForm"> |</span>
        </div>

        <div className="col-sm">
          <FloatingLabel
            controlId="floatingInput"
            label="اسم المسار"
            className="mb-3 floatingInputAr"
            style={{ direction: "rtl", left: "initial", right: 0 }}
          >
            <Form.Control
              type="text"
              placeholder="اسم"
              readOnly
              value={ground.NameAr}

              style={{ direction: "rtl", left: "%" }}
            />
          </FloatingLabel>
        </div>
      </div>


    </div>
  )
}

export default TrackLengthPopup