import React, { useState, useEffect } from "react";
import "../../Components/CSS/forms.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { fetchSinglejockey } from "../../redux/getReducer/getSingleJockey";
import swal from "sweetalert";
import axios from "axios";

import { ImCross } from 'react-icons/im';
import { Form, Input, Select } from 'antd';

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import { fetchNationalityList } from "../../redux/getDropDownReducer/getNationalityList";

import { AiOutlineReload } from "react-icons/ai";
import { Modal } from "react-bootstrap";
import NationalityPopup from "../PostTable/Nationality";
import Loader from "../../Components/Common/Loader";
const EditJockey = () => {
  const { data: NationalityList } = useSelector((state) => state.NationalityList);
  const [form] = Form.useForm();
  const { Option } = Select;
  const [NationalityID, setNationalityID] = useState("");
  const dispatch = useDispatch();
  const history = useNavigate();
  const { state } = useLocation();
  const [isLoading, setisLoading] = useState(false)
  const [isLoading1, setisLoading1] = useState(false);


  const { jockeyid } = state;



  const [JockeyLicenseDate, setJockeyLicenseDate] = useState("");
  const [DOB, setDOB] = useState("");
  const [showActivenationality, setShowNationality] = useState(false);

  const handleCloseActivenationality = () => setShowNationality(false);

  const handleShowActivenationality = async () => {
    await setShowNationality(true);
  };
  const FetchNew = () => {
    dispatch(fetchNationalityList());
  };

  const [state1, setState] = useState({
    NameEn: "",
    NameAr: "",
    ShortNameEn: "",
    ShortNameAr: "",
    DOB: "",
    RemarksEn: "",
    JockeyAllowance: "",
    MaximumJockeyWeight: "",
    MiniumumJockeyWeight: "",
    image: "",
    RemarksAr: "",
    Rating: "",
    JockeyLicenseDate: "",
    NationalityID: "",
    shortCode:""
  });
  const [image, setImage] = useState();
  const [preview, setPreview] = useState();

  const fileSelected = (event) => {
    const image = event.target.files[0];
    setImage(image, image);
  };
  const handleSearch = async (value) => {
    try {
      dispatch(fetchNationalityList({ value }))

    } catch (error) {

    }
  };

  const handleChange = async (value) => {

    await setNationalityID(value)
    setState({ ...state1, nationalityName: value })

  };

  const handlePreview = () => {
    setImage()
    setPreview()
    setisLoading1(true)
    document.getElementById("file").value = ""
  };
  useEffect(() => {
    dispatch(fetchSinglejockey());
  }, [dispatch]);
  //---------------------------get data from previous state---------------------------//


  console.log(jockeyid,"jockeyid <<<<<<<----------------------")


  useEffect(() => {
    if (jockeyid) {
      setState({
        NameEn: jockeyid.NameEn,
        NameAr: jockeyid.NameAr,
        ShortNameEn: jockeyid.ShortNameEn,
        ShortNameAr: jockeyid.ShortNameAr,
        RemarksEn: jockeyid.RemarksEn,
        RemarksAr: jockeyid.RemarksAr,
        MaximumJockeyWeight: jockeyid.MaximumJockeyWeight,
        MiniumumJockeyWeight: jockeyid.MiniumumJockeyWeight,
        Rating: jockeyid.Rating,
        JockeyLicenseDate: jockeyid.JockeyLicenseDate,
        DOB: jockeyid.DOB,
        JockeyAllowance: jockeyid.JockeyAllowance,
        NationalityID: jockeyid.NationalityID,
        shortCode: jockeyid.shortCode,
        nationalityName: jockeyid?.JockeyNationalityData ? jockeyid?.JockeyNationalityData.NameEn : null

      });
      let day123 = new Date(jockeyid.JockeyLicenseDate);
      let daydate = day123.getDate();
      if (daydate < 10) {
        daydate = `0${day123.getDate()}`;
      }
      let daymonth = day123.getMonth() + 1;
      if (daymonth < 10) {
        daymonth = `0${day123.getMonth() + 1}`;
      }
      let dayformat = `${day123.getFullYear()}-${daymonth}-${daydate}`;
      console.log(dayformat, "dayformat");

      setJockeyLicenseDate(dayformat);



      let day1231 = new Date(jockeyid.DOB);
      let daydate1 = day1231.getDate();
      if (daydate1 < 10) {
        daydate1 = `0${day1231.getDate()}`;
      }
      let daymonth1 = day1231.getMonth() + 1;
      if (daymonth1 < 10) {
        daymonth1 = `0${day1231.getMonth() + 1}`;
      }
      let dayformat1 = `${day1231.getFullYear()}-${daymonth1}-${daydate1}`;
      console.log(dayformat1, "dayformat");

      setDOB(dayformat1);

    } else {
      dispatch(fetchSinglejockey({ jockeyid }));
    }
  }, [dispatch, jockeyid]);
  //---------------------------Image Preview---------------------------//

  console.log(jockeyid,"jockeyid")
  useEffect(() => {
    if (image === undefined) {
      setPreview(jockeyid.image);
      return;
    }
    const objectUrl = URL.createObjectURL(image);
    setPreview(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [image, jockeyid.image]);

  //---------------------------options for dropdowns---------------------------//
  let AllNationality =
    NationalityList === undefined ? (
      <></>
    ) : (
      NationalityList.map(function (item) {
        return {
          id: item._id,
          value: item._id,
          label: item.NameEn,
        };
      })
    );

  


  //---------------------------Submit---------------------------//

  const submit = async () => {

    try {
      setisLoading(true)
      const formData = new FormData();
      formData.append("image", image);
      formData.append("NameEn", state1.NameEn);
      formData.append("NameAr", state1.NameAr + " ");
      formData.append("ShortNameEn", state1.ShortNameEn);
      formData.append("ShortNameAr", state1.ShortNameAr);
      formData.append("RemarksEn", state1.RemarksEn);
      formData.append("RemarksAr", state1.RemarksAr);
      formData.append("MaximumJockeyWeight", state1.MaximumJockeyWeight);
      formData.append("MiniumumJockeyWeight", state1.MiniumumJockeyWeight);
      formData.append("JockeyAllowance", state1.JockeyAllowance);
      formData.append("shortCode", state1.shortCode);

      formData.append("Rating", state1.Rating);
      formData.append("JockeyLicenseDate", JockeyLicenseDate);
      formData.append("NationalityID", NationalityID === undefined ? state1.NationalityID : NationalityID);
      formData.append("DOB", DOB);
      if (isLoading1 === true && image === undefined) {
        formData.append("deleteexisitingimage", isLoading1)
      }

      await axios.put(
        `${window.env.API_URL}/updateJockey/${jockeyid._id}`,
        formData
      );
      history("/jockey");
      swal({
        title: "Success!",
        text: "Data has been Updated successfully ",
        icon: "success",
        button: "OK",
      });
    } catch (error) {
      const err = error.response.data.message[0];
      const err1 = error.response.data.message[1];
      const err2 = error.response.data.message[2];
      swal({
        title: "Error!",
        text: err, err1, err2,
        icon: "error",
        button: "OK",
      });
    } setisLoading(false)
  };

  form.setFieldsValue({
    NameEn: state1.NameEn,
    NameAr: state1.NameAr,
    TitleEn: state1.TitleEn,
    TitleAr: state1.TitleAr,
    ShortEn: state1.ShortNameEn,
    ShortAr: state1.ShortNameAr,
    Description: state1.DetailEn,
    DescriptionAr: state1.DetailAr,
    RemarksEn: state1.RemarksEn,
    RemarksAr: state1.RemarksAr,
    nationalityName: state1.nationalityName,
    MiniumumJockeyWeight: state1.MiniumumJockeyWeight,
    MaximumJockeyWeight: state1.MaximumJockeyWeight,
    JockeyAllowance: state1.JockeyAllowance,
    Rating: state1.Rating,
    shortCode: state1.shortCode

  });

  return (
    <>
      <div className="page">
        <div className="rightsidedata">
          <div
            style={{
              marginTop: "30px",
            }}
          >
            <div className="Headers">Edit Jockey</div>
            {isLoading ? <Loader /> : <div className="form">
              <Form onFinish={submit} form={form}>
                <div className="row mainrow">
                  <div className="col-sm">
                    <Form.Item
                      name="NameEn"
                      label="Name"
                      rules={[
                        {
                          required: true,
                          message: 'Name (English) is required',
                        },
                      ]}
                    >
                      <Input placeholder="Name" onChange={(e) =>
                        setState({ ...state1, NameEn: e.target.value })
                      }
                        value={state1.NameEn} name="NameEn" />
                    </Form.Item>


                    <span className="spanForm spacer"> |</span>
                  </div>

                  <div className="col-sm">

                    <Form.Item
                      name="NameAr"
                      label="اسم"
                      rules={[
                        {
                          required: true,
                          message: 'Name (Arabic) is required',
                        },
                      ]}
                      style={{ direction: 'rtl' }}
                    >
                      <Input placeholder="اسم" onChange={(e) =>
                        setState({ ...state1, NameAr: e.target.value })
                      }
                        name="Name"
                        value={state1.NameAr} />
                    </Form.Item>
                  </div>
                </div>








                <div className="row mainrow">
                  <div className="col-sm">
                    <Form.Item
                      name="ShortEn"
                      label="Short Name"
                      rules={[
                        {
                          required: true,
                          message: 'Short Name (English) is required',
                        },
                      ]}
                    >
                      <Input placeholder="Short Name" onChange={(e) =>
                        setState({ ...state1, ShortNameEn: e.target.value })
                      }
                        value={state1.ShortNameEn} name="ShortEn" />
                    </Form.Item>


                    <span className="spanForm spacer"> |</span>
                  </div>

                  <div className="col-sm">

                    <Form.Item
                      name="ShortAr"
                      label="اسم قصير"
                      rules={[
                        {
                          required: true,
                          message: 'Name (Arabic) is required',
                        },
                      ]}
                      style={{ direction: 'rtl' }}
                    >
                      <Input placeholder="اسم قصير" onChange={(e) =>
                        setState({ ...state1, ShortNameAr: e.target.value })
                      }
                        name="ShortAr"
                        value={state1.ShortNameAr} />
                    </Form.Item>
                  </div>
                </div>

                <div className="row mainrow">
                  <div className="col-sm">

                    <Form.Item
                      name="RemarksEn"
                      label="Remarks"
                    
                    >
                      <Input placeholder="Remarks" onChange={(e) =>
                        setState({ ...state1, RemarksEn: e.target.value })
                      }
                        value={state1.RemarksEn} name="RemarksEn" />
                    </Form.Item>












                    <span className="spanForm spacer"> |</span>
                  </div>

                  <div className="col-sm">








                    <Form.Item
                      name="RemarksAr"
                      label="ملاحظات"
                   
                      style={{ direction: 'rtl' }}
                    >
                      <Input placeholder="ملاحظات" onChange={(e) =>
                        setState({ ...state1, RemarksAr: e.target.value })
                      }
                        name="RemarksAr"
                        value={state1.RemarksAr} />
                    </Form.Item>




                  </div>
                </div>

                <div className="row mainrow">
                  <div className="col-sm">



                    <Form.Item
                      name="Rating"
                      label="Rating"
                      rules={[
                        {
                          required: true,
                          message: 'Rating  is required',
                        },
                      ]}
                    >
                      <Input onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }} placeholder="Rating" onChange={(e) =>
                        setState({ ...state1, Rating: e.target.value })
                      }
                        value={state1.Rating} name="Rating" type="number" />
                    </Form.Item>













                  </div>
                </div>
                <div className="row mainrow ">
                  <p className="selectLabel">Jockey license Date</p>
                  <input
                    type="date"
                    data-placeholder="Jockey license Date"
                    onChange={(e) => setJockeyLicenseDate(e.target.value)}
                    value={JockeyLicenseDate}
                    className="dateforraces"
                    required
                  />
               

                </div>
                <div className="row mainrow">
                  <p className="selectLabel">Date of Birth</p>

                  <input
                    type="date"
                    data-placeholder="Date of Birth"
                    onChange={(e) => setDOB(e.target.value)}
                    value={DOB}
                    className="dateforraces"
                    required
                  />





                </div>

                <div className="row mainrow">
                  <div className="col-sm">
                    <Form.Item
                      name="MiniumumJockeyWeight"
                      label="Miniumum Jockey Weight"
                      rules={[
                        {
                          required: true,
                          message: 'Miniumum Jockey Weight is required',
                        },
                      ]}
                    >
                      <Input placeholder="MiniumumJockeyWeight" onChange={(e) =>
                        setState({ ...state1, MiniumumJockeyWeight: e.target.value })
                      }
                        value={state1.MiniumumJockeyWeight} name="MiniumumJockeyWeight" type="number" />
                    </Form.Item>



                    {/* <span className="spanForm"> |</span> */}
                  </div>


                </div>
                <div className="row mainrow">
                  <div className="col-sm">





                    <Form.Item
                      name="MaximumJockeyWeight"
                      label="Maximum Jockey Weight"
                      rules={[
                        {
                          required: true,
                          message: 'Maximum Jockey Weight  is required',
                        },
                      ]}
                    >
                      <Input placeholder="Maximum Jockey Weight" onChange={(e) =>
                        setState({ ...state1, MaximumJockeyWeight: e.target.value })
                      }
                        value={state1.MaximumJockeyWeight} name="MaximumJockeyWeight" type="number" />
                    </Form.Item>







                    {/* <span className="spanForm"> |</span> */}
                  </div>


                </div>
                <div className="row mainrow">
                  <div className="col-sm">


                    <Form.Item
                      name="JockeyAllowance"
                      label="Jockey Allowance"
                      rules={[
                        {
                          required: true,
                          message: 'JockeyAllowance  is required',
                        },
                      ]}
                    >
                      <Input onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }} placeholder="Jockey Allowance" onChange={(e) =>
                        setState({ ...state1, JockeyAllowance: e.target.value })
                      }
                        value={state1.JockeyAllowance} name="JockeyAllowance" />
                    </Form.Item>













                    {/* <span className="spanForm"> |</span> */}
                  </div>



                </div>
                <div className="row mainrow edit">

                  <div className="col-sm">
                    <Form.Item

                      name="nationalityName"
                      label="Nationality"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: 'Please select your Nationality!',
                        },
                      ]}
                    >
                      <Select
                        value={NationalityID}
                        filterOption={false}
                        defaultValue={state.nationalityName}
                        onChange={handleChange}
                        onSearch={handleSearch}
                        showSearch placeholder="Please select a Nationality" >

                        {
                          NationalityList?.map((item) => (


                            <Option value={item._id}>{item.NameEn} ({item.NameAr})</Option>

                          ))
                        }
                      </Select>
                    </Form.Item>


                    <span className="spanForm selectSpacer">
                      <OverlayTrigger
                        overlay={
                          <Tooltip id={`tooltip-top`}>Add more</Tooltip>
                        }
                      >
                        <span
                          className="addmore"
                          onClick={handleShowActivenationality}
                        >
                          +
                        </span>
                      </OverlayTrigger>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id={`tooltip-top`}>Fetch New</Tooltip>
                        }
                      >
                        <span className="addmore" onClick={FetchNew}>
                          <AiOutlineReload />
                        </span>
                      </OverlayTrigger>

                    </span>

                  </div>


                </div>

                <div className="row mainrow">
                    <div className="col-sm">
                      <Form.Item
                        name="shortCode"
                        label="Short Code"
                        rules={[
                          {
                            required: true,
                            message: "short Code is required"
                          },
                        ]}
                      >
                        <Input
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          onChange={(e) =>
                            setState({ ...state1, shortCode: e.target.value })
                          }
                          value={state1.shortCode}
                          onPaste={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          placeholder="Short Code"
                          maxLength="5"
                        />
                      </Form.Item>
                    </div>
                  </div>

                <div className="ButtonSection">
                  <div className={image === undefined ? "inputFile" : ""}>
                    <label className="Multipleownerlabel">
                      Select Jockey image
                    </label>
                    <input
                      type="file"
                      onChange={fileSelected}
                      className="formInput"
                      id="file"
                    />
                    {preview && (
                      <>
                        <ImCross
                          onClick={handlePreview}
                          className="crossIcon2"
                        />
                        <img src={preview} className="PreviewImage" alt="" />
                      </>
                    )}
                  </div>
                  <div>
                    <button type="submit" className="SubmitButton" disabled={isLoading}>
                      Update
                    </button></div>
                </div>
              </Form>
            </div>}
          </div>
        </div>
      </div>
      <Modal
        show={showActivenationality}
        onHide={handleCloseActivenationality}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2>Nationality</h2>
        </Modal.Header>
        <Modal.Body>
          <NationalityPopup setShowNationality={setShowNationality} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditJockey;