import React, { useState, useEffect } from "react";
import "../../Components/CSS/forms.css";
import { useNavigate, useLocation } from "react-router-dom";
import swal from "sweetalert";
import axios from "axios";
import { ImCross } from "react-icons/im";

import Loader from "../../Components/Common/Loader";
import { Form, Input } from 'antd';
const EditBreeder = () => {
  const history = useNavigate();
  const { state } = useLocation();
  const [form] = Form.useForm();

  const { breederid } = state;

  const [image, setImage] = useState();
  const [preview, setPreview] = useState();
  const [isLoading, setisLoading] = useState(false)
  const [isLoading1, setisLoading1] = useState(false);

  const [state1, setState] = useState({
    NameEn: "",
    NameAr: "",
    shortCode: "",
    DescriptionEn: "",
    DescriptionAr: "",
    image: image,
  });

  const fileSelected = (event) => {
    const image = event.target.files[0];
    setImage(image);
  };
  //----------------------------get Data -------------------------//

  useEffect(() => {
    if (breederid) {
      setState({
        NameEn: breederid.NameEn,
        NameAr: breederid.NameAr,
        DescriptionEn: breederid.DescriptionEn,
        DescriptionAr: breederid.DescriptionAr,
        shortCode: breederid.shortCode,
        image: image,
      });
    } else {
      alert("No Data");
    }
  }, [breederid, image]);
  //----------------------------image Preview -------------------------//
  useEffect(() => {
    if (image === undefined) {
      setPreview(breederid.image);
      return;
    }

    const objectUrl = URL.createObjectURL(image);
    setPreview(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [breederid.image, image]);

  const handlePreview = () => {
    setImage();
    setPreview();
    setisLoading1(true)
    document.getElementById("file").value = " ";
  };
  //----------------------------submit -------------------------//


  const submit = async () => {

    try {
      setisLoading(true)
      const formData = new FormData();
      formData.append("image", image);
      formData.append("NameEn", state1.NameEn);
      formData.append("NameAr", state1.NameAr + " ");
      formData.append("shortCode", state1.shortCode);
      formData.append("DescriptionEn", state1.DescriptionEn);
      formData.append("DescriptionAr", state1.DescriptionAr + " ");
      if (isLoading1 === true && image === undefined) {
        formData.append("deleteexisitingimage", isLoading1)
      }

      await axios.put(
        `${window.env.API_URL}/updateBreeder/${breederid._id}`,
        formData
      );
      history("/breederlist");
      swal({
        title: "Success!",
        text: "Data has been Updated successfully ",
        icon: "success",
        button: "OK",
      });
    } catch (error) {
      const err = error.response.data.message[0];
      const err1 = error.response.data.message[1];
      const err2 = error.response.data.message[2];
      swal({
        title: "Error!",
        text: err,
        err1,
        err2,
        icon: "error",
        button: "OK",
      });
    }
    setisLoading(false)
  };
  form.setFieldsValue({
    NameEn: state1.NameEn,
    NameAr: state1.NameAr,
    Description: state1.DescriptionEn,
    DescriptionAr: state1.DescriptionAr,
    shortCode: state1.shortCode

  });
  return (
    <>
      <div className="page">
        <div className="rightsidedata">
          <div
            style={{
              marginTop: "30px",
            }}
          >
            <div className="Headers">Edit Breeder</div>
            {isLoading ? <Loader /> :
              <div className="form">
                <Form onFinish={submit} form={form}>
                  <div className="row mainrow">
                    <div className="col-sm">
                      <Form.Item
                        name="NameEn"
                        label="Name"
                        rules={[
                          {
                            required: true,
                            message: 'Name (English) is required',
                          },
                        ]}
                      >
                        <Input placeholder="Name" onChange={(e) =>
                          setState({ ...state1, NameEn: e.target.value })
                        }
                          value={state1.NameEn} name="NameEn" />
                      </Form.Item>


                      <span className="spanForm spacer"> |</span>
                    </div>

                    <div className="col-sm">
                      <Form.Item
                        name="NameAr"
                        label="اسم"
                        rules={[
                          {
                            required: true,
                            message: 'Name (Arabic) is required',
                          },
                        ]}
                        style={{ direction: 'rtl' }}
                      >
                        <Input placeholder="اسم" onChange={(e) =>
                          setState({ ...state1, NameAr: e.target.value })
                        }
                          name="Name"
                          value={state1.NameAr} />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="row mainrow">
                    <div className="col-sm">

                      <Form.Item
                        name="Description"
                        label="Description"
                        rules={[
                          {
                            required: true,
                            message: 'Description (English) is required',
                          },
                        ]}
                      >
                        <Input placeholder="Description" onChange={(e) =>
                          setState({ ...state1, DescriptionEn: e.target.value })
                        }
                          value={state1.DescriptionEn} name="Description" />
                      </Form.Item>












                      <span className="spanForm spacer"> |</span>
                    </div>

                    <div className="col-sm">








                      <Form.Item
                        name="DescriptionAr"
                        label="وصف"
                        rules={[
                          {
                            required: true,
                            message: 'Description (Arabic) is required',
                          },
                        ]}
                        style={{ direction: 'rtl' }}
                      >
                        <Input placeholder="وصف" onChange={(e) =>
                          setState({ ...state1, DescriptionAr: e.target.value })
                        }
                          name="DescriptionAr"
                          value={state1.DescriptionAr} />
                      </Form.Item>




                    </div>
                  </div>

                  <div className="row mainrow">
                    <div className="col-sm">
                      <Form.Item
                        name="shortCode"
                        label="Short Code"
                        rules={[
                          {
                            required: true,
                            message: "short Code is required"
                          },
                        ]}
                      >
                        <Input
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          onChange={(e) =>
                            setState({ ...state1, shortCode: e.target.value })
                          }
                          value={state1.shortCode}
                          onPaste={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          placeholder="Short Code"
                          maxLength="5"
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="ButtonSection">
                    <div className={image === undefined ? "inputFile" : ""}>
                      <label className="Multipleownerlabel">
                        Select Breeder image
                      </label>
                      <input
                        type="file"
                        onChange={fileSelected}
                        className="formInput"
                        id="file"
                      />
                      {preview && (
                        <>
                          <ImCross
                            onClick={handlePreview}
                            className="crossIcon"
                          />
                          <img src={preview} className="PreviewImage" alt="" />
                        </>
                      )}
                    </div>
                    <div>
                      <button type="submit" className="SubmitButton" disabled={isLoading}>
                        Update
                      </button></div>
                  </div>
                </Form>
              </div>}
          </div>
        </div>
      </div>
    </>
  );
};

export default EditBreeder;
