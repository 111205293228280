import React, { useState, useEffect } from "react";
import "../../Components/CSS/forms.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Form, Input } from 'antd';
import swal from "sweetalert";
import { ImCross } from "react-icons/im";
import Loader from "../../Components/Common/Loader";

const AdsForm = () => {
  //for error

  const [isLoading, setisLoading] = useState(false);

  const history = useNavigate();
  const [TitleEn, setTitleEn] = useState("");
  const [TitleAr, setTitleAr] = useState("");
  const [DescriptionAr, setDescriptionAr] = useState("");
  const [DescriptionEn, setDescriptionEn] = useState("");
  const [Url, setUrl] = useState("");
  const [image, setImage] = useState();

  const [preview, setPreview] = useState();
  //----------------------------submit -------------------------//
  const submit = async (event) => {
    setisLoading(true);
    try {
      const formData = new FormData();
      formData.append("image", image);
      formData.append("TitleEn", TitleEn);
      formData.append("TitleAr", TitleAr);
      formData.append("DescriptionEn", DescriptionEn);
      formData.append("DescriptionAr", DescriptionAr);
      formData.append("Url", Url);
      await axios.post(`${window.env.API_URL}/uploadAds`, formData);
      setisLoading(false);
      swal({
        title: "Success!",
        text: "Data has been added Successfully",
        icon: "success",
        button: "OK",
      });

      history("/ads");
      setisLoading(false);
    } catch (error) {
      const err = error.response.data.message;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
      setisLoading(false);
    }
  };
  //----------------------------image preview -------------------------//

  useEffect(() => {
    if (!image) {
      setPreview(undefined);
      return;
    }
    if (image.name.match(/\.(gif)$/)) {
      setImage("");
      setPreview(undefined);
      document.getElementById("file").value = "";
      swal({
        title: "Error!",
        text: "Invalid Format ",
        icon: "error",
        button: "OK",
      });
    }

    const objectUrl = URL.createObjectURL(image);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [image]);
  //----------------------------remove preview -------------------------//

  const handlePreview = () => {
    setImage();
    document.getElementById("file").value = "";
  };

  const onSelectFile = (e) => {
    setImage(e.target.files[0]);
  };

  return (
    <>
      <div className="page">
        <div className="rightsidedata">
          <div
            style={{
              marginTop: "30px",
            }}
          >
            <div className="Headers">Create Advertisement</div>
            {isLoading ? <Loader /> : <div className="form">
              <Form onFinish={submit}>
                <div className="row mainrow">
                  <div className="col-sm">





                    <Form.Item
                      name="NameEn"
                      label="Title"
                      rules={[
                        {
                          required: true,
                          message: 'Title (English) is required',
                        },
                      ]}
                    >
                      <Input placeholder="Title" onChange={(e) => setTitleEn(e.target.value)}
                        value={TitleEn} name="NameEn" />
                    </Form.Item>








                    <span className="spanForm" spacer> |</span>
                    <span className={Error.status ? "success" : "error"}>
                      {Error.message}
                    </span>
                  </div>

                  <div className="col-sm">



                    <Form.Item
                      name="TitleAr"
                      label="عنوان"
                      rules={[
                        {
                          required: true,
                          message: 'Title (Arabic) is required',
                        },
                      ]}
                      style={{ direction: 'rtl' }}
                    >
                      <Input placeholder="عنوان" onChange={(e) => setTitleAr(e.target.value)}
                        name="TitleAr"
                        value={TitleAr} />
                    </Form.Item>









                  </div>
                </div>

                <div className="row mainrow">
                  <div className="col-sm">




                    <Form.Item
                      name="DescriptionEn"
                      label="Description"
                      rules={[
                        {
                          required: true,
                          message: 'Description (English) is required',
                        },
                      ]}
                    >
                      <Input placeholder="Description" onChange={(e) => setDescriptionEn(e.target.value)}
                        value={DescriptionEn} name="DescriptionEn" />
                    </Form.Item>









                    <span className="spanForm spacer"> |</span>

                  </div>

                  <div className="col-sm">



                    <Form.Item
                      name="DescriptionAr"
                      label="وصف"
                      rules={[
                        {
                          required: true,
                          message: 'Description (Arabic) is required',
                        },
                      ]}
                      style={{ direction: 'rtl' }}
                    >
                      <Input placeholder="وصف" onChange={(e) => setDescriptionAr(e.target.value)}
                        name="DescriptionAr"
                        value={DescriptionAr} />
                    </Form.Item>








                  </div>
                </div>

                <div className="row mainrow">
                  <div className="col-sm">





                    <Form.Item
                      name="Url"
                      label="Url"
                      rules={[
                        {
                          required: true,
                          message: 'Url is required',
                        },
                      ]}
                    >
                      <Input placeholder="Url" onChange={(e) => setUrl(e.target.value)}
                        name="Name"
                        value={Url} />
                    </Form.Item>






                  </div>
                </div>

                <div className="ButtonSection">
                  <div>
                    <label className="Multipleownerlabel">
                      Select Advertisement image
                    </label>
                    <input
                      type="file"
                      onChange={onSelectFile}
                      className="formInput"
                      id="file"
                    />
                    {image && (
                      <>
                        <ImCross
                          onClick={handlePreview}
                          className="crossIcon"
                        />
                        <img src={preview} className="PreviewImage" alt="" />
                      </>
                    )}
                  </div>
                  <div>
                    <button
                      type="submit"
                      className="SubmitButton"
                      disabled={isLoading}
                    >
                                           Save

                    </button></div>
                </div>
              </Form>
            </div>}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdsForm;
