import React, { useState, useEffect } from "react";
import "../../Components/CSS/forms.css";
import { useNavigate, useLocation } from "react-router-dom";
import swal from "sweetalert";
import axios from "axios";


import { Form, Input } from 'antd';
const EditSeo = () => {
  const history = useNavigate();
  const { state } = useLocation();
  const [form] = Form.useForm();
  const { seoid } = state;

  const [isLoading, setisLoading] = useState(false);
  const [state1, setState] = useState({
    KeywordEn: "",
    KeywordAr: "",
    TitleEn: "",
    TitleAr: "",
  });
  //---------------------------get Data from previous State---------------------------//
  useEffect(() => {
    if (seoid) {
      setState({
        KeywordEn: seoid.KeywordEn,
        KeywordAr: seoid.KeywordAr,
        TitleEn: seoid.TitleEn,
        TitleAr: seoid.TitleAr,
      });
    } else {
    }
  }, [seoid]);
  //---------------------------submit---------------------------//
  const submit = async () => {

    try {
      setisLoading(true);
      const formData = new FormData();
      formData.append("KeywordEn", state1.KeywordEn);
      formData.append("KeywordAr", state1.KeywordAr + " ");
      formData.append("TitleEn", state1.TitleEn);
      formData.append("TitleAr", state1.TitleAr + " ");

      await axios.put(
        `${window.env.API_URL}/updateSeoKeyword/${seoid._id}`,
        formData
      );
      history("/Seolisting");
      swal({
        title: "Success!",
        text: "Data has been Updated successfully ",
        icon: "success",
        button: "OK",
      });
    } catch (error) {
      const err = error.response.data.message[0];
      const err1 = error.response.data.message[1];
      const err2 = error.response.data.message[2];
      swal({
        title: "Error!",
        text: err,
        err1,
        err2,
        icon: "error",
        button: "OK",
      });
    }
    setisLoading(false);
  };
  form.setFieldsValue({
    Keyword: state1.KeywordEn,
    KeywordAr: state1.KeywordAr,
    TitleEn: state1.TitleEn,
    NameAr: state1.TitleAr,


  });
  return (
    <>
      <div className="page">
        <div className="rightsidedata">
          <div
            style={{
              marginTop: "30px",
            }}
          >
            <div className="Headers">Edit SEO</div>
            <div className="form">
              <Form onFinish={submit} form={form}>
                <div className="row mainrow">
                  <div className="col-sm">


                    <Form.Item
                      name="Keyword"
                      label="KeyWord"
                      rules={[
                        {
                          required: true,
                          message: 'KeyWord (English) is required',
                        },
                      ]}
                    >
                      <Input placeholder="KeyWord" onChange={(e) =>
                        setState({ ...state1, KeywordEn: e.target.value })
                      }
                        value={state1.KeywordEn} name="KeyWord" />
                    </Form.Item>













                    <span className="spanForm"> |</span>
                  </div>

                  <div className="col-sm">






                    <Form.Item
                      name="KeywordAr"
                      label="الكلمة الرئيسية "
                      rules={[
                        {
                          required: true,
                          message: 'KeyWord (Arabic) is required',
                        },
                      ]}
                      style={{ direction: 'rtl' }}
                    >
                      <Input placeholder="الكلمة الرئيسية " onChange={(e) =>
                        setState({ ...state1, KeywordAr: e.target.value })
                      }
                        name="KeywordAr"
                        value={state1.KeywordAr} />
                    </Form.Item>




                  </div>
                </div>

                <div className="row mainrow">
                  <div className="col-sm">





                    <Form.Item
                      name="TitleEn"
                      label="Title"
                      rules={[
                        {
                          required: true,
                          message: 'Title (English) is required',
                        },
                      ]}
                    >
                      <Input placeholder="Title" onChange={(e) =>
                        setState({ ...state1, TitleEn: e.target.value })
                      }
                        value={state1.TitleEn} name="NameEn" />
                    </Form.Item>






                    <span className="spanForm spacer"> |</span>
                  </div>

                  <div className="col-sm">



                    <Form.Item
                      name="NameAr"
                      label="عنوان"
                      rules={[
                        {
                          required: true,
                          message: 'Title (Arabic) is required',
                        },
                      ]}
                      style={{ direction: 'rtl' }}
                    >
                      <Input placeholder="عنوان" onChange={(e) =>
                        setState({ ...state1, TitleAr: e.target.value })
                      }
                        name="Name"
                        value={state1.TitleAr} />
                    </Form.Item>











                  </div>
                </div>

                <div
                  className="ButtonSection"
                  style={{ justifyContent: "end" }}
                >
                  <button
                    type="submit"
                    className="SubmitButton"
                    disabled={isLoading}
                  >
                    Update
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditSeo;
