import React, { useEffect, useState } from "react";
import { fetchSponsor, STATUSES } from "../../redux/getReducer/getSponsorSlice";
import { useDispatch, useSelector } from "react-redux";
import { MdAutoDelete, MdDelete } from "react-icons/md";
import swal from "sweetalert";
import { Link, useNavigate } from "react-router-dom";
import { BiEdit } from "react-icons/bi";
import ScrollContainer from "react-indiana-drag-scroll";
import SponserPopup from "../../Components/Popup/SponserPopup";
import { Modal } from "react-bootstrap";
import Lottie from "lottie-react";
import HorseAnimation from "../../assets/horselottie.json";
import axios from "axios";
import { BsEyeFill } from "react-icons/bs";
import Pagination from "./Pagination";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { BiFilter } from "react-icons/bi";
import { Form } from "react-bootstrap";
import Defaultimg from "../../assets/default.jpg";
import CSVSponsor from "../../Components/CSVUploadPopup/SponsorPopup";
import Notfound from "../../Notfound";

const Slider = () => {
  //---------------------------state-------------------------//

  const [Value, setValue] = useState(false);
  const [ShowCalender, setShowCalender] = useState(false);
  const { userInfo } = useSelector((state) => state.user);

  const [SearchTitle, setSearchTitle] = useState("");
  const [TotalCount, setTotalCount] = useState();
  const [TotalPages, setTotalPages] = useState("");
  //For Modal
  const [show, setShow] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [modaldata, setmodaldata] = useState();
  const handleClose = () => setShow(false);
  const handleShow = async (data) => {
    setmodaldata(data);
    await setShow(true);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: sponsor, status } = useSelector((state) => state.sponsor);

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(8);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  //---------------------------modal-------------------------//

  const [showCSV, setShowCSV] = useState(false);
  const [modaldataCSV, setmodaldataCSV] = useState();
  const handleCloseCSV = () => setShowCSV(false);
  const handleShowCSV = async (data) => {
    setmodaldataCSV(data);
    await setShowCSV(true);
  };

  const Data1 = async () => {
    const res = await axios.get(
      `${window.env.API_URL}Sponsorget?TitleEn=${SearchTitle === undefined ? '' : SearchTitle}&page=${currentPage}`
    );
    const totalcount = res.data.totalcount;
    setTotalCount(totalcount);
    const TotalPagesData = res.data.totalPages;
    setTotalPages(TotalPagesData);
    if(TotalPagesData <= 1){
      setCurrentPage(1)
    }
  };

  const GetSearch = async () => {
    dispatch(fetchSponsor({ SearchTitle }));
    Data1(currentPage);
  };

  useEffect(() => {
    dispatch(fetchSponsor({ SearchTitle, currentPage }));
    Data1(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, currentPage]);
  //---------------------------delete-------------------------//

  const handleRemove1 = async (Id) => {
    try {
      swal({
        title: "Are you sure?",
        text: "do you want to delete this data ?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          await axios.delete(`${window.env.API_URL}/softdeletesponsor/${Id}`);
          swal("Your data has been deleted Successfully!", {
            icon: "success",
          });
          dispatch(fetchSponsor({ SearchTitle }));
        } else {
          swal("Your data is safe!");
        }
      });
    } catch (error) {
      const err = error.response.data.message;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
    }
  };

  const handleRemove = async (id) => {
    try {
      swal({
        title: "Are you sure?",
        text: "do you want to delete this data ?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          setLoading(true)
          const res = await axios.delete(`${window.env.API_URL}/DeleteSponsorAlltoAll/${id}`);
          const msg = res.data.message ?  res.data.message : "deleted";
          swal(msg, {
            icon: "",
          });
      let data1 =  JSON.stringify(res.data.data)
      const blob = new Blob([data1]);
      if(res?.data?.flag){
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.download = "report1.json";
        link.href = url;
        link.click();
        setLoading(false)
            GetSearch({ currentPage });

      }     
      setLoading(false)
          GetSearch({ currentPage });

        } else {
          swal("Your data is safe!");
        }
      });
    } catch (error) {
      const err = error.success;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
      setLoading(false)

    }
  }

  if (status === STATUSES.ERROR) {
    return (
      <h2
        style={{
          margin: "100px",
        }}
      >
        Something went wrong!
      </h2>
    );
  }

  return (
    <>
      <div className="page">
        <div className="rightsidedata">
          <div
            style={{
              marginTop: "30px",
            }}
          >
            <div className="Header ">
              <h4>Sponsor Listings</h4>

              <div>
                <Link to="/sponsorform">
                  <button>Add Sponsor</button>
                </Link>
                <OverlayTrigger
                  overlay={<Tooltip id={`tooltip-top`}>Filter</Tooltip>}
                >
                  <span className="addmore">
                    <BiFilter
                      className="calendericon"
                      onClick={() => setShowCalender(!ShowCalender)}
                    />
                  </span>
                </OverlayTrigger>

                <p onClick={() => handleShowCSV()} className="importcsv">
                  Import JSON
                </p>
              </div>
            </div>
            <div>
              {ShowCalender ? (
                <span className="transitionclass">
                  <div className="userfilter">
                    <div className="filtertextform forflex">
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setSearchTitle(e.target.value)}
                        placeholder="Enter Name"
                      />
                    </div>
                  </div>
                  <button className="filterbtn" onClick={GetSearch}>
                    Apply Filter
                  </button>
                </span>
              ) : (
                <></>
              )}
            </div>
            <div className="div_maintb">
              <ScrollContainer className="scroll-container">
                <table striped bordered hover>
                  <thead>
                    <tr>
                      <th style={{ textAlign: "center" }}>Action</th>

                      <th>Name </th>
                      <th>Name Arabic</th>
                      <th>Description </th>
                      <th>Description Arabic</th>
                      <th>Url</th>
                      <th>Image</th>
                      {/* <th>Active</th> */}
                    </tr>
                  </thead>
                  {sponsor === [] ? (
                    <Notfound />
                  ) : (
                    <tbody>
                      {status === "loading" ? (
                        <Lottie
                          animationData={HorseAnimation}
                          loop={true}
                          className="TableLottie"
                        />
                      ) : (
                        sponsor.map((item, index) => {
                          return (
                            <>
                              <tr className="tr_table_class">
                                <td
                                  className="table_delete_btn1"

                                >
                                  <BiEdit
                                    onClick={() =>
                                      navigate("/editsponsor", {
                                        state: {
                                          sponsorid: item,
                                        },
                                      })
                                    }
                                  />
                                   <MdAutoDelete
                                    style={{
                                      fontSize: "22px",
                                    }}
                                    onClick={() => handleRemove(item._id)}
                                  />
                                 
                                  <BsEyeFill onClick={() => handleShow(item)} />
                                </td>
                                <td>{item.TitleEn}</td>
                                <td>{item.TitleAr}</td>
                                <td>{item.DescriptionEn}</td>
                                <td>{item.DescriptionAr}</td>
                                <td>{item.Url}</td>
                                <td>
                                  <img
                                    src={item.image ? item.image : Defaultimg}
                                    alt=""
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  />
                                </td>
                                {/* <td>
                                  <Form.Check
                                    type="switch"
                                    id="custom-switch"
                                    onChange={() => setValue(true)}

                                    value={Value}
                                  />
                                </td> */}
                              </tr>
                            </>
                          );
                        })
                      )}
                    </tbody>
                  )}
                </table>
              </ScrollContainer>
            </div>
          </div>
          <Pagination
            postsPerPage={postsPerPage}
            totalPosts={TotalCount}
            paginate={paginate}
            currentPage={currentPage}
            TotalPages={TotalPages}
          />
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2>Sponsor </h2>
        </Modal.Header>
        <Modal.Body>
          <SponserPopup data={modaldata} />
        </Modal.Body>

        <Modal.Footer>
          <button onClick={handleClose} className="modalClosebtn">
            Close
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showCSV}
        onHide={handleCloseCSV}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2 style={{ fontFamily: "inter" }}>Sponsor JSON</h2>
        </Modal.Header>
        <Modal.Body>
          <CSVSponsor data={modaldataCSV} />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};
export default Slider;
