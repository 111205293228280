import React, { useState, useEffect } from "react";
import "../../Components/CSS/forms.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import swal from "sweetalert";
import axios from "axios";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";


import { fetchTrainerList } from "../../redux/getDropDownReducer/getTrainerList";
import { fetchbreeder } from "../../redux/getReducer/getBreeder";
import { fetchnationality } from "../../redux/getReducer/getNationality";
import { fetchHorseKind } from "../../redux/getReducer/getHorseKind";
import { fetchgenderList } from "../../redux/getDropDownReducer/getGenderList";
import { AiOutlineReload } from "react-icons/ai";
import { Modal } from "react-bootstrap";
import BreederPopup from "../PostTable/Breeder";
import ColorPopup from "../PostTable/Color";
import OwnerPopup from "../PostTable/Owner/OwnerForm";
import TrainerPopup from "../PostTable/PostTrainer";
import GenderPopup from "../PostTable/Gender";
import NationalityPopup from "../PostTable/Nationality";
import HorseKindPopup from "../PostTable/Horsekindform";
import { ImCross } from "react-icons/im";
import Moment from "react-moment";
import DatePicker from "react-date-picker";
import { fetchNationalityList } from "../../redux/getDropDownReducer/getNationalityList";
import { fetchColorDropdown } from "../../redux/getDropDownReducer/getColor";
import { fetchOwnerList } from "../../redux/getDropDownReducer/getOwnerList";
import { fetchBreederList } from "../../redux/getDropDownReducer/getBreederList";
import { fetchHorseKindData } from "../../redux/getDropDownReducer/getHorseKindData";
import Loader from "../../Components/Common/Loader";
import { Input, Form, Select } from "antd";
import { fetchgender } from "../../redux/getReducer/getGenderSlice";
//---------------------------options for dropdowns---------------------------//



const Gelteds = [
  {
    id: 0,
    label: "false"

  },
  {
    id: 1,
    label: "true"

  }
];


const Foals = [
  { id: "0", value: "1", label: "1" },
  { id: "1", value: "2", label: "2" },
  { id: "2", value: "3", label: "3" },
  { id: "3", value: "4", label: "4" },
  { id: "4", value: "5", label: "5" },
  { id: "5", value: "6", label: "6" },
  { id: "6", value: "7", label: "7" },
  { id: "7", value: "8", label: "8" },
  { id: "8", value: "9", label: "9" },
  { id: "9", value: "10", label: "10" },
];

const EditHorse = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const { state } = useLocation();
  const [isLoading1, setisLoading1] = useState(false);

  const { data: TrainerList } = useSelector((state) => state.TrainerList);
  const { data: OwnerList } = useSelector((state) => state.OwnerList);
  const { data: BreederList } = useSelector((state) => state.BreederList);

  const { data: gender } = useSelector((state) => state.gender);
  const { data: NationalityList } = useSelector(
    (state) => state.NationalityList
  );
  const { data: colordropdown } = useSelector((state) => state.colordropdown);
  const { data: HorseKindData } = useSelector((state) => state.HorseKindData);

  const { data: HorseKind } = useSelector((state) => state.HorseKind);

  const { horseid } = state;

  const SearchTitle = ""
  const SearchCode = ""
  const currentPage = 1;

  const [Breeder, setBreeder] = useState("");
  const [ActiveTrainer, setActiveTrainer] = useState("");
  const [ActiveOwner, setActiveOwner] = useState("");
  const [Sex, setSex] = useState("");
  const [NationalityID, setNationalityID] = useState("");
  const [load, setLoad] = useState(false)

  const [ColorID, setColor] = useState("");
  const [Value3, setValue3] = useState('');
  const [Rds, setRds] = useState("");
  const [isGelded, setisGelded] = useState("");

  const [HorseStatus, setHorseStatus] = useState("");
  const [KindHorse, setKindHorse] = useState("");
  const [Dam, setDam] = useState("");
  const [GSire, setGSire] = useState("");
  const [Sire, setSire] = useState("");
  const [DOB, setDOB] = useState("");

  const [Value2, setValue2] = useState("");
  const [Value4, setValue4] = useState("");
  const [Value5, setValue5] = useState("");
  const [Value6, setValue6] = useState("");
  const { Option } = Select;
  const [Value7, setValue7] = useState('')
  const [Foal, setFoal] = useState("");
  //---------------------------show popup---------------------------//
  const [showBreeder, setShowBreeder] = useState(false);
  const [showColor, setShowColor] = useState(false);
  const [showGender, setShowGender] = useState(false);
  const [showActiveOwner, setShowActiveOwner] = useState(false);
  const [showActiveTrainer, setShowActiveTrainer] = useState(false);
  const [showActivenationality, setShowActivenationality] = useState(false);
  const [showHorseKind, setShowHorseKind] = useState(false);

  const handleCloseBreeder = () => setShowBreeder(false);
  const handleCloseColor = () => setShowColor(false);
  const handleCloseGender = () => setShowGender(false);
  const handleCloseActiveOwner = () => setShowActiveOwner(false);
  const handleCloseActiveTrainer = () => setShowActiveTrainer(false);
  const handleCloseActivenationality = () => setShowActivenationality(false);
  const handleCloseHorseKind = () => setShowHorseKind(false);

  const handleShowBreeder = async () => {
    await setShowBreeder(true);
  };

  const handleShowColor = async () => {
    await setShowColor(true);
  };

  const handleShowGender = async () => {
    await setShowGender(true);
  };

  const handleShowActiveOwner = async () => {
    await setShowActiveOwner(true);
  };

  const handleShowActiveTrainer = async () => {
    await setShowActiveTrainer(true);
  };

  const handleShowActivenationality = async () => {
    await setShowActivenationality(true);
  };

  const handleShowHorseKind = async () => {
    await setShowHorseKind(true);
  };
  const handleSearch = async (value) => {
    try {
      dispatch(fetchNationalityList({ value }))

    } catch (error) {

    }
  };
  const handleChange3 = (value) => {
    setSex(value)

    setValue5(value)
    setState({ ...state1, genderName: value })
  }

  const handleChange4 = (value) => {

    setValue5(value)
    setDam(value)

    setState({ ...state1, damName: value })
  }
  const handleChange9 = (value) => {

    setActiveTrainer(value)

    setState({ ...state1, trainerName: value })
  }

  const handleSearch1 = (value) => {
    setValue2(value)

  }
  const handleChange5 = (value) => {

    setSire(value)

    setState({ ...state1, sireName: value })
  }

  useEffect(() => {
    const SearchTitle = Value2
    dispatch(fetchgender({ SearchTitle }));

  }, [Value2, dispatch])


  const handleSearch2 = (value) => {

    setValue3(value)

  }
  const handleSearch6 = (value) => {

    setValue7(value)

  }
  const handleSearch5 = (value) => {
    setValue6(value)
  }

  useEffect(() => {

    const Value4 = Value6
    dispatch(fetchTrainerList({ Value4 }));
  }, [dispatch, Value6]);
  useEffect(() => {
    const Value3 = Value7
    dispatch(fetchBreederList({ Value3 }));
  }, [dispatch, Value7]);



  useEffect(() => {

    const Value2 = Value3
    dispatch(fetchColorDropdown({ Value2 }))

  }, [Value3, dispatch])




  //---------------------------fetch---------------------------//
  const FetchNew = () => {
    dispatch(fetchOwnerList());
    dispatch(fetchColorDropdown());
    dispatch(fetchbreeder());
    dispatch(fetchnationality());
    dispatch(fetchgenderList());
    dispatch(fetchHorseKind());
    dispatch(fetchNationalityList());
    dispatch(fetchHorseKind());
    dispatch(fetchBreederList({ Value3 }));
  };
  //---------------------------code verification---------------------------//
  const VerifyCode = async (event) => {
    event.preventDefault();
    try {
      const formData = new FormData();
      formData.append("shortCode", state1.shortCode);
      const response = await axios.post(
        `${window.env.API_URL}VerifyShortCode`,
        formData
      );

      const resdata =
        response.data.data === null
          ? "Yes You can use it"
          : `Already exit in ${response.data.data.NameEn}`;

      swal({
        title: "!",
        text: resdata,
        icon: "success",
        button: "OK",
      });
    } catch (error) {
      const err = error.response.data.message;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
    }
  };
  const [form] = Form.useForm();

  const handleChange = async (value) => {

    await setKindHorse(value)
    setState({ ...state1, horseKindName: value })

  };
  const handleChange1 = async (value) => {

    await setNationalityID(value)
    setState({ ...state1, nationalityName: value })

  };
  const handleChange2 = async (value) => {

    await setColor(value)
    setState({ ...state1, colorName: value })

  };
  const handleSearch3 = (value) => {

    setValue4(value)

  }
  const KindHorseid = KindHorse === '' ? horseid.KindHorseData._id : KindHorse;
  const handleChange6 = (value) => {

    setGSire(value)
    setState({ ...state1, GSireName: value })


  }
  const handleSearch4 = (value) => {
    setValue5(value)


  }
  const handleChange7 = (value) => {
    setActiveOwner(value)
    setState({ ...state1, ActiveOwners: value })

  }
  useEffect(() => {
    const Value2 = Value5
    dispatch(fetchOwnerList({ Value2 }));
  }, [dispatch, Value5]);


  useEffect(() => {

    const Value1 = Value4
    dispatch(fetchHorseKindData({ Value1, KindHorseid }));
  }, [dispatch, KindHorse, KindHorseid, Value4])
  // useEffect(() => {
  //   dispatch(fetchOwnerList({ Value2 }));
  // }, [dispatch, Value2]);
  useEffect(() => {
    dispatch(fetchTrainerList({ Value4 }));
  }, [dispatch, Value4]);
  useEffect(() => {
    dispatch(fetchColorDropdown());
    dispatch(fetchBreederList({ Value3 }));
    dispatch(fetchNationalityList());
    dispatch(fetchgenderList());
    dispatch(fetchHorseKind({ SearchTitle, SearchCode, currentPage }));
  }, [dispatch]);
  //---------------------------options for dropdowns---------------------------//








  const handleChange8 = (value) => {

    setBreeder(value)
    setState({ ...state1, breederName: value })
  }



  const today = new Date();

  const [state1, setState] = useState({
    NameEn: "",
    NameAr: "",
    PurchasePrice: "",
    STARS: "",
    RemarksEn: "",
    RemarksAr: "",
    ActiveOwner: "",
    ActiveTrainer: "",
    Breeder: "",
    HorseHorseImage: "",
    Height: "",
    ColorID: "",
    NationalityID: "",
    KindHorse: "",
    CreationId: "",
    Rds: "",
    nationalityName: "",
    colorName: "",
    foal: '',
    genderName: '',
    HorseStatus: "",
    shortCode: "",
    Sex: ""
  });
  const [HorseImage, setHorseImage] = useState();
  const [preview, setPreview] = useState();

  const fileSelected = (event) => {
    const HorseImage = event.target.files[0];
    setHorseImage(HorseImage, HorseImage);
  };
  useEffect(() => {
    if (HorseImage === undefined) {
      setPreview(horseid.HorseImage);
      return;
    }
    const objectUrl = URL.createObjectURL(HorseImage);
    setPreview(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [HorseImage, horseid.HorseImage]);
  const handlePreview = () => {
    setHorseImage();
    setPreview();
    setisLoading1(true)
    document.getElementById("file").value = "";
  };
  //---------------------------get Data from state---------------------------//


  useEffect(() => {
    if (horseid) {
      setState({
        NameEn: horseid.NameEn,
        NameAr: horseid.NameAr,
        DOB: horseid.DOB,
        Breeder: horseid.BreederData ? horseid.BreederData._id : null,
        PurchasePrice: horseid.PurchasePrice,
        RemarksEn: horseid.RemarksEn,
        RemarksAr: horseid.RemarksAr,
        HorseImage: horseid.HorseImage,
        isGelded: horseid.isGelded,
        ActiveTrainer: horseid.ActiveTrainerData ? horseid.ActiveTrainerData._id : null,
        ActiveOwner: horseid.ActiveOwnerData ? horseid.ActiveOwnerData._id : null,
        Sex: horseid.SexModelData ? horseid.SexModelData._id : null,
        ColorID: horseid.ColorIDData ? horseid.ColorIDData._id : null,
        NationalityID: horseid.NationalityData ? horseid.NationalityData._id : null,
        KindHorse: horseid.KindHorseData ? horseid.KindHorseData._id : null,
        CreationId: horseid.NationalityData ? horseid.NationalityData._id : null,
        HorseStatus: horseid.HorseStatus,
        Rds: horseid.Rds,
        shortCode: horseid.shortCode,
        Foal: horseid.Foal,
        Dam: horseid?.DamData,
        Sire: horseid?.SireData,
        GSire: horseid.GSireData,
        colorName: horseid.ColorIDData ? horseid.ColorIDData.NameEn : null,
        nationalityName: horseid.NationalityData ? horseid.NationalityData.NameEn : null,
        genderName: horseid.SexModelData ? horseid.SexModelData.NameEn : null,
        horseKindName: horseid.KindHorseData ? horseid.KindHorseData.NameEn : null,
        horseCode: horseid.shortCode,
        damName: horseid?.DamData?.NameEn,
        sireName: horseid?.SireData?.NameEn,
        GSireName: horseid?.GSireData?.NameEn,
        ActiveOwners: horseid?.ActiveOwnerData?.NameEn,
        breederName: horseid?.BreederData?.NameEn,
        trainerName: horseid?.ActiveTrainerData?.NameEn,
        rds: horseid?.Rds,
        horseStatus: horseid?.HorseStatus,



      });
    } else {
    }
  }, [horseid]);
  console.log(horseid,"horseid")


  //---------------------------submit---------------------------//
  const submit = async () => {


    try {
      const formData = new FormData();
      formData.append("Horseimage", HorseImage);
      formData.append("NameEn", state1.NameEn);
      formData.append("DOB", DOB === '' ? state1.DOB : DOB);
      formData.append("NameAr", state1.NameAr + " ");
      formData.append("PurchasePrice", state1.PurchasePrice);
      formData.append("RemarksEn", state1.RemarksEn);
      formData.append("RemarksAr", state1.RemarksAr);
      formData.append("shortCode", state1.shortCode);
      formData.append(
        "Sex",
        Sex === undefined ? state1.Sex : Sex
      );

      if (isLoading1 === true && HorseImage === undefined) {
        formData.append("deleteexisitingimage", isLoading1)
      }


      formData.append(
        "isGelded",
        isGelded === undefined ? 'false' : isGelded
      );
      formData.append("Rds", Rds === undefined ? 'false' : Rds);
      formData.append(
        "ActiveTrainer",
        ActiveTrainer === undefined ? state1.ActiveTrainer : ActiveTrainer
      );

      if (Dam != "") {
        formData.append("Dam", Dam === undefined ? state1.Dam : Dam);
      }
      if (Sire != "") {
        formData.append("Sire", Sire === undefined ? state1.Sire : Sire);
      }
      if (GSire != "") {
        formData.append("GSire", GSire === undefined ? state1.GSire : GSire);
      }
      formData.append(
        "Breeder",
        Breeder === undefined ? state1.Breeder : Breeder
      );
      if (NationalityID != "") {
        formData.append(
          "NationalityID",
          NationalityID === undefined ? state1.NationalityID : NationalityID
        );
      }

      formData.append(
        "ActiveOwner",
        ActiveOwner === undefined ? state1.ActiveOwner : ActiveOwner
      );
      formData.append(
        "KindHorse",
        KindHorse === undefined ? state1.KindHorse : KindHorse
      );
      formData.append(
        "HorseStatus",
        HorseStatus === undefined ? state1.HorseStatus : HorseStatus
      );
      formData.append(
        "ColorID",
        ColorID === undefined ? state1.ColorID : ColorID
      );
      // formData.append(
      //   "CreationId",
      //   NationalityID.id === undefined ? state1.NationalityID : NationalityID.id
      // );
      if (NationalityID.id !== undefined) {
        formData.append(
          "CreationId",
          NationalityID === undefined ? state1.NationalityID : NationalityID
        );
      }
      formData.append(
        "Foal",
        Foal.value === undefined ? state1.Foal : Foal
      );
      setLoad(true)

      await axios.put(
        `${window.env.API_URL}/updatehorse/${horseid._id}`,
        formData
      );
      setLoad(false)
      console.log(load, "heello")
      history("/horse");
      swal({
        title: "Success!",
        text: "Data has been Updated successfully ",
        icon: "success",
        button: "OK",
      });
    } catch (error) {
      const err = error.response.data.message;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
      setLoad(false)
    }
  };

  console.log(Dam,"Dam Dam")

  const handleChangeg = (value) => {
    setisGelded(value)
    setState({ ...state1, Gelded: value })
  }

  const handleChanger = (value) => {
    setRds(value)
    setState({ ...state1, Rds: value })

  }
  const handleChangeStatus = (value) => {
    setHorseStatus(value)
    setState({ ...state1, horseStatus: value })

  }

  form.setFieldsValue({
    NameEn: state1.NameEn,
    NameAr: state1.NameAr,
    horseKindName: state1.horseKindName,
    ShortEn: state1.ShortNameEn,
    ShortAr: state1.ShortNameAr,
    Description: state1.DetailEn,
    DescriptionAr: state1.DetailAr,
    RemarksEn: state1.RemarksEn,
    RemarksAr: state1.RemarksAr,
    nationalityName: state1.nationalityName,
    Rating: state1.Rating,
    colorName: state1.colorName,
    foal: state1.Foal,
    horseCode: state1.shortCode,
    genderName: state1.genderName,
    damName: state1.damName,
    sireName: state1.sireName,
    GSireName: state1.GSireName,
    ActiveOwners: state1.ActiveOwners,
    breederName: state1.breederName,
    trainerName: state1.trainerName,
    PurchasePrice: state1.PurchasePrice,
    Gelded: state1.Gelded === 1 ? "true" : "false",
    rds: state1.rds === 1 ? "true" : "false",
    horseStatus: state1.horseStatus === 1 ? "true" : "false",


  });


  return (
    <>
      <div className="page">
        <div className="rightsidedata">
          <div
            style={{
              marginTop: "30px",
            }}
          >
            <div className="Headers">Edit Horse</div>
            {load ? <Loader /> : <div className="form">
              <Form onFinish={submit} form={form}>
                <div className="row mainrow">


                  <div className="col-sm">
                    <Form.Item
                      defaultValue={KindHorse}
                      name="horseKindName"
                      label="Horse Kind"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: 'Please select  Horse Kind!',
                        },
                      ]}
                    >
                      <Select

                        name="horseKindName" filterOption={false}
                        onChange={handleChange}


                        showSearch placeholder="Horse Kind" >

                        {
                          HorseKind?.map((item) => (


                            <Option value={item._id}>{item.NameEn} {"(" + item.NameAr + ")"}</Option>

                          ))
                        }
                      </Select>
                    </Form.Item>







                    <span className="spanForm selectSpacer">
                      <OverlayTrigger
                        overlay={<Tooltip id={`tooltip-top`}>Add more</Tooltip>}
                      >
                        <span className="addmore" onClick={handleShowHorseKind}>
                          +
                        </span>
                      </OverlayTrigger>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id={`tooltip-top`}>Fetch New</Tooltip>
                        }
                      >
                        <span className="addmore" onClick={FetchNew}>
                          <AiOutlineReload />
                        </span>
                      </OverlayTrigger>{" "}
                    </span>
                  </div>
                </div>
                <div className="row mainrow">
                  <div className="col-sm">






                    <Form.Item
                      name="horseCode"
                      label="Horse Code"
                      rules={[
                        {
                          required: true,
                          message: 'Horse Code  is required',
                        },
                      ]}
                    >
                      <Input onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }} placeholder="Horse Code" onChange={(e) =>
                        setState({ ...state1, shortCode: e.target.value })
                      }
                        value={state1.shortCode} name="horseCode" type="number" />
                    </Form.Item>











                  </div>
                  <div className="col-sm">
                    <p onClick={VerifyCode} className="verifybtn">
                      Verify Code
                    </p>
                  </div>
                </div>
                <div className="row mainrow">
                  <div className="col-sm">
                    <Form.Item
                      name="NameEn"
                      label="Name"
                      rules={[
                        {
                          required: true,
                          message: 'Name (English) is required',
                        },
                      ]}
                    >
                      <Input placeholder="Name" onChange={(e) =>
                        setState({ ...state1, NameEn: e.target.value })
                      }
                        value={state1.NameEn} name="NameEn" />
                    </Form.Item>


                    <span className="spanForm spacer"> |</span>
                  </div>

                  <div className="col-sm">

                    <Form.Item
                      name="NameAr"
                      label="اسم"
                      rules={[
                        {
                          required: true,
                          message: 'Name (Arabic) is required',
                        },
                      ]}
                      style={{ direction: 'rtl' }}
                    >
                      <Input placeholder="اسم" onChange={(e) =>
                        setState({ ...state1, NameAr: e.target.value })
                      }
                        name="Name"
                        value={state1.NameAr} />
                    </Form.Item>
                  </div>
                </div>
                <div className="row mainrow">
                  <p className="selectLabel">Date Of Birth </p>
                  <div className="col-sm">
                    <DatePicker
                      onChange={setDOB}
                      value={DOB}
                      dayPlaceholder=""
                      maxDate={today}
                      monthPlaceholder={horseid.DOB}
                      yearPlaceholder=""
                      className={DOB === "" ? "editDate" : ""}
                    />
                    <span className="spanForm"> |</span>
                  </div>
                  <div
                    className="col-sm"
                    style={{
                      display: "flex",
                      marginTop: "20px",
                    }}
                  >
                    <p>Horse Age :</p>
                    {DOB === "" ? (
                      <Moment durationFromNow>{horseid.DOB}</Moment>
                    ) : (
                      <Moment durationFromNow>{DOB}</Moment>
                    )}
                  </div>
                </div>
                <div className="row mainrow">

                  <div className="col-sm">


                    <Form.Item

                      name="foal"
                      label="Foal"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: 'Please select your Foal!',
                        },
                      ]}
                    >
                      <Select
                        name="foal"
                        value={Foals}
                        filterOption={false}
                        onChange={setFoal}

                        placeholder="Foal" >

                        {
                          Foals?.map((item) => (


                            <Option value={item.value} name="foal">{item.label} </Option>

                          ))
                        }
                      </Select>
                    </Form.Item>


                  </div>
                </div>
                <div className="row mainrow">

                  <div className="col-sm">



                    <Form.Item

                      name="nationalityName"
                      label="Nationality"
                      hasFeedback
                     
                    >
                      <Select
                        value={NationalityID}
                        filterOption={false}
                        defaultValue={state.nationalityName}
                        onChange={handleChange1}
                        onSearch={handleSearch}
                        showSearch placeholder="Please select a Nationality" >

                        {
                          NationalityList?.map((item) => (


                            <Option value={item._id}>{item.NameEn} ({item.NameAr})</Option>

                          ))
                        }
                      </Select>
                    </Form.Item>

                    <span className="spanForm  selectSpacer">
                      <OverlayTrigger
                        overlay={<Tooltip id={`tooltip-top`}>Add more</Tooltip>}
                      >
                        <span
                          className="addmore"
                          onClick={handleShowActivenationality}
                        >
                          +
                        </span>
                      </OverlayTrigger>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id={`tooltip-top`}>Fetch New</Tooltip>
                        }
                      >
                        <span className="addmore" onClick={FetchNew}>
                          <AiOutlineReload />
                        </span>
                      </OverlayTrigger>
                    </span>
                  </div>
                </div>
                <div className="row mainrow">


                  <div className="col-sm">



                    <Form.Item

                      name="colorName"
                      label="Color"

                      rules={[
                        {
                          required: true,
                          message: 'Please select your Color!',
                        },
                      ]}
                    >
                      <Select
                        name="colorName"
                        value={ColorID}
                        filterOption={false}
                        onChange={handleChange2}
                        onSearch={handleSearch2}
                        showSearch placeholder="Please select a Color" >

                        {
                          colordropdown?.map((item) => (


                            <Option value={item._id}>{item.NameEn} {"(" + item.NameAr + ")"}</Option>

                          ))
                        }
                      </Select>

                    </Form.Item>








                    <span className="spanForm selectSpacers   selectSpacer">
                      <OverlayTrigger
                        overlay={<Tooltip id={`tooltip-top`}>Add more</Tooltip>}
                      >
                        <span className="addmore" onClick={handleShowColor}>
                          +
                        </span>
                      </OverlayTrigger>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id={`tooltip-top`}>Fetch New</Tooltip>
                        }
                      >
                        <span className="addmore" onClick={FetchNew}>
                          <AiOutlineReload />
                        </span>
                      </OverlayTrigger>{" "}
                    </span>
                  </div>
                </div>
                <div className="row mainrow">

                  <div className="col-sm">

                    <Form.Item

                      name="genderName"
                      label="Gender"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: 'Please select your Gender!',
                        },
                      ]}
                    >
                      <Select
                        name="genderName"
                        value={Sex}
                        filterOption={false}
                        onChange={handleChange3}
                        onSearch={handleSearch1}
                        showSearch placeholder="Please select a Gender" >

                        {
                          gender?.map((item) => (


                            <Option value={item._id}>{item.NameEn} ({item.NameAr})</Option>

                          ))
                        }
                      </Select>

                    </Form.Item>











                    <span className="spanForm selectSpacers selectSpacer">
                      <OverlayTrigger
                        overlay={<Tooltip id={`tooltip-top`}>Add more</Tooltip>}
                      >
                        <span className="addmore" onClick={handleShowGender}>
                          +
                        </span>
                      </OverlayTrigger>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id={`tooltip-top`}>Fetch New</Tooltip>
                        }
                      >
                        <span className="addmore" onClick={FetchNew}>
                          <AiOutlineReload />
                        </span>
                      </OverlayTrigger>{" "}
                    </span>
                  </div>
                </div>
                <div className="row mainrow">
                  <div className="col-sm">



                    <Form.Item

                      name="damName"
                      label="Dam"
                      hasFeedback

                    >
                      <Select
                        name="damName"
                        label="Dam"
                        value={Dam}
                        filterOption={false}
                        onChange={handleChange4}
                        onSearch={handleSearch3}
                        showSearch placeholder="Please select a dam" >

                        {
                          HorseKindData?.map((item) => (


                            <Option value={item._id}>{item.NameEn} ({item.NameAr}) {item.NationalityData ? item.NationalityData.NameEn : <></>}</Option>

                          ))
                        }
                      </Select>

                    </Form.Item>









                  </div>
                </div>
                <div className="row mainrow">
                  <div className="col-sm">
                    <Form.Item
                      name="sireName"
                      label="Sire"
                      hasFeedback
                    >
                      <Select
                        name="Sire Name"
                        value={Sire}
                        filterOption={false}
                        onChange={handleChange5}
                        onSearch={handleSearch3}
                        showSearch placeholder="Please select a Sire" >

                        {
                          HorseKindData?.map((item) => (


                            <Option value={item._id}>{item.NameEn} ({item.NameAr}) {item.NationalityData ? item.NationalityData.NameEn : <></>}</Option>

                          ))
                        }
                      </Select>

                    </Form.Item>



                  </div>
                </div>
                <div className="row mainrow">
                  <div className="col-sm">





                    <Form.Item

                      name="GSireName"
                      label="GSire"
                      hasFeedback

                    >
                      <Select
                        name="GSireName"
                        value={GSire}
                        filterOption={false}
                        onChange={handleChange6}
                        onSearch={handleSearch3}
                        showSearch placeholder="Please select a GSire" >

                        {
                          HorseKindData?.map((item) => (


                            <Option value={item._id}>{item.NameEn} ({item.NameAr}) {item.NationalityData ? item.NationalityData.NameEn : <></>}</Option>

                          ))
                        }
                      </Select>

                    </Form.Item>




                  </div>
                </div>

                <div className="row mainrow">

                  <div className="col-sm">






                    <Form.Item

                      name="ActiveOwners"
                      label="Active Owner"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: 'Please select your Active Owner!',
                        },
                      ]}
                    >

                      <Select
                        name="ActiveOwners"
                        value={ActiveOwner}
                        filterOption={false}
                        onChange={handleChange7}
                        onSearch={handleSearch4}
                        showSearch placeholder="Please select a Active Owner" >

                        {
                          OwnerList?.map((item) => (


                            <Option value={item._id}>{item.NameEn} ({item.NameAr}) </Option>

                          ))
                        }
                      </Select>
                    </Form.Item>










                    <span className="spanForm selectSpacer selectSpacers">
                      <OverlayTrigger
                        overlay={<Tooltip id={`tooltip-top`}>Add more</Tooltip>}
                      >
                        <span
                          className="addmore"
                          onClick={handleShowActiveOwner}
                        >
                          +
                        </span>
                      </OverlayTrigger>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id={`tooltip-top`}>Fetch New</Tooltip>
                        }
                      >
                        <span className="addmore" onClick={FetchNew}>
                          <AiOutlineReload />
                        </span>
                      </OverlayTrigger>{" "}
                    </span>
                  </div>
                </div>
                <div className="row mainrow">

                  <div className="col-sm">


                    <Form.Item

                      name="breederName"
                      label=" Breeder"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: 'Please select your  Breeder!',
                        },
                      ]}
                    >

                      <Select
                        name="breederName"
                        value={Breeder}
                        filterOption={false}
                        onChange={handleChange8}
                        onSearch={handleSearch6}
                        showSearch placeholder="Please select a Breeder" >

                        {
                          BreederList?.map((item) => (


                            <Option value={item._id}>{item.NameEn} ({item.NameAr}) </Option>

                          ))
                        }
                      </Select>
                    </Form.Item>







                    <span className="spanForm selectSpacer">
                      <OverlayTrigger
                        overlay={<Tooltip id={`tooltip-top`}>Add more</Tooltip>}
                      >
                        <span className="addmore" onClick={handleShowBreeder}>
                          +
                        </span>
                      </OverlayTrigger>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id={`tooltip-top`}>Fetch New</Tooltip>
                        }
                      >
                        <span className="addmore" onClick={FetchNew}>
                          <AiOutlineReload />
                        </span>
                      </OverlayTrigger>{" "}
                    </span>
                  </div>
                </div>
                <div className="row mainrow">

                  <div className="col-sm">




                    <Form.Item

                      name="trainerName"
                      label="Active trainer"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: 'Please select your Active trainer!',
                        },
                      ]}
                    >

                      <Select
                        name="trainerName"
                        value={ActiveTrainer}
                        filterOption={false}
                        onChange={handleChange9}
                        onSearch={handleSearch5}
                        showSearch placeholder="Please select a Active trainer" >

                        {
                          TrainerList?.map((item) => (


                            <Option value={item._id}>{item.NameEn} ({item.NameAr}) </Option>

                          ))
                        }
                      </Select>
                    </Form.Item>


                    <span className="spanForm selectSpacer">
                      <OverlayTrigger
                        overlay={<Tooltip id={`tooltip-top`}>Add more</Tooltip>}
                      >
                        <span
                          className="addmore"
                          onClick={handleShowActiveTrainer}
                        >
                          +
                        </span>
                      </OverlayTrigger>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id={`tooltip-top`}>Fetch New</Tooltip>
                        }
                      >
                        <span className="addmore" onClick={FetchNew}>
                          <AiOutlineReload />
                        </span>
                      </OverlayTrigger>{" "}
                    </span>
                  </div>
                </div>
                <div className="row mainrow">
                  <div className="col-sm">





                    <Form.Item
                      name="PurchasePrice"
                      label="Purchase Price"
                      rules={[
                        {
                          required: true,
                          message: 'Purchase Price is required',
                        },
                      ]}
                    >
                      <Input placeholder="Purchase Price" onChange={(e) =>
                        setState({ ...state1, PurchasePrice: e.target.value })
                      }

                        value={state1.PurchasePrice} name="PurchasePrice" type="number" onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}

                        maxLength='7'

                        min='0'
                      />
                    </Form.Item>






                  </div>
                </div>

                <div className="row mainrow">


                  <div className="col-sm">
                    {/* <Select
                      placeholder={
                        <div>
                          {horseid.isGelded === false ? "false" : "true"}
                        </div>
                      }
                      defaultValue={isGelded}
                      onChange={setisGelded}
                      options={Gelted}
                      isClearable={true}
                      isSearchable={true}
                    /> */}





                    <Form.Item

                      name="Gelded"
                      label=" Gelded"

                      rules={[
                        {
                          required: true,
                          message: 'Please select your Gelded!',
                        },
                      ]}
                    >

                      <Select
                        name="Gelded"
                        value={isGelded}
                        filterOption={false}
                        onChange={handleChangeg}
                        showSearch placeholder="Please select a Gelded" >

                        {
                          Gelteds?.map((item) => (


                            <Option value={item.id}>{item.label}  </Option>

                          ))
                        }
                      </Select>
                    </Form.Item>

                  </div>
                </div>
                <div className="row mainrow">

                  <div className="col-sm">













                    <Form.Item

                      name="rds"
                      label="Rds"

                      rules={[
                        {
                          required: true,
                          message: 'Please select your RDS!',
                        },
                      ]}
                    >

                      <Select
                        name="rds"
                        value={Rds}
                        filterOption={false}
                        onChange={handleChanger}
                        showSearch placeholder="Please select a Rds" >

                        {
                          Gelteds?.map((item) => (


                            <Option value={item.id}>{item.label}  </Option>

                          ))
                        }
                      </Select>
                    </Form.Item>
                  </div>

                  {/* <div className="col-sm">
                    <Select
                      placeholder={
                        <div>{horseid.Rds === false ? "false" : "true"}</div>
                      }
                      defaultValue={Rds}
                      onChange={setRds}
                      options={Gelted}
                      isClearable={true}
                      isSearchable={true}
                    />
                  </div> */}
                </div>
                <div className="row mainrow">





                  <div className="col-sm">


                    <Form.Item

                      name="horseStatus"
                      label=" Horse Status"

                      rules={[
                        {
                          required: true,
                          message: 'Please select your  Horse Status!',
                        },
                      ]}
                    >

                      <Select
                        name="horseStatus"

                        filterOption={false}
                        defaultValue={HorseStatus}
                        onChange={handleChangeStatus}
                        showSearch placeholder="Please select a Horse Status" >

                        {
                          Gelteds?.map((item) => (


                            <Option value={item.id}>{item.label}  </Option>

                          ))
                        }
                      </Select>
                    </Form.Item>
                  </div>




                  {/* <div className="col-sm">
                    <Select
                      placeholder={
                        <div>
                          {horseid.HorseStatus === false ? "false" : "true"}
                        </div>
                      }
                      defaultValue={HorseStatus}
                      onChange={setHorseStatus}
                      options={HorseStatusAll}
                      isClearable={true}
                      isSearchable={true}
                    />
                  </div> */}
                </div>
                <div className="row mainrow">
                  <div className="col-sm">

                    <Form.Item
                      name="RemarksEn"
                      label="Remarks"

                    >
                      <Input placeholder="Remarks" onChange={(e) =>
                        setState({ ...state1, RemarksEn: e.target.value })
                      }
                        value={state1.RemarksEn} name="RemarksEn" />
                    </Form.Item>












                    <span className="spanForm spacer"> |</span>
                  </div>

                  <div className="col-sm">








                    <Form.Item
                      name="RemarksAr"
                      label="ملاحظات"

                      style={{ direction: 'rtl' }}
                    >
                      <Input placeholder="ملاحظات" onChange={(e) =>
                        setState({ ...state1, RemarksAr: e.target.value })
                      }
                        name="RemarksAr"
                        value={state1.RemarksAr} />
                    </Form.Item>




                  </div>
                </div>



                <div className="ButtonSection">
                  <div className={HorseImage === undefined ? "inputFile" : ""}>
                    <label className="Multipleownerlabel">
                      Select Horse image
                    </label>
                    <input
                      type="file"
                      onChange={fileSelected}
                      className="formInput"
                      id="file"
                    />
                    {preview && (
                      <>
                        <ImCross
                          onClick={handlePreview}
                          className="crossIcon2"
                        />
                        <img src={preview} className="PreviewImage" alt="" />
                      </>
                    )}
                  </div><div>
                    <button type="submit" className="SubmitButton" disabled={load}>
                      Update
                    </button>
                  </div>    </div>
              </Form>
            </div>}
          </div>
        </div>
      </div>
      <Modal
        show={showBreeder}
        onHide={handleCloseBreeder}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2> Breeder</h2>
        </Modal.Header>
        <Modal.Body>
          <BreederPopup setShowBreeder={setShowBreeder} />
        </Modal.Body>
      </Modal>
      <Modal
        show={showColor}
        onHide={handleCloseColor}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2>Color</h2>
        </Modal.Header>
        <Modal.Body>
          <ColorPopup setShowColor={setShowColor} />
        </Modal.Body>
      </Modal>
      <Modal
        show={showActiveOwner}
        onHide={handleCloseActiveOwner}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2>Owner</h2>
        </Modal.Header>
        <Modal.Body>
          <OwnerPopup setShowActiveOwner={setShowActiveOwner} />
        </Modal.Body>
      </Modal>
      <Modal
        show={showActiveTrainer}
        onHide={handleCloseActiveTrainer}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2>Trainer</h2>
        </Modal.Header>
        <Modal.Body>
          <TrainerPopup setShowActiveTrainer={setShowActiveTrainer} />
        </Modal.Body>
      </Modal>
      <Modal
        show={showGender}
        onHide={handleCloseGender}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2>Gender</h2>
        </Modal.Header>
        <Modal.Body>
          <GenderPopup setShowGender={setShowGender} />
        </Modal.Body>
      </Modal>
      <Modal
        show={showActivenationality}
        onHide={handleCloseActivenationality}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2>Nationality</h2>
        </Modal.Header>
        <Modal.Body>
          <NationalityPopup setShowNationality={setShowActivenationality} />
        </Modal.Body>
      </Modal>
      <Modal
        show={showHorseKind}
        onHide={handleCloseHorseKind}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2>Horse Kind</h2>
        </Modal.Header>
        <Modal.Body>
          <HorseKindPopup setshowhorseKind={setShowHorseKind} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditHorse;