import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});

const getEditSingleRaceSlice = createSlice({
    name: 'EditSingleRace',
    initialState: {
        data: [],
        status: STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchEditSingleRace.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(fetchEditSingleRace.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.IDLE
            })
            .addCase(fetchEditSingleRace.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
    }
});

export const { setEditSingleRace, setStatus } = getEditSingleRaceSlice.actions;
export default getEditSingleRaceSlice.reducer;

export const fetchEditSingleRace = createAsyncThunk('/EditSingleRaceget/fetch', async ({id1}) => {
    const res = await axios.get(`${window.env.API_URL}/GetSingleRaceForEdit/${id1}`);
    const EditSingleRaceData = res.data;
    return EditSingleRaceData.data;
})