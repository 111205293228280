import React, { useState, Fragment, useEffect } from "react";
import "../../Components/CSS/forms.css";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { fetchNationalityList } from "../../redux/getDropDownReducer/getNationalityList";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Form, Input, DatePicker, Select } from 'antd';
import { useNavigate, useLocation } from "react-router-dom";
import swal from "sweetalert";
import { AiOutlineReload } from "react-icons/ai";
import { Modal } from "react-bootstrap";
import NationalityPopup from "./Nationality";
import { ImCross } from 'react-icons/im';
import Loader from "../../Components/Common/Loader";
import { fetchGetTrainerMaxShortCode } from "../../redux/getShortCode/gettrainershortcode";

const TrainerForm = () => {
  const { Option } = Select;

  const [isLoading, setisLoading] = useState(false);

  const [ErrorDateofBirth, setErrorDateofBirth] = useState("");
  const [ErrorLicenseDate, setErrorLicenseDate] = useState("");


  const [ErrorNationality, setErrorNationality] = useState("");


  const history = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const { data: NationalityList } = useSelector((state) => state.NationalityList);

  const [NameEn, setNameEn] = useState("");
  const [DetailEn, setDetailEn] = useState("");
  const [DetailAr, setDetailAr] = useState("");
  const [RemarksEn, setRemarksEn] = useState("");
  const [RemarksAr, setRemarksAr] = useState("");
  const [NameAr, setNameAr] = useState("");
  const [DOB, setDOB] = useState("");
  const [TitleAr, setTitleAr] = useState("");
  const [TitleEn, setTitleEn] = useState("");
  const [TrainerLicenseDate, setTrainerLicenseDate] = useState("");
  const [ShortNameEn, setShortNameEn] = useState("");
  const [ShortNameAr, setShortNameAr] = useState("");

  const [preview, setPreview] = useState();
  const [image, setImage] = useState();
  const [NationalityId, setNationalityId] = useState("");

  const [showActivenationality, setShowNationality] = useState(false);
  //----------------------------show Popup -------------------------//
  const handleCloseActivenationality = () => setShowNationality(false);
  const { data: GetTrainerMaxShortCode } = useSelector((state) => state.GetTrainerMaxShortCode)


  const handleShowActivenationality = async () => {
    await setShowNationality(true);
  };

  const [state1, setState] = useState({
    shortCode: '',
  });
  //----------------------------shortcode -------------------------//
  useEffect(() => {
    if (GetTrainerMaxShortCode) {
      setState({
        shortCode: GetTrainerMaxShortCode.length === 0 ? 10 : GetTrainerMaxShortCode[0].maxshortCode + 1,
      });
    } else {
    }
  }, [GetTrainerMaxShortCode]);


  // useEffect(() => {
  //   dispatch(fetchGetTrainerMaxShortCode())
  // },[dispatch])
  //----------------------------submit -------------------------//
  const submit = async () => {
    if (NationalityId === '') {

      setErrorNationality("Nationality is required")



    } else {
      setisLoading(true)
      try {
        const formData = new FormData();
        formData.append("image", image);
        formData.append("NameEn", NameEn);
        formData.append("DetailEn", DetailEn);
        formData.append("DetailAr", DetailAr);
        formData.append("RemarksEn", RemarksEn);
        formData.append("RemarksAr", RemarksAr);
        formData.append("NameAr", NameAr);
        formData.append("DOB", DOB);
        formData.append("TitleAr", TitleAr);
        formData.append("NationalityID", NationalityId);
        formData.append("TitleEn", TitleEn);
        formData.append("TrainerLicenseDate", TrainerLicenseDate);
        formData.append("ShortNameEn", ShortNameEn);
        formData.append("ShortNameAr", ShortNameAr);
        formData.append("shortCode", state1?.shortCode);

        await axios.post(
          `${window.env.API_URL}/uploadtrainer`,
          formData
        );

        swal({
          title: "Success!",
          text: "Data has been added successfully",
          icon: "success",
          button: "OK",
        });
        if (pathname === "/trainerform") {
          history("/trainer");
        }
        setisLoading(false)
      } catch (error) {
        const err = error.response.data.message[0];
        const err1 = error.response.data.message[1];
        const err2 = error.response.data.message[2];


        swal({
          title: "Error!",
          text: err, err1, err2,
          icon: "error",
          button: "OK",
        });
        setisLoading(false)
      }

    }
  };

  const handleSearch = async (value) => {
    try {
      dispatch(fetchNationalityList({ value }))

    } catch (error) {

    }
  };

  var today = new Date();
  useEffect(() => {
    dispatch(fetchNationalityList());
    if (!image) {
      setPreview(undefined);
      return;
    }
    if (image.name.match(/\.(gif)$/)) {
      setImage("");
      setPreview(undefined);
      document.getElementById("file").value = "";
      swal({
        title: "Error!",
        text: "Invalid Format ",
        icon: "error",
        button: "OK",
      });
    }
    const objectUrl = URL.createObjectURL(image);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [dispatch, image]);
  const FetchNew = () => {
    dispatch(fetchNationalityList());
  }
  const onSelectFile = (e) => {
    setImage(e.target.files[0]);
  };
  //----------------------------option for dropdown -------------------------//


  //----------------------------remove image preview -------------------------//
  const handlePreview = () => {
    setImage()
    document.getElementById("file").value = ""
  };
  const handleDateChange = (date, dateString) => {
    setDOB(dateString);
  };

  const handleDateChange1 = (date, dateString) => {
    setTrainerLicenseDate(dateString);
  };








  return (
    <Fragment>
      <div className="page">
        <div className="rightsidedata">
          <div
            style={{
              marginTop: "30px",
            }}
          >
            <div className="Headers">Create Trainer</div>
            {isLoading ? <Loader /> : <div className="form">
              <Form onFinish={submit}>
                <div className="row mainrow">
                  <div className="col-sm">




                    <Form.Item
                      name="NameEn"
                      label="Name"
                      rules={[
                        {
                          required: true,
                          message: 'Name (English) is required',
                        },
                      ]}
                    >
                      <Input placeholder="Name" onChange={(e) => setNameEn(e.target.value)}
                        value={NameEn} name="NameEn" />
                    </Form.Item>








                    <span className="spanForm spacer"> |</span>

                  </div>

                  <div className="col-sm">
                    <Form.Item
                      name="NameAr"
                      label="اسم"
                      rules={[
                        {
                          required: true,
                          message: 'Name (Arabic) is required',
                        },
                      ]}
                      style={{ direction: 'rtl' }}
                    >
                      <Input placeholder="اسم" onChange={(e) => setNameAr(e.target.value)}
                        name="NameAr"
                        value={NameAr} />
                    </Form.Item>

                  </div>
                </div>

                <div className="row mainrow">
                  <div className="col-sm">
                    <Form.Item name="date-picker" label="Date of Birth " rules={[
                      {
                        required: true,
                        message: 'Date of Birth is required',
                      },
                    ]}>


                      <DatePicker value={DOB} onChange={handleDateChange}  format="DD-MM-YYYY" />

                    </Form.Item>



                  </div>


                </div>


                <div className="row mainrow">
                  <div className="col-sm">
                    <Form.Item name="date-picker1" label="Trainer License Date " rules={[
                      {
                        required: true,
                        message: 'Trainer License Date is required',
                      },
                    ]}>


                      <DatePicker value={TrainerLicenseDate} onChange={handleDateChange1}  format="DD-MM-YYYY" />

                    </Form.Item>



                  </div>


                </div>



                <div className="row mainrow">
                  <div className="col-sm">





                    <Form.Item
                      name="Title"
                      label="Title"
                      rules={[
                        {
                          required: true,
                          message: 'Title (English) is required',
                        },
                      ]}
                    >
                      <Input placeholder="Title" onChange={(e) => setTitleEn(e.target.value)}
                        value={TitleEn} name="Title" />
                    </Form.Item>








                    <span className="spanForm spacer" > |</span>
                    <span className={Error.status ? "success" : "error"}>
                      {Error.message}
                    </span>
                  </div>

                  <div className="col-sm">



                    <Form.Item
                      name="TitleAr"
                      label="عنوان"
                      rules={[
                        {
                          required: true,
                          message: 'Title (Arabic) is required',
                        },
                      ]}
                      style={{ direction: 'rtl' }}
                    >
                      <Input placeholder="عنوان" onChange={(e) => setTitleAr(e.target.value)}
                        name="TitleAr"
                        value={TitleAr} />
                    </Form.Item>









                  </div>
                </div>
                <div className="row mainrow">
                  <div className="col-sm">






                    <Form.Item
                      name="ShortName"
                      label="Short Name"
                      rules={[
                        {
                          required: true,
                          message: 'Short Name (English) is required',
                        },
                      ]}
                    >
                      <Input placeholder="Short Name" onChange={(e) => setShortNameEn(e.target.value)}
                        value={ShortNameEn} name="ShortName" />
                    </Form.Item>







                    <span className="spanForm spacer"> |</span>

                  </div>




                  <div className="col-sm">


                    <Form.Item
                      name="ShortNameAr"
                      label="اسم قصير"
                      rules={[
                        {
                          required: true,
                          message: 'Short Name (Arabic) is required',
                        },
                      ]}
                      style={{ direction: 'rtl' }}
                    >
                      <Input placeholder="اسم قصير" onChange={(e) => setShortNameAr(e.target.value)}
                        name="ShortNameAr"
                        value={ShortNameAr} />
                    </Form.Item>














                  </div>
                </div>
                <div className="row mainrow">
                  <div className="col-sm">




                    <Form.Item
                      name="Detail"
                      label="Detail"
                      rules={[
                        {
                          required: true,
                          message: 'Detail (English) is required',
                        },
                      ]}
                    >
                      <Input placeholder="Detail" onChange={(e) => setDetailEn(e.target.value)}
                        value={DetailEn} name="Detail" />
                    </Form.Item>












                    <span className="spanForm spacer"> |</span>

                  </div>

                  <div className="col-sm">


                    <Form.Item
                      name="DetailAr"
                      label="تفاصيل"
                      rules={[
                        {
                          required: true,
                          message: 'Detail (Arabic) is required',
                        },
                      ]}
                      style={{ direction: 'rtl' }}
                    >
                      <Input placeholder="تفاصيل" onChange={(e) => setDetailAr(e.target.value)}
                        value={DetailAr} name="DetailAr" />
                    </Form.Item>











                  </div>
                </div>

                <div className="row mainrow">
                  <div className="col-sm">



                    <Form.Item

                      name="select"
                      label="Nationality"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: 'Please select your Nationality!',
                        },
                      ]}
                    >


                      <Select
                        value={NationalityId}
                        filterOption={false}
                        onChange={setNationalityId}
                        onSearch={handleSearch}
                        showSearch placeholder="Please select a Nationality" >

                        {
                          NationalityList?.map((item) => (


                            <Option value={item._id}>{item.NameEn} ({item.NameAr})</Option>

                          ))
                        }
                      </Select>



                    </Form.Item>

                    <span className="spanForm selectSpacer">
                      <OverlayTrigger
                        overlay={<Tooltip id={`tooltip-top`}>Add more</Tooltip>}
                      >
                        <span
                          className="addmore"
                          onClick={handleShowActivenationality}
                        >
                          +
                        </span>
                      </OverlayTrigger>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id={`tooltip-top`}>Fetch New</Tooltip>
                        }
                      >
                        <span className="addmore" onClick={FetchNew}>
                          <AiOutlineReload />
                        </span>
                      </OverlayTrigger>{" "}

                    </span>

                    <span className="error">{ErrorNationality}</span>
                  </div>

                </div>
                <div className="row mainrow">
                  <div className="col-sm">






                    <Form.Item
                      name="Remarks"
                      label="Remarks"
                      rules={[
                        {
                          required: true,
                          message: 'Remarks (English) is required',
                        },
                      ]}
                    >
                      <Input placeholder="Remarks" onChange={(e) => setRemarksEn(e.target.value)}
                        value={RemarksEn} name="Remarks" />
                    </Form.Item>










                    <span className="spanForm spacer"> |</span>

                  </div>

                  <div className="col-sm">












                    <Form.Item
                      name="RemarksAr"
                      label="ملاحظات"
                      rules={[
                        {
                          required: true,
                          message: 'Remarks (Arabic) is required',
                        },
                      ]}
                      style={{ direction: 'rtl' }}
                    >
                      <Input placeholder="ملاحظات" onChange={(e) => setRemarksAr(e.target.value)}
                        name="RemarksAr"
                        value={RemarksAr} />
                    </Form.Item>



                  </div>
                </div>

                <div className="row mainrow">
                <div className="col-sm">
                  <Form.Item
                    name="shortCode"
                    label="Short Code"
                    rules={[
                      {
                        required: true,
                        message: "short Code is required"
                      },
                    ]}
                  >
                    <Input
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(e) =>
                        setState({ ...state1, shortCode: e.target.value })
                      }
                      value={state1.shortCode}
                      onPaste={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      placeholder="Short Code"
                      maxLength="5"
                    />
                  </Form.Item>


                </div>
              </div>
                <div className="ButtonSection">
                  <div>
                    <label className="Multipleownerlabel">
                      Select Trainer image
                    </label>
                    <input
                      type="file"
                      onChange={onSelectFile}
                      className="formInput"
                      id="file"
                    />
                    {image && (
                      <>
                        <ImCross onClick={handlePreview} className="crossIcon" />
                        <img src={preview} className="PreviewImage" alt="" />
                      </>
                    )}
                  </div>
                  <div>

                    <button type="submit" className="SubmitButton" disabled={isLoading}>
                    Save

                    </button>
                  </div>
                </div>
              </Form>
            </div>}
          </div>
        </div>
      </div>
      <Modal
        show={showActivenationality}
        onHide={handleCloseActivenationality}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2>Nationality</h2>
        </Modal.Header>
        <Modal.Body>
          <NationalityPopup setShowNationality={setShowNationality} />
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default TrainerForm;
