import React, { useState, useEffect } from "react";
import "../../Components/CSS/forms.css";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";

import { Form, Input } from 'antd';
import { ImCross } from "react-icons/im";
import swal from "sweetalert";
import Loader from "../../Components/Common/Loader";
const SponsorForm = ({ setShowSponsor }) => {
  //for error

  const [isLoading, setisLoading] = useState(false);

  const history = useNavigate();

  const { pathname } = useLocation();

  const [Url, setUrl] = useState();
  const [TitleEn, setTitleEn] = useState("");
  const [TitleAr, setTitleAr] = useState("");
  const [DescriptionAr, setDescriptionAr] = useState("");
  const [DescriptionEn, setDescriptionEn] = useState("");
  const [image, setImage] = useState();
  const [preview, setPreview] = useState();

  const submit = async () => {

    setisLoading(true);
    if (image == undefined) {
      swal({
        title: "Error!",
        text: "Image is Required",
        icon: "error",
        button: "OK",
      });
      setisLoading(false);

    }
    else {
      try {
        const formData = new FormData();
        formData.append("image", image);
        formData.append("TitleEn", TitleEn);
        formData.append("TitleAr", TitleAr);
        formData.append("DescriptionAr", DescriptionAr);
        formData.append("DescriptionEn", DescriptionEn);
        formData.append("Url", Url);

        await axios.post(
          `${window.env.API_URL}/uploadSponsor`,
          formData
        );

        swal({
          title: "Success!",
          text: "Data has been added successfully",
          icon: "success",
          button: "OK",
        });
        if (pathname === "/sponsorform") {
          history("/sponsor");
        }
        setShowSponsor(false)
        setisLoading(false);
      } catch (error) {
        const err = error.response.data.message;
        swal({
          title: "Error!",
          text: err,
          icon: "error",
          button: "OK",
        });
        setisLoading(false);
      }
    }
  };
  useEffect(() => {
    if (!image) {
      setPreview(undefined);
      return;
    }
    if (image.name.match(/\.(gif)$/)) {
      setImage("");
      setPreview(undefined);
      document.getElementById("file").value = "";
      swal({
        title: "Error!",
        text: "Invalid Format ",
        icon: "error",
        button: "OK",
      });
    }

    const objectUrl = URL.createObjectURL(image);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [image]);

  const onSelectFile = (e) => {
    setImage(e.target.files[0])(image, "image");
  };

  const handlePreview = () => {
    setImage();
    document.getElementById("file").value = "";
  };


  return (
    <>
      <div className="page">
        <div className="rightsidedata">
          <div
            style={{
              marginTop: "30px",
            }}
          >
            <div className="Headers">Add Sponsor</div>
            {isLoading ? (
              <Loader />
            ) : (
              <div className="form">
                <Form onFinish={submit}>
                  <div className="row mainrow">
                    <div className="col-sm">





                      <Form.Item
                        name="NameEn"
                        label="Title"
                        rules={[
                          {
                            required: true,
                            message: 'Title (English) is required',
                          },
                        ]}
                      >
                        <Input placeholder="Title" onChange={(e) => setTitleEn(e.target.value)}
                          value={TitleEn} name="NameEn" />
                      </Form.Item>








                      <span className="spanForm" spacer> |</span>
                      <span className={Error.status ? "success" : "error"}>
                        {Error.message}
                      </span>
                    </div>

                    <div className="col-sm">



                      <Form.Item
                        name="TitleAr"
                        label="عنوان"
                        rules={[
                          {
                            required: true,
                            message: 'Title (Arabic) is required',
                          },
                        ]}
                        style={{ direction: 'rtl' }}
                      >
                        <Input placeholder="عنوان" onChange={(e) => setTitleAr(e.target.value)}
                          name="TitleAr"
                          value={TitleAr} />
                      </Form.Item>









                    </div>
                  </div>

                  <div className="row mainrow">
                    <div className="col-sm">




                      <Form.Item
                        name="DescriptionEn"
                        label="Description"
                        rules={[
                          {
                            required: true,
                            message: 'Description (English) is required',
                          },
                        ]}
                      >
                        <Input placeholder="Description" onChange={(e) => setDescriptionEn(e.target.value)}
                          value={DescriptionEn} name="DescriptionEn" />
                      </Form.Item>









                      <span className="spanForm spacer"> |</span>

                    </div>

                    <div className="col-sm">



                      <Form.Item
                        name="DescriptionAr"
                        label="وصف"
                        rules={[
                          {
                            required: true,
                            message: 'Description (Arabic) is required',
                          },
                        ]}
                        style={{ direction: 'rtl' }}
                      >
                        <Input placeholder="وصف" onChange={(e) => setDescriptionAr(e.target.value)}
                          name="DescriptionAr"
                          value={DescriptionAr} />
                      </Form.Item>








                    </div>
                  </div>

                  <div className="row mainrow">
                    <div className="col-sm">





                      <Form.Item
                        name="Url"
                        label="Url"
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: 'Url is required',
                        //   },
                        // ]}
                      >
                        <Input placeholder="Url" onChange={(e) => setUrl(e.target.value)}
                      type="url"
                      name="Name"
                          value={Url} />
                      </Form.Item>







                    </div>
                  </div>

                  <div className="ButtonSection">
                    <div>
                      <label className="Multipleownerlabel">
                        Select Sponsor image
                      </label>
                      <input
                        type="file"
                        onChange={onSelectFile}
                        className="formInput"
                        id="file"
                      />
                      {image && (
                        <>
                          <ImCross
                            onClick={handlePreview}
                            className="crossIcon"
                          />
                          <img src={preview} className="PreviewImage" alt="" />
                        </>
                      )}
                    </div>
                    <div>
                      <button
                        type="submit"
                        className="SubmitButton"
                        disabled={isLoading}
                      >
                                            Save

                      </button></div>
                  </div>
                </Form>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SponsorForm;