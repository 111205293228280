import axios from "axios";
import { createAsyncThunk,createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
    IDLE : 'idle',
    ERROR:'error',
    LOADING: 'loading',
});

const getSingleEmailTemplateSlice = createSlice({
    name: 'SingleEmailTemplate',
    initialState: {
        data:[],
        status : STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
        .addCase(fetchSingleEmailTemplate.pending, (state, action) => {
            state.status = STATUSES.LOADING;
        })
        .addCase(fetchSingleEmailTemplate.fulfilled, (state, action) => {
            state.data = action.payload;
            state.status = STATUSES.IDLE
        }) 
        .addCase(fetchSingleEmailTemplate.rejected , (state,action) => {
            state.status = STATUSES.ERROR;
        })
    }
});

export const {setSingleEmailTemplate , setStatus} = getSingleEmailTemplateSlice.actions;
export default getSingleEmailTemplateSlice.reducer;

export const fetchSingleEmailTemplate = createAsyncThunk('/SingleEmailTemplateget/fetch', async({id}) => {
    const res = await axios.get(`${window.env.API_URL}/SingleEmailTemplate/${id}`);
    const SingleEmailTemplateData = res.data;
    return SingleEmailTemplateData.data;
})