import React, { useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { fetchjockey } from "../../../redux/getReducer/getJockeySlice";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { fetchracecourse } from "../../../redux/getReducer/getRaceCourseSlice";

import { fetchMeeting } from "../../../redux/getReducer/getMeeting";
import { fetchRaceName } from "../../../redux/getReducer/getRaceName";
import { fetchHorseKind } from "../../../redux/getReducer/getHorseKind";

import { fetchgroundtype } from "../../../redux/getReducer/getGroundType";
import { fetchpointTable } from "../../../redux/getReducer/getPointTable";
import { fetchTrackConditionDropdown } from "../../../redux/getDropDownReducer/getTrackConditionDropDown";
import swal from "sweetalert";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import axios from "axios";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { AiOutlineReload } from "react-icons/ai";
import { Modal } from "react-bootstrap";
import TextInputValidation from "../../../utils/TextInputValidation";
import Racename from "../Racenameform";
import MeetingTypePopUp from "../MeetingType";
import RaceTypePopup from "../Racetypeform";
import TrackLengthPopup from "../Tracklengthform";
import GroundTypePopup from "../GroundType";
import RaceKindPopup from "../RaceKind";
import RaceCoursePopup from "../RaceCourseForm";
import HorseKindPopup from "../Horsekindform";
import JockeyPopup from "../JockeyForm";
import SponsorPopup from "../SponsorForm";
import TrackConditionPopup from "../TrackCondition";
import { fetchcurrency } from "../../../redux/getReducer/getCurrency";
import CurrencyPopup from "../Currency";
import { fetchRaceKindDropdown } from "../../../redux/getDropDownReducer/getRaceKind";
import { fetchRaceTypeDropdown } from "../../../redux/getDropDownReducer/getRaceType";
import { fetchSponsorDropdown } from "../../../redux/getDropDownReducer/getSponsor";
import Loader from "../../../Components/Common/Loader";
import { fetchTrackLengthwithRaceCourse } from "../../../redux/getDropDownReducer/getTracklengthwithRacecourse";
import axiosInstance from "../../../Components/Common/axiosInstance";
import { Input, Form, Select } from "antd";
import { fetchSearchRaceName } from "../../../redux/getReducer/getSearchRaceName";
import { fetchRaceType } from "../../../redux/getReducer/getRacetype";

const weatherOptions = [
  { id: 1, value: "Sunny", label: "Sunny" },
  { id: 2, value: "Cloudy", label: "Cloudy" },
];

const WeatherTypes = [
  {
    id: "1",
    value: "Sunny",
    label: (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <p>Sunny</p>
        <p>مشمس</p>
      </div>
    ),
  },
  {
    id: "2",
    value: "Cloudy",
    label: (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <p>Cloudy</p>
        <p>غائم</p>
      </div>
    ),
  },
];

// const ResultStatus = [
//   { id: "1", value: "Announced", label: "Announced" },
//   { id: "2", value: "Awaited", label: "Awaited" },
//   { id: "3", value: "Cancelled", label: "Cancelled" },

// ];

const bigRaceOption = [
  { id: "1", value: true, label: "true" },
  { id: "2", value: false, label: "false" },
];

const raceStatus = [
  {
    id: "1",
    value: "cancelled",
    label: "Cancel",
  },
  {
    id: "2",
    value: "Due",
    label: "Due",
  },
  {
    id: "3",
    value: "Live",
    label: "Live",
  },
  {
    id: "3",
    value: "Completed",
    label: "End",
  },
];

// const RaceStatussAr = [
//   { id: "1", value: "يلغي", label: "يلغي" },
//   { id: "2", value: "بسبب", label: "بسبب" },
//   { id: "2", value: "يعيش", label: "يعيش" },
//   { id: "2", value: "نهاية", label: "نهاية" },
// ];

const RaceForm = () => {
  const [ErrorMeetingType, setErrorMeetingType] = useState("");
  const [ErrorRaceNameEn, setErrorRaceNameEn] = useState("");
  const [ErrorMeetingCode, setErrorMeetingCode] = useState("");
  const { Option } = Select;

  const [ErrorRaceKind, setErrorRaceKind] = useState("");
  const [ErrorCondition, setErrorCondition] = useState("");
  const [ErrorDescriptionEn, setErrorDescriptionEn] = useState("");
  const [ErrorDescriptionAr, setErrorDescriptionAr] = useState("");

  const [ErrorWeatherType, setErrorWeatherType] = useState("");
  const [ErrorRaceStatus, setErrorRaceStatus] = useState("");
  const [ErrorRaceCourse, setErrorRaceCourse] = useState("");

  const [ErrorWeatherDegree, setErrorWeatherDegree] = useState("");
  const [ErrorHorseKind, setErrorHorseKind] = useState("");
  const [ErrorSponsor, setErrorSponsor] = useState("");
  const [ErrorTrackLength, setErrorTrackLength] = useState("");

  const [ErrorStartTime, setErrorStartTime] = useState("");
  const [ErrorEndTime, setErrorEndTime] = useState("");
  const [ErrorRaceTyp, setErrorRaceType] = useState("");

  const [ErrorFirstPrice, setErrorFirstPrice] = useState("");

  const [isLoading, setisLoading] = useState(false);
  const [bigRace, setBigRace] = useState();

  const [Value1, setValue1] = useState("");
  const [Value2, setValue2] = useState("");
  const [Value3, setValue3] = useState("");
  const [Value4, setValue4] = useState("");
  const [Value5, setValue5] = useState("");
  const [Value6, setValue6] = useState("");
  const [Value7, setValue7] = useState("");
  const SearchTitle = "";
  const SearchCode = "";
  const SearchAge = "";
  //end
  const { data: racecourse } = useSelector((state) => state.racecourse);
  const { data: HorseKind } = useSelector((state) => state.HorseKind);
  const { data: sponsordropdown } = useSelector(
    (state) => state.sponsordropdown
  );
  const { data: meeting } = useSelector((state) => state.meeting);
  const { data: RaceType } = useSelector((state) => state.RaceType);
  const { data: searchRaceName } = useSelector((state) => state.searchRaceName);
  const { data: racekinddropdown } = useSelector(
    (state) => state.racekinddropdown
  );
  const { data: TrackConditiondropdown } = useSelector(
    (state) => state.TrackConditiondropdown
  );
  const { data: currency } = useSelector((state) => state.currency);
  const { data: tracklengthwithracecourse } = useSelector(
    (state) => state.tracklengthwithracecourse
  );

  console.log(tracklengthwithracecourse, "sahi");
  const history = useNavigate();
  const dispatch = useDispatch();

  let trackconditionTable =
    TrackConditiondropdown === undefined ? (
      <></>
    ) : (
      TrackConditiondropdown.map(function (item) {
        return {
          id: item._id,
          value: item.NameEn,
          label: (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <p>{item.NameEn}</p>
              <p>{item.NameAr}</p>
            </div>
          ),
        };
      })
    );

  const bigrace = [
    { id: "1", value: true, label: "True" },
    { id: "2", value: false, label: "False" },
  ];

  let currencyoption =
    currency === undefined ? (
      <></>
    ) : (
      currency.map(function (item) {
        return {
          id: item._id,
          value: item.NameEn,
          label: (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <p>{item.NameEn}</p>
              <p>{item.NameAr}</p>
            </div>
          ),
        };
      })
    );

  let racecourses =
    racecourse === undefined ? (
      <></>
    ) : (
      racecourse.map(function (item) {
        return {
          id: item._id,
          value: item.TrackNameEn,
          label: (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <p>{item.TrackNameEn}</p>
              <p>{item.TrackNameAr}</p>
            </div>
          ),
        };
      })
    );

  let horsekindoptions =
    HorseKind === undefined ? (
      <></>
    ) : (
      HorseKind.map(function (item) {
        return {
          id: item._id,
          value: item.NameEn,
          label: (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <p>{item.NameEn}</p>
              <p>{item.NameAr}</p>
            </div>
          ),
        };
      })
    );

  let Racenameoptions =
    searchRaceName === undefined ? (
      <></>
    ) : (
      searchRaceName.map(function (item) {
        return {
          id: item._id,
          value: item.NameEn,
          label: (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <p>{item.NameEn}</p>
              <p>{item.NameAr}</p>
            </div>
          ),
        };
      })
    );
  // let RacenameoptionsAr =
  //   RaceName === undefined ? (
  //     <></>
  //   ) : (
  //     RaceName.map(function (item) {
  //       return {
  //         id: item._id,
  //         value: item._id,
  //         label: item.NameAr,
  //       };
  //     })
  //   );

  let SponsorForTheRace =
    sponsordropdown === undefined ? (
      <></>
    ) : (
      sponsordropdown.map(function (item) {
        return {
          id: item._id,
          value: item.TitleEn,
          label: (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span
                style={{
                  display: "flex",
                }}
              >
                <img src={item.image} height="30px" width="30px" alt="" />
                <p>{item.TitleEn}</p>
              </span>
              <span
                style={{
                  display: "flex",
                }}
              >
                <img src={item.image} height="30px" width="30px" alt="" />
                <p>{item.TitleAr}</p>
              </span>
            </div>
          ),
        };
      })
    );

  // let SponsorForTheRaceAr =
  //   sponsor === undefined ? (
  //     <></>
  //   ) : (
  //     sponsor.map(function (item) {
  //       return {
  //         id: item._id,
  //         value: item.TitleAr,
  //         label: item.TitleAr,
  //       };
  //     })
  //   );

  let MeetingTypes =
    meeting === undefined ? (
      <></>
    ) : (
      meeting.map(function (item) {
        return {
          id: item._id,
          value: item.NameEn,
          label: (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <p>{item.NameEn}</p>
              <p>{item.NameAr}</p>
            </div>
          ),
        };
      })
    );

  // let MeetingTypesAr =
  //   meeting === undefined ? (
  //     <></>
  //   ) : (
  //     meeting.map(function (item) {
  //       return {
  //         id: item._id,
  //         value: item._id,
  //         label: item.NameAr,
  //       };
  //     })
  //   );

  let RaceTypes =
    RaceType === undefined ? (
      <></>
    ) : (
      RaceType.map(function (item) {
        return {
          id: item._id,
          value: item.NameEn,
          label: (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <p>{item.NameEn}</p>
              <p>{item.NameAr}</p>
            </div>
          ),
        };
      })
    );

  let TrackLenght =
    tracklengthwithracecourse === undefined
      ? {
          id: 0,
          label: "Tracklength Not Found in this RaceCourse",
        }
      : tracklengthwithracecourse.map(function (item) {
          return {
            id: item._id,
            value: item._id,
            label: (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "5px",
                }}
              >
                <p>{item.TrackLength} </p>
                <p>{item.TrackLength} </p>
              </div>
            ),
          };
        });

  let OptionRaceKind =
    racekinddropdown === undefined ? (
      <></>
    ) : (
      racekinddropdown.map(function (item) {
        return {
          id: item._id,
          value: item.NameEn,
          label: (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <p>{item.NameEn}</p>
              <p>{item.NameAr}</p>
            </div>
          ),
        };
      })
    );

  //  Modal functionalities Here
  const [showName, setShowName] = useState(false);
  const [showType, setShowType] = useState(false);
  const [showRaceType, setShowRaceType] = useState(false);
  const [showTrackLength, setShowTrackLength] = useState(false);
  const [showGroundType, setShowGroundType] = useState(false);
  const [showRaceKind, setShowRaceKind] = useState(false);
  const [showRaceCourse, setShowRaceCourse] = useState(false);
  const [showJockey, setShowJockey] = useState(false);
  const [showSponsor, setShowSponsor] = useState(false);
  const [showCondition, setshowCondition] = useState(false);
  const [showhorseKind, setshowhorseKind] = useState(false);
  const [showcurrency, setshowcurrency] = useState(false);

  const handleCloseName = () => setShowName(false);
  const handleCloseType = () => setShowType(false);
  const handleCloseRaceType = () => setShowRaceType(false);
  const handleCloseTrackLength = () => setShowTrackLength(false);
  const handleCloseGroundType = () => setShowGroundType(false);
  const handleCloseRaceKind = () => setShowRaceKind(false);
  const handleCloseRaceCourse = () => setShowRaceCourse(false);
  const handleCloseJockey = () => setShowJockey(false);
  const handleCloseSponsor = () => setShowSponsor(false);
  const handleCloseTrackCondition = () => setshowCondition(false);
  const handleCloseHorseKind = () => setshowhorseKind(false);
  const handleCloseCurrency = () => setshowcurrency(false);

  const handleShowName = async () => {
    await setShowName(true);
  };
  const handleShowHorseKind = async () => {
    await setshowhorseKind(true);
  };
  const handleShowType = async () => {
    await setShowType(true);
  };
  const handleShowTrackCondition = async () => {
    await setshowCondition(true);
  };

  const handleShowRaceType = async () => {
    await setShowRaceType(true);
  };
  const handleShowTrackLength = async () => {
    await setShowTrackLength(true);
  };

  const handleShowRaceKind = async () => {
    await setShowRaceKind(true);
  };

  const handleShowRaceCourse = async () => {
    await setShowRaceCourse(true);
  };

  const handleShowSponsor = async () => {
    await setShowSponsor(true);
  };

  const handleShowCurrency = async () => {
    await setshowcurrency(true);
  };

  // Modal functionalities End Here

  const handleChangeInput = (value) => {
    setValue1(value);
  };

  const FetchNew = () => {
    dispatch(fetchracecourse({ Value1 }));
    dispatch(fetchSponsorDropdown());
    dispatch(fetchMeeting({ SearchCode, SearchTitle, SearchAge }));
    dispatch(fetchRaceTypeDropdown());
    dispatch(fetchRaceName({ SearchCode, SearchTitle, SearchAge }));

    dispatch(fetchRaceKindDropdown());
    dispatch(fetchgroundtype({ SearchCode, SearchTitle, SearchAge }));
    dispatch(fetchpointTable({ SearchCode, SearchTitle, SearchAge }));
    dispatch(fetchTrackConditionDropdown());
    dispatch(fetchHorseKind({ SearchCode, SearchTitle, SearchAge }));
    dispatch(fetchcurrency({ SearchCode, SearchTitle }));
  };

  const [MeetingType, setMeetingType] = useState("");
  const [RaceNameEn, setRaceNameEn] = useState("");
  const [MeetingCode, setMeetingCode] = useState("");
  const [HorsesKind, sethorsesKind] = useState("");
  const [CurrencyData, setCurrencyData] = useState("");
  const [RaceWeight, setRaceWeight] = useState("0");

  const [RaceKind, setRaceKind] = useState("");
  const [DescriptionEn, setDescriptionEn] = useState("");
  const [DescriptionAr, setDescriptionAr] = useState("");
  const [WeatherType, setWeatherType] = useState("");
  const [RaceStatus, setRaceStatus] = useState("");
  const [RaceCourse, setRaceCourse] = useState("");

  const [Sponsor, setSponsor] = useState("");
  const [TrackLength, setTrackLength] = useState("");

  const [RaceTyp, setRaceType] = useState("");
  const [Day, setDay] = useState("");
  const [StartTime, setStartTime] = useState("");

  const [TrackCondition, setTrackCondition] = useState("");
  const [RaceNumber, setRaceNumber] = useState("");
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [TotalPrize, setTotalPrize] = useState("");
  const [FirstPrice, setFirstPrice] = useState("");
  const [SecondPrice, setSecondPrice] = useState("");
  const [ThirdPrice, setThirdPrice] = useState("");
  const [FourthPrice, setFourthPrice] = useState("");
  const [FifthPrice, setFifthPrice] = useState("");
  const [SixthPrice, setSixthPrice] = useState("");
  const [showCalculated, setshowCalculated] = useState(false);

  const [Dstate, setDstate] = useState({
    WeatherDegree: 24,
  });

  const racecourseid = RaceCourse === null ? "" : RaceCourse;
  console.log(racecourseid, "id");

  useEffect(() => {
    dispatch(fetchracecourse({ Value1 }));
    dispatch(fetchSponsorDropdown());
    dispatch(fetchMeeting({ SearchCode, SearchTitle, SearchAge }));
    dispatch(fetchRaceTypeDropdown());
    dispatch(fetchRaceName({ SearchCode, SearchTitle, SearchAge }));

    dispatch(fetchRaceKindDropdown());
    dispatch(fetchgroundtype({ SearchCode, SearchTitle, SearchAge }));
    dispatch(fetchpointTable({ SearchCode, SearchTitle, SearchAge }));
    dispatch(fetchHorseKind({ SearchCode, SearchTitle, SearchAge }));
    dispatch(fetchTrackConditionDropdown());
    dispatch(fetchcurrency({ SearchCode, SearchTitle }));
  }, [dispatch]);

  const handleSearch5 = (value) => {
    setValue7(value);
  };

  useEffect(() => {
    const value = Value7;
    dispatch(fetchTrackConditionDropdown({ value }));
  }, [Value7, dispatch]);

  const handleSearch4 = (value) => {
    setValue6(value);
  };

  useEffect(() => {
    const Value1 = Value6;
    dispatch(fetchracecourse({ Value1 }));
  }, [Value6, dispatch]);

  const handleSearch3 = (value) => {
    setValue5(value);
  };
  useEffect(() => {
    const SearchTitle = Value5;
    dispatch(fetchRaceType({ SearchTitle }));
  }, [Value5, dispatch]);

  const handleSearch2 = (value) => {
    setValue4(value);
  };
  useEffect(() => {
    const value = Value4;
    dispatch(fetchRaceKindDropdown({ value }));
  }, [Value4, dispatch]);

  const handleSearch1 = (value) => {
    setValue3(value);
  };
  useEffect(() => {
    const value = Value3;
    dispatch(fetchSponsorDropdown({ value }));
  }, [Value3, dispatch]);

  const handleSearch = (value) => {
    setValue2(value);
  };
  useEffect(() => {
    dispatch(fetchSearchRaceName({ Value2 }));
  }, [Value2, dispatch]);

  useEffect(() => {
    dispatch(fetchracecourse({ Value1 }));
  }, [Value1, dispatch]);

  const areFieldsNotEmpty = () => {
    if (
      MeetingType === "" ||
      RaceNameEn === "" ||
      MeetingCode === "" ||
      HorsesKind === "" ||
      CurrencyData === "" ||
      RaceWeight === "" ||
      RaceKind === "" ||
      DescriptionEn === "" ||
      DescriptionAr === "" ||
      WeatherType === "" ||
      RaceStatus === "" ||
      RaceCourse === "" ||
      Sponsor === "" ||
      TrackLength === "" ||
      RaceTyp === "" ||
      Day === "" ||
      StartTime === "" ||
      TrackCondition === "" ||
      RaceNumber === ""
    ) {
      return false;
    }
    return true;
  };

  console.log(Day);
  console.log(StartTime);

  const submit = async () => {
    console.log(Day);
    console.log(StartTime, "StartTime");

    if (StartTime === "" && Day === "") {
      swal({
        title: "Error!",
        text: "Please Enter A Day and Start Time ",
        icon: "error",
        button: "OK",
      });

      return;
    }

    if (RaceTyp === "") {
      swal({
        title: "Error!",
        text: "Please Enter A Race Type",
        icon: "error",
        button: "OK",
      });
    }

    let Timing = new Date(`${Day} ${StartTime}`);
    let Endtiming = new Date(Timing.getTime() + 1000);

    let UTCTime = Timing.toISOString();
    let UTCDate = Endtiming.toISOString();

    try {
      setisLoading(true);

      const formData = new FormData();
      formData.append("BigRace", bigRace === undefined ? false : bigRace);
      formData.append("RaceName", RaceNameEn === "" ? null : RaceNameEn);
      formData.append("MeetingType", MeetingType === "" ? "" : MeetingType);
      formData.append("MeetingCode", MeetingCode);
      formData.append("RaceNumber", RaceNumber);
      formData.append("RaceType", RaceTyp);
      formData.append("HorseKindinRace", HorsesKind);
      formData.append("RaceKind", RaceKind);
      formData.append("DescriptionEn", DescriptionEn);
      formData.append("DescriptionAr", DescriptionAr);
      formData.append("Currency", CurrencyData);
      formData.append("RaceWeight", RaceWeight);
      formData.append("Day", Day);
      formData.append(
        "WeatherType",
        WeatherType === "" ? "Sunny" : WeatherType
      );
      formData.append("RaceStatus", RaceStatus === "" ? "Live" : RaceStatus);
      formData.append("RaceCourse", RaceCourse);
      formData.append("WeatherIcon", "WeatherIcon");
      formData.append("StartTime", StartTime);
      formData.append("Timing", UTCTime);
      formData.append("Endtiming", UTCDate);

      formData.append("totalPrize", TotalPrize);
      formData.append("PrizeNumber", isSubscribed === false ? 5 : 6);
      formData.append("Sponsor", Sponsor);
      formData.append("WeatherDegree", Dstate.WeatherDegree);
      formData.append("TrackCondition", TrackCondition);
      formData.append("TrackLength", TrackLength);

      const response = await axios.post(
        `${window.env.API_URL}/createrace`,
        formData
      );
      setisLoading(false);
      swal({
        title: "Success",
        text: "Data has been added successfully ",
        icon: "success",
        button: "OK",
      });
      const RaceId = response.data.data._id;
      history("/publishrace", {
        state: {
          RaceId: RaceId,
        },
      });
      setisLoading(false);
    } catch (error) {
      if (error.code === "ECONNABORTED") {
        // Timeout error occurred
        swal({
          title: "Error!",
          text: "The request timed out. Please try again later.",
          icon: "error",
          button: "OK",
        });
      } else {
        const err = error.response.data.message;
        swal({
          title: "Error!",
          text: err,
          icon: "error",
          button: "OK",
        });
        setisLoading(false);
      }
    }
    setisLoading(false);
  };

  const data1 = JSON.stringify(
    TextInputValidation("en", DescriptionEn, "Race Description")
  );

  const obj = JSON.parse(data1);

  const data2 = JSON.stringify(
    TextInputValidation("ar", DescriptionAr, "Race Description Arabic")
  );

  const objAr = JSON.parse(data2);

  const handleChange = (event) => {
    if (event.target.checked) {
      console.log("✅ Checkbox is checked", isSubscribed);
    } else {
      console.log("⛔️ Checkbox is NOT checked", isSubscribed);
    }
    setIsSubscribed((current) => !current);
  };

  const calculatePrize = () => {
    setshowCalculated(true);
    if (!isSubscribed) {
      let data1 = 60 / 100;
      let data2 = 20 / 100;
      let data3 = 11 / 100;
      let data4 = 6 / 100;
      let data5 = 3 / 100;
      setFirstPrice(data1 * TotalPrize);
      setSecondPrice(data2 * TotalPrize);
      setThirdPrice(data3 * TotalPrize);
      setFourthPrice(data4 * TotalPrize);
      setSixthPrice("0");
      setFifthPrice(data5 * TotalPrize);
    } else {
      let data1 = 60 / 100;
      let data2 = 20 / 100;
      let data3 = 10 / 100;
      let data4 = 5 / 100;
      let data5 = 3 / 100;
      let data6 = 2 / 100;
      setFirstPrice(data1 * TotalPrize);
      setSecondPrice(data2 * TotalPrize);
      setThirdPrice(data3 * TotalPrize);
      setFourthPrice(data4 * TotalPrize);
      setSixthPrice(data6 * TotalPrize);
      setFifthPrice(data5 * TotalPrize);
    }
  };

  useEffect(() => {
    dispatch(fetchTrackLengthwithRaceCourse({ racecourseid }));
  }, [dispatch, racecourseid]);
  const fetchTrackLength = async () => {
    dispatch(fetchTrackLengthwithRaceCourse({ racecourseid }));
  };

  const initialValues = {
    WeatherDegree: 24,
  };

  const [form] = Form.useForm();

  form.setFieldsValue({
    raceststus: "Live",
    watherT: "Sunny",
    bigrace: "false",
  });

  return (
    <>
      <div className="page">
        <div className="rightsidedata">
          <div
            style={{
              marginTop: "30px",
            }}
          >
            <div className="Headers">Add Race</div>
            {isLoading ? (
              <Loader />
            ) : (
              <div className="form">
                <Form
                  onFinish={submit}
                  initialValues={initialValues}
                  form={form}
                >
                  <div className="row mainrow">
                    <input
                      type="date"
                      data-placeholder="Race Date"
                      onChange={(e) => setDay(e.target.value)}
                      value={Day}
                      className="dateforraces"
                      required
                      aria-required="true"
                    />

                    <span
                      style={{ top: "270px" }}
                      className={Day === "" ? "error" : "success"}
                    ></span>
                  </div>
                  <div className="row mainrow">
                    <div className="col-sm">
                      <input
                        type="time"
                        onChange={(e) => setStartTime(e.target.value)}
                        value={StartTime}
                        onBlur={() =>
                          StartTime === ""
                            ? setErrorStartTime("Start Time is required ")
                            : setErrorStartTime("Start Time is Validated")
                        }
                        required
                        aria-required="true"
                      />

                      {/* <span className="spanForm">|</span> */}
                    </div>

                    <div className="col-sm">
                      <Form.Item
                        name="RaceNumber "
                        label="Race Number"
                        rules={[
                          {
                            required: true,
                            message: " Race Number  is required",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Race Number"
                          onChange={(e) => setRaceNumber(e.target.value)}
                          value={RaceNumber}
                          name="Race Number"
                          type="number"
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="row  mainrow">
                    <div className="col-sm">
                      <Form.Item
                        name="Meeting Code"
                        label="Meeting Code"
                        rules={[
                          {
                            required: true,
                            message: "Meeting Code is required",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Meeting Code"
                          onChange={(e) => setMeetingCode(e.target.value)}
                          value={MeetingCode}
                          name="Meeting Code"
                          type="text"
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="row mainrow">
                    <div className="col-sm">
                      <Form.Item
                        value={HorsesKind}
                        name="HorsesKind"
                        label="HorseKind"
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: "Please select your Horse Kind!",
                          },
                        ]}
                      >
                        <Select
                          filterOption={false}
                          onChange={sethorsesKind}
                          showSearch
                          placeholder="Horse Kind"
                        >
                          {HorseKind?.map((item) => (
                            <Option value={item._id}>
                              {item.NameEn} {"(" + item.NameAr + ")"}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>

                      <span className="spanForm selectSpacer">
                        <OverlayTrigger
                          overlay={
                            <Tooltip id={`tooltip-top`}>Add more</Tooltip>
                          }
                        >
                          <span
                            className="addmore"
                            onClick={handleShowHorseKind}
                          >
                            +
                          </span>
                        </OverlayTrigger>
                        <OverlayTrigger
                          overlay={
                            <Tooltip id={`tooltip-top`}>Fetch New</Tooltip>
                          }
                        >
                          <span className="addmore" onClick={FetchNew}>
                            <AiOutlineReload />
                          </span>
                        </OverlayTrigger>{" "}
                      </span>
                    </div>
                  </div>
                  <div className="row mainrow">
                    <div className="col-sm">
                      <Form.Item
                        defaultValue={RaceNameEn}
                        name="raceName"
                        label="Race Name"
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: "Please select your Race Name",
                          },
                        ]}
                      >
                        <Select
                          filterOption={false}
                          onChange={setRaceNameEn}
                          onSearch={handleSearch}
                          showSearch
                          placeholder="Race Name"
                        >
                          {searchRaceName?.map((item) => (
                            <Option value={item._id}>
                              {item.NameEn} {"(" + item.NameAr + ")"}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>

                      {/* <Select
                      placeholder={<div>Race Name</div>}
                      defaultValue={RaceNameEn}
                      onChange={setRaceNameEn}
                      required aria-required="true"
                      options={Racenameoptions}
                      isClearable={true}
                      isSearchable={true}
                      onBlur={() =>
                        RaceNameEn === ""
                          ? setErrorRaceNameEn("Race Name is required ")
                          : setErrorRaceNameEn("Race Name is Validated")
                      }
                    />{" "} */}
                      <span className="spanForm selectSpacer">
                        <OverlayTrigger
                          overlay={
                            <Tooltip id={`tooltip-top`}>Add more</Tooltip>
                          }
                        >
                          <span className="addmore" onClick={handleShowName}>
                            +
                          </span>
                        </OverlayTrigger>
                        <OverlayTrigger
                          overlay={
                            <Tooltip id={`tooltip-top`}>Fetch New</Tooltip>
                          }
                        >
                          <span className="addmore" onClick={FetchNew}>
                            <AiOutlineReload />
                          </span>
                        </OverlayTrigger>
                      </span>
                    </div>

                    {/* <div className="col-sm">
                    <Select
                      placeholder={<div>اسم العرق</div>}
                      className="selectdir"
                      defaultValue={RaceNameEn}
                      onChange={setRaceNameEn}
                      options={RacenameoptionsAr}
                      isClearable={true}
                      isSearchable={true}
                    />
                  </div> */}
                  </div>
                  <div className="row mainrow">
                    <div className="col-sm">
                      <Form.Item
                        defaultValue={Sponsor}
                        name="sponsor"
                        label="Sponsor"
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: "Please select your Sponsor ",
                          },
                        ]}
                      >
                        <Select
                          filterOption={false}
                          onChange={setSponsor}
                          onSearch={handleSearch1}
                          showSearch
                          placeholder="Sponsor"
                        >
                          {sponsordropdown.map((item) => (
                            <Option key={item._id} value={item._id}>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={item.image}
                                  alt={item.image}
                                  style={{
                                    width: "24px",
                                    height: "24px",
                                    marginRight: "8px",
                                  }}
                                />
                                {item.TitleEn} ({item.TitleAr})
                              </div>
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>

                      {/* <Select
                      placeholder={<div>Sponsor</div>}
                      defaultValue={Sponsor}
                      onChange={setSponsor}
                      required aria-required="true"
                      options={SponsorForTheRace}
                      isClearable={true}
                      isSearchable={true}
                      onBlur={() =>
                        Sponsor === ""
                          ? setErrorSponsor("Sponsor  is required ")
                          : setErrorSponsor("Sponsor  is Validated")
                      }
                    /> */}
                      <span className="spanForm selectSpacer">
                        <OverlayTrigger
                          overlay={
                            <Tooltip id={`tooltip-top`}>Add more</Tooltip>
                          }
                        >
                          <span className="addmore" onClick={handleShowSponsor}>
                            +
                          </span>
                        </OverlayTrigger>
                        <OverlayTrigger
                          overlay={
                            <Tooltip id={`tooltip-top`}>Fetch New</Tooltip>
                          }
                        >
                          <span className="addmore" onClick={FetchNew}>
                            <AiOutlineReload />
                          </span>
                        </OverlayTrigger>
                      </span>
                    </div>

                    {/* <div className="col-sm">
                    <Select
                      placeholder={<div>نوع السباق</div>}
                      defaultValue={Sponsor}
                      onChange={setSponsor}
                      options={SponsorForTheRaceAr}
                      className="selectdir"
                      isClearable={true}
                      isSearchable={true}
                    />
                  </div> */}
                  </div>
                  <div className="row mainrow">
                    <div className="col-sm">
                      <Form.Item
                        defaultValue={RaceKind}
                        name="racekind"
                        label="Race Kind"
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: "Please select your Race Kind ",
                          },
                        ]}
                      >
                        <Select
                          filterOption={false}
                          onChange={setRaceKind}
                          onSearch={handleSearch2}
                          showSearch
                          placeholder="Race Kind"
                        >
                          {racekinddropdown?.map((item) => (
                            <Option value={item._id}>
                              {item.NameEn} {"(" + item.NameAr + ")"}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>

                      {/* <Select
                      placeholder={<div>RaceKind</div>}
                      defaultValue={RaceKind}
                      onChange={setRaceKind}
                      options={OptionRaceKind}
                      isClearable={true}
                      isSearchable={true}
                      hasValue={true}
                      onBlur={() =>
                        RaceKind === ""
                          ? setErrorRaceKind("Race Kind is required ")
                          : setErrorRaceKind("")
                      }
                    /> */}
                      <span className="spanForm selectSpacer">
                        <OverlayTrigger
                          overlay={
                            <Tooltip id={`tooltip-top`}>Add more</Tooltip>
                          }
                        >
                          <span
                            className="addmore"
                            onClick={handleShowRaceKind}
                          >
                            +
                          </span>
                        </OverlayTrigger>
                        <OverlayTrigger
                          overlay={
                            <Tooltip id={`tooltip-top`}>Fetch New</Tooltip>
                          }
                        >
                          <span className="addmore" onClick={FetchNew}>
                            <AiOutlineReload />
                          </span>
                        </OverlayTrigger>{" "}
                      </span>
                    </div>
                  </div>
                  <div className="row mainrow">
                    <div className="col-sm">
                      <Form.Item
                        defaultValue={RaceTyp}
                        name="RaceTyp"
                        label="Race Type"
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: "Please select your Race Type ",
                          },
                        ]}
                      >
                        <Select
                          filterOption={false}
                          onChange={setRaceType}
                          name="RaceTyp"
                          onSearch={handleSearch3}
                          showSearch
                          placeholder="Race Type"
                        >
                          {RaceType?.map((item) => (
                            <Option value={item._id}>
                              {item.NameEn} {"(" + item.NameAr + ")"}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>

                      <span className="spanForm selectSpacer">
                        <OverlayTrigger
                          overlay={
                            <Tooltip id={`tooltip-top`}>Add more</Tooltip>
                          }
                        >
                          <span
                            className="addmore"
                            onClick={handleShowRaceType}
                          >
                            +
                          </span>
                        </OverlayTrigger>
                        <OverlayTrigger
                          overlay={
                            <Tooltip id={`tooltip-top`}>Fetch New</Tooltip>
                          }
                        >
                          <span className="addmore" onClick={FetchNew}>
                            <AiOutlineReload />
                          </span>
                        </OverlayTrigger>{" "}
                      </span>
                    </div>
                  </div>

                  <div className="row mainrow">
                    <div className="col-sm">
                      <Form.Item
                        name="DescriptionEn"
                        label="Description"
                        rules={[
                          {
                            required: true,
                            message: "Description (English) is required",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Description"
                          onChange={(e) => setDescriptionEn(e.target.value)}
                          value={DescriptionEn}
                          name="DescriptionEn"
                        />
                      </Form.Item>

                      <span className="spanForm spacer"> |</span>
                    </div>

                    <div className="col-sm">
                      <Form.Item
                        name="DescriptionAr"
                        label="وصف"
                        rules={[
                          {
                            required: true,
                            message: "Description (Arabic) is required",
                          },
                        ]}
                        style={{ direction: "rtl" }}
                      >
                        <Input
                          placeholder="وصف"
                          onChange={(e) => setDescriptionAr(e.target.value)}
                          value={DescriptionAr}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="row mainrow">
                    <div className="col-sm">
                      <Form.Item
                        defaultValue={MeetingType}
                        name="meetingtype"
                        label="Meeting Type"
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: "Please select your Meeting Type ",
                          },
                        ]}
                      >
                        <Select
                          filterOption={false}
                          onChange={setMeetingType}
                          name="meetingtype"
                          placeholder="Meeting Type"
                        >
                          {meeting?.map((item) => (
                            <Option value={item._id}>
                              {item.NameEn} {"(" + item.NameAr + ")"}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>

                      <span className="spanForm selectSpacer">
                        <OverlayTrigger
                          overlay={
                            <Tooltip id={`tooltip-top`}>Add more</Tooltip>
                          }
                        >
                          <span className="addmore" onClick={handleShowType}>
                            +
                          </span>
                        </OverlayTrigger>
                        <OverlayTrigger
                          overlay={
                            <Tooltip id={`tooltip-top`}>Fetch New</Tooltip>
                          }
                        >
                          <span className="addmore" onClick={FetchNew}>
                            <AiOutlineReload />
                          </span>
                        </OverlayTrigger>
                      </span>
                    </div>
                  </div>

                  <div className="row  mainrow">
                    <div className="col-sm">
                      <Form.Item
                        name="WeatherDegree"
                        label="Weather Degree"
                        rules={[
                          {
                            required: true,
                            message: "Weather Degree is required",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Weather Degree"
                          onChange={(e) =>
                            setDstate({
                              ...Dstate,
                              WeatherDegree: e.target.value,
                            })
                          }
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          maxLength="4"
                          value={Dstate.WeatherDegree}
                          name="WeatherDegree"
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="row mainrow">
                    <div className="col-sm">
                      <Form.Item
                        defaultValue={WeatherType}
                        name="watherT"
                        label="Weather Type"
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: "Please select your weather Type ",
                          },
                        ]}
                      >
                        <Select
                          filterOption={false}
                          onChange={setWeatherType}
                          name="weather"
                          placeholder="weather Type"
                        >
                          {weatherOptions?.map((item) => (
                            <Option value={item.value}>{item.label} </Option>
                          ))}
                        </Select>
                      </Form.Item>

                      {/* <span className="spanForm"> |</span> */}
                    </div>
                  </div>
                  <div className="row mainrow">
                    <div className="col-sm">
                      <Form.Item
                        defaultValue={RaceCourse}
                        name="Racecourse"
                        label="Race Course"
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: "Please select your Race Course ",
                          },
                        ]}
                      >
                        <Select
                          filterOption={false}
                          onChange={setRaceCourse}
                          name="Race Course"
                          onSearch={handleSearch4}
                          showSearch
                          placeholder="Race Course"
                        >
                          {racecourse?.map((item) => (
                            <Option value={item._id}>
                              {item.TrackNameEn} {"(" + item.TrackNameAr + ")"}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>

                      {/* 
                    <Select
                      placeholder={<div>Race Course</div>}
                      defaultValue={RaceCourse}
                      onChange={setRaceCourse}
                      onInputChange={handleChangeInput}
                      options={racecourses}
                      isClearable={true}
                      isSearchable={true}
                      onBlur={() =>
                        RaceCourse === ""
                          ? setErrorRaceCourse("Race Course is required ")
                          : setErrorRaceCourse("Race Course is Validated")
                      }
                    /> */}

                      <span className="spanForm selectSpacer">
                        <OverlayTrigger
                          overlay={
                            <Tooltip id={`tooltip-top`}>Add more</Tooltip>
                          }
                        >
                          <span
                            className="addmore"
                            onClick={handleShowRaceCourse}
                          >
                            +
                          </span>
                        </OverlayTrigger>
                        <OverlayTrigger
                          overlay={
                            <Tooltip id={`tooltip-top`}>Fetch New</Tooltip>
                          }
                        >
                          <span className="addmore" onClick={FetchNew}>
                            <AiOutlineReload />
                          </span>
                        </OverlayTrigger>{" "}
                      </span>

                      <div className="col-sm fetchTrack">
                        <p onClick={fetchTrackLength} className="verifybtn">
                          Fetch TrackLength
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="row mainrow">
                    <div className="col-sm">
                      <Form.Item
                        defaultValue={RaceCourse}
                        name="Track Length"
                        label="Track Length"
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: "Please select your Track Length ",
                          },
                        ]}
                      >
                        <Select
                          filterOption={false}
                          onChange={setTrackLength}
                          name=" Track Length"
                          showSearch
                          placeholder=" Track Length"
                        >
                          {tracklengthwithracecourse?.map((item) => (
                            <Option value={item._id}>
                              {item.TrackLength}(
                              {item?.GroundTypeModelData?.NameEn})
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>

                      {/* <Select

                      placeholder="Track Length"
                      options={TrackLenght}
                      onChange={setTrackLength}
                      value={TrackLength}

                      onBlur={() =>
                        TrackLength === " "
                          ? setErrorTrackLength("Track Length is required ")
                          : setErrorTrackLength("")
                      }
                    /> */}

                      <span className="spanForm selectSpacer">
                        <OverlayTrigger
                          overlay={
                            <Tooltip id={`tooltip-top`}>Add more</Tooltip>
                          }
                        >
                          <span
                            className="addmore"
                            onClick={handleShowTrackLength}
                          >
                            +
                          </span>
                        </OverlayTrigger>
                        <OverlayTrigger
                          overlay={
                            <Tooltip id={`tooltip-top`}>Fetch New</Tooltip>
                          }
                        >
                          <span className="addmore" onClick={FetchNew}>
                            <AiOutlineReload />
                          </span>
                        </OverlayTrigger>{" "}
                      </span>
                    </div>
                  </div>

                  <div className="row mainrow">
                    <div className="col-sm">
                      <Form.Item
                        defaultValue={RaceStatus}
                        name="raceststus"
                        label="Race Status"
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: "Please select your Race Status ",
                          },
                        ]}
                      >
                        <Select
                          filterOption={false}
                          onChange={setRaceStatus}
                          name="raceststus"
                          placeholder="Race Status"
                        >
                          {raceStatus?.map((item) => (
                            <Option value={item.value}>{item.label} </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>

                  <div className="row mainrow">
                    <div className="col-sm">
                      <Form.Item
                        defaultValue={TrackCondition}
                        name="trackcondition"
                        label="Track Condition"
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: "Please select your Track Condition ",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          filterOption={false}
                          onChange={setTrackCondition}
                          name="trackcondition"
                          onSearch={handleSearch5}
                          placeholder="Track Condition"
                        >
                          {TrackConditiondropdown?.map((item) => (
                            <Option value={item._id}>
                              {item.NameEn} {"(" + item.NameAr + ")"}{" "}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>

                      {/* <Select
                      placeholder={<div>Track Condition</div>}
                      defaultValue={{ label: "GS", id: "08829866-24d9-4573-9950-eab0962ee25c" }}
                      onChange={setTrackCondition}
                      options={trackconditionTable}
                      isClearable={true}
                      isSearchable={true}
                      onBlur={() =>
                        TrackCondition === ""
                          ? setErrorCondition("Track Condition is required ")
                          : setErrorCondition("Track Condition is Validated")
                      }
                    /> */}
                      <span className="spanForm selectSpacer">
                        <OverlayTrigger
                          overlay={
                            <Tooltip id={`tooltip-top`}>Add more</Tooltip>
                          }
                        >
                          <span
                            className="addmore"
                            onClick={handleShowTrackCondition}
                          >
                            +
                          </span>
                        </OverlayTrigger>
                        <OverlayTrigger
                          overlay={
                            <Tooltip id={`tooltip-top`}>Fetch New</Tooltip>
                          }
                        >
                          <span className="addmore" onClick={FetchNew}>
                            <AiOutlineReload />
                          </span>
                        </OverlayTrigger>{" "}
                      </span>
                      {/* <span className="spanForm"> |</span> */}
                    </div>

                    {/* <div className="col-sm">
                    <Select
                      placeholder={<div>حالة السباق</div>}
                      className="selectdir"
                      options={RaceStatussAr}
                      defaultValue={RaceStatus}
                      onChange={setRaceStatus}
                      isClearable={true}
                      isSearchable={true}
                    />
                  </div> */}
                  </div>

                  <div className="row mainrow">
                    <div className="col-sm">
                      <Form.Item
                        defaultValue={currency}
                        name="currency"
                        label="Currency"
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: "Please select your Currency ",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          filterOption={false}
                          onChange={setCurrencyData}
                          name="currency"
                          placeholder="Currency"
                        >
                          {currency?.map((item) => (
                            <Option value={item._id}>
                              {item.NameEn} {"(" + item.NameAr + ")"}{" "}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>

                      <span className="spanForm selectSpacer">
                        <OverlayTrigger
                          overlay={
                            <Tooltip id={`tooltip-top`}>Add more</Tooltip>
                          }
                        >
                          <span
                            className="addmore"
                            onClick={handleShowCurrency}
                          >
                            +
                          </span>
                        </OverlayTrigger>
                        <OverlayTrigger
                          overlay={
                            <Tooltip id={`tooltip-top`}>Fetch New</Tooltip>
                          }
                        >
                          <span className="addmore" onClick={FetchNew}>
                            <AiOutlineReload />
                          </span>
                        </OverlayTrigger>
                      </span>
                    </div>
                  </div>
                  <div className="row mainrow">
                    <div className="col-sm">
                      <Form.Item
                        defaultValue={bigRace}
                        name="bigrace"
                        label="Big Race"
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: "Please select Big Race ",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          filterOption={false}
                          onChange={setBigRace}
                          name="bigrace"
                          placeholder="Big Race"
                        >
                          {bigRaceOption?.map((item) => (
                            <Option value={item.value}>{item.label} </Option>
                          ))}
                        </Select>
                      </Form.Item>

                      {/* <Select
                      placeholder={<div>Big Race </div>}
                      onChange={setBigRace}
                      value={bigRace}
                      options={bigrace}
                      isClearable={true}
                      isSearchable={true}

                    /> */}

                      {/* <span className={Sponsor === "" ? "error" : "success"}>
                      {ErrorSponsor}
                    </span> */}
                    </div>
                  </div>

                  <div className="row mainrow">
                    <div className="col-sm">
                      <Form.Item
                        name="totalprize"
                        label="Total Prize"
                        rules={[
                          {
                            required: true,
                            message: "Total Prize  is required",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Total Prize"
                          onChange={(e) => setTotalPrize(e.target.value)}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          min="0"
                          value={TotalPrize}
                          name="totalprize"
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div
                    className="ButtonSection"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div>
                      <div className="ViewCalulatedPrize">
                        <div className="ViewCalulatedPrize1122">
                          <span className="ViewCalulatedPrize111">
                            <input
                              type="checkbox"
                              id="vehicle1"
                              name="checked"
                              value={isSubscribed}
                              onChange={handleChange}
                            />
                            <label for="vehicle1">Six Position</label>
                            <br />
                          </span>
                          <span
                            onClick={calculatePrize}
                            className="ViewCalulatedPrize1"
                          >
                            Calculate Prize
                          </span>
                        </div>

                        {showCalculated ? (
                          <table className="calcualtedPrizeTable">
                            <tr>
                              <th>First Prize</th>
                              <th>Second Prize</th>
                              <th>Third Prize</th>
                              <th>Fourth Prize</th>
                              <th>Fifth Prize</th>
                              <th>Six Prize</th>
                            </tr>
                            <tr>
                              <td>{FirstPrice}</td>
                              <td>{SecondPrice}</td>
                              <td>{ThirdPrice}</td>
                              <td>{FourthPrice}</td>
                              <td>{FifthPrice}</td>
                              <td>{SixthPrice}</td>
                            </tr>
                          </table>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <div>
                      <button
                        type="submit"
                        className="SubmitButton"
                        disabled={isLoading}
                      >
                        Save & Add Horses
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            )}
          </div>
        </div>
      </div>

      {/*  ------------Modal Popup ------------------ */}

      <Modal
        Modal
        show={showName}
        onHide={handleCloseName}
        size="lg"
        arialabelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2>Race Name</h2>
        </Modal.Header>
        <Modal.Body>
          <Racename setShowName={setShowName} />
        </Modal.Body>
      </Modal>
      <Modal
        show={showType}
        onHide={handleCloseType}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2>Meeting Type</h2>
        </Modal.Header>
        <Modal.Body>
          <MeetingTypePopUp setShowType={setShowType} />
        </Modal.Body>
      </Modal>
      <Modal
        show={showRaceType}
        onHide={handleCloseRaceType}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2>Race Type</h2>
        </Modal.Header>
        <Modal.Body>
          <RaceTypePopup setShowRaceType={setShowRaceType} />
        </Modal.Body>
      </Modal>
      <Modal
        show={showTrackLength}
        onHide={handleCloseTrackLength}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2>Track Length</h2>
        </Modal.Header>
        <Modal.Body>
          <TrackLengthPopup setShowTrackLength={setShowTrackLength} />
        </Modal.Body>
      </Modal>
      <Modal
        show={showRaceKind}
        onHide={handleCloseRaceKind}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2>Race Kind</h2>
        </Modal.Header>
        <Modal.Body>
          <RaceKindPopup setShowRaceKind={setShowRaceKind} />
        </Modal.Body>
      </Modal>
      <Modal
        show={showRaceCourse}
        onHide={handleCloseRaceCourse}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2>Create Race Course</h2>
        </Modal.Header>
        <Modal.Body>
          <RaceCoursePopup setShowRaceCourse={setShowRaceCourse} />
        </Modal.Body>
      </Modal>

      <Modal
        show={showSponsor}
        onHide={handleCloseSponsor}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2>Sponsor</h2>
        </Modal.Header>
        <Modal.Body>
          <SponsorPopup setShowSponsor={setShowSponsor} />
        </Modal.Body>
      </Modal>

      <Modal
        show={showcurrency}
        onHide={handleCloseCurrency}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2>Currency</h2>
        </Modal.Header>
        <Modal.Body>
          <CurrencyPopup setshowcurrency={setshowcurrency} />
        </Modal.Body>
      </Modal>

      <Modal
        show={showGroundType}
        onHide={handleCloseGroundType}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2>Ground Type</h2>
        </Modal.Header>
        <Modal.Body>
          <GroundTypePopup setShowGroundType={setShowGroundType} />
        </Modal.Body>
      </Modal>
      <Modal
        show={showCondition}
        onHide={handleCloseTrackCondition}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2>Track Condition</h2>
        </Modal.Header>
        <Modal.Body>
          <TrackConditionPopup setshowCondition={setshowCondition} />
        </Modal.Body>
      </Modal>
      <Modal
        show={showhorseKind}
        onHide={handleCloseHorseKind}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2>Horse Kind</h2>
        </Modal.Header>
        <Modal.Body>
          <HorseKindPopup setshowhorseKind={setshowhorseKind} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RaceForm;
