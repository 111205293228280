import React, { Fragment, useState , useEffect } from "react";
import swal from "sweetalert";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import TextInputValidation from "../../utils/TextInputValidation";
import Loader from "../../Components/Common/Loader";

const Color = () => {
  //for errors
  const [Error, setError] = useState("");
  const [colorForPrimary, setColorForPrimary] = useState("");
  const [colorForSecondary, setColorForSecondary] = useState("");


  const [registeration, setregisteration] = useState({
    ThemeName: "",
    PrimaryColor: "",
    SecondaryColor: "",
  });

  const history = useNavigate();
  const { state } = useLocation();

  const { themeid } = state;

  console.log(themeid,'123 21')
  const [state1, setState] = useState({
    ThemeName: "",
    PrimaryColor: "",
    SecondaryColor: ""
  });

  //---------------------------get Data from previous State---------------------------//
  useEffect(() => {
    if (themeid) {
      setState({
        ThemeName: themeid.ThemeName,
        PrimaryColor: themeid.PrimaryColor,
        SecondaryColor: themeid.SecondaryColor,
      });
    } else {
    }
  }, [themeid]);


  const [isLoading, setisLoading] = useState(false);

  //----------------------------shortcode ---------------------//

 
  //----------------------------input validation ---------------------//
  const data1 = JSON.stringify(
    TextInputValidation("en", registeration.ThemeName, "Color Name")
  );

  const obj = JSON.parse(data1);

  //----------------------------submit ---------------------//
  const submit = async (event) => {
    event.preventDefault();
    setisLoading(true);
    try {
      const formData = new FormData();
      formData.append("ThemeName", state1.ThemeName);
      formData.append("PrimaryColor", state1.PrimaryColor);
      formData.append("SecondaryColor", state1.SecondaryColor);

      await axios.put(`${window.env.API_URL}/updateTheme/${themeid._id}`, formData);
      swal({
        title: "Success!",
        text: "Data has been Updated Successfully ",
        icon: "success",
        button: "OK",
      });
      history("/ThemeListing");

    } catch (error) {
      const err = error.response.data.message;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
      setisLoading(false);
    }
  };
  //conditional styling for errors


  return (
    <Fragment>
      <div className="page">
        <div className="rightsidedata">
          <div
            style={{
              marginTop: "30px",
            }}
          >
            <div className="Headers">Theme Setting</div>
            {!isLoading ? <div className="form">
              <form onSubmit={submit}>
                <div className="row mainrow">
                  <div className="col-sm">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Theme Name"
                      className="mb-3"
                      name="Name"
                      onChange={(e) =>
                        setState({ ...state1, ThemeName: e.target.value })
                      }
                    >
                      <Form.Control
                        required
                        value={state1.ThemeName}
                        name="ThemeName"
                        type="text"
                        placeholder="Theme Name"
                        onBlur={() => setError(obj)}
                      />
                    </FloatingLabel>
                    <span className={Error.status ? "success" : "error"}>
                      {Error.message}
                    </span>
                  </div>
                </div>

                <div className="row mainrow">
                <div className="col-sm">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Primary Color "
                      className="mb-3"


                    >
                      <Form.Control
                        required
                        name="PrimaryColor"
                        type="text"
                        value={state1.PrimaryColor}
                        placeholder="Primary Color"
                        readOnly

                      />
                    </FloatingLabel>
                    <span className="spanForm"> |</span>

                  </div>

                  <div className="col-sm">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Secondary Color "
                      className="mb-3"


                    >
                      <Form.Control
                        required

                        name="SecondaryColor"
                        type="text"
                        value={state1.SecondaryColor}
                        placeholder="Secondary Color"
                        readOnly

                      />
                    </FloatingLabel>

                  </div>
                </div>
            
                <div className="row mainrow1">
                <div className="col-sm">
                <div className="swatch">
                  <input type="color" id="color" name="color"  onChange={(e) =>
                          setState({ ...state1, PrimaryColor: e.target.value })
                        } value={state1.PrimaryColor}  />
                  {
                    colorForPrimary ? <h5 className="text-center p-1">
                      {state1.PrimaryColor}
                    </h5> : <label className="Multipleownerlabel">
                      Select Primary Color
                    </label>
                  }

                
                </div>
                </div>
                <div className="col-sm">
                <div className="swatch">
                  <input type="color" id="color" name="color"  onChange={(e) =>
                          setState({ ...state1, SecondaryColor: e.target.value })
                        } value={state1.SecondaryColor} />
                  {
                    colorForSecondary ? <h5 className="text-center p-1">
                      {colorForSecondary}
                    </h5> : <label className="Multipleownerlabel">
                      Select Secondary Color
                    </label>
                  }

                
                </div>
                </div>
                </div>
                

                
                
                <div
                  className="ButtonSection "
                  style={{ justifyContent: "end" }}
                >
                  <button
                    Name="submit"
                    className="SubmitButton"
                    disabled={isLoading}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div> : <Loader />}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Color;
