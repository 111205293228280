import axios from "axios";
import { createAsyncThunk,createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
    IDLE : 'idle',
    ERROR:'error',
    LOADING: 'loading',
});

const getGetTrainerMaxShortCodeSlice = createSlice({
    name: 'GetTrainerMaxShortCode',
    initialState: {
        data:[],
        status : STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
        .addCase(fetchGetTrainerMaxShortCode.pending, (state, action) => {
            state.status = STATUSES.LOADING;
        })
        .addCase(fetchGetTrainerMaxShortCode.fulfilled, (state, action) => {
            state.data = action.payload;
            state.status = STATUSES.IDLE
        }) 
        .addCase(fetchGetTrainerMaxShortCode.rejected , (state,action) => {
            state.status = STATUSES.ERROR;
        })
    }
});

export const {setGetTrainerMaxShortCode , setStatus} = getGetTrainerMaxShortCodeSlice.actions;
export default getGetTrainerMaxShortCodeSlice.reducer;

export const fetchGetTrainerMaxShortCode = createAsyncThunk('/getGetTrainerMaxShortCode/fetch', async() => {
    const res = await axios.get(`${window.env.API_URL}/GetTrainerMaxShortCode`);
    const GetTrainerMaxShortCodeData = res.data;
    return GetTrainerMaxShortCodeData.data;
})