import React from "react";
import Lottie from "lottie-react";
import HorseAnimation from "../../assets/horselottie.json";

const Loader = () => {
  return (
    <div className="d-flex justify-centre w-10 loaderDiv">
      <Lottie
        animationData={HorseAnimation}
        loop={true}
        className="defaultLoader"
      />
    </div>
  );
};

export default Loader;
