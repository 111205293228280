import React from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Default from "../../assets/default.jpg"



const HorsePopup = (data) => {

  const color = data.data.ColorIDData ? data.data.ColorIDData : "N/A"
  const breeder = data.data.BreederData ? data.data.BreederData : "N/A"
  const sex = data.data.SexModelData ? data.data.SexModelData : "N/A"

  const nationality = data.data.NationalityData ? data.data.NationalityData : "N/A"
  const trainer = data.data.ActiveTrainerData ? data.data.ActiveTrainerData : "N/A"
  const owner = data.data.ActiveOwnerData ? data.data.ActiveOwnerData : "N/A"
  const dam = data.data.DamData ? data.data.DamData : "N/A"
  const sire = data.data.SireData ? data.data.SireData : "N/A"
  const gsire = data.data.GSireData ? data.data.GSireData : "N/A"


  return (
    <>

      <div className="form">
        <div className="modalPreview">

          <img src={data.data.HorseImage ? data.data.HorseImage : Default} className="PreviewImage" alt="" />



        </div>

        <div className="row mainrow">
          <div className="col-sm">

            <FloatingLabel
              controlId="floatingInput"
              label="Name"
              className="mb-3"



            >
              <Form.Control type="text" placeholder="Description" readOnly value={data.data.NameEn} />
            </FloatingLabel>

            <span className="spanForm"> |</span>
          </div>

          <div className="col-sm">
            <FloatingLabel
              controlId="floatingInput"
              label="اسم"
              style={{ direction: "rtl", left: "initial", right: 0 }}
              className="mb-3 floatingInputAr"

            >
              <Form.Control type="text" placeholder="اسم" readOnly value={data.data.NameAr} style={{ left: "%" }} />
            </FloatingLabel>

          </div>
        </div>

        <div className="row mainrow">
          <div className="col-sm">

            <FloatingLabel
              controlId="floatingInput"
              label="Color"
              className="mb-3"



            >
              <Form.Control type="text" placeholder="Description" readOnly value={color.NameEn} />
            </FloatingLabel>

            <span className="spanForm"> |</span>
          </div>

          <div className="col-sm">
            <FloatingLabel
              controlId="floatingInput"
              label=""
              style={{ direction: "rtl", left: "initial", right: 0 }}
              className="mb-3 floatingInputAr"

            >
              <Form.Control type="text" placeholder="اسم" readOnly value={color.NameAr} style={{ left: "%" }} />
            </FloatingLabel>

          </div>
        </div>

        <div className="row mainrow">
          <div className="col-sm">

            <FloatingLabel
              controlId="floatingInput"
              label="Breeder"
              className="mb-3"



            >
              <Form.Control type="text" placeholder="Description" readOnly value={breeder.NameEn} />
            </FloatingLabel>

            <span className="spanForm"> |</span>
          </div>

          <div className="col-sm">
            <FloatingLabel
              controlId="floatingInput"
              label=""
              style={{ direction: "rtl", left: "initial", right: 0 }}
              className="mb-3 floatingInputAr"

            >
              <Form.Control type="text" placeholder="اسم" readOnly value={breeder.NameAr} style={{ left: "%" }} />
            </FloatingLabel>

          </div>
        </div>

        <div className="row mainrow">
          <div className="col-sm">

            <FloatingLabel
              controlId="floatingInput"
              label="Gender"
              className="mb-3"



            >
              <Form.Control type="text" placeholder="Description" readOnly value={sex.NameEn} />
            </FloatingLabel>

            <span className="spanForm"> |</span>
          </div>

          <div className="col-sm">
            <FloatingLabel
              controlId="floatingInput"
              label="اسم"
              style={{ direction: "rtl", left: "initial", right: 0 }}
              className="mb-3 floatingInputAr"

            >
              <Form.Control type="text" placeholder="اسم" readOnly value={sex.NameAr} style={{ left: "%" }} />
            </FloatingLabel>

          </div>
        </div>
        <div className="row mainrow">
          <div className="col-sm">

            <FloatingLabel
              controlId="floatingInput"
              label="Remarks"
              className="mb-3"



            >
              <Form.Control type="text" placeholder="Description" readOnly value={data.data.RemarksEn} />
            </FloatingLabel>

            <span className="spanForm"> |</span>
          </div>
          <div className="col-sm">
            <FloatingLabel
              controlId="floatingInput"
              label="اسم"
              style={{ direction: "rtl", left: "initial", right: 0 }}
              className="mb-3 floatingInputAr"

            >
              <Form.Control type="text" placeholder="اسم" readOnly value={data.data.RemarksAr} style={{ left: "%" }} />
            </FloatingLabel>

          </div>
        </div>


        <div className="row mainrow">
          <div className="col-sm">

            <FloatingLabel
              controlId="floatingInput"
              label="Nationality"
              className="mb-3"



            >
              <Form.Control type="text" placeholder="Date Of Birth" readOnly value={nationality.NameEn} />
            </FloatingLabel>
            <span className="spanForm"> |</span>
          </div>
          <div className="col-sm">
            <FloatingLabel
              controlId="floatingInput"
              label="اسم"
              style={{ direction: "rtl", left: "initial", right: 0 }}
              className="mb-3 floatingInputAr"

            >
              <Form.Control type="text" placeholder="اسم" readOnly value={nationality.NameAr} style={{ left: "%" }} />
            </FloatingLabel>

          </div>
        </div>





        <div className="row mainrow">
          <div className="col-sm">

            <FloatingLabel
              controlId="floatingInput"
              label="Owner"
              className="mb-3"



            >
              <Form.Control type="text" placeholder="Date Of Birth" readOnly value={owner.NameEn} />
            </FloatingLabel>
            <span className="spanForm"> |</span>
          </div>
          <div className="col-sm">
            <FloatingLabel
              controlId="floatingInput"
              label="اسم"
              style={{ direction: "rtl", left: "initial", right: 0 }}
              className="mb-3 floatingInputAr"

            >
              <Form.Control type="text" placeholder="اسم" readOnly value={owner.NameAr} style={{ left: "%" }} />
            </FloatingLabel>

          </div>
        </div>
        <div className="row mainrow">
          <div className="col-sm">

            <FloatingLabel
              controlId="floatingInput"
              label="Trainer"
              className="mb-3"



            >
              <Form.Control type="text" placeholder="Date Of Birth" readOnly value={trainer.NameEn} />
            </FloatingLabel>
            <span className="spanForm"> |</span>
          </div>
          <div className="col-sm">
            <FloatingLabel
              controlId="floatingInput"
              label="اسم"
              style={{ direction: "rtl", left: "initial", right: 0 }}
              className="mb-3 floatingInputAr"

            >
              <Form.Control type="text" placeholder="اسم" readOnly value={trainer.NameAr} style={{ left: "%" }} />
            </FloatingLabel>

          </div>
        </div>

        <div className="row mainrow">
          <div className="col-sm">

            <FloatingLabel
              controlId="floatingInput"
              label="Dam"
              className="mb-3"



            >
              <Form.Control type="text" placeholder="Date Of Birth" readOnly value={dam.NameEn} />
            </FloatingLabel>
            <span className="spanForm"> |</span>
          </div>
          <div className="col-sm">
            <FloatingLabel
              controlId="floatingInput"
              label="اسم"
              style={{ direction: "rtl", left: "initial", right: 0 }}
              className="mb-3 floatingInputAr"

            >
              <Form.Control type="text" placeholder="اسم" readOnly value={dam.NameAr} style={{ left: "%" }} />
            </FloatingLabel>

          </div>
        </div>
        <div className="row mainrow">
          <div className="col-sm">

            <FloatingLabel
              controlId="floatingInput"
              label="Sire"
              className="mb-3"



            >
              <Form.Control type="text" placeholder="Date Of Birth" readOnly value={sire.NameEn} />
            </FloatingLabel>
            <span className="spanForm"> |</span>
          </div>
          <div className="col-sm">
            <FloatingLabel
              controlId="floatingInput"
              label="اسم"
              style={{ direction: "rtl", left: "initial", right: 0 }}
              className="mb-3 floatingInputAr"

            >
              <Form.Control type="text" placeholder="اسم" readOnly value={sire.NameAr} style={{ left: "%" }} />
            </FloatingLabel>

          </div>
        </div>
        <div className="row mainrow">
          <div className="col-sm">

            <FloatingLabel
              controlId="floatingInput"
              label="Grand Sire"
              className="mb-3"



            >
              <Form.Control type="text" placeholder="Date Of Birth" readOnly value={gsire.NameEn} />
            </FloatingLabel>
            <span className="spanForm"> |</span>
          </div>
          <div className="col-sm">
            <FloatingLabel
              controlId="floatingInput"
              label="اسم"
              style={{ direction: "rtl", left: "initial", right: 0 }}
              className="mb-3 floatingInputAr"

            >
              <Form.Control type="text" placeholder="اسم" readOnly value={gsire.NameAr} style={{ left: "%" }} />
            </FloatingLabel>

          </div>
        </div>
        <div className="row mainrow">
          <div className="col-sm">

            <FloatingLabel
              controlId="floatingInput"
              label="Purchase Price"
              className="mb-3"



            >
              <Form.Control type="text" placeholder="Date Of Birth" readOnly value={data.data.PurchasePrice} />
            </FloatingLabel>

          </div>

        </div>

        <div className="row mainrow">
          <div className="col-sm">

            <FloatingLabel
              controlId="floatingInput"
              label="Rds"
              className="mb-3"



            >
              <Form.Control type="text" placeholder="Date Of Birth" readOnly value={data.data.Rds} />
            </FloatingLabel>

          </div>

        </div>

        <div className="row mainrow">
          <div className="col-sm">

            <FloatingLabel
              controlId="floatingInput"
              label="Foal"
              className="mb-3"



            >
              <Form.Control type="text" placeholder="Date Of Birth" readOnly value={data.data.Foal} />
            </FloatingLabel>

          </div>

        </div>


        <div className="row mainrow">
                    <div className="col-sm">
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Short Code"
                            className="mb-3"

                        >
                            <Form.Control type="text" placeholder="Short Code" value={data.data.shortCode} readOnly />
                        </FloatingLabel>

                    </div>


                </div>

        <div className="row mainrow">
          <div className="col-sm">

            <FloatingLabel
              controlId="floatingInput"
              label="Date of Birth"
              className="mb-3"



            >
              <Form.Control type="text" placeholder="Date Of Birth" readOnly value={data.data.DOB} />
            </FloatingLabel>

          </div>

        </div>
      </div>
    </>
  );
};

export default HorsePopup;