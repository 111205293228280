import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";

import { Form, Input } from 'antd';
import Select from "react-select";

import { ImCross } from "react-icons/im";
import { fetchnationalityshortcode } from "../../redux/getShortCode/getnationalityshortcode";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../Components/Common/Loader";




//----------------------------options -------------------------//
const Hemisphere = [
  {
    id: "1",
    value: "Southern Hemisphere",
    label: (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <p>Southern Hemisphere</p>
        <p>نصف الكرة الجنوبي</p>
      </div>
    ),
  },
  {
    id: "2",
    value: "Northern Hemisphere",
    label: (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <p>Northern Hemisphere</p>
        <p>نصف الكرة الشمالي</p>
      </div>
    ),
  },
];

const Nationality = ({ setShowNationality }) => {
  const { data: nationalityshortcode } = useSelector(
    (state) => state.nationalityshortcode
  );
  //----------------------------state for errors -------------------------//


  const [isLoading, setisLoading] = useState(false);

  const [NameEn, setNameEn] = useState("");
  const [NameAr, setNameAr] = useState("");

  const [AbbrevEn, setAbbrevEn] = useState("");
  const [AbbrevAr, setAbbrevAr] = useState("");
  const [AltNameEn, setAltNameEn] = useState("");
  const [AltNameAr, setAltNameAr] = useState("");

  const [HemisphereEn, setHemisphereEn] = useState("");

  const [image, setImage] = useState();
  const [preview, setPreview] = useState();
  const dispatch = useDispatch();

  const history = useNavigate();
  const { pathname } = useLocation();

  const [state1, setState] = useState({
    shortCode: "",
  });
  //----------------------------shortcode -------------------------//
  useEffect(() => {
    if (nationalityshortcode) {
      setState({
        shortCode:
          nationalityshortcode.length === 0
            ? 10
            : nationalityshortcode[0].maxshortCode + 1,
      });
    } else {
      setState.shortCode("9");
    }
  }, [nationalityshortcode]);
  useEffect(() => {
    dispatch(fetchnationalityshortcode());
  }, [dispatch]);








  //----------------------------submit form -------------------------//
  const submit = async () => {

    setisLoading(true)
    try {
      setisLoading(true)
      const formData = new FormData();
      formData.append("NameEn", NameEn);
      formData.append("NameAr", NameAr);
      formData.append("shortCode", state1.shortCode);
      formData.append("AbbrevEn", AbbrevEn);
      formData.append("AbbrevAr", AbbrevAr);
      formData.append("AltNameEn", AltNameEn);
      formData.append("AltNameAr", AltNameAr);
      if (HemisphereEn.value) {

        formData.append("HemisphereEn", HemisphereEn.value);
      }
      formData.append("image", image);
      await axios.post(`${window.env.API_URL}/uploadNationality`, formData);

      if (pathname === "/nationality") {
        history("/nationalitylist");
      }
      swal({
        title: "Success!",
        text: "Data has been added Successfully ",
        icon: "success",
        button: "OK",
      });
      setShowNationality(false)
    } catch (error) {
      const err = error.response.data.message[0];
      const err1 = error.response.data.message[1];
      const err2 = error.response.data.message[2];
      const err3 = error.response.data.message[3];
      swal({
        title: "Error!",
        text: err,
        err1,
        err2,
        err3,
        icon: "error",
        button: "OK",
      });
      setisLoading(false)
    }


  };
  //----------------------------Image Preview -------------------------//
  useEffect(() => {
    if (!image) {
      setPreview(undefined);
      return;
    }
    if (image.name.match(/\.(gif)$/)) {
      setImage("");
      setPreview(undefined);
      document.getElementById("file").value = "";
      swal({
        title: "Error!",
        text: "Invalid Format",
        icon: "error",
        button: "OK",
      });
    }
    const objectUrl = URL.createObjectURL(image);
    setPreview(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [image]);

  const onSelectFile = (e) => {
    setImage(e.target.files[0])(image, "image");
  };

  const handlePreview = () => {
    setImage();
    document.getElementById("file").value = "";
  };












  return (
    <div className="page">
      <div className="rightsidedata">
        <div
          style={{
            marginTop: "30px",
          }}
        >
          <div className="Headers">Create Nationality</div>
          {isLoading ? <Loader /> : <div className="form">
            <Form onFinish={submit}
              fields={[{

                name: "shortCode",
                value: state1.shortCode

              }]}
            >
              <div className="row mainrow">
                <div className="col-sm">


                  <Form.Item
                    name="NameEn"
                    label="Name"
                    rules={[
                      {
                        required: true,
                        message: 'Name (English) is required',
                      },
                    ]}
                  >
                    <Input placeholder="Name" onChange={(e) => setNameEn(e.target.value)}
                      value={NameEn} name="NameEn" />
                  </Form.Item>



                  <span className="spanForm"> |</span>





                </div>

                <div className="col-sm">
                  <Form.Item
                    name="NameAr"
                    label="اسم"
                    rules={[
                      {
                        required: true,
                        message: 'Name (Arabic) is required',
                      },
                    ]}
                    style={{ direction: 'rtl' }}
                  >
                    <Input placeholder="اسم" onChange={(e) => setNameAr(e.target.value)}
                      name="Name"
                      value={NameAr} />
                  </Form.Item>

                </div>
              </div>




              <div className="row mainrow">
                <div className="col-sm">

                  <Form.Item
                    name="Abbreviation"
                    label="Abbreviation"
                    rules={[
                      {
                        required: true,
                        message: 'Abbreviation is required',
                      },
                    ]}
                  >
                    <Input placeholder="Abbreviation"

                      onChange={(e) => setAbbrevEn(e.target.value)}
                      value={AbbrevEn} />
                  </Form.Item>













                  <span className="spanForm"> |</span>





                </div>

                <div className="col-sm">
                  <Form.Item
                    name="اكتب الاختصار"
                    label="اكتب الاختصار"
                    rules={[
                      {
                        required: true,
                        message: 'Abbreviation (Arabic) is required',
                      },
                    ]}
                    style={{ direction: 'rtl' }}
                  >
                    <Input placeholder="اكتب الاختصار" onChange={(e) => setAbbrevAr(e.target.value)}
                      value={AbbrevAr}




                    />
                  </Form.Item>

                </div>
              </div>

              <div className="row mainrow">
                <div className="col-sm">


                  <Form.Item
                    name="AltNameEn"
                    label="AltNameEn"
                    rules={[
                      {
                        required: true,
                        message: 'AltNameEn (English) is required',
                      },
                    ]}
                  >
                    <Input placeholder="Alternative Name" label=" Alternative Name"

                      onChange={(e) => setAltNameEn(e.target.value)}
                      value={AltNameEn} name="AltNameEn" />
                  </Form.Item>







                  <span className="spanForm"> |</span>

                </div>

                <div className="col-sm">





                  <Form.Item
                    name="اكتب الاسم البديل"
                    label="متبادل"
                    rules={[
                      {
                        required: true,
                        message: 'Alternative Name (Arabic) is required',
                      },
                    ]}
                    style={{ direction: 'rtl' }}
                  >
                    <Input placeholder="اكتب الاسم البديل" onChange={(e) => setAltNameAr(e.target.value)}
                      value={AltNameAr}

                      style={{ direction: "rtl", left: "initial", right: 0 }}


                    />
                  </Form.Item>










                </div>
              </div>



              <div className="row mainrow">
                <div className="col-sm">
                  <Select
                    placeholder={<div>Select Hemisphere</div>}
                    defaultValue={Hemisphere}
                    onChange={setHemisphereEn}
                    options={Hemisphere}
                    isClearable={true}
                    isSearchable={true}
                  />


                </div>
              </div>
              <div className="row mainrow">
                <div className="col-sm">
                  <Form.Item
                    name="shortCode"
                    label="Short Code"
                    rules={[
                      {
                        required: true,
                        message: "short Code is required"
                      },
                    ]}
                  >
                    <Input
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(e) =>
                        setState({ ...state1, shortCode: e.target.value })
                      }
                      value={state1.shortCode}
                      onPaste={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      placeholder="Short Code"
                      maxLength="5"
                    />
                  </Form.Item> </div>
              </div>

              <div className="ButtonSection">
                <div>
                  <label className="Multipleownerlabel">
                    Select Nationality Flag image
                  </label>
                  <input
                    type="file"
                    onChange={onSelectFile}
                    className="formInput fileinputdata"
                    id="file"
                  />
                  {image && (
                    <>
                      <ImCross onClick={handlePreview} className="crossIcon" />
                      <img src={preview} className="PreviewImage" alt="" />
                    </>
                  )}
                </div>
                <div>
                  <button
                    type="submit"
                    className="SubmitButton"
                    disabled={isLoading}
                  >
                   Save
                  </button>
                </div>
              </div>
            </Form>
          </div>}
        </div>
      </div>
    </div>
  );
};

export default Nationality;
