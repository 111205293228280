import React, { useEffect, useState } from "react";
import { fetchPushNotificationTemplateget, STATUSES } from "../../redux/getReducer/getNotificationListing";
import { useDispatch, useSelector } from "react-redux";
import { MdDelete } from "react-icons/md";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import AdsPopup from "../../Components/Popup/AdsPopup";
import ScrollContainer from "react-indiana-drag-scroll";
import Lottie from "lottie-react";
import HorseAnimation from "../../assets/horselottie.json";
import { BiEdit } from "react-icons/bi";
import axios from "axios";
import swal from "sweetalert";
import { BsEyeFill } from "react-icons/bs";
import Pagination from "./Pagination";
import Form from "react-bootstrap/Form";
import { BiFilter } from "react-icons/bi";
import { CSVLink } from "react-csv";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Notfound from "../../Notfound";

const Ads = () => {
  const [Value, setValue] = useState(false);
  const [ShowCalender, setShowCalender] = useState(false);

  const [SearchTitle, setSearchTitle] = useState("");
  const [TotalCount, setTotalCount] = useState();
  const [TotalPages, setTotalPages] = useState("");

  //for Modal
  const [show, setShow] = useState(false);
  const [modaldata, setmodaldata] = useState();
  const handleClose = () => setShow(false);
  const handleShow = async (data) => {
    setmodaldata(data);
    await setShow(true);
  };
  const { data: PushNotificationTemplateget, status } = useSelector((state) => state.PushNotificationTemplateget);
 
  const history = useNavigate();
  const dispatch = useDispatch();



  //--------------------------api----------------------//

  let totalcountdata = PushNotificationTemplateget ? PushNotificationTemplateget?.length : "";
  const [postsPerPage] = useState(11);
  const totalpagesdata = Math.ceil(totalcountdata / postsPerPage);
  const [currentPage, setCurrentPage] = useState(1);
  const firstPageIndex = (currentPage - 1) * postsPerPage;
  const lastPageIndex = firstPageIndex + postsPerPage;
  const SliceData =  PushNotificationTemplateget?.slice(firstPageIndex, lastPageIndex);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);



  useEffect(() => {
    dispatch(fetchPushNotificationTemplateget())
  },[dispatch])
  //--------------------------delete----------------------//
  const handleRemove = async (Id) => {
    try {
      swal({
        title: "Are you sure?",
        text: "do you want to delete this data ?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          await axios.delete(`${window.env.API_URL}/softdeleteAds/${Id}`);
          swal("Your data has been deleted Successfully!", {
            icon: "success",
          });
          dispatch(fetchPushNotificationTemplateget());
        } else {
          swal("Your data is safe!");
        }
      });
    } catch (error) {
      const err = error.response.data.message;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
    }
  };

  if (status === STATUSES.ERROR) {
    return (
      <h2
        style={{
          margin: "100px",
        }}
      >
        Something went wrong!
      </h2>
    );
  }

  return (
    <>
      <div className="page">
        <div className="rightsidedata">
          <div
            style={{
              marginTop: "30px",
            }}
          >
            <>
              <div className="Header ">
                <h4>Notification Listings</h4>
                <div>
                {/* <Link to="/AddNotification">
                    <button>Create Notification</button>
                  </Link> */}
                </div>
              </div>
           
              <div className="div_maintb">
                <ScrollContainer className="scroll-container">
                  <table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Action</th>
                        <th>Notification Template Name </th>
                        <th>Notification Template Title</th>
                        <th>Notification Template TitleAr </th>
                        <th>Notification Template Text </th>
                        <th>Notification Template TextAr </th>
                        <th>Image</th>

                      </tr>
                    </thead>
                    {
                      <tbody>
                        {status === STATUSES.LOADING ? (
                          <Lottie
                            animationData={HorseAnimation}
                            loop={true}
                            className="TableLottie"
                          />
                        ) : (
                          SliceData.length ? SliceData.map((item, index) => {
                            return (
                              <>
                                <tr className="tr_table_class">
                                  <td className="table_delete_btn1">
                                    <BiEdit
                                      onClick={() =>
                                        history("/editnotification", {
                                          state: {
                                            notoficationid: item,
                                          },
                                        })
                                      }
                                    />

                                    {/* <MdDelete
                                      style={{
                                        fontSize: "22px",
                                      }}
                                      onClick={() => handleRemove(item._id)}
                                    /> */}
                                    {/* <BsEyeFill
                                      onClick={() => handleShow(item)}
                                    /> */}
                                  </td>
                                  <td>{item.NotificationTemplateName}</td>
                                  <td>{item.NotificationTemplateTitleEn}</td>
                                  <td>{item.NotificationTemplateTitleAr}</td>
                                  <td>{item.NotificationTemplateTextEn}</td>
                                  <td>{item.NotificationTemplateTextAr}</td>
                                  

                                  <td>
                                    <img src={item.NotificationImage} alt="" />
                                  </td>
                                  
                                </tr>
                              </>
                            );
                          }) : <Notfound />


                        )}
                      </tbody>
                    }
                  </table>
                </ScrollContainer>
              </div>
            </>
          </div>
          <Pagination
                postsPerPage={postsPerPage}
                totalPosts={totalcountdata}
                paginate={paginate}
                currentPage={currentPage}
                TotalPages={totalpagesdata}
              />
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2 style={{ fontFamily: "inter" }}>Advertisement </h2>
        </Modal.Header>
        <Modal.Body>
          <AdsPopup data={modaldata} />
        </Modal.Body>
        <Modal.Footer>
          <button onClick={handleClose} className="modalClosebtn">
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default Ads;
