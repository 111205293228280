import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import swal from "sweetalert";
import axios from "axios";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchcategory } from "../../../redux/getReducer/getCategory";
import { AiOutlineReload } from "react-icons/ai";
import SponsorPopup from "../../PostTable/SponsorForm";
import { fetchSponsorDropdown } from "../../../redux/getDropDownReducer/getSponsor";
import CategoryPopup from "../../PostTable/Competition/AddCategory";
import { Form, Input, DatePicker, Select } from "antd";



const EditComptetion = () => {
  const CategoryType = [
    {
      id: 1,
      value: "pick",
      label: "pick",
    },
    {
      id: 1,
      value: "cast",
      label: "Cast",
    },
  ];
  const history = useNavigate();
  const dispatch = useDispatch()
  const { state } = useLocation();
  const [Sponsors, setSponsor] = useState("")
  const [form] = Form.useForm();
  const [SearchAge, setSearchAge] = useState("");
  const [categoryName, setcategoryName] = useState("");

  const [CompetitionCategory, setCompetitionCategory] = useState("")
  const [showCategory, setShowCategory] = useState(false);
  const handleCloseCategory = () => setShowCategory(false);
  const handleShowCategory = async () => {
    await setShowCategory(true);
  };
  const [Type, setType] = useState("");
  const { data: sponsordropdown } = useSelector((state) => state.sponsordropdown);

  console.log(CompetitionCategory,"CompetitionCategory")
  const { data: category } = useSelector((state) => state.category);
  const [showSponsor, setShowSponsor] = useState(false);
  const handleCloseSponsor = () => setShowSponsor(false);
  const handleShowSponsor = async () => {
    await setShowSponsor(true);
  };
  // const [Sponsor, setSponsor1] = useState("");
  const [NumberOfRace, setNumberOfRace] = useState("");

  const { competitionid } = state;

  const [StartDate, setStartDate] = useState("");
  const [EndDate, setEndDate] = useState("");
  const { Option } = Select;
  
  const [state1, setState] = useState({
    NameAr: "",
    NameEn: "",
    DescEn: "",
    DescAr: "",
    CategoryCount: "",
    CompetitionCategory: "",
    StartDate: "",
    EndDate: " ",
    CompetitionCode: "",
    Sponsor: "",
    shortCode: "",
    sponsorName:"",
    CompetitionTypeData:"",
    CompetitionTypeDataName:""
  });
  const FetchNew = () => {
    dispatch(fetchcategory());
    dispatch(fetchSponsorDropdown());
  };

  console.log(competitionid)
  //----------------------------get Data -------------------------//
  useEffect(() => {

    dispatch(fetchcategory());
    dispatch(fetchSponsorDropdown());

    if (competitionid) {
      setState({
        NameEn: competitionid.NameEn,
        NameAr: competitionid.NameAr,
        CategoryCount: competitionid.CategoryCount,
        StartDate: competitionid.StartDate,
        CompetitionCode: competitionid.CompetitionCode,
        shortCode: competitionid.shortCode,
        CompetitionCategory: competitionid.CompetitionCategory,
        EndDate: competitionid.EndDate,
        Sponsor: competitionid.CompetitionSponsorData._id,
        sponsorName: competitionid.CompetitionSponsorData.TitleEn,
        CompetitionTypeData:competitionid.CompetitionTypeData._id,
        CompetitionTypeDataName:competitionid.CompetitionTypeData.NameEn
      });

      setcategoryName(state1.CompetitionCategory)
      let day123 = new Date(competitionid.StartDate);
      let daydate = day123.getDate();
      if (daydate < 10) {
        daydate = `0${day123.getDate()}`;
      }
      let daymonth = day123.getMonth() + 1;
      if (daymonth < 10) {
        daymonth = `0${day123.getMonth() + 1}`;
      }
      let dayformat = `${day123.getFullYear()}-${daymonth}-${daydate}`;


      setStartDate(dayformat);


      let day456 = new Date(competitionid.EndDate);
      let daydate1 = day456.getDate();
      if (daydate1 < 10) {
        daydate1 = `0${day456.getDate()}`;
      }
      let daymonth1 = day456.getMonth() + 1;
      if (daymonth1 < 10) {
        daymonth1 = `0${day456.getMonth() + 1}`;
      }
      let dayformat1 = `${day456.getFullYear()}-${daymonth1}-${daydate1}`;

      setEndDate(dayformat1);
    } else {
    }
  }, [competitionid, dispatch, state1.CompetitionCategory]);

 
  //----------------------------submit -------------------------//

 
  const handleEditRace = async (event) => {
    try {
      const formData = new FormData();
      formData.append("NameEn", state1.NameEn);
      formData.append("NameAr", state1.NameAr);

      formData.append("CategoryCount", state1.CategoryCount);
      formData.append("CompetitionSponsor", Sponsors ? Sponsors : state1.Sponsor);

      // formData.append("CompetitionType", state1.CompetitionTypeData);

      formData.append("CompetitionCategory", CompetitionCategory === undefined ? state1.CompetitionCategory : CompetitionCategory);
      formData.append("StartDate", StartDate);
      formData.append("EndDate", EndDate);
      formData.append("CompetitionCode", state1.CompetitionCode);
      formData.append("shortCode", state1.shortCode);

      await axios.put(
        `${window.env.API_URL}/updateCompetiton/${competitionid._id}`,
        formData
      );
      // history("/competitionrace", {
      //   state: {
      //     CompetitionId: competitionid._id,
      //   },
      // });
      history("/editcompetitionrace", {
        state: {
          compid: competitionid,
        },
      })

      swal({
        title: "Success!",
        text: "Data has been Updated successfully ",
        icon: "success",
        button: "OK",
      });
    } catch (error) {
      const err = error.response.data.message;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
    }
  };

  const submit = async (event) => {
    // event.preventDefault();
    try {
      const formData = new FormData();
      formData.append("NameEn", state1.NameEn);
      formData.append("NameAr", state1.NameAr);

      formData.append("CategoryCount", state1.CategoryCount);
      formData.append("CompetitionSponsor", Sponsors ? Sponsors : state1.Sponsor);
      // formData.append("CompetitionType", state1.CompetitionTypeData);

      formData.append("CompetitionType", CompetitionCategory === undefined ? state1.CompetitionCategory : CompetitionCategory);
      formData.append("StartDate", StartDate);
      formData.append("EndDate", EndDate);
      formData.append("CompetitionCode", state1.CompetitionCode);
      formData.append("shortCode", state1.shortCode);

      await axios.put(
        `${window.env.API_URL}/updateCompetiton/${competitionid._id}`,
        formData
      );
      // history("/competitionrace", {
      //   state: {
      //     CompetitionId: competitionid._id,
      //   },
      // });
      history("/editcompetitionrace", {
        state: {
          compid: competitionid,
        },
      })
      swal({
        title: "Success!",
        text: "Data has been Updated successfully ",
        icon: "success",
        button: "OK",
      });
    } catch (error) {
      const err = error.response.data.message;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
    }
  };

  const handleDateChange = (date, dateString) => {
    setStartDate(dateString);
  };

  const handleDateChange1 = (date, dateString) => {
    setEndDate(dateString);
  };


  
  const handleSearch = async (value) => {
    setSearchAge(value);
  };

  const handleChange = async (value) => {
    await setCompetitionCategory(value);
    setState({ ...state1, CompetitionTypeDataName:value })
  };

  const handleChange1 = async (value) => {
    await  setSponsor(value);
     setState({ ...state1, sponsorName:value })

  };

  const handleChange2 = async (value) => {
    await setType(value);
         setState({ ...state1, CompetitionCategory:value })
  };


  form.setFieldsValue({
    NameEn: state1.NameEn,
    NameAr: state1.NameAr,
    CategoryCount: state1.CategoryCount,
    StartDate: state1.StartDate,
    CompetitionCode: state1.CompetitionCode,
    shortCode: state1.shortCode,
    EndDate: state1.EndDate,
    Sponsor: state1.CompetitionSponsorData,
    Type: state1.CompetitionCategory,
    sponsorName:state1.sponsorName,
    CompetitionTypeData:state1.CompetitionTypeData,
    CompetitionTypeDataName:state1.CompetitionTypeDataName
  });

  return (
    <>
      <div className="page">
        <div className="rightsidedata">
          <div
            style={{
              marginTop: "30px",
            }}
          >
            <div className="Headers">Edit Competition</div>
            <div className="form">
            <Form onFinish={submit} form={form}>
                  <div className="row mainrow">
                    <div className="col-sm">
                      <Form.Item
                        name="NameEn"
                        label="Name"
                        rules={[
                          {
                            required: true,
                            message: "Name (English) is required",
                          },
                        ]}
                      >
                        <Input placeholder="Name" 
                        onChange={(e) =>
                        setState({ ...state1, NameEn: e.target.value })
                      }
                        value={state1.NameEn} name="NameEn" />
                      </Form.Item>

                      <span className="spanForm spacer"> |</span>
                    </div>
                    <div className="col-sm">
                      <Form.Item
                        name="NameAr"
                        label="اسم"
                        rules={[
                          {
                            required: true,
                            message: "Name (Arabic) is required",
                          },
                        ]}
                        style={{ direction: "rtl" }}
                      >
                        <Input
                          placeholder="اسم"
                          onChange={(e) =>
                            setState({ ...state1, NameAr: e.target.value })
                          }
                          name="NameAr"
                          value={state1.NameAr}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="row mainrow">
                    <div className="col-sm">
                      <Form.Item
                        name="CompetitionCode"
                        label="Code"
                        rules={[
                          {
                            required: true,
                            message: "Code (English) is required",
                          },
                        ]}
                      >
                       <Input placeholder="Competition Code" onChange={(e) =>
                        setState({ ...state1, CompetitionCode: e.target.value })
                      }
                        value={state1.CompetitionCode} name="NameEn" />
                      </Form.Item>

                    </div>
                  </div>

                  <div className="row mainrow">
                    <p className="dateLabel">Start Date</p>
                    <input
                      type="date"
                      data-placeholder="Start Date"
                      onChange={(e) => setStartDate(e.target.value)}
                      value={StartDate}
                      className="dateforraces"
                      required
                    />
                    

                    <div className="col-sm" style={{ direction: "rtl" }}></div>
                  </div>

                  <div className="row mainrow">
                    <p className="dateLabel">End Date</p>
                    <input
                      type="date"
                      data-placeholder="End Date"
                      onChange={(e) => setEndDate(e.target.value)}
                      value={EndDate}
                      className="dateforraces"
                      required
                    />
                 
                
                  

                    <div className="col-sm" style={{ direction: "rtl" }}></div>
                  </div>

                  <div className="row mainrow">
                    <div className="col-sm">
                      <Form.Item
                        name="CompetitionTypeDataName"
                        label="Category"
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: "Please select your Category!",
                          },
                        ]}
                      >
                        <Select
                        defaultValue={state1.CompetitionTypeDataName}
                          value={CompetitionCategory}
                          filterOption={false}

                          onChange={handleChange}
                          showSearch
                          placeholder="Please select a Category"
                        >
                          {category?.map((item) => (
                            <Option value={item._id}>{item.NameEn}</Option>
                          ))}
                        </Select>
                      </Form.Item>

                      <span className="spanForm spacer">
                        <OverlayTrigger
                          overlay={
                            <Tooltip id={`tooltip-top`}>Add more</Tooltip>
                          }
                        >
                          <span
                            className="addmore"
                            onClick={handleShowCategory}
                          >
                            +
                          </span>
                        </OverlayTrigger>
                        <OverlayTrigger
                          overlay={
                            <Tooltip id={`tooltip-top`}>Fetch New</Tooltip>
                          }
                        >
                          <span className="addmore" onClick={FetchNew}>
                            <AiOutlineReload />
                          </span>
                        </OverlayTrigger>{" "}
                      </span>
                    </div>
                  </div>

                  <div className="row mainrow">
                    <div className="col-sm">
                      <Form.Item
                        name="sponsorName"
                        label="SponsorName"
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: "Please select your Sponsor!",
                          },
                        ]}
                      >
                        <Select
                          value={Sponsors}
                          defaultValue={state1?.sponsorName}
                          onSearch={handleSearch}
                          filterOption={false}
                          onChange={handleChange1}
                          showSearch
                          placeholder="Please select a Sponsor"
                        >
                         {sponsordropdown.map((item) => (
                            <Option key={item._id} value={item._id}>
                              <div style={{ display: "flex", alignItems: "center" }}>
                                <img
                                  src={item.image}
                                  alt={item.image}
                                  style={{ width: "24px", height: "24px", marginRight: "8px" }}
                                />
                                {item.TitleEn} ({item.TitleAr})
                              </div>
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>

                      <span className="spanForm spacer">
                        <OverlayTrigger
                          overlay={
                            <Tooltip id={`tooltip-top`}>Add more</Tooltip>
                          }
                        >
                          <span className="addmore" onClick={handleShowSponsor}>
                            +
                          </span>
                        </OverlayTrigger>
                        <OverlayTrigger
                          overlay={
                            <Tooltip id={`tooltip-top`}>Fetch New</Tooltip>
                          }
                        >
                          <span className="addmore" onClick={FetchNew}>
                            <AiOutlineReload />
                          </span>
                        </OverlayTrigger>{" "}
                      </span>
                    </div>
                  </div>

                  {/* <div className="row mainrow">
                    <div className="col-sm">
                      <Form.Item
                        name="Type"
                        label="Type"
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: "Please select your Type!",
                          },
                        ]}
                      >
                        <Select
                          value={Type}
                          defaultValue={state1?.CompetitionCategory}
                          filterOption={false}
                          onChange={handleChange2}
                          showSearch
                          placeholder="Please select a Type"
                        >
                          {CategoryType?.map((item) => (
                            <Option value={item.value}>{item.label}</Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                  </div> */}
 <div className="row mainrow">
                        <div className="col-sm">
                          <Form.Item
                            name="CategoryCount"
                            label="No of Races in Competition"
                            rules={[
                              {
                                required: true,
                                message: "No of Races is required",
                              },
                            ]}
                          >
                            <Input
                              placeholder="No of Races"
                              type="number"
                              onChange={(e) =>
                                setState({ ...state1, CategoryCount: e.target.value })
                              }
                                value={state1.CategoryCount}
                              name="Code"
                            />
                          </Form.Item>
                        </div>
                      </div>
                  <div
                    className="ButtonSection "
                    style={{ justifyContent: "end" }}
                  >
                    <div className="sbmtbtndiv">
                      <div className="RaceButtonDiv">
                        <button
                          className="SubmitButton"
                          type="submit"                         
                        >
                          Add Races
                        </button>
                      </div>
                    </div>

                     
                
                  </div>

                 


                </Form>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showSponsor}
        onHide={handleCloseSponsor}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2>Sponsor</h2>
        </Modal.Header>
        <Modal.Body>
          <SponsorPopup setShowSponsor={setShowSponsor} />
        </Modal.Body>
      </Modal>
      <Modal
        show={showCategory}
        onHide={handleCloseCategory}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2>Competition Category</h2>
        </Modal.Header>
        <Modal.Body>
          <CategoryPopup setShowCategory={setShowCategory} />
        </Modal.Body>
      </Modal>
    </>

  );
};

export default EditComptetion;
