import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});

const getEquipmentDropDownSlice = createSlice({
    name: 'equipmentdropdown',
    initialState: {
        data: [],
        status: STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchequipmentdropdown.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(fetchequipmentdropdown.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.IDLE
            })
            .addCase(fetchequipmentdropdown.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
    }
});

export const { setEquipment, setStatus } = getEquipmentDropDownSlice.actions;
export default getEquipmentDropDownSlice.reducer;

export const fetchequipmentdropdown = createAsyncThunk('/Equipmentget/fetch', async () => {
    const res = await axios.get(`${window.env.API_URL}/Equipmentget?size=800`);
    const equipmentData = res.data;
    return equipmentData.data;
})