import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { remove } from "../../../redux/postReducer/postRace";
import "../../../Components/CSS/Table.css";
import ScrollContainer from "react-indiana-drag-scroll";
import "../../../Components/CSS/race.css";
import { Modal } from "react-bootstrap";
import { MdDelete } from "react-icons/md";
import swal from "sweetalert";
import { DateRangePicker } from "react-date-range";
import Select from "react-select";
import { fetchRaceName } from "../../../redux/getReducer/getRaceName";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { BiFilter } from "react-icons/bi";
import {
  fetchraceresult,
  STATUSES,
} from "../../../redux/getReducer/getRaceResultAnnounced";
import Lottie from "lottie-react";
import HorseAnimation from "../../../assets/horselottie.json";
import Form from "react-bootstrap/Form";

import Notfound from "../../../Notfound";
import Pagination from "../../GetTable/Pagination";
import { BiEdit } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Moment from "react-moment";

const Prize = (data) => {
  const slider = document.querySelector(".horizontal-scroll-wrapper");
  let isDown = false;
  let startX;
  let scrollLeft;
  if (slider) {
    slider.addEventListener("mousedown", (e) => {
      isDown = true;
      slider.classList.add("active");
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    });

    slider.addEventListener("mouseleave", () => {
      isDown = false;
      slider.classList.remove("active");
    });
    slider.addEventListener("mouseup", () => {
      isDown = false;
      slider.classList.remove("active");
    });
    slider.addEventListener("mousemove", (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 3;
      slider.scrollLeft = scrollLeft - walk;

    });
  }
  function distanceLabel(distance, rank) {
    const DistanceTobeChecked = parseFloat(distance);
    console.log(DistanceTobeChecked);
    if (DistanceTobeChecked.toFixed(2) > 25) {
      return "Dist";
    } else if (DistanceTobeChecked.toFixed(2) == 0.05 && rank < 80) {
      return "SHd";
    } else if (DistanceTobeChecked.toFixed(2) == 0.10 && rank < 80) {
      return "Hd";
    } else if (DistanceTobeChecked.toFixed(2) == 0.25 && rank < 80) {
      return "Nk";
    } else if (DistanceTobeChecked.toFixed(2) == 0.01 && rank < 80) {
      return "Ns";
    } else if (DistanceTobeChecked.toFixed(2) == 0.00 && rank < 80) {
      if(rank === 1){
        return 0;
      }
      return "Dh";
    } else {
      return distance;
    }
  }


  return (
    <>
      <h4
        style={{
          textAlign: "center",
          justifyContent: "center",
          textDecoration: "underline",
        }}
      >
        {" "}
        {data.data.RaceResultData === undefined ||
          data.data.RaceResultData.length === 0 ? (
          <></>
        ) : (
          <>
            <p> Total Race Time : {data.data.RaceResultData[0].RaceTime}</p>
            <p>
              Best Turn Out Horse :{" "}
              {data.data.RaceResultData[0].BestTurnOutData ? (
                data.data.RaceResultData[0].BestTurnOutData.NameEn
              ) : (
                <></>
              )}
            </p>
            <p>
              Best Turn Out Prize :{" "}
              {data.data.RaceResultData[0].BestTurnPrice ? (
                data.data.RaceResultData[0].BestTurnPrice
              ) : (
                <></>
              )}
            </p>
          </>
        )}
      </h4>
      <div className="tableresultclass">
        <ScrollContainer className="scroll-container">
          <table className="Prizeclass">
            <thead className="Prizeclassthead Prizeclass">
              <tr style={{
                padding: '15px'
              }}>
                <th>Final Position </th>

                {/* <th >Rank </th> */}
                <th>Horse </th>
                <th>Distance</th>
                <th style={{
                  padding: '15px'
                }} >Cumulative </th>
                <th>Prize Win </th>
                <th>BeatenBy </th>
                <th>Rating </th>
                <th>Remarks </th>
              </tr>
            </thead>
            <tbody className="Prizeclasstbody Prizeclass">
              {data.data.RaceResultData === undefined ||
                data.data.RaceResultData.length === 0 ? (
                <></>
              ) : (
                data.data.RaceResultData.map((item) => {
                  return (
                    <>
                      <tr></tr>

                      <tr>
                        <td>
                          {item.FinalPositionDataHorse === null ? (
                            <></>
                          ) : (
                            item.FinalPositionDataHorse.NameEn
                          )}
                        </td>
                        {/* <td>
                          {item.FinalPositionDataHorse === null ? (
                            <></>
                          ) : (
                            item.FinalPositionDataHorse.Rank
                          )}
                        </td> */}
                        <td>
                          {item.HorseIDData === null ? (
                            <></>
                          ) : (
                            item.HorseIDData.NameEn
                          )}
                        </td>
                        <td>{distanceLabel(item.Distance ,  item.FinalPositionDataHorse?.Rank)}</td>
                        <td>
                          {item.CumulativeDistance === null ? (
                            <></>
                          ) : (
                            item.CumulativeDistance
                          )}
                        </td>

                        <td>{item.PrizeWin === null ? <></> : item.PrizeWin}</td>
                        <td>
                          {item.BeatenByData === null ? (
                            <></>
                          ) : (
                            item.BeatenByData.NameEn
                          )}
                        </td>
                        <td>{item.Rating === null ? <></> : item.Rating}</td>
                        <td>{item.RaceTime === null ? <></> : item.Remarks}</td>
                      </tr>
                    </>
                  );
                })
              )}
            </tbody>
          </table>
        </ScrollContainer>
      </div>
    </>
  );
};


const Races = () => {
  const dispatch = useDispatch();

  const { data: raceresult, status } = useSelector((state) => state.raceresult);
  const [ShowCalender, setShowCalender] = useState(false);
  const { data: RaceName } = useSelector((state) => state.RaceName);

  const { userInfo } = useSelector((state) => state.user);


  const [TotalCount, setTotalCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [SearchRaceName, setRaceName] = useState('');
  const SearchCode = "";
  const SearchTitle = "";
  const SearchAge = "";
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const TotalPages = raceresult.totalPages;
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const [StatusData, setStatusData] = useState(false);

  const totalPage = Math.round(TotalPages);

  const [postsPerPage] = useState(4);


  const [show, setShow] = useState(false);
  const [modaldata, setmodaldata] = useState();
  const handleClose = () => setShow(false);
  const handleShow = async (data) => {
    setmodaldata(data);
    await setShow(true);
  };
  const history = useNavigate();
  const racenameid = SearchRaceName === null ? "" : SearchRaceName.id;

  useEffect(() => {
    dispatch(fetchraceresult({ racenameid, currentPage }));
  }, [dispatch, currentPage]);

  function convertDateFormat(dateString) {
    // Create a new Date object from the given date string
    var date = new Date(dateString);

    // Get the individual components of the date
    var year = date.getFullYear();
    var month = ("0" + (date.getMonth() + 1)).slice(-2);
    var day = ("0" + date.getDate()).slice(-2);
    var hours = ("0" + date.getHours()).slice(-2);
    var minutes = ("0" + date.getMinutes()).slice(-2);
    var seconds = ("0" + date.getSeconds()).slice(-2);

    // Assemble the converted date format
    var convertedDate = year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;

    // Return the converted date format
    return convertedDate;
  }

  let startdate1 = !StatusData ? '' : convertDateFormat(state[0].startDate);
  let enddate1 = !StatusData ? '' : convertDateFormat(state[0].endDate);


  useEffect(() => {
    dispatch(fetchRaceName({ SearchCode, SearchTitle, SearchAge }));
  }, [SearchAge, SearchCode, SearchTitle, dispatch]);

  let Racenameoptions =
    RaceName === undefined ? (
      <></>
    ) : (
      RaceName.map(function (item) {
        return {
          id: item._id,
          value: item.NameEn,
          label: (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <p>{item.NameEn}</p>
            </div>
          ),
        };
      })
    );

  const GetSearch = () => {
    dispatch(fetchraceresult({ racenameid, currentPage, startdate1, enddate1 }));
    setCurrentPage(1);
  };


  const handleRemove = async (Id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        dispatch(remove(Id));
        await swal("Poof! Your imaginary file has been deleted!", {
          icon: "success",
        });
        dispatch(fetchraceresult({ racenameid, currentPage, startdate1, enddate1 }));

      } else {
        swal("Your Data is safe!");
      }
    });
  };
  if (status === STATUSES.ERROR) {
    return (
      <h2
        style={{
          margin: "auto",
        }}
      >
        No Result Found
      </h2>
    );
  }

  let racetime = raceresult.data ? raceresult.data.map((item) => {
    return (item.StartTime);
  }) : <></>;
  const handleStatus = async () => {
    setStatusData(!StatusData);

  };

  return (
    <>
      <div className="page">
        <div className="rightsidedata">
          <div
            style={{
              marginTop: "30px",
            }}
          >
            <div className="Header ">
              <h4>Result Announced</h4>
              <div>
                <div>
                  <OverlayTrigger
                    overlay={<Tooltip id={`tooltip-top`}>Filter</Tooltip>}
                  >
                    <span className="addmore">
                      <BiFilter
                        className="calendericon"
                        onClick={() => setShowCalender(!ShowCalender)}
                      />
                    </span>
                  </OverlayTrigger>{" "}
                </div>
              </div>
            </div>
            <div>
              {ShowCalender ? (
                <>
                  <div className="userfilter userFilter">
                    <div className="calenderuser">
                      <DateRangePicker
                        onChange={(item) => setState([item.selection])}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={2}
                        ranges={state}
                        direction="horizontal"
                      />
                    </div>

                    <div className="filtertextform filterTextForm">
                      <div className="">
                        <p>Active Calender</p>
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          onChange={() => handleStatus()}
                          defaultChecked={StatusData}
                        />
                      </div>
                      <div className="searchDropdown">
                        <Select
                          placeholder={<div>Race Name</div>}
                          defaultValue={SearchRaceName}
                          onChange={setRaceName}
                          options={Racenameoptions}
                          isClearable={true}
                          isSearchable={true}
                        />
                      </div>
                    </div>

                    <div className="filtertextform  "></div>
                  </div>
                  <button className="filterbtn filterBtn" onClick={GetSearch}>
                    Apply Filter
                  </button>
                </>
              ) : (
                <></>
              )}
            </div>
            <div className="div_maintb">
              <ScrollContainer className="scroll-container">
                <table className="Sc">
                  <thead
                    style={{
                      marginTop: "30px",
                    }}
                  >
                    <tr className="trtabletd">
                      <th>Action</th>
                      <th>Race Name</th>
                      <th>Race No</th>
                      <th>Date</th>
                      <th>Time</th>
                      <th>Winner</th>
                    </tr>
                  </thead>
                  {raceresult.data === [] ? (
                    <Notfound />
                  ) : (
                    <>
                      {status === "loading" ? (
                        <Lottie
                          animationData={HorseAnimation}
                          loop={true}
                          className="TableLottie"
                        />
                      ) : (
                        <tbody
                          // key={raceresult._id}
                          style={{
                            marginTop: "20px",
                          }}
                        >
                          {raceresult.data && raceresult.data.map((item, index) => {
                            return (
                              <tr>
                                <td className="table_delete_btn1">
                                  <MdDelete
                                    style={{
                                      fontSize: "22px",
                                    }}
                                    onClick={() => handleRemove(item._id)}
                                  />
                                  {
                                    item?.RaceDataOfCompetition?.length == 0 ? <BiEdit
                                    onClick={() =>
                                      history("/editresult", {
                                        state: {
                                          RaceId: item._id,
                                        },
                                      })
                                    }
                                  /> : <BiEdit
                                  className="editcomp1"
                                />
                                  }
                                  
                                  {/* <BsEyeFill /> */}
                                </td>

                                <td>
                                  {item.RaceNameModelData === null ||
                                    item.RaceNameModelData === undefined ? (
                                    <>N/A</>
                                  ) : (
                                    item.RaceNameModelData.NameEn
                                  )}{" "}
                                </td>
                                <td>
                                  {item.RaceNumber === null ||
                                    item.RaceNumber === undefined ? (
                                    <>N/A</>
                                  ) : (
                                    item.RaceNumber
                                  )}{" "}
                                </td>

                                <td>
                                  <Moment format="YYYY/MM/DD">
                                    {item.StartTime}
                                  </Moment>
                                </td>
                                <td>
                                  {new Date(racetime[index]).toLocaleTimeString()}
                                </td>
                                <td>
                                  <button
                                    className="Approvedbtn resultbtn"
                                    onClick={() => handleShow(item)}
                                  >
                                    Click
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      )}
                    </>
                  )}
                </table>
              </ScrollContainer>
            </div>
          </div>
          <Pagination
            postsPerPage={postsPerPage}
            totalPosts={TotalCount}
            paginate={paginate}
            currentPage={currentPage}
            TotalPages={TotalPages}
          />
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className=""
      >
        <Modal.Header closeButton>
          <h2>Race Result </h2>
        </Modal.Header>
        <Modal.Body>
          <Prize data={modaldata} />
        </Modal.Body>
      </Modal>
    </>
  );
};


export default Races;