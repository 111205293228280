import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});

const getBreederListSlice = createSlice({
    name: 'BreederList',
    initialState: {
        data: [],
        status: STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchBreederList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(fetchBreederList.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.IDLE
            })
            .addCase(fetchBreederList.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
    }
});

export const { setHorseList, setStatus } = getBreederListSlice.actions;
export default getBreederListSlice.reducer;

export const fetchBreederList = createAsyncThunk('/BreederListget/fetch', async ({ Value3 }) => {
    const res = await axios.get(`${window.env.API_URL}/BreederDropDown?NameEn=${Value3 === undefined ? '' : Value3}&limit=50`);
    const BreederListData = res.data;
    return BreederListData.data;
})
