import React, { useEffect, Fragment, useState } from "react";
import { fetchcurrency, STATUSES } from "../../redux/getReducer/getCurrency";
import { useDispatch, useSelector } from "react-redux";
import { MdAutoDelete, MdDelete } from "react-icons/md";
import { BiEdit } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import ScrollContainer from "react-indiana-drag-scroll";
import Lottie from "lottie-react";
import HorseAnimation from "../../assets/horselottie.json";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { BsEyeFill } from "react-icons/bs";
import CurrencyPopup from "../../Components/Popup/CurrencyPopup";
import Pagination from "./Pagination";
import { BiFilter } from "react-icons/bi";
import { CSVLink } from "react-csv";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Notfound from "../../Notfound";


const CurrencyTable = () => {
  const [ShowCalender, setShowCalender] = useState(false);
  const [SearchCode, setSearchCode] = useState('');
  const [SearchTitle, setSearchTitle] = useState('');
  const [TotalCount, setTotalCount] = useState()
  const [TotalPages, setTotalPages] = useState('');
  const [show, setShow] = useState(false);
  const [modaldata, setmodaldata] = useState();
  const { userInfo } = useSelector((state) => state.user);

  const handleClose = () => setShow(false);
  const handleShow = async (data) => {
    setmodaldata(data);
    await setShow(true);
  };

  const dispatch = useDispatch();
  const history = useNavigate();
  const { data: currency, status } = useSelector((state) => state.currency);

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(8);
  const [Loading, setLoading] = useState(false);


  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const GetSearch = async () => {
    dispatch(fetchcurrency({ SearchTitle, SearchCode }));


  };
  //--------------------------api----------------------//
  const Data1 = async () => {
    const res = await axios.get(`${window.env.API_URL}/Currencyget?shortCode=${SearchCode}&NameEn=${SearchTitle}&page=${currentPage}`);
    const totalcount = res.data.totalcount;
    setTotalCount(totalcount);
    const TotalPagesData = res.data.totalPages;
    setTotalPages(TotalPagesData)

  };

  useEffect(() => {
    dispatch(fetchcurrency({ SearchTitle, SearchCode, currentPage }));
    Data1()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, currentPage]);
  //--------------------------delete----------------------//
  const handleRemove1 = async (Id) => {
    try {
      swal({
        title: "Are you sure?",
        text: "do you want to delete this data ?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          await axios.delete(`${window.env.API_URL}/softdeleteCurrency/${Id}`);
          swal(" Your data has been deleted Successfully!", {
            icon: "success",
          });
          dispatch(fetchcurrency({ SearchTitle, SearchCode, currentPage }));
        } else {
          swal("Your data is safe!");
        }
      });
    } catch (error) {
      const err = error.response.data.message;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
    }
  };

  const handleRemove = async (id) => {
    try {
      swal({
        title: "Are you sure?",
        text: "do you want to delete this data ?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          setLoading(true)
          const res = await axios.delete(`${window.env.API_URL}/DeleteCurrencyAlltoAll/${id}`);
          const msg = res.data.message ?  res.data.message : "deleted";
          swal(msg, {
            icon: "",
          });
      let data1 =  JSON.stringify(res.data.data)
      const blob = new Blob([data1]);
      if(res?.data?.flag){
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.download = "report1.json";
        link.href = url;
        link.click();
        setLoading(false)
            GetSearch({ currentPage });

      }     
      setLoading(false)
          GetSearch({ currentPage });

        } else {
          swal("Your data is safe!");
        }
      });
    } catch (error) {
      const err = error.success;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
      setLoading(false)

    }
  }
  // const handleRemove = async (id) => {
  //   try {
  //     swal({
  //       title: "Are you sure?",
  //       text: "do you want to delete this data ?",
  //       icon: "warning",
  //       buttons: true,
  //       dangerMode: true,
  //     }).then(async (willDelete) => {
  //       if (willDelete) {
  //         setLoading(true)
  //         const res = await axios.delete(`${window.env.API_URL}/DeleteRaceCourse/${id}`);
  //         const msg = res.data.message ?  res.data.message : "deleted";
  //         swal(msg, {
  //           icon: "",
  //         });
  //     let data1 =  JSON.stringify(res.data.data)
  //     const blob = new Blob([data1]);
  //     if(res?.flag){
  //       const url = URL.createObjectURL(blob);
  //       const link = document.createElement("a");
  //       link.download = "report1.json";
  //       link.href = url;
  //       link.click();
  //       setLoading(false)
  //           GetSearch({ currentPage });

  //     }     
  //     setLoading(false)
  //         GetSearch({ currentPage });

  //       } else {
  //         swal("Your data is safe!");
  //       }
  //     });
  //   } catch (error) {
  //     const err = error.success;
  //     swal({
  //       title: "Error!",
  //       text: err,
  //       icon: "error",
  //       button: "OK",
  //     });
  //     setLoading(false)

  //   }
  // }

  if (status === STATUSES.ERROR) {
    return (
      <h2
        style={{
          margin: "100px",
        }}
      >
        Something went wrong!
      </h2>
    );
  }

  return (
    <Fragment>
      <div className="page">
        <div className="rightsidedata">
          <div
            style={{
              marginTop: "30px",
            }}
          >
            <div className="Header ">
              <h4>Currency Listings</h4>

              <div>
                <Link to="/currency">
                  <button>Add Currency</button>
                </Link>
                <OverlayTrigger
                  overlay={<Tooltip id={`tooltip-top`}>Filter</Tooltip>}
                >
                  <span className="addmore">
                    <BiFilter
                      className="calendericon"
                      onClick={() => setShowCalender(!ShowCalender)}
                    />
                  </span>
                </OverlayTrigger>
                <CSVLink
                  data={currency}
                  separator={";"}
                  filename={"MKS Currency.csv"}
                  className="csvclass"
                >
                  Export CSV
                </CSVLink>
              </div>
            </div>
            <div>
              {ShowCalender ? (
                <span className="transitionclass">
                  <div className="userfilter">
                    <div className="filtertextform forflex">
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setSearchTitle(e.target.value)}
                        placeholder="Enter Name"
                      />
                      <input
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        className="form-control"
                        onChange={(e) => setSearchCode(e.target.value)}
                        placeholder="Enter Short Code"
                      />

                    </div>
                  </div>
                  <button className="filterbtn" onClick={GetSearch}>
                    Apply Filter
                  </button>
                </span>
              ) : (
                <></>
              )}
            </div>
            <>
              <div className="div_maintb">
                <ScrollContainer>
                  <table>
                    <thead>
                      <tr>
                        <th>Action</th>

                        <th>Name</th>
                        <th>Name Arabic </th>

                        <th>Short Code</th>
                        <th>Symbol</th>
                        <th>Rate</th>

                      </tr>
                    </thead>
                    {currency === undefined ? <Notfound /> :
                      <tbody>
                        {

                          status === 'loading' ?

                            <Lottie animationData={HorseAnimation} loop={true} className="TableLottie" />

                            :

                            currency.map((item, index) => {
                              return (
                                <>
                                  <tr className="tr_table_class">
                                    <td className="table_delete_btn1">
                                      <BiEdit
                                        onClick={() =>
                                          history("/editcurrency", {
                                            state: {
                                              currencyid: item,
                                            },
                                          })
                                        }
                                      />
                                      {userInfo && userInfo.data.role === "admin" ? (
                                        <MdAutoDelete
                                          style={{
                                            fontSize: "22px",
                                          }}
                                          onClick={() => handleRemove(item._id)}
                                        />
                                      ) : (
                                        <></>
                                      )}
                                      <BsEyeFill onClick={() => handleShow(item)} />
                                    </td>
                                    <td>{item.NameEn}</td>
                                    <td>{item.NameAr}</td>

                                    <td>{item.shortCode} </td>
                                    <td>{item.Symbol}</td>
                                    <td>{item.Rate} </td>



                                  </tr>
                                </>
                              );
                            })}
                      </tbody>}
                  </table>
                </ScrollContainer>
              </div>
            </>
          </div>
          <span className="plusIconStyle"></span>
          <Pagination
            postsPerPage={postsPerPage}
            totalPosts={TotalCount}
            paginate={paginate}
            currentPage={currentPage}
            TotalPages={TotalPages}
          />
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2 style={{ fontFamily: "inter" }}>Currency</h2>
        </Modal.Header>
        <Modal.Body>
          <CurrencyPopup data={modaldata} />
        </Modal.Body>

        <Modal.Footer>
          <button onClick={handleClose} className="modalClosebtn">
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default CurrencyTable;