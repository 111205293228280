import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});

const getLiveRaceOnlySlice = createSlice({
    name: 'LiveRaceOnly',
    initialState: {
        data: [],
        status: STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchLiveRaceOnly.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(fetchLiveRaceOnly.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.IDLE
            })
            .addCase(fetchLiveRaceOnly.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
    }
});

export const { setLiveRaceOnly, setStatus } = getLiveRaceOnlySlice.actions;
export default getLiveRaceOnlySlice.reducer;

export const fetchLiveRaceOnly = createAsyncThunk('/LiveRaceOnlyget/fetch', async () => {
    const res = await axios.get(`${window.env.API_URL}/LiveRaceOnlyDropDown`);
    const LiveRaceOnlyData = res.data;
    return LiveRaceOnlyData.data;
})
