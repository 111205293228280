import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});

const getGroupNameSlice = createSlice({
    name: 'groupName',
    initialState: {
        data: [],
        status: STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchGroupName.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(fetchGroupName.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.IDLE
            })
            .addCase(fetchGroupName.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
    }
});

export const { setGroupName, setStatus } = getGroupNameSlice.actions;
export default getGroupNameSlice.reducer;

export const fetchGroupName = createAsyncThunk('/PointGroupName/fetch', async () => {
    const res = await axios.get(`${window.env.API_URL}/PointGroupNameget`);
    const groupNameData = res.data;
    return groupNameData.data;
})