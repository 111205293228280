import React, { useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { fetchjockey } from "../../../redux/getReducer/getJockeySlice";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { fetchHorseForRace } from "../../../redux/getDropDownReducer/getHorseRace";
import { fetchequipment } from "../../../redux/getReducer/getEquipment";
import { fetchEditRaceHorse } from "../../../redux/getReducer/getEditHorseRaceSlice";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import swal from "sweetalert";
import axios from "axios";
import { fetchColorDropdown } from "../../../redux/getDropDownReducer/getColor";
import { fetchsilkcolordropdown } from "../../../redux/getDropDownReducer/getOwnerSilkColor";
import { ImCross } from "react-icons/im";
import { TiTick } from "react-icons/ti";
import { fetchJockeyForRace } from "../../../redux/getDropDownReducer/getJockeyForRace";
import { fetchequipmentdropdown } from "../../../redux/getDropDownReducer/getEquipment";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { AiOutlineReload } from "react-icons/ai";

const EditRaceHorse = () => {
  const [InputData, SetinputData] = useState("");
  const [IncreasePage, setIncreasePage] = useState(16);
  const [IncreasePage1, setIncreasePage1] = useState(16);
  const [Gate, setGate] = useState("");
  const [EquipmentData, SetEquipmentData] = useState("");
  const [JockeyData, SetJockeyData] = useState("");

  const [CapColor, SetCapColor] = useState("");
  const [HorseStatus, SetHorseStatus] = useState(true);
  const [items, setitems] = useState([]);
  const [HorseData, SetHorseData] = useState("");
  const [value1, SetValue1] = useState();

  const { data: EditRaceHorse } = useSelector((state) => state.EditRaceHorse);
  const { data: JockeyForRace } = useSelector((state) => state.JockeyForRace);
  const { data: HorseForRace } = useSelector((state) => state.HorseForRace);
  const { data: equipmentdropdown } = useSelector((state) => state.equipmentdropdown);
  const { data: colordropdown } = useSelector((state) => state.colordropdown);
  const { data: silkcolordropdown } = useSelector((state) => state.silkcolordropdown);

  const [SearchAge, setSearchAge] = useState("");
  const [SearchCode, setSearchCode] = useState("");
  const [SearchTitle, setSearchTitle] = useState("");
  const [SearchNameEn, setSearchNameEn] = useState("");
  const [SearchRating, setSearchRating] = useState("");

  const [RaceWeight, setRaceWeight] = useState("");
  const [RaceRating, setRaceRating] = useState(0);
  const history = useNavigate();
  const { state } = useLocation();
  const { RaceId } = state;

  const [StoreData, setStoreData] = useState([]);

  const slider = document.querySelector(".horizontal-scroll-wrapper");
  let isDown = false;
  let startX;
  let scrollLeft;
  if (slider) {
    slider.addEventListener("mousedown", (e) => {
      isDown = true;
      slider.classList.add("active");
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    });

    slider.addEventListener("mouseleave", () => {
      isDown = false;
      slider.classList.remove("active");
    });
    slider.addEventListener("mouseup", () => {
      isDown = false;
      slider.classList.remove("active");
    });
    slider.addEventListener("mousemove", (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 3;
      slider.scrollLeft = scrollLeft - walk;
    });
  }

  const H1 = EditRaceHorse.map((item) => item.HorseNo);

  const HighNum = Math.max(...H1);
  let IncreseData = HighNum + 1;
  const [HorseNo, setHorseNo] = useState(1);

  let MapGate = [];
  for (var i = 1; i < 100; i++) {
    MapGate.push({ id: i, value: i, label: i });
  }

  const A1 = EditRaceHorse.map((item) => item.HorseModelId);

  let horseoptions = HorseForRace.map(function (item) {
    return {
      id: item._id,
      value: item.NameEn,
      label: item.NameEn,
      ActiveOwnerid: item.ActiveOwner === null ? <></> : item.ActiveOwner,
      Ownername:
        item.ActiveOwnerData === null ? <>NAN</> : item.ActiveOwnerData.NameEn,
      rating: item.STARS,
      isdisabled:
        A1[0] !== item._id &&
          A1[1] !== item._id &&
          A1[2] !== item._id &&
          A1[3] !== item._id &&
          A1[4] !== item._id &&
          A1[5] !== item._id &&
          A1[6] !== item._id &&
          A1[7] !== item._id &&
          A1[8] !== item._id &&
          A1[9] !== item._id &&
          A1[10] !== item._id &&
          A1[11] !== item._id &&
          A1[12] !== item._id &&
          A1[13] !== item._id &&
          A1[14] !== item._id &&
          A1[15] !== item._id &&
          A1[16] !== item._id &&
          A1[17] !== item._id &&
          A1[18] !== item._id &&
          A1[19] !== item._id &&
          A1[20] !== item._id &&
          A1[21] !== item._id
          ? false
          : true,
    };
  });

  const G1 = EditRaceHorse.map((item) => item.GateNo);

  let Gateoptions = MapGate.map(function (item) {
    return {
      id: item._id,
      value: item.value,
      label: item.value,
      isdisabled:
        G1[0] !== item.value &&
          G1[1] !== item.value &&
          G1[2] !== item.value &&
          G1[3] !== item.value &&
          G1[4] !== item.value &&
          G1[5] !== item.value &&
          G1[6] !== item.value &&
          G1[7] !== item.value &&
          G1[8] !== item.value &&
          G1[9] !== item.value &&
          G1[10] !== item.value &&
          G1[11] !== item.value &&
          G1[12] !== item.value &&
          G1[13] !== item.value &&
          G1[14] !== item.value &&
          G1[15] !== item.value &&
          G1[16] !== item.value &&
          G1[17] !== item.value &&
          G1[18] !== item.value &&
          G1[19] !== item.value &&
          G1[20] !== item.value
          ? false
          : true,
    };
  });



  const J1 = EditRaceHorse.map((item) => item.JockeyOnRace);

  let AllJockey = JockeyForRace.map(function (item) {
    return {
      id: item._id,
      value: item._id,
      label: item.NameEn,
      weight: item.JockeyAllowance,
      minweight: item.MiniumumJockeyWeight,
      isdisabled:
        J1[0] !== item._id &&
          J1[1] !== item._id &&
          J1[2] !== item._id &&
          J1[3] !== item._id &&
          J1[4] !== item._id &&
          J1[5] !== item._id &&
          J1[6] !== item._id &&
          J1[7] !== item._id &&
          J1[8] !== item._id &&
          J1[9] !== item._id &&
          J1[10] !== item._id &&
          J1[11] !== item._id &&
          J1[12] !== item._id &&
          J1[13] !== item._id &&
          J1[14] !== item._id &&
          J1[15] !== item._id &&
          J1[16] !== item._id &&
          J1[17] !== item._id &&
          J1[18] !== item._id &&
          J1[19] !== item._id &&
          J1[20] !== item._id
          ? false
          : true,
    };
  });

  let AllEquipment = equipmentdropdown.map(function (item) {
    return {
      id: item._id,
      value: item.NameEn,
      label: item.NameEn,
    };
  });

  const dispatch = useDispatch();
  const AOwnerid = HorseData.ActiveOwnerid === '' ? <></> : HorseData.ActiveOwnerid


  useEffect(() => {
    dispatch(fetchHorseForRace({ RaceId, IncreasePage }))
  }, [IncreasePage, RaceId, dispatch])
  useEffect(() => {

    dispatch(fetchJockeyForRace({ value1, IncreasePage1 }));
  }, [IncreasePage1, dispatch, value1])

  useEffect(() => {

    dispatch(fetchEditRaceHorse({ RaceId }));
    dispatch(fetchequipmentdropdown());
    dispatch(fetchColorDropdown());
    setHorseNo(IncreseData);
  }, [
    IncreseData,
    RaceId,
    SearchAge,
    SearchCode,
    SearchNameEn,
    SearchRating,
    SearchTitle,
    dispatch,
  ]);

  useEffect(() => {
    dispatch(fetchsilkcolordropdown({ AOwnerid }));
  },[dispatch,AOwnerid,CapColor])


  const fetchall = (AOwnerid) => {
    dispatch(fetchsilkcolordropdown({ AOwnerid }));
  }

  let AllsilkColor = silkcolordropdown.map(function (item) {
    return {
      id: item._id,
      value: item.NameEn,
      urlimg: item.OwnerSilkColor,
      label: (
        <img src={item.OwnerSilkColor} height="30px" width="30px" alt="" />

      ),
    };
  });


  useEffect(() => {
    localStorage.setItem("lists", JSON.stringify(items));
    localStorage.setItem("mapping", JSON.stringify(StoreData));
  }, [items, InputData, StoreData]);

  const saveEditItem = async (event, data) => {
    event.preventDefault();
    const formData = new FormData();
    let a = {
      GateNo: undefined,
      HorseNo: undefined,
      HorseModelId: undefined,
      Equipment: undefined,
      JockeyOnRace: undefined,
      JockeyWeight: undefined,
      Rating: undefined,
      HorseRunningStatus: undefined,
      CapColor: undefined,
      JockeyRaceWeight: undefined,
    };
    // eslint-disable-next-line array-callback-return
    EditRaceHorse.map((singleentry) => {
      if (singleentry._id === data) {
        a.GateNo = singleentry.GateNo;
        a.HorseModelId = singleentry.HorseModelId;
        a.Equipment = singleentry.Equipment;
        a.HorseNo = singleentry.HorseNo;
        a.JockeyOnRace = singleentry.JockeyOnRace;
        a.JockeyWeight = singleentry.JockeyWeight;
        a.GateRatingNo = singleentry.GateRatingNo;
        a.HorseRunningStatus = singleentry.HorseRunningStatus;
        a.CapColor = singleentry.CapColor;
        a.JockeyRaceWeight = singleentry.JockeyRaceWeight;
      }
    });
    formData.append("GateNo", Gate.value === undefined ? a.GateNo : Gate.value);
    formData.append("HorseNo", a.HorseNo);
    formData.append(
      "HorseModelId",
      HorseData.id === undefined ? a.HorseModelId : HorseData.id
    );
    formData.append(
      "JockeyRaceWeight",
      RaceWeight === undefined ? a.JockeyRaceWeight : RaceWeight
    );
    if (EquipmentData.id !== undefined) {
      formData.append(
        "Equipment",
        EquipmentData.id === undefined ? a.Equipment : EquipmentData.id
      );

    }
    if (JockeyData.id !== undefined) {
      formData.append(
        "JockeyOnRace",
        JockeyData.id === undefined ? a.JockeyOnRace : JockeyData.id
      );
    }


    if (a.JockeyWeight !== null || JockeyData.weight !== undefined) {
      formData.append(
        "JockeyWeight",
        JockeyData.weight === undefined ? a.JockeyWeight : JockeyData.weight
      );
    }

    formData.append(
      "Rating",
      JockeyData.Rating === undefined ? RaceRating : RaceRating
    );
    formData.append(
      "HorseRunningStatus",
      HorseStatus === undefined ? a.HorseRunningStatus : HorseStatus
    );
    if (CapColor.id !== undefined) {
      formData.append(
        "CapColor",
        CapColor.id === undefined ? a.CapColor : CapColor.id
      );
    }

    formData.append("Rowid", data);

    try {
      await axios.put(
        `${window.env.API_URL}EditRaceHorses/${RaceId}`,
        formData
      );
      localStorage.removeItem("lists");
      setGate(1);
      swal({
        title: "Success",
        text: "Data has been added successfully ",
        icon: "success",
        button: "OK",
      });
      dispatch(fetchEditRaceHorse({ RaceId }));
      // window.location.reload();
    } catch (error) {
      const err = error.response.data.message;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
    }
  };

  const handleRemove = async (Id) => {
    try {
      swal({
        title: "Are you sure?",
        text: "do you want to delete this data ?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
         try {
          const res =  await axios.delete(`${window.env.API_URL}/DeleteRaceHorse/${Id}`);
          console.log(res)
          swal("Your data has been deleted Successfully!", {
            icon: "success",
          });
          dispatch(fetchEditRaceHorse({ RaceId }));
         } catch (error) {
          const err = error.response.data.message
          swal(err, {
            icon: "error",
          });
         }
        } else {
          swal("Your data is safe!");
        }
      });
    } catch (error) {

      
    }
  };

  const addMore = (e) => {
    history("/editmorehorse", {
      state: {
        RaceId: RaceId,
      },
    });
  };

  const handleOnScrollBottom = () => {
    setIncreasePage(IncreasePage + 12)
    dispatch(fetchHorseForRace({ RaceId, IncreasePage }))
  };

  const handleOnScrollBottom1 = () => {
    setIncreasePage1(IncreasePage1 + 12)
    dispatch(fetchJockeyForRace({ value1, IncreasePage1 }));
  };

  const handleChangeInput1 = (value) => {
    SetValue1(value);
  };
  return (
    <>
      <div className="page">
        <div className="rightsidedata">
          <div
            style={{
              marginTop: "30px",
            }}
          >
            <div className="Header ">
              <h4>Edit Horse</h4>
              <button className="AddAnother1" onClick={addMore}>
                Add More Horse
              </button>
            </div>

            <div className="horizontal-scroll-wrapper squares">
              <table className="editformtable">   <thead >
                <tr >
                  <th>Horse #</th>
                  <th>Gate #</th>
                  <th>Horse</th>
                  <th>Owner Name</th>
                  <th>Jockey</th>
                  <th> Weight</th>
                  <th>Min Weight</th>
                  <th>Race Weight</th>
                  <th>Rating</th>
                  <th>Silk Color</th>
                  <th>Equipment</th>
                  <th>Horse Status</th>
                  <th>Action</th>
                </tr>
              </thead>

                <tbody >
                  <>
                    {!EditRaceHorse ? (
                      <></>
                    ) : (
                      EditRaceHorse.map((item, i) => {
                        return (
                          <tr className="" key={i}> 

                            <td className="myedit">
                              <input
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                value={item.HorseNo}
                                min="1"
                              />
                            </td>

                            <td className="myedit">

                              <Select
                                placeholder={
                                  item.GateNo === 100 ? (
                                    <>Non Runner</>
                                  ) : (
                                    item.GateNo
                                  )
                                }
                                className="dropdown multidropdown"
                                defaultValue={Gate}
                                onChange={setGate}
                                options={Gateoptions}
                                isOptionDisabled={(option) => option.isdisabled}
                                isSearchable={true}

                              />
                            </td>


                            <td className="myedit">

                              <Select
                                placeholder={item.HorseModelIdData1 ? item.HorseModelIdData1.NameEn : <></>}
                                className="dropdown multidropdown"
                                defaultValue={HorseData}
                                onChange={SetHorseData}
                                options={horseoptions}
                                isOptionDisabled={(option) => option.isdisabled}
                                isSearchable={true}
                                
                                onMenuScrollToBottom={(e) => handleOnScrollBottom(e)}
                              />

                            </td>

                            <td className="myedit">

                              <input
                                placeholder={HorseData.OwnerOnRaceData1 ? item.OwnerOnRaceData1.NameEn : <></>}
                                value={item.OwnerOnRaceData1 ? item.OwnerOnRaceData1.NameEn : <></>}
                                readOnly
                                style={{ width: "120px" }}
                              />

                            </td>

                            <td className="myedit">

                              <Select
                                placeholder={
                                  item.JockeyOnRaceData1 === null ? (
                                    <>Non Runner</>
                                  ) : (
                                    item.JockeyOnRaceData1.NameEn
                                  )
                                }
                                className="dropdown multidropdown"
                                defaultValue={JockeyData}
                                onChange={SetJockeyData}
                                options={AllJockey}
                                isOptionDisabled={(option) => option.isdisabled}
                                isSearchable={true}
                                onInputChange={handleChangeInput1}
                                onMenuScrollToBottom={(e) => handleOnScrollBottom1(e)}
                              />
                            </td>


                            <td className="myedit">

                              <input
                                placeholder={
                                  item.JockeyWeight === null
                                    ? 0
                                    : item.JockeyWeight
                                }
                                value={item.JockeyWeight}
                                readOnly
                              />
                            </td>


                            <td className="myedit">

                              <input
                                placeholder={
                                  item.JockeyWeight === null
                                    ? 0
                                    : item.JockeyWeight
                                }
                                value={item.minweight}
                                readOnly
                              />

                            </td>

                            <td className="myedit">

                              <input
                                placeholder={item.JockeyRaceWeight}
                                onChange={(e) => setRaceWeight(e.target.value)}
                                onKeyPress={(event) => {
                                  if (!/^\d*\.?\d*$/
                                    .test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                              />
                            </td>


                            <td className="myedit">

                              <input
                                onChange={(e) => setRaceRating(e.target.value)}
                                onKeyPress={(event) => {
                                  if (!/^\d*\.?\d*$/
                                    .test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                placeholder={item?.Rating}
                              />

                            </td>

                            <td className="myedit" key={i}>

                              <Select
                                placeholder={
                                  item.CapColorData1 ?
                                    < img src={item.CapColorData1 ? item.CapColorData1.OwnerSilkColor : <></>} alt="" style={{
                                      width: '30px', height: '30px'
                                    }} />
                                    : <></>}
                                className="dropdown multidropdown"
                                defaultValue={CapColor}
                                onChange={SetCapColor}
                                options={AllsilkColor}
                                isSearchable={true}
                                // key={i}
                              />
                               <span className="spanForm spanForm1 aaaa1">
                              
                            
                            </span>
                            <span className="addmore addmore1" onClick={() => fetchall(item.HorseModelIdData1?.ActiveOwner)}>
                                  <p className="verifybtn verifybtncap">Fetch</p>
                                </span>

                            </td>

                            <td className="myedit">

                              <Select
                                placeholder={
                                  item.EquipmentData1 === null ? (
                                    <>-</>
                                  ) : (
                                    item.EquipmentData1.NameEn
                                  )
                                }
                                className="dropdown multidropdown"
                                defaultValue={EquipmentData}
                                onChange={SetEquipmentData}
                                options={AllEquipment}
                                isSearchable={true}
                              />

                            </td>

                            <td className="myedit">
                              <Form.Check
                                type="switch"
                                id="custom-switch"
                                onChange={() => SetHorseStatus(!item.HorseRunningStatus)}
                                value={HorseStatus}
                                defaultChecked={item.HorseRunningStatus}
                              />
                            </td>
                            <td className="myedit">
                              <button
                                className="savebtnhorse"
                                onClick={(e) => saveEditItem(e, item._id)}
                              >
                                <TiTick />
                              </button>

                              <button
                                className="removebtnhorse"
                                onClick={() => handleRemove(item._id)}
                              >
                                <ImCross />
                              </button>
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </>

                  <div className="sbmtbtndiv"></div>
                </tbody>
              </table>
            </div>
            <div className="RaceButtonDiv">
              {/* <button className="updateButton" onClick={submit}>
                      Add
                    </button> */}

              <button
                className="SubmitButton"
                type="submit"
                onClick={() =>
                  history("/editraceverdict", {
                    state: {
                      RaceId: RaceId,
                    },
                  })
                }
              >
                Edit Verdict
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditRaceHorse;