import React, { useEffect, useRef } from "react";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import { fetchHorseforRace } from "../../../redux/getReducer/getHorsesForRace";
import { ImCross } from "react-icons/im";
import { TiTick } from "react-icons/ti";
import { Modal } from "react-bootstrap";


import Select from "react-select";
import swal from "sweetalert";
import axios from "axios";
import { toast } from "react-toastify";

import RaceImage from './ResultImages';
import { fetchfinalpositiondropdown } from "../../../redux/getDropDownReducer/getFinalPosition";



export const validTime = new RegExp(
  '^([0-2][0-3]:[0-5][0-9])|(0?[0-9]:[0-5][0-9])$'
);

const ResultForm = () => {
  const [HorseID, SetHorseID] = useState("");
  const [FinalPosition, setFinalPosition] = useState("");
  const [VideoLink, setVideoLink] = useState("");
  const [Distance, setDistance] = useState("");
  const [BTO, setBTO] = useState("");
  const [BTOHorse, setBTOHorse] = useState("");

  const [Remarks, setRemarks] = useState("");

  const [CumulativeDistance, setCumulativeDistance] = useState("");
  const [videoError, setVideoError] = useState("")
  const [disablebtn, setdisablebtn] = useState(false)


  const [isDisable, setisDisable] = useState(false);
  const [StoreData, setStoreData] = useState([]);
  const [items, setitems] = useState([]);
  const [time, setTime] = useState("");
  const [isLoading, setisLoading] = useState(false);

  const [isValid, setIsValid] = useState(false);

  const [RaceTime, setRaceTime] = useState("");
  const [ItemLength1, setItemLength1] = useState(0);
  const { data: finalpositiondropdown } = useSelector((state) => state.finalpositiondropdown);
  const { data: HorseforRace } = useSelector((state) => state.HorseforRace);

  const history = useNavigate();
  const { state } = useLocation();
  const { RaceId } = state;

  const [showResultimage, setShowResultimage] = useState(false);

  const handleCloseResultimage = () => setShowResultimage(false);

  const handleShowResultimage = async () => {
    await setShowResultimage(true);
  };

  let finalposition1 = finalpositiondropdown.map(function (item) {
    return {
      id: item._id,
      value: item.NameEn,
      Rank: item.Rank,
      label: (
        <div>
          <p>{item.NameEn} ({item.Rank})</p>
        </div>
      )
    };
  });

  let horseoptions = HorseforRace.map(function (item) {

    const A1 = items.map((item) => item.HorseID);
    return {
      id: item.HorseModelIdData1 ? item.HorseModelIdData1._id : <></>,
      value: item.HorseModelIdData1 ? item.HorseModelIdData1.NameEn : <></>,
      label: <>{item.HorseModelIdData1 ? item.HorseModelIdData1.NameEn : <></>}({item.HorseNo})</>,
      JockeyOnRace: item.JockeyOnRace,
      STARS: item.HorseModelIdData1 ? item.HorseModelIdData1.STARS : <></>,
      TrainerOnRace: item.TrainerOnRace,
      isdisabled: A1[0] !== item.HorseModelIdData1._id && A1[1] !== item.HorseModelIdData1._id && A1[2] !== item.HorseModelIdData1._id &&
        A1[3] !== item.HorseModelIdData1._id && A1[4] !== item.HorseModelIdData1._id && A1[5] !== item.HorseModelIdData1._id && A1[6] !== item.HorseModelIdData1._id &&
        A1[7] !== item.HorseModelIdData1._id && A1[8] !== item.HorseModelIdData1._id && A1[9] !== item.HorseModelIdData1._id &&
        A1[10] !== item.HorseModelIdData1._id && A1[11] !== item.HorseModelIdData1._id && A1[12] !== item.HorseModelIdData1._id && A1[13] !== item.HorseModelIdData1._id &&
        A1[14] !== item.HorseModelIdData1._id && A1[15] !== item.HorseModelIdData1._id && A1[16] !== item.HorseModelIdData1._id && A1[17] !== item.HorseModelIdData1._id &&
        A1[18] !== item.HorseModelIdData1._id && A1[19] !== item.HorseModelIdData1._id && A1[20] !== item.HorseModelIdData1._id && A1[21] !== item.HorseModelIdData1._id
        ? false : true,
    };
  });
  let formRef = useRef();
  const dispatch = useDispatch();

  const [state1, setState] = useState({
    Rating: '',
  });
  useEffect(() => {
    if (HorseID) {
      setState({
        Rating: HorseID.STARS,
      });
    } else {
    }
  }, [HorseID]);



  useEffect(() => {
    dispatch(fetchHorseforRace({ RaceId }));
    dispatch(fetchfinalpositiondropdown());
    localStorage.setItem("results", JSON.stringify(items));
    localStorage.setItem("mappingdata", JSON.stringify(StoreData));
  }, [dispatch, StoreData, items, RaceId]);


  let sortedProducts = StoreData.sort((p1, p2) =>
    p1.Rank > p2.Rank ? 1 : p1.Rank < p2.Rank ? -1 : 0
  );

  for (let i = 0; i < sortedProducts.length; i++) {
    if (i > 0) {
      if (sortedProducts[i].Rank === sortedProducts[i - 1].Rank) {
        sortedProducts[i].CumulativeDistance = Number(
          sortedProducts[i - 1].CumulativeDistance
        );
        sortedProducts[i].BeatenBy = sortedProducts[i - 1].BeatenBy;
      } else {
        sortedProducts[i].CumulativeDistance =
          Number(sortedProducts[i - 1].CumulativeDistance) +
          Number(sortedProducts[i].Distance);
      }
    }
    if (sortedProducts[i].Rank === 1) {

    }
    if (sortedProducts[i].Rank === 2) {

    }
    if (sortedProducts[i].Rank === 3) {

    }
    if (sortedProducts[i].Rank === 4) {

    }
    if (sortedProducts[i].Rank === 5) {

    }
    if (sortedProducts[i].Rank === 6) {

    }

  }


  const arr = sortedProducts.map((item) => item.Distance);
  var sum = 0;
  for (let i = 0; i < arr.length; i++) {
    sum += parseInt(arr[i]);
  }
  const DataABC = JSON.parse(localStorage.getItem("mappingdata"));
  let TotalHorse = HorseforRace.length;
  let EnterHorse = items.length;
  let RemaingHorse = TotalHorse - EnterHorse;

  const addItem = (e) => {
    e.preventDefault();
    let ResultEntry = {
      FinalPosition: FinalPosition.id,
      Rank: FinalPosition.Rank,
      Rating: state1.Rating,
      HorseID: HorseID.id,
      CumulativeDistance: 0,
      Prize: 0,
      Distance: Distance,
      VideoLink: VideoLink,
      RaceTime: time,
      TrainerOnRace: HorseID.TrainerOnRace,
      JockeyOnRace: HorseID.JockeyOnRace,
      BestTurnOut: BTOHorse.id,
      BestTurnPrice: BTO,
      Remarks: Remarks,
      BeatenBy: null
    };

    let ResultMapEntry = {
      FinalId: FinalPosition.value,
      Rank: FinalPosition.Rank,
      Rating: state1.Rating,
      HorseName: HorseID.value,
      CumulativeDistance: sum,
      Distance: Distance,
      Remarks: Remarks,

    };
    if (HorseID === "" || FinalPosition === "" || Distance === "" || state1.Rating === "") {
      toast("Select Values ");
    }
    else {
      setitems([...items, ResultEntry]);
      setStoreData([...StoreData, ResultMapEntry]);
      SetHorseID("");
      setFinalPosition("");
      setCumulativeDistance("");
      setDistance("");
      setRemarks("")
      setState({
        Rating: '',
      });

    }
  };
  const removesingleItem = (id) => {
    const updateItems = items.filter((elem, ind) => {
      return ind !== id;
    });
    setitems(updateItems);

    const updateItems1 = StoreData.filter((elem, ind) => {
      return ind !== id;
    });
    setStoreData(updateItems1);
  };
  const Remove = () => {
    setitems([]);
    setisDisable(false);
    setStoreData([]);
    SetHorseID("");
    setFinalPosition("");
    setRemarks("")
    setVideoLink("");
    setItemLength1(0);
    setVideoLink("");
    setRaceTime("");
    setisDisable(false);
    setState({
      Rating: '',
    });
  };

  const submit = async (event) => {
    event.preventDefault();
    if (TotalHorse !== EnterHorse) {
      toast(`You have ${RemaingHorse} Horse Remaining`);
    }
    else {
      setisLoading(true);

      try {
        await axios.post(
          `${window.env.API_URL}ResultCreationV2/${RaceId}`, { ResultEntry: items }
        );
        swal({
          title: "Success",
          text: "Data has been added successfully ",
          icon: "success",
          button: "OK",
        });
        setisLoading(false);

        history("/resultannounced");
        localStorage.removeItem("results");
      }
      catch (error) {
        const err = error.response.data.message;
        swal({
          title: "Error!",
          text: err,
          icon: "error",
          button: "OK",
        });
      }
      setisLoading(false);

    }
  };
  const addMore = (e) => {
    if (time === '') {
      toast('Enter Value First');
    }
    else {
      setItemLength1(ItemLength1 + 1);
      setisDisable(true);
    }

  };







  const handleTimeChange = (event) => {
    const inputTime = event.target.value;
    const formattedTime = inputTime.replace(/(\d{2})(?=\d{2})/g, '$1:');
    setTime(formattedTime);
  };
  const videoLinkPattern = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be|vimeo\.com)\/.+$/;
  const handleVideoLinkChange = (event) => {
    const inputValue = event.target.value;
    const isLinkValid = videoLinkPattern.test(inputValue);
    setVideoLink(inputValue);
    setIsValid(isLinkValid);
    setVideoError(!isLinkValid ? "Please Enter A Valid Video Link" : "Video Link is Validated")
  };


  

  return (
    <>
      <div className="page">
        <div className="rightsidedata">
          <div
            style={{
              marginTop: "30px",
            }}
          >
            <div className="Header ">
              <h4>Add Result</h4>
              <h4>Result Awaited Horse :({RemaingHorse})</h4>

            </div>
            <div className="resulttopheader">
              <span className="transitionclass">
                <div className="results">
                  <div className="filtertextform forflex filtertextformresp">
                    <input type="text" value={time} onChange={handleTimeChange} placeholder="Enter Time" maxLength='8'
                      onKeyPress={(event) => {
                        if (!/^\d*\.?\d*$/
                          .test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      required
                    />
                  </div>
                  <div className="filtertextform forflex filtertextformresp">
                    <input
                      type="url"
                      className="form-control"
                      placeholder="Enter Video Link"
                      value={VideoLink}
                      onChange={handleVideoLinkChange}
                      disabled={isDisable}
                      name="VideoLink"
                      required
                    />
                      {isValid ? <span className="videosuccess">{videoError}</span> : <span className="videoerror">{videoError}</span>}
                  
                  </div>
                
                  <div className="resultDropdown">
                    <Select
                      placeholder="Horse"
                      className="btohorse"
                      onChange={setBTOHorse}
                      options={horseoptions}
                      isOptionDisabled={(option) => option.isdisabled}
                      isSearchable={true}
                      value={BTOHorse}
                    />

                  </div>
                  <div className="filtertextform forflex">
                    <input
                      type="url"
                      className="form-control"
                      placeholder="Enter BTO"
                      value={BTO}
                      onKeyPress={(event) => {
                        if (!/^\d*\.?\d*$/
                          .test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(e) => setBTO(e.target.value)}
                      disabled={isDisable}
                      name="VideoLink"
                    />

                  </div>



                  <div className="filtertextform forflex">
                    <button onClick={handleShowResultimage} className="Approvedbtn resultbtn raceimagebtn">Upload Images</button>
                  </div>
                </div>
                <button className="filterbtn resultButton" onClick={addMore}>
                  Save
                </button>
              </span>
            </div>
            {
              ItemLength1 === 0 ? <></> :
                <>
                  <div className="myselecthorse myresult">
                    <div className="myselecthorsedata">
                      <span>Horse Name</span>
                      <span>Distance</span>
                      <span>Final Position</span>
                      <span>Cumulative Distance</span>
                      <span>Rating</span>
                      <span>Remarks</span>
                      <span>Action</span>
                    </div>
                  </div>
                  <div className="myselectdata resultsdata resultsdata12 ">
                    <form
                      ref={formRef}
                      onSubmit={addItem}
                      className="myselectiondata"
                    >
                      <span className="spanfordropdown">
                        <Select
                          placeholder="Horse"
                          className="dropdown multidropdown"
                          onChange={SetHorseID}
                          options={horseoptions}
                          isOptionDisabled={(option) => option.isdisabled}
                          isSearchable={true}
                          value={HorseID}
                        />
                      </span>
                      <span>
                        <input
                          onKeyPress={(event) => {
                            if (!/^\d*\.?\d*$/
                              .test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          placeholder="Distance"
                          onChange={(e) => setDistance(e.target.value)}
                          className="resultforminput"
                          value={Distance}
                        />
                      </span>

                      <span className="spanfordropdown">
                        <Select
                          placeholder="Final Position"
                          className="dropdown multidropdown"
                          onChange={setFinalPosition}
                          options={finalposition1}
                          isSearchable={true}
                          value={FinalPosition}
                        />
                      </span>

                      <span>
                        <input
                          onKeyPress={(event) => {
                            if (!/^\d*\.?\d*$/
                              .test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          placeholder="Cumulative Distance"
                          value={sum}
                          onChange={(e) => setCumulativeDistance(e.target.value)}
                          className="resultforminput"
                          min='0'
                          readOnly
                        />
                      </span>
                      <span>

                        <input
                          onKeyPress={(event) => {
                            if (!/^\d*\.?\d*$/
                              .test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          placeholder='Rating'
                          onChange={(e) =>
                            setState({ ...state1, Rating: e.target.value })
                          }
                          className="resultforminput"
                          min='0'
                          value={state1.Rating}
                        />
                      </span>
                      <span>
                        <input
                          placeholder="Remarks"
                          onChange={(e) => setRemarks(e.target.value)}
                          className="resultforminput"
                          value={Remarks}
                        />
                      </span>
                      <span>
                        <button className="savebtnhorse" onClick={addItem}>
                          <TiTick />
                        </button>
                      </span>

                    </form>


                  </div>
                  <div className="sbmtbtndiv">
              <div className="RaceButtonDiv">
              
                <button
                  className="SubmitButton"
                  type="submit"
                  onClick={submit}
                  disabled={isLoading}

                >
                  Save
                </button>
              </div>
                 </div>
                </>
                
            }
            <div className="myselectdata resultsdata resultsdata12">
              {
                !DataABC ? (
                  <></>
                ) : (
                  DataABC.map((item, i) => {
                    return (
                      <div className="myselectiondata">
                        <span className="spanfordropdown">
                          <input
                            placeholder="Horse Name"
                            value={item.HorseName}
                            readOnly
                            className="resultforminput"
                          />
                        </span>
                        <span >
                          <input
                            placeholder="Distance"
                            value={item.Distance}
                            readOnly
                            className="resultforminput"

                          />
                        </span>

                        <span className="InputAddhorse2">
                          <input
                            placeholder="Final Position"
                            value={item.FinalId}
                            readOnly
                            className="resultforminput"
                          />
                        </span>

                        <span className="InputAddhorse2">
                          <input
                            placeholder="Cumulative Distance"
                            value={item.CumulativeDistance}
                            readOnly
                            className="resultforminput"
                          />
                        </span>
                        <span className="InputAddhorse2">
                          <input
                            placeholder="Rating"
                            value={item.Rating}
                            readOnly
                            className="resultforminput"
                          />
                        </span>
                        <span >
                          <input
                            placeholder="Remarks"
                            value={item.Remarks}
                            readOnly
                            className="resultforminput"

                          />
                        </span>
                        <span>
                          <button
                            className="removebtnhorse"
                            onClick={() => removesingleItem(i)}
                          >
                            <ImCross />
                          </button>
                        </span>



                      </div>
                    );

                  })
                )
              }
                 
            </div>
        
          </div>
        </div>
      </div>
      <Modal
        show={showResultimage}
        onHide={handleCloseResultimage}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2> Upload Images</h2>
        </Modal.Header>
        <Modal.Body>
          <RaceImage data1={{RaceId,handleCloseResultimage}} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ResultForm;
