import React, { useState, useEffect } from "react";
import "../../Components/CSS/forms.css";
import { useNavigate, useLocation } from "react-router-dom";
import swal from "sweetalert";
import axios from "axios";
import { fetchgroundtypelist } from "../../redux/getDropDownReducer/getGroundList";
import { useDispatch, useSelector } from "react-redux";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import { Modal } from "react-bootstrap";
import { AiOutlineReload } from "react-icons/ai";
import RaceCoursePopup from "../PostTable/RaceCourseForm";
import GroundTypePopup from "../PostTable/GroundType";
import { ImCross } from "react-icons/im";
import { fetchracecourse } from "../../redux/getReducer/getRaceCourseSlice";
import Loader from "../../Components/Common/Loader";
import { Form, Input, Select } from 'antd';

const EditTrack = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const history = useNavigate();
  const { Option } = Select;
  const { state } = useLocation();

  const [showRaceCourse, setShowRaceCourse] = useState(false);

  const [isLoading, setisLoading] = useState(false)
  const [Racecourse, setRaceCourse] = useState("")

  const { data: GroundList } = useSelector((state) => state.GroundList);
  const { data: racecourse } = useSelector((state) => state.racecourse);
  const [option, setOptions] = useState(GroundList)

  const [showGroundType, setShowGroundType] = useState(false);
  const [isLoading1, setisLoading1] = useState(false);
  const [Value1, setValue1] = useState("");
  //---------------------------show Popups---------------------------//

  const handleCloseGroundType = () => setShowGroundType(false);
  const handleCloseActiveRaceCourse = () => setShowRaceCourse(false);

  const handleShowGroundType = async () => {
    await setShowGroundType(true);
  };

  const handleShowRaceCourse = async () => {
    await setShowRaceCourse(true);
  };
  //---------------------------options for dropdown---------------------------//

  const handleSearch = async (value) => {

    setOptions(GroundList)
    try {
      // Filtering the NationalityList based on the search value
      const filteredNationalities = GroundList.filter(
        (item) =>
          item.NameEn.toLowerCase().includes(value.toLowerCase()) ||
          item.NameAr.includes(value)
      );
      setOptions(filteredNationalities);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  const handleChange1 = async (value) => {

    await setRaceCourse(value)
    setValue1(value)

    setState({ ...state1, RaceCourseName: value })

  };

  const handleChange = async (value) => {

    await setGroundType(value)

    setState({ ...state1, GroundTypeName: value })

  };
  const handleSearch1 = async (Value1) => {

    setValue1(Value1)

  };

  //---------------------------re fetch Api---------------------------//
  const FetchNew = () => {
    dispatch(fetchgroundtypelist());
    dispatch(fetchracecourse({ Value1 }))

  };
  const { trackid } = state;
  const [image, setImage] = useState();
  const [preview, setPreview] = useState();
  const [GroundType, setGroundType] = useState();


  const [state1, setState] = useState({
    TrackLength: "",
    RailPosition: "",
    RaceCourse: "",
    GroundType: "",
    GroundTypeName: "",
    RaceCourseName: "",
    image: image,
  });


  //---------------------------get data from previous state---------------------------//
  useEffect(() => {
    if (trackid) {
      setState({
        TrackLength: trackid.TrackLength,
        RailPosition: trackid.RailPosition,
        RaceCourse: trackid.TrackLengthRaceCourseData,
        GroundType: trackid.GroundTypeModelData,
        GroundTypeName: trackid.GroundTypeModelData && trackid.GroundTypeModelData.NameEn,
        RaceCourseName: trackid.TrackLengthRaceCourseData && trackid.TrackLengthRaceCourseData.TrackNameEn

      });
    } else {
      alert("No Data");


    }
  }, [trackid]);


  useEffect(() => {
    if (image === undefined) {
      setPreview(trackid.RaceCourseImage);
      return;
    }
    if (image && !image.name.match(/\.(gif)$/)) {
      setImage("");
      setPreview(undefined);
      document.getElementById("file").value = "";
      swal({
        title: "Error!",
        text: "Enter Gif Image",
        icon: "error",
        button: "OK",
      });
      return
    }
    if (image) {
      const objectUrl = URL.createObjectURL(image);
      setPreview(objectUrl);
      return () => URL.revokeObjectURL(objectUrl);
    }

  }, [image, trackid.RaceCourseImage]);

  const fileSelected = (event) => {
    const image = event.target.files[0];
    setImage(image);
  }
  //---------------------------submit---------------------------//

  const submit = async () => {



    try {
      setisLoading(true)
      const formData = new FormData();


      formData.append("TrackLength", state1.TrackLength);
      formData.append("image", image === undefined ? null : image);
      formData.append("GroundType", GroundType === undefined ? trackid.GroundTypeModelData._id : GroundType);
      formData.append("RailPosition", state1.RailPosition);
      formData.append("RaceCourse", Racecourse === undefined ? trackid.TrackLengthRaceCourseData._id : Racecourse);
      if (isLoading1 === true && image === undefined) {
        formData.append("deleteexisitingimage", isLoading1)
      }


      await axios.put(
        `${window.env.API_URL}/updateTrackLength/${trackid._id}`,
        formData
      );
      history("/tracklength");
      swal({
        title: "Success!",
        text: "Data has been Updated successfully ",
        icon: "success",
        button: "OK",
      });
    } catch (error) {
      swal({
        title: "Error!",
        text: error,
        icon: "error",
        button: "OK",
      });

    }
    setisLoading(false)
  };
  const handlePreview = () => {
    fetchracecourse({ Value1 })
    setImage();
    setPreview();
    setisLoading1(true)
    document.getElementById("file").value = "";
  };
  useEffect(() => {

    dispatch(fetchracecourse({ Value1 }))

  }, [Value1, dispatch])
  useEffect(() => {

    dispatch(fetchgroundtypelist())

  }, [dispatch])

  form.setFieldsValue({
    TrackLength: state1.TrackLength,

    RailPosition: state1.RailPosition,
    RaceCourseName: state1.RaceCourseName,
    GroundTypeName: state1.GroundTypeName
  });



  return (
    <>
      <div className="page" onClick={() => { console.log(Racecourse, "<=== =race ", Value1, "<===  value1") }}>
        <div className="rightsidedata">

          <div className="Headers">Edit Track Length</div>
          {isLoading ? <Loader /> : <div className="form">
            <Form onFinish={submit} form={form}  >
              <div className="row mainrow">
                <div className="col-sm">




                  <Form.Item
                    name="TrackLength"
                    label="Track Length"
                    rules={[
                      {
                        required: true,
                        message: 'Track Length  is required',
                      },
                    ]}
                  >
                    <Input onChange={(e) =>
                      setState({ ...state1, TrackLength: e.target.value })
                    }
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      placeholder="Track Length"
                      value={state1.TrackLength}
                    />
                  </Form.Item>



                </div>
              </div>

              <div className="row mainrow">

                <div className="col-sm">
                  <Form.Item

                    name="GroundTypeName"
                    label="Ground Type"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: 'Please select your Ground Type!',
                      },
                    ]}
                  >
                    <Select

                      filterOption={false}
                      onChange={handleChange}
                      value={GroundType}
                      defaultValue={state1?.GroundTypeName}
                      onSearch={handleSearch}
                      showSearch placeholder="Please select a Ground Type" >

                      {
                        option?.map((item) => (


                          <Option value={item._id}>{item.NameEn} {"(" + item.NameAr + ")"}</Option>

                        ))
                      }
                    </Select>
                  </Form.Item>
                  <span className="spanForm selectSpacer">
                    <OverlayTrigger
                      overlay={<Tooltip id={`tooltip-top`}>Add more</Tooltip>}
                    >
                      <span
                        className="addmore"
                        onClick={handleShowGroundType}
                      >
                        +
                      </span>
                    </OverlayTrigger>
                    <OverlayTrigger
                      overlay={
                        <Tooltip id={`tooltip-top`}>Fetch New</Tooltip>
                      }
                    >
                      <span className="addmore" onClick={FetchNew}>
                        <AiOutlineReload />
                      </span>
                    </OverlayTrigger>

                  </span>

                </div>
              </div>

              <div className="row mainrow">
                <div className="col-sm">

                  <Form.Item
                    name="RailPosition"
                    label="Rail Position"
                    rules={[
                      {
                        required: true,
                        message: 'Rail Position is required',
                      },
                    ]}
                  >
                    <Input
                      value={state1.RailPosition}
                      onChange={(e) =>
                        setState({ ...state1, RailPosition: e.target.value })
                      }
                      name="RailPosition" />
                  </Form.Item>

                </div>
              </div>
              <div className="row mainrow">

                <div className="col-sm">

                  <Form.Item

                    name="RaceCourseName"
                    label="Race Course"

                    rules={[
                      {
                        required: true,
                        message: 'Please select your Race Course Course !',
                      },
                    ]}
                  >
                    <Select
                      value={Racecourse}
                      defaultValue={state1?.RaceCourseName}

                      onChange={handleChange1}
                      filterOption={false}
                      onSearch={handleSearch1}
                      showSearch placeholder="Please select a Race Course" >

                      {
                        racecourse?.map((item) => (


                          <Option value={item._id}>{item.TrackNameEn} {"(" + item.TrackNameAr + ")"}</Option>

                        ))
                      }
                    </Select>
                  </Form.Item>


                  <span className="spanForm selectSpacer">
                    <OverlayTrigger
                      overlay={<Tooltip id={`tooltip-top`}>Add more</Tooltip>}
                    >
                      <span
                        className="addmore"
                        onClick={handleShowRaceCourse}
                      >
                        +
                      </span>
                    </OverlayTrigger>
                    <OverlayTrigger
                      overlay={
                        <Tooltip id={`tooltip-top`}>Fetch New</Tooltip>
                      }
                    >
                      <span className="addmore" onClick={FetchNew}>
                        <AiOutlineReload />
                      </span>
                    </OverlayTrigger>{" "}
                  </span>

                </div>

              </div>

              <div className="ButtonSection">
                <div className={image === undefined ? "inputFile" : ""}>
                  <label className="Multipleownerlabel">
                    Select Track Gif
                  </label>
                  <input
                    type="file"
                    onChange={fileSelected}
                    className="formInput"
                    id="file"
                  />
                  {preview && (
                    <>
                      <ImCross
                        onClick={handlePreview}
                        className="crossIcon2"
                      />
                      <img src={preview} className="PreviewImage" alt="" />
                    </>
                  )}
                </div>
                <div>
                  <button type="submit" className="SubmitButton" onClick={submit} disabled={isLoading}>
                    Update
                  </button>
                </div>
              </div>
            </Form>
          </div>}

        </div>
      </div>
      <Modal
        show={showGroundType}
        onHide={handleCloseGroundType}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2>Ground Type</h2>
        </Modal.Header>
        <Modal.Body>
          <GroundTypePopup setShowGroundType={setShowGroundType} />
        </Modal.Body>
      </Modal>
      <Modal
        show={showRaceCourse}
        onHide={handleCloseActiveRaceCourse}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2>RaceCourse</h2>
        </Modal.Header>
        <Modal.Body>
          <RaceCoursePopup setShowRaceCourse={setShowRaceCourse} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditTrack;
