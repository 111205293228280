import React, { useEffect, Fragment, useRef } from "react";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Modal from 'react-bootstrap/Modal';
import { fetchverdict } from "../../../redux/getReducer/getVerdict";
import Select from "react-select";
import swal from "sweetalert";
import axios from "axios";
import { toast } from "react-toastify";
import { ImCross } from 'react-icons/im';
import { TiTick } from 'react-icons/ti';
import { fetchHorseforRace } from "../../../redux/getReducer/getHorsesForRace";
import Verdict from "../Verdicts";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { AiOutlineReload } from "react-icons/ai";
import { IoIosAddCircleOutline } from "react-icons/io"
import Loader from "../../../Components/Common/Loader";



const LocalItem = () => {
  const list = localStorage.getItem("verdict");
  if (list) {
    return JSON.parse(localStorage.getItem("verdict"));
  } else {
    return [];
  }
};

const PublishRace = () => {
  const [SearchTitle, setSearchTitle] = useState("");
  const [shortCode, setSearchCode] = useState("");
  const [FirstHorse, SetFirstHorse] = useState("");
  const [SecondHorse, SetSecondHorse] = useState("");
  const [ThirdHorse, SetThirdHorse] = useState("");
  const [VerdictRemark, SetVerdictRemark] = useState("");
  const [NewVerdictData, setNewVerdictData] = useState("");
  const [isLoading, setisLoading] = useState(false)
  const [VerdictName, SetVerdictName] = useState("");
  const [NameEn, setNameEn] = useState("");

  const [StoreDataVerdict, setStoreDataVerdict] = useState([]);

  const [Gate, setGate] = useState(1);
  const [itemsverdict, setitemsverdict] = useState(LocalItem());



  const { data: verdict } = useSelector((state) => state.verdict);

  const history = useNavigate();
  const { state } = useLocation();
  const { RaceId } = state;
  const { data: HorseforRace } = useSelector((state) => state.HorseforRace);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);





  let horseoptions = HorseforRace.map(function (item) {
    return {
      id: item.HorseModelIdData1._id,
      value: item.HorseModelIdData1._id,
      label: item.HorseModelIdData1.NameEn,
      isdisabled: FirstHorse.id !== item.HorseModelIdData1._id && SecondHorse.id !== item.HorseModelIdData1._id ? false : true,
    };
  });

  const G1 = StoreDataVerdict.map((item) => item.VedictId)


  let AllVerdict = verdict.map(function (item) {
    return {
      id: item._id,
      value: item.NameEn,
      label: item.NameEn,
      // isdisabled: NewVerdictData != item._id ? false : true,
      isdisabled: G1[0] !== item._id && G1[1] !== item._id && G1[2] !== item._id && G1[3] !== item._id && G1[4] !== item._id && G1[5] !== item._id && G1[6] !== item._id && G1[7] !== item._id && G1[8] !== item._id && G1[9] !== item._id && G1[10] !== item._id ? false : true,

    };
  });

  const dispatch = useDispatch();
  const DataABC = JSON.parse(localStorage.getItem("verdictdata"));

  useEffect(() => {
    dispatch(fetchHorseforRace({ RaceId }));
    dispatch(fetchverdict({ NameEn, shortCode }));
  }, [NameEn, RaceId, dispatch, shortCode]);

  useEffect(() => {
    localStorage.setItem("verdict", JSON.stringify(itemsverdict));
    localStorage.setItem("verdictdata", JSON.stringify(StoreDataVerdict));
  }, [StoreDataVerdict, itemsverdict]);



  const VerdictLength = AllVerdict.length;
  const ItemLength = itemsverdict.length;
  let formRef = useRef();


  const slider = document.querySelector('.horizontal-scroll-wrapper');
  let isDown = false;
  let startX;
  let scrollLeft;
  if (slider) {
    slider.addEventListener('mousedown', (e) => {
      isDown = true;
      slider.classList.add('active');
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    });

    slider.addEventListener('mouseleave', () => {
      isDown = false;
      slider.classList.remove('active');
    });
    slider.addEventListener('mouseup', () => {
      isDown = false;
      slider.classList.remove('active');
    });
    slider.addEventListener('mousemove', (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 3; //scroll-fast
      slider.scrollLeft = scrollLeft - walk;

    });
  }


  const addItem = (e) => {
    e.preventDefault();
    let VerdictEntry = {
      VerdictName: VerdictName.id,
      HorseNo1: FirstHorse.id,
      HorseNo2: SecondHorse.id,
      HorseNo3: ThirdHorse.id,
      Remarks: VerdictRemark || "N/A",
    };
    let VerdictMappingData = {
      VedictId: VerdictName.id,
      VerdictName: VerdictName.label,
      HorseNo1: FirstHorse.label,
      HorseNo2: SecondHorse.label,
      HorseNo3: ThirdHorse.label,
      VerdictRemark: VerdictRemark,
    };

      if (
        VerdictName === "" ||
        FirstHorse === "" ||
        SecondHorse === "" ||
        ThirdHorse === ""
    ) {
     return toast("Select Values ");
    } 
    else {
      setitemsverdict([...itemsverdict, VerdictEntry]);
      setStoreDataVerdict([...StoreDataVerdict, VerdictMappingData]);
      formRef.current?.reset();
      SetVerdictName("");
      SetFirstHorse("");
      SetSecondHorse("");
      SetThirdHorse("")
      SetVerdictRemark("")
    }

  };

  const Remove = () => {
    setitemsverdict([]);
    setGate(1);
    setStoreDataVerdict([]);

  };


  const removesingleItem = (id) => {

    const updateItemsverdict = itemsverdict.filter((elem, ind) => {
      return ind !== id;


    });
    setitemsverdict(updateItemsverdict);
    setStoreDataVerdict(updateItemsverdict)
  };

  const fetchNew = () => {
    dispatch(fetchHorseforRace({ RaceId }));
    dispatch(fetchverdict({ NameEn, shortCode }));

  }


  const submit = async (event) => {
    event.preventDefault();

    try {
      setisLoading(true)

      await axios.post(
        `${window.env.API_URL}addverdicts/${RaceId}`,
        { VerdictEntry: itemsverdict }
      );
      await axios.put(
        `${window.env.API_URL}/publishrace/${RaceId}`
      );
      localStorage.removeItem("verdict");
      history("/races");
      swal({
        title: "Success",
        text: "Data has been added successfully ",
        icon: "success",
        button: "OK",
      });
    } catch (error) {
      const err = error.response.data.message;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
    } setisLoading(false)
  

  };

  const handleInputChange = (e) => {
    const trimmedValue = e.target.value.replace(/^\s+/, ''); // Trim leading spaces
    SetVerdictRemark(trimmedValue);
  };
  return (
    <Fragment>
      {isLoading ? <Loader /> : <div className="page">
        <div className="rightsidedata edditver">
          <div
            style={{
              marginTop: "30px",
            }}
          >
            <div className="Header ">
              <h4>Add Verdict</h4>
              <div>
                <OverlayTrigger
                  overlay={<Tooltip id={`tooltip-top`}>Add More Verdict</Tooltip>}
                >
                  <span className="fetchNewVerdict" onClick={handleShow}>
                    <IoIosAddCircleOutline />
                  </span>
                </OverlayTrigger>
                <OverlayTrigger
                  overlay={
                    <Tooltip id={`tooltip-top`}>Fetch New</Tooltip>
                  }
                >
                  <span className="fetchNewVerdict" onClick={fetchNew}>
                    <AiOutlineReload />
                  </span>
                </OverlayTrigger>{" "}
              </div>
            </div>
            <div className="horizontal-scroll-wrapper squares">
              <div className="myselecthorse editver">
                <div className="myselecthorsedata newmyselecthorsedata">

                  <span>Verdict Name</span>
                  <span>1st Horse </span>
                  <span>2nd Horse </span>
                  <span>3rd Horse </span>
                  <span>Remarks </span>
                  <span>Action</span>
                </div>
              </div>
              <div className="myselectdata myaddver">
                {!StoreDataVerdict ? (
                  <></>
                ) : (
                  StoreDataVerdict.map((item, i) => {
                    return (
                      <div className="myselectiondataaddHorse myaddver myselectiondataaddHorse2" key={i}>

                        <span>
                          <input type="text" value={item.VerdictName} className="verdictdataclass" />
                        </span>

                        <span>
                          <input type="text" value={item.HorseNo1} className="verdictdataclass" />
                        </span>


                        <span>
                          <input type="text" value={item.HorseNo2} className="verdictdataclass" />
                        </span>
                        <span>
                          <input type="text" value={item.HorseNo3} className="verdictdataclass" />
                        </span>
                        <span>
                          <input type="text" value={item.VerdictRemark} />
                        </span>
                        {/* <button className="savebtnhorse" onClick={addItem}>
                          <TiTick />
                        </button> */}
                        <span> <button className="removebtnhorse" onClick={() => removesingleItem(i)}>
                          <ImCross />
                        </button></span>
                      </div>
                    );
                  })
                )}

                <form
                  ref={formRef}
                  onSubmit={addItem}
                  className="myselectiondataaddHorse myselectiondataaddHorse1 "
                >
                 
                  <span>
                    <Select
                      onChange={SetVerdictName}
                      className="dropdown multidropdown"
                      options={AllVerdict}
                      isClearable={false}
                      isSearchable={true}
                      value={VerdictName}
                      isOptionDisabled={(option) => option.isdisabled}


                    />
                  </span>
                  <span>
                    <Select
                      onChange={SetFirstHorse}
                      className="dropdown multidropdown"
                      options={horseoptions}
                      isOptionDisabled={(option) => option.isdisabled}
                      isClearable={false}
                      isSearchable={true}
                      value={FirstHorse}
                    />
                  </span>
                  <span>
                    <Select
                      onChange={SetSecondHorse}
                      className="dropdown multidropdown"
                      options={horseoptions}
                      isClearable={false}
                      isOptionDisabled={(option) => option.isdisabled}
                      isSearchable={true}
                      value={SecondHorse}

                    />
                  </span>
                  <span>
                    <Select
                      onChange={SetThirdHorse}
                      className="dropdown multidropdown"
                      options={horseoptions}
                      isClearable={false}
                      isOptionDisabled={(option) => option.isdisabled}
                      isSearchable={true}
                      value={ThirdHorse}

                    />
                  </span>
                  <span>
                  <input
  type="text"
  placeholder="Remarks"
  onChange={handleInputChange}
  value={VerdictRemark}
/>
                  </span>
                  <span><button className="savebtnhorse" type="submit" onClick={addItem}>
                    <TiTick />
                  </button></span>

                </form>

                <hr />





              </div>
            </div>

            <div className="sbmtbtndiv">
              <div className="RaceButtonDiv">
                <button className="updateButton" onClick={Remove}>
                  Remove
                </button>

                <button
                  className="SubmitButton"
                  type="submit"
                  onClick={submit} disabled={isLoading}
                >
                  Publish Race
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>}

      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2>Add Verdict</h2>
        </Modal.Header>
        <Modal.Body>
          <Verdict />

        </Modal.Body>

      </Modal>
    </Fragment>
  );
};

export default PublishRace;