import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});

const getEditRaceHorseSlice = createSlice({
    name: 'EditRaceHorse',
    initialState: {
        data: [],
        status: STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchEditRaceHorse.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(fetchEditRaceHorse.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.IDLE
            })
            .addCase(fetchEditRaceHorse.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
    }
});

export const { setEditRaceHorse, setStatus } = getEditRaceHorseSlice.actions;
export default getEditRaceHorseSlice.reducer;

export const fetchEditRaceHorse = createAsyncThunk('/EditRaceHorseget/fetch', async ({ RaceId }) => {
    const res = await axios.get(`${window.env.API_URL}/GetEditRaceHorses/${RaceId}`);
    const EditRaceHorseData = res.data;
    return EditRaceHorseData.data;
})  