import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { STATUSES } from "../../redux/getReducer/getHorseSlice";
import { MdAutoDelete, MdDelete } from "react-icons/md";
import { BiEdit } from "react-icons/bi";
import ScrollContainer from "react-indiana-drag-scroll";
import swal from "sweetalert";
import Moment from "react-moment";
import { fetchTrainerList } from "../../redux/getDropDownReducer/getTrainerList";
import { fetchHorseKind } from "../../redux/getReducer/getHorseKind";
import { Modal } from "react-bootstrap";
import HorsePopup from "../../Components/Popup/HorsePopup";
import Lottie from "lottie-react";
import HorseAnimation from "../../assets/horselottie.json";
import axios from "axios";
import { BsEyeFill } from "react-icons/bs";
import { MdOutlineSecurityUpdateWarning } from "react-icons/md";
import Pagination from "./Pagination";
import { BiFilter } from "react-icons/bi";
import { fetchColorDropdown } from "../../redux/getDropDownReducer/getColor";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { fetchBreederList } from "../../redux/getDropDownReducer/getBreederList";
import CSVHorse from "../../Components/CSVUploadPopup/HorsePopup";
import Select from "react-select";
import { fetchNationalityList } from "../../redux/getDropDownReducer/getNationalityList";
import { fetchOwnerList } from "../../redux/getDropDownReducer/getOwnerList";
import DefaulImg from "../../assets/default.jpg";
import Notfound from "../../Notfound";
import HorseDeletePopup from '../../Components/Popup/HorseDeletePopup'
import Cookies from "js-cookie";

const Horse = () => {
  //--------------------------useState----------------------//

  const [ShowCalender, setShowCalender] = useState(false);
  const [SearchAge, setSearchAge] = useState("");
  const [SearchCode, setSearchCode] = useState("");
  const [NationalityId, setNationalityId] = useState();
  const [SearchTitle, setSearchTitle] = useState("");
  const [TotalCount, setTotalCount] = useState();
  const [SearchKindHorse, setKindHorse] = useState();
  const [SearchBreeder, setBreeder] = useState();
  const [SearchActiveTrainer, setActiveTrainer] = useState();
  const [SearchData, setSearchData] = useState([]);
  const [SearchColorID, setColor] = useState("");
  const [SearchActiveOwner, setActiveOwner] = useState();
  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [SaveHorseId, setSaveHorseId] = useState(false);
  const { userInfo } = useSelector((state) => state.user);
  const [shortCode, setshortCode] = useState("");


  //--------------------------modal ----------------------//

  const [show, setShow] = useState(false);
  const [modaldata, setmodaldata] = useState();
  const handleClose = () => setShow(false);
  const handleShow = async (data) => {
    setmodaldata(data);
    await setShow(true);
  };
  //--------------------------import csv----------------------//

  const [showCSV, setShowCSV] = useState(false);
  const [modaldataCSV, setmodaldataCSV] = useState();
  const handleCloseCSV = () => setShowCSV(false);
  const handleShowCSV = async (data) => {
    setmodaldataCSV(data);
    await setShowCSV(true);
  };

  const handleHorseData = (id) => {
    setModalShow(true)
    setSaveHorseId(id)
  }

  const dispatch = useDispatch();
  const history = useNavigate();
  //--------------------------get data from redux----------------------//

  const { status } = useSelector((state) => state.horse);
  const { data: NationalityList } = useSelector(
    (state) => state.NationalityList
  );

  const { data: colordropdown } = useSelector((state) => state.colordropdown);
  const { data: BreederList } = useSelector((state) => state.BreederList);
  const { data: HorseKind } = useSelector((state) => state.HorseKind);
  const { data: OwnerList } = useSelector((state) => state.OwnerList);
  const { data: TrainerList } = useSelector((state) => state.TrainerList);

  //--------------------------data for dropdown----------------------//

  let AllNationality =
    NationalityList === undefined ? (
      <></>
    ) : (
      NationalityList.map(function (item) {
        return {
          id: item._id,
          value: item.NameEn,
          label: (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <p>{item.NameEn}</p>
            </div>
          ),
        };
      })
    );

  let AllColor =
    colordropdown === undefined ? (
      <></>
    ) : (
      colordropdown.map(function (item) {
        return {
          id: item._id,
          value: item.NameEn,
          label: (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <p>{item.NameEn}</p>
            </div>
          ),
        };
      })
    );
  let AllBreeder =
    BreederList === undefined ? (
      <></>
    ) : (
      BreederList.map(function (item) {
        return {
          id: item._id,
          value: item.NameEn,
          label: (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <p>{item.NameEn}</p>
            </div>
          ),
        };
      })
    );
  let horsekindoptions =
    HorseKind === undefined ? (
      <></>
    ) : (
      HorseKind.map(function (item) {
        return {
          id: item._id,
          value: item.NameEn,
          label: (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <p>{item.NameEn}</p>
            </div>
          ),
        };
      })
    );
  let owneroption =
    OwnerList === undefined ? (
      <></>
    ) : (
      OwnerList.map(function (item) {
        return {
          id: item._id,
          value: item.NameEn,
          label: (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <p>{item.NameEn}</p>
            </div>
          ),
        };
      })
    );
  let traineroption =
    TrainerList === undefined ? (
      <></>
    ) : (
      TrainerList.map(function (item) {
        return {
          id: item._id,
          value: item.NameEn,
          label: (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <p>{item.NameEn}</p>
            </div>
          ),
        };
      })
    );
  const [currentPage, setCurrentPage] = useState(1);
  const [TotalPages, setTotalPages] = useState("");

  const [postsPerPage] = useState(8);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const GetSearch = async () => {
    setLoading(true);
    const response = await axios.get(
      `${window.env.API_URL}SearchHorse?Foal=${SearchCode}&shortCode=${shortCode}&NameEn=${SearchTitle}&RemarksEn=${SearchAge}&NationalityID=${data}&ColorID=${Color}&Breeder=${Breeder}&KindHorse=${Kind}&ActiveOwner=${owners}&ActiveTrainer=${Trainers}&page=${currentPage}`
    );
    setSearchData(response.data.data);
    setLoading(false);
    const totalcount = response.data.totalcount;
    const TotalPagesData = response.data.totalPages;
    setTotalCount(totalcount);
    setTotalPages(TotalPagesData);
    if(TotalPagesData <= 2){
      setCurrentPage(1)
    }
  };
  //--------------------------variiable for searching----------------------//

  const data = NationalityId ? NationalityId.id : "";
  const Color = SearchColorID ? SearchColorID.id : "";
  const Breeder = SearchBreeder ? SearchBreeder.id : "";
  const Kind = SearchKindHorse ? SearchKindHorse.id : "";
  const owners = SearchActiveOwner ? SearchActiveOwner.id : "";
  const Trainers = SearchActiveTrainer ? SearchActiveTrainer.id : "";

  const [Value2, SetValue1] = useState();
  const [value, Setvalue] = useState();
  const [Value3, Setvalue3] = useState();
  const [Value4, SetValue4] = useState();

  let data1a = ''

  useEffect(() => {
    GetSearch();
  }, [dispatch, currentPage]);
  //--------------------------delete----------------------//

  const handleOpen = () => {
   setShowCalender(!ShowCalender)
  }
  const handleChangeInput1 = (value) => {
    SetValue1(value);
  };

  const handleChangeInput3 = (value) => {
    Setvalue3(value);
  };

  const handleChangeInput4 = (value) => {
    SetValue4(value);
  };

  
  const shouldRunEffect = ShowCalender;
 
  const handleChangeInput = (value) => {
    Setvalue(value);
  };
  useEffect(() => {
    dispatch(fetchOwnerList({ Value2}));
  },[dispatch,Value2])


  useEffect(() => {
    dispatch(fetchColorDropdown({data1a}));

  }, [dispatch])
  

  useEffect(() => {
    dispatch(fetchTrainerList({Value4}))
  },[dispatch,Value4])


  useEffect(() => {
    if (shouldRunEffect) {
      dispatch(fetchHorseKind({ SearchTitle, SearchCode, SearchAge }));
    }
  }, [shouldRunEffect, SearchAge, SearchCode, SearchTitle, dispatch]);

  useEffect(() => {
    dispatch(fetchNationalityList({value}));
  },[dispatch,value])

  useEffect(()=>{
     dispatch(fetchBreederList({Value3}));

  },[dispatch,Value3])

  const token = Cookies.get('userToken')
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  
  const handleRemove1 = async (Id) => {
    try {
      swal({
        title: "Are you sure?",
        text: "do you want to delete this data ?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          await axios.delete(`${window.env.API_URL}/softdeletehorse/${Id}`,config);
          swal("Your data has been deleted Successfully!", {
            icon: "success",
          });
          GetSearch();
        } else {
          swal("Your data is safe!");
        }
      });
    } catch (error) {
      const err = error.response.data.message;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
    }
  };


  const handleRemove = async (id) => {
    try {
      swal({
        title: "Are you sure?",
        text: "do you want to delete this data ?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          setLoading(true)
          const res = await axios.delete(`${window.env.API_URL}/DeleteHorseAlltoAll/${id}`);
          const msg = res.data.message ?  res.data.message : "deleted";
          swal(msg, {
            icon: "",
          });
      let data1 =  JSON.stringify(res.data.data)
      const blob = new Blob([data1]);
      if(res?.data?.flag){
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.download = "report1.json";
        link.href = url;
        link.click();
        setLoading(false)
        GetSearch();

      }     
      setLoading(false)
      GetSearch();

        } else {
          swal("Your data is safe!");
        }
      });
    } catch (error) {
      const err = error.success;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
      setLoading(false)

    }
  }




  if (status === STATUSES.ERROR) {
    return (
      <h2
        style={{
          margin: "100px",
        }}
      >
        Something went wrong!
      </h2>
    );
  }

  return (
    <>
      <div className="page ">
        <div className="rightsidedata">
          <div
            style={{
              marginTop: "30px",
            }}
          >
            <div className="Header ">
              <h4>Horse Listings</h4>

              <div>
                <Link to="/horseform">
                  <button>Create Horse</button>
                </Link>
                <OverlayTrigger
                  overlay={<Tooltip id={`tooltip-top`}>Filter</Tooltip>}
                >
                  <span className="addmore">
                    <BiFilter
                      className="calendericon"
                      onClick={handleOpen}
                    />
                  </span>
                </OverlayTrigger>

                <p onClick={() => handleShowCSV()} className="importcsv">
                  Import JSON
                </p>
              </div>
            </div>
            <div>
              {ShowCalender ? (
                <>
                  <div className="userfilter">
                    <div className="filtertextform d-block">
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setSearchTitle(e.target.value)}
                        placeholder="Enter Name"
                      />

                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setSearchAge(e.target.value)}
                        placeholder="Enter Remarks"
                      />
                      <input
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        className="form-control"
                        onChange={(e) => setshortCode(e.target.value)}
                        placeholder="Enter Short Code"
                      />
                    </div>
                    <div className="filtertextform">
                      <div className="searchDropdown">
                        <Select
                          placeholder="Breeder"
                          defaultValue={SearchBreeder}
                          onChange={setBreeder}
                          options={AllBreeder}
                          onInputChange={handleChangeInput3}
                          isClearable={true}
                          isSearchable={true}
                        />
                      </div>
                      <div className="searchDropdown">
                        <Select
                          placeholder="Nationality"
                          defaultValue={NationalityId}
                          onChange={setNationalityId}
                          options={AllNationality}
                          isClearable={true}
                          onInputChange={handleChangeInput}
                          isSearchable={true}
                        />
                      </div>
                      <div className="searchDropdown">
                        <Select
                          placeholder="Color"
                          defaultValue={SearchColorID}
                          onChange={setColor}
                          options={AllColor}
                          isClearable={true}
                          isSearchable={true}
                        />
                      </div>
                    </div>

                    <div className="filtertextform  ">
                      <div className="searchDropdown">
                        <Select
                          placeholder=" Horse Kind"
                          defaultValue={SearchKindHorse}
                          onChange={setKindHorse}
                          options={horsekindoptions}
                          isClearable={true}
                          isSearchable={true}
                        />
                      </div>
                      <div className="searchDropdown">
                        <Select
                          placeholder=" Owner"
                          defaultValue={SearchActiveOwner}
                          onChange={setActiveOwner}
                          options={owneroption}
                          onInputChange={handleChangeInput1}
                          isClearable={true}
                          isSearchable={true}
                        />
                      </div>
                      <div className="searchDropdown">
                        <Select
                          placeholder=" Trainer"
                          defaultValue={SearchActiveTrainer}
                          onChange={setActiveTrainer}
                          options={traineroption}
                          onInputChange={handleChangeInput4}
                          isClearable={true}
                          isSearchable={true}
                        />
                      </div>
                    </div>
                  </div>
                  <button className="filterbtn" onClick={GetSearch}>
                    Apply Filter
                  </button>
                </>
              ) : (
                <></>
              )}
            </div>
            <>
              <div className="div_maintb">
                <ScrollContainer className="scroll-container">
                  <table id="customers">
                    <thead>
                      <tr>
                        <th>Actions</th>
                        <th>Name</th>
                        <th>Name Arabic</th>
                        <th>Short Code</th>

                        <th>Age</th>
                        <th>Sex</th>
                        <th>Color</th>
                        {/* <th>Purchase Price</th> */}
                        <th>Breeder</th>

                        <th>Remarks</th>
                        <th>Remarks Arabic</th>

                        <th>Rds</th>
                        <th>Foal</th>
                        <th>Purchase Price</th>
                        {/* <th>Stars</th> */}
                        <th>Horse Status</th>
                        {/* <th>Height</th> */}
                        <th>Kind Of Horse</th>
                        <th>Nationality</th>
                        {/* <th>Created in</th> */}
                        <th>Active Trainer</th>
                        <th>Owner</th>
                        <th>Dam </th>
                        <th>Sire </th>
                        <th>G sire</th>

                        <th>Image</th>
                      </tr>
                    </thead>

                    {loading ? (
                      <Lottie
                        animationData={HorseAnimation}
                        loop={true}
                        className="TableLottie"
                      />
                    ) : !SearchData ? (
                      <Notfound />
                    ) : (
                      SearchData.map((item) => {
                        return (
                          <>
                            <tbody>
                              <tr className="tr_table_class">
                                <td
                                  className="table_delete_btn1"
                                // style={{ textAlign: "center" }}
                                >
                                  <BiEdit
                                    onClick={() =>
                                      history("/edithorse", {
                                        state: {
                                          horseid: item,
                                        },
                                      })
                                    }
                                  />
                                <MdAutoDelete
                                  style={{
                                    fontSize: "22px",
                                  }}
                                  onClick={() => handleRemove(item._id)}
                                />

                                {userInfo && userInfo.data.role === "admin" ? (
                                 <MdOutlineSecurityUpdateWarning  onClick={() => handleHorseData(item._id)}/>
                                ) : (
                                  <></>
                                )}
                                  
                                  <BsEyeFill onClick={() => handleShow(item)} />
                                  
                                  
                                </td>
                                <td>{item.NameEn}</td>
                                <td>{item.NameAr}</td>
                                <td>
                                  {item.shortCode ? item.shortCode : <>-</>}
                                </td>
                                <td>
                                  <Moment fromNow ago>
                                    {item.DOB}
                                  </Moment>
                                </td>

                                <td>
                                  {item.SexModelData === null ? (
                                    <>N/A</>
                                  ) : (
                                    <>{item.SexModelData.NameEn}</>
                                  )}
                                </td>

                                <td>
                                  {item.ColorIDData === null ? (
                                    <>N/A</>
                                  ) : (
                                    item.ColorIDData.NameEn
                                  )}{" "}
                                </td>

                                {/* <td>{item.PurchasePrice}</td> */}
                                <td>
                                  {item.BreederData === null ? (
                                    <>No Data</>
                                  ) : (
                                    <>{item.BreederData.NameEn}</>
                                  )}
                                </td>
                                <td className="cell">
                                  {item.RemarksEn ? item.RemarksEn : <> - </>}
                                </td>
                                <td className="cell">
                                  {item.RemarksAr ? item.RemarksAr : <> - </>}
                                </td>

                                <td>
                                  {item.Rds === true ? <>Yes</> : <>No</>}
                                </td>
                                <td>{item.Foal}</td>
                                <td>{item.PurchasePrice}</td>

                                <td>
                                  {item.HorseStatus === true ? (
                                    <>running</>
                                  ) : (
                                    <>not running</>
                                  )}
                                </td>

                                <td>
                                  {" "}
                                  {item.KindHorseData ? (
                                    item.KindHorseData.NameEn
                                  ) : (
                                    <>-</>
                                  )}
                                </td>
                                <td>
                                  {item.NationalityData ? (
                                    item.NationalityData.NameEn
                                  ) : (
                                    <>-</>
                                  )}
                                </td>

                                <td>
                                  {item.ActiveTrainerData ? (
                                    item.ActiveTrainerData.NameEn
                                  ) : (
                                    <>-</>
                                  )}
                                </td>
                                <td>
                                  {item.ActiveOwnerData ? (
                                    item.ActiveOwnerData.NameEn
                                  ) : (
                                    <>-</>
                                  )}
                                </td>
                                <td>
                                  {item.DamData ? item.DamData.NameEn : <>-</>}
                                </td>
                                <td>
                                  {item.SireData ? (
                                    item.SireData.NameEn
                                  ) : (
                                    <>-</>
                                  )}
                                </td>
                                <td>
                                  {item.GSireData ? (
                                    item.GSireData.NameEn
                                  ) : (
                                    <>-</>
                                  )}
                                </td>
                                

                                <td>
                                  <img
                                    src={
                                      item.HorseImage
                                        ? item.HorseImage
                                        : DefaulImg
                                    }
                                    alt=""
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  ></img>
                                </td>
                              </tr>
                            </tbody>
                          </>
                        );
                      })
                    )}
                  </table>
                </ScrollContainer>
              </div>
            </>
          </div>
          <Pagination
            postsPerPage={postsPerPage}
            totalPosts={TotalCount}
            paginate={paginate}
            currentPage={currentPage}
            TotalPages={TotalPages}
          />
        </div>
      </div>

      <HorseDeletePopup data={SaveHorseId} show={modalShow} onHide={() => setModalShow(false)} />



      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2>Horse </h2>
        </Modal.Header>
        <Modal.Body>
          <HorsePopup data={modaldata} />
        </Modal.Body>

        <Modal.Footer>
          <button onClick={handleClose} className="modalClosebtn">
            Close
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showCSV}
        onHide={handleCloseCSV}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2 style={{ fontFamily: "inter" }}>Horse JSON</h2>
        </Modal.Header>
        <Modal.Body>
          <CSVHorse data={modaldataCSV} />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};
export default Horse;
