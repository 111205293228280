import React, { useEffect, Fragment, useState } from "react";
import { fetchpointTable, STATUSES } from "../../redux/getReducer/getPointTable";
import { useDispatch, useSelector } from "react-redux";
import { MdAutoDelete, MdDelete } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import ScrollContainer from "react-indiana-drag-scroll";
import Lottie from "lottie-react";
import HorseAnimation from "../../assets/horselottie.json";
import axios from "axios";
import { BiEdit } from "react-icons/bi";
import { Modal } from "react-bootstrap";
import { BsEyeFill } from "react-icons/bs"
import Pagination from "./Pagination";
import { BiFilter } from 'react-icons/bi';
import { CSVLink } from "react-csv";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Notfound from "../../Notfound";
import Select from "react-select";
import { fetchGroupName } from '../../redux/getReducer/getGroupName'



const BonusPoint = (data) => {
  return (
    <>
      <table className="Prizeclass">
        <thead className="Prizeclassthead">
          <tr>
            <th>Type </th>
            <th>Rank </th>
            <th>Point </th>
            <th>Bonus Point</th>




          </tr>
        </thead>
        <tbody className="Prizeclasstbody">
          {
            data.data.PointSystemidDataOfCompetition ? data.data.PointSystemidDataOfCompetition.map((item) => {
              return (
                <tr>
                  <td>{item.Type}</td>
                  <td>{item.Rank}</td>

                  <td>{item.Point}</td>
                  <td>{item.BonusPoint}</td>

                </tr>
              )
            }) : <></>
          }
          <tr>
            {/* <td>{data.data.FirstPrice}</td>
            <td>{data.data.SecondPrice}</td>
            <td>{data.data.ThirdPrice}</td>
            <td>{data.data.FourthPrice}</td>
            <td>{data.data.FifthPrice}</td>
            <td>{data.data.SixthPrice}</td> */}
          </tr>
        </tbody>
      </table>
    </>
  );
};

const Pointstable = () => {
  //---------------------------state-------------------------//

  const { userInfo } = useSelector((state) => state.user);

  const [ShowCalender, setShowCalender] = useState(false)
  const [SearchAge, setSearchAge] = useState('');
  const [SearchCode, setSearchCode] = useState('');
  const [TotalCount, setTotalCount] = useState()
  const [TotalPages, setTotalPages] = useState('');
  //---------------------------modal-------------------------//

  const [modaldata, setmodaldata] = useState();
  const [show, setShow] = useState(false);
  const [Loading, setLoading] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = async (data) => {
    setmodaldata(data);
    await setShow(true);
  };



  const CategoryType = [
    {
      id: 1,
      value: "Pick",
      label: "Pick",
    },
    {
      id: 2,
      value: "Cast",
      label: "Cast",
    },
  ];


  const dispatch = useDispatch();
  const history = useNavigate();
  const { data: pointTable, status } = useSelector((state) => state.pointTable);
  const { data: groupName } = useSelector((state) => state.groupName);

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(8)


  const paginate = pageNumber => setCurrentPage(pageNumber);
  const [Type, setType] = useState("");
  const [Group_Name, setGroup_Name] = useState("");

  let AllGroup =
  groupName === undefined ? (
    <></>
  ) : (
    groupName.map(function (item) {
      return {
        id: item._id,
        value: item.NameEn,
        label: item.NameEn,
      };
    })
  );

  //---------------------------api-------------------------//

  // const Data1 = async () => {
  //   const res = await axios.get(`${window.env.API_URL}PointTableSystemget?Group_Name=${SearchTitle}&Rank=${SearchAge}&page=${currentPage}`);
  //   const totalcount = res.data.totalcount;
  //   setTotalCount(totalcount);
  //   const TotalPagesData = res.data.totalPages;
  //   setTotalPages(TotalPagesData)
  // };
  //---------------------------search-------------------------//

  const SearchTitle = Group_Name ? Group_Name.id : ''
  const Type1 = Type ? Type.value : ''

  const GetSearch = async () => {
    dispatch(fetchpointTable({ SearchTitle, Type1, SearchAge, }));
  };


  useEffect(() => {
    dispatch(fetchpointTable({ SearchTitle, Type, SearchAge, }));
    dispatch(fetchGroupName());
    // Data1()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, currentPage]);
  //---------------------------delete-------------------------//

  const handleRemove1 = async (Id) => {
    try {
      swal({
        title: "Are you sure?",
        text: "do you want to delete this data ?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          const res = await axios.delete(`${window.env.API_URL}/VerifyAndDeletePointTableSystem/${Id}`);
          const msg = res.data.message
                //    const msg = res.
                    swal(msg, {
                        icon: "",
                    });
          dispatch(fetchpointTable({ SearchTitle, SearchCode, SearchAge }));
        } else {
          swal("Your data is safe!");
        }
      });
    } catch (error) {
      const err = error.response.data.message;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
    }
  };


  const handleRemove = async (id) => {
    try {
      swal({
        title: "Are you sure?",
        text: "do you want to delete this data ?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          setLoading(true)
          const res = await axios.delete(`${window.env.API_URL}/DeletePointTableSystemAlltoAll/${id}`);
          const msg = res.data.message ?  res.data.message : "deleted";
          swal(msg, {
            icon: "",
          });
      let data1 =  JSON.stringify(res.data.data)
      const blob = new Blob([data1]);
      if(res?.data?.flag){
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.download = "report1.json";
        link.href = url;
        link.click();
        setLoading(false)
            GetSearch({ currentPage });

      }     
      setLoading(false)
          GetSearch({ currentPage });

        } else {
          swal("Your data is safe!");
        }
      });
    } catch (error) {
      const err = error.success;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
      setLoading(false)

    }
  }





  if (status === STATUSES.ERROR) {
    return (
      <h2
        style={{
          margin: "100px",
        }}
      >
        Something went wrong!
      </h2>
    );
  }

  return (
    <Fragment>
      <div className="page">
        <div className="rightsidedata">
          <div
            style={{
              marginTop: "30px",
            }}
          >
            <div className="Header ">
              <h4>Point Table Listings</h4>

              <div>
                <h6
                  style={{
                    marginRight: "100px",
                    alignItems: "center",
                    color: "rgba(0, 0, 0, 0.6)",
                  }}
                >

                </h6>

                <Link to="/addcompetitionPoint">
                  <button>Add Point Table</button>
                </Link>
                <OverlayTrigger
                  overlay={<Tooltip id={`tooltip-top`}>Filter</Tooltip>}
                >
                  <span
                    className="addmore"
                  >
                    <BiFilter
                      className="calendericon"
                      onClick={() => setShowCalender(!ShowCalender)}
                    />
                  </span>
                </OverlayTrigger>
                <CSVLink data={pointTable} separator={";"} filename={"MKS Point Table.csv"} className='csvclass'>
                  Export CSV
                </CSVLink>
              </div>
            </div>
            <div>

              {
                ShowCalender ?
                  <span className="transitionclass">
                    <div className="userfilter">

                      <div className="filtertextform forflex">

                      <div className="searchDropdown">
                       <Select
                    placeholder={<div>Group Name</div>}
                    defaultValue={Group_Name}
                    onChange={setGroup_Name}
                    options={AllGroup}
                    isClearable={true}
                    isSearchable={true}
                  />
                  </div>
                       <div className="searchDropdown">
                       <Select
                    placeholder={<div>Type/Category</div>}
                    defaultValue={Type}
                    onChange={setType}
                    options={CategoryType}
                    isClearable={true}
                    isSearchable={true}
                  />
                  </div>
                      </div>

                    </div>
                    <button className="filterbtn" onClick={GetSearch}>
                      Apply Filter
                    </button>
                  </span>
                  : <></>
              }
            </div>
            <>
              <div className="div_maintb">
                <ScrollContainer>
                  <table>
                    <thead>
                      <tr>
                        <th>Action</th>

                        <th>Point Group Name</th>

                        <th>Length</th>
                        <th>Point System </th>

                        <th>Short Code</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        pointTable === [] ? <Notfound /> :
                          status === "loading" ?

                            <Lottie animationData={HorseAnimation} loop={true} className="TableLottie" />

                            :
                            pointTable.map((item, index) => {
                              return (
                                <>
                                  <tr className="tr_table_class">
                                    <td className="table_delete_btn1">
                                      <BiEdit

                                        onClick={() =>
                                          history("/editpoint", {
                                            state: {
                                              pointid: item,
                                            },
                                          })
                                        }
                                      />
                                         <MdAutoDelete
                                    style={{
                                      fontSize: "22px",
                                    }}
                                    onClick={() => handleRemove(item._id)}
                                  />
                                      <BsEyeFill
                                        onClick={() => handleShow(item)}
                                      />
                                    </td>
                                    <td>{item.Group_NameDataOfCompetition ? item.Group_NameDataOfCompetition.NameEn : <></>}</td>

                                    <td>{item.Length}</td>

                                    <td>
                                      <button
                                        className="Approvedbtn resultbtn"
                                        onClick={() => handleShow(item)}
                                      >
                                        Click
                                      </button>
                                    </td>

                                    <td>{item.shortCode} </td>


                                  </tr>
                                </>
                              );
                            })}
                    </tbody>
                  </table>
                </ScrollContainer>
              </div>
            </>
          </div>
          <span className="plusIconStyle"></span>
          <Pagination
            postsPerPage={postsPerPage}
            totalPosts={TotalCount}
            paginate={paginate}
            currentPage={currentPage}
            TotalPages={TotalPages}

          />
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2>Point Table  </h2>
        </Modal.Header>
        <Modal.Body>
          <BonusPoint data={modaldata} />
        </Modal.Body>
        <Modal.Footer>
          <button onClick={handleClose} className="modalClosebtn">
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default Pointstable;
