import axios from "axios";
import { createAsyncThunk,createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
    IDLE : 'idle',
    ERROR:'error',
    LOADING: 'loading',
});

const getAllResultsSlice = createSlice({
    name: 'AllResults',
    initialState: {
        data:[],
        status : STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
        .addCase(fetchAllResults.pending, (state, action) => {
            state.status = STATUSES.LOADING;
        })
        .addCase(fetchAllResults.fulfilled, (state, action) => {
            state.data = action.payload;
            state.status = STATUSES.IDLE
        })
        .addCase(fetchAllResults.rejected , (state,action) => {
            state.status = STATUSES.ERROR;
        })
    }
});

export const {setAllResults , setStatus} = getAllResultsSlice.actions;
export default getAllResultsSlice.reducer;

export const fetchAllResults = createAsyncThunk('/AllResultsget/fetch', async() => {
    const res = await axios.get(`${window.env.API_URL}/AllDeclaredRaces`);
    const AllResultsData = res.data;
    return AllResultsData.data;
})