import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});

const getActiveThemeSlice = createSlice({
    name: 'ActiveTheme',
    initialState: {
        data: [],
        status: STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchActiveTheme.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(fetchActiveTheme.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.IDLE
            })
            .addCase(fetchActiveTheme.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
    }
});

export const { setActiveTheme, setStatus } = getActiveThemeSlice.actions;
export default getActiveThemeSlice.reducer;

export const fetchActiveTheme = createAsyncThunk('/ActiveThemeget/fetch', async ({id}) => {
    const res = await axios.put(`${window.env.API_URL}/ActiveTheme/${id}`);
    const ActiveThemeData = res.data;
    return ActiveThemeData.data;
})