import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});

const getRaceKindSlice = createSlice({
    name: 'raceKinddropdown',
    initialState: {
        data: [],
        status: STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchRaceKindDropdown.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(fetchRaceKindDropdown.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.IDLE
            })
            .addCase(fetchRaceKindDropdown.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
    }
});

export const { setRaceKind, setStatus } = getRaceKindSlice.actions;
export default getRaceKindSlice.reducer;

export const fetchRaceKindDropdown = createAsyncThunk('/RaceKind/fetch', async ({ value }) => {
    const res = await axios.get(`${window.env.API_URL}/searchracekind?NameEn=${value === undefined ? '' : value}&size=3000`);
    const RaceKindData = res.data;
    return RaceKindData.data;
})