import React, { Fragment, useState, useEffect } from "react";
import { fetchTrainer } from "../../redux/getReducer/getTrainerSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Select from "react-select";
import { fetchOwnerList } from "../../redux/getDropDownReducer/getOwnerList";
import { fetchHorseList } from "../../redux/getDropDownReducer/getHorseList";
import { fetchColorDropdown } from "../../redux/getDropDownReducer/getColor";
import { fetchBreederList } from "../../redux/getDropDownReducer/getBreederList";
import { fetchNationalityList } from "../../redux/getDropDownReducer/getNationalityList";
import { fetchgender } from "../../redux/getReducer/getGenderSlice";
import { fetchHorseKindData } from "../../redux/getDropDownReducer/getHorseKindData";
import { fetchTrainerList } from "../../redux/getDropDownReducer/getTrainerList";

import { fetchHorseKind } from "../../redux/getReducer/getHorseKind";
import DatePicker from "react-date-picker";
import swal from "sweetalert";
import axios from "axios";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { Modal } from "react-bootstrap";
import { AiOutlineReload } from "react-icons/ai";
import TextInputValidation from "../../utils/TextInputValidation";
import BreederPopup from './Breeder';
import ColorPopup from './Color';
import GenderPopup from './Gender';
import OwnerPopup from "./Owner/OwnerForm";
import TrainerPopup from "./PostTrainer";
import NationalityPopup from "./Nationality";
import HorseKindPopup from "./Horsekindform";
import { ImCross } from 'react-icons/im';
import Moment from "react-moment";
import { useLocation } from "react-router-dom";


//------------------------options for dropdown-----------------------//
const Gelted = [
  {
    id: "0", value: "false", label: (
      <div style={{
        display: 'flex',
        justifyContent: 'space-between'
      }}>
        <p>false</p>
        <p>خاطئة</p>

      </div>
    ),
  },
  {
    id: "1", value: "true", label: (
      <div style={{
        display: 'flex',
        justifyContent: 'space-between'
      }}>
        <p>true</p>
        <p>حقيقي</p>

      </div>
    )
  },
];


const HorseStatusAll = [
  {
    id: "0", value: "false", label: (
      <div style={{
        display: 'flex',
        justifyContent: 'space-between'
      }}>
        <p>false</p>
        <p>خاطئة</p>

      </div>
    ),
  },
  {
    id: "1", value: "true", label: (
      <div style={{
        display: 'flex',
        justifyContent: 'space-between'
      }}>
        <p>true</p>
        <p>حقيقي</p>

      </div>
    )
  },
];


const Foals = [
  { id: "0", value: "1", label: "1" },
  { id: "1", value: "2", label: "2" },
  { id: "2", value: "3", label: "3" },
  { id: "3", value: "4", label: "4" },
  { id: "4", value: "5", label: "5" },
  { id: "5", value: "6", label: "6" },
  { id: "6", value: "7", label: "7" },
  { id: "7", value: "8", label: "8" },
  { id: "8", value: "9", label: "9" },
  { id: "9", value: "10", label: "10" },
];

const HorseForm = () => {
  const { pathname } = useLocation();

  //for error
  const [Error, setError] = useState("");
  const [ErrorAr, setErrorAr] = useState("");
  const [ErrorRemarks, setErrorRemarks] = useState("");

  const [ErrorRds, setErrorRds] = useState("");
  const [ErrorHorseKind, setErrorHorseKind] = useState("");
  const [ErrorHorseStatus, setErrorHorseStatus] = useState("");
  const [ErrorPurchase, setErrorPurchase] = useState("");
  const [ErrorFoal, setErrorFoal] = useState("");
  const [ErrorBreeder, setErrorBreeder] = useState("");
  const [ErrorColor, setErrorColor] = useState("");
  const [ErrorGender, setErrorGender] = useState("");

  const [ErrorOwner, setErrorOwner] = useState("");
  const [ErrorTrainer, setErrorTrainer] = useState("");
  const [ErrorGelded, setErrorGelded] = useState("");
  const [ErrorNationality, setErrorNationality] = useState("");

  const [ErrorRemarksAr, setErrorRemarksAr] = useState("");



  const [Value1, setValue1] = useState('');
  const [value, setvalue] = useState('');
  const [Value2, setValue2] = useState('');
  const [Value3, setValue3] = useState('');
  const [Value4, setValue4] = useState('');
  const [Value5, setValue5] = useState('');

  const [KindHorse, setKindHorse] = useState("");


  const dispatch = useDispatch();
  const history = useNavigate();
  //------------------------data from redux-----------------------//

  const { data: TrainerList } = useSelector((state) => state.TrainerList);
  const { data: OwnerList } = useSelector((state) => state.OwnerList);
  const { data: colordropdown } = useSelector((state) => state.colordropdown);
  const { data: BreederList } = useSelector((state) => state.BreederList);
  const { data: NationalityList } = useSelector((state) => state.NationalityList);
  const { data: gender } = useSelector((state) => state.gender);
  const { data: HorseKind } = useSelector((state) => state.HorseKind);
  const { data: HorseKindData } = useSelector((state) => state.HorseKindData);



  const KindHorseid = KindHorse === '' ? "" : KindHorse.id;


  let data = ''
  useEffect(() => {
    dispatch(fetchHorseKindData({ Value1, KindHorseid }));
  }, [dispatch, Value1, KindHorse, KindHorseid])
  useEffect(() => {
    dispatch(fetchOwnerList({ Value2 }));
  }, [dispatch, Value2]);
  useEffect(() => {
    dispatch(fetchBreederList({ Value3 }));
  }, [dispatch, Value3]);
  useEffect(() => {
    dispatch(fetchTrainerList({ Value4 }));
  }, [dispatch, Value4]);
  useEffect(() => {
    dispatch(fetchColorDropdown({data}));
    // dispatch(fetchBreederList());
    dispatch(fetchHorseKind({ SearchTitle, SearchCode, SearchAge }));
  }, [dispatch]);

  useEffect(() => {
    const SearchTitle = Value5
    dispatch(fetchgender({ SearchTitle, SearchCode, SearchAge }));

  },[dispatch,Value5])

  useEffect(() => {
    dispatch(fetchNationalityList({value}));
  },[dispatch,value])

  //------------------------options for dropdown-----------------------//
  let horseoptions =
    HorseKindData === undefined ? (
      <></>
    ) : (
      HorseKindData.map(function (item) {
        return {
          id: item._id,
          value: item.NameEn,
          label: (
            <div style={{
              display: 'flex',
              justifyContent: 'space-between'
            }}>
              <p>{item.NameEn}({item.NationalityData ? item.NationalityData.NameEn : <></>})</p>
              <p>{item.NameAr}({item.NationalityData ? item.NationalityData.NameAr : <></>})</p>

            </div>
          ),
        };
      })
    );

  let horsekindoptions =
    HorseKind === undefined ? (
      <></>
    ) : (
      HorseKind.map(function (item) {
        return {
          id: item._id,
          value: item.NameEn,
          label: (
            <div style={{
              display: 'flex',
              justifyContent: 'space-between'
            }}>
              <p>{item.NameEn}</p>
              <p>{item.NameAr}</p>

            </div>
          ),
        };
      })
    );

  let traineroption =
    TrainerList === undefined ? (
      <></>
    ) : (
      TrainerList.map(function (item) {
        return {
          id: item._id,
          value: item.NameEn,
          label: (
            <div style={{
              display: 'flex',
              justifyContent: 'space-between'
            }}>
              <p>{item.NameEn}</p>
              <p>{item.NameAr}</p>

            </div>
          ),
        };
      })
    );

  let owneroption =
    OwnerList === undefined ? (
      <></>
    ) : (
      OwnerList.map(function (item) {
        return {
          id: item._id,
          value: item.NameEn,
          label: (
            <div style={{
              display: 'flex',
              justifyContent: 'space-between'
            }}>
              <p>{item.NameEn}</p>
              <p>{item.NameAr}</p>

            </div>
          ),
        };
      })
    );

  let AllColor =
    colordropdown === undefined ? (
      <></>
    ) : (
      colordropdown.map(function (item) {
        return {
          id: item._id,
          value: item.NameEn,
          label: (
            <div style={{
              display: 'flex',
              justifyContent: 'space-between'
            }}>
              <p>{item.NameEn}</p>
              <p>{item.NameAr}</p>

            </div>
          ),
        };
      })
    );


  let AllBreeder =
    BreederList === undefined ? (
      <></>
    ) : (
      BreederList.map(function (item) {
        return {
          id: item._id,
          value: item.NameEn,
          label: (
            <div style={{
              display: 'flex',
              justifyContent: 'space-between'
            }}>
              <p>{item.NameEn}</p>
              <p>{item.NameAr}</p>

            </div>
          ),
        };
      })
    );


  let AllNationality =
    NationalityList === undefined ? (
      <></>
    ) : (
      NationalityList.map(function (item) {
        return {
          id: item._id,
          value: item.NameEn,
          label: (
            <div style={{
              display: 'flex',
              justifyContent: 'space-between'
            }}>
              <p>{item.NameEn}</p>
              <p>{item.NameAr}</p>

            </div>
          ),
        };
      })
    );


  let AllGender =
    gender === undefined ? (
      <></>
    ) : (
      gender.map(function (item) {
        return {
          id: item._id,
          value: item.NameEn,
          label: (
            <div style={{
              display: 'flex',
              justifyContent: 'space-between'
            }}>
              <p>{item.NameEn}</p>
              <p>{item.NameAr}</p>

            </div>
          ),
        };
      })
    );

  const handleChangeInput = value => {
    setValue1(value)
  }

  const handleChangeInputnationality = value => {
    setvalue(value)
  }


  const handleChangeInput1 = value => {
    setValue2(value)
  }

  const handleChangeInput2 = value => {
    setValue3(value)
  }

  const handleChangeInput3 = value => {
    setValue4(value)
  }


  const handleChangeInputSex = value => {
    setValue5(value)
  }



  //------------------------Modal-----------------------//
  const [showBreeder, setShowBreeder] = useState(false);
  const [showColor, setShowColor] = useState(false);
  const [showGender, setShowGender] = useState(false);
  const [showActiveOwner, setShowActiveOwner] = useState(false);
  const [showActiveTrainer, setShowActiveTrainer] = useState(false);
  const [showActivenationality, setShowActivenationality] = useState(false);
  const [showHorseKind, setShowHorseKind] = useState(false);
  const [isLoading, setisLoading] = useState(false);




  const handleCloseBreeder = () => setShowBreeder(false);
  const handleCloseColor = () => setShowColor(false);
  const handleCloseGender = () => setShowGender(false);
  const handleCloseActiveOwner = () => setShowActiveOwner(false);
  const handleCloseActiveTrainer = () => setShowActiveTrainer(false);
  const handleCloseActivenationality = () => setShowActivenationality(false);
  const handleCloseHorseKind = () => setShowHorseKind(false);

  const handleShowBreeder = async () => {
    await setShowBreeder(true);
  };

  const handleShowColor = async () => {
    await setShowColor(true);
  };

  const handleShowGender = async () => {
    await setShowGender(true);
  };

  const handleShowActiveOwner = async () => {
    await setShowActiveOwner(true);
  };

  const handleShowActiveTrainer = async () => {
    await setShowActiveTrainer(true);
  };

  const handleShowActivenationality = async () => {
    await setShowActivenationality(true);
  };

  const handleShowHorseKind = async () => {
    await setShowHorseKind(true);
  };



  const SearchTitle = ""
  const SearchCode = ""
  const SearchAge = ""
  const FetchNew = () => {
    dispatch(fetchOwnerList({ SearchTitle, SearchCode, SearchAge }));
    dispatch(fetchTrainerList({ Value4 }));
    dispatch(fetchHorseList());
    dispatch(fetchColorDropdown());
    dispatch(fetchBreederList());
    dispatch(fetchNationalityList());
    dispatch(fetchgender({ SearchTitle, SearchCode, SearchAge }));
    dispatch(fetchHorseKind({ SearchTitle, SearchCode, SearchAge }));

  };
  // Modal functionalities End Here

  const [ActiveOwner, setActiveOwner] = useState("");
  const [NameEn, setNameEn] = useState("");
  const [NameAr, setNameAr] = useState("");
  const [ActiveTrainer, setActiveTrainer] = useState("");
  const [Breeder, setBreeder] = useState("");
  const [RemarksEn, setRemarksEn] = useState("");
  const [RemarksAr, setRemarksAr] = useState("");
  const [HorseStatus, setHorseStatus] = useState("");
  const [Sex, setSex] = useState("");
  const [ColorID, setColor] = useState("");


  const [Dam, setDam] = useState("");
  const [Sire, setSire] = useState("");
  const [DOB, setDOB] = useState("");

  const [GSire, setGSire] = useState("");
  const [HorseCode, setHorseCode] = useState("");
  const [image, setimage] = useState();
  const [Foal, setFoal] = useState("");

  const [CodeMsg, setCodeMsg] = useState('');
  const [isGelted, setisGelted] = useState(false);
  const [NationalityId, setNationalityId] = useState("");

  const [Rds, setRds] = useState("");

  const [preview, setPreview] = useState();
  const [Dstate, setDstate] = useState({
    PurchasePrice: 0,
  });
  var today = new Date();

  //------------------------Submit-----------------------//
  const submit = async (event) => {
    event.preventDefault();
    setisLoading(true)
    try {
      const formData = new FormData();
      formData.append("image", image);
      formData.append("NameEn", NameEn);
      formData.append("DOB", DOB === '' ? today : DOB);
      formData.append("Height", 0)
      formData.append("NameAr", NameAr);
      formData.append("RemarksEn", RemarksEn);
      formData.append("RemarksAr", RemarksAr);
      formData.append("ActiveOwner", ActiveOwner.id);

      formData.append("HorseStatus", HorseStatus.value === undefined ? 1 : HorseStatus.value);
      formData.append("ActiveTrainer", ActiveTrainer.id);
      formData.append("Sex", Sex.id);
      formData.append("Breeder", Breeder.id);
      formData.append("ColorID", ColorID.id);
      formData.append("KindHorse", KindHorse.id);
      formData.append("Dam", Dam === '' ? '' : Dam.id);
      formData.append("Sire", Sire === '' ? '' : Sire.id);
      formData.append("GSire", GSire === '' ? '' : GSire.id);

      formData.append("Foal", Foal.value === undefined ? 1 : Foal.value);

      formData.append("Rds", Rds.value === undefined ? 0 : Rds.value);
      formData.append("STARS", 0);
      formData.append("isGelded", isGelted.value === undefined ? 0 : isGelted.value);
      formData.append("NationalityID", NationalityId.id);
      formData.append("CreationId", NationalityId.id);
      formData.append("PurchasePrice", Dstate.PurchasePrice);
      await axios.post(
        `${window.env.API_URL}createhorse?keyword=&page=`,
        formData
      );
      setisLoading(false)

      swal({
        title: "Success!",
        text: "Data has been added Successfully",
        icon: "success",
        button: "OK",
      });
     
    } catch (error) {
      const err = error.response.data.message;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
      setisLoading(false)
    }
  };
  //------------------------Code Verification-----------------------//
  const VerifyCode = async (event) => {
    event.preventDefault();
    try {
      const formData = new FormData();
      formData.append("shortCode", HorseCode);
      const response = await axios.post(
        `${window.env.API_URL}VerifyShortCode`,
        formData
      );
      console.log(response.data.data)
      const resdata = response.data.data === null ? "Yes You can use it" : `Already exit in ${response.data.data.NameEn}`
      setCodeMsg(resdata);

    } catch (error) {


    }
  };
  //------------------------Image Preview-----------------------//
  useEffect(() => {
    if (!image) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(image);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [image]);

  const onSelectFile = (e) => {
    setimage(e.target.files[0]);

  };
  //------------------------remove preview-----------------------//
  const handlePreview = () => {
    setimage()
    document.getElementById("file").value = ""
  };
  //------------------------input validation -----------------------//

  const data1 = JSON.stringify(
    TextInputValidation("en", NameEn, "Horse English Name ")
  );
  const obj = JSON.parse(data1);

  const data2 = JSON.stringify(
    TextInputValidation("ar", NameAr, "Horse Arabic Name ")
  );
  const objAr = JSON.parse(data2);


  const data3 = JSON.stringify(
    TextInputValidation("en", RemarksEn, "Horse Remarks English ")
  );
  const remark = JSON.parse(data3);

  const data4 = JSON.stringify(
    TextInputValidation("ar", RemarksAr, "Horse Remarks Arabic  ")
  );
  const remarkar = JSON.parse(data4);

  return (
    <Fragment>
      <div className="page">
        <div className="rightsidedata">
          <div
            style={{
              marginTop: "30px",
            }}
          >
            <div className="Headers">Add Horse</div>
            <div className="form">
              <form onSubmit={submit}>

                <div className="row mainrow">
                  <div className="col-sm" style={{
                    marginTop: '10px'
                  }}>
                    <Select
                      style={{
                        marginTop: '10px'
                      }}
                      placeholder={<div>Select Horse Kind</div>}
                      defaultValue={KindHorse}
                      onChange={setKindHorse}
                      options={horsekindoptions}
                      isSearchable={true}
                      onBlur={() => setKindHorse === "" ? setErrorHorseKind("Horse Kind is required ") : setErrorHorseKind("Horse Kind is Validated ")}

                    />
                    <span className="spanForm abbbbb">
                      <OverlayTrigger
                        overlay={<Tooltip id={`tooltip-top`}>Add more</Tooltip>}
                      >
                        <span className="addmore" onClick={handleShowHorseKind}>
                          +
                        </span>
                      </OverlayTrigger>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id={`tooltip-top`}>Fetch New</Tooltip>
                        }
                      >
                        <span className="addmore" onClick={FetchNew}>
                          <AiOutlineReload />
                        </span>
                      </OverlayTrigger>{" "}

                    </span>
                    <span className={KindHorse === "" ? "error" : "success"}>{ErrorHorseKind}</span>
                  </div>

                </div>
                <div className="row mainrow">
                  <div className="col-sm">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Horse Code"
                      className="mb-3"
                      onChange={(e) => setHorseCode(e.target.value)}
                      name="Name"
                      value={HorseCode}
                      onBlur={() => setError(obj)}
                    >
                      <Form.Control type="text" placeholder=" Horse Code" required />
                    </FloatingLabel>
                    <span className="spanForm"> |</span>
                    <span className="CodeMsg">{CodeMsg}</span>

                  </div>
                  <div className="col-sm">
                    <p onClick={VerifyCode} className="verifybtn">Verify Code</p>

                  </div>

                </div>
                <div className="row mainrow">
                  <div className="col-sm">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Horse Name"
                      className="mb-3"
                      onChange={(e) => setNameEn(e.target.value)}
                      name="Name"
                      value={NameEn}
                      onBlur={() => setError(obj)}
                    >
                      <Form.Control type="text" placeholder=" Horse Name" required />
                    </FloatingLabel>

                    <span className="spanForm"> |</span>
                    {/* <span className={Error.status ? "success" : "error"}>{Error.message}</span> */}
                  </div>

                  <div className="col-sm">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="اسم"
                      className="mb-3 floatingInputAr"
                      onChange={(e) => setNameAr(e.target.value)}
                      name="Name"
                      value={NameAr}
                      style={{ direction: "rtl" }}
                      onBlur={() => setErrorAr(objAr)}

                    >
                      <Form.Control type="text" placeholder="اسم" required />
                    </FloatingLabel>
                    <span className={ErrorAr.status ? "successAr" : "errorAr"}>{ErrorAr.message}</span>
                  </div>
                </div>
                <div className="row mainrow">
                  <div className="col-sm">
                    <DatePicker
                      onChange={setDOB}
                      value={DOB}
                      dayPlaceholder=""
                      maxDate={today}
                      monthPlaceholder="Date of Birth"
                      yearPlaceholder=""

                    />

                  </div>
                

                </div>
                <div className="row mainrow">
                  <div className="col-sm">
                    <Select
                      placeholder={<div>Select Foal</div>}
                      defaultValue={Foals[0]}
                      // value={Foal}
                      onChange={setFoal}
                      options={Foals}
                      className="basic-single"
                      classNamePrefix="select"
                      isClearable={true}
                      isSearchable={true}
                      onBlur={(e) => Foal === "" ? setErrorFoal("Foal is required ") : setErrorFoal("Foal is Validated ")}
                    />

                    <span className={Foal === "" ? "error" : "success"}>{ErrorFoal}</span>
                  </div>


                </div>
                <div className="row mainrow">
                  <div className="col-sm">
                    <Select
                      placeholder={<div>Type to search Nationality</div>}
                      defaultValue={NationalityId}
                      onChange={setNationalityId}
                      options={AllNationality}
                      onInputChange={handleChangeInputnationality}

                      isClearable={true}
                      isSearchable={true}
                      onBlur={() => NationalityId === "" ? setErrorNationality("Horse Nationality is required ") : setErrorNationality("Horse Nationality is Validated")}
                    />
                    <span className="spanForm abbbbb">
                      <OverlayTrigger
                        overlay={<Tooltip id={`tooltip-top`}>Add more</Tooltip>}
                      >
                        <span className="addmore" onClick={handleShowActivenationality}>
                          +
                        </span>
                      </OverlayTrigger>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id={`tooltip-top`}>Fetch New</Tooltip>
                        }
                      >
                        <span className="addmore" onClick={FetchNew}>
                          <AiOutlineReload />
                        </span>
                      </OverlayTrigger>

                    </span>
                    <span className={NationalityId === "" ? "error" : "success"} >{ErrorNationality}</span>

                  </div>

                </div>
                <div className="row mainrow">
                  <div className="col-sm">
                    <Select
                      placeholder={<div>Select Color</div>}
                      defaultValue={ColorID}
                      onChange={setColor}
                      options={AllColor}
                      isClearable={true}
                      isSearchable={true}
                      onBlur={() => ColorID === "" ? setErrorColor("Horse Color is required ") : setErrorColor("Horse Color is Validated")}

                    />
                    <span className="spanForm abbbbb">
                      <OverlayTrigger
                        overlay={<Tooltip id={`tooltip-top`}>Add more</Tooltip>}
                      >
                        <span className="addmore" onClick={handleShowColor}>
                          +
                        </span>
                      </OverlayTrigger>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id={`tooltip-top`}>Fetch New</Tooltip>
                        }
                      >
                        <span className="addmore" onClick={FetchNew}>
                          <AiOutlineReload />
                        </span>
                      </OverlayTrigger>{" "}

                    </span>
                    <span className={ColorID === "" ? "error" : "success"}>{ErrorColor}</span>
                  </div>

                </div>
                <div className="row mainrow">
                  <div className="col-sm">
                    <Select
                      placeholder={<div>Select Gender</div>}
                      defaultValue={Sex}
                      onChange={setSex}
                      options={AllGender}
                      onInputChange={handleChangeInputSex}

                      isClearable={true}
                      isSearchable={true}
                      onBlur={() => Sex === "" ? setErrorGender("Horse Gender is required ") : setErrorGender("Horse Gender is Validated ")}


                    />
                    <span className="spanForm abbbbb">
                      <OverlayTrigger
                        overlay={<Tooltip id={`tooltip-top`}>Add more</Tooltip>}
                      >
                        <span className="addmore" onClick={handleShowGender}>
                          +
                        </span>
                      </OverlayTrigger>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id={`tooltip-top`}>Fetch New</Tooltip>
                        }
                      >
                        <span className="addmore" onClick={FetchNew}>
                          <AiOutlineReload />
                        </span>
                      </OverlayTrigger>{" "}

                    </span>
                    <span className={Sex === "" ? "error" : "success"}>{ErrorGender}</span>
                  </div>

                </div>

                <div className="row mainrow">
                  <div className="col-sm">
                    <Select
                      placeholder={<div>Type to search Dam</div>}
                      onChange={setDam}
                      options={horseoptions}
                      isOptionDisabled={(option) => option.isdisabled}
                      isSearchable={true}
                      hideSelectedOptions={true}
                      onInputChange={handleChangeInput}
                      value={Dam}
                    />


                  </div>

                </div>
                <div className="row mainrow">
                  <div className="col-sm">
                    <Select
                      placeholder={<div>Type to search Sire</div>}
                      defaultValue={Sire}
                      onChange={setSire}
                      options={horseoptions}
                      isClearable={true}
                      isSearchable={true}
                      onInputChange={handleChangeInput}
                      value={Sire}
                    />

                  </div>


                </div>
                <div className="row mainrow">
                  <div className="col-sm">
                    <Select
                      placeholder={<div>Type to search GSire</div>}
                      defaultValue={GSire}
                      onChange={setGSire}
                      options={horseoptions}
                      isClearable={true}
                      isSearchable={true}
                      onInputChange={handleChangeInput}
                      value={GSire}
                    />

                  </div>

                </div>
                <div className="row mainrow">
                  <div className="col-sm">
                    <Select
                      placeholder={<div>Type to search Active Owner</div>}
                      defaultValue={ActiveOwner}
                      onChange={setActiveOwner}
                      options={owneroption}
                      isClearable={true}
                      isSearchable={true}
                      onInputChange={handleChangeInput1}
                      value={ActiveOwner}
                      onBlur={() => ActiveOwner === "" ? setErrorOwner("Horse Owner is required ") : setErrorOwner("Horse Owner is Validated ")}

                    />
                    <span className="spanForm abbbbb">
                      <OverlayTrigger
                        overlay={<Tooltip id={`tooltip-top`}>Add more</Tooltip>}
                      >
                        <span className="addmore" onClick={handleShowActiveOwner}>
                          +
                        </span>
                      </OverlayTrigger>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id={`tooltip-top`}>Fetch New</Tooltip>
                        }
                      >
                        <span className="addmore" onClick={FetchNew}>
                          <AiOutlineReload />
                        </span>
                      </OverlayTrigger>{" "}

                    </span>
                    <span className={ActiveOwner === "" ? "error" : "success"}>{ErrorOwner}</span>
                  </div>


                </div>
                <div className="row mainrow">
                  <div className="col-sm">
                    <Select
                      placeholder={<div>Type to search Active trainer</div>}
                      defaultValue={ActiveTrainer}
                      onChange={setActiveTrainer}
                      options={traineroption}
                      onInputChange={handleChangeInput3}
                      isClearable={true}
                      isSearchable={true}

                      onBlur={() => ActiveTrainer === "" ? setErrorTrainer("Horse Trainer is required ") : setErrorTrainer("Horse Trainer is Validated ")}
                    />
                    <span className="spanForm abbbbb">
                      <OverlayTrigger
                        overlay={<Tooltip id={`tooltip-top`}>Add more</Tooltip>}
                      >
                        <span className="addmore" onClick={handleShowActiveTrainer}>
                          +
                        </span>
                      </OverlayTrigger>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id={`tooltip-top`}>Fetch New</Tooltip>
                        }
                      >
                        <span className="addmore" onClick={FetchNew}>
                          <AiOutlineReload />
                        </span>
                      </OverlayTrigger>{" "}

                    </span>
                    <span className={ActiveTrainer === "" ? "error" : "success"}>{ErrorTrainer}</span>
                  </div>

                </div>
                <div className="row mainrow">
                  <div className="col-sm">
                    <Select
                      placeholder={<div>Select Breeder</div>}
                      defaultValue={Breeder}
                      onChange={setBreeder}
                      options={AllBreeder}
                      onInputChange={handleChangeInput2}
                      isClearable={true}
                      isSearchable={true}
                      onBlur={() => Breeder === "" ? setErrorBreeder("Horse Breeder is required ") : setErrorBreeder(" Horse Breeder is Validated")}

                    />
                    <span className="spanForm abbbbb">
                      <OverlayTrigger
                        overlay={<Tooltip id={`tooltip-top`}>Add more</Tooltip>}
                      >
                        <span className="addmore" onClick={handleShowBreeder}>
                          +
                        </span>
                      </OverlayTrigger>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id={`tooltip-top`}>Fetch New</Tooltip>
                        }
                      >
                        <span className="addmore" onClick={FetchNew}>
                          <AiOutlineReload />
                        </span>
                      </OverlayTrigger>{" "}

                    </span>
                    <span className={Breeder === "" ? "error" : "success"}>{ErrorBreeder}</span>
                  </div>

                </div>




                <div className="row mainrow">
                  <div className="col-sm">
                    <Select
                      placeholder={<div>Select Gelded</div>}
                      defaultValue={Gelted[0]}
                      onChange={setisGelted}
                      options={Gelted}
                      isClearable={true}
                      isSearchable={true}
                      onBlur={() => isGelted === "" ? setErrorGelded("Gelded is required ") : setErrorGelded("Gelded is Validated")}

                    />

                    <span className={isGelted === "" ? "error" : "success"}>{ErrorGelded}</span>
                  </div>

                </div>
                <div className="row mainrow">
                  <div className="col-sm">
                    <Select
                      placeholder={<div>Select Rds</div>}
                      defaultValue={Gelted[0]}
                      onChange={setRds}
                      options={Gelted}
                      isClearable={true}
                      isSearchable={true}
                      onBlur={() => Rds === "" ? setErrorRds("Horse Rds is required ") : setErrorRds("Horse Rds is Validated")}

                    />

                    <span className={Rds === "" ? "error" : "success"}>{ErrorRds}</span>
                  </div>

                </div>
                <div className="row mainrow">
                  <div className="col-sm">
                    <Select
                      placeholder={<div>Select Horse Status</div>}
                      defaultValue={HorseStatusAll[1]}
                      onChange={setHorseStatus}
                      options={HorseStatusAll}
                      isClearable={true}
                      isSearchable={true}
                      onBlur={() => HorseStatus === "" ? setErrorHorseStatus("Horse Status is required ") : setErrorHorseStatus("Horse Status is Validated")}


                    />
                    <span className={HorseStatus === "" ? "error" : "success"}>{ErrorHorseStatus}</span>
                  </div>

                </div>

                <div className="row mainrow">
                  <div className="col-sm">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Purchase Price"
                      className="mb-3"
                      onChange={(e) =>
                        setDstate({ ...Dstate, PurchasePrice: e.target.value })
                      }
                      onBlur={() => Dstate.PurchasePrice === "" ? setErrorPurchase("Horse Purchase Price is required ") : setErrorPurchase("Horse Purchase Price is Validated  ")}

                    >
                      <Form.Control
                         onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                        min='0'
                        value={Dstate.PurchasePrice}

                        placeholder="Purchase Price"
                      />
                    </FloatingLabel>
                    <span className={Dstate.PurchasePrice === "" ? "error" : "success"}>{ErrorPurchase}</span>


                  </div>

                </div>

                <div className="row mainrow">
                  <div className="col-sm">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Remarks"
                      className="mb-3"
                      onChange={(e) => setRemarksEn(e.target.value)}
                      value={RemarksEn}
                      required
                      onBlur={() => setErrorRemarks(remark)}

                    >
                      <Form.Control type="text" placeholder="Details" />
                    </FloatingLabel>
                    <span className={ErrorRemarks.status ? "success" : "error"}>{ErrorRemarks.message}</span>
                    <span className="spanForm"> |</span>
                  </div>

                  <div className="col-sm">
                    <FloatingLabel
                      controlId="floatingInput"
                      onChange={(e) => setRemarksAr(e.target.value)}
                      value={RemarksAr}
                      label="ملاحظة"
                      className="mb-3 floatingInputAr"
                      style={{ direction: "rtl" }}
                      onBlur={() => setErrorRemarksAr(remarkar)}
                    >
                      <Form.Control type="text" placeholder="ملاحظات" />
                    </FloatingLabel>
                    <span className={ErrorRemarksAr.status ? "successAr" : "errorAr"}>{ErrorRemarksAr.message}</span>
                  </div>
                </div>
                <div className="ButtonSection">
                  <div>
                    <label className="Multipleownerlabel">
                      Select Horse image
                    </label>
                    <input
                      type="file"
                      onChange={onSelectFile}
                      className="formInput"
                      id="file"
                    />
                    {image && (
                      <>
                        <ImCross onClick={handlePreview} className="crossIcon" />
                        <img src={preview} className="PreviewImage" alt="" />
                      </>
                    )}
                  </div>
                  <button
                    type="submit"
                    disabled={isLoading}
                    className="SubmitButton"
                  >
                    Add Horse
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={showBreeder}
        onHide={handleCloseBreeder}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2> Breeder</h2>
        </Modal.Header>
        <Modal.Body>
          <BreederPopup />
        </Modal.Body>
      </Modal>
      <Modal
        show={showColor}
        onHide={handleCloseColor}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2>Color</h2>
        </Modal.Header>
        <Modal.Body>
          <ColorPopup />
        </Modal.Body>
      </Modal>
      <Modal
        show={showActiveOwner}
        onHide={handleCloseActiveOwner}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2>Owner</h2>
        </Modal.Header>
        <Modal.Body>
          <OwnerPopup />
        </Modal.Body>
      </Modal>
      <Modal
        show={showActiveTrainer}
        onHide={handleCloseActiveTrainer}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2>Trainer</h2>
        </Modal.Header>
        <Modal.Body>
          <TrainerPopup />
        </Modal.Body>
      </Modal>
      <Modal
        show={showGender}
        onHide={handleCloseGender}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2>Gender</h2>
        </Modal.Header>
        <Modal.Body>
          <GenderPopup />
        </Modal.Body>
      </Modal>
      <Modal
        show={showActivenationality}
        onHide={handleCloseActivenationality}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2>Nationality</h2>
        </Modal.Header>
        <Modal.Body>
          <NationalityPopup />
        </Modal.Body>
      </Modal>
      <Modal
        show={showHorseKind}
        onHide={handleCloseHorseKind}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2>Horse Kind</h2>
        </Modal.Header>
        <Modal.Body>
          <HorseKindPopup />
        </Modal.Body>
      </Modal>

    </Fragment>
  );
};

export default HorseForm;