import React, { useState } from "react";
import swal from "sweetalert";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import Loader from "../../Components/Common/Loader";
import { Form, Input } from 'antd';

const Seoform = () => {
  //for error

  const [isLoading, setisLoading] = useState(false);

  const [KeyWord, setKeyWord] = useState("");
  const [KeyWordAr, setKeyWordAr] = useState("");
  const [TitleEn, setTitleEn] = useState("");
  const [TitleAr, setTitleAr] = useState("");

  const history = useNavigate();
  const { pathname } = useLocation();
  //----------------------------submit -------------------------//
  const submit = async (event) => {
    event.preventDefault();
    setisLoading(true);
    try {
      const formData = new FormData();

      formData.append("KeywordAr", KeyWordAr);
      formData.append("KeywordEn", KeyWord);
      formData.append("TitleEn", TitleEn);
      formData.append("TitleAr", TitleAr);

      await axios.post(`${window.env.API_URL}/uploadSeoKeyword`, formData);
      if (pathname === "/seoform") {
        history("/seolisting");
      }
      swal({
        title: "Success!",
        text: "Data has been added successfully ",
        icon: "success",
        button: "OK",
      });
      setisLoading(false);
    } catch (error) {
      const err = error.response.data.message;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
      setisLoading(false);
    }
  };




  return (
    <div className="page">
      <div className="rightsidedata">
        <div
          style={{
            marginTop: "30px",
          }}
        >
          <div className="Headers">Create SEO</div>
          {isLoading ? <Loader /> : <div className="form">
            <Form onFinish={submit}>
              <div className="row mainrow">
                <div className="col-sm">







                  <Form.Item
                    name="keyword"
                    label="keyword"
                    rules={[
                      {
                        required: true,
                        message: 'keyword (English) is required',
                      },
                    ]}
                  >
                    <Input onChange={(e) => setKeyWord(e.target.value)}
                      placeholder="keyword"
                      name="keyword"
                      value={KeyWord}
                    />
                  </Form.Item>






                  <span className="spanForm spacer" > |</span>

                </div>

                <div className="col-sm">





                  <Form.Item
                    name="KeyWordAr"
                    label="الكلمة الرئيسية"
                    rules={[
                      {
                        required: true,
                        message: 'Key Word (Arabic) is required',
                      },
                    ]}
                    style={{ direction: 'rtl' }}
                  >
                    <Input onChange={(e) => setKeyWordAr(e.target.value)}
                      name="KeyWordAr"
                      placeholder="الكلمة الرئيسية"
                      value={KeyWordAr} />
                  </Form.Item>









                </div>
              </div>

              <div className="row mainrow">
                <div className="col-sm">





                  <Form.Item
                    name="NameEn"
                    label="Title"
                    rules={[
                      {
                        required: true,
                        message: 'Title (English) is required',
                      },
                    ]}
                  >
                    <Input placeholder="Title" onChange={(e) => setTitleEn(e.target.value)}
                      value={TitleEn} name="NameEn" />
                  </Form.Item>








                  <span className="spanForm spacer" > |</span>

                </div>

                <div className="col-sm">



                  <Form.Item
                    name="TitleAr"
                    label="عنوان"
                    rules={[
                      {
                        required: true,
                        message: 'Title (Arabic) is required',
                      },
                    ]}
                    style={{ direction: 'rtl' }}
                  >
                    <Input placeholder="عنوان" onChange={(e) => setTitleAr(e.target.value)}
                      name="TitleAr"
                      value={TitleAr} />
                  </Form.Item>









                </div>
              </div>

              <div className="ButtonSection " style={{ justifyContent: "end" }}>
                <button
                  Name="submit"
                  className="SubmitButton"
                  disabled={isLoading}
                >
                  Add SEO
                </button>
              </div>
            </Form>
          </div>}
        </div>
      </div>
    </div>
  );
};

export default Seoform;
