import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import axios from "axios";

import { useNavigate, useLocation } from "react-router-dom";

import { fetchcurrencyshortcode } from "../../redux/getShortCode/getcurrencyshortcode";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../Components/Common/Loader";

import { Form, Input } from 'antd';

const Currency = ({ setshowcurrency }) => {
  //for error


  const dispatch = useDispatch();
  const { data: currencyshortcode } = useSelector(
    (state) => state.currencyshortcode
  );


  const [NameEn, setNameEn] = useState("");
  const [NameAr, setNameAr] = useState("");
  const [Rate, setRate] = useState("");
  const [Symbol, setSymbol] = useState("");

  const [isLoading, setisLoading] = useState(false);
  const [state1, setState] = useState({
    shortCode: "",
  });

  const history = useNavigate();
  const { pathname } = useLocation();
  //----------------------------shortcode -------------------------//
  useEffect(() => {
    if (currencyshortcode) {
      setState({
        shortCode:
          currencyshortcode.length === 0
            ? 10
            : currencyshortcode[0].maxshortCode + 1,
      });
    } else {
      setState.shortCode("9");
    }
  }, [currencyshortcode]);
  useEffect(() => {
    dispatch(fetchcurrencyshortcode());
  }, [dispatch]);



  const submit = async () => {

    setisLoading(true);
    try {
      const formData = new FormData();

      formData.append("NameEn", NameEn);
      formData.append("NameAr", NameAr + " ");
      formData.append("Symbol", Symbol);
      formData.append("Rate", Rate);
      formData.append("shortCode", state1.shortCode);

      await axios.post(`${window.env.API_URL}/uploadCurrency`, formData);
      if (pathname === "/currency") {
        history("/currencylist");
      } setshowcurrency(false)
      swal({
        title: "Success!",
        text: "Data has been added successfully ",
        icon: "success",
        button: "OK",
      });
      setisLoading(false);
    } catch (error) {
      const err = error.response.data.message[0];
      const err1 = error.response.data.message[1];
      const err2 = error.response.data.message[2];
      swal({
        title: "Error!",
        text: err,
        err1,
        err2,
        icon: "error",
        button: "OK",
      });
      setisLoading(false);
    }
  };

  return (
    <div className="page">
      <div className="rightsidedata">
        <div
          style={{
            marginTop: "30px",
          }}
        >
          <div className="Headers">Create Currency</div>
          {isLoading ? <Loader /> : <div className="form">
            <Form onFinish={submit} fields={[{

              name: "shortCode",
              value: state1.shortCode

            }]}>
              <div className="row mainrow">
                <div className="col-sm">
                  <Form.Item
                    name="NameEn"
                    label="Name"
                    rules={[
                      {
                        required: true,
                        message: 'Name (English) is required',
                      },
                    ]}
                  >
                    <Input placeholder="Name" onChange={(e) => setNameEn(e.target.value)}
                      value={NameEn} name="NameEn" />
                  </Form.Item>

                  <span className="spanForm spacer"> |</span>

                </div>

                <div className="col-sm">
                  <Form.Item
                    name="NameAr"
                    label="اسم"
                    rules={[
                      {
                        required: true,
                        message: 'Name (Arabic) is required',
                      },
                    ]}
                    style={{ direction: 'rtl' }}
                  >
                    <Input placeholder="اسم" onChange={(e) => setNameAr(e.target.value)}
                      name="Name"
                      value={NameAr} />
                  </Form.Item>

                </div>
              </div>
              <div className="row mainrow">
                <div className="col-sm">


                  <Form.Item
                    name="Symbol"
                    label="Symbol"
                    rules={[
                      {
                        required: true,
                        message: 'Symbol is required',
                      },
                    ]}
                  >
                    <Input placeholder="Symbol" onChange={(e) => setSymbol(e.target.value)}
                      value={Symbol}
                      name="Symbol"
                      type="text"
                    />
                  </Form.Item>







                </div>
              </div>
              <div className="row mainrow">
                <div className="col-sm">

                  <Form.Item
                    name="Rate"
                    label="Rate"
                    rules={[
                      {
                        required: true,
                        message: 'Rate  is required',
                      },
                    ]}
                  >
                    <Input placeholder="Rate" onChange={(e) => setRate(e.target.value)}
                      value={Rate}
                      name="Rate"
                      type="number"
                    />
                  </Form.Item>










                  {/* <span className="spanForm"> |</span> */}
                </div>


              </div>

              <div className="row mainrow">
                <div className="col-sm">
                  <Form.Item
                    name="shortCode"
                    label="Short Code"
                    rules={[
                      {
                        required: true,
                        message: "short Code is required"
                      },
                    ]}
                  >
                    <Input
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(e) =>
                        setState({ ...state1, shortCode: e.target.value })
                      }
                      value={state1.shortCode}
                      onPaste={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      placeholder="Short Code"
                      maxLength="5"
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="ButtonSection" style={{ justifyContent: "end" }}>
                <button
                  type="submit"
                  className="SubmitButton"
                  disabled={isLoading}
                >
                                      Save

                </button>
              </div>
            </Form>
          </div>}
        </div>
      </div>
    </div>
  );
};

export default Currency;
