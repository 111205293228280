import React, { useState, useEffect } from "react";
import "../../Components/CSS/forms.css";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { fetchNationalityList } from "../../redux/getDropDownReducer/getNationalityList";
import swal from "sweetalert";
import { useSelector } from "react-redux";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import { Form, Input, DatePicker, Select } from 'antd';
import { AiOutlineReload } from "react-icons/ai";
import { Modal } from "react-bootstrap";

import NationalityPopup from "./Nationality";
import { ImCross } from "react-icons/im";
import Loader from "../../Components/Common/Loader";

const JockeyForm = () => {
  //for Errors


  const [ErrorDateofBirth, setErrorDateofBirth] = useState("");
  const [ErrorLicenseDate, setErrorLicenseDate] = useState("");
  const { Option } = Select;

  const [ErrorNationality, setErrorNationality] = useState("");
  const [isLoading, setisLoading] = useState(false);

  const dispatch = useDispatch();
  const history = useNavigate();
  const { pathname } = useLocation();

  const { data: NationalityList } = useSelector(
    (state) => state.NationalityList
  );

  var today = new Date();

  const [NameEn, setNameEn] = useState("");
  const [NameAr, setNameAr] = useState("");
  const [ShortNameEn, setShortNameEn] = useState("");
  const [ShortNameAr, setShortNameAr] = useState("");
  const [RemarksEn, setRemarksEn] = useState("");
  const [RemarksAr, setRemarksAr] = useState("");
  const [DOB, setDOB] = useState("");
  const [JockeyLicenseDate, setJockeyLicenseDate] = useState("");
  const [Rating, setRating] = useState("");
  const [MiniumumJockeyWeight, setMiniumumJockeyWeight] = useState("");
  const [MaximumJockeyWeight, setMaximumJockeyWeight] = useState("");
  const [JockeyAllowance, setJockeyAllowance] = useState("");
  const [image, setImage] = useState();
  const [NationalityID, setNationalityID] = useState("");
  const [preview, setPreview] = useState();
  const [showActivenationality, setShowNationality] = useState(false);
  const handleCloseActivenationality = () => setShowNationality(false);
  //----------------------------handle Popup -------------------------//
  const handleShowActivenationality = async () => {
    await setShowNationality(true);
  };


  const handleSearch = async (value) => {
    dispatch(fetchNationalityList({ value }))

  };
  //----------------------------submit -------------------------//

  const submit = async () => {
    if (NationalityID === '') {

      setErrorNationality("Nationality is Required")
    }
    setisLoading(true);
    try {
      const formData = new FormData();
      formData.append("image", image);
      formData.append("NameEn", NameEn);
      formData.append("ShortNameEn", ShortNameEn);
      formData.append("ShortNameAr", ShortNameAr);
      formData.append("JockeyAllowance", JockeyAllowance);
      formData.append("NameAr", NameAr);
      formData.append("DOB", DOB);
      formData.append("RemarksEn", RemarksEn);
      formData.append("NationalityID", NationalityID);
      formData.append("RemarksAr", RemarksAr);
      formData.append("MiniumumJockeyWeight", MiniumumJockeyWeight);
      formData.append("MaximumJockeyWeight", MaximumJockeyWeight);
      formData.append("Rating", Rating);
      formData.append("JockeyLicenseDate", JockeyLicenseDate);

      await axios.post(`${window.env.API_URL}/uploadJockey`, formData);
      swal({
        title: "Success!",
        text: "Data has been added Successfully",
        icon: "success",
        button: "OK",
      });
      if (pathname === "/jockeyform") {
        history("/jockey");
      }
      setisLoading(false);
    } catch (error) {

      const err = error.response.data.message[0];
      const err1 = error.response.data.message[1];
      const err2 = error.response.data.message[2];

      swal({
        title: "Error!",
        text: err,
        err1,
        err2,
        icon: "error",
        button: "OK",
      });
      setisLoading(false);
    }
  };
  //----------------------------image Preview -------------------------//
  useEffect(() => {
    dispatch(fetchNationalityList());
    if (!image) {
      setPreview(undefined);
      return;
    }
    if (image.name.match(/\.(gif)$/)) {
      setImage("");
      setPreview(undefined);
      document.getElementById("file").value = "";
      swal({
        title: "Error!",
        text: "Invalid Format ",
        icon: "error",
        button: "OK",
      });
    }
    const objectUrl = URL.createObjectURL(image);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [image, dispatch]);
  const FetchNew = () => {
    dispatch(fetchNationalityList());
  };
  const onSelectFile = (e) => {
    setImage(e.target.files[0]);
  };

  //----------------------------dropdown Value -------------------------//

  let AllNationality =
    NationalityList === undefined ? (
      <></>
    ) : (
      NationalityList.map(function (item) {
        return {
          id: item._id,
          value: item.NameEn,
          label: (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <p>{item.NameEn}</p>
              <p>{item.NameAr}</p>
            </div>
          ),
        };
      })
    );
  //----------------------------remove privew -------------------------//
  const handlePreview = () => {
    setImage();
    document.getElementById("file").value = "";
  };
  //Checking Validation
  const handleDateChange = (date, dateString) => {
    setDOB(dateString);
  };

  const handleDateChange1 = (date, dateString) => {
    setJockeyLicenseDate(dateString);
  };



  return (
    <>
      <div className="page">
        <div className="rightsidedata">
          <div
            style={{
              marginTop: "30px",
            }}
          >
            <div className="Headers">Create Jockey</div>
            {isLoading ? <Loader /> : <div className="form">
              <Form onFinish={submit}>
                <div className="row mainrow">
                  <div className="col-sm">




                    <Form.Item
                      name="NameEn"
                      label="Name"
                      rules={[
                        {
                          required: true,
                          message: 'Name (English) is required',
                        },
                      ]}
                    >
                      <Input placeholder="Name" onChange={(e) => setNameEn(e.target.value)}
                        value={NameEn} name="NameEn" />
                    </Form.Item>








                    <span className="spanForm spacer"> |</span>

                  </div>

                  <div className="col-sm">
                    <Form.Item
                      name="NameAr"
                      label="اسم"
                      rules={[
                        {
                          required: true,
                          message: 'Name (Arabic) is required',
                        },
                      ]}
                      style={{ direction: 'rtl' }}
                    >
                      <Input placeholder="اسم" onChange={(e) => setNameAr(e.target.value)}
                        name="NameAr"
                        value={NameAr} />
                    </Form.Item>

                  </div>
                </div>

                <div className="row mainrow">
                  <div className="col-sm">






                    <Form.Item
                      name="ShortName"
                      label="Short Name"
                      rules={[
                        {
                          required: true,
                          message: 'Short Name (English) is required',
                        },
                      ]}
                    >
                      <Input placeholder="Short Name" onChange={(e) => setShortNameEn(e.target.value)}
                        value={ShortNameEn} name="ShortName" />
                    </Form.Item>







                    <span className="spanForm spacer"> |</span>

                  </div>




                  <div className="col-sm">


                    <Form.Item
                      name="ShortNameAr"
                      label="اسم قصير"
                      rules={[
                        {
                          required: true,
                          message: 'Short Name (Arabic) is required',
                        },
                      ]}
                      style={{ direction: 'rtl' }}
                    >
                      <Input placeholder="اسم قصير" onChange={(e) => setShortNameAr(e.target.value)}
                        name="ShortNameAr"
                        value={ShortNameAr} />
                    </Form.Item>














                  </div>
                </div>

                <div className="row mainrow">
                  <div className="col-sm">






                    <Form.Item
                      name="Remarks"
                      label="Remarks"
                      rules={[
                        {
                          required: true,
                          message: 'Remarks (English) is required',
                        },
                      ]}
                    >
                      <Input placeholder="Remarks" onChange={(e) => setRemarksEn(e.target.value)}
                        value={RemarksEn} name="Remarks" />
                    </Form.Item>










                    <span className="spanForm spacer"> |</span>

                  </div>

                  <div className="col-sm">












                    <Form.Item
                      name="RemarksAr"
                      label="ملاحظات"
                      rules={[
                        {
                          required: true,
                          message: 'Remarks (Arabic) is required',
                        },
                      ]}
                      style={{ direction: 'rtl' }}
                    >
                      <Input placeholder="ملاحظات" onChange={(e) => setRemarksAr(e.target.value)}
                        name="RemarksAr"
                        value={RemarksAr} />
                    </Form.Item>



                  </div>
                </div>

                <div className="row mainrow">
                  <div className="col-sm">
                    <Form.Item name="date-picker1" label="Date of Birth " rules={[
                      {
                        required: true,
                        message: 'Date of Birth is required',
                      },
                    ]}>


                      <DatePicker value={DOB} onChange={handleDateChange} format="YYYY-MM-DD" />

                    </Form.Item>



                  </div>


                </div>


                <div className="row mainrow">
                  <div className="col-sm">
                    <Form.Item name="date-picker" label="Jockey License Date " rules={[
                      {
                        required: true,
                        message: 'Jockey License Date is required',
                      },
                    ]}>


                      <DatePicker value={JockeyLicenseDate} onChange={handleDateChange1} format="YYYY-MM-DD" />

                    </Form.Item>



                  </div>


                </div>


                <div className="row mainrow">
                  <div className="col-sm">








                    <Form.Item

                      name="select"
                      label="Nationality"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: 'Please select your Nationality!',
                        },
                      ]}
                    >


                      <Select
                        value={NationalityID}
                        filterOption={false}
                        onChange={setNationalityID}
                        onSearch={handleSearch}
                        showSearch placeholder="Please select a Nationality" >

                        {
                          NationalityList?.map((item) => (


                            <Option value={item._id}>{item.NameEn} ({item.NameAr})</Option>

                          ))
                        }
                      </Select>



                    </Form.Item>



                    <Select
                      placeholder={<div>Select Nationality</div>}
                      defaultValue={NationalityID}
                      onChange={setNationalityID}
                      options={AllNationality}
                      isClearable={true}
                      isSearchable={true}
                      onBlur={() =>
                        NationalityID === ""
                          ? setErrorNationality("Nationality is required")
                          : setErrorNationality("")
                      }
                    />
                    <span className="spanForm selectSpacer">
                      <OverlayTrigger
                        overlay={<Tooltip id={`tooltip-top`}>Add more</Tooltip>}
                      >
                        <span
                          className="addmore"
                          onClick={handleShowActivenationality}
                        >
                          +
                        </span>
                      </OverlayTrigger>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id={`tooltip-top`}>Fetch New</Tooltip>
                        }
                      >
                        <span className="addmore" onClick={FetchNew}>
                          <AiOutlineReload />
                        </span>
                      </OverlayTrigger>
                    </span>
                    <span className="error">{ErrorNationality}</span>
                  </div>
                </div>
                <div className="row mainrow">
                  <div className="col-sm">







                    <Form.Item
                      name="Rating"
                      label="Rating"
                      rules={[
                        {
                          required: true,
                          message: 'Rating  is required',
                        },
                      ]}
                    >
                      <Input placeholder="Rating"
                        min="1"
                        maxLength='4'
                        step="0.01"
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }} onChange={(e) => setRating(e.target.value)}
                        value={Rating} name="Rating" type="number" />
                    </Form.Item>










                  </div>

                </div>

                <div className="row mainrow">
                  <div className="col-sm">




                    <Form.Item
                      name="Minimum"
                      label="Jockey Minimum Weight"
                      rules={[
                        {
                          required: true,
                          message: 'Jockey Minimum Weight  is required',
                        },
                      ]}
                    >
                      <Input placeholder="Jockey Minimum Weight"
                        maxLength='4'
                        step="0.01"
                        onChange={(e) => setMiniumumJockeyWeight(e.target.value)}
                        value={MiniumumJockeyWeight}
                      />
                    </Form.Item>















                  </div>


                </div>

                <div className="row mainrow">
                  <div className="col-sm">





                    <Form.Item
                      name="Maximum"
                      label="Jockey Maximum Weight"
                      rules={[
                        {
                          required: true,
                          message: 'Jockey Maximum Weight  is required',
                        },
                      ]}
                    >
                      <Input placeholder="Jockey Maximum Weight" onChange={(e) => setMaximumJockeyWeight(e.target.value)}
                        value={MaximumJockeyWeight} name="Maximum" />
                    </Form.Item>

















                  </div>


                </div>

                <div className="row mainrow">
                  <div className="col-sm">





                    <Form.Item
                      name="Allowance"
                      label="Jockey Allowance"
                      rules={[
                        {
                          required: true,
                          message: 'Jockey Allowance is required',
                        },
                      ]}
                    >
                      <Input placeholder="Jockey Maximum Weight" onChange={(e) => setJockeyAllowance(e.target.value)}
                        value={JockeyAllowance} name="Allowance" type="number" />
                    </Form.Item>









                  </div>


                </div>

                <div className="ButtonSection">
                  <div>
                    <label className="Multipleownerlabel">
                      Select Jockey image
                    </label>
                    <input
                      type="file"
                      onChange={onSelectFile}
                      className="formInput"
                      id="file"
                    />
                    {image && (
                      <>
                        <ImCross
                          onClick={handlePreview}
                          className="crossIcon"
                        />
                        <img src={preview} className="PreviewImage" alt="" />
                      </>
                    )}
                  </div>
                  <div>
                    <button
                      type="submit"
                      className="SubmitButton"
                      disabled={isLoading}
                    >
                      Add Jockey
                    </button></div>
                </div>
              </Form>
            </div>}
          </div>
        </div>
      </div>
      <Modal
        show={showActivenationality}
        onHide={handleCloseActivenationality}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2>Nationality</h2>
        </Modal.Header>
        <Modal.Body>
          <NationalityPopup setShowNationality={setShowNationality} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default JockeyForm;
