import axios from "axios";
import { createAsyncThunk,createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
    IDLE : 'idle',
    ERROR:'error',
    LOADING: 'loading',
});

const getVerifyAndDeleteColorSlice = createSlice({
    name: 'VerifyAndDeleteColor',
    initialState: {
        data:[],
        status : STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
        .addCase(fetchVerifyAndDeleteColor.pending, (state, action) => {
            state.status = STATUSES.LOADING;
        })
        .addCase(fetchVerifyAndDeleteColor.fulfilled, (state, action) => {
            state.data = action.payload;
            state.status = STATUSES.IDLE
        }) 
        .addCase(fetchVerifyAndDeleteColor.rejected , (state,action) => {
            state.status = STATUSES.ERROR;
        })
    }
});

export const {setVerifyAndDeleteColor , setStatus} = getVerifyAndDeleteColorSlice.actions;
export default getVerifyAndDeleteColorSlice.reducer;

export const fetchVerifyAndDeleteColor = createAsyncThunk('/getVerifyAndDeleteColor/fetch', async({id}) => {
    const res = await axios.delete(`${window.env.API_URL}/VerifyAndDeleteColor/${id}`);
    const VerifyAndDeleteColorData = res.data;
    return VerifyAndDeleteColorData.data
    ;
})