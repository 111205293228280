import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});

const getsilkcolorDropDownSlice = createSlice({
    name: 'silkcolordropdown',
    initialState: {
        data: [],
        status: STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchsilkcolordropdown.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(fetchsilkcolordropdown.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.IDLE
            })
            .addCase(fetchsilkcolordropdown.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
    }
});

export const { setEquipment, setStatus } = getsilkcolorDropDownSlice.actions;
export default getsilkcolorDropDownSlice.reducer;

export const fetchsilkcolordropdown = createAsyncThunk('/SilkColorget/fetch', async ({AOwnerid}) => {
    const res = await axios.get(`${window.env.API_URL}/GetSilkColorOfOwner/${AOwnerid}?size=800`);
    const equipmentData = res.data;
    return equipmentData.data;
})