import React, { useEffect } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { fetchEditSingleRace } from "../../redux/getReducer/getSingleRace";
import { useDispatch, useSelector } from "react-redux";

const RaceDetailPopup = (data) => {



  const { data: EditSingleRace } = useSelector((state) => state.EditSingleRace);
  const dispatch = useDispatch();

  const id1 = data?.data?._id


  useEffect(() => {
    dispatch(fetchEditSingleRace({id1}))
  },[])


  const type = EditSingleRace?.RaceTypeModelData ? EditSingleRace?.RaceTypeModelData : "-";

  const racecourse = EditSingleRace?.RaceCourseData ? EditSingleRace?.RaceCourseData : "-";
  const kind = EditSingleRace?.RaceKindData ? EditSingleRace?.RaceKindData : "-";
  const length = EditSingleRace?.TrackLengthData ? EditSingleRace?.TrackLengthData : "-";
  const name = EditSingleRace?.RaceNameModelData ? EditSingleRace?.RaceNameModelData : "-";
  const meet = EditSingleRace?.MeetingTypeData ? EditSingleRace?.MeetingTypeData : "-";
  const sponsor = EditSingleRace?.SponsorData ? EditSingleRace?.SponsorData : "-";


  // const totalprize = EditSingleRace?.FirstPrice + EditSingleRace?.FourthPrice + EditSingleRace?.FifthPrice + EditSingleRace?.SecondPrice + EditSingleRace?.ThirdPrice + EditSingleRace?.FifthPrice + EditSingleRace?.FifthPrice;
  return (
    <div className="form">
      <div className="modalPreview">
        {/* <img src={EditSingleRace.image} className="PreviewImage" alt="" />
         */}
      </div>

      <div className="row mainrow">
        <div className="col-sm">
          <FloatingLabel
            controlId="floatingInput"
            label="Race Name"
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="Title"
              value={name.NameEn}
              readOnly
            />
          </FloatingLabel>

          <span className="spanForm"> |</span>
        </div>

        <div className="col-sm">
          <FloatingLabel
            controlId="floatingInput"
            label="اسم"
            style={{ direction: "rtl", left: "initial", right: 0 }}
            className="mb-3 floatingInputAr"
          >
            <Form.Control
              type="text"
              placeholder="عنوان"
              value={name.NameAr}
              readOnly
              style={{ left: "%" }}
            />
          </FloatingLabel>
        </div>
      </div>

      <div className="row mainrow">
        <div className="col-sm">
          <FloatingLabel
            controlId="floatingInput"
            label="Description"
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="Description"
              value={EditSingleRace.DescriptionEn}
              readOnly
            />
          </FloatingLabel>

          <span className="spanForm"> |</span>
        </div>

        <div className="col-sm">
          <FloatingLabel
            controlId="floatingInput"
            label="وصف"
            className="mb-3 floatingInputAr"
            style={{ direction: "rtl", left: "initial", right: 0 }}
          >
            <Form.Control
              type="text"
              placeholder="وصف"
              value={EditSingleRace.DescriptionAr}
              style={{ left: "%" }}
              readOnly
            />
          </FloatingLabel>
        </div>
      </div>

      <div className="row mainrow">
        <div className="col-sm">
          <FloatingLabel
            controlId="floatingInput"
            label="Race Type "
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="Second-Title"
              value={type.NameEn}
              readOnly
            />
          </FloatingLabel>

          <span className="spanForm"> |</span>
        </div>

        <div className="col-sm">
          <FloatingLabel
            controlId="floatingInput"
            label="نوع السباق"
            className="mb-3 floatingInputAr"
            style={{ direction: "rtl", left: "initial", right: 0 }}
          >
            <Form.Control
              type="text"
              placeholder="Description"
              value={type.NameAr}
              style={{ left: "%" }}
              readOnly
            />
          </FloatingLabel>
        </div>
      </div>

      <div className="row mainrow">
        <div className="col-sm">
          <FloatingLabel
            controlId="floatingInput"
            label="Race Course "
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="Second-Title"
              value={racecourse.TrackNameEn}
              readOnly
            />
          </FloatingLabel>

          <span className="spanForm"> |</span>
        </div>

        <div className="col-sm">
          <FloatingLabel
            controlId="floatingInput"
            label="نوع السباق"
            className="mb-3 floatingInputAr"
            style={{ direction: "rtl", left: "initial", right: 0 }}
          >
            <Form.Control
              type="text"
              placeholder="Description"
              value={racecourse.TrackNameAr}
              style={{ left: "%" }}
              readOnly
            />
          </FloatingLabel>
        </div>
      </div>

      <div className="row mainrow">
        <div className="col-sm">
          <FloatingLabel
            controlId="floatingInput"
            label="Meeting Type "
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="Second-Title"
              value={meet.NameEn}
              readOnly
            />
          </FloatingLabel>

          <span className="spanForm"> |</span>
        </div>

        <div className="col-sm">
          <FloatingLabel
            controlId="floatingInput"
            label="نوع السباق"
            className="mb-3 floatingInputAr"
            style={{ direction: "rtl", left: "initial", right: 0 }}
          >
            <Form.Control
              type="text"
              placeholder="Description"
              value={meet.NameAr}
              style={{ left: "%" }}
              readOnly
            />
          </FloatingLabel>
        </div>
      </div>

      <div className="row mainrow">
        <div className="col-sm">
          <FloatingLabel
            controlId="floatingInput"
            label="Sponsor"
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="Second-Title"
              value={sponsor.TitleEn}
              readOnly
            />
          </FloatingLabel>

          <span className="spanForm"> |</span>
        </div>

        <div className="col-sm">
          <FloatingLabel
            controlId="floatingInput"
            label="نوع السباق"
            className="mb-3 floatingInputAr"
            style={{ direction: "rtl", left: "initial", right: 0 }}
          >
            <Form.Control
              type="text"
              placeholder="Description"
              value={sponsor.TitleAr}
              style={{ left: "%" }}
              readOnly
            />
          </FloatingLabel>
        </div>
      </div>

      <div className="row mainrow">
        <div className="col-sm">
          <FloatingLabel
            controlId="floatingInput"
            label="Race Kind "
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="Second-Title"
              value={kind.NameEn}
              readOnly
            />
          </FloatingLabel>

          <span className="spanForm"> |</span>
        </div>

        <div className="col-sm">
          <FloatingLabel
            controlId="floatingInput"
            label="نوع السباق"
            className="mb-3 floatingInputAr"
            style={{ direction: "rtl", left: "initial", right: 0 }}
          >
            <Form.Control
              type="text"
              placeholder="Description"
              value={kind.NameAr}
              style={{ left: "%" }}
              readOnly
            />
          </FloatingLabel>
        </div>
      </div>

      <div className="row mainrow">
        <div className="col-sm">
          <FloatingLabel
            controlId="floatingInput"
            label="Track Length "
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="Second-Title"
              value={length.TrackLength}
              readOnly
            />
          </FloatingLabel>
        </div>
      </div>
      <div className="row mainrow">
        <div className="col-sm">
          <FloatingLabel
            controlId="floatingInput"
            label="Number Of Horse"
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="Second-Title"
              value={EditSingleRace?.RacehorsesData?.length}
              readOnly
            />
          </FloatingLabel>
        </div>
      </div>
      <div className="row mainrow">
        <div className="col-sm">
          <FloatingLabel controlId="floatingInput" label="Day" className="mb-3">
            <Form.Control
              type="text"
              placeholder="Second-Title"
              value={EditSingleRace.Day}
              readOnly
            />
          </FloatingLabel>
        </div>
      </div>

      <div className="row mainrow">
        <div className="col-sm">
          <FloatingLabel
            controlId="floatingInput"
            label="Start Time"
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="Second-Title"
              value={EditSingleRace.StartTime}
              readOnly
            />
          </FloatingLabel>
        </div>
      </div>


      <div className="row mainrow">
        <div className="col-sm">
          <FloatingLabel
            controlId="floatingInput"
            label="Result Status"
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="Second-Title"
              value={EditSingleRace.ResultStatus}
              readOnly
            />
          </FloatingLabel>
        </div>
      </div>

      <div className="row mainrow">
        <div className="col-sm">
          <FloatingLabel
            controlId="floatingInput"
            label="Race Status"
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="Second-Title"
              value={EditSingleRace.RaceStatus}
              readOnly
            />
          </FloatingLabel>
        </div>
      </div>

      {/* <div className="row mainrow">
        <div className="col-sm">
          <FloatingLabel
            controlId="floatingInput"
            label="Prize"
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="Prize"
              value={EditSingleRace.RaceStatus}
              readOnly
            />
          </FloatingLabel>
        </div>
      </div> */}
    </div>
  );
};

export default RaceDetailPopup;
