import React, { useState, useEffect } from "react";
import "../../Components/CSS/forms.css";
import { useNavigate, useLocation } from "react-router-dom";
import swal from "sweetalert";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";

//---------------------------modueles for quill---------------------------//

const modules = {
  toolbar: [
    [{ header: 1 }, { header: 2 }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script: "sub" }, { script: "super" }],
    ["blockquote", "code-block"],
    [{ align: [] }],
    [
      { list: "ordered" },
      { list: "bullet" },
      { list: "check" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image", "video"],
    ["clean"],
    ["formula"],
    ["undo"],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
  "undo",
];

const EditNewsForm = () => {
  const history = useNavigate();
  const { state } = useLocation();
  const [isLoading, setisLoading] = useState(false);
  const { newsid } = state;

  const [image, setImage] = useState();
  const [preview, setPreview] = useState();
  const [DescriptionEn, setDescriptionEn] = useState();
  const [DescriptionAr, setDescriptionAr] = useState();
  const [state1, setState] = useState({
    TitleEn: "",
    TitleAr: "",
    DescriptionEn: "",
    DescriptionAr: "",
    SecondTitleEn: "",
    SecondTitleAr: "",
    image: image,
  });
  //---------------------------get Data from previous State---------------------------//

  useEffect(() => {
    if (newsid) {
      setState({
        TitleEn: newsid.TitleEn,
        TitleAr: newsid.TitleAr,
        DescriptionEn: newsid.DescriptionEn,
        DescriptionAr: newsid.DescriptionAr,
        SecondTitleEn: newsid.SecondTitleEn,
        SecondTitleAr: newsid.SecondTitleAr,
        image: newsid.image,
      });
    } else {
      alert("No Data");
    }
  }, [newsid]);

  const fileSelected = (event) => {
    const image = event.target.files[0];
    setImage(image);
  };
  //---------------------------Image Preview---------------------------//

  useEffect(() => {
    if (image === undefined) {
      setPreview(newsid.image);
      return;
    }
    const objectUrl = URL.createObjectURL(image);
    setPreview(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [image, newsid.image]);
  //---------------------------Submit---------------------------//

  const submit = async (event) => {
    event.preventDefault();
    try {
      setisLoading(true);
      const formData = new FormData();
      formData.append("TitleEn", state1.TitleEn);
      formData.append("TitleAr", state1.TitleAr + " ");
      formData.append(
        "DescriptionEn",
        DescriptionEn === undefined ? state1.DescriptionEn : DescriptionEn
      );
      formData.append(
        "DescriptionAr",
        DescriptionAr === undefined ? state1.DescriptionAr : DescriptionAr
      );

      formData.append("SecondTitleEn", state1.SecondTitleEn);
      formData.append("SecondTitleAr", state1.SecondTitleAr + " ");
      formData.append("image", image);

      await axios.put(
        `${window.env.API_URL}/updatenews/${newsid._id}`,
        formData
      );
      history("/news");
      swal({
        title: "Success!",
        text: "Data has been Updated successfully ",
        icon: "success",
        button: "OK",
      });
    } catch (error) {
      const err = error.response.data.message;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
    }
    setisLoading(false);
  };
  return (
    <>
      <div className="page">
        <div className="rightsidedata">
          <div
            style={{
              marginTop: "30px",
            }}
          >
            <div className="Headers">Edit News</div>
            <div className="form">
              <form onSubmit={submit}>
                <div className="row mainrow">
                  <div className="col-sm">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Title"
                      className="mb-3"
                      onChange={(e) =>
                        setState({ ...state1, TitleEn: e.target.value })
                      }
                    >
                      <Form.Control
                        type="text"
                        placeholder="Details"
                        value={state1.TitleEn}
                      />
                    </FloatingLabel>

                    <span className="spanForm"> |</span>
                  </div>

                  <div className="col-sm">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="عنوان"
                      className="mb-3 floatingInputAr"
                      style={{ direction: "rtl" }}
                      onChange={(e) =>
                        setState({ ...state1, TitleAr: e.target.value })
                      }
                    >
                      <Form.Control
                        type="text"
                        placeholder="Details"
                        value={state1.TitleAr}
                      />
                    </FloatingLabel>
                  </div>
                </div>
                <div className="row mainrow">
                  <div className="col-sm">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Second Title "
                      className="mb-3"
                      onChange={(e) =>
                        setState({ ...state1, SecondTitleEn: e.target.value })
                      }
                    >
                      <Form.Control
                        type="text"
                        placeholder="Description"
                        value={state1.SecondTitleEn}
                      />
                    </FloatingLabel>

                    <span className="spanForm"> |</span>
                  </div>

                  <div className="col-sm">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="العنوان الثاني"
                      className="mb-3 floatingInputAr"
                      style={{ direction: "rtl" }}
                      onChange={(e) =>
                        setState({ ...state1, SecondTitleAr: e.target.value })
                      }
                    >
                      <Form.Control
                        type="text"
                        placeholder="Description"
                        value={state1.SecondTitleAr}
                      />
                    </FloatingLabel>
                  </div>
                </div>
                <div className="row quillRow">
                  <div className="col-sm">
                    <ReactQuill
                      theme="snow"
                      modules={modules}
                      formats={formats}
                      defaultValue={state1.DescriptionEn}
                      value={DescriptionEn}
                      onChange={setDescriptionEn}
                    />
                  </div>
                  <div className="col-sm">
                    <ReactQuill
                      theme="snow"
                      className=""
                      modules={modules}
                      formats={formats}
                      defaultValue={state1.DescriptionAr}
                      value={DescriptionAr}
                      onChange={setDescriptionAr}
                    />
                  </div>
                </div>

                <div className="ButtonSection">
                  <div>
                    <input
                      type="file"
                      onChange={fileSelected}
                      className="formInput"
                    />
                    <img src={preview} className="PreviewImage" alt="" />
                  </div>
                  <div>
                  <button
                    type="submit"
                    className="SubmitButton"
                    disabled={isLoading}
                  >
                    Update
                  </button></div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditNewsForm;
