import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});

const getHorseForRaceSlice = createSlice({
    name: 'HorseForRace',
    initialState: {
        data: [],
        status: STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchHorseForRace.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(fetchHorseForRace.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.IDLE
            })
            .addCase(fetchHorseForRace.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
    }
});

export const { setHorseForRace, setStatus } = getHorseForRaceSlice.actions;
export default getHorseForRaceSlice.reducer;

export const fetchHorseForRace = createAsyncThunk('/HorseForRaceget/fetch', async ({ RaceId, Value, IncreasePage }) => {
    const res = await axios.get(`${window.env.API_URL}/horserace/${RaceId}?NameEn=${Value === undefined ? '' : Value}&size=${IncreasePage}`);
    const HorseForRaceData = res.data;
    return HorseForRaceData.data;
})
