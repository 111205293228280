import React from "react";

const AdsPopup = (data) => {
  console.log(data,'123')
  return (
    <>
      <div className="form">
        

        <div className="row">
        <h4>Primary Color</h4>

        <input type="color" id="color" name="color" value={data.data.PrimaryColor} />

        </div>

        <div className="row">
          <h4>Secondary Color</h4>
        <input type="color" id="color" name="color" value={data.data.SecondaryColor} />

        </div>

      </div>
    </>
  );
};

export default AdsPopup;
