import React, { useEffect, Fragment, useState } from "react";
import {
  fetchnationality,
  STATUSES,
} from "../../redux/getReducer/getNationality";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { MdDelete } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { BiEdit } from "react-icons/bi";
import swal from "sweetalert";
import Lottie from "lottie-react";
import HorseAnimation from "../../assets/horselottie.json";
import ScrollContainer from "react-indiana-drag-scroll";
import axios from "axios";
import { Modal } from "react-bootstrap";
import NationalityPopup from "../../Components/Popup/NationalityPopup";
import { BsEyeFill } from "react-icons/bs";
import Pagination from "./Pagination";
import { BiFilter } from "react-icons/bi";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import CSVNationalityPopup from "../../Components/CSVUploadPopup/NationalityPopup";
import Defaultimg from "../../assets/default.jpg";
import Notfound from "../../Notfound";
import { MdAutoDelete } from 'react-icons/md';
import { fetchVerifyAndDeleteNationality } from '../../redux/VerifyDeleteReducer/getnationalityverifydelete';


const NationalityTable = () => {
  //--------------------------state----------------------//

  const [ShowCalender, setShowCalender] = useState(false);
  const [SearchAge, setSearchAge] = useState("");
  const [SearchHemisphere, setHemisphere] = useState();
  const [SearchTitle, setSearchTitle] = useState("");
  const [TotalCount, setTotalCount] = useState();
  const [TotalPages, setTotalPages] = useState("");
  const [SearchCode, setSearchcode] = useState("");
  const { userInfo } = useSelector((state) => state.user);

  const [show, setShow] = useState(false);
  const [modaldata, setmodaldata] = useState();
  const handleClose = () => setShow(false);
  const handleShow = async (data) => {
    setmodaldata(data);
    await setShow(true);
  };
  //--------------------------csv import----------------------//

  const [showCSV, setShowCSV] = useState(false);
  const [modaldataCSV, setmodaldataCSV] = useState();
  const handleCloseCSV = () => setShowCSV(false);
  const handleShowCSV = async (data) => {
    setmodaldataCSV(data);
    await setShowCSV(true);
  };

  const dispatch = useDispatch();
  const history = useNavigate();
  const { data: nationality, status } = useSelector(
    (state) => state.nationality
  );

  const { data: VerifyAndDeleteNationality } = useSelector(
    (state) => state.VerifyAndDeleteNationality
  );

  const [loading, setloading] = useState(false)

  const [currentPage, setCurrentPage] = useState(1);

  const [postsPerPage] = useState(8);
  //--------------------------data for dropdown----------------------//

  const Hemisphere = [
    {
      id: "1",
      value: "Southern Hemisphere",
      label: (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <p>Southern Hemisphere</p>
        </div>
      ),
    },
    {
      id: "2",
      value: "Northern Hemisphere",
      label: (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <p>Northern Hemisphere</p>
        </div>
      ),
    },
  ];

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  //--------------------------api----------------------//
  // const Data1 = async () => {
  //   const res = await axios.get(
  //     `${window.env.API_URL}/Nationalityget?shortCode=${SearchCode}&NameEn=${SearchTitle}`
  //   );
  //   const totalcount = res.data.totalcount;
  //   setTotalCount(totalcount);

  //   const TotalPagesData = res.data.totalPages;
  //   setTotalPages(TotalPagesData);
  // };

  //--------------------------search----------------------//
  const GetSearch = async () => {
    dispatch(
      fetchnationality({
        SearchTitle,
        SearchCode,
        SearchAge,
        hemisphere,
        currentPage,
      })
    )
    setCurrentPage(1)
  };
  useEffect(() => {
    setTotalCount(nationality.totalcount)
    setTotalPages(nationality.totalPages)
  },[nationality])
  const hemisphere = SearchHemisphere ? SearchHemisphere.value : "";
  useEffect(() => {
    dispatch(
      fetchnationality({
        SearchTitle,
        SearchCode,
        SearchAge,
        hemisphere,
        currentPage,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, currentPage]);

  //--------------------------delete----------------------//

  const handleRemove = async (Id) => {
    try {
      swal({
        title: "Are you sure?",
        text: "do you want to delete this data ?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          await axios.delete(
            `${window.env.API_URL}/softdeleteNationality/${Id}`
          );
          swal("Your data has been deleted Successfully!", {
            icon: "success",
          });
          dispatch(
            fetchnationality({
              SearchTitle,
              SearchCode,
              SearchAge,
              hemisphere,
              currentPage,
            })
          );
        } else {
          swal("Your data is safe!");
        }
      });
    } catch (error) {
      const err = error.response.data.message;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
    }
  };

  const handleDeleteVerify = async (id) => {
    try {
      swal({
        title: "Are you sure?",
        text: "do you want to delete this data ?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          setloading(true)
          const res = await axios.delete(`${window.env.API_URL}/DeleteNationalityAlltoAll/${id}`);
          const msg = res.data.message ?  res.data.message : "deleted";
          swal(msg, {
            icon: "",
          });
      let data1 =  JSON.stringify(res.data.data)
      const blob = new Blob([data1]);
      if(res?.data?.flag){
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.download = "report1.json";
        link.href = url;
        link.click();
        setloading(false)
        dispatch(
      fetchnationality({
        SearchTitle,
        SearchCode,
        SearchAge,
        hemisphere,
        currentPage,
      })
    );

      }     
      setloading(false)
      dispatch(
      fetchnationality({
        SearchTitle,
        SearchCode,
        SearchAge,
        hemisphere,
        currentPage,
      })
    );

        } else {
          swal("Your data is safe!");
        }
      });
    } catch (error) {
      const err = error.success;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
      setloading(false)

    }

    // try {
    //   const res =  await axios.delete(`${window.env.API_URL}/VerifyAndDeleteColor/${id}`);
    //   console.log(res,'121 sa')
    //   await swal(res.data.data.ColorCodeData?.length !== 0 ? "Can't Delete Data beceause it is used in the table that are showing in report" : "Deleted", {
    //     icon: "",
    //   });
    //   let data1 =  JSON.stringify(res.data.data)
    //   const blob = new Blob([data1]);
    //   const url = URL.createObjectURL(blob);
    //   const link = document.createElement("a");
    //   link.download = "report1.json";
    //   link.href = url;
    //   link.click();
    //   dispatch(fetchcolorList({ SearchTitle, SearchCode, currentPage }));
    // } catch (error) {
    //   const err = error.response.data.message;
    //   swal({
    //     title: "Error!",
    //     text: err,
    //     icon: "error",
    //     button: "OK",
    //   });
    // }
  }

  
  if (status === STATUSES.ERROR) {
    return (
      <h2
        style={{
          margin: "100px",
        }}
      >
        Something went wrong!
      </h2>
    );
  }
  return (
    <Fragment>
      <div className="page">
        <div className="rightsidedata">
          <div
            style={{
              marginTop: "30px",
            }}
          >
            <div className="Header ">
              <h4>Nationality Listings</h4>

              <div>
                <Link to="/nationality">
                  <button>Add Nationality</button>
                </Link>
                <OverlayTrigger
                  overlay={<Tooltip id={`tooltip-top`}>Filter</Tooltip>}
                >
                  <span className="addmore">
                    <BiFilter
                      className="calendericon"
                      onClick={() => setShowCalender(!ShowCalender)}
                    />
                  </span>
                </OverlayTrigger>
                <p onClick={() => handleShowCSV()} className="importcsv">
                  Import JSON
                </p>
              </div>
            </div>
            <div>
              {ShowCalender ? (
                <span className="transitionclass">
                  <div className="userfilter">
                    <div className="filtertextform forflex">
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setSearchTitle(e.target.value)}
                        placeholder="Enter Name"
                      />
                      <input
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        className="form-control"
                        onChange={(e) => setSearchcode(e.target.value)}
                        placeholder="Enter Short Code"
                      />

                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setSearchAge(e.target.value)}
                        placeholder="Enter Alternative Name"
                      />
                      <div className="searchDropdown searchDropdown1">
                        <Select
                          placeholder={<div>Search Hemisphere</div>}
                          defaultValue={SearchHemisphere}
                          onChange={setHemisphere}
                          options={Hemisphere}
                          isClearable={true}
                          isSearchable={true}
                        />
                      </div>
                    </div>
                  </div>
                  <button className="filterbtn" onClick={GetSearch}>
                    Apply Filter
                  </button>
                </span>
              ) : (
                <></>
              )}
            </div>
            <>
              <div className="div_maintb">
                <ScrollContainer>
                  <table>
                    <thead>
                      <tr>
                        <th>Action</th>

                        <th>Name</th>
                        <th>Name Arabic </th>
                        <th>Short Code</th>

                        <th>Alternative Name </th>
                        <th>Alternative Name Arabic</th>
                        <th>Abbreviation </th>
                        <th>Abbreviation Arabic</th>
                        <th>Hemisphere English</th>
                        <th>Hemisphere Arabic</th>


                        <th>Image</th>
                      </tr>
                    </thead>
                    {loading ?  <Lottie
                            animationData={HorseAnimation}
                            loop={true}
                            className="TableLottie"
                          /> :    nationality.data === undefined ? (
                      <Notfound />
                    ) : (
                      <tbody>
                        {status === "loading" ? (
                          <Lottie
                            animationData={HorseAnimation}
                            loop={true}
                            className="TableLottie"
                          />
                        ) : (
                          nationality.data && nationality.data.map((item, index) => {
                            return (
                              <>
                                <tr className="tr_table_class">
                                  <td className="table_delete_btn1">
                                    <BiEdit
                                      onClick={() =>
                                        history("/editnationality", {
                                          state: {
                                            nationalityid: item,
                                          },
                                        })
                                      }
                                    />
                                  {/* <MdDelete
                                    style={{
                                      fontSize: "22px",
                                    }}
                                    onClick={() => handleRemove(item._id)}
                                  /> */}
 <MdAutoDelete
                                    onClick={() => handleDeleteVerify(item._id)}
                                  />
                                {/* {userInfo && userInfo.data.role === "admin" ? (
                                  <MdAutoDelete
                                    onClick={() => handleDeleteVerify(item._id)}
                                  />
                                ) : (
                                  <></>
                                )} */}
                                    <BsEyeFill
                                      onClick={() => handleShow(item)}
                                    />
                                  </td>
                                  <td>{item.NameEn}</td>
                                  <td>{item.NameAr}</td>
                                  <td>{item.shortCode} </td>

                                  <td>{item.AltNameEn}</td>
                                  <td>{item.AltNameAr}</td>
                                  <td>{item.AbbrevEn}</td>
                                  <td>{item.AbbrevAr}</td>
                                  <td>
                                    {item.HemisphereEn === undefined ? (
                                      <> Southern Hemisphere </>
                                    ) : (
                                      <>{item.HemisphereEn}</>
                                    )}
                                  </td>
                                  <td>
                                    {item.HemisphereAr ? (
                                      <>نصف الكرة الجنوبي</>
                                    ) : (
                                      <>نصف الكرة الشمالي</>
                                    )}
                                  </td>

                                  <td>
                                    <img
                                      src={item.image ? item.image : Defaultimg}
                                      alt=""
                                    />
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        )}
                      </tbody>
                    )}{" "}
                  </table>
                </ScrollContainer>
              </div>
            </>
          </div>
          <span className="plusIconStyle"></span>
          <Pagination
            postsPerPage={postsPerPage}
            totalPosts={TotalCount}
            currentPage={currentPage}
            paginate={paginate}
            TotalPages={TotalPages}
          />
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2 style={{ fontFamily: "inter" }}>Nationality</h2>
        </Modal.Header>
        <Modal.Body>
          <NationalityPopup data={modaldata} />
        </Modal.Body>

        <Modal.Footer>
          <button onClick={handleClose} className="modalClosebtn">
            Close
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showCSV}
        onHide={handleCloseCSV}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2 style={{ fontFamily: "inter" }}>Nationality JSON</h2>
        </Modal.Header>
        <Modal.Body>
          <CSVNationalityPopup data={modaldataCSV} />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default NationalityTable;
