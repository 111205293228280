import React, { useEffect, Fragment, useState } from "react";
import { fetchdeletedowner, STATUSES } from "../../redux/getDeletedreducer/DeletedOwnerSlice";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import ScrollContainer from "react-indiana-drag-scroll";
import Lottie from "lottie-react";
import HorseAnimation from "../../assets/horselottie.json";
import Pagination from "../../pages/GetTable/Pagination";
import { FaTrashRestoreAlt } from "react-icons/fa"
import { Modal } from "react-bootstrap";
import { BsEyeFill } from "react-icons/bs";
import Moment from "react-moment";
import OwnerPopup from "../Popup/OwnerPopup";


const DeletedOwner = () => {
  const [Disable, setDisable] = useState(true);
  //for Modal
  const [show, setShow] = useState(false);
  const [modaldata, setmodaldata] = useState();
  const handleClose = () => setShow(false);
  const handleShow = async (data) => {
    setmodaldata(data);
    await setShow(true);
  };
  const Restorefunction = async (id) => {
    try {

      //buttons disable
      setDisable(false)

      await axios.post(`${window.env.API_URL}/restoresoftdeleteowner/${id}`,);
      // api 
      // button enable
      dispatch(fetchdeletedowner());
      setDisable(true)
      swal({
        title: "Success!",
        text: "Data has been added successfully ",
        icon: "success",
        button: "OK",
      });
    } catch (error) {
      const err = error.response.data.message;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
    }



  }
  const dispatch = useDispatch();

  const { data: deletedowner, status } = useSelector((state) => state.deletedowner);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(8)

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = deletedowner.slice(indexOfFirstPost, indexOfLastPost);
  const paginate = pageNumber => setCurrentPage(pageNumber);


  useEffect(() => {
    dispatch(fetchdeletedowner());
  }, [dispatch]);


  if (status === STATUSES.LOADING) {
    return (
      <Lottie animationData={HorseAnimation} loop={true} className="Lottie" />
    );
  }

  if (status === STATUSES.ERROR) {
    return (
      <h2
        style={{
          margin: "100px",
        }}
      >
        Something went wrong!
      </h2>
    );
  }
  return (
    <>

      <div className="page">

        <div className="rightsidedata">
          <div
            style={{
              marginTop: "30px",
            }}
          >
            <div className="Header ">
              <h4>Owner Listings</h4>

              <div>
                <h6
                  style={{
                    marginRight: "100px",
                    alignItems: "center",
                    color: "rgba(0, 0, 0, 0.6)",
                  }}
                >

                </h6>

                {/* <Link to="/ownerform">
                  <button>Add Owner</button>
                </Link> */}
              </div>
            </div>
            <>
              <div className="div_maintb">
                <ScrollContainer className="scroll-container">
                  <table >
                    <thead>
                      <tr>
                        <th style={{ textAlign: 'center' }}>Action</th>
                        <th>Owner Name</th>
                        <th>Owner Name Arabic</th>
                        <th>Title</th>
                        <th>Title Arabic</th>
                        <th>Short Name</th>
                        <th>Short Name Arabic</th>
                        <th>Registeration Date</th>


                        <th>Image</th>

                      </tr>
                    </thead>
                    <tbody>
                      {deletedowner.map((item, index) => {
                        return (
                          <>
                            <tr className="tr_table_class">
                              <td className="table_delete_btn1">
                                <FaTrashRestoreAlt onClick={() => Restorefunction(item._id)} disabled={!Disable} />
                                <BsEyeFill onClick={() => handleShow(item)} />
                              </td>
                              <td>{item.NameEn}</td>
                              <td>{item.NameAr}</td>
                              <td>{item.TitleEn}</td>
                              <td>{item.TitleAr === '' ? <>N/A</> : item.TitleAr} </td>
                              <td>{item.ShortEn}</td>
                              <td>{item.ShortAr === '' ? <>N/A</> : item.ShortAr} </td>
                              <td>   <Moment format="YYYY/MM/DD">
                                {item.RegistrationDate}
                              </Moment></td>



                              <td>
                                <img src={item.image} alt="" />
                              </td>

                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </ScrollContainer>
              </div>

            </>
          </div>
          <Pagination
            postsPerPage={postsPerPage}
            totalPosts={deletedowner.length}
            paginate={paginate}
          />
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2>Owner </h2>
        </Modal.Header>
        <Modal.Body>
          <OwnerPopup data={modaldata} />
        </Modal.Body>
        <Modal.Footer>
          <button onClick={handleClose} className="modalClosebtn">
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default DeletedOwner;