import React, { useEffect, Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MdDelete } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import ScrollContainer from "react-indiana-drag-scroll";
import {
  fetchgroundtype,
  STATUSES,
} from "../../redux/getReducer/getGroundType";
import Defaultimg from "../../assets/default.jpg"
import Lottie from "lottie-react";
import HorseAnimation from "../../assets/horselottie.json";
import axios from "axios";
import { BiEdit } from "react-icons/bi";
import { BsEyeFill } from "react-icons/bs";
import { Modal } from "react-bootstrap";
import GroundTypePopup from "../../Components/Popup/GroundTypePopup";
import Pagination from "./Pagination";
import { BiFilter } from "react-icons/bi";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import CSVGround from "../../Components/CSVUploadPopup/GroundPopup"
import Notfound from "../../Notfound";
import { MdAutoDelete } from 'react-icons/md';
import { fetchVerifyAndDeleteGroundType } from '../../redux/VerifyDeleteReducer/getgroundverifydelete';
const GroundTypeTable = () => {
  //--------------------------useState----------------------//

  const [ShowCalender, setShowCalender] = useState(false);
  const [SearchAge, setSearchAge] = useState('');
  const [SearchCode, setSearchCode] = useState('');
  const [SearchTitle, setSearchTitle] = useState('');
  const [TotalCount, setTotalCount] = useState('');
  const [TotalPages, setTotalPages] = useState();
  const [loading, setloading] = useState(false)


  //for Modal
  const [show, setShow] = useState(false);
  const [modaldata, setmodaldata] = useState();
  const handleClose = () => setShow(false);
  const { userInfo } = useSelector((state) => state.user);

  const handleShow = async (data) => {
    setmodaldata(data);
    await setShow(true);
  };
  const [showCSV, setShowCSV] = useState(false);
  const [modaldataCSV, setmodaldataCSV] = useState();
  const handleCloseCSV = () => setShowCSV(false);
  const handleShowCSV = async (data) => {
    setmodaldataCSV(data);
    await setShowCSV(true);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: groundtype, status } = useSelector((state) => state.groundtype);
  const { data: VerifyAndDeleteGroundType } = useSelector(
    (state) => state.VerifyAndDeleteGroundType
  );
  const [currentPage, setCurrentPage] = useState(1);

  const [postsPerPage] = useState(8)



  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  //--------------------------api----------------------//


  const Data1 = async () => {
    const res = await axios.get(`${window.env.API_URL}/GroundTypeget?shortCode=${SearchCode}&NameEn=${SearchTitle}&page=${currentPage}`);
    const totalcount = res.data.totalcount;
    const TotalPagesData = res.data.totalPages;
    setTotalPages(TotalPagesData)

    setTotalCount(totalcount);

  };
  //--------------------------search----------------------//

  const GetSearch = async () => {
    dispatch(fetchgroundtype({ SearchTitle, SearchCode, SearchAge }));

  };


  useEffect(() => {
    dispatch(fetchgroundtype({ SearchTitle, SearchCode, SearchAge, currentPage }));
    Data1()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, currentPage]);

  //--------------------------delete----------------------//

  const handleRemove = async (Id) => {
    try {
      swal({
        title: "Are you sure?",
        text: "do you want to delete this data ?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          await axios.delete(
            `${window.env.API_URL}/softdeleteGroundType/${Id}`
          );
          swal("Your data has been deleted Successfully!", {
            icon: "success",
          });
          dispatch(fetchgroundtype({ SearchTitle, SearchCode, SearchAge }));
        } else {
          swal("Your data is safe!");
        }
      });
    } catch (error) {
      const err = error.response.data.message;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
    }
  };

  // const handleDeleteVerify = async (id) => {

  //   try {
  //     swal({
  //       title: "Are you sure?",
  //       text: "do you want to delete this data ?",
  //       icon: "warning",
  //       buttons: true,
  //       dangerMode: true,
  //     }).then(async (willDelete) => {
  //       if (willDelete) {
  //         setloading(true)
  //         const res = await axios.delete(`${window.env.API_URL}/VerifyAndDeleteGroundType/${id}`);
  //         const msg = res.data.message ?  res.data.message : "deleted";
  //         swal(msg, {
  //           icon: "",
  //         });
  //         let data1 =  JSON.stringify(res.data.data)
  //     const blob = new Blob([data1]);
  //     const url = URL.createObjectURL(blob);
  //   const link = document.createElement("a");
  //     link.download = "report1.json";
  //     link.href = url;
  //     link.click();
  //     setloading(false)
  //     dispatch(fetchgroundtype({ SearchTitle, SearchCode, SearchAge, currentPage }));
  //       } else {
  //         swal("Your data is safe!");
  //       }
  //     });
  //   } catch (error) {
  //     const err = error.success;
  //     swal({
  //       title: "Error!",
  //       text: err,
  //       icon: "error",
  //       button: "OK",
  //     });
  //   }

   
  // }


  const handleDeleteVerify = async (id) => {
    try {
      swal({
        title: "Are you sure?",
        text: "do you want to delete this data ?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          setloading(true)
          const res = await axios.delete(`${window.env.API_URL}/DeleteGroundAlltoAll/${id}`);
          const msg = res.data.message ?  res.data.message : "deleted";
          swal(msg, {
            icon: "",
          });
      let data1 =  JSON.stringify(res.data.data)
      const blob = new Blob([data1]);
      if(res?.data?.flag){
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.download = "report1.json";
        link.href = url;
        link.click();
        setloading(false)
        dispatch(fetchgroundtype({ SearchTitle, SearchCode, SearchAge, currentPage }));

      }     
      setloading(false)
      dispatch(fetchgroundtype({ SearchTitle, SearchCode, SearchAge, currentPage }));

        } else {
          swal("Your data is safe!");
        }
      });
    } catch (error) {
      const err = error.success;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
      setloading(false)

    }

    // try {
    //   const res =  await axios.delete(`${window.env.API_URL}/VerifyAndDeleteColor/${id}`);
    //   console.log(res,'121 sa')
    //   await swal(res.data.data.ColorCodeData?.length !== 0 ? "Can't Delete Data beceause it is used in the table that are showing in report" : "Deleted", {
    //     icon: "",
    //   });
    //   let data1 =  JSON.stringify(res.data.data)
    //   const blob = new Blob([data1]);
    //   const url = URL.createObjectURL(blob);
    //   const link = document.createElement("a");
    //   link.download = "report1.json";
    //   link.href = url;
    //   link.click();
    //   dispatch(fetchcolorList({ SearchTitle, SearchCode, currentPage }));
    // } catch (error) {
    //   const err = error.response.data.message;
    //   swal({
    //     title: "Error!",
    //     text: err,
    //     icon: "error",
    //     button: "OK",
    //   });
    // }
  }

  if (status === STATUSES.ERROR) {
    return (
      <h2
        style={{
          margin: "100px",
        }}
      >
        Something went wrong!
      </h2>
    );
  }

  return (
    <Fragment>
      <div className="page">
        <div className="rightsidedata">
          <div
            style={{
              marginTop: "30px",
            }}
          >
            <div className="Header ">
              <h4>Ground Type Listings</h4>

              <div>


                <Link to="/ground">
                  <button>Add Ground Type</button>
                </Link>
                <OverlayTrigger
                  overlay={<Tooltip id={`tooltip-top`}>Filter</Tooltip>}
                >
                  <span className="addmore">
                    <BiFilter
                      className="calendericon"
                      onClick={() => setShowCalender(!ShowCalender)}
                    />

                  </span>
                </OverlayTrigger>
                <p onClick={() => handleShowCSV()} className="importcsv">Import JSON</p>

              </div>
            </div>
            <div>
              {ShowCalender ? (
                <span className="transitionclass">
                  <div className="userfilter">

                    <div className="filtertextform forflex">

                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setSearchTitle(e.target.value)}
                        placeholder="Enter Ground Type"
                      />
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setSearchAge(e.target.value)}
                        placeholder="Enter Abbreviation"
                      />
                      <input
                         onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                        className="form-control"
                        onChange={(e) => setSearchCode(e.target.value)}
                        placeholder="Enter Short Code"
                      />
                    </div>

                  </div>
                  <button className="filterbtn" onClick={GetSearch}>
                    Apply Filter
                  </button>
                </span>
              ) : (
                <></>
              )}
            </div>

            <>
              <div className="div_maintb">
                <ScrollContainer>
                  <table>
                    <thead>
                      <tr>
                        <th>Action</th>
                        <th>Ground Type</th>
                        <th>Ground Type Arabic </th>
                        <th>Short Code</th>

                        <th>Abbreviation</th>
                        <th>Abbreviation Arabic </th>
                        <th>Image</th>


                      </tr>
                    </thead>
                    {
                     (
                      loading ? < Lottie animationData={HorseAnimation} loop={true} className="TableLottie" /> :   <tbody>




                      {status === STATUSES.LOADING ?


                        < Lottie animationData={HorseAnimation} loop={true} className="TableLottie" />

                        :

                        groundtype.map((item, index) => {
                          return (
                            <>
                              <tr className="tr_table_class" key={index}>
                                <td className="table_delete_btn1">
                                  <BiEdit
                                    onClick={() =>
                                      navigate("/editgroundtype", {
                                        state: {
                                          groundtypeid: item,
                                        },
                                      })
                                    }
                                  />
                                {/* <MdDelete
                                  style={{
                                    fontSize: "22px",
                                  }}
                                  onClick={() => handleRemove(item._id)}
                                /> */}

<MdAutoDelete
                                 onClick={() => handleDeleteVerify(item._id)}
                               />
                              {/* {userInfo && userInfo.data.role === "admin" ? (
                                <MdAutoDelete
                                  onClick={() => handleDeleteVerify(item._id)}
                                />
                              ) : (
                                <></>
                              )} */}
                                  <BsEyeFill onClick={() => handleShow(item)} />
                                </td>
                                <td>{item.NameEn}</td>
                                <td>{item.NameAr}</td>
                                <td>{item.shortCode} </td>

                                <td>{item.AbbrevEn}</td>
                                <td>{item.AbbrevAr}</td>
                                <td>
                                  <img src={item.image ? item.image : Defaultimg} alt="" />
                                </td>
                                {/* <td>
                              <Form.Check
                                type="switch"
                                id="custom-switch"
                                onChange={() => setValue(true)}
                                // label="Check this switch"
                                value={Value}
                              />
                            </td> */}

                              </tr>
                            </>
                          );
                        })}
                    </tbody>
                     )
                    } </table>
                </ScrollContainer>
              </div>
            </>
          </div>
          <span className="plusIconStyle"></span>
          <Pagination
            postsPerPage={postsPerPage}
            totalPosts={TotalCount}
            currentPage={currentPage}
            paginate={paginate}
            TotalPages={TotalPages}

          />
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2 style={{ fontFamily: "inter" }}>Ground Type </h2>
        </Modal.Header>
        <Modal.Body>
          <GroundTypePopup data={modaldata} />
        </Modal.Body>

        <Modal.Footer>
          <button onClick={handleClose} className="modalClosebtn">
            Close
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showCSV}
        onHide={handleCloseCSV}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2 style={{ fontFamily: "inter" }}>Ground Type JSON</h2>
        </Modal.Header>
        <Modal.Body>
          <CSVGround data={modaldataCSV} />
        </Modal.Body>
        <Modal.Footer>

        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default GroundTypeTable;
