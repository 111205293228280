import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";

import { fetchequipmentshortcode } from "../../redux/getShortCode/getequipmentshortcode";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../Components/Common/Loader";
import { Form, Input } from 'antd';

const Equipment = () => {
  //for error

  const dispatch = useDispatch();
  const { data: equipmentshortcode } = useSelector(
    (state) => state.equipmentshortcode
  );

  const [NameEn, setNameEn] = useState("");
  const [NameAr, setNameAr] = useState("");

  const [isLoading, setisLoading] = useState(false);

  const history = useNavigate();
  const { pathname } = useLocation();
  useEffect(() => {
    dispatch(fetchequipmentshortcode());
  }, [dispatch]);

  const [state1, setState] = useState({
    shortCode: "",
  });
  //----------------------------shortcode ---------------------//
  useEffect(() => {
    if (equipmentshortcode) {
      setState({
        shortCode:
          equipmentshortcode.length === 0
            ? 10
            : equipmentshortcode[0].maxshortCode + 1,
      });
    } else {
      setState.shortCode("9");
    }

  }, [equipmentshortcode]);
  console.log(state1.shortCode, "shortCode")
  //----------------------------submit---------------------//
  const submit = async () => {

    setisLoading(true);
    try {
      const formData = new FormData();

      formData.append("NameAr", NameAr);
      formData.append("NameEn", NameEn);
      formData.append("shortCode", state1.shortCode);

      await axios.post(`${window.env.API_URL}/uploadEquipment`, formData);
      if (pathname === "/equipment") {
        history("/equipmentlist");
      }
      swal({
        title: "Success!",
        text: "Data has been added successfully ",
        icon: "success",
        button: "OK",
      });
      setisLoading(false);
    } catch (error) {
      const err = error.response.data.message[0];
      const err1 = error.response.data.message[1];
      const err2 = error.response.data.message[2];


      swal({
        title: "Error!",
        text: err,
        err1,
        err2,
        icon: "error",
        button: "OK",
      });
      setisLoading(false);
    }
  };
  //----------------------------Input Validation---------------------//





  return (
    <div className="page">
      <div className="rightsidedata">
        <div
          style={{
            marginTop: "30px",
          }}
        >
          <div className="Headers">Create Equipment</div>
          {!isLoading ? <div className="form">
            <Form onFinish={submit}
              fields={[{

                name: "shortCode",
                value: state1.shortCode

              }]}
            >
              <div className="row mainrow">
                <div className="col-sm">


                  <Form.Item
                    name="NameEn"
                    label="Name"
                    rules={[
                      {
                        required: true,
                        message: 'Name (English) is required',
                      },
                    ]}
                  >
                    <Input placeholder="Name" onChange={(e) => setNameEn(e.target.value)}
                      value={NameEn} name="NameEn" />
                  </Form.Item>



                  <span className="spanForm"> |</span>





                </div>

                <div className="col-sm">
                  <Form.Item
                    name="NameAr"
                    label="اسم"
                    rules={[
                      {
                        required: true,
                        message: 'Name (Arabic) is required',
                      },
                    ]}
                    style={{ direction: 'rtl' }}
                  >
                    <Input placeholder="اسم" onChange={(e) => setNameAr(e.target.value)}
                      name="Name"
                      value={NameAr} />
                  </Form.Item>

                </div>
              </div>
              <div className="row mainrow">
                <div className="col-sm">

                  <Form.Item
                    name="shortCode"
                    label="Short Code"
                    rules={[
                      {
                        required: true,
                        message: "short Code is required"
                      },
                    ]}
                  >
                    <Input
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(e) =>
                        setState({ ...state1, shortCode: e.target.value })
                      }
                      value={state1.shortCode}


                      onPaste={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      placeholder="Short Code"
                      maxLength="5"
                      name="shortCode"
                    />
                  </Form.Item>

                </div>
              </div>

              <div className="ButtonSection" style={{ justifyContent: "end" }}>
                <button
                  type="submit"
                  className="SubmitButton"
                  disabled={isLoading}
                >
                 Save
                </button>
              </div>
            </Form>
          </div> : <Loader />}
        </div>
      </div>
    </div>
  );
};

export default Equipment;
