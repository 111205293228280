import React, { useState } from "react";
import swal from "sweetalert";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";



import Loader from "../../Components/Common/Loader";
import { Form, Input } from 'antd';

const Horsekindform = ({ setshowhorseKind }) => {
  //FOR ERRORSaa


  const [NameEn, setNameEn] = useState("");
  const [NameAr, setNameAr] = useState("");
  const [shortName, setshortName] = useState("");
  const [shortNameAr, setshortNameAr] = useState("");
  const [isLoading, setisLoading] = useState(false);

  const history = useNavigate();
  const { pathname } = useLocation();

  //-------submit-------//

  const submit = async () => {

    setisLoading(true);
    try {
      const formData = new FormData();
      formData.append("NameEn", NameEn);
      formData.append("NameAr", NameAr);

      formData.append("AbbrevEn", shortName);
      formData.append("AbbrevAr", shortNameAr);
      await axios.post(`${window.env.API_URL}/uploadHorseKind`, formData);
      swal({
        title: "Success!",
        text: "Data has been added successfully ",
        icon: "success",
        button: "OK",
      });

      if (pathname === "/horsekindform") {
        return history("/horsekind");
      }
      setisLoading(false);
      setshowhorseKind(false);

    } catch (error) {
      const err = error.response.data.message[0];

      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
      setisLoading(false);

    }
  };



  return (
    <div className="page">
      <div className="rightsidedata">
        <div
          style={{
            marginTop: "30px",
          }}
        >
          <div className="Headers">Create Horse Kind</div>
          {isLoading ? (
            <Loader />
          ) : (
            <div className="form">
              <Form onFinish={submit}>
                <div className="row mainrow">
                  <div className="col-sm">


                    <Form.Item
                      name="NameEn"
                      label="Name"
                      rules={[
                        {
                          required: true,
                          message: 'Name (English) is required',
                        },
                      ]}
                    >
                      <Input placeholder="Name" onChange={(e) => setNameEn(e.target.value)}
                        value={NameEn} name="NameEn" />
                    </Form.Item>



                    <span className="spanForm spacer"> |</span>





                  </div>

                  <div className="col-sm">
                    <Form.Item
                      name="NameAr"
                      label="اسم"
                      rules={[
                        {
                          required: true,
                          message: 'Name (Arabic) is required',
                        },
                      ]}
                      style={{ direction: 'rtl' }}
                    >
                      <Input placeholder="اسم" onChange={(e) => setNameAr(e.target.value)}
                        name="Name"
                        value={NameAr} />
                    </Form.Item>

                  </div>
                </div>




                <div className="row mainrow">
                  <div className="col-sm">


                    <Form.Item
                      name="Abbreviation"
                      label="Short Name "
                      rules={[
                        {
                          required: true,
                          message: 'Short Name  is required',
                        },
                      ]}
                    >
                      <Input placeholder="Short Name "

                        onChange={(e) => setshortName(e.target.value)}
                        value={shortName} />
                    </Form.Item>



                    <span className="spanForm"> |</span>





                  </div>

                  <div className="col-sm">
                    <Form.Item
                      name="اكتب الاختصار"
                      label="اسم قصير"
                      rules={[
                        {
                          required: true,
                          message: 'Short Name  (Arabic) is required',
                        },
                      ]}
                      style={{ direction: 'rtl' }}
                    >
                      <Input placeholder="اكتب الاختصار" onChange={(e) => setshortNameAr(e.target.value)}
                        value={shortNameAr}




                      />
                    </Form.Item>

                  </div>
                </div>
                {/* <div className="row mainrow">
                  <div className="col-sm">
                  <FloatingLabel
                      controlId="floatingInput"
                      label="Short Code"
                      className="mb-3"
                      onChange={(e) =>
                        setState({ ...state1, shortCode: e.target.value })
                      }
                    
                    >
                      <Form.Control  onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }} placeholder="Description" value={state1.shortCode}/>
                    </FloatingLabel>
                 
									
                  </div>
                </div> */}
                <div
                  className="ButtonSection "
                  style={{ justifyContent: "end" }}
                >
                  <button
                    type="submit"
                    className="SubmitButton"
                    disabled={isLoading}
                  >
                   Save
                  </button>
                </div>
              </Form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Horsekindform;
