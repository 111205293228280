import axios from "axios";
import { createAsyncThunk,createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
    IDLE : 'idle',
    ERROR:'error',
    LOADING: 'loading',
});

const getVerifyAndDeleteHorseKindSlice = createSlice({
    name: 'VerifyAndDeleteHorseKind',
    initialState: {
        data:[],
        status : STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
        .addCase(fetchVerifyAndDeleteHorseKind.pending, (state, action) => {
            state.status = STATUSES.LOADING;
        })
        .addCase(fetchVerifyAndDeleteHorseKind.fulfilled, (state, action) => {
            state.data = action.payload;
            state.status = STATUSES.IDLE
        }) 
        .addCase(fetchVerifyAndDeleteHorseKind.rejected , (state,action) => {
            state.status = STATUSES.ERROR;
        })
    }
});

export const {setVerifyAndDeleteHorseKind , setStatus} = getVerifyAndDeleteHorseKindSlice.actions;
export default getVerifyAndDeleteHorseKindSlice.reducer;

export const fetchVerifyAndDeleteHorseKind = createAsyncThunk('/getVerifyAndDeleteHorseKind/fetch', async({id}) => {
    const res = await axios.delete(`${window.env.API_URL}/VerifyAndDeleteHorseKind/${id}`);
    const VerifyAndDeleteHorseKindData = res.data;
    return VerifyAndDeleteHorseKindData.data;
})