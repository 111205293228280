import React, { useEffect, useState } from "react";
import { STATUSES } from "../../redux/getReducer/getRaceSlice";
import { fetchtobePublishRace } from "../../redux/getReducer/getToBePublishRace";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Defaultimg from "../../assets/default.jpg";
import "../../Components/CSS/Table.css";
import ScrollContainer from "react-indiana-drag-scroll";
import "../../Components/CSS/race.css";
import { Modal } from "react-bootstrap";
import { MdDelete } from "react-icons/md";
import swal from "sweetalert";
import Moment from "react-moment";
import Lottie from "lottie-react";
import HorseAnimation from "../../assets/horselottie.json";
import axios from "axios";
import { BiEdit } from "react-icons/bi";
import RaceDetailPopup from "../../Components/Popup/RaceDetailPopup";
import { BsEyeFill } from "react-icons/bs";
import Pagination from "./Pagination";
import { BiFilter } from "react-icons/bi";
import { CSVLink } from "react-csv";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import Notfound from "../../Notfound";

const Prize = (data) => {
  //---------------------------prize-------------------------//
  return (
    <>
      <table className="Prizeclass">
        <thead className="Prizeclassthead">
          <tr>
            <th>1st</th>
            <th>2nd </th>
            <th>3rd </th>
            <th>4th </th>
            <th>5th </th>
            <th>6th </th>
          </tr>
        </thead>
        <tbody className="Prizeclasstbody">
          <tr>
            <td>{data.data.FirstPrice}</td>
            <td>{data.data.SecondPrice}</td>
            <td>{data.data.ThirdPrice}</td>
            <td>{data.data.FourthPrice}</td>
            <td>{data.data.FifthPrice}</td>
            <td>{data.data.SixthPrice}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

const Races = () => {
  //---------------------------state-------------------------//

  const [ShowCalender, setShowCalender] = useState(false);

  const history = useNavigate();
  const [show, setShow] = useState(false);
  const [modaldata, setmodaldata] = useState();
  const handleClose = () => setShow(false);
  const handleShow = async (data) => {
    setmodaldata(data);
    await setShow(true);
  };
  //---------------------------modal-------------------------//

  const [showRacePopup, setShowRacePopup] = useState(false);
  const [modaldataPopup, setmodaldataPopup] = useState();
  const handleCloseRacePopup = () => setShowRacePopup(false);
  const handleShowRacePopup = async (data) => {
    setmodaldataPopup(data);
    await setShowRacePopup(true);
  };

  const dispatch = useDispatch();
  const { data: tobePublishRace, status } = useSelector(
    (state) => state.tobePublishRace
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(8);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = tobePublishRace.slice(indexOfFirstPost, indexOfLastPost);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);



  useEffect(() => {
    // dispatch(fetchrace());
    dispatch(fetchtobePublishRace());
  }, [dispatch]);

  //---------------------------delete-------------------------//

  const handleRemove = async (Id) => {
    try {
      swal({
        title: "Are you sure?",
        text: "do you want to delete this data ?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        await axios.delete(`${window.env.API_URL}/softdeleterace/${Id}`);

        if (willDelete) {
          swal("Your data has been deleted Successfully!", {
            icon: "success",
          });
          dispatch(fetchtobePublishRace());
        } else {
          swal("Your data is safe!");
        }
      });
    } catch (error) {
      const err = error.response.data.message;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
    }
  };

  const GoToPublish = (RaceId) => {
    history("/publishrace", {
      state: {
        RaceId: RaceId,
      },
    });
  };

  if (status === STATUSES.ERROR) {
    return (
      <h2
        style={{
          margin: "100px",
        }}
      >
        Something went wrong!
      </h2>
    );
  }

  return (
    <>
      <div className="page">
        <div className="rightsidedata">
          <div
            style={{
              marginTop: "30px",
            }}
          >
            <div className="Header ">
              <h4> Race To Be Published</h4>
              <div>
                <Link to="/raceform">
                  <button>Add Race</button>
                </Link>
                <OverlayTrigger
                  overlay={<Tooltip id={`tooltip-top`}>Filter</Tooltip>}
                >
                  <span className="addmore">
                    <BiFilter
                      className="calendericon"
                      onClick={() => setShowCalender(!ShowCalender)}
                    />
                  </span>
                </OverlayTrigger>{" "}
                <CSVLink
                  data={tobePublishRace}
                  separator={";"}
                  filename={"MKS Race.csv"}
                  className="csvclass"
                >
                  Export CSV
                </CSVLink>
              </div>
            </div>
            <div>
              {ShowCalender ? (
                <span className="transitionclass">
                  <div className="userfilter">
                    <div className="filtertextform forflex">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Title"
                      />
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Description"
                      />
                    </div>
                  </div>
                  <button className="filterbtn">Apply Filter</button>
                </span>
              ) : (
                <></>
              )}
            </div>
            <div className="div_maintb">
              <ScrollContainer className="scroll-container">
                <table className="Sc">
                  <thead
                    style={{
                      marginTop: "30px",
                    }}
                  >
                    <tr className="trtabletd">
                      <th>Action</th>
                      <th>Race Name</th>
                      <th>Race Name Arabic </th>
                      <th>Publish</th>
                      <th>Race Kind </th>
                      <th>Meeting Code</th>
                      <th>Meeting Type </th>
                      <th>Race Type</th>
                      <th>Race Course</th>
                      <th>Description</th>
                      <th>Description Arabic</th>
                      <th>Track Length</th>
                      <th>Weather in Degree</th>
                      <th>Weather Type</th>
                      <th>Day</th>
                      <th>Time</th>

                      <th>Race Status</th>
                      <th>Horse Kind</th>
                      <th>Result Status</th>
                      <th>Prize Money</th>
                      <th>Sponsor</th>
                      <th>Sponsor Image</th>
                    </tr>
                  </thead>
                  {tobePublishRace.length === 0 ? (
                    <div>
                      <Notfound />
                    </div>
                  ) : (
                    <>
                      {status === STATUSES.LOADING ? (
                        <Lottie
                          animationData={HorseAnimation}
                          loop={true}
                          className="TableLottie"
                        />
                      ) : (
                        currentPosts.map((item) => {
                          const { RaceStatus } = item;
                          return (
                            <tbody
                              key={item._id}
                              style={{
                                marginTop: "20px",
                              }}
                            >
                              <tr>
                                <td
                                  className="table_delete_btn1"
                                  style={{ textAlign: "center" }}
                                >
                                  <BiEdit
                                    onClick={() =>
                                      history("/editrace", {
                                        state: {
                                          fullraceid: item,
                                        },
                                      })
                                    }
                                  />
                                  <MdDelete
                                    onClick={() => handleRemove(item._id)}
                                  />
                                  <BsEyeFill
                                    onClick={() => handleShowRacePopup(item)}
                                  />
                                </td>
                                <td
                                  style={{
                                    backgroundColor: `${RaceStatus === "Cancelled"
                                      ? "#000000"
                                      : RaceStatus === "Completed"
                                        ? "#FF0000"
                                        : RaceStatus === "Live"
                                          ? "#5EC30F"
                                          : "#FF9900"
                                      }`,
                                    color: `${RaceStatus === "Cancelled"
                                      ? "#ffff"
                                      : RaceStatus === "Completed"
                                        ? "#ffff"
                                        : RaceStatus === "Live"
                                          ? "#ffff"
                                          : "#fff"
                                      }`,
                                    fontWeight: "600",
                                  }}
                                >
                                  {!item.RaceNameModelData ? (
                                    <></>
                                  ) : (
                                    item.RaceNameModelData.NameEn
                                  )}
                                </td>
                                <td>
                                  {item.RaceNameModelData === null ? (
                                    <>-</>
                                  ) : item.RaceNameModelData.NameAr ===
                                    undefined ? (
                                    <></>
                                  ) : (
                                    item.RaceNameModelData.NameAr
                                  )}
                                </td>
                                <td>
                                  <button
                                    className="Approvedbtn resultbtn"
                                    onClick={() => GoToPublish(item._id)}
                                  >
                                    Click
                                  </button>
                                </td>
                                <td>
                                  {item.RaceKindData
                                    ? item.RaceKindData.NameEn
                                    : "-"}
                                </td>
                                <td>{item.MeetingCode}</td>
                                <td>
                                  {item.MeetingTypeData
                                    ? item.MeetingTypeData.NameEn
                                    : "-"}
                                </td>
                                <td>
                                  {item.RaceTypeModelData === null ? (
                                    <>-</>
                                  ) : (
                                    item.RaceTypeModelData.NameEn
                                  )}
                                </td>
                                <td>
                                  {item.RaceCourseData === null ? (
                                    <>-</>
                                  ) : (
                                    item.RaceCourseData.TrackNameEn
                                  )}
                                </td>
                                <td
                                  style={{
                                    maxHeight: "400px",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {item.DescriptionEn}
                                </td>
                                <td
                                  style={{
                                    maxHeight: "400px",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {item.DescriptionAr}
                                </td>
                                <td>
                                  {item.TrackLengthData &&
                                    item.TrackLengthData.TrackLength}
                                </td>
                                <td>{item.WeatherDegree}</td>
                                <td>{item.WeatherType}</td>
                                <td>
                                  {" "}
                                  <Moment format="YYYY/MM/DD">
                                    {item.Day}
                                  </Moment>
                                </td>

                                <td>{item.StartTime}</td>

                                <td>{item.RaceStatus}</td>
                                <td>
                                  {" "}
                                  {!item.HorseKindinRaceData ? (
                                    <></>
                                  ) : (
                                    item.HorseKindinRaceData.NameEn
                                  )}
                                </td>
                                <td>{item.ResultStatus}</td>
                                <td>
                                  <button
                                    className="Approvedbtn resultbtn"
                                    onClick={() => handleShow(item)}
                                  >
                                    Click
                                  </button>
                                </td>
                                <td>
                                  {item.SponsorData
                                    ? item.SponsorData.TitleEn
                                    : "-"}{" "}
                                </td>
                                <td>
                                  <img
                                    src={
                                      item.SponsorData
                                        ? item.SponsorData.image
                                        : Defaultimg
                                    }
                                    alt=""
                                    style={{
                                      width: "50px",
                                      height: "50px",
                                    }}
                                  />{" "}
                                </td>
                              </tr>
                            </tbody>
                          );
                        })
                      )}
                    </>
                  )}
                </table>
              </ScrollContainer>
            </div>
          </div>
          <Pagination
            postsPerPage={postsPerPage}
            totalPosts={tobePublishRace.length}
            paginate={paginate}
            currentPage={currentPage}
          />
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2>Race Prize </h2>
        </Modal.Header>
        <Modal.Body>
          <Prize data={modaldata} />
        </Modal.Body>
        <Modal.Footer>
          <button onClick={handleClose} className="modalClosebtn">
            Close
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showRacePopup}
        onHide={handleCloseRacePopup}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2 style={{ fontFamily: "inter" }}>Race</h2>
        </Modal.Header>
        <Modal.Body>
          <RaceDetailPopup data={modaldataPopup} />
        </Modal.Body>
        <Modal.Footer>
          <button onClick={handleCloseRacePopup} className="modalClosebtn">
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default Races;
