import React, { useEffect, Fragment, useRef } from "react";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { fetchHorseforRace } from "../../../redux/getReducer/getHorsesForRace";
import { fetchverdict } from "../../../redux/getReducer/getVerdict";
import { fetchEditVerdictHorse } from "../../../redux/getReducer/getEditVerdictRaceSlice";
import Select from "react-select";
import swal from "sweetalert";

import axios from "axios";

import { toast } from "react-toastify";

const LocalItem = () => {
  const list = localStorage.getItem("verdict");
  if (list) {
    return JSON.parse(localStorage.getItem("verdict"));
  } else {
    return [];
  }
};

const EditRaceVerdict = () => {

  const [shortCode, setSearchCode] = useState("");
  const [FirstHorse, SetFirstHorse] = useState("");
  const [SecondHorse, SetSecondHorse] = useState("");
  const [ThirdHorse, SetThirdHorse] = useState("");
  const [VerdictRemark, SetVerdictRemark] = useState("");

  const [VerdictName, SetVerdictName] = useState("");

  const [NameEn, setNameEn] = useState("");

  const [StoreDataVerdict, setStoreDataVerdict] = useState([]);

  const [Gate, setGate] = useState(1);
  const [itemsverdict, setitemsverdict] = useState(LocalItem());
  const [items, setitems] = useState(LocalItem());

  const { data: HorseforRace } = useSelector((state) => state.HorseforRace);
  const { data: verdict } = useSelector((state) => state.verdict);
  const { data: EditVerdictHorse } = useSelector((state) => state.EditVerdictHorse);

  const history = useNavigate();
  const { state } = useLocation();
  const { RaceId } = state;


  //-------------------Function For Slider -------------------//

  const slider = document.querySelector('.horizontal-scroll-wrapper');
  let isDown = false;
  let startX;
  let scrollLeft;
  if (slider) {
    slider.addEventListener('mousedown', (e) => {
      isDown = true;
      slider.classList.add('active');
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    });

    slider.addEventListener('mouseleave', () => {
      isDown = false;
      slider.classList.remove('active');
    });
    slider.addEventListener('mouseup', () => {
      isDown = false;
      slider.classList.remove('active');
    });
    slider.addEventListener('mousemove', (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 3; //scroll-fast
      slider.scrollLeft = scrollLeft - walk;

    });
  }




  let horseoptions = HorseforRace.map(function (item) {
    return {
      id: item.HorseModelIdData1._id,
      value: item.HorseModelIdData1._id,
      label: item.HorseModelIdData1.NameEn,
      isdisabled: FirstHorse.id !== item.HorseModelIdData1._id && SecondHorse.id !== item.HorseModelIdData1._id ? false : true,
    };
  });

  let AllVerdict = verdict.map(function (item) {
    return {
      id: item._id,
      value: item.NameEn,
      label: item.NameEn,
      isdisabled: item._id !== VerdictName.id ? false : true,

    };
  });
  let formRef = useRef();

  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(fetchHorseforRace({ RaceId }));

    dispatch(fetchverdict({ NameEn, shortCode }));
    dispatch(fetchEditVerdictHorse({ RaceId }))
  }, [NameEn, RaceId, dispatch, shortCode]);

  useEffect(() => {
    localStorage.setItem("verdictdata", JSON.stringify(StoreDataVerdict));

    localStorage.setItem("verdict", JSON.stringify(items));
  }, [StoreDataVerdict, items]);



  const saveEditItem = async (event, data) => {
    event.preventDefault();
    const formData = new FormData();
    let a = {
      VerdictName: undefined,
      FirstHorse: undefined,
      SecondHorse: undefined,
      ThirdHorse: undefined,
      VerdictRemark: undefined,
     
    };
    // eslint-disable-next-line array-callback-return
    EditVerdictHorse.map((singleentry) => {
      if (singleentry.id === data) {
        a.VerdictName = singleentry?.VerdictNameData?._id;
        a.FirstHorse = singleentry?.HorseNo1Data?._id;
        a.SecondHorse = singleentry?.HorseNo2Data?._id;
        a.ThirdHorse = singleentry?.HorseNo3Data?._id;
        a.VerdictRemark = singleentry?.Remarks
        
      }
    });
    formData.append("VerdictName", VerdictName.id === undefined ? a.VerdictName : VerdictName.id);
    formData.append("HorseNo1", FirstHorse.id === undefined ? a.FirstHorse : FirstHorse.id);
    formData.append("HorseNo2", SecondHorse.id === undefined ? a.SecondHorse : SecondHorse.id);
    formData.append("HorseNo3", ThirdHorse.id === undefined ? a.ThirdHorse : ThirdHorse.id);
    formData.append("Remarks", VerdictRemark === undefined ? a.VerdictRemark : VerdictRemark);
   
 

    formData.append("Rowid", data);

    try {
      await axios.put(
        `${window.env.API_URL}EditRaceVerdictv2/${RaceId}`,
        formData
      );
      localStorage.removeItem("lists");
      swal({
        title: "Success",
        text: "Data has been added successfully ",
        icon: "success",
        button: "OK",
      });
      // window.location.reload();
      SetVerdictName("");
      SetFirstHorse("");
      SetSecondHorse("");
      SetThirdHorse("")
      SetVerdictRemark("")
    } catch (error) {
      const err = error.response.data.message;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
      SetVerdictName("");
      SetFirstHorse("");
      SetSecondHorse("");
      SetThirdHorse("")
      SetVerdictRemark("")
    }
  };


  const addItem = (e) => {
    e.preventDefault();
    let VerdictEntry = [
      `${VerdictName.id},${FirstHorse.id},${SecondHorse.id},${ThirdHorse.id},${VerdictRemark || "N/A"}`,
    ];

    let VerdictMappingData = (
      {
        VerdictName: VerdictName.label,
        FirstHorse: FirstHorse.label,
        SecondHorse: SecondHorse.label,
        ThirdHorse: ThirdHorse.label,
        VerdictRemark: VerdictRemark,
      }
    );


    if (FirstHorse === "" || VerdictName === "") {
      toast("Select Values ");
    }


    else {
      setitemsverdict([...itemsverdict, VerdictEntry]);
      setStoreDataVerdict([...StoreDataVerdict, VerdictMappingData]);
      setGate(Gate + 1);
      dispatch(fetchverdict());
      formRef.current?.reset();
    }
    SetVerdictName("");
    SetFirstHorse("");
    SetSecondHorse("");
    SetThirdHorse("")
    SetVerdictRemark("")
  };







  const handleRemove = async (Id) => {
    try {
      swal({
        title: "Are you sure?",
        text: "do you want to delete this data ?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          await axios.delete(`${window.env.API_URL}/DeleteRaceVerdict/${Id}`);
          swal("Your data has been deleted Successfully!", {
            icon: "success",
          });
          dispatch(fetchEditVerdictHorse({ RaceId }))
        } else {
          swal("Your data is safe!");
        }
      });
    } catch (error) {
      const err = error.response.data.message;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
    }
  };




  const addMore = (e) => {
    history("/editmoreverdict", {
      state: {
        RaceId: RaceId,
      },
    });
  };

  const submit = async (event) => {
    event.preventDefault();
    try {
      await axios.put(
        `${window.env.API_URL}EditRaceVerdict/${RaceId}`,
        { VerdictEntry: items }
      );

      localStorage.removeItem("verdict");
      setGate(1);
      history("/races");
      swal({
        title: "Success",
        text: "Data has been added successfully ",
        icon: "success",
        button: "OK",
      });
    } catch (error) {
      const err = error.response.data.message;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
    }
  };
  const handleInputChange = (e) => {
    const trimmedValue = e.target.value.replace(/^\s+/, ''); // Trim leading spaces
    SetVerdictRemark(trimmedValue);
  };

  return (
    <Fragment>
      <div className="page">
        <div className="rightsidedata edditver">
          <div
            style={{
              marginTop: "30px",
            }}
          >
            <div className="Header ">
              <h4>Edit Verdict</h4>
              <button className="AddAnother1" onClick={addMore}>
                Add More
              </button>
            </div>
            <div className="horizontal-scroll-wrapper squares">
              <div className="myselecthorse editver">
                <div className="myselecthorsedata">

                  <span>Verdict Name</span>
                  <span>1st Horse </span>
                  <span>2nd Horse </span>
                  <span>3rd Horse </span>
                  <span>Remarks </span>
                  <span>Action</span>
                </div>
              </div>

              <div className="myselectdata">

                {EditVerdictHorse.map((item, i) => {
                  return (
                    <div className="myselectiondata myselectiondataaddHorse2 editver">


                      <span>
                        <Select
                          placeholder={item?.VerdictNameData?.NameEn}
                          onChange={SetVerdictName}
                          options={AllVerdict}
                          isClearable={false}
                          isSearchable={true}
                        />
                      </span>

                      <span>
                        <Select
                          placeholder={item?.HorseNo1Data?.NameEn}
                          onChange={SetFirstHorse}
                          options={horseoptions}
                          isClearable={false}
                          isSearchable={true}
                          isOptionDisabled={(option) => option.isdisabled}
                        />
                      </span>
                      <span>
                        <Select
                          placeholder={item?.HorseNo2Data?.NameEn}
                          onChange={SetSecondHorse}
                          options={horseoptions}
                          isClearable={false}
                          isSearchable={true}
                          isOptionDisabled={(option) => option.isdisabled}

                        />
                      </span>
                      <span>
                        <Select
                          placeholder={item?.HorseNo3Data?.NameEn}
                          defaultValue={ThirdHorse}
                          onChange={SetThirdHorse}
                          options={horseoptions}
                          isClearable={false}
                          isSearchable={true}
                          isOptionDisabled={(option) => option.isdisabled}

                        />
                      </span>
                      <span>
                        <input
                          type="text"
                          className="Remarksverdict"
                          onChange={handleInputChange}

                          placeholder={item.Remarks}
                        />
                      </span>
                      <button className="savebtnhorse" onClick={(e) => saveEditItem(e, item.id)}>
                        Save
                      </button>
                      <button className="removebtnhorse" onClick={() => handleRemove(item?.id)}>
                        Remove
                      </button>
                    </div>
                  );
                })}

              </div>

            </div>
            <div className="sbmtbtndiv">
              <div className="RaceButtonDiv">
                {/* <button className="updateButton" onClick={Remove}>
                    Remove
                  </button> */}
                <button
                  className="SubmitButton"
                  type="submit"
                  onClick={submit}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EditRaceVerdict;
