import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";

import { fetchsexshortcode } from "../../redux/getShortCode/getsexshortcode";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../Components/Common/Loader";
import { Form, Input } from 'antd';


const Gender = () => {
  //for error
  const dispatch = useDispatch();


  const { data: sexshortcode } = useSelector((state) => state.sexshortcode);

  const [NameEn, setNameEn] = useState("");
  const [NameAr, setNameAr] = useState("");

  const [AbbrevEn, setAbbrevEn] = useState("");
  const [AbbrevAr, setAbbrevAr] = useState("");
  const [isLoading, setisLoading] = useState(false);

  const history = useNavigate();
  const { pathname } = useLocation();
  const [state1, setState] = useState({
    shortCode: "",
  });

  //----------------------------shortcode -------------------------//
  useEffect(() => {
    if (sexshortcode) {
      setState({
        shortCode:
          sexshortcode.length === 0 ? 10 : sexshortcode[0].maxshortCode + 1,
      });
    } else {
    }
  }, [sexshortcode]);

  useEffect(() => {
    dispatch(fetchsexshortcode());
  }, [dispatch]);
  //----------------------------submit -------------------------//
  const submit = async () => {

    setisLoading(true);
    try {
      const formData = new FormData();
      formData.append("NameEn", NameEn);
      formData.append("NameAr", NameAr);

      formData.append("shortCode", state1.shortCode);
      formData.append("AbbrevEn", AbbrevEn);
      formData.append("AbbrevAr", AbbrevAr);

      await axios.post(`${window.env.API_URL}/uploadSex`, formData);
      swal({
        title: "Success!",
        text: "Data has been added Successfully ",
        icon: "success",
        button: "OK",
      });
      if (pathname === "/gender") {
        history("/genderlist");
      }
      setisLoading(false);
    } catch (error) {
      const err = error.response.data.message[0];
      const err1 = error.response.data.message[1];
      const err2 = error.response.data.message[2];

      swal({
        title: "Error!",
        text: err,
        err1,
        err2,
        icon: "error",
        button: "OK",
      });
      setisLoading(false);
    }
  };
  //----------------------------input validation -------------------------//


  return (
    <div className="page">
      <div className="rightsidedata">
        <div
          style={{
            marginTop: "30px",
          }}
        >
          <div className="Headers">Create Gender</div>
          {isLoading ? <Loader /> : <div className="form">
            <Form onFinish={submit}

              fields={[{

                name: "shortCode",
                value: state1.shortCode

              }]}
            >
              <div className="row mainrow">
                <div className="col-sm">
                  <Form.Item
                    name="NameEn"
                    label="Name"
                    rules={[
                      {
                        required: true,
                        message: 'Name (English) is required',
                      },
                    ]}
                  >
                    <Input placeholder="Name" onChange={(e) => setNameEn(e.target.value)}
                      value={NameEn} name="NameEn" />
                  </Form.Item>


                  <span className="spanForm spacer"> |</span>

                </div>

                <div className="col-sm">
                  <Form.Item
                    name="NameAr"
                    label="اسم"
                    rules={[
                      {
                        required: true,
                        message: 'Name (Arabic) is required',
                      },
                    ]}
                    style={{ direction: 'rtl' }}
                  >
                    <Input placeholder="اسم" onChange={(e) => setNameAr(e.target.value)}
                      name="Name"
                      value={NameAr} />
                  </Form.Item>

                </div>
              </div>
              <div className="row mainrow">
                <div className="col-sm">
                  <Form.Item
                    name="AbbrevEn"
                    label="Abbreviation"
                    rules={[
                      {
                        required: true,
                        message: 'Abbreviation (English) is required',
                      },
                    ]}
                  >
                    <Input placeholder="Abbrevation" onChange={(e) => setAbbrevEn(e.target.value)}
                      value={AbbrevEn}
                      name="AbbrevEn"
                      type="text"
                    />
                  </Form.Item>


                  <span className="spanForm spacer"> |</span>

                </div>

                <div className="col-sm">


                  <Form.Item
                    name="AbbrevAr"
                    label="اختصار"
                    rules={[
                      {
                        required: true,
                        message: 'Abbreviation (Arabic) is required',
                      },
                    ]}
                    style={{ direction: 'rtl' }}
                  >
                    <Input placeholder="اختصار" name="AbbrevAr"
                      onChange={(e) => setAbbrevAr(e.target.value)}
                      value={AbbrevAr}
                      type="text"

                    />
                  </Form.Item>


                </div>
              </div>
              <div className="row mainrow">
                <div className="col-sm">


                  <Form.Item
                    name="shortCode"
                    label="Short Code"
                    rules={[
                      {
                        required: true,
                        message: "short Code is required"
                      },
                    ]}
                  >
                    <Input
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(e) =>
                        setState({ ...state1, shortCode: e.target.value })
                      }
                      value={state1.shortCode}
                      onPaste={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      placeholder="Short Code"
                      maxLength="5"
                    />
                  </Form.Item>

                </div>
              </div>

              <div className="ButtonSection" style={{ justifyContent: "end" }}>


                <button
                  type="submit"
                  className="SubmitButton"
                  disabled={isLoading}
                >
                                      Save

                </button>
              </div>

            </Form>
          </div>}
        </div>
      </div>
    </div>
  );
};

export default Gender;
