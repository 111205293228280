import React, { useEffect, Fragment, useState } from "react";
import { fetchHorseKind, STATUSES } from "../../redux/getReducer/getHorseKind";
import { useDispatch, useSelector } from "react-redux";
import { MdDelete } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import ScrollContainer from "react-indiana-drag-scroll";
import Lottie from "lottie-react";
import HorseAnimation from "../../assets/horselottie.json";
import axios from "axios";
import { BiEdit } from "react-icons/bi";
import { BsEyeFill } from "react-icons/bs";
import HorseKindPopup from "../../Components/Popup/HorseKindPopup";
import { Modal } from "react-bootstrap";
import Pagination from "./Pagination";
import { BiFilter } from 'react-icons/bi';

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Notfound from "../../Notfound"

import HorseKindCSV from '../../Components/CSVUploadPopup/HorseKindPopup'
import { MdAutoDelete } from 'react-icons/md';
import { fetchVerifyAndDeleteHorseKind } from '../../redux/VerifyDeleteReducer/gethorsekindverifydelete';
const HorseKind = () => {

  //--------------------------state----------------------//

  const [ShowCalender, setShowCalender] = useState(false)

  const [SearchCode, setSearchCode] = useState('');
  const [SearchTitle, setSearchTitle] = useState('');
  const [TotalCount, setTotalCount] = useState()
  const [TotalPages, setTotalPages] = useState('');
  const [loading, setloading] = useState(false)
  const { userInfo } = useSelector((state) => state.user);

  //for Modal
  const [show, setShow] = useState(false);
  const [modaldata, setmodaldata] = useState();
  const handleClose = () => setShow(false);
  const handleShow = async (data) => {
    setmodaldata(data);
    await setShow(true);
  };
  //--------------------------modal----------------------//

  const [showCSV, setShowCSV] = useState(false);
  const [modaldataCSV, setmodaldataCSV] = useState();
  const handleCloseCSV = () => setShowCSV(false);
  const handleShowCSV = async (data) => {
    setmodaldataCSV(data);
    await setShowCSV(true);
  };
  const dispatch = useDispatch();
  const history = useNavigate();
  const { data: HorseKind, status } = useSelector((state) => state.HorseKind);
  const { data: VerifyAndDeleteHorseKind } = useSelector((state) => state.VerifyAndDeleteHorseKind);

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(8)


  const paginate = pageNumber => setCurrentPage(pageNumber);


  //--------------------------api----------------------//
  const Data1 = async () => {
    const res = await axios.get(`${window.env.API_URL}/HorseKindget?AbbrevEn=${SearchCode}&NameEn=${SearchTitle}`);
    const totalcount = res.data.totalcount;
    setTotalCount(totalcount);
    const TotalPagesData = res.data.totalPages;
    setTotalPages(TotalPagesData)
  };
  //--------------------------search----------------------//
  const GetSearch = async () => {
    dispatch(fetchHorseKind({ SearchTitle, SearchCode, currentPage }));

  };


  useEffect(() => {
    dispatch(fetchHorseKind({ SearchTitle, SearchCode, currentPage }));
    Data1()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, currentPage]);

  //--------------------------delete----------------------//
  const handleRemove = async (Id) => {
    try {
      swal({
        title: "Are you sure?",
        text: "do you want to delete this data ?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })

        .then(async (willDelete) => {


          if (willDelete) {
            await axios.delete(
              `${window.env.API_URL}/softdeleteHorseKind/${Id}`
            );
            swal("Data has been Deleted successfully ", {
              icon: "success",

            }
            )
            dispatch(fetchHorseKind({ SearchTitle, SearchCode }));

          } else {
            swal("Your data is safe!");
          }
        });

    } catch (error) {

      const err = error.response.data.message;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
    }



  }

  const handleDeleteVerify = async (id) => {
    try {
      swal({
        title: "Are you sure?",
        text: "do you want to delete this data ?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          setloading(true)
          const res = await axios.delete(`${window.env.API_URL}/DeleteHorseKindModelAlltoAll/${id}`);
          const msg = res.data.message ?  res.data.message : "deleted";
          swal(msg, {
            icon: "",
          });
      let data1 =  JSON.stringify(res.data.data)
      console.log()
      const blob = new Blob([data1]);
      if(res?.data?.flag){
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.download = "report1.json";
        link.href = url;
        link.click();
        setloading(false)
                    dispatch(fetchHorseKind({ SearchTitle, SearchCode }));


      }     
      setloading(false)
                  dispatch(fetchHorseKind({ SearchTitle, SearchCode }));


        } else {
          swal("Your data is safe!");
        }
      });
    } catch (error) {
      const err = error.success;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
      setloading(false)

    }

    // try {
    //   const res =  await axios.delete(`${window.env.API_URL}/VerifyAndDeleteColor/${id}`);
    //   console.log(res,'121 sa')
    //   await swal(res.data.data.ColorCodeData?.length !== 0 ? "Can't Delete Data beceause it is used in the table that are showing in report" : "Deleted", {
    //     icon: "",
    //   });
    //   let data1 =  JSON.stringify(res.data.data)
    //   const blob = new Blob([data1]);
    //   const url = URL.createObjectURL(blob);
    //   const link = document.createElement("a");
    //   link.download = "report1.json";
    //   link.href = url;
    //   link.click();
    //   dispatch(fetchcolorList({ SearchTitle, SearchCode, currentPage }));
    // } catch (error) {
    //   const err = error.response.data.message;
    //   swal({
    //     title: "Error!",
    //     text: err,
    //     icon: "error",
    //     button: "OK",
    //   });
    // }
  }

  if (status === STATUSES.ERROR) {
    return (
      <h2
        style={{
          margin: "100px",
        }}
      >
        Something went wrong!
      </h2>
    );
  }

  return (
    <Fragment>
      <div className="page">
        <div className="rightsidedata">
          <div
            style={{
              marginTop: "30px",
            }}
          >
            <div className="Header ">
              <h4>Horse Kind Listings</h4>

              <div>
                <h6
                  style={{
                    alignItems: "center",
                    marginRight: "100px",
                    color: "rgba(0, 0, 0, 0.6)",
                  }}
                >

                </h6>

                <Link to="/horsekindform">
                  <button>Add Horse Kind</button>
                </Link>
                <OverlayTrigger
                  overlay={<Tooltip id={`tooltip-top`}>Filter</Tooltip>}
                >
                  <span
                    className="addmore"
                  >
                    <BiFilter
                      className="calendericon"
                      onClick={() => setShowCalender(!ShowCalender)}
                    />
                  </span>
                </OverlayTrigger>
                <p onClick={() => handleShowCSV()} className="importcsv">Import JSON</p>

              </div>
            </div>
            <div>

              {
                ShowCalender ?
                  <span className="transitionclass">
                    <div className="userfilter">

                      <div className="filtertextform forflex">

                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => setSearchTitle(e.target.value)}
                          placeholder="Enter Name"
                        />
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => setSearchCode(e.target.value)}
                          placeholder="Enter Abbreviation"
                        />

                      </div>

                    </div>
                    <button className="filterbtn" onClick={GetSearch}>
                      Apply Filter
                    </button>
                  </span> : <></>
              }
            </div>
            <>
              <div className="div_maintb">
                <ScrollContainer>
                  <table>
                    <thead>
                      <tr>
                        <th>Action</th>

                        <th>Name</th>
                        <th>Name Arabic </th>
                        <th>Abreviation</th>
                        <th>Abreviation Arabic </th>

                      </tr>
                    </thead>
                    <tbody>
                      {
                       loading ?  <Lottie
                       animationData={HorseAnimation}
                       loop={true}
                       className="TableLottie"
                     /> : !HorseKind.length ? <Notfound /> :

                          status === "loading" ?

                            <Lottie animationData={HorseAnimation} loop={true} className="TableLottie" />
                            :
                            HorseKind.map((item, index) => {
                              return (
                                <>
                                  <tr className="tr_table_class">
                                    <td className="table_delete_btn1">
                                      <BiEdit
                                        onClick={() =>
                                          history("/edithorsekind", {
                                            state: {
                                              horsekindid: item,
                                            },
                                          })
                                        }
                                      />

                                    {/* <MdDelete
                                    style={{
                                      fontSize: "22px",
                                    }}
                                    onClick={() => handleRemove(item._id)}
                                  /> */}
 <MdAutoDelete
                                    onClick={() => handleDeleteVerify(item._id)}
                                  />
                                {/* {userInfo && userInfo.data.role === "admin" ? (
                                  <MdAutoDelete
                                    onClick={() => handleDeleteVerify(item._id)}
                                  />
                                ) : (
                                  <></>
                                )} */}
                                      <BsEyeFill onClick={() => handleShow(item)} />
                                    </td>
                                    <td>{item.NameEn}</td>
                                    <td>{item.NameAr}</td>
                                    <td>{item.AbbrevEn}</td>
                                    <td>{item.AbbrevAr}</td>


                                  </tr>
                                </>
                              );
                            })}
                    </tbody>
                  </table>
                </ScrollContainer>
              </div>
            </>
          </div>
          <span className="plusIconStyle"></span>
          <Pagination
            postsPerPage={postsPerPage}
            totalPosts={TotalCount}
            paginate={paginate}
            currentPage={currentPage}
            TotalPages={TotalPages}

          />
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2 style={{ fontFamily: "inter" }}>Horse Kind </h2>
        </Modal.Header>
        <Modal.Body>
          <HorseKindPopup data={modaldata} />
        </Modal.Body>
        <Modal.Footer>
          <button onClick={handleClose} className="modalClosebtn">
            Close
          </button>
        </Modal.Footer>
      </Modal>



      <Modal
        show={showCSV}
        onHide={handleCloseCSV}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2 style={{ fontFamily: "inter" }}>Horse Kind JSON</h2>
        </Modal.Header>
        <Modal.Body>
          <HorseKindCSV data={modaldataCSV} />
        </Modal.Body>
        <Modal.Footer>

        </Modal.Footer>
      </Modal>


    </Fragment>
  );
};

export default HorseKind;
