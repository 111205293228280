import React, { useState } from "react";
import axios from "axios";
import swal from "sweetalert";


const OwnerUploadPopup = () => {
  const [FileContent1, setFileContent] = useState('');
  const [isLoading, setisLoading] = useState(false);
  const [state1, setState1] = useState({
    fileName: "",
    fileContent: [],
  });
  const onSelectFile = (e) => {
    setFileContent(e.target.files[0]);
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = () => {
      setState1({
        fileName: file.name,
        fileContent: reader.result,
      });
      reader.onerror = () => {

      };
    };
  };

  const UploadCSV = async (event) => {
    event.preventDefault();
    if (FileContent1 === '') {
      swal({
        title: "Error!",
        text: "File Not Found",
        icon: "error",
        button: "OK",
      });
    }
    else {
      try {
        const formData = new FormData();
        formData.append("file", FileContent1);
        await axios
          .post(`${window.env.API_URL}/TrainerMassUpload`, formData)
          .then((response) => {
            const fileData = JSON.stringify(response.data.message);
            if (!response.data.message) {
              swal({
                title: "Success!",
                text: "No Error During File Generation",
                icon: "success",
                button: "OK",
              });
            } else {
              const blob = new Blob([fileData]);
              const url = URL.createObjectURL(blob);
              const link = document.createElement("a");
              link.download = "report1.json";
              link.href = url;
              link.click();
            }
          });
      }
      catch (error) {
        setisLoading(false);

      }
    }

    setisLoading(false);
  };
  return (
    <>
      <div className="form">
        <div>
          <input type="file" onChange={onSelectFile} />
          <button
            onClick={UploadCSV}
            className="modalClosebtn UploadCSV1"
            disabled={isLoading}
          >
            Upload JSON
          </button>
        </div>
      </div>
    </>
  );
};
export default OwnerUploadPopup;