import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});

const getHorseListSlice = createSlice({
    name: 'HorseList',
    initialState: {
        data: [],
        status: STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchHorseList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(fetchHorseList.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.IDLE
            })
            .addCase(fetchHorseList.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
    }
});

export const { setHorseList, setStatus } = getHorseListSlice.actions;
export default getHorseListSlice.reducer;

export const fetchHorseList = createAsyncThunk('/HorseListget/fetch', async ({ Value1 }) => {
    const res = await axios.get(`${window.env.API_URL}/HorseDropDown?NameEn=${Value1 === undefined ? '' : Value1}&limit=20`);
    const HorseListData = res.data;
    return HorseListData.data;
})
