import React, { useState, useEffect } from "react";
import "../../Components/CSS/forms.css";
import { useNavigate, useLocation } from "react-router-dom";
import swal from "sweetalert";
import axios from "axios";
import { ImCross } from "react-icons/im";
import { Form, Input } from 'antd';

const EditSlider = () => {
  const history = useNavigate();
  const { state } = useLocation();
  const [form] = Form.useForm();
  const { sliderid } = state;
  const [isLoading, setisLoading] = useState(false);
  const [image, setImage] = useState();
  const [preview, setPreview] = useState();
  const [isLoading1, setisLoading1] = useState(false);

  const [state1, setState] = useState({
    TitleEn: "",
    TitleAr: "",
    Url: "",
    image: image,
  });
  //---------------------------get data from previous state---------------------------//
  useEffect(() => {
    if (sliderid) {
      setState({
        TitleEn: sliderid.TitleEn,
        TitleAr: sliderid.TitleAr,
        Url: sliderid.Url,
        image: sliderid.image,
      });
    } else {
      alert("No Data");
    }
  }, [sliderid]);
  //---------------------------Image Preview---------------------------//
  useEffect(() => {
    if (image === undefined) {
      setPreview(sliderid.image);
      return;
    }
    const objectUrl = URL.createObjectURL(image);
    setPreview(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [image, sliderid.image]);
  const fileSelected = (event) => {
    const image = event.target.files[0];
    setImage(image, image);
  };
  const handlePreview = () => {
    setImage();
    setPreview();
    setisLoading1(true)
    document.getElementById("file").value = "";
  };
  //---------------------------submit---------------------------//
  const submit = async () => {

    try {
      setisLoading(true);
      const formData = new FormData();
      formData.append("TitleEn", state1.TitleEn);
      formData.append("TitleAr", state1.TitleAr + " ");
      formData.append("Url", state1.Url);
      formData.append("image", image);
      if (isLoading1 === true && image === undefined) {
        formData.append("deleteexisitingimage", isLoading1)
      }
      await axios.put(
        `${window.env.API_URL}/updateSlider/${sliderid._id}`,
        formData
      );
      history("/slider");
      swal({
        title: "Success!",
        text: "Data has been Updated successfully ",
        icon: "success",
        button: "OK",
      });
    } catch (error) {
      const err = error.response.data.message;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
    }
    setisLoading(false);
  };

  form.setFieldsValue({
    TitleEn: state1.TitleEn,
    NameAr: state1.TitleAr,
    Url: state1.Url

  });
  return (
    <>
      <div className="page">
        <div className="rightsidedata">
          <div
            style={{
              marginTop: "30px",
            }}
          >
            <div className="Headers">Edit Slider</div>
            <div className="form">
              <Form onFinish={submit} form={form}>
                <div className="row mainrow">
                  <div className="col-sm">





                    <Form.Item
                      name="TitleEn"
                      label="Title"
                      rules={[
                        {
                          required: true,
                          message: 'Title (English) is required',
                        },
                      ]}
                    >
                      <Input placeholder="Title" onChange={(e) =>
                        setState({ ...state1, TitleEn: e.target.value })
                      }
                        value={state1.TitleEn} name="NameEn" />
                    </Form.Item>






                    <span className="spanForm spacer"> |</span>
                  </div>

                  <div className="col-sm">



                    <Form.Item
                      name="NameAr"
                      label="عنوان"
                      rules={[
                        {
                          required: true,
                          message: 'Title (Arabic) is required',
                        },
                      ]}
                      style={{ direction: 'rtl' }}
                    >
                      <Input placeholder="عنوان" onChange={(e) =>
                        setState({ ...state1, TitleAr: e.target.value })
                      }
                        name="Name"
                        value={state1.TitleAr} />
                    </Form.Item>











                  </div>
                </div>
                <div className="row mainrow">
                  <div className="col-sm">






                    <Form.Item
                      name="Url"
                      label="Url"
                      rules={[
                        {
                          required: true,
                          message: 'Url is required',
                        },
                      ]}
                    >
                      <Input placeholder="Url" onChange={(e) =>
                        setState({ ...state1, Url: e.target.value })
                      }

                        value={state1.Url} name="Url" />
                    </Form.Item>









                  </div>
                </div>

                <div className="ButtonSection">
                  <div>
                    <label className="Multipleownerlabel">
                      Select Slider image
                    </label>
                    <input
                      type="file"
                      onChange={fileSelected}
                      className="formInput"
                      id="file"
                    />
                    {preview && (
                      <>
                        <ImCross
                          onClick={handlePreview}
                          className="crossIcon"
                        />
                        <img src={preview} className="PreviewImage" alt="" />
                      </>
                    )}
                  </div>
                  <div>
                    <button
                      type="submit"
                      className="SubmitButton"
                      disabled={isLoading}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditSlider;
