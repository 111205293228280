
import React, { useEffect, Fragment, useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import ImageResize from 'quill-image-resize-module-react';
import { useDispatch, useSelector } from "react-redux";
import { fetchSingleEmailTemplate, STATUSES } from "../redux/getReducer/getSingleEmailList";
import { fetchEmailImage } from "../redux/getReducer/getEmailImagesList";
import axios from "axios";
import swal from "sweetalert";
import { useLocation,useNavigate } from "react-router-dom";
Quill.register('modules/imageResize', ImageResize);


const modules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }, { font: [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' }
    ],
    ['link', 'image', 'video'],
    ['clean']
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false
  },
  imageResize: {
    parchment: Quill.import('parchment'),
    modules: ['Resize', 'DisplaySize']
  }
};

const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video'
];
const Email = () => {

  const { state } = useLocation();
  const { id } = state;
  const history = useNavigate();

  const [StateData, setStateData] = useState()
  const [SubjectData, setSubjectData] = useState()
  const [TemplateData, setTemplateData] = useState()

  const dispatch = useDispatch();
  const { data: SingleEmailTemplate } = useSelector((state) => state.SingleEmailTemplate);
  const { data: EmailImage, status } = useSelector((state) => state.EmailImage);

  
  useEffect(() => {
    dispatch(fetchEmailImage());
  }, [dispatch]);
  useEffect(() => {
    dispatch(fetchSingleEmailTemplate({id}));
  }, [dispatch]);

  useEffect(() =>{
   if(SingleEmailTemplate){
    setStateData(SingleEmailTemplate.Html)
    setSubjectData(SingleEmailTemplate.TemplateName)
    setTemplateData(SingleEmailTemplate.EmailTitle)
   }
   else{

   }
  },[SingleEmailTemplate])

  const handleSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append("TemplateName", TemplateData)
      formData.append("EmailTitle", SubjectData);
      formData.append("Html",StateData);

      const response = await axios.put(
        `${window.env.API_URL}/updateEmailTemplate/${id}`,
        formData
      );
      const Res = response;
      swal({
        title: "Updated!",
        text: "Email Updated Successfully ",
        icon: "success",
        button: "OK",
      });
      history("/email")


    } catch (error) {
      
    }
  }

  const handleBack = () => {
    history('/email')
  }

  return (
    <div className='parentTemplate'>
      <div className="Headers">{TemplateData} </div>
      <div className="emailsendflex">
      <div className="emailcontent">
       <div className='templateInputs'>
        <input value={TemplateData} readOnly type="text" placeholder="Template Name"/>
       
      </div>

      <div className='templateInputs'>
        <input value={SubjectData} onChange={(e) => setSubjectData(e.target.value)} type="text" placeholder="Email Title"/>
       
      </div>
      <div className="row">
        <div className="col-sm">
          <ReactQuill
            theme="snow"
            modules={modules}
            formats={formats}
            onChange={setStateData}
            value={StateData}
          />

        </div>

      </div>
      <div className="ButtonSection foremail">
        <button Name="submit" className="SubmitButton" onClick={handleBack}>
          Back
        </button>

        <button Name="submit" className="SubmitButton" onClick={handleSubmit}>
          Submit
        </button>
        
      </div>
       </div>
      <div className='emailimages'>
        {
          EmailImage.map((item) => {
            return(
              <div className="inneremailimages">
              <img src={item.image} alt="" />
              <p>{item.Title}</p>
              </div>
            )
          })
        }
      </div>
      
      </div>
      
    </div>
  )
}

export default Email
