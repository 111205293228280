import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";

import TextInputValidation from "../../utils/TextInputValidation";
import { fetchsexshortcode } from "../../redux/getShortCode/getsexshortcode";
import { useDispatch } from "react-redux";
import { Form, Input } from 'antd';

const GroupName = () => {
    //for error
    const dispatch = useDispatch();





    const [NameEn, setNameEn] = useState("");
    const [NameAr, setNameAr] = useState("");


    const [isLoading, setisLoading] = useState(false);

    const history = useNavigate();
    const { pathname } = useLocation();





    useEffect(() => {
        dispatch(fetchsexshortcode());
    }, [dispatch]);
    //----------------------------submit -------------------------//
    const submit = async () => {

        setisLoading(true);
        try {
            const formData = new FormData();
            formData.append("NameEn", NameEn);
            formData.append("NameAr", NameAr);
            await axios.post(`${window.env.API_URL}/uploadPointGroupName`, formData);
            swal({
                title: "Success!",
                text: "Data has been added Successfully ",
                icon: "success",
                button: "OK",
            });
            if (pathname === "/groupname") {
                history("/groupnametable");
            }
            setisLoading(false);
        } catch (error) {
            const err = error.response.data.message[0];
            const err1 = error.response.data.message[1];
            const err2 = error.response.data.message[2];
            setisLoading(false);

            swal({
                title: "Error!",
                text: err,
                err1,
                err2,
                icon: "error",
                button: "OK",
            });
        }
    };
    //----------------------------input validation -------------------------//

    const data1 = JSON.stringify(
        TextInputValidation("en", NameEn, "Group Name English")
    );

    const obj = JSON.parse(data1);
    const data2 = JSON.stringify(
        TextInputValidation("ar", NameAr, "Group Name Arabic")
    );
    const objAr = JSON.parse(data2);


    return (
        <div className="page">
            <div className="rightsidedata">
                <div
                    style={{
                        marginTop: "30px",
                    }}
                >
                    <div className="Headers">Create Group Name</div>
                    <div className="form">
                        <Form onFinish={submit}

                        >
                            <div className="row mainrow">
                                <div className="col-sm">


                                    <Form.Item
                                        name="NameEn"
                                        label="Name"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Name (English) is required',
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Name" onChange={(e) => setNameEn(e.target.value)}
                                            value={NameEn} name="NameEn" />
                                    </Form.Item>



                                    <span className="spanForm spacer"> |</span>





                                </div>

                                <div className="col-sm">
                                    <Form.Item
                                        name="NameAr"
                                        label="اسم"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Name (Arabic) is required',
                                            },
                                        ]}
                                        style={{ direction: 'rtl' }}
                                    >
                                        <Input placeholder="اسم" onChange={(e) => setNameAr(e.target.value)}
                                            name="Name"
                                            value={NameAr} />
                                    </Form.Item>

                                </div>
                            </div>

                            <div className="ButtonSection" style={{ justifyContent: "end" }}>
                                <button
                                    type="submit"
                                    className="SubmitButton"
                                    disabled={isLoading}
                                >
                                    Add Group Name
                                </button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GroupName;
