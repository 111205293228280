import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});

const getColorSlice = createSlice({
    name: 'HorseKindData',
    initialState: {
        data: [],
        status: STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchHorseKindData.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(fetchHorseKindData.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.IDLE
            })
            .addCase(fetchHorseKindData.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
    }
});

export const { setColor, setStatus } = getColorSlice.actions;
export default getColorSlice.reducer;

export const fetchHorseKindData = createAsyncThunk('/HorseKindData/fetch', async ({ Value1 ,KindHorseid }) => {
    const res = await axios.get(`${window.env.API_URL}/SearchHorsesAccordingToHorseKind/${KindHorseid}?NameEn=${Value1 === undefined ? '' : Value1}&limit=50`);
    const ColorData = res.data;
    return ColorData.data;
})