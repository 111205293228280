import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { Form, Input } from 'antd';

import { ImCross } from 'react-icons/im';
import Loader from "../../Components/Common/Loader";

const SliderForm = () => {
  //for error

  const [isLoading, setisLoading] = useState(false);

  const history = useNavigate();
  const [TitleEn, setTitleEn] = useState("");
  const [TitleAr, setTitleAr] = useState("");
  const [Url, setUrl] = useState();
  const [image, setImage] = useState();
  const [preview, setPreview] = useState();



  //----------------------------submit -------------------------//
  const submit = async () => {

    setisLoading(true)
    try {
      const formData = new FormData();
      formData.append("image", image);
      formData.append("TitleEn", TitleEn);
      formData.append("TitleAr", TitleAr + ' ');
      formData.append("Url", Url);
      await axios.post(
        `${window.env.API_URL}/uploadSlider`,
        formData
      );
      history("/slider");
      swal({
        title: "Success!",
        text: "Data has been added successfully ",
        icon: "success",
        button: "OK",
      });
      setisLoading(false)
    } catch (error) {
      const err = error.response.data.message;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
      setisLoading(false)
    }
  };
  //----------------------------image preview -------------------------//
  useEffect(() => {
    if (!image) {
      setPreview(undefined);
      return;
    }
    if (image.name.match(/\.(gif)$/)) {
      setImage("");
      setPreview(undefined);
      document.getElementById("file").value = "";
      swal({
        title: "Error!",
        text: "Invalid Format ",
        icon: "error",
        button: "OK",
      });
    }
    const objectUrl = URL.createObjectURL(image);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [image]);

  const onSelectFile = (e) => {
    setImage(e.target.files[0]);
  };
  //----------------------------remove preview -------------------------//
  const handlePreview = () => {
    setImage()
    document.getElementById("file").value = ""
  };

  return (
    <>
      <div className="page">
        <div className="rightsidedata">
          <div
            style={{
              marginTop: "30px",
            }}
          >
            <div className="Headers">Add Slider</div>
            {isLoading ? <Loader /> : <div className="form">
              <Form onFinish={submit}>
                <div className="row mainrow">
                  <div className="col-sm">





                    <Form.Item
                      name="NameEn"
                      label="Title"
                      rules={[
                        {
                          required: true,
                          message: 'Title (English) is required',
                        },
                      ]}
                    >
                      <Input placeholder="Title" onChange={(e) => setTitleEn(e.target.value)}
                        value={TitleEn} name="NameEn" />
                    </Form.Item>








                    <span className="spanForm spacer" > |</span>

                  </div>

                  <div className="col-sm">



                    <Form.Item
                      name="TitleAr"
                      label="عنوان"
                      rules={[
                        {
                          required: true,
                          message: 'Title (Arabic) is required',
                        },
                      ]}
                      style={{ direction: 'rtl' }}
                    >
                      <Input placeholder="عنوان" onChange={(e) => setTitleAr(e.target.value)}
                        name="TitleAr"
                        value={TitleAr} />
                    </Form.Item>









                  </div>
                </div>

                <div className="row mainrow">
                  <div className="col-sm">





                    <Form.Item
                      name="Url"
                      label="Url"
                      rules={[
                        {
                          required: true,
                          message: 'Url is required',
                        },
                      ]}
                    >
                      <Input placeholder="Url" onChange={(e) => setUrl(e.target.value)}
                        name="Name"
                        value={Url} />
                    </Form.Item>






                  </div>
                </div>


                <div className="ButtonSection">
                  <div>
                    <label className="Multipleownerlabel">
                      Select Slider image
                    </label>
                    <input
                      type="file"
                      onChange={onSelectFile}
                      className="formInput"
                      id="file"
                    />
                    {image && (
                      <>
                        <ImCross onClick={handlePreview} className="crossIcon" />
                        <img src={preview} className="PreviewImage" alt="" />
                      </>
                    )}
                  </div>
                  <div>
                    <button
                      type="submit"

                      className="SubmitButton"
                      disabled={isLoading}
                    >
                                          Save

                    </button>
                  </div>
                </div>

              </Form>
            </div>}
          </div>
        </div>
      </div>
    </>
  );
};

export default SliderForm;
