import React, { useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import Lottie from "lottie-react";
import HorseAnimation from "../../assets/horselottie.json";

const HorseUploadPopup = () => {
  const [FileContent1, setFileContent] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [load, setload] = useState(false)
  const [state1, setState1] = useState({
    fileName: "",
    fileContent: [],
  });
  const onSelectFile = (e) => {
    setFileContent(e.target.files[0]);
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = () => {
      setState1({
        fileName: file.name,
        fileContent: reader.result,
      });
      reader.onerror = () => {

      };
    };
  };
  const UploadCSV = async (event) => {
    event.preventDefault();
    try {
      const formData = new FormData();
      formData.append("file", FileContent1);
      setload(true)
      await axios
        .post(`${window.env.API_URL}/HorseMassUploadV2`, formData)
        .then((response) => {
          const fileData = JSON.stringify(response.data);
          if (!response.data.message) {
            swal({
              title: "Success!",
              text: "No Error During File Generation",
              icon: "success",
              button: "OK",
            });

            const filename = new Date();
            const blob = new Blob([JSON.stringify(response.data)]);
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.download = `Success Log ${filename}.json`;
            link.href = url;
            link.click();
            setload(false)
            setisLoading(false);

          } else {

            console.log(fileData,"fileData fileData")
            const filename = new Date();
            const blob = new Blob([fileData]);
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.download = `Failure Log ${filename}.json`;
            link.href = url;
            link.click();
          }

          setload(false)

          setisLoading(false);

        });
        setload(false)

    } catch (error) {
      setisLoading(false);
      const err1 = error.response.data.message
      swal({
        title: "Error!",
        text: err1,
        icon: "error",
        button: "OK",
      });
      setload(false)

    }
    setisLoading(false);
  };
  return (
    <>
      {load ? <Lottie
        animationData={HorseAnimation}
        loop={true}
        className="popupLoader"
      />
        :

        <div className="form">
          <div>
            <input type="file" onChange={onSelectFile} />

            <button
              onClick={UploadCSV}
              className="modalClosebtn UploadCSV1"
              disabled={isLoading}

            >
              Upload JSON
            </button>
          </div>
        </div>

      }
    </>
  );
};
export default HorseUploadPopup;