import React from 'react'
import notfound from "./assets/notfound.png"


const Notfound = () => {
    return (
        <div className="notfound"><img src={notfound} alt="" /></div>
    )
}

export default Notfound