import axios from "axios";
import { createAsyncThunk,createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
    IDLE : 'idle',
    ERROR:'error',
    LOADING: 'loading',
});

const getVerifyAndDeleteEquipmentSlice = createSlice({
    name: 'VerifyAndDeleteEquipment',
    initialState: {
        data:[],
        status : STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
        .addCase(fetchVerifyAndDeleteEquipment.pending, (state, action) => {
            state.status = STATUSES.LOADING;
        })
        .addCase(fetchVerifyAndDeleteEquipment.fulfilled, (state, action) => {
            state.data = action.payload;
            state.status = STATUSES.IDLE
        }) 
        .addCase(fetchVerifyAndDeleteEquipment.rejected , (state,action) => {
            state.status = STATUSES.ERROR;
        })
    }
});

export const {setVerifyAndDeleteEquipment , setStatus} = getVerifyAndDeleteEquipmentSlice.actions;
export default getVerifyAndDeleteEquipmentSlice.reducer;

export const fetchVerifyAndDeleteEquipment = createAsyncThunk('/getVerifyAndDeleteEquipment/fetch', async({id}) => {
    const res = await axios.delete(`${window.env.API_URL}/VerifyAndDeleteEquipment/${id}`);
    const VerifyAndDeleteEquipmentData = res.data;
    return VerifyAndDeleteEquipmentData.data;
})