import React, { useEffect, Fragment, useState } from "react";
import { fetchcolorList, STATUSES } from "../../redux/getReducer/getColorList";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import ScrollContainer from "react-indiana-drag-scroll";
import Lottie from "lottie-react";
import HorseAnimation from "../../assets/horselottie.json";
import axios from "axios";
import { BiEdit } from "react-icons/bi";
import { Modal } from "react-bootstrap";
import ColorPopup from "../../Components/Popup/ColorPopup";
import { BsEyeFill } from "react-icons/bs";
import Pagination from "./Pagination";
import { BiFilter } from "react-icons/bi";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import CSVColor from "../../Components/CSVUploadPopup/ColorPopup";
import Notfound from "../../Notfound";
import { MdAutoDelete } from 'react-icons/md';
import { fetchVerifyAndDeleteColor } from '../../redux/VerifyDeleteReducer/getcolorverifydelete';
import DeleteBtn from '../../Components/Common/DeleteBtn'
import { getUserDetails } from "../../redux/postReducer/UserPost";
import { MdDelete } from "react-icons/md";

const ColorTable = () => {

  const { userInfo } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  

  const [ShowCalender, setShowCalender] = useState(false);
  const [SearchCode, setSearchCode] = useState("");
  const [SearchTitle, setSearchTitle] = useState("");
  const [loading, setloading] = useState(false)


  const [modaldata, setmodaldata] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = async (data) => {
    setmodaldata(data);
    await setShow(true);
  };

  const [showCSV, setShowCSV] = useState(false);
  const [modaldataCSV, setmodaldataCSV] = useState();
  const handleCloseCSV = () => setShowCSV(false);
  const handleShowCSV = async (data) => {
    setmodaldataCSV(data);
    await setShowCSV(true);
  };

  const history = useNavigate();
  const { data: ColorList, status } = useSelector((state) => state.colorList);
  const { data: VerifyAndDeleteColor } = useSelector((state) => state.VerifyAndDeleteColor);
  const [TotalCount, setTotalCount] = useState(ColorList.totalcount);
  const [TotalPages, setTotalPages] = useState(ColorList.totalPages);
  const [currentPage, setCurrentPage] = useState(1);

  const [postsPerPage] = useState(10);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  let data1 = 1

  const GetSearch = async () => {
    dispatch(fetchcolorList({ SearchTitle, SearchCode, data1 }));
  };

  useEffect(() => {
    dispatch(fetchcolorList({ SearchTitle, SearchCode, currentPage }));

  }, [dispatch, currentPage]);

  useEffect(() => {
    setTotalCount(ColorList.totalcount)
    setTotalPages(ColorList.totalPages)
  },[ColorList])

  const handleRemove = async (Id) => {
    try {
      swal({
        title: "Are you sure?",
        text: "do you want to delete this data ?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          await axios.delete(`${window.env.API_URL}/softdeleteColor/${Id}`);
          swal("Your data has been deleted Successfully!", {
            icon: "success",
          });
          dispatch(fetchcolorList({ SearchTitle, SearchCode, currentPage }));
        } else {
          swal("Your data is safe!");
        }
      });
    } catch (error) {
      const err = error.response.data.message;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
    }
  };

  const handleDeleteVerify = async (id) => {
    try {
      swal({
        title: "Are you sure?",
        text: "do you want to delete this data ?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          setloading(true)
          const res = await axios.delete(`${window.env.API_URL}/DeleteColorAlltoAll/${id}`);
          const msg = res.data.message ?  res.data.message : "deleted";
          swal(msg, {
            icon: "",
          });
      let data1 =  JSON.stringify(res.data.data)
      const blob = new Blob([data1]);
      console.log(res.data.flag)
      if(res?.data?.flag){
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.download = "report1.json";
        link.href = url;
        link.click();
        setloading(false)
        dispatch(fetchcolorList({ SearchTitle, SearchCode, currentPage }));

      }     
      setloading(false)
      dispatch(fetchcolorList({ SearchTitle, SearchCode, currentPage }));

        } else {
          swal("Your data is safe!");
        }
      });
    } catch (error) {
      const err = error.success;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
      setloading(false)

    }

    // try {
    //   const res =  await axios.delete(`${window.env.API_URL}/VerifyAndDeleteColor/${id}`);
    //   console.log(res,'121 sa')
    //   await swal(res.data.data.ColorCodeData?.length !== 0 ? "Can't Delete Data beceause it is used in the table that are showing in report" : "Deleted", {
    //     icon: "",
    //   });
    //   let data1 =  JSON.stringify(res.data.data)
    //   const blob = new Blob([data1]);
    //   const url = URL.createObjectURL(blob);
    //   const link = document.createElement("a");
    //   link.download = "report1.json";
    //   link.href = url;
    //   link.click();
    //   dispatch(fetchcolorList({ SearchTitle, SearchCode, currentPage }));
    // } catch (error) {
    //   const err = error.response.data.message;
    //   swal({
    //     title: "Error!",
    //     text: err,
    //     icon: "error",
    //     button: "OK",
    //   });
    // }
  }


  // const handleDeleteVerify = async (id) => {

  //   try {
  //     swal({
  //       title: "Are you sure?",
  //       text: "do you want to delete this data ?",
  //       icon: "warning",
  //       buttons: true,
  //       dangerMode: true,
  //     }).then(async (willDelete) => {
  //       if (willDelete) {
  //         setloading(true)
  //         const res = await axios.delete(`${window.env.API_URL}/VerifyAndDeleteColor/${id}`);
  //         const msg = res.data.message ?  res.data.message : "deleted";
  //         swal(msg, {
  //           icon: "",
  //         });
  //         let data1 =  JSON.stringify(res.data.data)
  //     const blob = new Blob([data1]);
  //     const url = URL.createObjectURL(blob);
  //     const link = document.createElement("a");
  //     link.download = "report1.json";
  //     link.href = url;
  //     link.click();
  //     setloading(false)
  //         dispatch(fetchcolorList({ SearchTitle, SearchCode, currentPage }));
  //       } else {
  //         swal("Your data is safe!");
  //       }
  //     });
  //   } catch (error) {
  //     const err = error.success;
  //     swal({
  //       title: "Error!",
  //       text: err,
  //       icon: "error",
  //       button: "OK",
  //     });
  //   }
  // }


  if (status === STATUSES.ERROR) {
    return (
      <h2
        style={{
          margin: "100px",
        }}
      >
        Something went wrong!
      </h2>
    );
  }


  console.log(ColorList,"ColorListColorList")

  return (
    <Fragment>
      <div className="page">
        <div className="rightsidedata">
          <div
            style={{
              marginTop: "30px",
            }}
          >
            <div className="Header ">
              <h4>Color Listings</h4>

              <div>


                <Link to="/color">
                  <button>Add Color</button>
                </Link>
                <OverlayTrigger
                  overlay={<Tooltip id={`tooltip-top`}>Filter</Tooltip>}
                >
                  <span className="addmore">
                    <BiFilter
                      className="calendericon"
                      onClick={() => setShowCalender(!ShowCalender)}
                    />
                  </span>
                </OverlayTrigger>

                <p onClick={() => handleShowCSV()} className="importcsv">
                  Import JSON
                </p>

                {/* <CSVLink  data={Color}  separator={";"} filename={"MKS Color.csv"} className='csvclass'>
                        Export CSV
                </CSVLink> */}
              </div>
            </div>
            <div>
              {ShowCalender ? (
                <span className="transitionclass">
                  <div className="userfilter">
                    <div className="filtertextform forflex">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Name"
                        onChange={(e) => setSearchTitle(e.target.value)}
                      />
                      <input
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        className="form-control"
                        placeholder="Enter Short Code"
                        onChange={(e) => setSearchCode(e.target.value)}
                      />
                    </div>
                  </div>
                  <button className="filterbtn" onClick={GetSearch}>
                    Apply Filter
                  </button>
                </span>
              ) : (
                <></>
              )}
            </div>
            <>
              <div className="div_maintb">
                <ScrollContainer>
                  <table>
                    <thead>
                      <tr>
                        <th>Action</th>
                        <th>Name</th>
                        <th>Name Arabic </th>
                        <th>Short Code</th>

                        <th>Abbreviation</th>
                        <th>Abbreviation Arabic </th>
                        <th>Color Code</th>

                      </tr>
                    </thead>
                    {ColorList.data?.length || !loading ? (


                      ColorList && ColorList.data?.map((item, index) => {
                        return (
                          <>
                            <tr className="tr_table_class">
                              <td className="table_delete_btn1">
                                <BiEdit
                                  onClick={() =>
                                    history("/editcolor", {
                                      state: {
                                        colorid: item,
                                      },
                                    })
                                  }
                                />
                         

                                {/* <MdDelete
                                 style={{
                                   fontSize: "22px",
                                 }}
                                 onClick={() => handleRemove(item._id)}
                               />  */}
{/* {
                                 userInfo && userInfo.data.role === "admin" ? <MdAutoDelete
                                 onClick={() => handleDeleteVerify(item._id)}
                               /> : <></>
                                } */}

                                <MdAutoDelete
                                 onClick={() => handleDeleteVerify(item._id)}
                               />
                                 
                                <BsEyeFill
                                  onClick={() => handleShow(item)}
                                />
                              </td>
                              <td>{item.NameEn}</td>
                              <td>{item.NameAr}</td>
                              <td>{item.shortCode} </td>

                              <td>{item.AbbrevEn}</td>
                              <td>{item.AbbrevAr}</td>
                              <td>{item.Color} </td>

                            </tr>
                          </>
                        );
                      })
                    ) : (
                      <tbody>
                        {status === STATUSES.LOADING ? (
                          <Lottie
                            animationData={HorseAnimation}
                            loop={true}
                            className="TableLottie"
                          />
                        ) : (
                          <Notfound />
                        )}
                      </tbody>
                    )}
                  </table>
                </ScrollContainer>
              </div>
            </>
          </div>
          <span className="plusIconStyle"></span>
          <Pagination
            postsPerPage={postsPerPage}
            totalPosts={TotalCount}
            paginate={paginate}
            currentPage={currentPage}
            TotalPages={TotalPages}
          />
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2>Color </h2>
        </Modal.Header>
        <Modal.Body>
          <ColorPopup data={modaldata} />
        </Modal.Body>
        <Modal.Footer>
          <button onClick={handleClose} className="modalClosebtn">
            Close
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showCSV}
        onHide={handleCloseCSV}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2 style={{ fontFamily: "inter" }}>Color JSON</h2>
        </Modal.Header>
        <Modal.Body>
          <CSVColor data={modaldataCSV} />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default ColorTable;
