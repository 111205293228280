import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});

const getHorseListReplaceSlice = createSlice({
    name: 'HorseListReplace',
    initialState: {
        data: [],
        status: STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchHorseListReplace.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(fetchHorseListReplace.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.IDLE
            })
            .addCase(fetchHorseListReplace.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
    }
});

export const { setHorseListReplace, setStatus } = getHorseListReplaceSlice.actions;
export default getHorseListReplaceSlice.reducer;

export const fetchHorseListReplace = createAsyncThunk('/HorseListReplaceget/fetch', async ({id, Value1 }) => {
    const res = await axios.get(`${window.env.API_URL}/HorseDropDownV2/${id}?NameEn=${Value1 === undefined ? '' : Value1}&limit=20`);
    const HorseListReplaceData = res.data;
    return HorseListReplaceData.data;
})
