import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Select from "react-select";
import { fetchVerifyHorseAssociation } from "../../redux/getReducer/getVerifyHorseAssociation";
import { fetchReplaceHorse , STATUSES } from "../../redux/getReducer/getReplaceHorse";
import { fetchHorseListReplace } from "../../redux/getDropDownReducer/getHorseReplaceList";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from 'react';
import { ImCross } from 'react-icons/im';
import { TiTick } from 'react-icons/ti';
import swal from "sweetalert";
import Lottie from "lottie-react";
import HorseAnimation from "../../assets/horselottie.json";


const HorseDeletePopup = (props) => {

  const {data: VerifyHorseAssociation } = useSelector((state) => state.VerifyHorseAssociation)
  const {data: HorseListReplace } = useSelector((state) => state.HorseListReplace)
  const {data: ReplaceHorse ,status} = useSelector((state) => state.ReplaceHorse)
  const dispatch = useDispatch();

  const [HorseDropDown , setHorseDropDown] = useState('')
  const [Value1, SetValue1] = useState();
  const [ReplaceHorse1, SetReplaceHorse1] = useState(ReplaceHorse);

  const id = props.data;
  const idtobereplace = HorseDropDown ? HorseDropDown.id : "";

  console.log(idtobereplace,'idtobereplace 123-')

  useEffect(() => {
    dispatch(fetchVerifyHorseAssociation({id}));
    dispatch(fetchHorseListReplace({id,Value1}));
    SetReplaceHorse1("")
  },[dispatch,id,Value1])

  const handleChangeInput1 = (value) => {
    SetValue1(value);
  };

  const ReplaceFunction = async () => {

    if (!HorseDropDown) {
      swal("Error!", "Please select a horse before replacing.", "error");
      return;
    }
    
    try {
      swal({
        title: "Are you sure?",
        text: "do you want to Replace this data ?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
           await dispatch(fetchReplaceHorse({id,idtobereplace}))
            swal("Your data has been Updated Successfully!", {
            icon: "success",
          });
          setHorseDropDown("")
          SetValue1("")
          SetReplaceHorse1(ReplaceHorse)

        } else {
          swal("Your data is not Replaced!");
        }
      });
    } catch (error) {
      const err = error.response.data.message;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
    }
  };
  let AllHorses = HorseListReplace.map(function (item) {
    return {
      id: item._id,
      value: item.NameEn,
      label: item.NameEn
    }})

  return (
    <div className='HorseDeletePopup'>
     <Modal {...props} size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Horse Association in Data
        </Modal.Title>
      </Modal.Header>
      {
        status == 'idle' ? <Modal.Body className="show-grid">  
        <Container>
        <Row style={{
          justifyContent:'center'
        }}>
        
        {
          VerifyHorseAssociation && VerifyHorseAssociation.NameEn
        }
          </Row>
          <hr/>
          <Row>
            <Col xs={12} md={6}>
              <b>Horse Exist In:</b>
              <ol>
                <li className='horsedataassociation'>Horse Associated with Race  {VerifyHorseAssociation && VerifyHorseAssociation.HorseIDData1 && VerifyHorseAssociation.HorseIDData1.length == 0 ? <ImCross /> : <TiTick />}  </li> 
                <li className='horsedataassociation'>Horse Associated with Competition {VerifyHorseAssociation && VerifyHorseAssociation.CompetitionHorseIDData && VerifyHorseAssociation.CompetitionHorseIDData.length == 0  ? <ImCross /> : <TiTick />}</li>
                <li className='horsedataassociation'>Horse Associated with Result {VerifyHorseAssociation && VerifyHorseAssociation.HorseNo1Data && VerifyHorseAssociation.HorseNo1Data.length == 0  ? <ImCross /> : <TiTick />}</li>
                <li className='horsedataassociation'>Horse Associated with BeatenBy {VerifyHorseAssociation && VerifyHorseAssociation.BeatenByData && VerifyHorseAssociation.BeatenByData.length == 0  ? <ImCross /> : <TiTick />}</li>
                <li className='horsedataassociation'>Horse Associated with BestTurnOur {VerifyHorseAssociation && VerifyHorseAssociation.BestTurnOutData && VerifyHorseAssociation.BestTurnOutData.length == 0  ? <ImCross /> : <TiTick />}</li>
                <li className='horsedataassociation'>Horse Associated with Tracker {VerifyHorseAssociation && VerifyHorseAssociation.TrackHorses && VerifyHorseAssociation.TrackHorses.length == 0  ? <ImCross /> : <TiTick />}</li>
                <li className='horsedataassociation'>Horse Associated with as a Dam {VerifyHorseAssociation && VerifyHorseAssociation.HorseNo2Data && VerifyHorseAssociation.HorseNo2Data.length == 0  ? <ImCross /> : <TiTick />}</li>
                <li className='horsedataassociation'>Horse Associated with as a GSire {VerifyHorseAssociation && VerifyHorseAssociation.HorseSireData && VerifyHorseAssociation.HorseSireData.length == 0  ? <ImCross /> : <TiTick />}</li>
                <li className='horsedataassociation'>Horse Associated with as a Sire {VerifyHorseAssociation && VerifyHorseAssociation.SireIdData && VerifyHorseAssociation.SireIdData.length == 0  ? <ImCross /> : <TiTick />}</li>
              </ol>
            </Col>
            <Col xs={6} md={6}>
            <Select
                              placeholder="Horse"
                              id='selecthorseass'
                              isClearable={true}
                              onChange={setHorseDropDown}
                              options={AllHorses}
                              onInputChange={handleChangeInput1}
                              isSearchable={true}
                              hideSelectedOptions={true}
                              value={HorseDropDown}
                            
                            />

                            <button className='replacebtn' onClick={ReplaceFunction} >Replace</button>

                <div className='afterreplacediv'>
                  <p>Data Updated from :</p>
                  <ol>
                    {ReplaceHorse1 && (ReplaceHorse1.updateonracehorses == 0 ? <></> : <li>updateonracehorses :Replaced</li> )}
                  {ReplaceHorse1 && (ReplaceHorse1.updateonracehorsesasSire == 0 ? <></> : <li>updateonracehorsesasSire :Replaced</li> )}
                  {ReplaceHorse1 && (ReplaceHorse1.updateonvoting == 0 ? <></> : <li>updateonvoting :Replaced</li> )}
                  {ReplaceHorse1 && (ReplaceHorse1.updateonhorseresult == 0 ? <></> : <li>updateonhorseresult : Replaced</li> )}
                  {ReplaceHorse1 && (ReplaceHorse1.updateonhorseresultasSire == 0 ? <></> : <li>updateonhorseresultasSire : Replaced</li> )}
                  {ReplaceHorse1 && (ReplaceHorse1.updateonhorseresultasBeatenBy == 0 ? <></> : <li>updateonhorseresultasBeatenBy : Replaced</li> )}
                  {ReplaceHorse1 && (ReplaceHorse1.updateonhorseresultasBestTurnOut == 0 ? <></> : <li> updateonhorseresultasBestTurnOut :Replaced</li> )}
                  {ReplaceHorse1 && (ReplaceHorse1.updateonhorseTracker == 0 ? <></> : <li> updateonhorseTracker : Replaced</li> )}
                  {ReplaceHorse1 && (ReplaceHorse1.updateonhorseVerdictRank1 == 0 ? <></> : <li>updateonhorseVerdictRank1 : Replaced</li> )}
                  {ReplaceHorse1 && (ReplaceHorse1.updateonhorseVerdictRank2 == 0 ? <></> : <li> updateonhorseVerdictRank2 :Replaced</li> )}
                  {ReplaceHorse1 && (ReplaceHorse1.updateonhorseVerdictRank3 == 0 ? <></> : <li> updateonhorseVerdictRank3 :Replaced</li> )}
                  </ol>
                  </div>            
            </Col>
          </Row>

        </Container>
      </Modal.Body>
      : <Lottie
      animationData={HorseAnimation}
      loop={true}
      className="popupLoader"
    />
      }
      
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
    </div>
  )
}

export default HorseDeletePopup
