import React, { useState, useEffect, useRef } from "react";
import swal from "sweetalert";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Select from "react-select";
import { TiTick } from "react-icons/ti";
import { ImCross } from "react-icons/im";
import { fetchLiveRaceOnly } from "../../../redux/getDropDownReducer/LiveRaceOnly";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { AiOutlineReload } from "react-icons/ai";
import { fetchPointTableSystemDropDown } from "../../../redux/getReducer/getPointByCategory";
import Moment from "react-moment";


const PublishCompetetion = () => {
  const LocalItem = () => {
    const list = localStorage.getItem("compete");
    if (list) {
      return JSON.parse(localStorage.getItem("compete"));
    } else {
      return [];
    }
  };

  const { data: LiveRaceOnly } = useSelector(
    (state) => state.LiveRaceOnly
  );
  const { data: PointTableSystemDropDown } = useSelector((state) => state.PointTableSystemDropDown);

  const { state } = useLocation();
  const { compid } = state;
  let id = compid._id

  const [items, setitems] = useState();
  const [checked, setChecked] = useState([]);
  const [StoreDataRace, setStoreDataRace] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [RaceName, setRaceName] = useState();
  const [Points, setPoints] = useState();
  const [itemsComp, setitemsComp] = useState(LocalItem());

  const dispatch = useDispatch();
  const history = useNavigate();
  const G1 = itemsComp.map((item) => item.RaceId)

  let raceoptions =
    LiveRaceOnly === undefined ? (
      <></>
    ) : (
      LiveRaceOnly.map(function (item) {
        return {
          id: item._id,
          value: item.RaceNameModelData.NameEn,
          isdisabled: G1[0] !== item._id && G1[1] !== item._id && G1[2] !== item._id && G1[3] !== item._id && G1[4] !== item._id && G1[5] !== item._id && G1[6] !== item._id && G1[7] !== item._id && G1[8] !== item._id && G1[9] !== item._id && G1[10] !== item._id ? false : true,

          label: (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >  <Moment format="DD/MM/YYYY">
            {item ===
            null ? (
              <></>
            ) : (
              item
                .Day
            )}
          </Moment>{item.RaceNameModelData.NameEn}( {item.RaceNumber}  )</div>
          ),
        };
      })
    );
  let formRef = useRef();

  const addItem = (e) => {
    e.preventDefault();


    let CompetitionRaces = {
      RaceId: RaceName.id,
      PointTableOfRace: Points.id,
    };
    let RaceMappingData = {
      RaceName: RaceName.value,
      Points: Points.value,
    };

    if (items === 0) {
      return toast("No Data ");
    }
    if (RaceName === '' || Points === '') {
      return toast("Enter Values ");
    }
    else {
      setitemsComp([...itemsComp, CompetitionRaces]);
      setStoreDataRace([...StoreDataRace, RaceMappingData]);
      formRef.current?.reset();
      // setNewVerdictData(RaceName.id);
    }
    setRaceName("");
    setPoints("");
  };

  let AllGroup =
    PointTableSystemDropDown === undefined ? (
      <></>
    ) : (
      PointTableSystemDropDown.map(function (item) {
        return {
          id: item._id,
          value: item.Group_NameDataOfCompetition.NameEn,
          label: item.Group_NameDataOfCompetition.NameEn,
        };
      })
    );

    useEffect(() => {
      dispatch(fetchPointTableSystemDropDown({id}));

    },[id, dispatch])

  useEffect(() => {
    dispatch(fetchLiveRaceOnly());
  }, [dispatch]);


  const fetch = () => {
    dispatch(fetchLiveRaceOnly());
    dispatch(fetchPointTableSystemDropDown({id}));

  };

  const Publish = async (event) => {
    event.preventDefault();
    setisLoading(true);
    try {
      const response = await axios.post(
        `${window.env.API_URL}/AddRacesInCompetitionV2/${compid._id}`,
        { RaceEntry: itemsComp }
        // { CastRaces: CastCountValue, PickRaces: TriCountValue }
      );

      const msgdata = response.data.message;

      setisLoading(false);

      history("/competitionlisting");
      swal({
        title: "Success!",
        text: msgdata,
        icon: "success",
        button: "OK",
      });
      setisLoading(false);
    } catch (error) {
      const err = error.response.data.message;
      setisLoading(false);
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
    }
  };


  const saveItem = (e) => {
    e.preventDefault();
    if (items.length) {
      setitems([...items]);
    } else {
      swal({
        title: "Limit!",
        text: "Limit Exceeded ",
        icon: "error",
        button: "OK",
      });
    }
  };
  const removesingleItem = (id) => {
    const updateItems = StoreDataRace.filter((elem, ind) => {
      return ind !== id;
    });
    const updateItems1 = itemsComp.filter((elem, ind) => {
      return ind !== id;
    });
    setStoreDataRace(updateItems);
    setitemsComp(updateItems1);

  };
  const handleCheck = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      if (checked.length === compid.CategoryCount) {
        return toast("limit exceed");
      }
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
  };

  return (
    <div className="page">
      <div className="rightsidedata">
        <div
          style={{
            marginTop: "30px",
          }}
        >
          <div className="Header ">
            <h4>Select Races for Competition</h4>
            <OverlayTrigger
              overlay={<Tooltip id={`tooltip-top`}>Fetch New</Tooltip>}
            >
              <span className="fetchNewVerdict" onClick={fetch}>
                <AiOutlineReload />
              </span>
            </OverlayTrigger>{" "}
          </div>

          <div className="myselecthorse">
            <div className="myselectioncompetition">
              <span>Race</span>
              <span>Points</span>
              <span>Action</span>
            </div>
          </div>

          {!StoreDataRace ? (
            <></>
          ) : (
            StoreDataRace.map((item, i) => {
              return (
                <div className="racesinCompetition">

                  <div className="inputAddraces">
                    <span>
                      <input
                        type="text"
                        value={item.RaceName}
                        className="racecompmappimg"
                      />
                    </span>
                  </div>
                  <div className="inputAddraces">
                    <span>
                      <input
                        type="text"
                        value={item.Points}
                        className="racecompmappimg"
                      />
                    </span>
                  </div>

                  <span>
                    {" "}
                    <button className="removebtnhorse"
                      onClick={() => removesingleItem(i)}
                    >
                      <ImCross />
                    </button>
                  </span>
                </div>
              );
            })
          )}
          <form ref={formRef} onSubmit={addItem}>
            <div className="racesinCompetition">
              <div className="inputAddraces">
                <Select
                  id="selectNow"
                  placeholder="Race "
                  className="dropdown multidropdown"
                  options={raceoptions}
                  isSearchable={true}
                  hideSelectedOptions={true}
                  name="HorseValue"
                  onChange={setRaceName}
                  value={RaceName}
                  isOptionDisabled={(option) => option.isdisabled}

                />
              </div>
              <div className="inputAddraces">
                <Select
                  id="selectNow"
                  placeholder="Points"
                  className="dropdown multidropdown"
                  options={AllGroup}
                  isSearchable={true}
                  hideSelectedOptions={true}
                  name="HorseValue"
                  onChange={setPoints}
                  value={Points}
                />
              </div>

              <button className="savebtnhorse" onClick={addItem} type="submit">
                <TiTick />
              </button>
            </div>
            <div className="ButtonSection " style={{ justifyContent: "end" }}>
              <button
                Name="submit"
                className="SubmitButton"
                onClick={Publish}
                disabled={isLoading}
              >
                Publish
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PublishCompetetion;
