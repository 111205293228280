import React, { useEffect, useState } from "react";
import { STATUSES } from "../../redux/getReducer/getTrainerSlice";
import { useDispatch, useSelector } from "react-redux";
import { fetchNationalityList } from "../../redux/getDropDownReducer/getNationalityList";
import { MdAutoDelete, MdDelete } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import TrainerPopup from "../../Components/Popup/TrainerPopup";
import ScrollContainer from "react-indiana-drag-scroll";
import Moment from "react-moment";
import swal from "sweetalert";
import Lottie from "lottie-react";
import HorseAnimation from "../../assets/horselottie.json";
import axios from "axios";
import { BiEdit } from "react-icons/bi";
import { BsEyeFill } from "react-icons/bs";
import Pagination from "./Pagination";
import { BiFilter } from "react-icons/bi";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import TrainerCSV from "../../Components/CSVUploadPopup/TrainerPopup";
import Defaultimg from "../../assets/default.jpg";
import Select from "react-select";
import Notfound from "../../Notfound";

const Trainer = () => {
  //------------------------state-----------------------//
  const [ShowCalender, setShowCalender] = useState(false);
  const [SearchData, setSearchData] = useState([]);
  const [SearchNameEn, setSearchNameEn] = useState("");
  const [shortCode, setshortCode] = useState("");

  const [SearchRating, setSearchRating] = useState("");
  const [TotalCount, setTotalCount] = useState();
  const [TotalPages, setTotalPages] = useState("");
  const [SearchNationalityId, setNationalityId] = useState();
  const [loading, setLoading] = useState(false);
  const [value, setvalue] = useState("");

  const [show, setShow] = useState(false);
  const [modaldata, setmodaldata] = useState();
  const handleClose = () => setShow(false);
  const handleShow = async (data) => {
    setmodaldata(data);
    await setShow(true);
  };

  const { userInfo } = useSelector((state) => state.user);

  //------------------------modal-----------------------//
  const [showCSV, setShowCSV] = useState(false);
  const [modaldataCSV, setmodaldataCSV] = useState();
  const handleCloseCSV = () => setShowCSV(false);
  const handleShowCSV = async (data) => {
    setmodaldataCSV(data);
    await setShowCSV(true);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { status } = useSelector((state) => state.trainer);
  const { data: NationalityList } = useSelector(
    (state) => state.NationalityList
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(8);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  //------------------------api-----------------------//

  const Nationality = SearchNationalityId ? SearchNationalityId.id : "";
  const GetSearch = async () => {
    setLoading(true);
    const response = await axios.get(
      `${window.env.API_URL}/SearchTrainer?NameEn=${SearchNameEn}&shortCode=${shortCode}&ShortNameEn=${SearchRating}&NationalityID=${Nationality}&page=${currentPage} `
    );
    setSearchData(response.data.data);
    setLoading(false);
    const totalcount = response.data.totalcount;
    setTotalCount(totalcount);
    const TotalPagesData = response.data.totalPages;
    setTotalPages(TotalPagesData);
    if(TotalPagesData <= 2){
      setCurrentPage(1)
    }
  };
  //------------------------options for dropdown-----------------------//
  let AllNationality =
    NationalityList === undefined ? (
      <></>
    ) : (
      NationalityList.map(function (item) {
        return {
          id: item._id,
          value: item.NameEn,
          label: (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <p>{item.NameEn}</p>
            </div>
          ),
        };
      })
    );

  useEffect(() => {
    GetSearch();
  }, [dispatch, currentPage]);

  useEffect(() => {
    dispatch(fetchNationalityList({value}));
  },[dispatch,value])

  const handleChangeInput = (value) => {
    setvalue(value);
  };

  //------------------------delete-----------------------//
  const handleRemove1 = async (Id) => {
    try {
      swal({
        title: "Are you sure?",
        text: "do you want to delete this data ?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          await axios.delete(`${window.env.API_URL}/softdeletetrainer/${Id}`);
          swal("Your data has been deleted Successfully!", {
            icon: "success",
          });
          GetSearch();
        } else {
          swal("Your data is safe!");
        }
      });
    } catch (error) {
      const err = error.response.data.message;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
    }
  };

  const handleRemove = async (id) => {
    try {
      swal({
        title: "Are you sure?",
        text: "do you want to delete this data ?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          setLoading(true)
          const res = await axios.delete(`${window.env.API_URL}/DeleteTrainerAlltoAll/${id}`);
          const msg = res.data.message ?  res.data.message : "deleted";
          swal(msg, {
            icon: "",
          });
      let data1 =  JSON.stringify(res.data.data)
    const blob = new Blob([data1]);
    if(res?.data?.flag){
      const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.download = "report1.json";
        link.href = url;
        link.click();
        setLoading(false)
            GetSearch({ currentPage });

      }     
      setLoading(false)
          GetSearch({ currentPage });

        } else {
          swal("Your data is safe!");
        }
      });
    } catch (error) {
      const err = error.success;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
      setLoading(false)

    }
  }


  if (status === STATUSES.ERROR) {
    return (
      <h2
        style={{
          margin: "100px",
        }}
      >
        Something went wrong!
      </h2>
    );
  }

  return (
    <>
      <div className="page">
        <div className="rightsidedata">
          <div
            style={{
              marginTop: "30px",
            }}
          >
            {" "}
            <div className="Header ">
              <h4>Trainer Listings</h4>

              <div>
                <Link to="/trainerform">
                  <button>Add Trainer</button>
                </Link>
                <OverlayTrigger
                  overlay={<Tooltip id={`tooltip-top`}>Filter</Tooltip>}
                >
                  <span className="addmore">
                    <BiFilter
                      className="calendericon"
                      onClick={() => setShowCalender(!ShowCalender)}
                    />
                  </span>
                </OverlayTrigger>
                <p onClick={() => handleShowCSV()} className="importcsv">
                  Import JSON
                </p>
              </div>
            </div>
            <div>
              {ShowCalender ? (
                <>
                  <div className="userfilter">
                    <div className="filtertextform d-flex">
                      <div className="searchDropdown searchDropdown1">
                        <Select
                          placeholder="Nationality"
                          defaultValue={SearchNationalityId}
                          onChange={setNationalityId}
                          options={AllNationality}
                          isClearable={true}
                          isSearchable={true}
                          onInputChange={handleChangeInput}

                        />
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setSearchNameEn(e.target.value)}
                        placeholder="Enter Name"
                      />
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setSearchRating(e.target.value)}
                        placeholder="Enter Title"
                      />

                        <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setshortCode(e.target.value)}
                        placeholder="Enter Short Code"
                      />

                    </div>
                  </div>
                  <button className="filterbtn" onClick={GetSearch}>
                    Apply Filter
                  </button>
                </>
              ) : (
                <></>
              )}
            </div>
            <>
              <div className="div_maintb">
                <ScrollContainer className="scroll-container">
                  <table>
                    <thead>
                      <tr>
                        <th>Action</th>
                        <th>Name</th>
                        <th>Name Arabic</th>
                        <th>Short Code</th>

                        <th>Age</th>
                        <th>Title</th>
                        <th>Title Arabic</th>

                        <th>License Date</th>
                        <th>Short Name</th>
                        <th>Short Name Arabic </th>

                        <th>Remarks</th>
                        <th>Remarks Arabic</th>
                        <th>Details</th>
                        <th>Details Arabic</th>

                        <th>Nationality</th>

                        <th>Image</th>
                      </tr>
                    </thead>
                    {loading ? (
                      <Lottie
                        animationData={HorseAnimation}
                        loop={true}
                        className="TableLottie"
                      />
                    ) : SearchData === [] ? (
                      <Notfound />
                    ) : (
                      <tbody>
                        {SearchData.map((item, index) => {
                          return (
                            <>
                              <tr key={index} className="tr_table_class">
                                <td
                                  className="table_delete_btn1"
                                // style={{ textAlign: "center" }}
                                >
                                  <BiEdit
                                    onClick={() =>
                                      navigate("/edittrainer", {
                                        state: {
                                          trainerid: item,
                                        },
                                      })
                                    }
                                  />
                                 <MdAutoDelete
                                    style={{
                                      fontSize: "22px",
                                    }}
                                    onClick={() => handleRemove(item._id)}
                                  />
                                  <BsEyeFill onClick={() => handleShow(item)} />
                                </td>
                                <td>{item.NameEn}</td>
                                <td>{item.NameAr}</td>
                                <td>{item.shortCode}</td>

                                <td>
                                  {" "}
                                  <Moment fromNow ago>
                                    {item.DOB}
                                  </Moment>
                                </td>
                                <td>{item.TitleEn}</td>
                                <td>
                                  {item.TitleAr === "" ? (
                                    <>N/A</>
                                  ) : (
                                    item.TitleAr
                                  )}
                                </td>

                                <td>
                                  {" "}
                                  <Moment format="YYYY/MM/DD">
                                    {item.TrainerLicenseDate}
                                  </Moment>
                                </td>
                                <td>{item.ShortNameEn}</td>
                                <td>
                                  {item.ShortNameAr === "" ? (
                                    <>N/A</>
                                  ) : (
                                    item.ShortNameAr
                                  )}{" "}
                                </td>

                                <td
                                  style={{
                                    maxWidth: "400px",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {item.RemarksEn ? (
                                    <>{item.RemarksEn}</>
                                  ) : (
                                    <>-</>
                                  )}
                                </td>
                                <td
                                  style={{
                                    maxWidth: "400px",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {item.RemarksAr ? (
                                    <>{item.RemarksAr}</>
                                  ) : (
                                    <>-</>
                                  )}
                                </td>
                                <td
                                  style={{
                                    maxWidth: "400px",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {item.DetailEn}
                                </td>
                                <td
                                  style={{
                                    maxWidth: "400px",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {item.DetailAr}
                                </td>
                                <td>
                                  {item.TrainerNationalityData === null ? (
                                    <>N/A</>
                                  ) : (
                                    item.TrainerNationalityData.NameEn
                                  )}
                                </td>

                                <td>
                                  <img
                                    src={item.image ? item.image : Defaultimg}
                                    alt=""
                                  />
                                </td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    )}
                  </table>
                </ScrollContainer>
              </div>
            </>
          </div>
          <Pagination
            postsPerPage={postsPerPage}
            totalPosts={TotalCount}
            paginate={paginate}
            currentPage={currentPage}
            TotalPages={TotalPages}
          />
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2 style={{ fontFamily: "inter" }}>Trainer </h2>
        </Modal.Header>
        <Modal.Body>
          <TrainerPopup data={modaldata} />
        </Modal.Body>
        <Modal.Footer>
          <button onClick={handleClose} className="modalClosebtn">
            Close
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showCSV}
        onHide={handleCloseCSV}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2 style={{ fontFamily: "inter" }}>Trainer JSON</h2>
        </Modal.Header>
        <Modal.Body>
          <TrainerCSV data={modaldataCSV} />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};
export default Trainer;
