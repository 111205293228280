import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});

const getcompetitionRaceSlice = createSlice({
    name: 'competitionrace',
    initialState: {
        data: [],
        status: STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchcompetitionrace.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(fetchcompetitionrace.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.IDLE
            })
            .addCase(fetchcompetitionrace.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
    }
});

export const { setcompetition, setStatus } = getcompetitionRaceSlice.actions;
export default getcompetitionRaceSlice.reducer;

export const fetchcompetitionrace = createAsyncThunk('/GetCompetitionRaces/fetch', async (id) => {
    const res = await axios.get(`${window.env.API_URL}/GetCompetitionRaces/${id}`);
    const competitionRaceData = res.data;
    return competitionRaceData.data;
})