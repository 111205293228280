import React, { useEffect, useState } from "react";
import { STATUSES } from "../../redux/getReducer/getCompetition";
import { useDispatch, useSelector } from "react-redux";
import { MdAutoDelete, MdDelete } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { BiEdit } from "react-icons/bi";
import swal from "sweetalert";
import { Form, Modal } from "react-bootstrap";
import { BsEyeFill } from "react-icons/bs";
import ScrollContainer from "react-indiana-drag-scroll";
import Moment from "react-moment";
import axios from "axios";
import Lottie from "lottie-react";
import HorseAnimation from "../../assets/horselottie.json";
import CompetitionPopup from "../../Components/Popup/CompetitionPopup";
import Pagination from "./Pagination";
import { BiFilter } from "react-icons/bi";
import { CSVLink } from "react-csv";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { DateRangePicker } from "react-date-range";

import Notfound from "../../Notfound";
//--------------------------option for dropdown----------------------//
const CategoryType = [
  {
    id: "pick",
    value: "pick",
    label: "Pick",
  },
  {
    id: "cast",
    value: "cast",
    label: "Cast",
  },
];
const CompetetionCategory = () => {
  const [ShowCalender, setShowCalender] = useState(false);
  const [SearchData, setSearchData] = useState([]);

  const [loading, setLoading] = useState(false);
  const [TotalPages, setTotalPages] = useState("");
  const [SearchAge, setSearchAge] = useState("");
  const [SearchCode, setSearchCode] = useState("");
  const [SearchTitle, setSearchTitle] = useState("");
  const [TotalCount, setTotalCount] = useState();
  const [SearchCategoryType, setSearchCategoryType] = useState("");

  const { userInfo } = useSelector((state) => state.user);

  //--------------------------seraching with date----------------------//
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [show, setShow] = useState(false);
  const [modaldata, setmodaldata] = useState();
  const handleClose = () => setShow(false);
  const handleShow = async (data) => {
    setmodaldata(data);
    await setShow(true);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const history = useNavigate();

  const { data: competition, status } = useSelector(
    (state) => state.competition
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(8);
  const [Active, setActive] = useState(false);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  //--------------------------api----------------------//

  const GetSearch = async () => {
    setLoading(true);
    const response = await axios.get(
      `${window.env.API_URL}/searchcompetition?CategoryCount=${SearchAge}&NameEn=${SearchTitle}&CompetitionCode=${SearchCode}&CompetitionCategory=${SearchCategoryType}&page=${currentPage}`
    );
    setSearchData(response.data.data);
    setLoading(false);
    setSearchCategoryType("");
    const TotalPagesData = response.data.totalPages;
    setTotalPages(TotalPagesData);
    const totalcount = response.data.totalcount;
    setTotalCount(totalcount);
  };
  const CloseCompetition = async (data) => {
    setmodaldata(data);
    await setShow(true);
  };
  const handleActivate = async (id) => {
    setActive(false);
    try {
      swal({
        title: "Are you sure?",
        text: `do you want to Activate this Competetion`,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        const formData = new FormData();
        formData.append("Active", true);

        await axios.put(
          `${window.env.API_URL}ActivateCompetition/${id}`,
          formData
        );
        if (willDelete) {
          swal("Race Status is Updated Successfully!", {
            icon: "success",
          });
          GetSearch();
        } else {
          swal("Competetion Status Not Changed!");
        }
      });
    } catch (error) {
      swal({
        title: "Error!",
        text: error.message,
        icon: "error",
        button: "OK",
      });
    }
  };

  const handleDeactivate = (id) => {
    setActive(true);
    try {
      swal({
        title: "Are you sure?",
        text: `do you want to deactivate this Competetion ?`,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        const formData = new FormData();

        await axios.put(
          `${window.env.API_URL}InactiveCompetition/${id}`,
          formData
        );
        if (willDelete) {
          swal("Race Status is Updated Successfully!", {
            icon: "success",
          });
          GetSearch();
        } else {
          swal("Comeptetion Status Not Changed!");
        }
      });
    } catch (error) {
      swal({
        title: "Error!",
        text: error.message,
        icon: "error",
        button: "OK",
      });
    }
  };

  useEffect(() => {
    GetSearch({ currentPage });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, currentPage]);

  //--------------------------delete----------------------//
 


  const handleRemove1 = async (Id) => {
    try {
      swal({
        title: "Are you sure?",
        text: "do you want to delete this data ?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          const res = await axios.delete(`${window.env.API_URL}/VerifyAndDeleteCompetition/${Id}`);
          const msg = res.data.message
                //    const msg = res.
                    swal(msg, {
                        icon: "",
                    });
                    GetSearch();        } else {
          swal("Your data is safe!");
        }
      });
    } catch (error) {
      const err = error.response.data.message;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
    }
  };


  const handleRemove = async (id) => {
    try {
      swal({
        title: "Are you sure?",
        text: "do you want to delete this data ?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          setLoading(true)
          const res = await axios.delete(`${window.env.API_URL}/DeleteCompetitionAlltoAll/${id}`);
          const msg = res.data.message ?  res.data.message : "deleted";
          swal(msg, {
            icon: "",
          });
      let data1 =  JSON.stringify(res.data.data)
      const blob = new Blob([data1]);
      if(res?.data?.flag){
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.download = "report1.json";
        link.href = url;
        link.click();
        setLoading(false)
            GetSearch({ currentPage });

      }     
      setLoading(false)
          GetSearch({ currentPage });

        } else {
          swal("Your data is safe!");
        }
      });
    } catch (error) {
      const err = error.success;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
      setLoading(false)

    }
  }

  if (status === STATUSES.ERROR) {
    return (
      <h2
        style={{
          margin: "100px",
        }}
      >
        Something went wrong!
      </h2>
    );
  }
  return (
    <>
      <div className="page">
        <div className="rightsidedata">
          <div
            style={{
              marginTop: "30px",
            }}
          >
            <div className="Header ">
              <h4>Competition Listings</h4>

              <div>
                <Link to="/addcompetition">
                  <button>Add Competition</button>
                </Link>
                <OverlayTrigger
                  overlay={<Tooltip id={`tooltip-top`}>Filter</Tooltip>}
                >
                  <span className="addmore">
                    <BiFilter
                      className="calendericon"
                      onClick={() => setShowCalender(!ShowCalender)}
                    />
                  </span>
                </OverlayTrigger>{" "}
                <CSVLink
                  data={competition}
                  separator={";"}
                  filename={"MKS Competition.csv"}
                  className="csvclass"
                >
                  Export CSV
                </CSVLink>
              </div>
            </div>
            <div>
              {ShowCalender ? (
                <>
                  <div className="userfilter userFilter">
              
                    <div className="filtertextform filterTextForm">
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setSearchTitle(e.target.value)}
                        placeholder="Enter Name"
                      />
                     
                  
                    </div>
                  </div>
                  <button className="filterbtn filterBtn" onClick={GetSearch}>
                    Apply Filter
                  </button>
                </>
              ) : (
                <></>
              )}
            </div>
            <>
              <div className="div_maintb">
                <ScrollContainer>
                  <table>
                    <thead>
                      <tr>
                        <th>Action</th>

                        <th>Active</th>
                        <th>Close</th>
                        <th>Name</th>
                        <th>Name Arabic </th>
                        <th>Competition Code </th>
                        <th>Short Code</th>
                        <th>Type/Category</th>
                        <th>Count </th>
                        <th>Start Date </th>
                        <th>End Date </th>
                      </tr>
                    </thead>
                    {loading ? (
                      <Lottie
                        animationData={HorseAnimation}
                        loop={true}
                        className="TableLottie"
                      />
                    ) : !SearchData.length ? (
                      <Notfound />
                    ) : (
                      <tbody>
                        {status === STATUSES.LOADING ? (
                          <Lottie
                            animationData={HorseAnimation}
                            loop={true}
                            className="TableLottie"
                          />
                        ) : (
                          SearchData.map((item, index) => {
                            return (
                              <>
                                <tr className="tr_table_class">
                                  <td className="table_delete_btn1">
                                    <BiEdit
                                      onClick={() =>
                                        navigate("/editcompetition", {
                                          state: {
                                            competitionid: item,
                                          },
                                        })
                                      }
                                    />
                                     <MdAutoDelete
                                    style={{
                                      fontSize: "22px",
                                    }}
                                    onClick={() => handleRemove(item._id)}
                                  />
                                    {/* <BsEyeFill
                                      onClick={() => handleShow(item)}
                                    /> */}
                                  </td>
                                  <td>
                                    {!item.Active ? (
                                      <button
                                        className="Approvedbtn purple"
                                        onClick={() => handleActivate(item._id)}
                                      >
                                        Activate{" "}
                                      </button>
                                    ) : (
                                      <button
                                        className="Approvedbtn red"
                                        onClick={() =>
                                          handleDeactivate(item._id)
                                        }
                                      >
                                        deactivate
                                      </button>
                                    )}
                                  </td>
                                  <td>
                                    {!item.Close ? (
                                      <button
                                        className="Approvedbtn purple"
                                        onClick={() => CloseCompetition(item)}
                                      >
                                        To be Close{" "}
                                      </button>
                                    ) : (
                                      <button className="Approvedbtn red">
                                        Closed
                                      </button>
                                    )}
                                  </td>

                                  <td>
                                    {item.NameEn === null ? (
                                      <>N/A</>
                                    ) : (
                                      item.NameEn
                                    )}
                                  </td>
                                  <td>
                                    {item.NameAr === null ? (
                                      <>N/A</>
                                    ) : (
                                      item.NameAr
                                    )}
                                  </td>
                                  <td>
                                    {item.CompetitionCode === null ? (
                                      <>N/A</>
                                    ) : (
                                      item.CompetitionCode
                                    )}
                                  </td>
                                  <td>{item.shortCode}</td>
                                  <td>{item.CompetitionCategory}</td>
                                  <td>{item.CategoryCount}</td>
                                  <td>
                                    <Moment format="YYYY/MM/DD">
                                      {item.StartDate}
                                    </Moment>{" "}
                                  </td>
                                  <td>
                                    <Moment format="YYYY/MM/DD">
                                      {item.EndDate}
                                    </Moment>{" "}
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        )}
                      </tbody>
                    )}
                  </table>
                </ScrollContainer>
              </div>
            </>
          </div>
          <span className="plusIconStyle"></span>
          <Pagination
            postsPerPage={postsPerPage}
            totalPosts={TotalCount}
            paginate={paginate}
            currentPage={currentPage}
            TotalPages={TotalPages}
          />
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2>Competition </h2>
        </Modal.Header>
        <Modal.Body>
          <CompetitionPopup data={modaldata} />
        </Modal.Body>
{/* 
        <Modal.Footer>
          <button onClick={handleClose} className="modalClosebtn">
            Close
          </button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
};
export default CompetetionCategory;
