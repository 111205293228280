import React, { useState, useEffect } from "react";
import "../../Components/CSS/forms.css";
import { useNavigate, useLocation } from "react-router-dom";
import swal from "sweetalert";
import axios from "axios";

import { Form, Input } from 'antd';
import Loader from "../../Components/Common/Loader";
const EditRaceName = () => {
  const history = useNavigate();
  const { state } = useLocation();
  const [form] = Form.useForm();
  const { racenameid } = state;
  const [isLoading, setisLoading] = useState(false);
  const [state1, setState] = useState({
    NameEn: "",
    NameAr: "",
    shortCode: "",
  });

  useEffect(() => {
    if (racenameid) {
      setState({
        NameEn: racenameid.NameEn,
        NameAr: racenameid.NameAr,
        shortCode: racenameid.shortCode,
      });
    } else {
      alert("No Data");
    }
  }, [racenameid]);

  const submit = async () => {

    try {
      setisLoading(true);
      const formData = new FormData();
      formData.append("NameEn", state1.NameEn);
      formData.append("NameAr", state1.NameAr + " ");
      formData.append("shortCode", state1.shortCode);

      await axios.put(
        `${window.env.API_URL}/updateRaceName/${racenameid._id}`,
        formData
      );
      history("/racename");
      swal({
        title: "Success!",
        text: "Data has been Updated successfully ",
        icon: "success",
        button: "OK",
      });
    } catch (error) {
      const err = error.response.data.message
      const err1 = error.response.data.message[1];
      const err2 = error.response.data.message[2];
      swal({
        title: "Error!",
        text: err,
        err1,
        err2,
        icon: "error",
        button: "OK",
      });
    }
    setisLoading(false);
  };
  form.setFieldsValue({
    NameEn: state1.NameEn,
    NameAr: state1.NameAr,

    shortCode: state1.shortCode

  });
  return (
    <>
      <div className="page">
        <div className="rightsidedata">
          <div
            style={{
              marginTop: "30px",
            }}
          >
            <div className="Headers">Edit Race Name</div>
            {isLoading ? <Loader /> : <div className="form">
              <Form onFinish={submit} form={form} >
                <div className="row mainrow">
                  <div className="col-sm">
                    <Form.Item
                      name="NameEn"
                      label="Name"
                      rules={[
                        {
                          required: true,
                          message: 'Name (English) is required',
                        },
                      ]}
                    >
                      <Input placeholder="Name" onChange={(e) =>
                        setState({ ...state1, NameEn: e.target.value })
                      }
                        value={state1.NameEn} name="NameEn" />
                    </Form.Item>


                    <span className="spanForm"> |</span>
                  </div>

                  <div className="col-sm">
                    <Form.Item
                      name="NameAr"
                      label="اسم"
                      rules={[
                        {
                          required: true,
                          message: 'Name (Arabic) is required',
                        },
                      ]}
                      style={{ direction: 'rtl' }}
                    >
                      <Input placeholder="اسم" onChange={(e) =>
                        setState({ ...state1, NameAr: e.target.value })
                      }
                        name="Name"
                        value={state1.NameAr} />
                    </Form.Item>
                  </div>
                </div>
                <div className="row mainrow">
                  <div className="col-sm">
                    <Form.Item
                      name="shortCode"
                      label="Short Code"
                      rules={[
                        {
                          required: true,
                          message: "short Code is required"
                        },
                      ]}
                    >
                      <Input
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        onChange={(e) =>
                          setState({ ...state1, shortCode: e.target.value })
                        }
                        value={state1.shortCode}
                        onPaste={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        placeholder="Short Code"
                        maxLength="5"
                      />
                    </Form.Item>


                  </div>
                </div>


                <div className="ButtonSection" style={{ justifyContent: "end" }}>
                  <button type="submit" className="SubmitButton" disabled={isLoading}>
                    Update
                  </button>
                </div>
              </Form>
            </div>}
          </div>
        </div>
      </div>
    </>
  );
};

export default EditRaceName;
