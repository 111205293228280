import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});

const getDeletedPointsSlice = createSlice({
    name: 'deletedpoints',
    initialState: {
        data: [],
        status: STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchdeletedpoint.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(fetchdeletedpoint.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.IDLE
            })
            .addCase(fetchdeletedpoint.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
    }
});

export const { setequipment, setStatus } = getDeletedPointsSlice.actions;
export default getDeletedPointsSlice.reducer;

export const fetchdeletedpoint = createAsyncThunk('/PointTableSystemgetdeleted/fetch', async () => {
    const res = await axios.get(`${window.env.API_URL}/PointTableSystemgetdeleted?keyword=&page=`);
    const pointData = res.data;
    return pointData.data;
})


