import React, { useEffect, useState } from "react";
import { STATUSES } from "../../redux/getReducer/getJockeySlice";
import { useDispatch, useSelector } from "react-redux";
import { MdAutoDelete, MdDelete } from "react-icons/md";
import { fetchNationalityList } from "../../redux/getDropDownReducer/getNationalityList";

import { Link, useNavigate } from "react-router-dom";
import { BiEdit } from "react-icons/bi";
import swal from "sweetalert";
import JockeyPopup from "../../Components/Popup/JockeyPopup";
import { Modal } from "react-bootstrap";
import { BsEyeFill } from "react-icons/bs";
import ScrollContainer from "react-indiana-drag-scroll";
import Moment from "react-moment";
import axios from "axios";
import Lottie from "lottie-react";
import HorseAnimation from "../../assets/horselottie.json";
import Pagination from "./Pagination";
import { BiFilter } from 'react-icons/bi';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Defaultimg from "../../assets/default.jpg"
import JockeyCSV from '../../Components/CSVUploadPopup/JockeyPopup'
import Select from "react-select";
import Notfound from "../../Notfound";



const Jockey = () => {
  //--------------------------state----------------------//

  const [SearchData, setSearchData] = useState([]);
  const [ShowCalender, setShowCalender] = useState(false)
  const [SearchNameEn, setSearchNameEn] = useState('');
  const [loading, setLoading] = useState(false);
  const [shortCode, setshortCode] = useState("");

  const [NationalityId, setNationalityId] = useState()
  const [TotalPages, setTotalPages] = useState('');
  const { userInfo } = useSelector((state) => state.user);
  const [value, setvalue] = useState("");



  const [show, setShow] = useState(false);
  const [modaldata, setmodaldata] = useState();
  const handleClose = () => setShow(false);
  const handleShow = async (data) => {
    setmodaldata(data);
    await setShow(true);
  };
  //--------------------------import csv----------------------//

  const [showCSV, setShowCSV] = useState(false);
  const [modaldataCSV, setmodaldataCSV] = useState();
  const handleCloseCSV = () => setShowCSV(false);
  const handleShowCSV = async (data) => {
    setmodaldataCSV(data)
    await setShowCSV(true);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  //--------------------------state----------------------//
  const { status } = useSelector((state) => state.jockey);
  const { data: NationalityList } = useSelector((state) => state.NationalityList);

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(8)
  const [TotalCount, setTotalCount] = useState()



  const localTime = "Sat Jul 01 2023 00:00:00 GMT+0500 (Pakistan Standard Time)";
  const date = new Date(localTime);
  
  // Get the time zone offset in minutes and convert it to milliseconds
  const timeZoneOffsetMs = date.getTimezoneOffset() * 60000;
  
  // Adjust the date by subtracting the time zone offset to get the UTC time
  const utcTime = new Date(date.getTime() - timeZoneOffsetMs);


  const paginate = pageNumber => setCurrentPage(pageNumber);

  //--------------------------data for dropdown----------------------//
  let AllNationality =
    NationalityList === undefined ? (
      <></>
    ) : (
      NationalityList.map(function (item) {
        return {
          id: item._id,
          value: item.NameEn,
          label: (
            <div style={{
              display: 'flex',
              justifyContent: 'space-between'
            }}>
              <p>{item.NameEn}</p>


            </div>
          ),
        };
      })
    );

  //--------------------------api----------------------//

  const Nationality = NationalityId ? NationalityId.id : ""


  const GetSearch = async () => {
    setLoading(true)
    const response = await axios.get(
      `${window.env.API_URL}/SearchJockey?NameEn=${SearchNameEn}&shortCode=${shortCode}&NationalityID=${Nationality}&page=${currentPage}`
    );
    setSearchData(response.data.data);
    setLoading(false)
    setTotalCount(response.data.totalcount)
    const TotalPagesData = response.data.totalPages;
    setTotalPages(TotalPagesData)

    if(TotalPagesData <= 1){
      setCurrentPage(1)
    }
  };


  useEffect(() => {

    GetSearch({ currentPage })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, currentPage]);

  useEffect(() => {
    dispatch(fetchNationalityList({value}));
  },[dispatch,value])
  //--------------------------delete----------------------//


  const handleChangeInput = (value) => {
    setvalue(value);
  };
  const handleRemove1 = async (Id) => {
    try {
      swal({
        title: "Are you sure?",
        text: "do you want to delete this data ?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })

        .then(async (willDelete) => {


          if (willDelete) {
            await axios.delete(`${window.env.API_URL}/softdeleteJockey/${Id}`)
            swal(" Your data has been deleted Successfully!", {
              icon: "success",

            }
            )
            GetSearch()

          } else {
            swal("Your data is safe!");
          }
        });

    } catch (error) {

      const err = error.response.data.message;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
    }



  }

  const handleRemove = async (id) => {
    try {
      swal({
        title: "Are you sure?",
        text: "do you want to delete this data ?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          setLoading(true)
          const res = await axios.delete(`${window.env.API_URL}/DeleteJockeyAlltoAll/${id}`);
          const msg = res.data.message ?  res.data.message : "deleted";
          swal(msg, {
            icon: "",
          });
      let data1 =  JSON.stringify(res.data.data)
      const blob = new Blob([data1]);
      if(res?.data?.flag){
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.download = "report1.json";
        link.href = url;
        link.click();
        setLoading(false)
            GetSearch({ currentPage });

      }     
      setLoading(false)
          GetSearch({ currentPage });

        } else {
          swal("Your data is safe!");
        }
      });
    } catch (error) {
      const err = error.success;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
      setLoading(false)

    }
  }


  if (status === STATUSES.ERROR) {
    return (
      <h2
        style={{
          margin: "100px",
        }}
      >
        Something went wrong!
      </h2>
    );
  }
  return (
    <>
      <div className="page">
        <div className="rightsidedata">
          <div
            style={{
              marginTop: "30px",
            }}
          >
            <div className="Header ">
              <h4>Jockey Listings</h4>

              <div>
                <h6
                  style={{
                    marginRight: "100px",
                    alignItems: "center",
                    color: "rgba(0, 0, 0, 0.6)",
                  }}
                >
                </h6>

                <Link to="/jockeyform">
                  <button>Add Jockey</button>
                </Link>
                <OverlayTrigger
                  overlay={<Tooltip id={`tooltip-top`}>Filter</Tooltip>}
                >
                  <span
                    className="addmore"
                  >
                    <BiFilter
                      className="calendericon"
                      onClick={() => setShowCalender(!ShowCalender)}
                    />
                  </span>
                </OverlayTrigger>
                <p onClick={() => handleShowCSV()} className="importcsv">Import JSON</p>


              </div>
            </div>
            <div>

              {
                ShowCalender ?
                  <>
                    <div className="userfilter">
                      <div className="filtertextform d-flex">
                        <div className="searchDropdown searchDropdown1">
                          <Select
                            placeholder="Search Nationality"
                            defaultValue={NationalityId}
                            onChange={setNationalityId}
                            options={AllNationality}
                            isClearable={true}
                            isSearchable={true}
                            onInputChange={handleChangeInput}

                          />


                        </div>
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => setSearchNameEn(e.target.value)}
                          placeholder="Enter Name"
                        />

                          <input
                          type="text"
                          className="form-control"
                          onChange={(e) => setshortCode(e.target.value)}
                          placeholder="Enter Short Code"
                        />





                      </div>
                    </div>
                    <div>
                      <button className="filterbtn filterBtn" onClick={GetSearch}>
                        Apply Filter
                      </button>
                    </div>
                  </> : <></>
              }
            </div>
            <>
              <div className="div_maintb">
                <ScrollContainer>
                  <table>
                    <thead>
                      <tr>
                        <th>Action</th>
                        <th>Jockey Name</th>
                        <th>Name Arabic </th>
                        <th>Short Code</th>

                        <th>Short Name </th>
                        <th>Short Name Arabic</th>
                        <th>Age</th>
                        <th>Rating</th>
                        <th>License Date </th>
                        <th>Remarks</th>
                        <th>Remarks Arabic </th>
                        <th>Min Weight</th>
                        <th>Max Weight</th>
                        <th>Nationality</th>

                        <th>Image</th>
                      </tr>
                    </thead>
                    {loading ? <Lottie animationData={HorseAnimation} loop={true} className="TableLottie" /> :

                      !SearchData.length ? <Notfound /> :

                        <tbody>
                          {

                            status === STATUSES.LOADING ?

                              <Lottie animationData={HorseAnimation} loop={true} className="TableLottie Lottie" />

                              :
                              SearchData.map((item, index) => {
                                return (
                                  <>
                                    <tr className="tr_table_class">
                                      <td className="table_delete_btn1">
                                        <BiEdit onClick={() => navigate('/editjockey', {
                                          state: {
                                            jockeyid: item
                                          }
                                        })} />
                                         <MdAutoDelete
                                            style={{
                                              fontSize: "22px",
                                            }}
                                            onClick={() => handleRemove(item._id)}
                                          />
                                        <BsEyeFill onClick={() => handleShow(item)} />
                                      </td>
                                      <td>{item.NameEn}</td>
                                      <td>{item.NameAr}</td>
                                      <td>{item.shortCode}</td>

                                      <td>{item.ShortNameEn}</td>
                                      <td>{item.ShortNameAr === '' ? <>N/A</> : item.ShortNameAr}</td>
                                      <td>
                                        {" "}
                                        <Moment fromNow ago>
                                          {item.DOB}
                                        </Moment>
                                      </td>
                                      <td>{item.Rating} </td>

                                      <td>
                                        <Moment format="YYYY/DD/MM">
                                          {item.JockeyLicenseDate}
                                        </Moment>{" "}
                                      </td>
                                      <td>{item.RemarksEn ? item.RemarksEn : <>-</>}</td>
                                      <td>{item.RemarksAr ? item.RemarksAr : <>-</>} </td>
                                      <td>{item.MiniumumJockeyWeight} KG</td>
                                      <td>{item.MaximumJockeyWeight} KG</td>
                                      <td>{item.JockeyNationalityData === null ? <>N/A</> : item.JockeyNationalityData.NameEn}</td>

                                      <td>
                                        <img src={item.image ? item.image : Defaultimg} alt="" />
                                      </td>


                                    </tr>
                                  </>
                                );
                              })}
                        </tbody>
                    }
                  </table>
                </ScrollContainer>
              </div>
            </>
          </div>
          <span className="plusIconStyle"></span>
          <Pagination
            postsPerPage={postsPerPage}
            totalPosts={TotalCount}
            paginate={paginate}
            currentPage={currentPage}
            TotalPages={TotalPages}

          />
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2>Jockey </h2>
        </Modal.Header>
        <Modal.Body>
          <JockeyPopup data={modaldata} />
        </Modal.Body>
        <Modal.Footer>
          <button onClick={handleClose} className="modalClosebtn">
            Close
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showCSV}
        onHide={handleCloseCSV}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2 style={{ fontFamily: "inter" }}>Jockey JSON</h2>
        </Modal.Header>
        <Modal.Body>
          <JockeyCSV data={modaldataCSV} />
        </Modal.Body>
        <Modal.Footer>

        </Modal.Footer>
      </Modal>
    </>
  );
};
export default Jockey;
