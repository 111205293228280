import axios from "axios";
import Cookies from "js-cookie";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const userLogin = createAsyncThunk(
  "user/login",
  async ({ Email, password }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {  
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post(
        `${window.env.API_URL}/adminlogin`,
        { Email, password },
        config
      );
      Cookies.set("userToken", data.token);
      Cookies.set("id", data.user._id);

      return data;
    } catch (error) {
      if (error) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const registerUser = createAsyncThunk(
  "user/register",
  async (
    { FirstName, LastName, PassportNo, PhoneNumber, password, Email },
    { rejectWithValue }
  ) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        `${window.env.API_URL}/register`,
        { FirstName, LastName, PassportNo, PhoneNumber, password, Email },
        config
      );
      Cookies.set("userToken", data.token);
      Cookies.set("id", data.user._id);

      return data;
    } catch (error) {
      if (error) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getUserDetails = createAsyncThunk(
  "user/getUserDetails",
  async (arg, { getState, rejectWithValue }) => {
    try {
      // get user data from store
      const { user } = getState();

      // configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${user.userToken}`,
        },
      };

      const { data } = await axios.get(
        `${window.env.API_URL}/singleAdmin/${Cookies.get("id")}`,
        config
      );
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
