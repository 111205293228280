import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { useSelector } from "react-redux";
import { fetchfinalpositiondropdown } from "../../redux/getDropDownReducer/getFinalPosition";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchHorseforRace } from "../../redux/getReducer/getHorsesForRace";
import { fetchEditRaceResult } from "../../redux/getReducer/getEditRaceResult";
import EditImages from "./EditResultImages";
import { Modal } from "react-bootstrap";
import Lottie from "lottie-react";
import HorseAnimation from "../../assets/horselottie.json";
import Loader from "../../Components/Common/Loader";


import axios from "axios";
import swal from "sweetalert";

const EditResult = () => {
  const dispatch = useDispatch();
  const [StoreData, setStoreData] = useState([]);
  const [StorDatanew, setStorDatanew] = useState([]);
  const [time, setTime] = useState("");
  const [videolink1, setvideolink1] = useState("");
  const [isLoading, setisLoading] = useState(false);

  const { data: HorseforRace } = useSelector((state) => state.HorseforRace);
  const { data: editraceresult } = useSelector((state) => state.editraceresult);

  const { data: finalpositiondropdown } = useSelector(
    (state) => state.finalpositiondropdown
  );
  const [isEditing, setIsEditing] = useState(false);
  const [state1, setState] = useState({
    Rating: "",
  });
  const { state } = useLocation();
  const { RaceId } = state;
  const [items, setitems] = useState([]);
  const history = useNavigate();

  const [showResultimage, setShowResultimage] = useState(false);

  const handleCloseResultimage = () => setShowResultimage(false);

  const handleShowResultimage = async () => {
    await setShowResultimage(true);
  };

  const slider = document.querySelector(".horizontal-scroll-wrapper");
  let isDown = false;
  let startX;
  let scrollLeft;
  if (slider) {
    slider.addEventListener("mousedown", (e) => {
      isDown = true;
      slider.classList.add("active");
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    });

    slider.addEventListener("mouseleave", () => {
      isDown = false;
      slider.classList.remove("active");
    });
    slider.addEventListener("mouseup", () => {
      isDown = false;
      slider.classList.remove("active");
    });
    slider.addEventListener("mousemove", (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 3;
      slider.scrollLeft = scrollLeft - walk;
    });
  }
  const A1 = items.map((item) => item.HorseID);
  let finalposition1 = finalpositiondropdown.map(function (item) {
    return {
      id: item._id,
      value: item.NameEn,
      Rank: item.Rank,
      label: item.NameEn,
    };
  });
  let sortedProducts = StoreData.sort((p1, p2) =>
    p1.Rank > p2.Rank ? 1 : p1.Rank < p2.Rank ? -1 : 0
  );
  const arr = sortedProducts.map((item) => item.Distance);
  var sum = 0;
  for (let i = 0; i < arr.length; i++) {
    sum += parseInt(arr[i]);
  }
  const DataABC = JSON.parse(localStorage.getItem("mappingdata"));
  const removesingleItem = (id) => {
    const updateItems = items.filter((elem, ind) => {
      return ind !== id;
    });
    setitems(updateItems);

    const updateItems1 = StoreData.filter((elem, ind) => {
      return ind !== id;
    });
    setStoreData(updateItems1);
  };
  const [statetime, setStatetime] = useState({
    Time: "",
  });

  const [videoLink, setvideoLink] = useState({
    Link: "",
  });


  
  useEffect(() => {
   if(time){
    setStatetime({
      Time:time.RaceTime
    })
   }
  },[time])

  useEffect(() => {
    if(videolink1){
      setvideoLink({
        Link:videolink1.VideoLink
     })
    }
   },[videolink1])


  const updateState = () => { };

  let horseoptions = HorseforRace.map(function (item) {
    return {
      id: item.HorseModelIdData1 ? item.HorseModelIdData1._id : <></>,
      value: item.HorseModelIdData1 ? item.HorseModelIdData1.NameEn : <></>,
      label: item.HorseModelIdData1 ? item.HorseModelIdData1.NameEn : <></>,
    };
  });

  
  const handleTimeChange = (event) => {
    const inputTime = event.target.value;
    const formattedTime = inputTime.replace(/(\d{2})(?=\d{2})/g, '$1:');
    setTime(formattedTime);
  };

  useEffect(() => {
    dispatch(fetchHorseforRace({ RaceId }));
    dispatch(fetchfinalpositiondropdown());

    // GetSearch();
    localStorage.setItem("results", JSON.stringify(items));
    localStorage.setItem("mappingdata", JSON.stringify(StoreData));
  }, [RaceId, StoreData, dispatch]);

  const [data, setData] = useState(editraceresult);

  useEffect(() => {
    axios.get(`${window.env.API_URL}/GetEditRaceResultV2/${RaceId}`)
      .then(response => {
        setData(response.data.data);
        setStorDatanew(response.data)
        setTime(response.data.RaceTime)
        setvideolink1(response.data.VideoLink)
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  const handleEdit = (index, newData) => {

    console.log(newData,"newData 123 123")
    console.log(data,"index 123 123")
    const updatedData = [...data]; // create a copy of the original array
    updatedData[index] = { ...updatedData[index], ...newData };
    setData(updatedData); // set the updated data in the component state
  };


  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedCity2, setSelectedCity2] = useState(null);


  const handleCityChange111 = ( index,selectedOption) => {
    setSelectedCity(selectedOption);
    handleEdit(index, { HorseModelIdData1: { ...data[index].HorseModelIdData1, Distance: selectedOption.Distance } });
  };


  
  const handleCityChange222 = ( index,selectedOption) => {
    setSelectedCity(selectedOption);
    handleEdit(index, { HorseModelIdData1: { ...data[index].HorseModelIdData1, Rating: selectedOption.Rating } });
  };


  const handleCityChange444 = ( index,selectedOption) => {
    setSelectedCity(selectedOption);
    handleEdit(index, { HorseModelIdData1: { ...data[index].HorseModelIdData1, Remarks: selectedOption.Remarks } });
  };


  const formattedTime = (inputTime) => {
    return inputTime.replace(/(\d{2})(?=\d{2})/g, '$1:');
  };

  const handleChange = (e) => {
    const inputTime = e.target.value;
    const formatted = formattedTime(inputTime);

    setStatetime({ ...statetime, Time: formatted });
  };

  const handleChangeLink = (e) => {
    const inputlink = e.target.value;
    setvideoLink({ ...videoLink, Link: inputlink });
  };

  const handleCityChange1 = (selectedOption, index) => {

    console.log(selectedOption,'selected 0ppo')
    setSelectedCity2(selectedOption);
    handleEdit(index, { HorseModelIdData1: { ...data[index].HorseModelIdData1, FinalPositionid: selectedOption.id, FinalPositionNameEn: selectedOption.value , FinalPositionRank: selectedOption.Rank} });

  };




  let MapGate = [];
  for (var i = 0; i < data.length; i++) {
    MapGate.push({
      FinalPosition: data[i].HorseModelIdData1 ? data[i].HorseModelIdData1.FinalPositionid :null,
      FinalPositionName: data[i].HorseModelIdData1 ? data[i].HorseModelIdData1.FinalPositionNameEn :null,
      Rank: data[i].HorseModelIdData1 ? data[i].HorseModelIdData1.FinalPositionRank :null,
      Rating:data[i].HorseModelIdData1 ?  Number(data[i].HorseModelIdData1.Rating) : 0,
      HorseID: data[i].HorseModelIdData1 ? data[i].HorseModelIdData1._id :null,
      HorseName: data[i].HorseModelIdData1 ? data[i].HorseModelIdData1.NameEn :null,
      CumulativeDistance: 0,
      Prize: 0,
      Distance: data[i].HorseModelIdData1 ? Number(data[i].HorseModelIdData1.Distance):0,
      VideoLink:videoLink.Link,
      RaceTime: statetime.Time,
      BestTurnOut: data[i].HorseModelIdData1 ? (data[i].HorseModelIdData1.BestTurnOut === null ? StorDatanew?.BestTurnOut.BestTurnOut : data[i].HorseModelIdData1.BestTurnOut) :StorDatanew?.BestTurnOut.BestTurnOut,
      BestTurnPrice: StorDatanew?.BestTurnOutPrice.BestTurnPrice,
      Remarks: data[i].HorseModelIdData1 ?(data[i].HorseModelIdData1.Remarks === null ? "N/A" : data[i].HorseModelIdData1.Remarks) : null,
      BeatenBy: null,
      TrainerOnRace: data[i].TrainerOnRaceData1 ? data[i].TrainerOnRaceData1._id : null,
      JockeyOnRace: data[i].JockeyOnRaceData1 ? data[i].JockeyOnRaceData1._id : null,
    });
  }


  const handleSave = async () => {

    let errorstatements = [];

    try {
      setisLoading(true);

  
      
      for (let i = 0; i < MapGate.length; i++) {
        if (typeof MapGate[i].FinalPosition != "string") {
          errorstatements.push({
            recordnumber: i + 1,
            message: `RecordNo ${i + 1} is missing FinalPosition `,
            
          });
        }
        if (typeof MapGate[i].Rank != "number") {
          errorstatements.push({
            recordnumber: i + 1,
            message: `RecordNo ${i + 1} is missing Rank `,
            
          });
        }
        if (typeof MapGate[i].Distance != "number") {
          errorstatements.push({
            recordnumber: i + 1,
            message: `RecordNo ${i + 1} is missing Distance `,
            
          });
        }
        if (typeof MapGate[i].HorseID != "string") {
          errorstatements.push({
            recordnumber: i + 1,
            message: `RecordNo ${i + 1} is missing HorseID `,
            
          });
        }
        if (typeof MapGate[i].CumulativeDistance != "number") {
          errorstatements.push({
            recordnumber: i + 1,
            message: `RecordNo ${i + 1} is missing CumulativeDistance `,
            
          });
        }
        if (typeof MapGate[i].Prize != "number") {
          errorstatements.push({
            recordnumber: i + 1,
            message: `RecordNo ${i + 1} is missing Prize `,
            
          });
        }
        if (typeof MapGate[i].Rating != "number") {
          errorstatements.push({
            recordnumber: i + 1,
            message: `RecordNo ${i + 1} is missing Rating `,
            
          });
        }
        if (typeof MapGate[i].VideoLink != "string") {
          errorstatements.push({
            recordnumber: i + 1,
            message: `RecordNo ${i + 1} is missing VideoLink `,
            
          });
        }
        if (typeof MapGate[i].RaceTime != "string") {
          errorstatements.push({
            recordnumber: i + 1,
            message: `RecordNo ${i + 1} is missing RaceTime `,
            
          });
        }
        if (typeof MapGate[i].TrainerOnRace != "string") {
          errorstatements.push({
            recordnumber: i + 1,
            message: `RecordNo ${i + 1} is missing TrainerOnRace `,
            
          });
        }
        if (typeof MapGate[i].JockeyOnRace != "string") {
          // errorstatements.push({
          //   recordnumber: i + 1,
          //   message: `RecordNo ${i + 1} is missing JockeyOnRace `,
          //   
          // });
          MapGate[i].JockeyOnRace = null;
        }
        if (typeof MapGate[i].BestTurnOut != "string") {
          errorstatements.push({
            recordnumber: i + 1,
            message: `RecordNo ${i + 1} is missing BestTurnOut `,
            
          });
        }
        if (typeof MapGate[i].BestTurnPrice != "number") {
          errorstatements.push({
            recordnumber: i + 1,
            message: `RecordNo ${i + 1} is missing BestTurnPrice `,
            
          });
        }
      }
  
    
        // const updatedData = MapGate.map((obj) => ({ ...obj }));
      // setData(updatedData);


      await axios.put(
        `${window.env.API_URL}EditRaceResultV3/${RaceId}`, { ResultEntry: MapGate }
      );

      setisLoading(false);

      swal({
        title: "Success",
        text: "Data has been added successfully ",
        icon: "success",
        button: "OK",
      });
      history("/resultannounced");

    } catch (error) {

      swal({
        title: "Error!",
        text:"Error Report Downloaded",
        icon: "error",
        button: "OK",
      });
  
      let data1 =  JSON.stringify(errorstatements)
      const blob = new Blob([data1]);
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.download = "Result Error.json";
      link.href = url;
      link.click();      
      setisLoading(false);

    }
    setisLoading(false);

  };

  function roundToDecimal(number, decimalPlaces) {
    const factor = 10 ** decimalPlaces;
    return Math.round(number * factor) / factor;
  }

  return (
    <div className="page">
      <div className="rightsidedata">
        <div
          style={{
            marginTop: "30px",
          }}
        >
          <div className="Header ">
            <h4>Edit Result</h4>
          </div>
          
          {
            isLoading ?  <Loader /> : 
            <div className="horizontal-scroll-wrapper squares">
            
             <div className="editraceflex1">
              <div>
              <button onClick={handleShowResultimage} className="Approvedbtn editraceimage data12data">Edit Race Imges</button>
                </div>         
         <p className="timeracedata">
         Race Time<input
                     type="text"
                     value={statetime.Time}
                     onChange={handleChange}

                     style={{
                       color: '#000'
                     }}
                     maxLength='8'
                     onKeyPress={(event) => {
                       if (!/^\d*\.?\d*$/
                         .test(event.key)) {
                         event.preventDefault();
                       }
                     }}
                     className="timeracedata"
                     
                   />
         </p>

         <p className="timeracedata">
         Video Link<input
                     type="url"
                     value={videoLink.Link}
                     onChange={handleChangeLink}

                     style={{
                       color: '#000'
                     }}
                   
                     className="timeracedata"
                     
                   />
         </p>
              </div>


          <table className="editformtable">
            <thead>
              <tr>
                <th>Horse Name</th>
                <th>Distance</th>
                <th>Final Position</th>
                <th>Rating</th>
                <th>Remarks</th>




              </tr>
            </thead>

            {
              MapGate.length !== 0 ?
                MapGate.map((obj, index) => (
                  <tbody
                    className="myselectdata resultsdata resultsdata12 "
                    key={obj._id}
                  >
                   
                    <tr>
                      <td>
                        <input
                          type="text"
                          value={obj.HorseName}
                          onChange={(event) =>
                            handleEdit(index, { HorseName: event.target.value })
                          }
                          style={{
                           
                            color: '#000'
                          }}
                          readOnly
                        />
                 
                      </td>
                      <td>
                        <input
                          value={roundToDecimal(obj.Distance, 4)}
                          onChange={(event) =>
                            handleCityChange111(index, { Distance: event.target.value })
                          }
                          type="number"
                          // onKeyPress={(event) => {
                          //   if (!/[0-9.]/.test(event.key)) {
                          //                                     event.preventDefault();
                          //   }
                          // }}
                          style={{
                            color: '#000'
                          }}
                        />
                      </td>
                      <td>
            


                        <Select options={finalposition1}
                          value={{ value: obj.FinalPosition, label: obj.FinalPositionName, Rank:obj.Rank }}
                          onChange={(selectedOption) => handleCityChange1(selectedOption, index)}
                          style={{
                           
                            color: '#000'
                          }}
                          className="finalpositiondata112"
                          />
                      </td>

                      <td>
                        <input
                          type="text"
                          value={obj.Rating}
                          placeholder="Ratings"
                          onChange={(event) =>
                            handleCityChange222(index, { Rating: event.target.value })
                          }
                          onKeyPress={(event) => {
                            if (!/[0-9.]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          style={{
                           
                            color: '#000'
                          }}
                        />
                      </td>

                      <td >
                        <input
                          type="text"
                          value={obj.Remarks}
                          placeholder="Enter Remarks"
                          onChange={(event) =>
                            handleCityChange444(index, { Remarks: event.target.value })
                          }
                          style={{
                           
                            color: '#000'
                          }}
                        />
                      </td>
                  
                    </tr>
                  </tbody>
                )) :  <Lottie
                animationData={HorseAnimation}
                loop={true}
                className="TableLottie"
              />
            }

          </table>
        </div>
          }
          

          <div className="RaceButtonDiv">
            {/* <button className="updateButton" onClick={submit}>
                      Add
                    </button> */}

            <button className="SubmitButton" disabled={isLoading} onClick={handleSave}>
              Update Result
            </button>
          </div>
        </div>
        <div></div>
      </div>

      <Modal
        show={showResultimage}
        onHide={handleCloseResultimage}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2> Upload Images</h2>
        </Modal.Header>
        <Modal.Body>
          <EditImages data1={{RaceId,handleCloseResultimage}} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default EditResult;
