import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import { fetchsilkcolordropdown } from "../../redux/getDropDownReducer/getOwnerSilkColor";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../Components/Common/Loader";


const OwnerColor = () => {
  const { state } = useLocation();
  const { ownerid } = state;
  const [selectedImages, setSelectedImages] = useState([]);
  const [OwnerSlik, setOwnerSlik] = useState([]);
  const { data: silkcolordropdown } = useSelector((state) => state.silkcolordropdown);
  const dispatch = useDispatch();


  const [isLoading, setisLoading] = useState(false);
  const history = useNavigate();

  let AOwnerid = ownerid;
  useEffect(() => {
    dispatch(fetchsilkcolordropdown({ AOwnerid }));
  }, [dispatch, ownerid]);

  const onSelectFile = (event) => {
    const selectedFiles = event.target.files;

    const selectedFilesArray = Array.from(selectedFiles);
    const imagesArray = selectedFilesArray.map((file) => {
      return URL.createObjectURL(file);
    });
    setOwnerSlik(selectedFilesArray);

    // setsetOwnerSlik(imagesArray)
    console.log(selectedFilesArray, 'selectedImages');

    setSelectedImages((previousImages) => previousImages.concat(imagesArray));
    event.target.value = "";
  };

  const UploadSilkColor = async (event) => {
    event.preventDefault();
    setisLoading(true);
    try {
      const formData = new FormData();

      // formData.append("image", OwnerSlik);
      OwnerSlik.forEach((image) => {
        formData.append("image", image);
      });
      await axios.post(
        `${window.env.API_URL}/AddOwnerSilkColor/${ownerid}`,
        formData
      );
      setisLoading(false);
      swal({
        title: "Success!",
        text: "Data has been added Successfully",
        icon: "success",
        button: "OK",
      });
      history("/EditownerCap", {
        state: {
          ownerid: ownerid,
        },
      });
      setisLoading(false);
    } catch (error) {
      const err = error.response.data.message;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
      setisLoading(false);
    }
  };

  function deleteHandler(image) {

    setSelectedImages(selectedImages.filter((e) => e !== image));
    URL.revokeObjectURL(image);

  }


  function skipdata() {
    history("/EditownerCap", {
      state: {
        ownerid: ownerid,
      },
    });
  }
  const handleRemove = async (obj) => {
    setisLoading(true)
    try {
      const response = await axios.delete(`${window.env.API_URL}/DeleteOwnerSilkColor/${obj.OwnerID}/${obj._id}`);
      console.log(response);
      swal({
        title: "Success!",
        text: `${response.data.message}`,
        icon: "success",
        button: "OK",
      });
      dispatch(fetchsilkcolordropdown({ AOwnerid }));
    }
    catch (err) {
      swal({
        title: "Error!",
        text: `${err}`,
        icon: "error",
        button: "OK",
      });
    }
    setisLoading(false)
  };

  return (
    <>
      <div className="page">
        <div className="rightsidedata">
          <div
            style={{
              marginTop: "30px",
            }}
          >
            <div className="Headers">Edit Silk Color</div>
            {isLoading ? <Loader /> : <section className="addsectionimage">
              <label className="AddImages1">
                + Add Images
                <br />
                <span>up to 5 images</span>
                <input
                  type="file"
                  name="images"
                  onChange={onSelectFile}
                  multiple
                  accept="image/png , image/jpeg, image/webp"
                />
              </label>
              <br />

              {selectedImages.length > 0 &&
                (selectedImages.length > 5 ? (
                  <p className="error11">
                    You can't upload more than 5 images! <br />
                    <span>
                      please delete <b> {selectedImages.length - 5} </b> of
                      them
                    </span>
                  </p>
                ) : (
                  <button
                    className="upload-btn111"
                    onClick={UploadSilkColor}
                    disabled={isLoading}
                  >
                    UPLOAD {selectedImages.length} IMAGE
                    {selectedImages.length === 1 ? "" : "S"}
                  </button>
                ))}

              <div className="images111">
                {selectedImages &&
                  selectedImages.map((image, index) => {
                    return (
                      <div key={image} className="image111">
                        <img src={image} height="200" alt="upload" />
                        <button onClick={() => deleteHandler(image)} disabled={isLoading}>
                          delete image
                        </button>
                        <p>{index + 1}</p>
                      </div>
                    );
                  })}
              </div>
            </section>}
          </div>
          <div className="editsilkcolorapi">
            {
              silkcolordropdown.map((item) => {
                return (
                  <div className="editsilkcolorimg">
                    <img src={item.OwnerSilkColor} alt="" />
                    <button
                      onClick={() => handleRemove(item)}
                    >Delete</button>
                  </div>
                );
              })
            }
          </div>
          <button className="skipbutton" onClick={skipdata}>
            Skip
          </button>
        </div>
      </div>
    </>
  );
};

export default OwnerColor;
