import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";

import { fetchracecourse } from "../../redux/getReducer/getRaceCourseSlice";
import { useDispatch, useSelector } from "react-redux";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { fetchgroundtype } from "../../redux/getReducer/getGroundType";
import { Modal } from "react-bootstrap";
import { AiOutlineReload } from "react-icons/ai";
import { ImCross } from "react-icons/im";
import RaceCoursePopup from "./RaceCourseForm";
import GroundTypePopup from "./GroundType";
import { fetchgroundtypelist } from "../../redux/getDropDownReducer/getGroundList";
import Loader from "../../Components/Common/Loader";
import { Input, Form, Select } from "antd";

const Tracklengthform = ({ setShowTrackLength }) => {
  //----------------------------state for errors -------------------------//





  const [isLoading, setisLoading] = useState(false);


  const [showRaceCourse, setShowRaceCourse] = useState(false);


  const dispatch = useDispatch();

  const { data: GroundList } = useSelector((state) => state.GroundList);
  const { data: racecourse } = useSelector((state) => state.racecourse);
  const [option, setOptions] = useState(GroundList)

  const [showGroundType, setShowGroundType] = useState(false);
  const [showActiveRaceCourse, setShowActiveRaceCourse] = useState(false);
  const [Value1, setValue1] = useState("");


  const handleCloseGroundType = () => setShowGroundType(false);
  const handleCloseRaceCourse = () => setShowRaceCourse(false);
  const handleCloseActiveRaceCourse = () => setShowActiveRaceCourse(false);
  const handleShowRaceCourse = async () => {
    await setShowRaceCourse(true);
  };

  const handleShowGroundType = async () => {
    await setShowGroundType(true);
  };
  const { Option } = Select;
  //----------------------------options for dropdown -------------------------//



  const [TrackLength, setTrackLength] = useState("");
  const [RaceCourseImage, setRaceCourseImage] = useState("");
  const [Racecourse, setRaceCourse] = useState("");
  const [GroundTypes, setGroundType] = useState("");
  const [preview, setPreview] = useState("");
  const [RailPosition, setRailPosition] = useState("");

  const { pathname } = useLocation();
  const history = useNavigate();






  useEffect(() => {

    dispatch(fetchracecourse({ Value1 }));
  }, [dispatch, Value1])

  //----------------------------submit-------------------------//
  const submit = async () => {

    setisLoading(true);
    try {
      const formData = new FormData();
      formData.append("TrackLength", TrackLength);
      formData.append("image", RaceCourseImage);
      formData.append("GroundType", GroundTypes);
      formData.append("RailPosition", RailPosition);
      formData.append("RaceCourse", Racecourse);

      await axios.post(`${window.env.API_URL}/uploadTrackLength`, formData);
      swal({
        title: "Success!",
        text: "Data has been added successfully ",
        icon: "success",
        button: "OK",
      });
      if (pathname === "/tracklengthform") {
        history("/tracklength");
      } setShowTrackLength(false)

    } catch (error) {
      const err = error.response.data.message;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
      setisLoading(false);
    }




  };



  const handleSearch = async (value) => {
    try {
      // Filtering the NationalityList based on the search value
      const filteredNationalities = GroundList.filter(
        (item) =>
          item.NameEn.toLowerCase().includes(value.toLowerCase()) ||
          item.NameAr.includes(value)
      );
      setOptions(filteredNationalities);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // ... (rest 
  const handleSearch1 = async (Value1) => {

    setValue1(Value1)


  };
  const handleChange1 = async (value) => {
    await setRaceCourse(value)
    setValue1(value)

  };

  const handleChange = async (value) => {
    await setGroundType(value)

  };

  const onSelectFile = (e) => {
    setRaceCourseImage(e.target.files[0]);
  };
  //----------------------------image preview -------------------------//
  useEffect(() => {
    dispatch(fetchgroundtypelist());


    if (!RaceCourseImage) {
      setPreview(undefined);
      return;
    }

    if (!RaceCourseImage.name.match(/\.(gif)$/)) {
      setRaceCourseImage("");
      setPreview(undefined);
      document.getElementById("file").value = "";
      swal({
        title: "Error!",
        text: "Enter Gif Image",
        icon: "error",
        button: "OK",
      });
    }

    const objectUrl = URL.createObjectURL(RaceCourseImage);
    setPreview(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [RaceCourseImage, dispatch]);

  const handlePreview = () => {
    setRaceCourseImage();
    document.getElementById("file").value = "";
  };
  const SearchTitle = ""
  const SearchCode = ""
  const SearchAge = ""

  const FetchNew = () => {
    dispatch(fetchgroundtype({ SearchTitle, SearchCode, SearchAge }));
    dispatch(fetchracecourse({ SearchTitle, SearchCode, SearchAge }));
  };

  if (GroundTypes.value === "Dirt") {
    var zero = 0;
  }
  const [form] = Form.useForm();


  const initialValues = {
    RailPosition: 1,
  };



  return (
    <div className="page">
      <div className="rightsidedata">
        <div
          style={{
            marginTop: "30px",
          }}
        >
          <div className="Headers">Create Track Length</div>
          {isLoading ? <Loader /> : <div className="form">
            <Form onFinish={submit} initialValues={initialValues} form={form}>
              <div className="row mainrow">
                <div className="col-sm">






                  <Form.Item
                    name="TrackLength"
                    label="Track  Length"
                    rules={[
                      {
                        required: true,
                        message: 'TrackLength  is required',
                      },
                    ]}
                  >
                    <Input placeholder="TrackLength" onChange={(e) => setTrackLength(e.target.value)}
                      value={TrackLength} name="TrackLength" type="number" />
                  </Form.Item>









                </div>
              </div>
              <div className="row mainrow">
                <div className="col-sm">


                  <Form.Item

                    name="select1"
                    label="Ground Type"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: 'Please select your Ground Type!',
                      },
                    ]}
                  >
                    <Select

                      filterOption={false}
                      onChange={handleChange}

                      onSearch={handleSearch}
                      showSearch placeholder="Please select a Ground Type" >

                      {
                        option?.map((item) => (


                          <Option value={item._id}>{item.NameEn} {"(" + item.NameAr + ")"}</Option>

                        ))
                      }
                    </Select>
                  </Form.Item>

                  <span className="spanForm selectSpacer">
                    <OverlayTrigger
                      overlay={<Tooltip id={`tooltip-top`}>Add more</Tooltip>}
                    >
                      <span className="addmore" onClick={handleShowGroundType}>
                        +
                      </span>
                    </OverlayTrigger>
                    <OverlayTrigger
                      overlay={<Tooltip id={`tooltip-top`}>Fetch New</Tooltip>}
                    >
                      <span className="addmore" onClick={FetchNew}>
                        <AiOutlineReload />
                      </span>
                    </OverlayTrigger>
                  </span>


                </div>

              </div>

              <div className="row mainrow">
                <div className="col-sm">





                  <Form.Item
                    name="RailPosition"
                    label={zero === undefined ? "Rail Position" : zero}
                    rules={[
                      {
                        required: true,
                        message: 'Rail Position is required',
                      },
                    ]}
                  >
                    <Input
                      maxLength='4'
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onPaste={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }} placeholder="Rail Position" onChange={(e) => setRailPosition(e.target.value)}
                      value={RailPosition} name="RailPosition" />
                  </Form.Item>







                </div>
              </div>
              <div className="row mainrow">
                <div className="col-sm">


                  <Form.Item

                    name="select"
                    label="Race Course"

                    rules={[
                      {
                        required: true,
                        message: 'Please select your Race Course Course !',
                      },
                    ]}
                  >
                    <Select

                      onChange={handleChange1}
                      value={Racecourse}
                      filterOption={false}
                      onSearch={handleSearch1}
                      showSearch placeholder="Please select a Race Course" >

                      {
                        racecourse?.map((item) => (


                          <Option value={item._id}>{item.TrackNameEn} {"(" + item.TrackNameAr + ")"}</Option>

                        ))
                      }
                    </Select>
                  </Form.Item>



                  <span className="spanForm selectSpacer">
                    <OverlayTrigger
                      overlay={<Tooltip id={`tooltip-top`}>Add more</Tooltip>}
                    >
                      <span
                        className="addmore"
                        onClick={handleShowRaceCourse}
                      >
                        +
                      </span>
                    </OverlayTrigger>
                    <OverlayTrigger
                      overlay={
                        <Tooltip id={`tooltip-top`}>Fetch New</Tooltip>
                      }
                    >
                      <span className="addmore" onClick={FetchNew}>
                        <AiOutlineReload />
                      </span>
                    </OverlayTrigger>{" "}
                  </span>


                </div>

              </div>

              <div className="ButtonSection">
                <div >
                  <label className="Multipleownerlabel">Select GIF Image</label>
                  <input
                    type="file"
                    onChange={onSelectFile}
                    className="formInput"
                    id="file"
                  />
                  {RaceCourseImage && (
                    <>
                      <ImCross onClick={handlePreview} className="crossIcon" />
                      <img src={preview} className="PreviewImage" alt="" />
                    </>
                  )}
                </div>

                <button
                  type="submit"
                  className="SubmitButton"
                  disabled={isLoading}
                >
                                      Save

                </button>
              </div>
            </Form>
          </div>}
        </div>
      </div>
      <Modal
        show={showGroundType}
        onHide={handleCloseGroundType}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2>Ground Type</h2>
        </Modal.Header>
        <Modal.Body>
          <GroundTypePopup setShowGroundType={setShowGroundType} />
        </Modal.Body>
      </Modal>
      <Modal
        show={showActiveRaceCourse}
        onHide={handleCloseActiveRaceCourse}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2>RaceCourse</h2>
        </Modal.Header>
        <Modal.Body>
          <RaceCoursePopup />
        </Modal.Body>
      </Modal>
      <Modal
        show={showRaceCourse}
        onHide={handleCloseRaceCourse}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2>Create Race Course</h2>
        </Modal.Header>
        <Modal.Body>
          <RaceCoursePopup setShowRaceCourse={setShowRaceCourse} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Tracklengthform;
