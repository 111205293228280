import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { ImCross } from "react-icons/im";
import { useNavigate, useLocation } from "react-router-dom";
import { fetchNationalityList } from "../../redux/getDropDownReducer/getNationalityList";
import { useSelector } from "react-redux";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { AiOutlineReload } from "react-icons/ai";
import { Modal } from "react-bootstrap";
import "../../Components/CSS/forms.css";
import swal from "sweetalert";
import axios from "axios";



import { Form, Input, Select } from 'antd';

import NationalityPopup from "../PostTable/Nationality";
import Loader from "../../Components/Common/Loader";
const EditTrainer = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [isLoading, setisLoading] = useState(false)
  const [isLoading1, setisLoading1] = useState(false);
  const [form] = Form.useForm();


  const { data: NationalityList } = useSelector(
    (state) => state.NationalityList
  );
  const [TrainerLicenseDate, setTrainerLicenseDate] = useState("");
  const [NationalityID, setNationalityID] = useState("");
  const [showActivenationality, setShowNationality] = useState(false);
  const handleCloseActivenationality = () => setShowNationality(false);

  const handleShowActivenationality = async () => {
    await setShowNationality(true);
  };
  const FetchNew = () => {
    dispatch(fetchNationalityList());
  };

  const [DOB, setDOB] = useState("");
  var today = new Date();

  const { trainerid } = state;

  const [state1, setState] = useState({
    NameEn: "",
    NameAr: "",
    TitleEn: "",
    TitleAr: "",
    ShortNameEn: "",
    ShortNameAr: "",
    RemarksEn: "",
    RemarksAr: "",
    DetailEn: "",
    DetailAr: "",
    TrainerLicenseDate: "",
    DOB: "",
    NationalityID: "",
    shortCode:""
  });

  const [preview, setPreview] = useState();

  const [image, setImage] = useState();

  const fileSelected = (event) => {
    const image = event.target.files[0];
    setImage(image);
  };
  useEffect(() => {
    dispatch(fetchNationalityList());
  }, [dispatch]);
  //---------------------------get value for dropdown---------------------------//
  let AllNationality =
    NationalityList === undefined ? (
      <></>
    ) : (
      NationalityList.map(function (item) {
        return {
          id: item._id,
          value: item._id,
          label: (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <p>{item.NameEn}</p>
              <p>{item.NameAr}</p>
            </div>
          ),
        };
      })
    );
  //---------------------------remove preview---------------------------//
  const handlePreview = () => {
    setImage();
    setPreview();
    setisLoading1(true)
    document.getElementById("file").value = "";
  };
  const handleSearch = async (value) => {
    try {
      dispatch(fetchNationalityList({ value }))

    } catch (error) {

    }
  };
  //---------------------------get data from previous state---------------------------//

  useEffect(() => {
    if (trainerid) {
      setState({
        NameEn: trainerid.NameEn,
        NameAr: trainerid.NameAr,
        TitleEn: trainerid.TitleEn,
        TitleAr: trainerid.TitleAr,
        ShortNameEn: trainerid.ShortNameEn,
        ShortNameAr: trainerid.ShortNameAr,
        RemarksEn: trainerid.RemarksEn,
        nationalityName: trainerid.TrainerNationalityData ? trainerid.TrainerNationalityData.NameEn : null,
        RemarksAr: trainerid.RemarksAr,
        DetailEn: trainerid.DetailEn,
        DetailAr: trainerid.DetailAr,
        TrainerLicenseDate: trainerid.TrainerLicenseDate,
        NationalityID: trainerid.NationalityID,
        DOB: trainerid.DOB,
        shortCode:trainerid?.shortCode
      });

      console.log(trainerid, "trainer");


      let day123 = new Date(trainerid.TrainerLicenseDate);

      console.log(day123, "day123day123")
      let daydate = day123.getDate();
      if (daydate < 10) {
        daydate = `0${day123.getDate()}`;
      }
      let daymonth = day123.getMonth() + 1;
      if (daymonth < 10) {
        daymonth = `0${day123.getMonth() + 1}`;
      }
      let dayformat = `${day123.getFullYear()}-${daymonth}-${daydate}`;
      console.log(dayformat, "dayformat");

      setTrainerLicenseDate(dayformat);



      let day1231 = new Date(trainerid.DOB);

      console.log(day1231, "day123day123day123day123 1")
      let daydate1 = day1231.getDate();
      if (daydate1 < 10) {
        daydate1 = `0${day1231.getDate()}`;
      }
      let daymonth1 = day1231.getMonth() + 1;
      if (daymonth1 < 10) {
        daymonth1 = `0${day1231.getMonth() + 1}`;
      }
      let dayformat1 = `${day1231.getFullYear()}-${daymonth1}-${daydate1}`;
      console.log(dayformat1, "dayformat 12 ");

      setDOB(dayformat1);





    } else {
    }
  }, [trainerid]);



  const { Option } = Select;


  const handleChange = async (value) => {

    await setNationalityID(value)
    setState({ ...state1, nationalityName: value })

  };


  //---------------------------image preview---------------------------//
  useEffect(() => {
    if (image === undefined) {
      setPreview(trainerid.image);
      return;
    }
    const objectUrl = URL.createObjectURL(image);
    setPreview(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [image, trainerid.image]);
  //---------------------------submit---------------------------//
  const submit = async () => {

    try {
      setisLoading(true)
     
      const formData = new FormData();
      formData.append("image", image);
      formData.append("NameEn", state1.NameEn);
      formData.append("NameAr", state1.NameAr);
      formData.append("TitleEn", state1.TitleEn);
      formData.append("TitleAr", state1.TitleAr);
      formData.append("RemarksEn", state1.RemarksEn);
      formData.append("RemarksAr", state1.RemarksAr);
      formData.append("ShortNameEn", state1.ShortNameEn);
      formData.append("ShortNameAr", state1.ShortNameAr);
      formData.append("DetailEn", state1.DetailEn);
      formData.append("DetailAr", state1.DetailAr);
      formData.append("shortCode", state1.shortCode);
      formData.append("TrainerLicenseDate", TrainerLicenseDate);
      formData.append(
        "NationalityID",
        NationalityID === undefined ? state1.NationalityID : NationalityID
      );

      formData.append("DOB", DOB);
      if (isLoading1 === true && image === undefined) {
        formData.append("deleteexisitingimage", isLoading1)
      }

      await axios.put(
        `${window.env.API_URL}/updatetrainer/${trainerid._id}`,
        formData
      );
      history("/trainer");
      swal({
        title: "Success!",
        text: "Data has been Updated successfully ",
        icon: "success",
        button: "OK",
      });
    } catch (error) {
      const err = error.response.data.message;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
    }
    setisLoading(false)
  };

  form.setFieldsValue({
    NameEn: state1.NameEn,
    NameAr: state1.NameAr,
    TitleEn: state1.TitleEn,
    TitleAr: state1.TitleAr,
    ShortEn: state1.ShortNameEn,
    ShortAr: state1.ShortNameAr,
    Description1: state1.DetailEn,
    DescriptionAr: state1.DetailAr,
    RemarksEn: state1.RemarksEn,
    RemarksAr: state1.RemarksAr,
    shortCode:state1.shortCode,
    nationalityName: state1.nationalityName

  });

  return (
    <>
      <div className="page">
        <div className="rightsidedata">
          <div
            style={{
              marginTop: "30px",
            }}
          >
            <div className="Headers">Edit Trainer</div>
            {isLoading ? <Loader /> : <div className="form">
              <Form onFinish={submit} form={form}>
                <div className="row mainrow">
                  <div className="col-sm">
                    <Form.Item
                      name="NameEn"
                      label="Name"
                      rules={[
                        {
                          required: true,
                          message: 'Name (English) is required',
                        },
                      ]}
                    >
                      <Input placeholder="Name" onChange={(e) =>
                        setState({ ...state1, NameEn: e.target.value })
                      }
                        value={state1.NameEn} name="NameEn" />
                    </Form.Item>


                    <span className="spanForm spacer"> |</span>
                  </div>

                  <div className="col-sm">

                    <Form.Item
                      name="NameAr"
                      label="اسم"
                      rules={[
                        {
                          required: true,
                          message: 'Name (Arabic) is required',
                        },
                      ]}
                      style={{ direction: 'rtl' }}
                    >
                      <Input placeholder="اسم" onChange={(e) =>
                        setState({ ...state1, NameAr: e.target.value })
                      }
                  
                        value={state1.NameAr} />
                    </Form.Item>
                  </div>
                </div>






                <div className="row mainrow">
                  <div className="col-sm">
                    <Form.Item
                      name="ShortEn"
                      label="Short Name"
                      rules={[
                        {
                          required: true,
                          message: 'Short Name (English) is required',
                        },
                      ]}
                    >
                      <Input placeholder="Short Name" onChange={(e) =>
                        setState({ ...state1, ShortNameEn: e.target.value })
                      }
                        value={state1.ShortEn} name="ShortEn" />
                    </Form.Item>


                    <span className="spanForm spacer"> |</span>
                  </div>

                  <div className="col-sm">

                    <Form.Item
                      name="ShortAr"
                      label="اسم قصير"
                      rules={[
                        {
                          required: true,
                          message: 'Name (Arabic) is required',
                        },
                      ]}
                      style={{ direction: 'rtl' }}
                    >
                      <Input placeholder="اسم قصير" onChange={(e) =>
                        setState({ ...state1, ShortNameAr: e.target.value })
                      }
                        name="ShortAr"
                        value={state1.ShortAr} />
                    </Form.Item>
                  </div>
                </div>



                <div className="row mainrow">
                  <div className="col-sm">





                    <Form.Item
                      name="TitleEn"
                      label="Title"
                      rules={[
                        {
                          required: true,
                          message: 'Title (English) is required',
                        },
                      ]}
                    >
                      <Input placeholder="Title" onChange={(e) =>
                        setState({ ...state1, TitleEn: e.target.value })
                      }
                        value={state1.TitleEn} name="NameEn" />
                    </Form.Item>






                    <span className="spanForm spacer"> |</span>
                  </div>

                  <div className="col-sm">



                    <Form.Item
                      name="TitleAr"
                      label="عنوان"
                      rules={[
                        {
                          required: true,
                          message: 'Title (Arabic) is required',
                        },
                      ]}
                      style={{ direction: 'rtl' }}
                    >
                      <Input placeholder="عنوان" onChange={(e) =>
                        setState({ ...state1, TitleAr: e.target.value })
                      }
                        name="TitleAr"
                        value={state1.TitleAr} />
                    </Form.Item>











                  </div>
                </div>

                <div className="row mainrow">
                  <div className="col-sm">

                    <Form.Item
                      name="Description1"
                      label="Description"
                      rules={[
                        {
                          required: true,
                          message: 'Description (English) is required',
                        },
                      ]}
                    >
                      <Input placeholder="Description" onChange={(e) =>
                        setState({ ...state1, DetailEn: e.target.value })
                      }
                        value={state1.DetailEn} name="Description1" />
                    </Form.Item>












                    <span className="spanForm spacer"> |</span>
                  </div>

                  <div className="col-sm">








                    <Form.Item
                      name="DescriptionAr"
                      label="وصف"
                      rules={[
                        {
                          required: true,
                          message: 'Description (Arabic) is required',
                        },
                      ]}
                      style={{ direction: 'rtl' }}
                    >
                      <Input placeholder="وصف" onChange={(e) =>
                        setState({ ...state1, DetailAr: e.target.value })
                      }
                        name="DescriptionAr"
                        value={state1.DetailAr} />
                    </Form.Item>




                  </div>
                </div>





                <div className="row mainrow">
                  <div className="col-sm">

                    <Form.Item
                      name="RemarksEn"
                      label="Remarks"

                    >
                      <Input placeholder="Remarks" onChange={(e) =>
                        setState({ ...state1, RemarksEn: e.target.value })
                      }
                        value={state1.RemarksEn} name="RemarksEn" />
                    </Form.Item>












                    <span className="spanForm spacer"> |</span>
                  </div>

                  <div className="col-sm">








                    <Form.Item
                      name="RemarksAr"
                      label="ملاحظات"

                      style={{ direction: 'rtl' }}
                    >
                      <Input placeholder="ملاحظات" onChange={(e) =>
                        setState({ ...state1, RemarksAr: e.target.value })
                      }
                        name="RemarksAr"
                        value={state1.RemarksAr} />
                    </Form.Item>




                  </div>
                </div>






                <div className="row mainrow ">
                  <p className="selectLabel">Trainer license Date</p>
                  <input
                    type="date"
                    data-placeholder="Trainer license Date"
                    onChange={(e) => setTrainerLicenseDate(e.target.value)}
                    value={TrainerLicenseDate}
                    className="dateforraces"
                    required
                  />
                  {/* <DatePicker
                    onChange={setTrainerLicenseDate}
                    value={TrainerLicenseDate}
                    dayPlaceholder=""

                    maxDate={today}
                    monthPlaceholder={state1.TrainerLicenseDate && state1.TrainerLicenseDate.split("T")[0]}
                    yearPlaceholder=""
                    className="editDate"
                  /> */}

                </div>
                <div className="row mainrow">
                  <p className="selectLabel">Date of Birth</p>

                  <input
                    type="date"
                    data-placeholder="Date of Birth"
                    onChange={(e) => setDOB(e.target.value)}
                    value={DOB}
                    className="dateforraces"
                    required
                  />





                </div>
                <div className="row mainrow edit">

                  <div className="col-sm">
                    <Form.Item

                      name="nationalityName"
                      label="Nationality"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: 'Please select your Nationality!',
                        },
                      ]}
                    >
                      <Select
                        value={NationalityID}
                        filterOption={false}
                        defaultValue={state.nationalityName}
                        onChange={handleChange}
                        onSearch={handleSearch}
                        showSearch placeholder="Please select a Nationality" >

                        {
                          NationalityList?.map((item) => (


                            <Option value={item._id}>{item.NameEn} ({item.NameAr})</Option>

                          ))
                        }
                      </Select>
                    </Form.Item>
                    <span className="spanForm">
                      <OverlayTrigger
                        overlay={<Tooltip id={`tooltip-top`}>Add more</Tooltip>}
                      >
                        <span
                          className="addmore"
                          onClick={handleShowActivenationality}
                        >
                          +
                        </span>
                      </OverlayTrigger>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id={`tooltip-top`}>Fetch New</Tooltip>
                        }
                      >
                        <span className="addmore" onClick={FetchNew}>
                          <AiOutlineReload />
                        </span>
                      </OverlayTrigger>
                    </span>
                  </div>
                </div>


                <div className="row mainrow">
                    <div className="col-sm">
                      <Form.Item
                        name="shortCode"
                        label="Short Code"
                        rules={[
                          {
                            required: true,
                            message: "short Code is required"
                          },
                        ]}
                      >
                        <Input
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          onChange={(e) =>
                            setState({ ...state1, shortCode: e.target.value })
                          }
                          value={state1.shortCode}
                          onPaste={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          placeholder="Short Code"
                          maxLength="5"
                        />
                      </Form.Item>
                    </div>
                  </div>


                <div className="ButtonSection">
                  <div className={image === undefined ? "inputFile" : ""}>
                    <label className="Multipleownerlabel">
                      Select Trainer image
                    </label>
                    <input
                      type="file"
                      onChange={fileSelected}
                      className="formInput"
                      id="file"
                    />
                    {preview && (
                      <>
                        <ImCross
                          onClick={handlePreview}
                          className="crossIcon2"
                        />
                        <img src={preview} className="PreviewImage" alt="" />
                      </>
                    )}
                  </div>
                  <div>
                    <button type="submit" className="SubmitButton" disabled={isLoading}>
                      Update Trainer
                    </button>
                  </div>
                </div>
              </Form>
            </div>}
          </div>
        </div>
      </div>
      <Modal
        show={showActivenationality}
        onHide={handleCloseActivenationality}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2>Nationality</h2>
        </Modal.Header>
        <Modal.Body>
          <NationalityPopup setShowNationality={setShowNationality} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditTrainer;
