import React, { useState, useEffect } from "react";
import "../../Components/CSS/forms.css";
import { useNavigate, useLocation } from "react-router-dom";
import swal from "sweetalert";
import axios from "axios";

import { Form, Input ,Select} from 'antd';
import { useSelector, useDispatch } from "react-redux";
import { ImCross } from "react-icons/im";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { fetchNationalityList } from "../../redux/getDropDownReducer/getNationalityList";
import { fetchColorDropdown } from "../../redux/getDropDownReducer/getColor";

import { AiOutlineReload } from "react-icons/ai";
import { Modal } from "react-bootstrap";
import NationalityPopup from "../PostTable/Nationality";
import ColorPopup from "../PostTable/Color";
import Loader from "../../Components/Common/Loader";

const EditRaceCourse = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { data: NationalityList } = useSelector(
    (state) => state.NationalityList
  );
  const { data: colordropdown } = useSelector((state) => state.colordropdown);
  const { Option } = Select;

  const [NationalityID, setNationalityID] = useState("");
  const [ColorID, setColor] = useState("");
  const [isLoading, setisLoading] = useState(false)
  const [showActivenationality, setShowNationality] = useState(false);
  const [showColor, setShowColor] = useState(false);

  const [ErrorColor, setErrorColor] = useState("");
  const [ErrorNationality, setErrorNationality] = useState("");
  const [isLoading1, setisLoading1] = useState(false);

  //---------------------------show Popups---------------------------//

  const handleCloseActivenationality = () => setShowNationality(false);
  const handleCloseColor = () => setShowColor(false);

  const handleShowActivenationality = async () => {
    await setShowNationality(true);
  };
  const handleShowColor = async () => {
    await setShowColor(true);
  };
  const FetchNew = () => {
    dispatch(fetchNationalityList());
    dispatch(fetchColorDropdown());
  };

  useEffect(() => {
    dispatch(fetchColorDropdown());
    dispatch(fetchNationalityList());

  }, [])

  const history = useNavigate();
  const { state } = useLocation();

  const { courseid } = state;
  const [image, setImage] = useState();
  const [preview, setPreview] = useState();

  const [state1, setState] = useState({
    TrackNameEn: "",
    TrackNameAr: "",
    shortCode: "",
    AbbrevEn: "",
    AbbrevAr: "",
    NationalityID: "",
    ColorID: "",
    Color: "",
    image: image,
  });

  console.log(courseid)
  //---------------------------get Data from previous State---------------------------//
  useEffect(() => {
    if (courseid) {
      setState({
        TrackNameEn: courseid.TrackNameEn,
        TrackNameAr: courseid.TrackNameAr,
        AbbrevEn: courseid.AbbrevEn,
        AbbrevAr: courseid.AbbrevAr,
        shortCode: courseid.shortCode,
        NationalityID: courseid.NationalityID,
        ColorID: courseid.ColorCode,
        Color: courseid.Color,
        nationalityName: courseid.NationalityDataRaceCourse && courseid.NationalityDataRaceCourse.NameEn,
        colorName: courseid.ColorCodeData && courseid.ColorCodeData.NameEn,

      });
    } else {
    }
  }, [courseid]);
  //---------------------------Image Preview---------------------------//
  useEffect(() => {
    if (image === undefined) {
      setPreview(courseid.image);
      return;
    }
    const objectUrl = URL.createObjectURL(image);
    setPreview(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [courseid.image, image]);
  const fileSelected = (event) => {
    const image = event.target.files[0];
    setImage(image, image);
  };


  const handleChange = async (value) => {

    await setNationalityID(value)
    setState({ ...state1, nationalityName: value })

  };

  const handleSearch = async (value) => {
    try {
      dispatch(fetchNationalityList({ value }))

    } catch (error) {

    }
  };


  
  const handleChange1 = async (value) => {

    await setColor(value)
    setState({ ...state1, colorName: value })

  };

  const handleSearch1 = async (Value2) => {
    try {
      dispatch(fetchColorDropdown({ Value2 }))

    } catch (error) {

    }
  };






  const handlePreview = () => {
    setImage();
    setPreview();
    setisLoading1(true)
    document.getElementById("file").value = "";
  };
  //---------------------------submit---------------------------//
  const submit = async () => {

    try {
      setisLoading(true)
      const formData = new FormData();
      formData.append("image", image);
      formData.append("TrackNameEn", state1.TrackNameEn);
      formData.append("AbbrevEn", state1.AbbrevEn);
      formData.append("AbbrevAr", state1.AbbrevAr);
      formData.append("TrackNameAr", state1.TrackNameAr + " ");

      formData.append("Color", state1.Color)
      formData.append(
        "NationalityID",
        NationalityID === undefined ? state1.NationalityID : NationalityID
      );
      formData.append(
        "ColorCode",
        ColorID === undefined ? state1.ColorID : ColorID
      );
      if (isLoading1 === true && image === undefined) {
        formData.append("deleteexisitingimage", isLoading1)
      }

      await axios.put(
        `${window.env.API_URL}/updatecourse/${courseid._id}`,
        formData
      );
      history("/racecourse");
      swal({
        title: "Success!",
        text: "Data has been Updated successfully",
        icon: "success",
        button: "OK",
      });
    } catch (error) {
      const err = error.response.data.message;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
    }
    setisLoading(false)
  };
  let AllNationality =
    NationalityList === undefined ? (
      <></>
    ) : (
      NationalityList.map(function (item) {
        return {
          id: item._id,
          value: item._id,
          label: item.NameEn,
        };
      })
    );


  let AllColor =
    colordropdown === undefined ? (
      <></>
    ) : (
      colordropdown.map(function (item) {
        return {
          id: item._id,
          value: item.NameEn,
          label: item.NameEn,
        };
      })
    );

  form.setFieldsValue({
    NameEn: state1.NameEn,
    TrackNameEn:state1.TrackNameEn,
    TrackNameAr:state1.TrackNameAr,
    Abbreviation:state1.AbbrevEn,
    AbbreviationAr:state1.AbbrevAr,
    RailPosition: state1.RailPosition,
    RaceCourseName: state1.RaceCourseName,
    GroundTypeName: state1.GroundTypeName,
    nationalityName:state1.nationalityName,
    colorName:state1.colorName
  });
  return (
    <>
      <div className="page edit">
        <div className="rightsidedata">
          <div
            style={{
              marginTop: "30px",
            }}
          >
            <div className="Headers">Edit Race Course</div>
            {isLoading ? <Loader /> : <div className="form">
              <Form onFinish={submit} form={form}>
                <div className="row mainrow">
                  <div className="col-sm">

                    <Form.Item
                      name="TrackNameEn"
                      label="Race Course Name"
                      rules={[
                        {
                          required: true,
                          message: 'Race Course Name (English) is required',
                        },
                      ]}
                    >
                      <Input placeholder="Race Course Name" onChange={(e) =>
                        setState({ ...state1, TrackNameEn: e.target.value })
                      }
                        value={state1.TrackNameEn} name="TrackNameEn" />
                    </Form.Item>




                    <span className="spanForm spacer"> |</span>
                  </div>

                  <div className="col-sm">

                    <Form.Item
                      name="TrackNameAr"
                      label="اسم دورة السباق"
                      rules={[
                        {
                          required: true,
                          message: 'Race Course Name (Arabic) is required',
                        },
                      ]}
                      style={{ direction: 'rtl' }}
                    >
                      <Input placeholder="اسم دورة السباق" onChange={(e) =>
                        setState({ ...state1, TrackNameAr: e.target.value })
                      }
                        name="TrackNameEn"
                        value={state1.TrackNameAr} />
                    </Form.Item>


                  </div>
                </div>
                <div className="row mainrow">
                  <div className="col-sm">

                    <Form.Item
                      name="Abbreviation"
                      label="Abbreviation"
                      rules={[
                        {
                          required: true,
                          message: 'Abbreviation is required',
                        },
                      ]}
                    >
                      <Input placeholder="Abbreviation"

                        onChange={(e) =>
                          setState({ ...state1, AbbrevEn: e.target.value })
                        }

                        value={state1.AbbrevEn} />
                    </Form.Item>

                    <span className="spanForm spacer"> |</span>
                  </div>

                  <div className="col-sm">
                    <Form.Item
                      name="AbbreviationAr"
                      label="اكتب الاختصار"
                      rules={[
                        {
                          required: true,
                          message: 'Abbreviation (Arabic) is required',
                        },
                      ]}
                      style={{ direction: 'rtl' }}
                    >
                      <Input placeholder="اكتب الاختصار" onChange={(e) =>
                        setState({ ...state1, AbbrevAr: e.target.value })
                      }
                        value={state1.AbbrevAr}




                      />
                    </Form.Item>
                  </div>
                </div>
                <div className="row mainrow">

<div className="col-sm">
  <Form.Item

    name="colorName"
    label="Color"
    hasFeedback
    rules={[
      {
        required: true,
        message: 'Please select your Color!',
      },
    ]}
  >
    <Select
      value={ColorID}
      filterOption={false}
      defaultValue={state.colorName}
      onChange={handleChange1}
      onSearch={handleSearch1}
      showSearch placeholder="Please select a Color" >

      {
        colordropdown?.map((item) => (


          <Option value={item._id}>{item.NameEn} ({item.NameAr})</Option>

        ))
      }
    </Select>
  </Form.Item>
  <span className="spanForm">
    <OverlayTrigger
      overlay={<Tooltip id={`tooltip-top`}>Add more</Tooltip>}
    >
      <span
        className="addmore"
        onClick={handleShowColor}
      >
        +
      </span>
    </OverlayTrigger>
    <OverlayTrigger
      overlay={
        <Tooltip id={`tooltip-top`}>Fetch New</Tooltip>
      }
    >
      <span className="addmore" onClick={FetchNew}>
        <AiOutlineReload />
      </span>
    </OverlayTrigger>
  </span>
</div>
               </div>

              
                <div className="row mainrow">

<div className="col-sm">
  <Form.Item

    name="nationalityName"
    label="Nationality"
    hasFeedback
    rules={[
      {
        required: true,
        message: 'Please select your Nationality!',
      },
    ]}
  >
    <Select
      value={NationalityID}
      filterOption={false}
      defaultValue={state.nationalityName}
      onChange={handleChange}
      onSearch={handleSearch}
      showSearch placeholder="Please select a Nationality" >

      {
        NationalityList?.map((item) => (


          <Option value={item._id}>{item.NameEn} ({item.NameAr})</Option>

        ))
      }
    </Select>
  </Form.Item>
  <span className="spanForm">
    <OverlayTrigger
      overlay={<Tooltip id={`tooltip-top`}>Add more</Tooltip>}
    >
      <span
        className="addmore"
        onClick={handleShowActivenationality}
      >
        +
      </span>
    </OverlayTrigger>
    <OverlayTrigger
      overlay={
        <Tooltip id={`tooltip-top`}>Fetch New</Tooltip>
      }
    >
      <span className="addmore" onClick={FetchNew}>
        <AiOutlineReload />
      </span>
    </OverlayTrigger>
  </span>
</div>
               </div>

                <div className="ButtonSection">
                  <div className={image === undefined ? "inputFile" : ""}>
                    <label className="Multipleownerlabel">
                      Select Race Course image
                    </label>
                    <input
                      type="file"
                      onChange={fileSelected}
                      className="formInput"
                      id="file"
                    />
                    {preview && (
                      <>
                        <ImCross
                          onClick={handlePreview}
                          className="crossIcon"
                        />
                        <img src={preview} className="PreviewImage" alt="" />
                      </>
                    )}
                  </div>
                  <div>
                    <button type="submit" className="SubmitButton" disabled={isLoading}>
                      Update
                    </button>
                  </div>
                </div>
              </Form>
            </div>}
          </div>
        </div>
      </div>
      <Modal
        show={showActivenationality}
        onHide={handleCloseActivenationality}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2>Nationality</h2>
        </Modal.Header>
        <Modal.Body>
          <NationalityPopup setShowNationality={setShowNationality} />
        </Modal.Body>
      </Modal>
      <Modal
        show={showColor}
        onHide={handleCloseColor}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2>Color</h2>
        </Modal.Header>
        <Modal.Body>
          <ColorPopup setShowColor={setShowColor} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditRaceCourse;
