import React, { useState, useEffect } from "react";
import "../../Components/CSS/forms.css";
import { useNavigate, useLocation } from "react-router-dom";
import swal from "sweetalert";
import axios from "axios";


import { Form, Input } from 'antd';
import { ImCross } from "react-icons/im";
import Loader from "../../Components/Common/Loader";
const EditGround = () => {
  const [form] = Form.useForm();
  const history = useNavigate();
  const { state } = useLocation();
  const [image, setImage] = useState();
  const [preview, setPreview] = useState();

  const { groundtypeid } = state;
  const [isLoading, setisLoading] = useState(false)
  const [isLoading1, setisLoading1] = useState(false);

  const [state1, setState] = useState({
    NameEn: "",
    NameAr: "",
    AbbrevEn: "",
    AbbrevAr: "",
    shortCode: "",
    image: image
  });
  //----------------------------get Data -------------------------//s
  useEffect(() => {
    if (groundtypeid) {
      setState({
        NameEn: groundtypeid.NameEn,
        NameAr: groundtypeid.NameAr,
        AbbrevEn: groundtypeid.AbbrevEn,
        AbbrevAr: groundtypeid.AbbrevAr,
        shortCode: groundtypeid.shortCode,
      });
    } else {
      alert("No Data");
    }
  }, [groundtypeid]);


  useEffect(() => {
    if (image === undefined) {
      setPreview(groundtypeid.image);
      return;
    }
    const objectUrl = URL.createObjectURL(image);
    setPreview(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [groundtypeid.image, image]);
  const fileSelected = (event) => {
    const image = event.target.files[0];
    setImage(image, image);
  };

  const handlePreview = () => {
    setImage();
    setPreview();
    setisLoading1(true)
    document.getElementById("file").value = "";
  };
  //----------------------------submit-------------------------//

  const submit = async () => {

    try {
      setisLoading(true)
      const formData = new FormData();
      formData.append("image", image);
      formData.append("NameEn", state1.NameEn);
      formData.append("NameAr", state1.NameAr);
      formData.append("AbbrevEn", state1.AbbrevEn);
      formData.append("AbbrevAr", state1.AbbrevAr);
      formData.append("shortCode", state1.shortCode);
      if (isLoading1 === true && image === undefined) {
        formData.append("deleteexisitingimage", isLoading1)
      }
      await axios.put(
        `${window.env.API_URL}/updateGroundType/${groundtypeid._id}`,
        formData
      );
      history("/groundlist");
      swal({
        title: "Success!",
        text: "Data has been Updated successfully ",
        icon: "success",
        button: "OK",
      });
    } catch (error) {
      const err = error.response.data.message[0];
      const err1 = error.response.data.message[1];
      const err2 = error.response.data.message[2];
      swal({
        title: "Error!",
        text: err,
        err1,
        err2,
        icon: "error",
        button: "OK",
      });
    }
    setisLoading(false)
  };
  form.setFieldsValue({
    NameEn: state1.NameEn,
    NameAr: state1.NameAr,
    Abbreviation: state1.AbbrevEn,
    AbbreviationAr: state1.AbbrevAr,
    shortCode: state1.shortCode

  });

  return (
    <>
      <div className="page">
        <div className="rightsidedata">
          <div
            style={{
              marginTop: "30px",
            }}
          >
            <div className="Headers">Edit Ground Type</div>
            {isLoading ? <Loader /> : <div className="form">
              <Form onFinish={submit} form={form}>
                <div className="row mainrow">
                  <div className="col-sm">

                    <Form.Item
                      name="NameEn"
                      label="Name"
                      rules={[
                        {
                          required: true,
                          message: 'Name (English) is required',
                        },
                      ]}
                    >
                      <Input placeholder="Name" onChange={(e) =>
                        setState({ ...state1, NameEn: e.target.value })
                      }
                        value={state1.NameEn} name="NameEn" />
                    </Form.Item>

                    <span className="spanForm spacer"> |</span>
                  </div>

                  <div className="col-sm">

                    <Form.Item
                      name="NameAr"
                      label="اسم"
                      rules={[
                        {
                          required: true,
                          message: 'Name (Arabic) is required',
                        },
                      ]}
                      style={{ direction: 'rtl' }}
                    >
                      <Input placeholder="اسم" onChange={(e) =>
                        setState({ ...state1, NameAr: e.target.value })
                      }
                        name="Name"
                        value={state1.NameAr} />
                    </Form.Item>

                  </div>
                </div>

                <div className="row mainrow">
                  <div className="col-sm">

                    <Form.Item
                      name="Abbreviation"
                      label="Abbreviation"
                      rules={[
                        {
                          required: true,
                          message: 'Abbreviation is required',
                        },
                      ]}
                    >
                      <Input placeholder="Abbreviation"

                        onChange={(e) =>
                          setState({ ...state1, AbbrevEn: e.target.value })
                        }

                        value={state1.AbbrevEn} />
                    </Form.Item>


                    <span className="spanForm spacer"> |</span>
                  </div>

                  <div className="col-sm">
                    <Form.Item
                      name="AbbreviationAr"
                      label="اكتب الاختصار"
                      rules={[
                        {
                          required: true,
                          message: 'Abbreviation (Arabic) is required',
                        },
                      ]}
                      style={{ direction: 'rtl' }}
                    >
                      <Input placeholder="اكتب الاختصار" onChange={(e) =>
                        setState({ ...state1, AbbrevAr: e.target.value })
                      }
                        value={state1.AbbrevAr}




                      />
                    </Form.Item>

                  </div>
                </div>
                <div className="row mainrow">
                  <div className="col-sm">
                    <Form.Item
                      name="shortCode"
                      label="Short Code"
                      rules={[
                        {
                          required: true,
                          message: "short Code is required"
                        },
                      ]}
                    >
                      <Input
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        onChange={(e) =>
                          setState({ ...state1, shortCode: e.target.value })
                        }
                        value={state1.shortCode}
                        onPaste={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        placeholder="Short Code"
                        maxLength="5"
                      />
                    </Form.Item>

                  </div>
                </div>





                <div className="ButtonSection">
                  <div className={image === undefined ? "inputFile" : ""}>
                    <label className="Multipleownerlabel">
                      Select Ground GIF
                    </label>
                    <input
                      type="file"
                      onChange={fileSelected}
                      className="formInput"
                      id="file"
                    />
                    {preview && (
                      <>
                        <ImCross
                          onClick={handlePreview}
                          className="crossIcon2"
                        />
                        <img src={preview} className="PreviewImage" alt="" />
                      </>
                    )}
                  </div>
                  <button type="submit" className="SubmitButton" disabled={isLoading}>
                    Update
                  </button>
                </div>
              </Form>
            </div>}
          </div>
        </div>
      </div>
    </>
  );
};

export default EditGround;
