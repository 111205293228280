import React, { useState, useEffect } from "react";
import "../../Components/CSS/forms.css";
import { useNavigate, useLocation } from "react-router-dom";
import swal from "sweetalert";
import axios from "axios";

import { Form, Input } from 'antd';

import { ImCross } from "react-icons/im";
import Loader from "../../Components/Common/Loader";
const EditSponsor = () => {
  const history = useNavigate();
  const { state } = useLocation();

  const { sponsorid } = state;
  const [form] = Form.useForm();
  const [preview, setPreview] = useState();
  const [isLoading, setisLoading] = useState(false);
  const [isLoading1, setisLoading1] = useState(false);

  const [image, setImage] = useState();
  const [state1, setState] = useState({
    TitleEn: "",
    TitleAr: "",
    DescriptionEn: "",
    DescriptionAr: "",
    Url: "",
    image: image,
  });
  //---------------------------remove preview---------------------------//
  const handlePreview = () => {
    setImage();
    setPreview();
    setisLoading1(true)
    document.getElementById("file").value = "";
  };
  //---------------------------get Data from previous state---------------------------//
  useEffect(() => {
    if (sponsorid) {
      setState({
        TitleEn: sponsorid.TitleEn,
        TitleAr: sponsorid.TitleAr,
        DescriptionEn: sponsorid.DescriptionEn,
        DescriptionAr: sponsorid.DescriptionAr,
        Url: sponsorid.Url,
      });
    } else {
    }
  }, [sponsorid]);
  //---------------------------image preview---------------------------//
  useEffect(() => {
    if (image === undefined) {
      setPreview(sponsorid.image);
      return;
    }
    const objectUrl = URL.createObjectURL(image);
    setPreview(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [image, sponsorid.image]);
  const fileSelected = (event) => {
    const image = event.target.files[0];
    setImage(image);
  };
  //---------------------------submit---------------------------//
  const submit = async () => {

    try {
      setisLoading(true);
      const formData = new FormData();
      formData.append("TitleEn", state1.TitleEn);
      formData.append("TitleAr", state1.TitleAr + " ");
      formData.append("DescriptionEn", state1.DescriptionEn);
      formData.append("DescriptionAr", state1.DescriptionAr + " ");
      formData.append("Url", state1.Url);
      formData.append("image", image === undefined ? null : image);
      if (isLoading1 === true && image === undefined) {
        formData.append("deleteexisitingimage", isLoading1)
      }

      // if()
      await axios.put(
        `${window.env.API_URL}/updateSponsor/${sponsorid._id}`,
        formData
      );
      history("/sponsor");
      swal({
        title: "Success!",
        text: "Data has been Updated successfully ",
        icon: "success",
        button: "OK",
      });
    } catch (error) {
      const err = error.response.data.message[0];
      const err1 = error.response.data.message[1];
      const err2 = error.response.data.message[2];
      swal({
        title: "Error!",
        text: err,
        err1,
        err2,
        icon: "error",
        button: "OK",
      });
    }
    setisLoading(false);
  };
  form.setFieldsValue({
    TitleEn: state1.TitleEn,
    NameAr: state1.TitleAr,
    Description: state1.DescriptionEn,
    DescriptionAr: state1.DescriptionAr,
    Url: state1.Url

  });
  return (
    <>
      <div className="page">
        <div className="rightsidedata">
          <div
            style={{
              marginTop: "30px",
            }}
          >
            <div className="Headers">Edit Sponsor</div>
            {isLoading ? <Loader /> : <div className="form">
              <Form onFinish={submit} form={form}>
                <div className="row mainrow">
                  <div className="col-sm">





                    <Form.Item
                      name="TitleEn"
                      label="Title"
                      rules={[
                        {
                          required: true,
                          message: 'Title (English) is required',
                        },
                      ]}
                    >
                      <Input placeholder="Title" onChange={(e) =>
                        setState({ ...state1, TitleEn: e.target.value })
                      }
                        value={state1.TitleEn} name="NameEn" />
                    </Form.Item>






                    <span className="spanForm spacer"> |</span>
                  </div>

                  <div className="col-sm">



                    <Form.Item
                      name="NameAr"
                      label="عنوان"
                      rules={[
                        {
                          required: true,
                          message: 'Title (Arabic) is required',
                        },
                      ]}
                      style={{ direction: 'rtl' }}
                    >
                      <Input placeholder="عنوان" onChange={(e) =>
                        setState({ ...state1, TitleAr: e.target.value })
                      }
                        name="Name"
                        value={state1.TitleAr} />
                    </Form.Item>











                  </div>
                </div>

                <div className="row mainrow">
                  <div className="col-sm">

                    <Form.Item
                      name="Description"
                      label="Description"
                      rules={[
                        {
                          required: true,
                          message: 'Description (English) is required',
                        },
                      ]}
                    >
                      <Input placeholder="Description" onChange={(e) =>
                        setState({ ...state1, DescriptionEn: e.target.value })
                      }
                        value={state1.DescriptionEn} name="Description" />
                    </Form.Item>












                    <span className="spanForm spacer"> |</span>
                  </div>

                  <div className="col-sm">








                    <Form.Item
                      name="DescriptionAr"
                      label="وصف"
                      rules={[
                        {
                          required: true,
                          message: 'Description (Arabic) is required',
                        },
                      ]}
                      style={{ direction: 'rtl' }}
                    >
                      <Input placeholder="وصف" onChange={(e) =>
                        setState({ ...state1, DescriptionAr: e.target.value })
                      }
                        name="DescriptionAr"
                        value={state1.DescriptionAr} />
                    </Form.Item>




                  </div>
                </div>
                <div className="row mainrow">
                  <div className="col-sm">






                    <Form.Item
                      name="Url"
                      label="Url"
                      rules={[
                        {
                          required: true,
                          message: 'Url is required',
                        },
                      ]}
                    >
                      <Input placeholder="Url" onChange={(e) =>
                        setState({ ...state1, Url: e.target.value })
                      }

                        value={state1.Url} name="Url" />
                    </Form.Item>









                  </div>
                </div>

                <div className="ButtonSection">
                  <div className={image === undefined ? "inputFile" : ""}>
                    <label className="Multipleownerlabel">
                      Select Sponsor Image
                    </label>
                    <input
                      type="file"
                      onChange={fileSelected}
                      className="formInput"
                      id="file"
                    />
                    {preview && (
                      <>
                        <ImCross
                          onClick={handlePreview}
                          className="crossIcon"
                        />
                        <img src={preview} className="PreviewImage" alt="" />
                      </>
                    )}
                  </div>
                  <div>
                    <button
                      type="submit"
                      className="SubmitButton"
                      disabled={isLoading}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </Form>
            </div>}
          </div>
        </div>
      </div>
    </>
  );
};

export default EditSponsor;
