import React, { useEffect, Fragment, useState } from "react";
import { fetchRaceType } from "../../redux/getReducer/getRacetype";
import { useDispatch , useSelector } from "react-redux";
import { MdAutoDelete, MdDelete } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import ScrollContainer from "react-indiana-drag-scroll";
import Lottie from "lottie-react";
import HorseAnimation from "../../assets/horselottie.json";
import axios from "axios";
import { BiEdit } from "react-icons/bi";
import { BsEyeFill } from "react-icons/bs";
import RaceTypepopup from "../../Components/Popup/RaceTypepopup";
import { Modal } from "react-bootstrap";
import Pagination from "./Pagination";
import { BiFilter } from "react-icons/bi";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import CSVRaceType from "../../Components/CSVUploadPopup/RaceTypePopup";
import Notfound from "../../Notfound";

const Racetype = () => {
  //---------------------------state-------------------------//

  const [ShowCalender, setShowCalender] = useState(false);
  const [SearchCode, setSearchCode] = useState("");
  const [SearchTitle, setSearchTitle] = useState("");
  const [TotalCount, setTotalCount] = useState();
  const [TotalPages, setTotalPages] = useState("");
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modaldata, setmodaldata] = useState();
  const handleClose = () => setShow(false);
  const handleShow = async (data) => {
    setmodaldata(data);
    await setShow(true);
  };


  const { userInfo } = useSelector((state) => state.user);

  //---------------------------modal-------------------------//

  const [showCSV, setShowCSV] = useState(false);
  const [modaldataCSV, setmodaldataCSV] = useState();
  const handleCloseCSV = () => setShowCSV(false);
  const handleShowCSV = async (data) => {
    setmodaldataCSV(data);
    await setShowCSV(true);
  };

  const dispatch = useDispatch();
  const history = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(8);
  const [searchData, setSearchData] = useState([]);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  //---------------------------api-------------------------//

  const Data1 = async () => {
    setLoading(true);
    
    const res = await axios.get(
      `${window.env.API_URL}/RaceTypeget?shortCode=${SearchCode}&NameEn=${SearchTitle}&page=${currentPage}`
    );
    const totalcount = res.data.totalcount;
    setLoading(false);
    setTotalCount(totalcount);
    const TotalPagesData = res.data.totalPages;
    setTotalPages(TotalPagesData);
    const data = res.data.data;
    setSearchData(data);
  };
  const GetSearch = async () => {
    Data1();
  };

  useEffect(() => {
    dispatch(fetchRaceType({ SearchTitle, SearchCode, currentPage }));
    Data1();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, currentPage]);
  //---------------------------delete-------------------------//

  const handleRemove1 = async (Id) => {
    try {
      swal({
        title: "Are you sure?",
        text: "do you want to delete this data ?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          await axios.delete(`${window.env.API_URL}/softdeleteRaceType/${Id}`);
          swal(" Your data has been deleted Successfully!", {
            icon: "success",
          });
          Data1();
                } else {
          swal("Your data is safe!");
        }
      });
    } catch (error) {
      const err = error.response.data.message;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
    }
  };

  const handleRemove = async (id) => {
    try {
      swal({
        title: "Are you sure?",
        text: "do you want to delete this data ?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          setLoading(true)
          const res = await axios.delete(`${window.env.API_URL}/DeleteRaceTypeAlltoAll/${id}`);
          const msg = res.data.message ?  res.data.message : "deleted";
          swal(msg, {
            icon: "",
          });
      let data1 =  JSON.stringify(res.data.data)
      const blob = new Blob([data1]);
      if(res?.data?.flag){
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.download = "report1.json";
        link.href = url;
        link.click();
        setLoading(false)
            GetSearch({ currentPage });

      }     
      setLoading(false)
          GetSearch({ currentPage });

        } else {
          swal("Your data is safe!");
        }
      });
    } catch (error) {
      const err = error.success;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
      setLoading(false)

    }
  }

  return (
    <Fragment>
      <div className="page">
        <div className="rightsidedata">
          <div
            style={{
              marginTop: "30px",
            }}
          >
            <div className="Header ">
              <h4>Race Type Listings</h4>

              <div>
                <Link to="/racetypeform">
                  <button>Add Race Type</button>
                </Link>
                <OverlayTrigger
                  overlay={<Tooltip id={`tooltip-top`}>Filter</Tooltip>}
                >
                  <span className="addmore">
                    <BiFilter
                      className="calendericon"
                      onClick={() => setShowCalender(!ShowCalender)}
                    />
                  </span>
                </OverlayTrigger>

                <p onClick={() => handleShowCSV()} className="importcsv">
                  Import JSON
                </p>
              </div>
            </div>
            <div>
              {ShowCalender ? (
                <span className="transitionclass">
                  <div className="userfilter">
                    <div className="filtertextform forflex">
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setSearchTitle(e.target.value)}
                        placeholder="Enter Name"
                      />

                      <input
                         onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                        className="form-control"
                        onChange={(e) => setSearchCode(e.target.value)}
                        placeholder="Enter Short Code"
                      />
                    </div>
                  </div>
                  <button className="filterbtn" onClick={GetSearch}>
                    Apply Filter
                  </button>
                </span>
              ) : (
                <></>
              )}
            </div>
            <>
              <div className="div_maintb">
                <ScrollContainer>
                  <table>
                    <thead>
                      <tr>
                        <th>Action</th>
                        <th>Name</th>
                        <th>Name Arabic </th>
                        <th>Short Code</th>
                      </tr>
                    </thead>
                    {loading ? (
                      <Lottie
                        animationData={HorseAnimation}
                        loop={true}
                        className="TableLottie"
                      />
                    ) : searchData.length === 0 ? (
                      <Notfound />
                    ) : (
                      <tbody>
                        {searchData.map((item, index) => {
                          return (
                            <>
                              <tr className="tr_table_class">
                                <td className="table_delete_btn1">
                                  <BiEdit
                                    onClick={() =>
                                      history("/editracetype", {
                                        state: {
                                          racetypeid: item,
                                        },
                                      })
                                    }
                                  />
                                        <MdAutoDelete
                                    style={{
                                      fontSize: "22px",
                                    }}
                                    onClick={() => handleRemove(item._id)}
                                  />
                                  <BsEyeFill onClick={() => handleShow(item)} />
                                </td>
                                <td>{item.NameEn}</td>
                                <td>{item.NameAr}</td>
                                <td>{item.shortCode} </td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    )}
                  </table>
                </ScrollContainer>
              </div>
            </>
          </div>
          <span className="plusIconStyle"></span>
          <Pagination
            postsPerPage={postsPerPage}
            totalPosts={TotalCount}
            paginate={paginate}
            currentPage={currentPage}
            TotalPages={TotalPages}
          />
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2 style={{ fontFamily: "inter" }}>Race Type</h2>
        </Modal.Header>
        <Modal.Body>
          <RaceTypepopup data={modaldata} />
        </Modal.Body>
        <Modal.Footer>
          <button onClick={handleClose} className="modalClosebtn">
            Close
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showCSV}
        onHide={handleCloseCSV}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2 style={{ fontFamily: "inter" }}>Race Type JSON</h2>
        </Modal.Header>
        <Modal.Body>
          <CSVRaceType data={modaldataCSV} />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default Racetype;
