import React, { useState } from "react";
import swal from "sweetalert";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { Form, Input } from 'antd';

import Loader from "../../../Components/Common/Loader";



const Competition = ({ setShowCategory }) => {
  //for error

  const [isLoading, setisLoading] = useState(false);

  const [NameEn, setNameEn] = useState("");
  const [NameAr, setNameAr] = useState("");
  const [state1, setState] = useState({
    shortCode: "",
  });
  const history = useNavigate();
  const { pathname } = useLocation();

  const submit = async () => {

    setisLoading(true);
    try {
      const formData = new FormData();
      formData.append("NameEn", NameEn);
      formData.append("NameAr", NameAr);
      formData.append("shortCode", state1.shortCode)

      await axios.post(
        `${window.env.API_URL}/uploadCompetitionCategory`,
        formData
      );
      setisLoading(false);
      if (pathname === "/addCategory") {
        history("/CategoryListing");
      }
      swal({
        title: "Success!",
        text: "Data has been added Successfully ",
        icon: "success",
        button: "OK",
      }); setShowCategory(false)
    } catch (error) {
      const err = error.response.data.message;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
      setisLoading(false);
    }
  };

  return (
    <div className="page">
      <div className="rightsidedata">
        <div
          style={{
            marginTop: "30px",
          }}
        >
          <div className="Headers">Create Category</div>
          {!isLoading ?
            <>
              <div className="form">
                <Form onFinish={submit}
                  fields={[{

                    name: "shortCode",
                    value: state1.shortCode

                  }]}
                >
                  <div className="row mainrow">
                    <div className="col-sm">


                      <Form.Item
                        name="NameEn"
                        label="Name"
                        rules={[
                          {
                            required: true,
                            message: 'Name (English) is required',
                          },
                        ]}
                      >
                        <Input placeholder="Name" onChange={(e) => setNameEn(e.target.value)}
                          value={NameEn} name="NameEn" />
                      </Form.Item>



                      <span className="spanForm spacer"> |</span>





                    </div>

                    <div className="col-sm">
                      <Form.Item
                        name="NameAr"
                        label="اسم"
                        rules={[
                          {
                            required: true,
                            message: 'Name (Arabic) is required',
                          },
                        ]}
                        style={{ direction: 'rtl' }}
                      >
                        <Input placeholder="اسم" onChange={(e) => setNameAr(e.target.value)}
                          name="Name"
                          value={NameAr} />
                      </Form.Item>

                    </div>
                  </div>

                  <div className="ButtonSection" style={{ justifyContent: "end" }}>
                    <button
                      type="submit"
                      className="SubmitButton"
                      disabled={isLoading}
                    >
                                           Save

                    </button>
                  </div>
                </Form>
              </div>  </> : <Loader />}
        </div>
      </div >
    </div >
  );
};

export default Competition;
