import axios from "axios";
import { createAsyncThunk,createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
    IDLE : 'idle',
    ERROR:'error',
    LOADING: 'loading',
});

const getVerifyAndDeleteFinalPositionSlice = createSlice({
    name: 'VerifyAndDeleteFinalPosition',
    initialState: {
        data:[],
        status : STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
        .addCase(fetchVerifyAndDeleteFinalPosition.pending, (state, action) => {
            state.status = STATUSES.LOADING;
        })
        .addCase(fetchVerifyAndDeleteFinalPosition.fulfilled, (state, action) => {
            state.data = action.payload;
            state.status = STATUSES.IDLE
        }) 
        .addCase(fetchVerifyAndDeleteFinalPosition.rejected , (state,action) => {
            state.status = STATUSES.ERROR;
        })
    }
});

export const {setVerifyAndDeleteFinalPosition , setStatus} = getVerifyAndDeleteFinalPositionSlice.actions;
export default getVerifyAndDeleteFinalPositionSlice.reducer;

export const fetchVerifyAndDeleteFinalPosition = createAsyncThunk('/getVerifyAndDeleteFinalPosition/fetch', async({id}) => {
    const res = await axios.delete(`${window.env.API_URL}/VerifyAndDeleteFinalPosition/${id}`);
    const VerifyAndDeleteFinalPositionData = res.data;
    return VerifyAndDeleteFinalPositionData.data;
})