import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Upload, Button, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import axios from "axios";
import swal from "sweetalert";

const ResultColor = ({data1}) => {
  const { state } = useLocation();
  const { RaceId } = state;
  const [selectedImages, setSelectedImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const onSelectFile = (fileList) => {
    setSelectedImages(fileList);
  };



  const UploadSilkColor = async () => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      selectedImages.forEach((image) => {
        formData.append("image", image.originFileObj);
      });

      const res =  await axios.post(`${window.env.API_URL}/AddRaceImage/${RaceId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      const success = res.statusText;
      console.log(res,"http://localhost:3001/editresult")
      setIsLoading(false);
      swal({
        title: "Success!",
        text: "Data submitted",
        icon: "success",
        button: "OK",
      });
      data1.handleCloseResultimage();

    } catch (error) {
      console.log(error,"error")
      setIsLoading(false);
      const err = "File size too large";
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
    }
  };

  const handleRemove = (file) => {
    const updatedList = selectedImages.filter((image) => image.uid !== file.uid);
    setSelectedImages(updatedList);
  };

  const beforeUpload = (file) => {
    // Add any custom validation here (e.g., file size, file type)
    return true;
  };

  const uploadProps = {
    multiple: true,
    fileList: selectedImages,
    onChange: (info) => {
      if (info.file.status === "done" || info.file.status === "error") {
        // Do something after the file is uploaded or upload failed
      }
      onSelectFile(info.fileList);
    },
    onRemove: handleRemove,
    beforeUpload: beforeUpload,
  };

  return (
    <>
      <div className="page">
        <div className="rightsidedata">
          <div style={{ marginTop: "30px" }}>
            <div className="Headers">Upload Race Result Images</div>
            <section className="addsectionimage">
            <Upload {...uploadProps}>
                <Button icon={<UploadOutlined />}>+ Add Images</Button>
              </Upload>
              <br />

              {selectedImages.length > 0 && (
                <>
                  {selectedImages.length > 10 ? (
                    <p className="error11">
                      You can't upload more than 10 images! <br />
                      <span>
                        please delete <b>{selectedImages.length - 10}</b> of them{" "}
                      </span>
                    </p>
                  ) : (
                    <Button
                      type="primary"
                      className="upload-btn111"
                      onClick={UploadSilkColor}
                      loading={isLoading}
                    >
                      UPLOAD {selectedImages.length} IMAGE
                      {selectedImages.length === 1 ? "" : "S"}
                    </Button>
                  )}
                </>
              )}

              {/* <div className="images111">
                {selectedImages.map((image, index) => (
                  <div key={index} className="image111">
                    <img src={image.thumbUrl} height="200" width="200" alt="upload" />
                    <Button onClick={() => handleRemove(image)}>delete image</Button>
                    <p>{index + 1}</p>
                  </div>
                ))}
              </div> */}
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResultColor;
