import React, { useState, useEffect } from "react";
import "../../Components/CSS/forms.css";
import { useNavigate, useLocation } from "react-router-dom";
import swal from "sweetalert";
import axios from "axios";
import { Form, Input, Select } from 'antd';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";


import { useSelector, useDispatch } from "react-redux";
import { fetchNationalityList } from "../../redux/getDropDownReducer/getNationalityList"
import { AiOutlineReload } from "react-icons/ai";
import { Modal } from "react-bootstrap";
import NationalityPopup from "../PostTable/Nationality";
import { ImCross } from "react-icons/im";
import Loader from "../../Components/Common/Loader";


const EditOwner = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useNavigate();
  const { state } = useLocation();
  const { data: NationalityList } = useSelector((state) => state.NationalityList);

  const { ownerid } = state;
  const [image, setImage] = useState();
  const [preview, setPreview] = useState();
  const [NationalityID, setNationalityID] = useState("");
  const [RegistrationDate, setRegistrationDate] = useState("");
  const [showActivenationality, setShowNationality] = useState(false);
  const [isLoading, setisLoading] = useState(false)
  const [isLoading1, setisLoading1] = useState(false);

  const handleCloseActivenationality = () => setShowNationality(false);

  const handleShowActivenationality = async () => {
    await setShowNationality(true);
  };
  const FetchNew = () => {
    dispatch(fetchNationalityList());
  };
  const { Option } = Select;

  const handleChange = async (value) => {

    await setNationalityID(value)
    setState({ ...state1, nationalityName: value })

  };


  const handlePreview = () => {
    setImage();
    setPreview();
    setisLoading1(true)
    document.getElementById("file").value = "";
  };

  const [state1, setState] = useState({
    NameAr: "",
    NameEn: "",
    TitleEn: "",
    TitleAr: "",
    ShortEn: "",
    ShortAr: "",
    nationalityName: "",
    nationality: "",
    shortCode:"",

    image: image,
  });
  //---------------------------get Data from previous---------------------------//

  useEffect(() => {
    if (ownerid) {
      setState({
        NameEn: ownerid.NameEn,
        NameAr: ownerid.NameAr,
        TitleEn: ownerid.TitleEn,
        TitleAr: ownerid.TitleAr,
        ShortEn: ownerid.ShortEn,
        ShortAr: ownerid.ShortAr,
        RegistrationDate: ownerid.RegistrationDate,
        NationalityID: ownerid.NationalityID,
        nationality: ownerid.OwnerDataNationalityData && ownerid.OwnerDataNationalityData._id,
        nationalityName: ownerid.OwnerDataNationalityData && ownerid.OwnerDataNationalityData.NameEn,
        shortCode:ownerid.shortCode,
        image: ownerid.image,
      });
      let day123 = new Date(ownerid.RegistrationDate);
      let daydate = day123.getDate();
      if (daydate < 10) {
        daydate = `0${day123.getDate()}`;
      }
      let daymonth = day123.getMonth() + 1;
      if (daymonth < 10) {
        daymonth = `0${day123.getMonth() + 1}`;
      }
      let dayformat = `${day123.getFullYear()}-${daymonth}-${daydate}`;
      console.log(dayformat, "dayformat");

      setRegistrationDate(dayformat);
    } else {
      alert("No Data");
    }
  }, [ownerid]);

  useEffect(() => {
    if (image === undefined) {
      setPreview(ownerid.image);
      return;
    }
    const objectUrl = URL.createObjectURL(image);
    setPreview(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [image, ownerid.image]);
  const fileSelected = (event) => {
    const image = event.target.files[0];
    setImage(image);
  };
  //---------------------------Submits---------------------------//

  const submit = async () => {

    try {
      const formData = new FormData();
      formData.append("NameEn", state1.NameEn);
      formData.append("NameAr", state1.NameAr);
      formData.append("TitleEn", state1.TitleEn);
      formData.append("TitleAr", state1.TitleAr);
      formData.append("ShortEn", state1.ShortEn);
      formData.append("ShortAr", state1.ShortAr);
      formData.append("shortCode", state1.shortCode);
      formData.append("RegistrationDate", RegistrationDate);
      formData.append("NationalityID", NationalityID === undefined ? state1.NationalityID : NationalityID);
      formData.append("Ownerimage", image);
      if (isLoading1 === true && image === undefined) {
        formData.append("deleteexisitingimage", isLoading1)
      }

      await axios.put(
        `${window.env.API_URL}/updateOwner/${ownerid._id}`,
        formData
      );
      history("/owner");
      swal({
        title: "Success!",
        text: "Data has been Updated successfully ",
        icon: "success",
        button: "OK",
      });
    } catch (error) {
      const err = error.response.data.message;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
    }
  };
  const handleData = async (event) => {
    event.preventDefault();
    setisLoading(true)
    try {
      const formData = new FormData();
      formData.append("NameEn", state1.NameEn);
      formData.append("NameAr", state1.NameAr);
      formData.append("TitleEn", state1.TitleEn);
      formData.append("TitleAr", state1.TitleAr);
      formData.append("ShortEn", state1.ShortEn);
      formData.append("ShortAr", state1.ShortAr);
      formData.append("shortCode", state1.shortCode);
      formData.append("RegistrationDate", RegistrationDate);
      formData.append("NationalityID", NationalityID.id === undefined ? state1.NationalityID : NationalityID);
      formData.append("Ownerimage", image);

      await axios.put(
        `${window.env.API_URL}/updateOwner/${ownerid._id}`,
        formData
      );
      history("/editownersilkcolor", {
        state: {
          ownerid: ownerid._id,
        },
      })
      swal({
        title: "Success!",
        text: "Data has been Updated successfully ",
        icon: "success",
        button: "OK",
      });
    } catch (error) {
      const err = error.response.data.message;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
    }
    setisLoading(false)

  }

  const handleSearch = async (value) => {
    try {
      dispatch(fetchNationalityList({ value }))

    } catch (error) {

    }
  };
  form.setFieldsValue({
    NameEn: state1.NameEn,
    NameAr: state1.NameAr,
    TitleEn: state1.TitleEn,
    TitleAr: state1.TitleAr,
    ShortEn: state1.ShortEn,
    ShortAr: state1.ShortAr,
    shortCode:state1.shortCode,
    nationalityName: state1.nationalityName

  });
  return (
    <>
      <div className="page">
        <div className="rightsidedata">
          <div
            style={{
              marginTop: "30px",
            }}
          >
            <div className="Headers">Edit Owner</div>
            {isLoading ? <Loader /> : <div className="form">
              <Form onFinish={submit} form={form}>
                <div className="row mainrow">
                  <div className="col-sm">
                    <Form.Item
                      name="NameEn"
                      label="Name"
                      rules={[
                        {
                          required: true,
                          message: 'Name (English) is required',
                        },
                      ]}
                    >
                      <Input placeholder="Name" onChange={(e) =>
                        setState({ ...state1, NameEn: e.target.value })
                      }
                        value={state1.NameEn} name="NameEn" />
                    </Form.Item>


                    <span className="spanForm spacer"> |</span>
                  </div>

                  <div className="col-sm">

                    <Form.Item
                      name="NameAr"
                      label="اسم"
                      rules={[
                        {
                          required: true,
                          message: 'Name (Arabic) is required',
                        },
                      ]}
                      style={{ direction: 'rtl' }}
                    >
                      <Input placeholder="اسم" onChange={(e) =>
                        setState({ ...state1, NameAr: e.target.value })
                      }
                        name="Name"
                        value={state1.NameAr} />
                    </Form.Item>
                  </div>
                </div>
                <div className="row mainrow">
                  <div className="col-sm">





                    <Form.Item
                      name="TitleEn"
                      label="Title"
                      rules={[
                        {
                          required: true,
                          message: 'Title (English) is required',
                        },
                      ]}
                    >
                      <Input placeholder="Title" onChange={(e) =>
                        setState({ ...state1, TitleEn: e.target.value })
                      }
                        value={state1.TitleEn} name="NameEn" />
                    </Form.Item>






                    <span className="spanForm spacer"> |</span>
                  </div>

                  <div className="col-sm">



                    <Form.Item
                      name="TitleAr"
                      label="عنوان"
                      rules={[
                        {
                          required: true,
                          message: 'Title (Arabic) is required',
                        },
                      ]}
                      style={{ direction: 'rtl' }}
                    >
                      <Input placeholder="عنوان" onChange={(e) =>
                        setState({ ...state1, TitleAr: e.target.value })
                      }
                        name="TitleAr"
                        value={state1.TitleAr} />
                    </Form.Item>











                  </div>
                </div>








                <div className="row mainrow">
                  <div className="col-sm">
                    <Form.Item
                      name="ShortEn"
                      label="Short Name"
                      rules={[
                        {
                          required: true,
                          message: 'Short Name (English) is required',
                        },
                      ]}
                    >
                      <Input placeholder="Short Name" onChange={(e) =>
                        setState({ ...state1, ShortEn: e.target.value })
                      }
                        value={state1.ShortEn} name="ShortEn" />
                    </Form.Item>


                    <span className="spanForm spacer"> |</span>
                  </div>

                  <div className="col-sm">

                    <Form.Item
                      name="ShortAr"
                      label="اسم قصير"
                      rules={[
                        {
                          required: true,
                          message: 'Name (Arabic) is required',
                        },
                      ]}
                      style={{ direction: 'rtl' }}
                    >
                      <Input placeholder="اسم قصير" onChange={(e) =>
                        setState({ ...state1, ShortAr: e.target.value })
                      }
                        name="ShortAr"
                        value={state1.ShortAr} />
                    </Form.Item>
                  </div>
                </div>




                {/* <div className="row mainrow">
                  <div className="col-sm">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Short Name "
                      className="mb-3"
                      onChange={(e) =>
                        setState({ ...state1, ShortEn: e.target.value })
                      }
                    >
                      <Form.Control
                        type="text"
                        placeholder="Description"
                        value={state1.ShortEn}
                      />
                    </FloatingLabel>

                    <span className="spanForm"> |</span>
                  </div>

                  <div className="col-sm">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="اسم قصير"
                      className="mb-3 floatingInputAr"
                      style={{ direction: "rtl" }}
                      onChange={(e) =>
                        setState({ ...state1, ShortAr: e.target.value })
                      }
                    >
                      <Form.Control
                        type="text"
                        placeholder="Description"
                        value={state1.ShortAr}
                      />
                    </FloatingLabel>
                  </div>
                </div> */}


                <div className="row mainrow ">
                  <p className="selectLabel">Owner Registration Date</p>
                  <input
                    type="date"
                    data-placeholder="Owner Registration Date"
                    onChange={(e) => setRegistrationDate(e.target.value)}
                    value={RegistrationDate}
                    className="dateforraces"
                    required
                  />

                </div>
                <div className="row mainrow">

                  <div className="col-sm">

                    <Form.Item

                      name="nationalityName"
                      label="Nationality"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: 'Please select your Nationality!',
                        },
                      ]}
                    >
                      <Select
                        value={NationalityID}
                        filterOption={false}
                        defaultValue={state.nationalityName}
                        onChange={handleChange}
                        onSearch={handleSearch}
                        showSearch placeholder="Please select a Nationality" >

                        {
                          NationalityList?.map((item) => (


                            <Option value={item._id}>{item.NameEn} ({item.NameAr})</Option>

                          ))
                        }
                      </Select>
                    </Form.Item>


                    {/* 
                    <Select
                      placeholder={
                        <div>{ownerid.OwnerDataNationalityData && ownerid.OwnerDataNationalityData.NameEn}</div>

                      }
                      defaultInputValue={NationalityID}
                      onChange={setNationalityID}
                      options={AllNationality}
                      isClearable={true}
                      isSearchable={true}
                    /> */}

                    <span className="spanForm selectSpacer">
                      <OverlayTrigger
                        overlay={<Tooltip id={`tooltip-top`}>Add more</Tooltip>}
                      >
                        <span
                          className="addmore"
                          onClick={handleShowActivenationality}
                        >
                          +
                        </span>
                      </OverlayTrigger>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id={`tooltip-top`}>Fetch New</Tooltip>
                        }
                      >
                        <span className="addmore" onClick={FetchNew}>
                          <AiOutlineReload />
                        </span>
                      </OverlayTrigger>

                    </span>
                  </div>


                </div>


                <div className="row mainrow">
                    <div className="col-sm">
                      <Form.Item
                        name="shortCode"
                        label="Short Code"
                        rules={[
                          {
                            required: true,
                            message: "short Code is required"
                          },
                        ]}
                      >
                        <Input
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          onChange={(e) =>
                            setState({ ...state1, shortCode: e.target.value })
                          }
                          value={state1.shortCode}
                          onPaste={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          placeholder="Short Code"
                          maxLength="5"
                        />
                      </Form.Item>
                    </div>
                  </div>


                <div className="ButtonSection">
                  <div className={image === undefined ? "inputFile" : ""}>
                    <label className="Multipleownerlabel">
                      Select Owner image
                    </label>
                    <input
                      type="file"
                      onChange={fileSelected}
                      className="formInput"
                      id="file"
                    />
                    {preview && (
                      <>
                        <ImCross
                          onClick={handlePreview}
                          className="crossIcon2"
                        />
                        <img src={preview} className="PreviewImage" alt="" />
                      </>
                    )}
                  </div>
                  <div>
                    <button className="SubmitButton mx-5" onClick={handleData

                    }>Edit Silk Color </button>
                    <button type="submit" className="SubmitButton" disabled={isLoading}>
                      Update Owner
                    </button>
                  </div>
                </div>
              </Form>
            </div>}
          </div>
        </div>
      </div>
      <Modal
        show={showActivenationality}
        onHide={handleCloseActivenationality}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2>Nationality</h2>
        </Modal.Header>
        <Modal.Body>
          <NationalityPopup setShowNationality={setShowNationality} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditOwner;
