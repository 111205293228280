import React, { Fragment, useEffect, useState } from "react";
import swal from "sweetalert";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import TextInputValidation from "../../utils/TextInputValidation";
import { fetchGroupName } from "../../redux/getReducer/getGroupName";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { TiTick } from "react-icons/ti";
import { ImCross } from "react-icons/im";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { AiOutlineReload } from "react-icons/ai";
import GroupName from "./GroupName";
import { fetchfinalpositiondropdown } from "../../redux/getDropDownReducer/getFinalPosition";
import FinalPosition from "./FinalPosiiton"

const PointForm = ({ setShowPoints }) => {
  const CategoryType = [
    {
      id: 1,
      value: "Pick",
      label: "Pick",
    },
    {
      id: 2,
      value: "Cast",
      label: "Cast",
    },
  ];
  //for errors
  const { pathname } = useLocation();

  const { data: groupName } = useSelector((state) => state.groupName);
  const { data: finalpositiondropdown } = useSelector((state) => state.finalpositiondropdown);

  const dispatch = useDispatch();
  let AllGroup =
    groupName === undefined ? (
      <></>
    ) : (
      groupName.map(function (item) {
        return {
          id: item._id,
          value: item.NameEn,
          label: item.NameEn,
        };
      })
    );



  let finalposition =
    finalpositiondropdown === undefined ? (
      <></>
    ) : (
      finalpositiondropdown.map(function (item) {
        return {
          id: item._id,
          value: item.NameEn,
          label: item.NameEn,
        };
      })
    );


  const [Type, setType] = useState("");
  const [Group_Name, setGroup_Name] = useState("");
  const [Rank, setRank] = useState(1);

  const [CastCount, setCastCount] = useState("");
  const [PickCount, setPickCount] = useState("");

  const [showForm, setshowForm] = useState();
  const [registeration, setregisteration] = useState({
    Group_Name: "",
    Rank: "",
    Point: "",
    Bonus_Point: "",
  });

  const [items, setitems] = useState([]);

  const [isLoading, setisLoading] = useState(false);
  const [showSelection, setshowSelection] = useState(true);
  const [showCastForm, setshowCastForm] = useState(false);



  const data1 = JSON.stringify(
    TextInputValidation("en", registeration.Group_Name, "Group Points Name")
  );
  let GroupTable = {
    Rank: Rank,
    BonusPoint: registeration.Bonus_Point,
    Point: registeration.Point,
  };

  const saveItem = (e) => {
    e.preventDefault();
    if (registeration.Bonus_Point === '' || registeration.Point === '') {
      swal({
        title: "Validation!",
        text: "Enter Values ",
        icon: "error",
        button: "OK",
      });
    }
    else if (items.length < CastCount) {
      setitems([...items, GroupTable]);
      setregisteration({
        Point: "",
        Bonus_Point: "",
      });
      setRank(Rank + 1);
    } else {
      swal({
        title: "Limit!",
        text: "Limit Exceeded ",
        icon: "error",
        button: "OK",
      });
    }
  };
  const [showHorse, setShowHorse] = useState(false)
  const [showFinalPosiiton, setshowFinalPosiiton] = useState(false)
  const handleCloseHorse = () => setShowHorse(false)
  const handleFinalPositionClose = () => setshowFinalPosiiton(false)
  const handleshowHorse = async () => {
    await setShowHorse(true)
  }
  const handleshowFinalposition = async () => {
    await setshowFinalPosiiton(true)
  }
  const removesingleItem = (id) => {
    const updateItemsverdict = items.filter((elem, ind) => {
      return ind !== id;
    });
    setitems(updateItemsverdict);
  };
  // console.log(JSON.parse(items.length === 0 ? [GroupTable] : items),'items')


  const submit = async (event) => {
    event.preventDefault();

    console.log(CastCount, 'CastCount')
    console.log(items.length, 'items.length')
    if (CastCount == items.length) {
      setisLoading(true);
      try {
        const formData = new FormData();
        formData.append("Group_Name", Group_Name.id);
        formData.append("Type", Type.value);
        formData.append("Length", CastCount === "" ? 1 : CastCount);
        formData.append("PointTable", JSON.stringify(items.length === 0 ? [GroupTable] : items));

        await axios.post(
          `${window.env.API_URL}/uploadPointTableSystem`,
          formData
        );

        swal({
          title: "Success!",
          text: "Data has been added Successfully",
          icon: "success",
          button: "OK",
        });
        if (pathname === "/addcompetitionPoint") {
          history("/viewcompetitionPoint");
        }
        setShowPoints(false)
        setisLoading(false);
      } catch (error) {
        const err = error.response.data.message[0];

        swal({
          title: "Error!",
          text: err,
          icon: "error",
          button: "OK",
        });
        setisLoading(false);
      }
    }
    else {
      swal({
        title: "Error!",
        text: `${CastCount - items.length} Enteries Remaining`,
        icon: "error",
        button: "OK",
      });
    }

  };

  useEffect(() => {
    dispatch(fetchGroupName());
    dispatch(fetchfinalpositiondropdown())
  }, []);

  const fetchall = () => {
    dispatch(fetchGroupName());
    dispatch(fetchfinalpositiondropdown())
  }
  const history = useNavigate();
  const handleForm = () => {
    if (Type.id === 1 && Group_Name !== '') {
      setshowForm(true);
      setshowSelection(false);
    }
    if (Type.id === 2 && Group_Name !== '') {
      setshowCastForm(true);

      setshowSelection(false);
    }
  };
  return (
    <Fragment>
      <div className="page">
        <div className="rightsidedata">
          <div
            style={{
              marginTop: "30px",
            }}
          >
            <div className="Headers">
              <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '90%'
              }}>
                <h4> Create Point Table</h4>
                <h4>Length {CastCount - items.length}</h4>
              </div>
            </div>

            {showSelection ? (
              <div className="pointsAdd">



                <div className="pointsDropdown">

                  <Select
                    placeholder={<div>Type/Category</div>}
                    defaultValue={Type}
                    onChange={setType}
                    options={CategoryType}
                    isClearable={true}
                    isSearchable={true}
                  />
                </div>
                {Type && Type.id === 1 ? (
                  <input
                    placeholder="# No"
                    onChange={(e) => setCastCount(e.target.value)}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onPaste={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }} />
                ) : (
                  <input
                    placeholder="# No"
                    onChange={(e) => setCastCount(e.target.value)}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onPaste={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                )}
                <div className="pointsDropdown">
                  <Select
                    placeholder={<div>Group Name</div>}
                    defaultValue={Group_Name}
                    onChange={setGroup_Name}
                    options={AllGroup}
                    isClearable={true}
                    isSearchable={true}
                  />

                  <span className="spanFormcomp ">
                    <OverlayTrigger
                      overlay={<Tooltip id={`tooltip-top`}>Add more</Tooltip>}
                    >
                      <span className="addmorecomp " onClick={handleshowHorse}>
                        +
                      </span>
                    </OverlayTrigger>
                    <OverlayTrigger
                      overlay={
                        <Tooltip id={`tooltip-top`}>Fetch New</Tooltip>
                      }
                    >
                      <span className="addmorecomp" onClick={fetchall}>
                        <AiOutlineReload />
                      </span>
                    </OverlayTrigger>{" "}
                  </span>
                </div>

                <button onClick={handleForm} className="SubmitButton">
                  Add Points{" "}
                </button>
              </div>
            ) : (
              <></>
            )}

            {showForm ? (
              <div className="form">
                <div className="casttable">
                  <div className="pointsSelectHeader">
                    <th>Rank</th>
                    <th>Group Name</th>
                    {/* <th>Final Position</th> */}
                    <th>Points</th>
                    <th>Bonus Point</th>
                    <th>Action</th>
                  </div>
                  <div className="pointsForm">
                    <input
                      onChange={(event) => {
                        const value = Number(event.target.value);
                        setRank(value);
                      }}
                      value={Rank}
                      readOnly
                    />
                    <input value={Group_Name.value} />
                    {/* <div className="pointsDropdowns">
                      <Select
                        placeholder={<div>Final Position</div>}

                        options={finalposition}
                        isClearable={true}
                        isSearchable={true}
                      />

                      <span className="spanFormcomp  ">
                        <OverlayTrigger
                          overlay={<Tooltip id={`tooltip-top`}>Add more</Tooltip>}
                        >
                          <span className="addmorecomp addmorecomp1 " onClick={handleshowFinalposition}>
                            +
                          </span>
                        </OverlayTrigger>
                        <OverlayTrigger
                          overlay={
                            <Tooltip id={`tooltip-top`}>Fetch New</Tooltip>
                          }
                        >
                          <span className="addmorecomp addmorecomp1" onClick={fetchall}>
                            <AiOutlineReload />
                          </span>
                        </OverlayTrigger>{" "}
                      </span>
                    </div> */}
                    <input
                      onChange={(e) =>
                        setregisteration({
                          ...registeration,
                          Point: e.target.value,
                        })
                      }
                      value={registeration.Point}
                    />
                    <input
                      onChange={(e) =>
                        setregisteration({
                          ...registeration,
                          Bonus_Point: e.target.value,
                        })
                      }
                      value={registeration.Bonus_Point}
                    />

                    <button className="savebtnhorse" onClick={(e) => saveItem(e)}>
                      <TiTick />
                    </button>
                  </div>
                  {items.map((item, i) => {
                    return (
                      <div className="pointsForm">
                        <input value={item.Rank} />
                        <input value={Group_Name.value} />
                        <input value={item.Point} />
                        <input value={item.BonusPoint} />
                        <button className="removebtnhorse" onClick={() => removesingleItem(i)}>
                          <ImCross />
                        </button>
                      </div>
                    );
                  })}


                  {/* <table>
                    <thead>
                 
                      <th>Action</th>

                    </thead>
                  
                {runCallback(() => {
                  const row = [];
                  const total = CastCount;
                  for (var i = 0; i < total; i++) {
                    row.push(
                      <tbody className="casttablebody">
                        <td  style={{
                          color:'#000'
                        }} 
                       >
                     <input placeholder="Rank" onChange={(e) =>
                      setregisteration({
                        ...registeration,
                        Rank: e.target.value,
                      })
                    }/>
                       </td>
                        <td>{registeration.Group_Name}</td>
                        <td>
                        <input placeholder="Point Table"  onChange={(e) =>
                      setregisteration({
                        ...registeration,
                        Point: e.target.value,
                      })
                    }/>
                        </td>
                        <td> 
                          <input placeholder="Bonus Point" onChange={(e) =>
                      setregisteration({
                        ...registeration,
                        Bonus_Point: e.target.value,
                      })
                    }/>
                        </td>
                        <td ><TiTick onClick={(e) => saveItem(e,i)} className='saveinputpoint'/></td>
                      </tbody>
                    );
                  }
                  return row;
                })}
                </table> */}
                </div>
                <div
                  className="ButtonSection "
                  style={{ justifyContent: "end" }}
                >
                  <button
                    Name="submit"
                    className="SubmitButton"
                    disabled={isLoading}
                    onClick={submit}
                  >
                    Add Point Table
                  </button>
                </div>
              </div>
            ) : (
              <></>
            )}
            {showCastForm ? (
              <div className="form">
                <div className="casttable">
                  <div className="pointsSelectHeader">
                    <th>Rank</th>
                    <th>Group Name</th>
                    <th>Points</th>
                    <th>Bonus Point</th>
                    <th>Action</th>
                  </div>
                  <div className="pointsForm">
                    <input
                      onChange={(event) => {
                        const value = Number(event.target.value);
                        setRank(value);
                      }}
                      value={Rank}
                      readOnly
                    />
                    <input value={Group_Name.value} />
                    {/* <div className="pointsDropdowns">
                      <Select
                        placeholder={<div>Final Position</div>}

                        options={finalposition}
                        isClearable={true}
                        isSearchable={true}
                      />

                      <span className="spanFormcomp  ">
                      
                        <OverlayTrigger
                          overlay={
                            <Tooltip id={`tooltip-top`}>Fetch New</Tooltip>
                          }
                        >
                          <span className="addmorecomp addmorecomp1" onClick={fetchall}>
                            <AiOutlineReload />
                          </span>
                        </OverlayTrigger>{" "}
                      </span>
                    </div> */}
                    <input
                      onChange={(e) =>
                        setregisteration({
                          ...registeration,
                          Point: e.target.value,
                        })
                      }
                      value={registeration.Point}
                    />
                    <input
                      onChange={(e) =>
                        setregisteration({
                          ...registeration,
                          Bonus_Point: e.target.value,
                        })
                      }
                      value={registeration.Bonus_Point}
                    />

                    <button className="savebtnhorse" onClick={(e) => saveItem(e)}>
                      <TiTick />
                    </button>
                  </div>
                  {items.map((item, i) => {
                    return (
                      <div className="pointsForm">
                        <input value={item.Rank} />
                        <input value={Group_Name.value} />
                        
                        <input value={item.Point} />
                        <input value={item.BonusPoint} />
                        <button className="removebtnhorse" onClick={() => removesingleItem(i)}>
                          <ImCross />
                        </button>
                      </div>
                    );
                  })}


                  {/* <table>
                      <thead>
                   
                        <th>Action</th>

                      </thead>
                    
                  {runCallback(() => {
                    const row = [];
                    const total = CastCount;
                    for (var i = 0; i < total; i++) {
                      row.push(
                        <tbody className="casttablebody">
                          <td  style={{
                            color:'#000'
                          }} 
                         >
                       <input placeholder="Rank" onChange={(e) =>
                        setregisteration({
                          ...registeration,
                          Rank: e.target.value,
                        })
                      }/>
                         </td>
                          <td>{registeration.Group_Name}</td>
                          <td>
                          <input placeholder="Point Table"  onChange={(e) =>
                        setregisteration({
                          ...registeration,
                          Point: e.target.value,
                        })
                      }/>
                          </td>
                          <td> 
                            <input placeholder="Bonus Point" onChange={(e) =>
                        setregisteration({
                          ...registeration,
                          Bonus_Point: e.target.value,
                        })
                      }/>
                          </td>
                          <td ><TiTick onClick={(e) => saveItem(e,i)} className='saveinputpoint'/></td>
                        </tbody>
                      );
                    }
                    return row;
                  })}
                  </table> */}
                </div>
                <div
                  className="ButtonSection "
                  style={{ justifyContent: "end" }}
                >
                  <button
                    Name="submit"
                    className="SubmitButton"
                    disabled={isLoading}
                    onClick={submit}
                  >
                                         Save

                  </button>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <Modal show={showHorse} onHide={handleCloseHorse}

        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Add Group Name</Modal.Title>
        </Modal.Header>
        <Modal.Body><GroupName /> </Modal.Body>

      </Modal>

      <Modal show={showFinalPosiiton} onHide={handleFinalPositionClose}

        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Add Final Position</Modal.Title>
        </Modal.Header>
        <Modal.Body><FinalPosition /> </Modal.Body>

      </Modal>

    </Fragment>
  );
};

export default PointForm;
