import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});

const getSearchRaceNameSlice = createSlice({
    name: 'searchRaceName',
    initialState: {
        data: [],
        status: STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchSearchRaceName.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(fetchSearchRaceName.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.IDLE
            })
            .addCase(fetchSearchRaceName.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
    }
});

export const { setRaceName, setStatus } = getSearchRaceNameSlice.actions;
export default getSearchRaceNameSlice.reducer;

export const fetchSearchRaceName = createAsyncThunk('/searchracename/fetch', async ({ Value2 }) => {
    const res = await axios.get(`${window.env.API_URL}/searchracename?NameEn=${Value2 === undefined ? "" : Value2}`);
    const RaceNameData = res.data;
    return RaceNameData.data;
})