import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});

const getColorListSlice = createSlice({
    name: 'colorList',
    initialState: {
        data: [],
        status: STATUSES.IDLE,
        totalCount: '',
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchcolorList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(fetchcolorList.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.IDLE;
                state.totalCount = state.colorListData
            })
            .addCase(fetchcolorList.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
    }
});

export const { setcolorList, setStatus } = getColorListSlice.actions;
export default getColorListSlice.reducer;

export const fetchcolorList = createAsyncThunk('/ColorListget/fetch', async ({ SearchTitle, SearchCode, currentPage }) => {
    const res = await axios.get(`${window.env.API_URL}/Colorget?shortCode=${SearchCode}&NameEn=${SearchTitle}&page=${currentPage}`);
    const colorListData = res.data;
    return colorListData;
    // return colorListData.totalCount
})