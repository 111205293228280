import React, { useState, Fragment, useEffect } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { AiOutlineReload } from "react-icons/ai";
import TextInputValidation from "../../../utils/TextInputValidation";
import { fetchcategory } from "../../../redux/getReducer/getCategory";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { fetchSponsorDropdown } from "../../../redux/getDropDownReducer/getSponsor";

import { Modal } from "react-bootstrap";
import SponsorPopup from "../SponsorForm";
import CategoryPopup from "./AddCategory";
import Loader from "../../../Components/Common/Loader";
import { Form, Input, DatePicker, Select } from "antd";

//----------------------------category dropdown options -------------------------//
const CategoryType = [
  {
    id: 1,
    value: "pick",
    label: "Pick",
  },
  {
    id: 1,
    value: "cast",
    label: "Cast",
  },
];

const CompetionForm = () => {
  //----------------------------Error-------------------------//
  const { Option } = Select;

  const [isLoading, setisLoading] = useState(false);

  const [showSponsor, setShowSponsor] = useState(false);
  const [showCategory, setShowCategory] = useState(false);

  const handleCloseSponsor = () => setShowSponsor(false);
  const handleCloseCategory = () => setShowCategory(false);
  const handleShowSponsor = async () => {
    await setShowSponsor(true);
  };
  //----------------------------show popup-------------------------//
  const handleShowCategory = async () => {
    await setShowCategory(true);
  };
  const history = useNavigate();
  const dispatch = useDispatch();
  const [value, setSearchAge] = useState("");

  const { data: category } = useSelector((state) => state.category);
  const { data: sponsordropdown } = useSelector((state) => state.sponsordropdown);




  useEffect(() => {
    dispatch(fetchcategory());
  }, [dispatch]);

  useEffect(() => {

    dispatch(fetchSponsorDropdown({value}));
  }, [dispatch, value]);

  const FetchNew = () => {
    dispatch(fetchcategory());
    dispatch(fetchSponsorDropdown());
  };
  //----------------------------category dropdown options -------------------------//

  const [NameAr, setNameAr] = useState("");
  const [NameEn, setNameEn] = useState("");
  const [CodeEn, setCodeEn] = useState("");

  const [StartDate, setStartDate] = useState("");
  const [EndDate, setEndDate] = useState("");
  const [Type, setType] = useState("");
  const [NumberOfRace, setNumberOfRace] = useState("");
  const [CompetitionCategory, setCompetitionCategory] = useState("");
  const [Sponsor, setSponsor] = useState("");

  // const [NumberOfPosition, setNumberOfPosition] = useState("");

  const handleDateChange = (date, dateString) => {
    setStartDate(dateString);
  };

  const handleDateChange1 = (date, dateString) => {
    setEndDate(dateString);
  };

  //----------------------------submit-------------------------//
  const submit = async (event) => {
    // event.preventDefault();
    setisLoading(true);
    try {
      const formData = new FormData();
      formData.append("NameEn", NameEn);
      formData.append("NameAr", NameAr);
      formData.append("CompetitionCode", CodeEn);
      formData.append("StartDate", StartDate);
      formData.append("EndDate", EndDate);
      formData.append("CompetitionCategory", Type);
      formData.append("CategoryCount", NumberOfRace);
      formData.append("CompetitionType", CompetitionCategory);
      formData.append("CompetitionSponsor", Sponsor);

      const res = await axios.post(
        `${window.env.API_URL}/uploadCompetiton`,
        formData
      );
      swal({
        title: "Success!",
        text: "Data has been added Successfully",
        icon: "success",
        button: "OK",
      });
      const CompetitionId = res.data.data;
      history("/competitionrace", {
        state: {
          CompetitionId: CompetitionId,
        },
      });
      isLoading(false);
    } catch (error) {
      const err = error.response.data.message;

      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
      setisLoading(false);
    }
  };

  const handleSearch = async (value) => {
    setSearchAge(value);
  };

  const handleChange = async (value) => {
    await setCompetitionCategory(value);
  };

  const handleChange1 = async (value) => {
    await setSponsor(value);
  };

  const handleChange2 = async (value) => {
    await setType(value);
  };

  //----------------------------input validation-------------------------//
  const data1 = JSON.stringify(
    TextInputValidation("en", NameEn, "Competition Name English")
  );

  return (
    <Fragment>
      <div className="page">
        <div className="rightsidedata">
          <div
            style={{
              marginTop: "30px",
            }}
          >
            <div className="Headers">Competition</div>
            {!isLoading ? (
              <div className="form">
                <Form onFinish={submit} name="time_related_controls">
                  <div className="row mainrow">
                    <div className="col-sm">
                      <Form.Item
                        name="NameEn"
                        label="Name"
                        rules={[
                          {
                            required: true,
                            message: "Name (English) is required",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Name"
                          onChange={(e) => setNameEn(e.target.value)}
                          value={NameEn}
                          name="NameEn"
                        />
                      </Form.Item>

                      <span className="spanForm spacer"> |</span>
                    </div>
                    <div className="col-sm">
                      <Form.Item
                        name="NameAr"
                        label="اسم"
                        rules={[
                          {
                            required: true,
                            message: "Name (Arabic) is required",
                          },
                        ]}
                        style={{ direction: "rtl" }}
                      >
                        <Input
                          placeholder="اسم"
                          onChange={(e) => setNameAr(e.target.value)}
                          name="NameAr"
                          value={NameAr}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="row mainrow">
                    <div className="col-sm">
                      <Form.Item
                        name="Code"
                        label="Code"
                        rules={[
                          {
                            required: true,
                            message: "Code (English) is required",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Code"
                          onChange={(e) => setCodeEn(e.target.value)}
                          value={CodeEn}
                          name="Code"
                        />
                      </Form.Item>

                      {/* <span className="spanForm spacer" > |</span> */}
                    </div>
                  </div>

                  <div className="row mainrow">
                    <div className="col-sm">
                      <Form.Item
                        name="date-picker1"
                        label="Start Date"
                        rules={[
                          {
                            required: true,
                            message: "Start Date is required",
                          },
                        ]}
                      >
                        <DatePicker
                          value={StartDate}
                          onChange={handleDateChange}
                          format="DD-MM-YYYY"
                        />
                      </Form.Item>
                    </div>

                  </div>

                  <div className="row mainrow">
                    <div className="col-sm">
                      <Form.Item
                        name="date-picker"
                        label="End Date"
                        
                        rules={[
                          {
                            required: true,
                            message: "End Date is required",
                          },
                        ]}
                      >
                        <DatePicker
                          value={EndDate}
                          onChange={handleDateChange1}
                          format="DD-MM-YYYY"
                          
                          
                        />
                      </Form.Item>
                    </div>

                  </div>

                  <div className="row mainrow">
                    <div className="col-sm">
                      <Form.Item
                        name="Category"
                        label="Category"
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: "Please select your Category!",
                          },
                        ]}
                      >
                        <Select
                          value={CompetitionCategory}
                          filterOption={false}
                          onChange={handleChange}
                          showSearch
                          placeholder="Please select a Category"
                        >
                          {category?.map((item) => (
                            <Option value={item._id}>{item.NameEn}</Option>
                          ))}
                        </Select>
                      </Form.Item>

                      <span className="spanForm spacer">
                        <OverlayTrigger
                          overlay={
                            <Tooltip id={`tooltip-top`}>Add more</Tooltip>
                          }
                        >
                          <span
                            className="addmore"
                            onClick={handleShowCategory}
                          >
                            +
                          </span>
                        </OverlayTrigger>
                        <OverlayTrigger
                          overlay={
                            <Tooltip id={`tooltip-top`}>Fetch New</Tooltip>
                          }
                        >
                          <span className="addmore" onClick={FetchNew}>
                            <AiOutlineReload />
                          </span>
                        </OverlayTrigger>{" "}
                      </span>
                    </div>
                  </div>

                  <div className="row mainrow">
                    <div className="col-sm">
                      <Form.Item
                        name="Sponsor"
                        label="Sponsor"
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: "Please select your Sponsor!",
                          },
                        ]}
                      >
                        <Select
                          value={Sponsor}
                          onSearch={handleSearch}
                          filterOption={false}
                          onChange={handleChange1}
                          showSearch
                          placeholder="Please select a Sponsor"
                        >
                            {sponsordropdown.map((item) => (
                            <Option key={item._id} value={item._id}>
                              <div style={{ display: "flex", alignItems: "center" }}>
                                <img
                                  src={item.image}
                                  alt={item.image}
                                  style={{ width: "24px", height: "24px", marginRight: "8px" }}
                                />
                                {item.TitleEn} ({item.TitleAr})
                              </div>
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>

                      <span className="spanForm spacer">
                        <OverlayTrigger
                          overlay={
                            <Tooltip id={`tooltip-top`}>Add more</Tooltip>
                          }
                        >
                          <span className="addmore" onClick={handleShowSponsor}>
                            +
                          </span>
                        </OverlayTrigger>
                        <OverlayTrigger
                          overlay={
                            <Tooltip id={`tooltip-top`}>Fetch New</Tooltip>
                          }
                        >
                          <span className="addmore" onClick={FetchNew}>
                            <AiOutlineReload />
                          </span>
                        </OverlayTrigger>{" "}
                      </span>
                    </div>
                  </div>

                  <div className="row mainrow">
                    <div className="col-sm">
                      <Form.Item
                        name="Type"
                        label="Type"
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: "Please select your Type!",
                          },
                        ]}
                      >
                        <Select
                          value={Type}
                          filterOption={false}
                          onChange={handleChange2}
                          showSearch
                          placeholder="Please select a Type"
                        >
                          {CategoryType?.map((item) => (
                            <Option value={item.value}>{item.label}</Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>

                  {Type === null ? (
                    <></>
                  ) : Type === "cast" ? (
                    <>
                      <div className="row mainrow">
                        <div className="col-sm">
                          <Form.Item
                            name="NoRaces"
                            label="No of Races in Competition"
                            rules={[
                              {
                                required: true,
                                message: "No of Races is required",
                              },
                            ]}
                          >
                            <Input
                              placeholder="No of Races"
                              type="number"
                              onChange={(e) => setNumberOfRace(e.target.value)}
                              value={NumberOfRace}
                              name="Code"
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="row mainrow">
                        <div className="col-sm">
                          <Form.Item
                            name="NoRaces"
                            label="No of Races in Competition"
                            rules={[
                              {
                                required: true,
                                message: "No of Races is required",
                              },
                            ]}
                          >
                            <Input
                              placeholder="No of Races"
                              type="number"
                              onChange={(e) => setNumberOfRace(e.target.value)}
                              value={NumberOfRace}
                              name="Code"
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </>
                  )}

                  <div
                    className="ButtonSection "
                    style={{ justifyContent: "end" }}
                  >
                    <div className="sbmtbtndiv">
                      <div className="RaceButtonDiv">
                        <button
                          className="SubmitButton"
                          type="submit"
                          disabled={isLoading}
                        >
                          Add Races
                        </button>
                      </div>
                    </div>
                   
                  </div>
                </Form>
              </div>
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </div>
      <Modal
        show={showSponsor}
        onHide={handleCloseSponsor}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2>Sponsor</h2>
        </Modal.Header>
        <Modal.Body>
          <SponsorPopup setShowSponsor={setShowSponsor} />
        </Modal.Body>
      </Modal>
      <Modal
        show={showCategory}
        onHide={handleCloseCategory}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2>Competition Category</h2>
        </Modal.Header>
        <Modal.Body>
          <CategoryPopup setShowCategory={setShowCategory} />
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default CompetionForm;
