import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});

const getEditRaceResultSlice = createSlice({
    name: 'editraceresult',
    initialState: {
        data: [],
        status: STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchEditRaceResult.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(fetchEditRaceResult.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.IDLE
            })
            .addCase(fetchEditRaceResult.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
    }
});

export const { setEditRaceResult, setStatus } = getEditRaceResultSlice.actions;
export default getEditRaceResultSlice.reducer;

export const fetchEditRaceResult = createAsyncThunk('/GetEditRaceResult/fetch', async ({ RaceId }) => {
    const res = await axios.get(`${window.env.API_URL}GetEditRaceResultV2/${RaceId}`);
    const EditRaceHorseData = res.data;
    return EditRaceHorseData.data.RaceResultData;
})  