import React, { Fragment, useState, useEffect } from "react";
import "../../Components/CSS/forms.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import { fetchNationalityList } from "../../redux/getDropDownReducer/getNationalityList";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";


import ColorPopUp from "../PostTable/Color";
import NationalityPopUp from "../PostTable/Nationality";
import { AiOutlineReload } from "react-icons/ai";
import { Modal } from "react-bootstrap";

import { ImCross } from "react-icons/im";
import { fetchColorDropdown } from "../../redux/getDropDownReducer/getColor";
import Loader from "../../Components/Common/Loader";
import { Form, Input , Select} from 'antd';

const RaceCourseForm = ({ setShowRaceCourse }) => {
  //----------------------------State For Errors -------------------------//

  const [ErrorColor, setErrorColor] = useState("");
  const { Option } = Select;

  const [ErrorNationality, setErrorNationality] = useState("");

  const [isLoading, setisLoading] = useState(false);

  const dispatch = useDispatch();
  const history = useNavigate();
  const { pathname } = useLocation();

  const { data: NationalityList } = useSelector(
    (state) => state.NationalityList
  );

  const { data: colordropdown } = useSelector((state) => state.colordropdown);

  
  const handleSearch = async (value) => {
    try {
      dispatch(fetchNationalityList({ value }))

    } catch (error) {

    }
  };

  const handleChange = async (value) => {
    await setNationalityId(value)
  setValue(value)
  };

  const handleSearch1 = async (Value2) => {
    try {
      dispatch(fetchColorDropdown({ Value2 }))

    } catch (error) {

    }
  };

  const handleChange1 = async (value) => {
    await setColorCode(value)
  setValue1(value)
  };




  //----------------------------options form dropdown -------------------------//
  let AllNationality =
    NationalityList === undefined ? (
      <></>
    ) : (
      NationalityList.map(function (item) {
        return {
          id: item._id,
          value: item.NameEn,
          label: (
            <span
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <p>{item.NameEn}</p>
              <p>{item.NameAr}</p>
            </span>
          ),
        };
      })
    );

  let AllColor =
    colordropdown === undefined ? (
      <></>
    ) : (
      colordropdown.map(function (item) {
        return {
          id: item._id,
          value: item.NameEn,
          label: (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <p>{item.NameEn}</p>
              <p>{item.NameAr}</p>
            </div>
          ),
        };
      })
    );


  const [TrackNameEn, setTrackNameEn] = useState("");
  const [TrackNameAr, setTrackNameAr] = useState("");

  // const [shortCode, setshortCode] = useState("");
  const [AbbrevEn, setAbbrevEn] = useState("");
  const [AbbrevAr, setAbbrevAr] = useState("");
  const [NationalityId, setNationalityId] = useState("");
  const [ColorCode, setColorCode] = useState("");
  const [value, setValue] = useState("");
  const [value1, setValue1] = useState("");
  const [image, setImage] = useState();
  const [preview, setPreview] = useState();

  const [showColor, setShowColor] = useState(false);
  const [showNationality, setShowNationality] = useState(false);
  //----------------------------for show popup -------------------------//
  const handleClose = () => setShowColor(false);
  const handleCloseNationality = () => setShowNationality(false);

  const handleShow = async () => {
    await setShowColor(true);
  };
  useEffect(() => {

    if (!image) {
      setPreview(undefined);
      return;
    }
    if (image.name.match(/\.(gif)$/)) {
      setImage("");
      setPreview(undefined);
      document.getElementById("file").value = "";
      swal({
        title: "Error!",
        text: "Invalid Format ",
        icon: "error",
        button: "OK",
      });
    }
    const objectUrl = URL.createObjectURL(image);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [dispatch, image]);
  const handleShowNationality = async () => {
    await setShowNationality(true);
  };
  //----------------------------submit -------------------------//
  const submit = async () => {

    if (NationalityId == "") {


      setErrorNationality("Nationality is Required")

    } else if (ColorCode == "") {


      setErrorColor("Color is Required")


    } else {

      setisLoading(true);
      try {
        const formData = new FormData();
        formData.append("image", image);
        formData.append("TrackNameEn", TrackNameEn);
        formData.append("TrackNameAr", TrackNameAr);

        formData.append("ColorCode", ColorCode === '' ? '' : ColorCode);
        formData.append("NationalityID", NationalityId);
        formData.append("AbbrevEn", AbbrevEn);
        formData.append("AbbrevAr", AbbrevAr);
        formData.append("Color", "#fff")

        // formData.append("shortCode", shortCode);
        await axios.post(
          `${window.env.API_URL}/createcourse`,
          formData
        );
        swal({
          title: "Success!",
          text: "Data has been added Successfully",
          icon: "success",
          button: "OK",
        });
        if (pathname === "/racecourseform") {
          history("/racecourse");
        } setShowRaceCourse(false)
        setisLoading(false);
      } catch (error) {
        const err = error.response.data.message;
        swal({
          title: "Error!",
          text: err,
          icon: "error",
          button: "OK",
        });
        setisLoading(false);
      }

    }
  };

  //----------------------------image Preview -------------------------//
  useEffect(() => {
    dispatch(fetchColorDropdown());




  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchNationalityList({value}));
  }, [dispatch , value]);

  const onSelectFile = (e) => {
    setImage(e.target.files[0]);
  };
  const handlePreview = () => {
    setImage();
    document.getElementById("file").value = "";
  };

  const FetchNew = () => {
    dispatch(fetchNationalityList({value}));
    dispatch(fetchColorDropdown());
  };



  return (
    <Fragment>
      <div className="page">
        <div className="rightsidedata">
          <div
            style={{
              marginTop: "30px",
            }}
          >
            <div className="Headers">Create Race Course</div>
            {isLoading ? <Loader /> : <div className="form">
              <Form onFinish={submit}>
                <div className="row mainrow">
                  <div className="col-sm">
                    <Form.Item
                      name="TrackNameEn"
                      label="Track Name "
                      rules={[
                        {
                          required: true,
                          message: 'Track Name  (English) is required',
                        },
                      ]}
                    >
                      <Input placeholder="Track Name" onChange={(e) => setTrackNameEn(e.target.value)}
                        name="TrackNameEn"
                        value={TrackNameEn} />
                    </Form.Item>











                    <span className="spanForm spacer"> |</span>

                  </div>

                  <div className="col-sm">


                    <Form.Item
                      name="TrackNameAr"
                      label="اسم المسار"
                      rules={[
                        {
                          required: true,
                          message: 'Track Name (Arabic) is required',
                        },
                      ]}
                      style={{ direction: 'rtl' }}
                    >
                      <Input placeholder="اسم المسار" name="TrackNameAr" onChange={(e) => setTrackNameAr(e.target.value)}
                        value={TrackNameAr} />
                    </Form.Item>











                  </div>
                </div>
                <div className="row mainrow">
                  <div className="col-sm">
                    <Form.Item
                      name="Abbreviation"
                      label="Abbreviation"
                      rules={[
                        {
                          required: true,
                          message: 'Abbreviation is required',
                        },
                      ]}
                    >
                      <Input placeholder="Abbreviation"

                        onChange={(e) => setAbbrevEn(e.target.value)}
                        value={AbbrevEn} />
                    </Form.Item>

                    <span className="spanForm spacer "> |</span>

                  </div>

                  <div className="col-sm">








                    <Form.Item
                      name="اكتب الاختصار"
                      label="اكتب الاختصار"
                      rules={[
                        {
                          required: true,
                          message: 'Abbreviation  (Arabic) is required',
                        },
                      ]}
                      style={{ direction: 'rtl' }}
                    >
                      <Input placeholder="اكتب الاختصار" onChange={(e) => setAbbrevAr(e.target.value)}
                        value={AbbrevAr}




                      />
                    </Form.Item>








                  </div>
                </div>


                <div className="row mainrow">
                  <div className="col-sm">


                    <Form.Item

                      name="select1a"
                      label="Color"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: 'Please select your Color!',
                        },
                      ]}
                    >
                      <Select
                        value={ColorCode}
                        filterOption={false}
                        onChange={handleChange1}
                        onSearch={handleSearch1}
                        showSearch placeholder="Please select a Color" >

                        {
                          colordropdown?.map((item) => (


                            <Option value={item._id}>{item.NameEn} ({item.NameAr})</Option>

                          ))
                        }
                      </Select>
                    </Form.Item>
                 
                    <span className="spanForm selectSpacer">
                      <OverlayTrigger
                        overlay={<Tooltip id={`tooltip-top`}>Add more</Tooltip>}
                      >
                        <span
                          className="addmore"
                          onClick={handleShow}
                        >
                          +
                        </span>
                      </OverlayTrigger>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id={`tooltip-top`}>Fetch New</Tooltip>
                        }
                      >
                        <span className="addmore" onClick={FetchNew}>
                          <AiOutlineReload />
                        </span>
                      </OverlayTrigger>{" "}
                    </span>

                  </div>
                </div>

                <div className="row mainrow">
                  <div className="col-sm">


                    <Form.Item

                      name="select"
                      label="Nationality"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: 'Please select your Nationality!',
                        },
                      ]}
                    >
                      <Select
                        value={NationalityId}
                        filterOption={false}
                        onChange={handleChange}
                        onSearch={handleSearch}
                        showSearch placeholder="Please select a Nationality" >

                        {
                          NationalityList?.map((item) => (


                            <Option value={item._id}>{item.NameEn} ({item.NameAr})</Option>

                          ))
                        }
                      </Select>
                    </Form.Item>
                 
                    <span className="spanForm selectSpacer">
                      <OverlayTrigger
                        overlay={<Tooltip id={`tooltip-top`}>Add more</Tooltip>}
                      >
                        <span
                          className="addmore"
                          onClick={handleShowNationality}
                        >
                          +
                        </span>
                      </OverlayTrigger>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id={`tooltip-top`}>Fetch New</Tooltip>
                        }
                      >
                        <span className="addmore" onClick={FetchNew}>
                          <AiOutlineReload />
                        </span>
                      </OverlayTrigger>{" "}
                    </span>

                  </div>
                </div>

                




                <div className="ButtonSection">
                  <div>
                    <label className="Multipleownerlabel">
                      Select Race Course image
                    </label>
                    <input
                      type="file"
                      onChange={onSelectFile}
                      className="formInput"
                      id="file"
                    />
                    {image && (
                      <>
                        <ImCross
                          onClick={handlePreview}
                          className="crossIcon"
                        />
                        <img src={preview} className="PreviewImage" alt="" />
                      </>
                    )}
                  </div>
                  <div>

                    <button
                      type="submit"
                      className="SubmitButton"
                      disabled={isLoading}
                    >
                     Save
                    </button>
                  </div>
                </div>
              </Form>
            </div>}
          </div>
        </div>
      </div>
      <Modal
        show={showColor}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2>Add Color</h2>
        </Modal.Header>
        <Modal.Body>
          <ColorPopUp setShowColor={setShowColor} />
        </Modal.Body>
      </Modal>

      <Modal
        show={showNationality}
        onHide={handleCloseNationality}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2>Add Nationality</h2>
        </Modal.Header>
        <Modal.Body>
          <NationalityPopUp setShowNationality={setShowNationality} />
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default RaceCourseForm;
