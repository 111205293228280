import React, { Fragment, useState ,useEffect} from "react";
import { BiEdit } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { fetchEmailTemplateget, STATUSES } from "../redux/getReducer/getEmailList";
import { useNavigate } from "react-router-dom";
import Pagination from "./GetTable/Pagination";

const NotificationTable = () => {
    const dispatch = useDispatch();
    const { data: EmailTemplateget, status } = useSelector((state) => state.EmailTemplateget);
    const history = useNavigate();

    let totalcountdata = EmailTemplateget ? EmailTemplateget?.length : "";
  const [postsPerPage] = useState(11);
  const totalpagesdata = Math.ceil(totalcountdata / postsPerPage);
  const [currentPage, setCurrentPage] = useState(1);
  const firstPageIndex = (currentPage - 1) * postsPerPage;
  const lastPageIndex = firstPageIndex + postsPerPage;
  const SliceData =  EmailTemplateget?.slice(firstPageIndex, lastPageIndex);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);


    useEffect(() => {
        dispatch(fetchEmailTemplateget());
      }, [dispatch]);

    
console.log(EmailTemplateget,'EmailTemplateget')
    return (
        <Fragment>
            <div className="page">
                <div className="rightsidedata">
                    <div
                        style={{
                            marginTop: "30px",
                        }}
                    >
                        <div className="Header ">
                            <h4>All Emails</h4>
                        </div>
                      
                        <>
                            <div className="div_maintb emaildata">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Action</th>
                                            <th>Subject</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            SliceData.map((item,index) => {
                                                return(
                                               <tr className="tr_table_class" key={index}>
                                            <td className="table_delete_btn1">
                                            <BiEdit
                                      onClick={() =>
                                        history("/sendemail", {
                                          state: {
                                            id: item._id,
                                          },
                                        })
                                      }
                                                />
                                               
                                                
                                            </td>
                                            <td>{item.TemplateName}</td>
                                           
                                        </tr>
                                                )
                                            })
                                        }
                                        

                                     


                                    </tbody>
                                    
                                </table>
                            </div>
                        </>
                    </div>
                    <span className="plusIconStyle"></span>
                    <Pagination
                postsPerPage={postsPerPage}
                totalPosts={totalcountdata}
                paginate={paginate}
                currentPage={currentPage}
                TotalPages={totalpagesdata}
              />
                </div>
            </div>
         
        </Fragment>
    );
};

export default NotificationTable;
