import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});

const getNationalityListSlice = createSlice({
    name: 'NationalityList',
    initialState: {
        data: [],
        status: STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchNationalityList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(fetchNationalityList.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.IDLE
            })
            .addCase(fetchNationalityList.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
    }
});

export const { setHorseList, setStatus } = getNationalityListSlice.actions;
export default getNationalityListSlice.reducer;

export const fetchNationalityList = createAsyncThunk('/NationalityDropDown/fetch', async ({ value }) => {

    const res = await axios.get(`${window.env.API_URL}/NationalityDropDown?NameEn=${value === undefined ? "" : value}&size=20`);
    const NationalityListData = res.data;
    return NationalityListData.data;
})