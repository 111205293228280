import React, { useEffect, Fragment, useState } from "react";
import { STATUSES } from "../../redux/getReducer/getRaceName";
import { useDispatch, useSelector } from "react-redux";
import { MdAutoDelete, MdDelete } from "react-icons/md";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import ScrollContainer from "react-indiana-drag-scroll";
import Lottie from "lottie-react";
import HorseAnimation from "../../assets/horselottie.json";
import axios from "axios";
import { BiEdit } from "react-icons/bi";
import { BsEyeFill } from "react-icons/bs";
import RaceNamePopup from "../../Components/Popup/RaceNamePopup";
import { Modal } from "react-bootstrap";
import Pagination from "./Pagination";
import { BiFilter } from "react-icons/bi";
import { CSVLink } from "react-csv";
import Notfound from "../../Notfound";

const Racename = () => {
  const [ShowCalender, setShowCalender] = useState(false);
  const [NameEn, setNameEn] = useState("");
  const [SearchData, setSearchData] = useState([]);
  const [shortCode, setshortCode] = useState("");
  const [TotalCount, setTotalCount] = useState();
  const [loading, setLoading] = useState(false);
  const [TotalPages, setTotalPages] = useState("");
  //for Modal
  const [show, setShow] = useState(false);
  const [modaldata, setmodaldata] = useState();
  const handleClose = () => setShow(false);
  const handleShow = async (data) => {
    setmodaldata(data);
    await setShow(true);
  };

  const { userInfo } = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const history = useNavigate();

  const { data: RaceName, status } = useSelector((state) => state.RaceName);

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(8);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  //---------------------------search-------------------------//
  const GetSearch = async () => {
    setLoading(true);
    const response = await axios.get(
      `${window.env.API_URL}/searchracename?NameEn=${NameEn}&shortCode=${shortCode}&page=${currentPage} `
    );
    setSearchData(response.data.data);
    setLoading(false);

    const totalcount = response.data.totalcount;
    setTotalCount(totalcount);

    const TotalPagesData = response.data.totalPages;
    setTotalPages(TotalPagesData);
    if(TotalPagesData == 1){
      setCurrentPage(1)
    }

  };
  useEffect(() => {
    GetSearch({ currentPage });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, currentPage]);
  //---------------------------delete-------------------------//
  const handleRemove1 = async (Id) => {
    try {
      swal({
        title: "Are you sure?",
        text: "do you want to delete this data ?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          await axios.delete(`${window.env.API_URL}/softdeleteRaceName/${Id}`);
          swal("Your data has been deleted Successfully!", {
            icon: "success",
          });
          GetSearch();
        } else {
          swal("Your data is safe!");
        }
      });
    } catch (error) {
      const err = error.response.data.message;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
    }
  };

  const handleRemove = async (id) => {
    try {
      swal({
        title: "Are you sure?",
        text: "do you want to delete this data ?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          setLoading(true)
          const res = await axios.delete(`${window.env.API_URL}/DeleteRaceNameAlltoAll/${id}`);
          const msg = res.data.message ?  res.data.message : "deleted";
          swal(msg, {
            icon: "",
          });
      let data1 =  JSON.stringify(res.data.data)
      const blob = new Blob([data1]);
      if(res?.data?.flag){
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.download = "report1.json";
        link.href = url;
        link.click();
        setLoading(false)
            GetSearch({ currentPage });

      }     
      setLoading(false)
          GetSearch({ currentPage });

        } else {
          swal("Your data is safe!");
        }
      });
    } catch (error) {
      const err = error.success;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
      setLoading(false)

    }
  }

  if (status === STATUSES.ERROR) {
    return (
      <h2
        style={{
          margin: "100px",
        }}
      >
        Something went wrong!
      </h2>
    );
  }

  return (
    <Fragment>
      <div className="page">
        <div className="rightsidedata">
          <div
            style={{
              marginTop: "30px",
            }}
          >
            <div className="Header ">
              <h4>Race Name Listings</h4>

              <div>
                <Link to="/racenameform">
                  <button>Add Race Name</button>
                </Link>
                <OverlayTrigger
                  overlay={<Tooltip id={`tooltip-top`}>Filter</Tooltip>}
                >
                  <span className="addmore">
                    <BiFilter
                      className="calendericon"
                      onClick={() => setShowCalender(!ShowCalender)}
                    />
                  </span>
                </OverlayTrigger>{" "}
                <CSVLink
                  data={RaceName}
                  separator={";"}
                  filename={"MKS Race Name.csv"}
                  className="csvclass"
                >
                  Export CSV
                </CSVLink>
              </div>
            </div>
            <div>
              {ShowCalender ? (
                <span className="transitionclass">
                  <div className="userfilter">
                    <div className="filtertextform forflex">
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setNameEn(e.target.value)}
                        placeholder="Enter Name"
                      />

                      <input
                         onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                        className="form-control"
                        onChange={(e) => setshortCode(e.target.value)}
                        placeholder="Enter Short Code"
                      />
                    </div>
                  </div>
                  <button className="filterbtn" onClick={GetSearch}>
                    Apply Filter
                  </button>
                </span>
              ) : (
                <></>
              )}
            </div>
            <>
              <div className="div_maintb">
                <ScrollContainer>
                  <table>
                    <thead>
                      <tr>
                        <th>Action</th>

                        <th>Name</th>
                        <th>Name Arabic </th>

                        <th>Short Code</th>
                      </tr>
                    </thead>
                    {status === "loading" ? (
                      <Lottie
                        animationData={HorseAnimation}
                        loop={true}
                        className="TableLottie"
                      />
                    ) : SearchData === [] ? (
                      <Notfound />
                    ) : (
                      <tbody>
                        {SearchData.map((item, index) => {
                          return (
                            <>
                              <tr className="tr_table_class">
                                <td className="table_delete_btn1">
                                  <BiEdit
                                    onClick={() =>
                                      history("/editracename", {
                                        state: {
                                          racenameid: item,
                                        },
                                      })
                                    }
                                  />
                                  <MdAutoDelete
                                    style={{
                                      fontSize: "22px",
                                    }}
                                    onClick={() => handleRemove(item._id)}
                                  />
                                  <BsEyeFill onClick={() => handleShow(item)} />
                                </td>
                                <td>{item.NameEn}</td>
                                <td>{item.NameAr}</td>

                                <td>{item.shortCode} </td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    )}
                  </table>
                </ScrollContainer>
              </div>
            </>
          </div>
          <span className="plusIconStyle"></span>
          <Pagination
            postsPerPage={postsPerPage}
            totalPosts={TotalCount}
            paginate={paginate}
            currentPage={currentPage}
            TotalPages={TotalPages}
          />
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2 style={{ fontFamily: "inter" }}>Race Name</h2>
        </Modal.Header>
        <Modal.Body>
          <RaceNamePopup data={modaldata} />
        </Modal.Body>
        <Modal.Footer>
          <button onClick={handleClose} className="modalClosebtn">
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default Racename;
