import React, { useEffect, useState } from "react";

import { fetchColorDropdown } from "../../redux/getDropDownReducer/getColor";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { MdAutoDelete, MdDelete } from "react-icons/md";
import swal from "sweetalert";
import { Link, useNavigate } from "react-router-dom";
import { BiEdit } from "react-icons/bi";
import { Modal } from "react-bootstrap";
import RacecoursePopup from "../../Components/Popup/RacecoursePopup";
import ScrollContainer from "react-indiana-drag-scroll";
import Lottie from "lottie-react";
import HorseAnimation from "../../assets/horselottie.json";
import axios from "axios";
import { BsEyeFill } from "react-icons/bs";
import Pagination from "./Pagination";
import { BiFilter } from "react-icons/bi";
import { CSVLink } from "react-csv";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { fetchNationalityList } from "../../redux/getDropDownReducer/getNationalityList";

import Defaultimg from "../../assets/default.jpg";
import Notfound from "../../Notfound";

const Racecourse = () => {
  //---------------------------state-------------------------//
  const [ShowCalender, setShowCalender] = useState(false);
  const [SearchAge, setSearchAge] = useState("");
  const [NationalityId, setNationalityId] = useState();
  const [SearchTitle, setSearchTitle] = useState("");
  const [SearchData, setSearchData] = useState([]);
  const [TotalCount, setTotalCount] = useState();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const { userInfo } = useSelector((state) => state.user);
  const [value, setvalue] = useState('');
  const [Value2, setValue2] = useState('');

  const [TotalPages, setTotalPages] = useState("");
  const [modaldata, setmodaldata] = useState();
  const [colors, setcolor] = useState();
  const handleClose = () => setShow(false);
  const handleShow = async (data) => {
    setmodaldata(data);
    await setShow(true);
  };
  //---------------------------data-------------------------//

  const handleChangeInput = value => {
    setvalue(value)
  }
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: racecourse } = useSelector((state) => state.racecourse);
  const { data: NationalityList } = useSelector(
    (state) => state.NationalityList
  );
  const { data: colordropdown } = useSelector((state) => state.colordropdown);

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(8);
  //---------------------------drop down options-------------------------//
  let AllNationality =
    NationalityList === undefined ? (
      <></>
    ) : (
      NationalityList.map(function (item) {
        return {
          id: item._id,
          value: item.NameEn,
          label: (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <p>{item.NameEn}</p>
            </div>
          ),
        };
      })
    );

  let AllColor =
    colordropdown === undefined ? (
      <></>
    ) : (
      colordropdown.map(function (item) {
        return {
          id: item._id,
          value: item.NameEn,
          label: (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <p>{item.NameEn}</p>
              <p>{item.NameAr}</p>
            </div>
          ),
        };
      })
    );

  const national = NationalityId ? NationalityId.id : "";
  const colodData = colors ? colors.id : "";
  //---------------------------search-------------------------//
  const GetSearch = async () => {
    setLoading(true);
    const response = await axios.get(
      `${window.env.API_URL}/searchracecourse?TrackNameEn=${SearchTitle}&NationalityID=${national}&ColorCode=${colodData}&shortCode=${SearchAge}&page=${currentPage}  `
    );
    setSearchData(response.data.data);
    setLoading(false);
    const totalcount = response.data.totalcount;
    setTotalCount(totalcount);
    const TotalPagesData = response.data.totalPages;
    setTotalPages(TotalPagesData);
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const SearchCode = "";
  useEffect(() => {
    GetSearch({ currentPage });
  }, [dispatch, currentPage]);


  useEffect(() => {
    dispatch(fetchColorDropdown({Value2}));

  }, [dispatch])

  useEffect(() => {
    dispatch(fetchNationalityList({value}));

  }, [dispatch,value])


  //---------------------------delete-------------------------//
  const handleRemove1 = async (Id) => {
    try {
      swal({
        title: "Are you sure?",
        text: "do you want to delete this data ?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          await axios.delete(`${window.env.API_URL}/softdeletecourse/${Id}`);
          swal("Your data has been deleted Successfully!", {
            icon: "success",
          });
          GetSearch();
        } else {
          swal("Your data is safe!");
        }
      });
    } catch (error) {
      const err = error.response.data.message;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
    }
  };


  const handleRemove = async (id) => {
    try {
      swal({
        title: "Are you sure?",
        text: "do you want to delete this data ?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          setLoading(true)
          const res = await axios.delete(`${window.env.API_URL}/DeleteRaceCourseAlltoAll/${id}`);
          const msg = res.data.message ?  res.data.message : "deleted";
          swal(msg, {
            icon: "",
          });
      let data1 =  JSON.stringify(res.data.data)
      const blob = new Blob([data1]);
      if(res?.data?.flag){
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.download = "report1.json";
        link.href = url;
        link.click();
        setLoading(false)
            GetSearch({ currentPage });

      }     
      setLoading(false)
          GetSearch({ currentPage });

        } else {
          swal("Your data is safe!");
        }
      });
    } catch (error) {
      const err = error.success;
      swal({
        title: "Error!",
        text: err,
        icon: "error",
        button: "OK",
      });
      setLoading(false)

    }
  }


  return (
    <>
      <div className="page">
        <div className="rightsidedata">
          <div
            style={{
              marginTop: "30px",
            }}
          >
            <div className="Header ">
              <h4>Race Course Listing</h4>

              <div>
                <Link to="/racecourseform">
                  <button>Add Race Course</button>
                </Link>
                <OverlayTrigger
                  overlay={<Tooltip id={`tooltip-top`}>Filter</Tooltip>}
                >
                  <span className="addmore">
                    <BiFilter
                      className="calendericon"
                      onClick={() => setShowCalender(!ShowCalender)}
                    />
                  </span>
                </OverlayTrigger>{" "}
                <CSVLink
                  data={racecourse}
                  separator={";"}
                  filename={"MKS Race Course.csv"}
                  className="csvclass"
                >
                  Export CSV
                </CSVLink>
              </div>
            </div>
            <div>
              {ShowCalender ? (
                <span className="transitionclass">
                  <div className="userfilter">
                    <div className="filtertextform d-flex">
                      <div className="searchInputs">
                        <div className="searchDropdown searchDropdown1">
                          <Select
                            placeholder="Search Nationality"
                            defaultValue={NationalityId}
                            onChange={setNationalityId}
                            options={AllNationality}
                            onInputChange={handleChangeInput}

                            isClearable={true}
                            isSearchable={true}
                          />
                        </div>
                        <div className="searchDropdown searchDropdown1">
                          <Select
                            placeholder="Search Color"
                            defaultValue={colors}
                            onChange={setcolor}
                            options={AllColor}

                            isClearable={true}
                            isSearchable={true}
                          />
                        </div>
                      </div>
                      <div className="searchInputs">
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => setSearchTitle(e.target.value)}
                          placeholder="Race Course Name"
                        />
                        <input
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          className="form-control"
                          onChange={(e) => setSearchAge(e.target.value)}
                          placeholder="Short Code"
                        />
                      </div>
                    </div>

                  </div>

                  <button className="filterbtn" onClick={GetSearch}>
                    Apply Filter
                  </button>
                </span>
              ) : (
                <></>
              )}
            </div>
            <div className="div_maintb">
              <ScrollContainer>
                <table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Actions</th>

                      <th>Race Course Name</th>
                      <th>Race Course Arabic </th>
                      <th>Short Code</th>

                      <th>Abbreviation</th>
                      <th>Abbreviation Arabic</th>

                      <th>Nationality</th>
                      <th>Color</th>
                      <th>Color Code</th>

                      <th>Image</th>
                    </tr>
                  </thead>
                  {loading ? (
                    <Lottie
                      animationData={HorseAnimation}
                      loop={true}
                      className="TableLottie"
                    />
                  ) : SearchData.length === 0 ? <Notfound /> : (
                    <tbody>
                      {SearchData.map((item, index) => {
                        return (
                          <>
                            <tr className="tr_table_class">
                              <td className="table_delete_btn1">
                                <BiEdit
                                  onClick={() =>
                                    navigate("/editracecourse", {
                                      state: {
                                        courseid: item,
                                      },
                                    })
                                  }
                                />

                                <MdAutoDelete
                                  style={{
                                    fontSize: "22px",
                                  }}
                                  onClick={() => handleRemove(item._id)}
                                />

                                <BsEyeFill onClick={() => handleShow(item)} />
                              </td>
                              <td>
                                {item.TrackNameEn === null ? (
                                  <>N/A</>
                                ) : (
                                  item.TrackNameEn
                                )}
                              </td>
                              <td>
                                {item.TrackNameAr === null ? (
                                  <>N/A</>
                                ) : (
                                  item.TrackNameAr
                                )}
                              </td>
                              <td>{item.shortCode} </td>

                              <td>
                                {item.AbbrevEn === null ? (
                                  <>N/A</>
                                ) : (
                                  <>{item.AbbrevEn}</>
                                )}
                              </td>
                              <td>
                                {item.AbbrevAr === null ? (
                                  <>N/A</>
                                ) : (
                                  <>{item.AbbrevAr}</>
                                )}
                              </td>
                              <td>
                                {item.NationalityDataRaceCourse ? (
                                  <>{item.NationalityDataRaceCourse.NameEn}</>
                                ) : (
                                  <>N/A</>
                                )}
                              </td>
                              <td>
                                {item.ColorCodeData ? (
                                  item.ColorCodeData.NameEn
                                ) : (
                                  <>N/A</>
                                )}
                              </td>
                              <td style={{ color: `${item.Color}` }}>
                                {item.Color}
                              </td>
                              <td>
                                <img
                                  src={item.image ? item.image : Defaultimg}
                                  alt=""
                                />
                              </td>
                            </tr >
                          </>
                        );
                      })}
                    </tbody>
                  )}
                </table>
              </ScrollContainer>
            </div>
          </div>
          <Pagination
            postsPerPage={postsPerPage}
            totalPosts={TotalCount}
            paginate={paginate}
            currentPage={currentPage}
            TotalPages={TotalPages}
          />
        </div>
      </div >
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h2 style={{ fontFamily: "inter" }}> Race Course </h2>
        </Modal.Header>
        <Modal.Body>
          <RacecoursePopup data={modaldata} />
        </Modal.Body>
        <Modal.Footer>
          <button onClick={handleClose} className="modalClosebtn">
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default Racecourse;