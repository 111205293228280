import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});

const getJockeyForRaceSlice = createSlice({
    name: 'JockeyForRace',
    initialState: {
        data: [],
        status: STATUSES.IDLE,
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchJockeyForRace.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(fetchJockeyForRace.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.IDLE
            })
            .addCase(fetchJockeyForRace.rejected, (state, action) => {
                state.status = STATUSES.ERROR;
            })
    }
});

export const { setHorseForRace, setStatus } = getJockeyForRaceSlice.actions;
export default getJockeyForRaceSlice.reducer;

export const fetchJockeyForRace = createAsyncThunk('/JockeyDropDownV2/fetch', async ({ Value1, IncreasePage1 }) => {
    const res = await axios.get(`${window.env.API_URL}/JockeyDropDownV2?NameEn=${Value1 === undefined ? '' : Value1}&size=${IncreasePage1}`);
    const HorseForRaceData = res.data;
    return HorseForRaceData.data;
})
