import React, { useState, useEffect } from "react";
import "../../Components/CSS/forms.css";
import { useNavigate, useLocation } from "react-router-dom";
import swal from "sweetalert";
import axios from "axios";
import { Form, Input } from 'antd';


const EditCategory = () => {
  const history = useNavigate();
  const { state } = useLocation();
  const [form] = Form.useForm();
  const { categoryid } = state;


  const [state1, setState] = useState({
    NameEn: "",
    NameAr: "",
    shortCode: "",
  });
  //----------------------------get Data -------------------------//
  useEffect(() => {
    if (categoryid) {
      setState({
        NameEn: categoryid.NameEn,
        NameAr: categoryid.NameAr,
        shortCode: categoryid.shortCode,
      });
    } else {
      alert("No Data");
    }
  }, [categoryid]);
  //----------------------------submit -------------------------//
  const submit = async () => {

    try {
      const formData = new FormData();
      formData.append("NameEn", state1.NameEn);
      formData.append("NameAr", state1.NameAr + " ");
      formData.append("shortCode", state1.shortCode);

      await axios.put(
        `${window.env.API_URL}/updateCompetitionCategory/${categoryid._id}`,
        formData
      );
      history("/CategoryListing");
      swal({
        title: "Success!",
        text: "Data has been Updated successfully ",
        icon: "success",
        button: "OK",
      });
    } catch (error) {
      const err = error.response.data.message[0];
      const err1 = error.response.data.message[1];
      const err2 = error.response.data.message[2];
      swal({
        title: "Error!",
        text: err,
        err1,
        err2,
        icon: "error",
        button: "OK",
      });
    }
  };
  form.setFieldsValue({
    NameEn: state1.NameEn,
    NameAr: state1.NameAr,

    shortCode: state1.shortCode

  });
  return (
    <>
      <div className="page">
        <div className="rightsidedata">
          <div
            style={{
              marginTop: "30px",
            }}
          >
            <div className="Headers">Edit Category</div>
            <div className="form">
              <Form onFinish={submit} form={form}>
                <div className="row mainrow">
                  <div className="col-sm">
                    <Form.Item
                      name="NameEn"
                      label="Name"
                      rules={[
                        {
                          required: true,
                          message: 'Name (English) is required',
                        },
                      ]}
                    >
                      <Input placeholder="Name" onChange={(e) =>
                        setState({ ...state1, NameEn: e.target.value })
                      }
                        value={state1.NameEn} name="NameEn" />
                    </Form.Item>


                    <span className="spanForm spacer"> |</span>
                  </div>

                  <div className="col-sm">
                    <Form.Item
                      name="NameAr"
                      label="اسم"
                      rules={[
                        {
                          required: true,
                          message: 'Name (Arabic) is required',
                        },
                      ]}
                      style={{ direction: 'rtl' }}
                    >
                      <Input placeholder="اسم" onChange={(e) =>
                        setState({ ...state1, NameAr: e.target.value })
                      }
                        name="Name"
                        value={state1.NameAr} />
                    </Form.Item>
                  </div>
                </div>


                <div
                  className="ButtonSection"
                  style={{ justifyContent: "end" }}
                >
                  <button type="submit" className="SubmitButton">
                    Update
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditCategory;
